import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";

import TextArea from "../../elements/TextArea";
import Embed from "../../elements/Embed";
import Select from "../../elements/Select";
import RefreshBotData from "../../elements/RefreshBotData";
import { EmojiAdder } from "./EmojiAdder";
import { checkIntegrationChild } from "../eventUtils";
import VariableTextInput from "../VariableTextInput";
export class DeleteVariable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dataType: "text",
			user: false,
			channel: false
		};
	}

	componentDidMount() {
		if (this.props.data == undefined) {
			var target = { user_id: "" };
			if (this.props.components["User"]) {
				target = { self: true };
			}
			this.props.updateElementData({
				data: {
					type: "delete_var",
					reference: "",
					target: target,
					key_target_all: false,
					key: "",
					validated: false
				},
				id: this.props.id
			});
		} else {
			var reference = this.props.data.reference;
			var variables_array = this.props.custom_variables.variables;
			var variable_settings = variables_array.find((v) => v.reference == reference);
			if (!variable_settings) return;
			var type = variable_settings.type;
			var user = false;
			var channel = false;
			if (type == "user") {
				type = variable_settings.dataType;
				user = true;
			} else if (type == "channel") {
				type = variable_settings.dataType;
				channel = true;
			}
			this.setState({ user: user, dataType: type, channel: channel });
		}
	}

	targetOnChange = (value) => {
		if (value == this.state.user) {
			return;
		}
		// {label:"User who used the command",value:"self"},
		// {label:"User variable",value:"variable"},
		// {label:"User ID",value:"id"},
		if (value == "self") {
			var data = { ...this.props.data };
			data.target = {
				self: true
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		} else if (value == "variable") {
			var data = { ...this.props.data };
			data.target = {
				variable: ""
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		} else if (value == "id") {
			var data = { ...this.props.data };
			data.target = {
				user_id: ""
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		} else if (value == "all") {
			var data = { ...this.props.data };
			data.target = {
				all: true
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		}

		this.setState({ user: value });
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "delete_var",
					reference: "",
					target: {
						self: true
						// variable:"{option_target}",
						// user_id:"12345"
					},
					key_target_all: false,
					validated: false
				},
				id: this.props.id
			});
		} else {
			// VALIDATE
			if (this.props.id != prevProps.id && this.props.data && this.props.data.reference) {
				var reference = this.props.data.reference;
				var variables_array = this.props.custom_variables.variables;
				var variable_settings = variables_array.find((v) => v.reference == reference);
				if (!variable_settings) return;
				var type = variable_settings.type;
				var user = false;
				var channel = false;
				if (type == "user") {
					type = variable_settings.dataType;
					user = true;
				} else if (type == "channel") {
					type = variable_settings.dataType;
					channel = true;
				}
				this.setState({ user: user, channel: channel, dataType: type });
			}
			if (this.props.data.equation != "" && this.props.data.reference != "") {
				if (this.props.data.validated == false) {
					this.updateData("validated", true);
				}
			} else if (this.props.data.validated == true) {
				this.updateData("validated", false);
			}
		}
	}

	nameChange = (value) => {
		value = value.toLowerCase();
		value = value.substring(0, 32);
		value = value.replace(" ", "-");
		const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/gu);
		value = value.replace(regexForNonAlphaNum, "");
		this.updateData("name", value);
	};

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	renderResponseTypes = () => {
		var user = "User who used the command";
		if (this.props.mode == "event" && this.props.components["User"]) {
			user = "The user.";
		}
		// const responseTypes = [
		//     { value: "reply", label: "Reply to the command", disabled: this.props.components.Interaction? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
		//     { value: "trigger_channel", label: `Send the message to the ${channel}`, disabled: this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true }
		// ];

		const responseTypes = [
			{ label: user, value: "self", disabled: this.props.components.Interaction || this.props.components.User ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "User option", value: "variable", disabled: this.props.mode == "event" ? true : false },
			{ label: "User ID", value: "id" },
			{ label: "All Users", value: "all" }
		];

		return responseTypes;
	};

	renderDisableField = () => {
		var disabled = true;
		if (this.props.data.target.reply == true && (this.props.components.Interaction || checkIntegrationChild(this.props.elements, this.props.id))) {
			disabled = false;
		} else if (this.props.data.target.trigger_channel == true && (this.props.components.Channel || checkIntegrationChild(this.props.elements, this.props.id))) {
			disabled = false;
		}
		return disabled;
	};

	renderChannelTypes = () => {
		var channel = "Channel the command was used in";
		if (this.props.mode == "event" && this.props.components["Channel"]) {
			channel = "The channel.";
		}
		// const responseTypes = [
		//     { value: "reply", label: "Reply to the command", disabled: this.props.components.Interaction? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
		//     { value: "trigger_channel", label: `Send the message to the ${channel}`, disabled: this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true }
		// ];

		const responseTypes = [
			{ label: channel, value: "self", disabled: this.props.components.Interaction || this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "Specific channel or variable", value: "id" },
			{ label: "All channels", value: "all" }
		];

		return responseTypes;
	};

	render() {
		if (this.props.data != undefined) {
			return (
				<div>
					<div className="mb-15">
						<h4>Delete Variable</h4>
						<p>Deletes the value of a variable. This will not incur a data storage call.</p>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Custom Variable <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/delete-variable#custom-variable" />
						</h4>
						<span>The Custom Variable to delete the value of.</span>
						<Select
							type="custom_variables"
							value={this.props.data.reference}
							onChange={(value) => {
								this.updateData("reference", value);
								var variables_array = this.props.custom_variables.variables;
								var variable_settings = variables_array.find((v) => v.reference == value);
								if (!variable_settings) return;
								var type = variable_settings.type;
								var user = false;
								var channel = false;
								if (type == "user") {
									type = variable_settings.dataType;
									user = true;
								} else if (type == "channel") {
									type = variable_settings.dataType;
									channel = true;
								}
								this.setState({ dataType: type, user: user, channel: channel });
							}}
						></Select>
					</div>

					{this.state.user ? (
						<>
							<div className="mb-15 slash-action">
								<h4>
									User Target <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/delete-variable#user-target" />
								</h4>
								<span>Which user to delete the custom variable of.</span>
								<Select
									value={"self" in this.props.data.target ? "self" : "variable" in this.props.data.target ? "variable" : "user_id" in this.props.data.target ? "id" : "all" in this.props.data.target ? "all" : ""}
									onChange={(value) => {
										this.targetOnChange(value);
										// value = JSON.parse(value);
										// this.setState({value:value})
									}}
									options={this.renderResponseTypes()}
								></Select>
							</div>

							{"variable" in this.props.data.target ? (
								<div className="mb-15 slash-action">
									<h4>User Option </h4>
									<span>The user option to delete the custom variable of.</span>
									<VariableTextInput
										slash_options={this.props.options}
										label="Option"
										placeholder="{option_user}"
										value={this.props.data.target.variable}
										onChange={(value) => {
											var data = { ...this.props.data };
											data.target = {
												variable: value
											};

											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										}}
										required={true}
									/>
									{/* <div class="long-input mt-15">
                                            <label>Option</label>
                                            <input onChange={(e) => {

                                                var data = { ... this.props.data };
                                                data.target = {
                                                    variable: e.target.value,
                                                };
                                                this.props.updateElementData(
                                                    {
                                                        data: data,
                                                        id: this.props.id
                                                    }
                                                );

                                            }} type="text" placeholder={"{option_user}"} value={this.props.data.target.variable} />
                                        </div> */}
								</div>
							) : null}

							{"user_id" in this.props.data.target ? (
								<div className="mb-15 slash-action">
									<h4>User ID </h4>
									<span>The user id to delete the custom variable of.</span>
									<VariableTextInput
										slash_options={this.props.options}
										label="User ID"
										placeholder="12345"
										value={this.props.data.target.user_id}
										onChange={(value) => {
											var data = { ...this.props.data };

											data.target = {
												user_id: value
											};

											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										}}
										required={true}
									/>

									{/* <div class="long-input mt-15">
                                            <label>ID</label>
                                            <input onChange={(e) => {

                                                var data = { ... this.props.data };
                                                data.target = {
                                                    user_id: e.target.value,
                                                };
                                                this.props.updateElementData(
                                                    {
                                                        data: data,
                                                        id: this.props.id
                                                    }
                                                );

                                            }} type="text" placeholder={"136327647792726016"} value={this.props.data.target.user_id} />
                                        </div> */}
								</div>
							) : null}

							{/* <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr> */}
						</>
					) : null}
					{/* Text Value */}

					{this.state.channel ? (
						<>
							<div className="mb-15 slash-action">
								<h4>Channel Target </h4>
								<span>Which channel to delete the custom variable of.</span>
								<Select
									value={"self" in this.props.data.target ? "self" : "all" in this.props.data.target ? "all" : "id"}
									onChange={(value) => {
										if (value == this.state.channel) {
											return;
										}
										// {label:"User who used the command",value:"self"},
										// {label:"User variable",value:"variable"},
										// {label:"User ID",value:"id"},
										if (value == "self") {
											var data = { ...this.props.data };
											data.target = {
												self: true
											};
											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										} else if (value == "id") {
											var data = { ...this.props.data };
											data.target = {
												id: ""
											};
											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										} else if (value == "all") {
											var data = { ...this.props.data };
											data.target = {
												all: true
											};
											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										}
										this.setState({ channel: value });

										// value = JSON.parse(value);
										// this.setState({value:value})
									}}
									options={this.renderChannelTypes()}
								></Select>
							</div>

							{"id" in this.props.data.target ? (
								<div className="mb-15 slash-action">
									<h4>Channel ID or Variable </h4>
									<span>The id or variable of a channel to delete the custom variable of.</span>
									<VariableTextInput
										slash_options={this.props.options}
										label="ID or Variable"
										placeholder="12345"
										value={this.props.data.target.id}
										onChange={(value) => {
											var data = { ...this.props.data };
											data.target = {
												id: value
											};
											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										}}
										required={true}
									/>
									{/* <div class="long-input mt-15">
                                        <label>ID or Variable</label>
                                        <input onChange={(e) => {

                                            var data = { ... this.props.data };
                                            data.target = {
                                                id: e.target.value,
                                            };
                                            this.props.updateElementData(
                                                {
                                                    data: data,
                                                    id: this.props.id
                                                }
                                            );

                                        }} type="text" placeholder={"136327647792726016"} value={this.props.data.target.id} />
                                    </div> */}
								</div>
							) : null}
						</>
					) : null}

					{this.state.dataType == "object" ? (
						<>

							<div>
								{/* Select either ALL keys or single key */}
								<div className="mb-15 slash-action">
									<h4>
										Target <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/set-variable#target" />
									</h4>
									<span>Which key or keys to delete from the object.</span>
									<Select
										value={
											this.props.data.key_target_all == true ? "all" : "key"
										}
										onChange={(value) => {
											if (value == "all") {
												this.updateData("key_target_all", true);
											} else {
												this.updateData("key_target_all", false);
											}

										}}
										options={[
											{ label: "All keys", value: "all" },
											{ label: "Specific key", value: "key" }
										]}
									></Select>
								</div>
							</div>
							{
								this.props.data.key_target_all != true ?
									<div className=" slash-action">
										<h4>
											Key <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/set-variable#key" />
										</h4>
										<span>The key of this custom variable to delete.</span>

										<VariableTextInput
											required={true}
											slash_options={this.props.options}
											label="Key"
											placeholder="key"
											value={this.props.data.key}
											onChange={(value) => {
												// value = value.substring(0, 100);
												// // value = value.replace(" ","-")
												// value = value.split(" ").join("_");
												// const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_]+/gu);
												// value = value.replace(regexForNonAlphaNum, "");
												// // console.log(value, 'VALUE NOW ');

												this.updateData("key", value);
											}}
										/>
									</div> : null}
							}
						</>
					) : null}
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	saveFailed: state.builder.saveFailed,
	custom_variables: state.data.bot.commands.variables,
	components: state.builder.components,
	mode: state.builder.mode
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteVariable);
