import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setHighlightAction } from '../../../../actions';

import Embed from '../../elements/Embed';
import TextArea from '../../elements/TextArea';
import EmojiAdder from './EmojiAdder';

import Select from '../../elements/Select';
import { checkIntegrationChild } from '../eventUtils';
import { renderResponseActionOptions } from '../builderUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { successToast, errorToast, loadingToast } from "../toast.js";

const responseTypes = [
    { value: "reply", label: "Reply to the command" },
    { value: "trigger_channel", label: "Send the message to the channel the command was used in." }
];
export class RandomReply extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            var target = {};
            if (this.props.mode == "event" && this.props.components['Channel']) {
                target = { trigger_channel: true };
            } else if (this.props.components['Interaction']) {
                target = { reply: true };
            }
            this.props.updateElementData(
                {
                    data: {
                        type: "random_response",
                        responses: [],
                        validated: false,
                        target: target
                    },
                    id: this.props.id
                }
            );
        } else if (this.props.data.target == undefined) {
            var target = {};
            if (this.props.mode == "event" && this.props.components['Channel']) {
                target = { trigger_channel: true };
            } else if (this.props.components['Interaction']) {
                target = { reply: true };
            }

            var dataTarget = { ...this.props.data.target };
            dataTarget = target;
            this.updateData("target", dataTarget);

        }

        // Highlight corrospodning action
        if (this.props.data && this.props.data.action_id && this.props.data.target && this.props.data.target.editId) {
            this.props.setHighlightAction(this.props.data.action_id);
        }

    }

    componentWillUnmount() {
        this.props.setHighlightAction(null);
    }

    validateResponse = () => {
        var check = false;
        this.props.data.responses.forEach(response => {
            if ("embed" in response) {
                if (response.embed.description != "") {
                    check = true;
                }
            } else {
                if (response.response != "") {
                    check = true;
                }
            }
        });

        return check;
    };

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "random_response",
                        responses: [],
                        target: { reply: true },
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else if (this.props.data.target == undefined) {
            var target = {};
            if (this.props.mode == "event" && this.props.components['Channel']) {
                target = { trigger_channel: true };
            } else if (this.props.components['Interaction']) {
                target = { reply: true };
            }

            var dataTarget = { ...this.props.data.target };
            dataTarget = target;
            this.updateData("target", dataTarget);

        } else {
            // VALIDATE

            if ((this.props.data.responses.length > 0 && this.validateResponse() == true)) {
                if (this.props.data.validated == false) {
                    this.updateData("validated", true);
                }
            } else if (this.props.data.validated == true) {
                this.updateData("validated", false);
            }
        }
    }

    nameChange = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "-");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("name", value);
    };

    updateData = (key, value) => {
        // console.log(key, value, this.props.id, this.props.data, 'HERE');
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    addResponse = () => {
        var responses = [...this.props.data.responses];
        if (responses.length < 100) {
            responses.push(
                {
                    response: "",
                    emojis: [],
                }
            );

            this.updateData("responses", responses);
        }
    };

    renderResponseTypes = () => {
        var channel = "channel the command was used in.";
        if (this.props.mode == "event" && this.props.components['Channel']) {
            channel = "channel.";
        }
        const responseTypes = [
            { value: "reply", label: "Reply to the command", disabled: this.props.components.Interaction ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
            { value: "trigger_channel", label: `Send the message to the ${channel}`, disabled: this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },

        ];

        responseTypes.push({ value: "editId", label: "Edit a message sent by another action" });


        if (this.props.mode == "event") {
            responseTypes.unshift({ value: "", label: "", disabled: true });
        }

        return responseTypes;
    };

    renderDisableField = () => {
        var disabled = true;
        if (!this.props.data.target || (this.props.data.target.reply == true && (this.props.components.Interaction || checkIntegrationChild(this.props.elements, this.props.id)))) {
            disabled = false;
        } else if (this.props.data.target.trigger_channel == true && (this.props.components.Channel || checkIntegrationChild(this.props.elements, this.props.id))) {
            disabled = false;
        } else if (this.props.data.target.editId) {

            disabled = false;
        }

        return disabled;
    };

    renderResponses = () => {
        var responses = [];

        this.props.data.responses.forEach((response, index) => {

            const changeType = () => {
                if ("response" in response) {
                    response = {
                        embed: {
                            title: "",
                            description: ""
                        },
                        emojis: response.emojis || []
                    };
                } else {
                    response = {
                        response: "",
                        emojis: response.emojis || []
                    };
                }
                var responses = [...this.props.data.responses];
                responses[index] = response;
                this.updateData("responses", responses);

            };

            const updateResponse = (key, value) => {
                var responses = [...this.props.data.responses];
                // console.log(key, value, 'TEST2');
                response[key] = value;
                responses[index] = response;
                this.updateData("responses", responses);

            };


            const deleteResponse = () => {
                var responses = [...this.props.data.responses];
                if (responses.length >= 2) {
                    responses.splice(index, 1);
                    this.updateData("responses", responses);

                }


            };
            responses.push(
                <div style={{ width: "100%", marginRight: "20px" }}>
                    <div style={{ display: "flex", marginBottom: "15px" }}>
                        <h4>{`Response ${index + 1}`}</h4>
                        {/* <div className="section-content-header">Create an announcement when someone joins your server.</div> */}
                        <div style={{ display: "flex", marginLeft: "auto" }}>
                            <button disabled={this.props.data.responses.length <= 1} type="button" className="btn btn-red" onClick={(e) => { deleteResponse(); }} style={{ marginRight: "15px", padding: "5px", fontSize: "15px", fontWeight: "800" }}>Delete</button>

                            <p style={{ marginInlineEnd: "12px", marginBottom: "0px" }}>{response.embed != undefined ? "Embed" : "Plain Text"}</p>
                            <Toggle value={response.response != undefined ? false : true} update={(value) => { changeType(); }} type="type"></Toggle>
                        </div>


                        {/* <div className="section-content-title justify-space-between">
                            <button disabled={this.props.data.responses.length <=1} type="button" className="btn btn-red" onClick={(e) =>{deleteResponse()}} style={{marginRight:"15px",padding:"5px",fontSize:"15px",fontWeight:"800"}}>Delete Response</button>
                            <span style={{ marginInlineEnd: "12px", marginBottom: "0px" }}>{response.embed != undefined ? "Embed" : "Plain Text"}</span>
                            <Toggle value={response.response != undefined ? false:true} update={(value) => {changeType() }} type="type"></Toggle>
                        </div> */}
                    </div>

                    <div>
                        {'response' in response ? <TextArea extra_classNames={`${response.response == "" && this.props.saveFailed ? "command-required" : ""}`} value={response.response} variableEditor={true} slash={true} slash_options={this.props.options} onChange={(value) => { updateResponse('response', value); }} /> : <Embed key={this.props.id} commandSaveFailed={this.props.saveFailed} variableEditor={true} slash={true} options={this.props.options} update={(value) => { updateResponse('embed', value); }} value={response.embed} />}
                    </div>


                    <div className='mt-15'>
                        <h4 style={{ marginBottom: "0px" }}>Reactions<DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/random-reply#reactions"}></DocsLink></h4>
                        <div className="section-content-header mb-15">Reactions to add to the message.</div>
                        <EmojiAdder emojis={response.emojis || []} update={(newEmojis) => {
                            updateResponse("emojis", newEmojis);
                        }} />

                    </div>

                    <hr className="slashcommand-hr"></hr>

                </div>
            );
        });

        return responses;
    };

    selectChange = (value) => {
        if (!value) return;
        this.props.setHighlightAction(value);
        var action = this.props.actions.find(action => action.id == value);

        if (!("editId" in action.data.data)) {
            var data = { ...action.data.data };
            data.editId = `${Date.now()}`;
            this.props.updateElementData(
                {
                    data: data,
                    id: value
                }
            );
            action.data.data.editId = data.editId;
        }
        var id = action.data.data.editId;

        var action_data = { ...this.props.data };
        action_data.target.editId = id;
        action_data.action_id = value;
        this.props.updateElementData(
            {
                data: action_data,
                id: this.props.id
            }
        );
    };

    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Random Reply</h4>
                        <p>Bot responds with random message from a list of given responses.</p>
                    </div>
                    <div className="mb-15">
                        <h4>Responses <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/random-reply#response-list" /> </h4>
                        <p>Click the add response button to add a response to the random response list. The bot will pick a response randomly to respond with.</p>

                        <div>
                            {this.props.data.responses.length > 0 && <h4 className='mb-4'>You have {this.props.data.responses.length}/100 responses.</h4>}
                            {this.renderResponses()}
                        </div>

                        <button disabled={this.renderDisableField()} className={`btn w-full btn-gray ${this.props.saveFailed == true && this.props.data.responses.length == 0 ? "hithere" : null}`} type="button" onClick={(e) => { this.addResponse(); }}>{this.props.data.responses.length === 100 ? "You have reached the limit of 100 responses." : "Add Response"}</button>

                        <hr className="slashcommand-hr"></hr>

                        {this.props.data.target ? <div>
                            <div className="mt-15 mb-15 slash-action">
                                <h4 style={{ marginBottom: "0px" }}>Response type <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/random-reply#response-type" /> </h4>
                                <div className="section-content-header mb-15">Where your bot should send this message.</div>
                                <Select onChange={(value) => {
                                    var target = { ...this.props.data.target };
                                    if (value == "reply") {
                                        target = {
                                            reply: true
                                        };
                                    } else if (value == "editId") {
                                        target = {
                                            editId: ""
                                        };
                                        if (this.props.data.action_id) {
                                            target.editId = this.props.data.action_id;
                                            this.props.setHighlightAction(this.props.data.action_id);
                                        }

                                    } else {
                                        target = {
                                            trigger_channel: true
                                        };
                                    }
                                    this.updateData("target", target);

                                }} value={"reply" in this.props.data.target ? "reply" : "trigger_channel" in this.props.data.target ? "trigger_channel" : 'editId' in this.props.data.target ? "editId" : ""} options={this.renderResponseTypes()} />
                            </div>
                        </div> : null}

                        {this.props.data.target && 'editId' in this.props.data.target ? <>
                            <div>
                                <h4 style={{ marginBottom: "0px" }}>Response Action<DocsLink location={"https://youtu.be/6aAYImRvszM"}></DocsLink></h4>
                                <div className="section-content-header mb-15">Select one of the response actions in this command to edit the message sent by that action.</div>
                                <Select saveFailed={this.props.data.action_id == "" && this.props.saveFailed} value={this.props.data.action_id} options={renderResponseActionOptions(this.props.actions, this.props.selected)} onChange={this.selectChange} />
                            </div>


                            <div className='mt-15'>
                                <h4 style={{ marginBottom: "0px" }}>Keep Components</h4>
                                <div className="section-content-header mb-15">Keep the buttons or select menu options of the original message.</div>
                                <Select saveFailed={this.props.data.action_id == "" && this.props.saveFailed} value={this.props.data.target.keep_components == true ? true : false} options={[
                                    {
                                        value: true,
                                        label: "Yes"
                                    },
                                    {
                                        value: false,
                                        label: "No"
                                    }
                                ]} onChange={(val) => {
                                    if (val == "true") {
                                        val = true;
                                    } else {
                                        val = false;
                                    }

                                    var data = { ...this.props.data };
                                    data.target.keep_components = val;
                                    this.props.updateElementData(
                                        {
                                            data: data,
                                            id: this.props.id
                                        }
                                    );
                                }} />
                            </div>
                        </> : null}
                        <hr className="slashcommand-hr"></hr>

                        <div className='mt-15'>
                            <h4 style={{ marginBottom: "0px" }}>Optional Variable</h4>
                            <div className="section-content-header mb-15">An optional variable to return the message id of this reply. You can use this variable in other blocks.</div>
                            <div class="long-input mb-15">
                                <label>Variable</label>
                                <input className={`${this.props.data.variable == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                    // if (!isNaN(e.target.value) && e.target.value <= 850) {
                                    var value = e.target.value;
                                    value = value.toLowerCase();
                                    value = value.substring(0, 32);
                                    value = value.replace(" ", "_");
                                    const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
                                    value = value.replace(regexForNonAlphaNum, "");

                                    this.updateData("variable", value);
                                }} type="text" value={this.props.data.variable ? this.props.data.variable : ""} />
                            </div>

                            {
                                this.props.data.variable ? <div>
                                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>



                                    <h4>Message Id Variable</h4>
                                    <p className="mb-15" style={{
                                        color: "#fff",
                                        opacity: "0.7",
                                        fontWeight: 500
                                    }}>You can use the variable <span style={{ color: "#f45142", opacity: "1 !important" }}>{`{${this.props.data.variable}}`}</span><FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.variable}}`); successToast("Copied variable to clipboard"); }} /> in other blocks.</p>

                                </div> : null
                            }
                        </div>
                        {/* <TextArea variableEditor={true} slash={true} slash_options ={this.props.options} required={true} value={this.props.data.response} onChange={(value) =>{this.props.update("response",value)}}/> */}
                    </div>
                </div >
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    components: state.builder.components,
    mode: state.builder.mode,
    beta: state.data.beta
});
const mapDispatchToProps = {
    updateElementData,
    setHighlightAction
};

export default connect(mapStateToProps, mapDispatchToProps)(RandomReply);
