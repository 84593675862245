import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setElements } from '../../../../actions';
import getLayoutedElements from "../getLayoutElements";
import Embed from '../../elements/Embed';
import TextArea from '../../elements/TextArea';
import Select from '../../elements/Select';
import RefreshBotData from '../../elements/RefreshBotData';
import { addEdge, getOutgoers } from 'react-flow-renderer';
import { EmojiAdder } from './EmojiAdder';
import splitElements from '../splitElements';

const responseTypes = [
    { value: "reply", label: "Reply to the command" },
    { value: "targeted", label: "Send the message to a specific text-channel" }
];


export class PremiumConditionChild extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ""
        };
    }

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        actions: [],
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        // if(this.props.data == undefined){
        //     this.props.updateElementData(
        //         {                
        //             data:{
        //                 type:"button_response",
        //                 response_options:{
        //                     response:"",
        //                     target: { reply: true },
        //                     emojis:[]
        //                 },
        //                 validated:false
        //             },
        //             id:this.props.id
        //         }
        //     )
        // }else{
        //     // VALIDATE
        //     if((
        //         ('response' in this.props.data.response_options && this.props.data.response_options.response != "") ||
        //         ("embed" in this.props.data.response_options && "description" in this.props.data.response_options.embed &&  this.props.data.response_options.embed.description != "")) &&
        //         (this.props.data.response_options.target.reply != undefined || ("variable" in this.props.data.response_options.target && this.props.data.response_options.target.variable != "") || ('id' in this.props.data.response_options.target && this.props.data.response_options.target.id != "")
        //     )){
        //         if(this.props.data.validated == false){
        //             this.updateData("validated",true)
        //         }

        //     }else if(this.props.data.validated == true){
        //         this.updateData("validated",false)

        //     }
        // }
    }


    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };


    renderOptions = () => {
        var options = [
            // {value:"trigger",label:"The member who triggered the command"}
        ];
        this.props.options.forEach(option => {
            options.push({ value: option.name, label: option.name });
        });
        return options;
    };

    selectUpdate = (value) => {
        value = JSON.parse(value);
        var response_options = { ...this.props.data.response_options };
        response_options.target = value;
        this.updateData("response_options", response_options);
    };

    addCondition = () => {
        var elements = [...this.props.elements];
        var element = this.props.elements.find(element => element.id == this.props.selected);
        var existingConditions = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);


        var id = `conChild${elements.length + 1}`;
        var lastChild = existingConditions[existingConditions.length - 1];
        console.log(lastChild);
        var docuElement = document.getElementById(lastChild.id);
        var width = 0;
        if (docuElement) {
            width = docuElement.clientWidth;
        }

        console.log(width, "WIDTH");

        elements.push(
            {
                id: id,
                type: 'conditionChild',
                data: {
                    node_options: {
                        type: "premium_condition"
                    },
                    data: {
                        actions: [],
                    }

                },
                // target:"3",
                draggable: true,
                position: { x: lastChild.position.x + width + 20, y: lastChild.position.y },
            },
        );
        var newEdges = addEdge({
            source: element.id,
            target: id,
            type: "step",
            animated: false,
            arrowHeadType: "arrowclosed"

        }, splitElements(elements).edges);
        newEdges.forEach(newEdge => {
            var index = elements.findIndex(element => element.id == newEdge.id);
            if (index == -1) {
                elements.push(newEdge);
            }
            // elements[index] = newNode;
        });
        // elements = getLayoutedElements(elements)
        this.props.setElements(elements);

    };

    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    {this.props.data.type != "else" ?
                        <div className="mb-15">
                            <h4>Premium Check Conditon</h4>
                            <p>Run connected actions when the bot is premium</p>
                        </div> :
                        <div className="mb-15">
                            <h4>Else Conditon</h4>
                            <p>Run connected actions when the bot is not premium</p>

                        </div>}






                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
    updateElementData,
    setElements
};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumConditionChild);
