import React, { Component } from "react";
import { connect } from "react-redux";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";
import RefreshBotData from "../../elements/RefreshBotData";
import Select from "../../elements/Select";
import RoleAdd from "../../elements/RoleAdd";
import DocsLink from "../../elements/DocsLink";
import { checkIntegrationChild } from "../eventUtils";
import VariableTextInput from "../VariableTextInput";

export class ChannelEditAction extends Component {
	componentDidMount() {
		if (!this.props.data) {
			var target = { id: "" };
			if (this.props.components["Channel"]) {
				target = { self: true };
			}
			this.props.updateElementData({
				data: {
					type: "channel_edit",
					name: "",
					topic: "",
					permissions: [],
					parent: "",
					guild_id: "",
					validated: false,
					reason: "",
					nsfw: false,
					userLimit: 0,
					rateLimitPerUser: 0,
					target: target,
					success_handles: false,
					mergePermissions: false
				},
				id: this.props.id
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data != undefined && this.props.data.success_handles == undefined) {
			this.updateData("success_handles", false);
		}
		if (!this.props.data) {
			this.props.updateElementData({
				data: {
					type: "channel_edit",
					name: "",
					topic: "",
					permissions: [],
					parent: "",
					guild_id: "",
					validated: false,
					reason: "",
					nsfw: false,
					userLimit: 0,
					rateLimitPerUser: 0,
					mergePermissions: false,
					target: {
						self: true
					}
				},
				id: this.props.id
			});
		} else {
			if (this.props.data.target && (("self" in this.props.data.target && this.props.data.target.self) || ("id" in this.props.data.target && this.props.data.target.id) || ("variable" in this.props.data.target && this.props.data.target.variable))) {
				if (!this.props.data.validated) {
					this.updateData("validated", true);
				}
			} else {
				if (this.props.data.validated) {
					this.updateData("validated", false);
				}
			}
		}
	}

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({ data: data, id: this.props.id });
	};

	selectUpdate = (value) => {
		value = JSON.parse(value);
		var target = { ...this.props.data.target };
		target = value;
		this.updateData("target", target);
	};

	renderRoleOptions = () => {
		var options = [];
		options.push({ value: JSON.stringify({ id: "" }), label: "" });
		this.props.options.forEach((option) => {
			if (option.type == "role") {
				options.push({ value: JSON.stringify({ variable: option.name }), label: `Role Option: ${option.name}` });
			}
		});
		if (this.props.bot_data || this.props.bot_data.length > 0) {
			this.props.bot_data.forEach((guild, index) => {
				guild.roles.forEach((role) => {
					options.push({ value: JSON.stringify(role), label: `${role.name} - ${guild.name}` });
				});
			});
		}
		return options;
	};

	renderTargetTypes = () => {
		var channel = "Channel the command was run in";
		if (this.props.mode == "event" && this.props.components["Channel"]) {
			channel = "The channel.";
		}
		const targetTypes = [
			{ label: channel, value: "self", disabled: this.props.components.Interaction || this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "Channel option", value: "variable", disabled: !this.props.components.Interaction },
			{ label: "Channel ID", value: "id" }
		];
		return targetTypes;
	};

	targetOnChange = (value) => {
		if (value == "self") {
			var data = { ...this.props.data };
			data.target = {
				self: true
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		} else if (value == "variable") {
			var data = { ...this.props.data };
			data.target = {
				variable: ""
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		} else if (value == "id") {
			var data = { ...this.props.data };
			data.target = {
				id: ""
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		}
	};

	renderPermissions = () => {
		var permissions = [];
		this.props.data.permissions.forEach((permission, index) => {
			permissions.push(
				<div>
					<hr class="slash-hr" style={{ borderTop: "1px solid rgb(173, 181, 189)" }} />
					<div style={{ display: "flex", alignItems: "center" }}>
						<h4 style={{ marginBottom: "0px" }}>Permission Overwrite {index + 1}</h4>
						<button
							onClick={() => {
								var permissions = [...this.props.data.permissions];
								permissions.splice(index, 1);
								this.updateData("permissions", permissions);
							}}
							className="btn btn-red"
							style={{ marginLeft: "auto", padding: "5px", fontSize: "15px", fontWeight: "800" }}
						>
							Delete
						</button>
					</div>
					<div className="mb-15 slash-action">
						<h4>Type</h4>
						<span>The type of this permission overwrite. Either Role or User.</span>
						<div className="mt-15">
							<Select
								value={permission.type}
								options={[
									{ value: "role", label: "Role" },
									{ value: "user", label: "User" }
								]}
								onChange={(value) => {
									var permissions = [...this.props.data.permissions];
									permissions[index].type = value;
									this.updateData("permissions", permissions);
								}}
							/>
						</div>
					</div>

					{permission.type == "role" ? (
						<div className="mb-15 slash-action">
							<h4>
								Role <RefreshBotData />
							</h4>
							<span>The role to apply this permission to.</span>
							{/* Either specific id + server_id or server roles dropdown */}
							<Select
								value={permission.specific ? "specific" : "server"}
								onChange={(value) => {
									var permissions = [...this.props.data.permissions];
									if (value == "specific") {
										permissions[index].id = { id: "" };
										permissions[index].server_id = "";
										permissions[index].specific = true;
										console.log(permissions);
										this.updateData("permissions", permissions);
									} else {
										permissions[index].id = { id: "" };
										permissions[index].server_id = "";
										permissions[index].specific = false;
										this.updateData("permissions", permissions);
									}
								}}
								options={[
									{ value: JSON.stringify({ id: "" }), label: "" },
									{ value: "specific", label: "Specific Role" },
									{ value: "server", label: "Server Roles" }
								]}
							></Select>

							{permission.specific ? (
								<div>
									{/* Two Inputs for Server Id + Role Id */}

									<VariableTextInput
										label={"Role ID"}
										placeholder="911634771019444224"
										slash_options={this.props.options}
										value={permission.id.id}
										onChange={(value) => {
											var permissions = [...this.props.data.permissions];
											permissions[index].id.id = value;
											this.updateData("permissions", permissions);
										}}
									/>
								</div>
							) : (
								<div className="mt-3">
									<Select
										className="mt-2"
										value={JSON.stringify(permission.id)}
										options={this.renderRoleOptions()}
										onChange={(value) => {
											var permissions = [...this.props.data.permissions];
											permissions[index].id = JSON.parse(value);
											this.updateData("permissions", permissions);
										}}
									/>
								</div>
							)}
						</div>
					) : (
						<div className="mb-15 slash-action">
							<h4>User</h4>
							<span>The user to apply the permissions to. Either a User ID or User Option.</span>
							{/* <VariableTextInput slash_options={this.props.options} value={this.props.data.response_options.target.user_option} onChange={(value) => {
                                var response_options = { ...this.props.data.response_options };
                                response_options.target.user_option = value;
                                this.updateData("response_options", response_options);
                            }} /> */}

							<VariableTextInput
								label={"User"}
								placeholder="User Id"
								slash_options={this.props.options}
								value={permission.id.id}
								onChange={(value) => {
									var permissions = [...this.props.data.permissions];
									permissions[index].id.id = value;
									this.updateData("permissions", permissions);
								}}
							/>
							{/* <div class="long-input mt-15">
                            <label>User</label>
                            <input onChange={(e) => {
                                var permissions = [...this.props.data.permissions];
                                permissions[index].id.id = e.target.value;
                                this.updateData("permissions", permissions);
                            }} type="text" value={permission.id.id} />
                        </div> */}
						</div>
					)}

					<div className="mb-15 slash-action">
						<h4>Allow</h4>
						<span>Permissions to allow</span>
						<RoleAdd
							update={(items) => {
								var permissions = [...this.props.data.permissions];
								permissions[index].allow = items;
								this.updateData("permissions", permissions);
							}}
							items={permission.allow}
							type="permissions"
							subType="channel"
						/>
					</div>

					<div className="mb-15 slash-action">
						<h4>Deny</h4>
						<span>Permissions to deny</span>
						<RoleAdd
							update={(items) => {
								var permissions = [...this.props.data.permissions];
								permissions[index].deny = items;
								this.updateData("permissions", permissions);
							}}
							items={permission.deny}
							type="permissions"
							subType="channel"
						/>
					</div>
				</div>
			);
		});
		return permissions;
	};

	render() {
		if (this.props.data) {
			return (
				<div>
					<div className="mb-15">
						<h4>Edit a Channel</h4>
						<p>Edit a channel in the server. User and Role options can be used for permissions.</p>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Channel <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#channel" />
						</h4>
						<span>Which channel do you want to edit?</span>
						<Select
							value={"self" in this.props.data.target ? "self" : "variable" in this.props.data.target ? "variable" : "id" in this.props.data.target ? "id" : ""}
							onChange={(value) => {
								this.targetOnChange(value);
							}}
							options={this.renderTargetTypes()}
						></Select>
					</div>

					{"variable" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>
								Channel Option <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#channel-option" />
							</h4>
							<span>The channel option to edit the channel</span>

							<VariableTextInput
								slash_options={this.props.options}
								label="Option"
								placeholder="{option_channel}"
								value={this.props.data.target.variable}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										variable: value
									};
									this.props.updateElementData({ data: data, id: this.props.id });
								}}
							/>
							{/* <div className="long-input mt-15">
                            <label>Option</label>
                            <input className={this.props.saveFailed && this.props.data.target.variable == "" ? "command-required" : ""} onChange={(e) => {
                                var data = { ...this.props.data };
                                data.target = {
                                    variable: e.target.value
                                };
                                this.props.updateElementData({ data: data, id: this.props.id });
                            }} type="text" placeholder="{option_channel}" value={this.props.data.target.variable} />
                        </div> */}
						</div>
					)}

					{"id" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>
								Channel ID <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#channel-id" />
							</h4>
							<span>The channel ID you want to edit</span>
							<VariableTextInput
								slash_options={this.props.options}
								label="ID"
								placeholder="911634771019444224"
								value={this.props.data.target.id}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										id: value
									};

									this.props.updateElementData({ data: data, id: this.props.id });
								}}
							/>
							{/* <div className="long-input mt-15">
                            <label>ID</label>
                            <input className={this.props.saveFailed && this.props.data.target.variable == "" ? "command-required" : ""} onChange={(e) => {
                                var data = { ...this.props.data };
                                data.target = {
                                    id: e.target.value
                                };
                                this.props.updateElementData({ data: data, id: this.props.id });
                            }} type="text" placeholder="911634771019444224" value={this.props.data.target.id} />
                        </div> */}
						</div>
					)}

					<div className="mb-15 slash-action">
						<h4>
							Name <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#name" />
						</h4>
						<span>An optional name for the edited channel.</span>
						<VariableTextInput
							slash_options={this.props.options}
							label="Name"
							placeholder="new-general"
							value={this.props.data.name}
							onChange={(value) => {
								this.updateData("name", value);
							}}
						/>
						{/* <div class="long-input mt-15">
                            <label>Name</label>
                            <input className={``} onChange={(e) => {
                                this.updateData("name", e.target.value);
                            }} minLength={1} maxLength={100} type="text" value={this.props.data.name} placeholder="new-general" />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Topic <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#topic" />
						</h4>
						<span>
							An optional topic for the edited channel. <span style={{ color: "yellow" }}>[This only works for Text Channels]</span>
						</span>
						<VariableTextInput
							slash_options={this.props.options}
							label="Topic"
							placeholder="General is a nice place to chat with fellow server members"
							value={this.props.data.topic}
							onChange={(value) => {
								this.updateData("topic", value);
							}}
						/>
						{/* <div class="long-input mt-15">
                            <label>Topic</label>
                            <input onChange={(e) => {
                                this.updateData("topic", e.target.value);
                            }} maxLength={1024} type="text" value={this.props.data.topic} placeholder="General is a nice place to chat with fellow server members" />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Category <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#category" />
						</h4>
						<span>An optional category to move the edited channel to. An Id or Option can be used.</span>
						<VariableTextInput
							slash_options={this.props.options}
							label="Category"
							placeholder="{option_category}"
							value={this.props.data.parent}
							onChange={(value) => {
								this.updateData("parent", value);
							}}
						/>
						{/* <div class="long-input mt-15">
                            <label>Category</label>
                            <input onChange={(e) => {
                                this.updateData("parent", e.target.value);
                            }} maxLength={250} type="text" value={this.props.data.parent} placeholder="{option_category}" />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Slowmode <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#slowmode" />
						</h4>
						<span>
							An optional rate limit (in seconds) that you want to set the edited channel to. <span style={{ color: "yellow" }}>[This only works for Text Channels]</span>
						</span>
						<VariableTextInput
							slash_options={this.props.options}
							label="Slowmode"
							placeholder="3"
							value={this.props.data.rateLimitPerUser}
							onChange={(value) => {
								this.updateData("rateLimitPerUser", value);
							}}
						/>
						{/* <div class="long-input mt-15">
                            <label>Slowmode</label>
                            <input onChange={(e) => {
                                this.updateData("rateLimitPerUser", e.target.value);
                            }} type="number" min={0} max={21600} value={this.props.data.rateLimitPerUser} placeholder={3} />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>
							NSFW <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#nsfw" />
						</h4>
						<span>
							An optional toggle to set the channel to NSFW (not safe for work) or not. <span style={{ color: "yellow" }}>[This only works for Text Channels]</span>
						</span>
						<Toggle
							value={this.props.data.nsfw}
							update={(value) => {
								this.updateData("nsfw", value);
							}}
						/>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							User Limit <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#user-limit" />
						</h4>
						<span>
							An optional limit to set the size of the voice channel. <span style={{ color: "yellow" }}>[This only works for Voice Channels]</span>
						</span>
						<VariableTextInput
							slash_options={this.props.options}
							label="User Limit"
							placeholder="3"
							value={this.props.data.userLimit}
							onChange={(value) => {
								this.updateData("userLimit", value);
							}}
						/>
						{/* <div class="long-input mt-15">
                            <label>User Limit</label>
                            <input onChange={(e) => {
                                this.updateData("userLimit", e.target.value);
                            }} type="number" min={0} max={21600} value={this.props.data.userLimit} placeholder={3} />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Optional Server ID <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#optional-server-id" />
						</h4>
						<span>An optional server Id to search for the channel in. An Id or Variable can be used. Leave this blank to search for the channel in the server this {this.props.mode == "event" ? "event" : "command"} was triggered in.</span>
						<VariableTextInput
							slash_options={this.props.options}
							label="Server ID"
							placeholder="822426820447567872"
							value={this.props.data.guild_id}
							onChange={(value) => {
								this.updateData("guild_id", value);
							}}
						/>
						{/* <div class="long-input mt-15">
                            <label>ID or Variable</label>
                            <input onChange={(e) => {
                                this.updateData("guild_id", e.target.value);
                            }} maxLength={250} type="text" value={this.props.data.guild_id} placeholder="822426820447567872" />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Reason <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#reason" />{" "}
						</h4>
						<span>An optional reason the channel is being edited.</span>
						<VariableTextInput
							slash_options={this.props.options}
							label="Reason"
							placeholder="Automod"
							value={this.props.data.reason}
							onChange={(value) => {
								this.updateData("reason", value);
							}}
						/>
						{/* <div className="long-input mt-15">
                            <label>Reason</label>
                            <input onChange={(e) => {
                                this.updateData("reason", e.target.value);
                            }} maxLength="250" type="text" value={this.props.data.reason} placeholder="Automod" />
                        </div> */}
					</div>

					<hr class="slash-hr" style={{ borderTop: "1px solid rgb(173, 181, 189)" }} />

					<div className="mb-15 slash-action">
						<h4>
							Merge Permissions <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#mergePermissions" />
						</h4>
						<span>
							Do you want to merge the permissions with the selected channel? <span style={{ color: "yellow" }}>[When enabled, the selected permissions will be merged with the already existing permissions of the channel. When disabled, permissions will be overridden]</span>
						</span>
						<Toggle
							value={this.props.data.mergePermissions}
							update={(value) => {
								this.updateData("mergePermissions", value);
							}}
						/>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Permissions <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#permissions" />
						</h4>
						<span>Add Role and User permisions to this new channel. You can use User and Role options for any permission.</span>
						<button
							class="btn btn-red add-button mt-15"
							onClick={() => {
								var permissions = [...this.props.data.permissions];
								permissions.push({
									type: "role",
									id: {
										id: ""
									},
									allow: [],
									deny: []
								});
								this.updateData("permissions", permissions);
							}}
						>
							Add Permission
						</button>
					</div>
					<div>{this.renderPermissions()}</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	bot_data: state.data.bot_data,
	saveFailed: state.builder.saveFailed,
	components: state.builder.components,
	beta: state.data.beta
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelEditAction);
