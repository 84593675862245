import React, { Component } from 'react';
import { connect } from 'react-redux';

var api_example = {
    "variables": [{
        "name": "message",
        "variable": "{event_message}",
        "value": "HELLO from webhooks"
    }]
};

export class IFTTTItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            name: ""
        };
    }

    componentDidMount() {
        if (this.props.index == "new") {
            var id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            this.setState({ id: id });
        } else {
            Object.keys(this.props.webhook).forEach((key) => {
                this.setState({ [key]: this.props.webhook[key] });
            });
        }
    }

    render() {
        return (
            <div>
                <hr class="slashcommand-hr" />

                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div>
                        <div className={`mb-15`}>
                            <div class="">
                                <h3 style={{ marginBottom: "0px" }}>Event id</h3>

                                <div className="section-content-header mb-2">The event id to pass along to the HTTP request</div>
                                <div className={`long-input ${this.props.showIcon ? "long-input-error" : ""}`}>
                                    <label>Event ID</label>
                                    <input
                                        required
                                        type={"text"}
                                        value={this.state.id}
                                        disabled={true}
                                        // maxLength={this.props.settings.maxLength}
                                        // placeholder={this.props.settings.placeholder || ""}
                                        onChange={(e) => {
                                            this.props.change(e.target.value);
                                        }}
                                    ></input>
                                </div>
                            </div>
                        </div>

                        <div className={`mb-15`}>
                            <div class="">
                                <h3 style={{ marginBottom: "0px" }}>Event name</h3>

                                <div className="section-content-header mb-2">A descriptive name for the event</div>
                                <div className={`long-input ${this.props.showIcon ? "long-input-error" : ""}`}>
                                    <label>Name</label>
                                    <input
                                        required
                                        type={"text"}
                                        value={this.state.name}
                                        // maxLength={this.props.settings.maxLength}
                                        // placeholder={this.props.settings.placeholder || ""}
                                        onChange={(e) => {
                                            this.setState({
                                                name: e.target.value
                                            });
                                        }}
                                    ></input>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-[0px] md:p-2 lg:p-4">
                        <div class="not-prose my-6 overflow-hidden rounded-2xl bg-[#27272a] shadow-md dark:ring-1 dark:ring-white/10">
                            <div class="flex min-h-[calc(theme(spacing.12)+1px)] flex-wrap items-start gap-x-4 border-b border-[#565656]  bg-[#27272a] px-4 dark:border-zinc-800 dark:bg-transparent">
                                <h3 class="mr-auto pt-3 text-xs font-semibold text-white">Example request (POST)</h3>
                            </div>
                            <div class="bg-[#27272a]">
                                <div class="relative">
                                    <pre class="overflow-x-auto p-4 text-xs text-white bg-[#27272a]">
                                        <code class="language-bash flex flex-col">
                                            <span>
                                                <span >curl https://api.botghost.com/webhook/{this.props.bot.id}/{this.state.id} \</span>
                                            </span>

                                            <span>
                                                <span > -X POST \</span>
                                            </span>
                                            <span>
                                                <span > -H </span>
                                                <span className="text-green">"Authorization: API_KEY"</span>
                                            </span>
                                            <span>
                                                <span > -d </span>
                                                <span >'{JSON.stringify(api_example)}'</span>
                                            </span>
                                        </code>
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="slashcommand-hr" />
                <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "auto" }}>
                        {this.props.index != "new" ? (
                            <button
                                style={{ marginRight: "15px" }}
                                onClick={() => {
                                    this.props.delete(this.props.index);
                                }}
                                className="btn btn-gray mt-15 mr-15"
                                type="button"
                            >
                                Delete
                            </button>
                        ) : null}
                        <button
                            className="btn btn-red mt-15"
                            disabled={this.state.name == ""}
                            onClick={() => {
                                this.props.onChange(this.state, this.props.index);
                                if (this.props.index == "new") {
                                    var id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                                    this.setState({
                                        id: id,
                                        name: ""
                                    });
                                }
                            }}
                        >
                            {this.props.index == "new" ? "Add Webhook Event" : "Edit Webhook Event"}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IFTTTItem);