import { faDollarSign, faCopy, faFileImport, faCode, faCodeBranch, faEnvelope, faEye, faHashtag, faMouse, faMousePointer, faPaperPlane, faReply, faReplyAll, faSortNumericDown, faTextHeight, faUser, faUserMinus, faUserPlus, faUsers, faQuestion, faComment, faCommentSlash, faHourglass, faPercent, faShoppingCart, faDatabase, faGreaterThanEqual, faFile, faExclamationTriangle, faLock, faEquals } from '@fortawesome/free-solid-svg-icons';
import { ConsoleView } from 'react-device-detect';
import { addEdge } from 'react-flow-renderer';
import { ACTION_TYPES } from '../../../variables';
import { copy } from 'copy-anything';

var types = [
    { icon: faTextHeight, title: "Text", description: "A plain text option", type: "string" },
    { icon: faSortNumericDown, title: "Number", description: "A number option", type: "int" },
    { icon: faUser, title: "User", description: "Select a member from the server", type: "user" },
    { icon: faHashtag, title: "Channel", description: "Select a channel from the server", type: "channel" },
    { icon: faUsers, title: "Role", description: "Select a role from the server", type: "role" },
    { icon: faQuestion, title: "Choice", description: "A True or False option", type: "boolean" },
    { icon: faFile, title: "Attachment", description: "An attachment option", type: "attachment" },
];

var CONDITION_TYPES = [
    { icon: faQuestion, title: "Options Condition", description: "Run actions based on option values", condition_type: "option" },
    { icon: faPercent, title: "Chance Condition", description: "Set a percent chance for actions to run", condition_type: "chance" },
    { icon: faHashtag, title: "Channel Condition", description: "Run actions based on the channel the command was used in", condition_type: "channel" },
    { icon: faUsers, title: "Role Condition", description: "Run actions based on the roles of the user.", condition_type: "role" },
    { icon: faUser, title: "User Condition", description: "Run actions based on who used the command", condition_type: "user" },
    { icon: faDollarSign, title: "Currency Condition", description: "Run actions based on the currency balance of the user", condition_type: "currency" },
    { icon: faShoppingCart, title: "Item Condition", description: "Run actions based on the inventory of the user", condition_type: "item" },
    { icon: faDatabase, title: "Custom Variable Condition", description: "Run actions based on the value of a custom variable", condition_type: "custom_variable" },
    { icon: faGreaterThanEqual, title: "Comparison Condition", description: "Run actions based on the difference between two values.", condition_type: "comparison" },
    { icon: faLock, title: "Permissions Condition", description: "Run actions based on the server permissions of a user.", condition_type: "permissions" },
    // { icon: faEquals, title: "Regex Match Condition", description: "Run actions based on wether a value matches a regex", condition_type: "regexMatch" }
];

// export default async function buildExistingCommand(command){



export default async function buildExistingCommand(command, mode, errorMode) {
    if (!("cooldown" in command) && mode != "event") {
        command.cooldown = {
            type: "disabled",
            interval: 1,
            interval_type: "minutes"
        };
    }
    if (mode == "event" && !errorMode) {
        var elements = [
            {
                id: 'root',
                type: 'root',
                data: {
                    event: true,
                    enabled: true,
                    data: {
                        name: command.name,
                        type: command.type,
                    }
                },
                // target:"3",
                draggable: false,
                position: { x: 250, y: 250 },
            },
        ];
    } else if (mode == "command" && !errorMode) {
        var elements = [
            {
                id: 'root',
                type: 'root',
                data: {
                    title: command.name,
                    description: command.description,
                    data: {
                        ephemeral: command.ephemeral,
                        permissions: command.permissions,
                        cooldown: command.cooldown,
                        handleErrors: command.handleErrors,
                        errors: command.errors
                    }
                },
                // target:"3",
                draggable: false,
                position: { x: 250, y: 250 },
            },
        ];
    } else {
        var elements = [{
            id: "error_handler",
            type: "error",
            position: {
                x: 500,
                y: 500
            },
            draggable: false,
            data: {
                node_options: {
                    title: "Error Handler",
                    description: `Handle errors that occur during the ${mode} execution`,
                    icon: faExclamationTriangle
                },
                data: {}
            }
        }];
    }

    //   Add Options
    command.options.forEach((option, index) => {
        var node_options = types.find(t => t.type == option.type);
        var id = `option_${index + 1}_${s4()}`;
        var element = {
            id: id,
            type: "option",
            position: {
                x: 250,
                y: 250
            },
            draggable: true,
            data: {
                node_options: node_options,
                data: { ...option, validated: true },
            }
        };

        elements = addEdge({
            source: id,
            target: "root",
            type: "step",
            animated: false,
            arrowHeadType: "arrowclosed"

        }, elements);
        elements.push(element);
    });
    var previousNode = elements[0].id;
    command.actions.forEach((action, index) => {
        var actionData = copy(action);
        delete actionData.actions;
        delete actionData.buttons;
        delete actionData.conditions;
        delete actionData.options;
        delete actionData.else;

        if (action.type == "condition") {
            var id = `condition_${elements.length + 1}_${s4()}_${s4()}`;
            var node_options = CONDITION_TYPES.find(t => t.condition_type == action.condition_type);

            var id = 'nodeID' in action ? action.nodeID : `condition_${new Date().getTime()}_${s4()}_${s4()}`;
            var alreadyExists = false;
            if ("nodeID" in action) {
                var elementCheck = elements.findIndex(e => e.id == action.nodeID);
                if (elementCheck != -1) {
                    var id = action.nodeID;
                    alreadyExists = true;
                } else {
                    var element = {
                        id: id,
                        type: "condition",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...actionData, validated: true },
                        }
                    };
                    elements.push(element);
                }
            } else {

                var element = {
                    id: id,
                    type: "condition",
                    position: {
                        x: 250,
                        y: 250
                    },
                    draggable: true,
                    data: {
                        node_options: node_options,
                        data: { ...actionData, validated: true },
                    }
                };
                elements.push(element);
            }
            elements = addEdge({
                source: previousNode,
                target: id,
                type: "custom",
                animated: false,
                arrowHeadType: "arrowclosed"

            }, elements);
            previousNode = id;
            elements = createConditionAction(action, id, elements, alreadyExists);
        } else {
            if (action.type == 'comparison') {
                action.type = 'condition';
                action.condition_type = 'comparison';
            }
            var node_options = ACTION_TYPES.find(t => t.value == action.type);
            var id = 'nodeID' in action ? action.nodeID : `action_${new Date().getTime()}_${s4()}_${s4()}`;
            if ("nodeID" in action) {
                var elementCheck = elements.findIndex(e => e.id == action.nodeID);
                if (elementCheck != -1) {
                    var id = action.nodeID;
                } else {
                    node_options.description = node_options.label;
                    var element = {
                        id: id,
                        type: "action",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...actionData, validated: true },
                        }
                    };
                    elements.push(element);
                }
            } else {
                node_options.description = node_options.label;
                var element = {
                    id: id,
                    type: "action",
                    position: {
                        x: 250,
                        y: 250
                    },
                    draggable: true,
                    data: {
                        node_options: node_options,
                        data: { ...actionData, validated: true },
                    }
                };
                elements.push(element);
            }

            elements = addEdge({
                source: previousNode,
                target: id,
                type: "custom",
                animated: false,
                arrowHeadType: "arrowclosed"

            }, elements);
            previousNode = id;

            if (action.type == "button_response") {
                elements = createButtonAction(action, id, elements);
            } else if (action.type == "select_menu") {
                elements = createSelectMenuAction(action, id, elements);
            }
        }
    });
    return elements;
}
function createConditionAction(action, parentId, elements, alreadyExists) {
    if ("nodeID" in action.else) {
        var elseID = 'nodeID' in action.else ? action.else.nodeID : `else_${elements.length + 1}_${s4()}_${s4()}`;
        if ("nodeID" in action.else) {
            var elementCheck = elements.findIndex(e => e.id == action.else.nodeID);
            if (elementCheck != -1) {
                var elseID = action.else.nodeID;
            } else {
                // console.log("elseID", elseID);
                var element = {
                    id: elseID,
                    type: "conditionChild",
                    position: {
                        x: 250,
                        y: 250
                    },
                    draggable: true,
                    data: {
                        node_options: {
                            type: action.condition_type
                        },
                        data: {
                            type: "else",
                            option: action.option,
                            actions: []
                        }
                    }
                };
                // console.log("element", element, elseID, 'elseID');
                elements.push(element);
                elements = addEdge({
                    source: parentId,
                    target: elseID,
                    type: "step",
                    animated: false,
                    arrowHeadType: "arrowclosed"
                }, elements);
            }
        } else {
            var element = {
                id: elseID,
                type: "conditionChild",
                position: {
                    x: 250,
                    y: 250
                },
                draggable: true,
                data: {
                    node_options: {
                        type: action.condition_type
                    },
                    data: {
                        type: "else",
                        option: action.option,
                        actions: []
                    }
                }
            };
            elements.push(element);
            elements = addEdge({
                source: parentId,
                target: elseID,
                type: "step",
                animated: false,
                arrowHeadType: "arrowclosed"
            }, elements);
        }
    } else {
        var elseID = `else_${elements.length + 1}_${s4()}_${s4()}`;
        var element = {
            id: elseID,
            type: "conditionChild",
            position: {
                x: 250,
                y: 250
            },
            draggable: true,
            data: {
                node_options: {
                    type: action.condition_type
                },
                data: {
                    type: "else",
                    option: action.option,
                    actions: []
                }
            }
        };
        elements.push(element);
        elements = addEdge({
            source: parentId,
            target: elseID,
            type: "step",
            animated: false,
            arrowHeadType: "arrowclosed"
        }, elements);
    }


    var previousElseConID = elseID;
    action.else.actions.forEach((conditionAction, index) => {
        var conditionActionData = copy(conditionAction);
        delete conditionActionData.actions;
        delete conditionActionData.buttons;
        delete conditionActionData.conditions;
        delete conditionActionData.options;
        delete conditionActionData.else;
        if (conditionAction.type == "condition") {
            var node_options = CONDITION_TYPES.find(t => t.condition_type == conditionAction.condition_type);
            var id = 'nodeID' in conditionAction ? conditionAction.nodeID : `condition_${new Date().getTime()}_${s4()}_${s4()}`;
            if ("nodeID" in conditionAction) {
                var elementCheck = elements.findIndex(e => e.id == conditionAction.nodeID);
                if (elementCheck != -1) {
                    var id = conditionAction.nodeID;
                } else {
                    var element = {
                        id: id,
                        type: "condition",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...conditionActionData, validated: true },
                        }
                    };
                    elements.push(element);
                }
            } else {
                var element = {
                    id: id,
                    type: "condition",
                    position: {
                        x: 250,
                        y: 250
                    },
                    draggable: true,
                    data: {
                        node_options: node_options,
                        data: { ...conditionActionData, validated: true },
                    }
                };
                elements.push(element);
            }

            elements = addEdge({
                source: previousElseConID,
                target: id,
                type: "custom",
                animated: false,
                arrowHeadType: "arrowclosed"

            }, elements);
            elements = createConditionAction(conditionAction, id, elements);
        } else {

            // Condition ELSE 
            var node_options = ACTION_TYPES.find(t => t.value == conditionAction.type);
            node_options.description = node_options.label;
            if (conditionAction.type == 'comparison') {
                conditionAction.type = 'condition';
                conditionAction.condition_type = 'comparison';
            }

            var conditionActionID = 'nodeID' in conditionAction ? conditionAction.nodeID : `action_${new Date().getTime()}_${s4()}_${s4()}`;
            if ("nodeID" in conditionAction) {
                var elementCheck = elements.findIndex(e => e.id == conditionAction.nodeID);
                if (elementCheck != -1) {
                    conditionActionID = conditionAction.nodeID;
                } else {
                    // ELEMENT
                    node_options.description = node_options.label;
                    var element = {
                        id: conditionActionID,
                        type: "action",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...conditionActionData, validated: true },
                        }
                    };
                    elements.push(element);
                }
            } else {
                // ELEMENT
                node_options.description = node_options.label;
                var element = {
                    id: conditionActionID,
                    type: "action",
                    position: {
                        x: 250,
                        y: 250
                    },
                    draggable: true,
                    data: {
                        node_options: node_options,
                        data: { ...conditionActionData, validated: true },
                    }
                };
                elements.push(element);
            }


            elements = addEdge({
                source: previousElseConID,
                target: conditionActionID,
                type: "custom",
                animated: false,
                arrowHeadType: "arrowclosed"

            }, elements);
            previousElseConID = conditionActionID;
            if (conditionAction.type == "button_response") {
                elements = createButtonAction(conditionAction, conditionActionID, elements);
            } else if (conditionAction.type == "select_menu") {
                elements = createSelectMenuAction(conditionAction, conditionActionID, elements);
            }
        }

    });

    action.conditions.forEach((condition, index) => {
        var conditionData = copy(condition);
        delete conditionData.actions;
        delete conditionData.buttons;
        delete conditionData.conditions;
        delete conditionData.options;
        delete conditionData.else;
        var conChild_id = `conChild${elements.length + 1}_${s4()}_${s4()}`;
        if ("nodeID" in condition) {
            var conChild_id = condition.nodeID;
            if ("nodeID" in action.else) {
                var elementCheck = elements.findIndex(e => e.id == condition.nodeID);
                if (elementCheck != -1) {
                    var conChild_id = condition.nodeID;
                } else {
                    var element = {
                        id: conChild_id,
                        type: "conditionChild",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: {
                                type: action.condition_type
                            },
                            data: conditionData
                        }
                    };

                    elements.push(element);
                    elements = addEdge({
                        source: parentId,
                        target: conChild_id,
                        type: "step",
                        animated: false,
                        arrowHeadType: "arrowclosed"
                    }, elements);
                }
            } else {
                var element = {
                    id: conChild_id,
                    type: "conditionChild",
                    position: {
                        x: 250,
                        y: 250
                    },
                    draggable: true,
                    data: {
                        node_options: {
                            type: action.condition_type
                        },
                        data: conditionData
                    }
                };

                elements.push(element);
                elements = addEdge({
                    source: parentId,
                    target: conChild_id,
                    type: "step",
                    animated: false,
                    arrowHeadType: "arrowclosed"
                }, elements);
            }
        } else {
            var element = {
                id: conChild_id,
                type: "conditionChild",
                position: {
                    x: 250,
                    y: 250
                },
                draggable: true,
                data: {
                    node_options: {
                        type: action.condition_type
                    },
                    data: conditionData
                }
            };

            elements.push(element);
            elements = addEdge({
                source: parentId,
                target: conChild_id,
                type: "step",
                animated: false,
                arrowHeadType: "arrowclosed"
            }, elements);
        }


        var previousConditionChildNode = conChild_id;
        condition.actions.forEach((conditionAction, index) => {
            var conditionChildActionData = copy(conditionAction);
            delete conditionChildActionData.actions;
            delete conditionChildActionData.buttons;
            delete conditionChildActionData.conditions;
            delete conditionChildActionData.options;
            delete conditionChildActionData.else;
            if (conditionAction.type == "condition") {


                var node_options = CONDITION_TYPES.find(t => t.condition_type == conditionAction.condition_type);
                var id = 'nodeID' in conditionAction ? conditionAction.nodeID : `condition_${new Date().getTime()}_${s4()}_${s4()}`;
                if ("nodeID" in conditionAction) {
                    var elementCheck = elements.findIndex(e => e.id == conditionAction.nodeID);
                    if (elementCheck != -1) {
                        var id = conditionAction.nodeID;
                    } else {

                        var element = {
                            id: id,
                            type: "condition",
                            position: {
                                x: 250,
                                y: 250
                            },
                            draggable: true,
                            data: {
                                node_options: node_options,
                                data: { ...conditionChildActionData, validated: true },
                            }
                        };
                        elements.push(element);
                    }
                } else {
                    var element = {
                        id: id,
                        type: "condition",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...conditionChildActionData, validated: true },
                        }
                    };
                    elements.push(element);
                }
                elements = addEdge({
                    source: previousConditionChildNode,
                    target: id,
                    type: "custom",
                    animated: false,
                    arrowHeadType: "arrowclosed"

                }, elements);
                elements = createConditionAction(conditionAction, id, elements);

            } else {
                // var conditionActionID = `action_${elements.length + 1}`;
                if (conditionAction.type == 'comparison') {
                    conditionAction.type = 'condition';
                    conditionAction.condition_type = 'comparison';
                }


                var node_options = ACTION_TYPES.find(t => t.value == conditionAction.type);

                node_options.description = node_options.label;
                var conditionActionID = 'nodeID' in conditionAction ? conditionAction.nodeID : `action_${new Date().getTime()}_${s4()}_${s4()}`;
                if ("nodeID" in conditionAction) {
                    var elementCheck = elements.findIndex(e => e.id == conditionAction.nodeID);
                    if (elementCheck != -1) {
                        conditionActionID = conditionAction.nodeID;
                    } else {
                        // ELEMENT
                        node_options.description = node_options.label;
                        var element = {
                            id: conditionActionID,
                            type: "action",
                            position: {
                                x: 250,
                                y: 250
                            },
                            draggable: true,
                            data: {
                                node_options: node_options,
                                data: { ...conditionChildActionData, validated: true },
                            }
                        };
                        elements.push(element);
                    }
                } else {
                    // ELEMENT
                    node_options.description = node_options.label;
                    var element = {
                        id: conditionActionID,
                        type: "action",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...conditionChildActionData, validated: true },
                        }
                    };
                    elements.push(element);
                }

                elements = addEdge({
                    source: previousConditionChildNode,
                    target: conditionActionID,
                    type: "custom",
                    animated: false,
                    arrowHeadType: "arrowclosed"

                }, elements);
                previousConditionChildNode = conditionActionID;
                if (conditionAction.type == "button_response") {
                    elements = createButtonAction(conditionAction, conditionActionID, elements);
                } else if (conditionAction.type == "select_menu") {
                    elements = createSelectMenuAction(conditionAction, conditionActionID, elements);
                }
            }

        });
    });

    return elements;
}
function createButtonAction(action, parentId, elements) {
    action.buttons.forEach((button, index) => {
        var button_id = 'nodeID' in button ? button.nodeID : `button_${new Date().getTime()}_${s4()}_${s4()}`;
        if ("nodeID" in button) {
            var elementCheck = elements.findIndex(e => e.id == button.nodeID);
            if (elementCheck != -1) {
                var button_id = button.nodeID;
            } else {
                var element = {
                    id: button_id,
                    type: "button",
                    position: {
                        x: 250,
                        y: 250
                    },
                    draggable: true,
                    data: {
                        node_options: {
                            label: button.label,
                            style: button.style
                        },
                        data: {
                            label: button.label,
                            style: button.style,
                            // actions: button.actions,
                            disableOnUse: "disableOnUse" in button ? button.disableOnUse : null,
                            disableAfter: "disableAfter" in button ? button.disableAfter : null,
                            disableText: "disableText" in button ? button.disableText : null,
                            disableStyle: "disableStyle" in button ? button.disableStyle : null,
                            cooldownEnabled: "cooldownEnabled" in button ? button.cooldownEnabled : null,
                            cooldownType: "cooldownType" in button ? button.cooldownType : null,
                            cooldownDuration: "cooldownDuration" in button ? button.cooldownDuration : null,

                            validated: true
                        },
                    }
                };
                if (button.style == "LINK") {
                    element.data.data.URL = button.URL;
                }
                elements.push(element);
                elements = addEdge({
                    source: parentId,
                    target: button_id,
                    type: "step",
                    animated: false,
                    arrowHeadType: "arrowclosed"
                }, elements);
            }
        } else {
            var element = {
                id: button_id,
                type: "button",
                position: {
                    x: 250,
                    y: 250
                },
                draggable: true,
                data: {
                    node_options: {
                        label: button.label,
                        style: button.style
                    },
                    data: {
                        label: button.label,
                        style: button.style,
                        // actions: button.actions,
                        disableOnUse: "disableOnUse" in button ? button.disableOnUse : null,
                        disableAfter: "disableAfter" in button ? button.disableAfter : null,
                        disableText: "disableText" in button ? button.disableText : null,
                        disableStyle: "disableStyle" in button ? button.disableStyle : null,
                        cooldownEnabled: "cooldownEnabled" in button ? button.cooldownEnabled : null,
                        cooldownType: "cooldownType" in button ? button.cooldownType : null,
                        cooldownDuration: "cooldownDuration" in button ? button.cooldownDuration : null,

                        validated: true
                    },
                }
            };
            if (button.style == "LINK") {
                element.data.data.URL = button.URL;
            }
            elements.push(element);
            elements = addEdge({
                source: parentId,
                target: button_id,
                type: "step",
                animated: false,
                arrowHeadType: "arrowclosed"
            }, elements);
        }





        var previousButtonNode = button_id;
        button.actions.forEach((buttonAction, index) => {
            var buttonActionData = copy(buttonAction);
            delete buttonActionData.actions;
            delete buttonActionData.buttons;
            delete buttonActionData.conditions;
            delete buttonActionData.options;
            delete buttonActionData.else;
            if (buttonAction.type == "condition") {

                var node_options = CONDITION_TYPES.find(t => t.condition_type == buttonAction.condition_type);

                var id = 'nodeID' in buttonAction ? buttonAction.nodeID : `condition_${new Date().getTime()}_${s4()}_${s4()}`;
                if ("nodeID" in buttonAction) {
                    var elementCheck = elements.findIndex(e => e.id == buttonAction.nodeID);
                    if (elementCheck != -1) {
                        var id = buttonAction.nodeID;
                    } else {
                        var element = {
                            id: id,
                            type: "condition",
                            position: {
                                x: 250,
                                y: 250
                            },
                            draggable: true,
                            data: {
                                node_options: node_options,
                                data: { ...buttonActionData, validated: true },
                            }
                        };
                        elements.push(element);
                    }
                } else {

                    var element = {
                        id: id,
                        type: "condition",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...buttonActionData, validated: true },
                        }
                    };
                    elements.push(element);
                }

                elements = addEdge({
                    source: previousButtonNode,
                    target: id,
                    type: "custom",
                    animated: false,
                    arrowHeadType: "arrowclosed"

                }, elements);
                elements = createConditionAction(buttonAction, id, elements);

            } else {
                var node_options = ACTION_TYPES.find(t => t.value == buttonAction.type);
                if (buttonAction.type == 'comparison') {
                    buttonAction.type = 'condition';
                    buttonAction.condition_type = 'comparison';
                }

                var buttonActionid = 'nodeID' in buttonAction ? buttonAction.nodeID : `button_action_${new Date().getTime()}_${s4()}_${s4()}`;
                if ("nodeID" in buttonAction) {
                    var elementCheck = elements.findIndex(e => e.id == buttonAction.nodeID);
                    if (elementCheck != -1) {
                        buttonActionid = buttonAction.nodeID;
                    } else {
                        node_options.description = node_options.label;
                        var element = {
                            id: buttonActionid,
                            type: "action",
                            position: {
                                x: 250,
                                y: 250
                            },
                            draggable: true,
                            data: {
                                node_options: node_options,
                                data: { ...buttonActionData, validated: true },
                            }
                        };
                        elements.push(element);
                    }
                } else {
                    node_options.description = node_options.label;
                    var element = {
                        id: buttonActionid,
                        type: "action",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...buttonActionData, validated: true },
                        }
                    };
                    elements.push(element);
                }


                elements = addEdge({
                    source: previousButtonNode,
                    target: buttonActionid,
                    type: "custom",
                    animated: false,
                    arrowHeadType: "arrowclosed"

                }, elements);
                previousButtonNode = buttonActionid;
                if (buttonAction.type == "button_response") {
                    elements = createButtonAction(buttonAction, buttonActionid, elements);
                } else if (buttonAction.type == "select_menu") {
                    elements = createSelectMenuAction(buttonAction, buttonActionid, elements);
                }
            }
        });
    });
    return elements;
}

function createSelectMenuAction(action, parentId, elements) {
    action.options.forEach((menuOption, index) => {
        // var menuOption_id = `select_menu_option_${elements.length + 1}`;
        var menuOption_id = 'nodeID' in menuOption ? menuOption.nodeID : `select_menu_option_${new Date().getTime()}_${s4()}_${s4()}`;
        if ("nodeID" in menuOption) {
            var elementCheck = elements.findIndex(e => e.id == menuOption.nodeID);
            if (elementCheck != -1) {
                var menuOption_id = menuOption.nodeID;
            } else {
                var element = {
                    id: menuOption_id,
                    type: "selectMenuOption",
                    position: {
                        x: 250,
                        y: 250
                    },
                    draggable: true,
                    data: {
                        node_options: {

                        },
                        data: {
                            label: menuOption.label,
                            description: menuOption.description,
                            validated: true
                        },
                    }
                };

                elements.push(element);
                elements = addEdge({
                    source: parentId,
                    target: menuOption_id,
                    type: "step",
                    animated: false,
                    arrowHeadType: "arrowclosed"
                }, elements);
            }
        } else {
            var element = {
                id: menuOption_id,
                type: "selectMenuOption",
                position: {
                    x: 250,
                    y: 250
                },
                draggable: true,
                data: {
                    node_options: {

                    },
                    data: {
                        label: menuOption.label,
                        description: menuOption.description,
                        validated: true
                    },
                }
            };

            elements.push(element);
            elements = addEdge({
                source: parentId,
                target: menuOption_id,
                type: "step",
                animated: false,
                arrowHeadType: "arrowclosed"
            }, elements);
        }


        // 


        var previousButtonNode = menuOption_id;
        menuOption.actions.forEach((menuOptionAction, index) => {
            var menuOptionActionData = copy(menuOptionAction);
            delete menuOptionActionData.actions;
            delete menuOptionActionData.buttons;
            delete menuOptionActionData.conditions;
            delete menuOptionActionData.options;
            delete menuOptionActionData.else;
            if (menuOptionAction.type == "condition") {
                // var id = `condition_${elements.length + 1}_${s4()}_${s4()}`;
                var node_options = CONDITION_TYPES.find(t => t.condition_type == menuOptionAction.condition_type);
                var id = 'nodeID' in menuOptionAction ? menuOptionAction.nodeID : `condition_${new Date().getTime()}_${s4()}_${s4()}`;
                if ("nodeID" in menuOptionAction) {
                    var elementCheck = elements.findIndex(e => e.id == menuOptionAction.nodeID);
                    if (elementCheck != -1) {
                        var id = menuOptionAction.nodeID;
                    } else {
                        var element = {
                            id: id,
                            type: "condition",
                            position: {
                                x: 250,
                                y: 250
                            },
                            draggable: true,
                            data: {
                                node_options: node_options,
                                data: { ...menuOptionActionData, validated: true },
                            }
                        };

                        elements.push(element);
                    }
                } else {
                    var element = {
                        id: id,
                        type: "condition",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...menuOptionActionData, validated: true },
                        }
                    };

                    elements.push(element);
                }


                elements = addEdge({
                    source: previousButtonNode,
                    target: id,
                    type: "custom",
                    animated: false,
                    arrowHeadType: "arrowclosed"

                }, elements);
                elements = createConditionAction(menuOptionAction, id, elements);

            } else {
                if (menuOptionAction.type == 'comparison') {
                    menuOptionAction.type = 'condition';
                    menuOptionAction.condition_type = 'comparison';
                }

                var menuOptionActionid = 'nodeID' in menuOptionAction ? menuOptionAction.nodeID : `menu_action_${new Date().getTime()}_${s4()}_${s4()}`;
                if ("nodeID" in menuOptionAction) {
                    var elementCheck = elements.findIndex(e => e.id == menuOptionAction.nodeID);
                    if (elementCheck != -1) {
                        menuOptionActionid = menuOptionAction.nodeID;
                    } else {
                        var node_options = ACTION_TYPES.find(t => t.value == menuOptionAction.type);
                        node_options.description = node_options.label;
                        var element = {
                            id: menuOptionActionid,
                            type: "action",
                            position: {
                                x: 250,
                                y: 250
                            },
                            draggable: true,
                            data: {
                                node_options: node_options,
                                data: { ...menuOptionActionData, validated: true },
                            }
                        };
                        elements.push(element);
                    }
                } else {
                    var node_options = ACTION_TYPES.find(t => t.value == menuOptionAction.type);
                    node_options.description = node_options.label;
                    var element = {
                        id: menuOptionActionid,
                        type: "action",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...menuOptionActionData, validated: true },
                        }
                    };
                    elements.push(element);
                }

                elements = addEdge({
                    source: previousButtonNode,
                    target: menuOptionActionid,
                    type: "custom",
                    animated: false,
                    arrowHeadType: "arrowclosed"

                }, elements);
                previousButtonNode = menuOptionActionid;
                if (menuOptionAction.type == "button_response") {
                    elements = createButtonAction(menuOptionAction, menuOptionActionid, elements);
                } else if (menuOptionAction.type == "select_menu") {
                    elements = createSelectMenuAction(menuOptionAction, menuOptionActionid, elements);
                }
            }
        });
    });
    return elements;
}

let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
};