import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MarkdownRender } from './MarkdownRender';

export class EmbedBuilderPreview extends Component {

    componentDidMount() {
        // console.log(this.props.response);
    }

    render() {
        return (
            <div className="discord-preview-container">
                <div className="discord-preview">
                    <div className="discord-messages">
                        <div className="discord-message">



                            <div className="discord-message-content">
                                <div class="discord-author-avatar">
                                    <img src="https://cdn.discordapp.com/embed/avatars/2.png" alt="" />

                                </div>

                                <div class="discord-message-body">
                                    <div className="discord-author-info-block">
                                        <span class="discord-author-info">
                                            <span class="discord-author-username">{this.props.username}</span>
                                            <span class="discord-author-bot-tag"> Bot </span>
                                        </span>
                                        <span class="discord-message-timestamp">Today at {new Date().getHours() % 12}:{new Date().getMinutes()} {new Date().getHours() >= 12 ? "PM" : "AM"}</span>
                                    </div>
                                    {/* <MarkdownRender text={this.props.content} /> */}
                                    {this.props.content}
                                    <div className="discord-embed">
                                        {this.props.embed.color ? <div class="discord-embed-left-border" style={{ backgroundColor: this.props.embed.color.hex }}></div> : <div class="discord-embed-left-border" style={{ backgroundColor: "#303136" }}></div>}
                                        <div className="discord-embed-container" style={{ width: "100%" }}>
                                            <div className="discord-embed-content">
                                                <div>
                                                    {(this.props.embed.author.icon_url || this.props.embed.author.name) && <div class="discord-embed-author">
                                                        {this.props.embed.author.icon_url && <img src={this.props.embed.author.icon_url} />}
                                                        {this.props.embed.author.name && <a href={this.props.embed.author.url} rel="noopener noreferrer">{this.props.embed.author.name}</a>}
                                                    </div>}

                                                    {this.props.embed.title && <div class="discord-embed-title">
                                                        {this.props.embed.title && <a href={this.props.embed.url} className={this.props.embed.url && 'active-link'} target="_blank" rel="noopener noreferrer">{this.props.embed.title}</a>}
                                                    </div>}

                                                    {this.props.embed.description && <div class="discord-embed-description"> {this.props.embed.description}</div>}

                                                    <div className="discord-embed-fields" style={{
                                                        minWidth: 0,
                                                        margin: '8px 0px, 0px',
                                                        display: 'grid',
                                                        gridColumn: 1 / 2,
                                                        gridGap: 8,
                                                    }}>
                                                        {this.props.fieldsRender.length > 0 && this.props.fieldsRender}
                                                    </div>

                                                    {(this.props.embed.image.url && this.props.embed.image.url !== "") && <img class="discord-embed-image" src={this.props.embed.image.url} alt=""></img>}
                                                </div>
                                                {(this.props.embed.thumbnail.url && this.props.embed.thumbnail.url !== "") && <img class="discord-embed-thumbnail" src={this.props.embed.thumbnail.url} alt="" />}
                                            </div>

                                            {(this.props.embed.footer.icon_url || this.props.embed.footer.text) && <div class="discord-embed-footer">
                                                {this.props.embed.footer.icon_url && <img src={this.props.embed.footer.icon_url} />}
                                                {this.props.embed.footer.text && <span>{this.props.embed.footer.text}</span>}
                                                {this.props.embed.footer.current_timestamp && <span>•  Today at {new Date().getHours() % 12}:{new Date().getMinutes()} {new Date().getHours() >= 12 ? "PM" : "AM"}</span>}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
    user: state.data.user
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(EmbedBuilderPreview);