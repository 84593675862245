import { faBan } from '@fortawesome/free-solid-svg-icons'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CommandAdd from './CommandAdd'

import { setBotModule } from "../../../actions"


export class Announcement extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showEdit: false
        }
    }

    componentDidMount() {

    }

    saveAnnouncement = (value) => {
        const announcements = { ...this.props.announcements };
        if (this.props.type != value.announcement_type) {
            announcements[this.props.type].splice(this.props.index, 1);
        }

        announcements[value.announcement_type][this.props.index] = value;
        this.props.setBotModule({
            module: "announcements",
            module_data: announcements
        })

        this.setState({ showEdit: false })

    }

    renderBadge = () => {
        var text = ""
        if (this.props.type == "ban") {
            text = "Ban"
        } else if (this.props.type == "join") {
            text = "Join"
        } else if (this.props.type == "leave") {
            text = "Leave"
        }
        return <div class="premium-badge">{text}</div>
    }

    delete = () => {
        const announcements = { ...this.props.announcements };
        announcements[this.props.type].splice(this.props.index, 1)
        this.props.setBotModule({
            module: "announcements",
            module_data: announcements
        })

    }

    render() {
        return (
            <div className="announcement">
                <div className="announcement-header" onClick={(e) => { this.setState({ showEdit: !this.state.showEdit }) }}>
                    <div>
                        <h3 className="mb-0">{this.props.value.name} {this.renderBadge()}</h3>
                        <div className="section-content-header">{`Posting a ${this.props.value.type} message in ${this.props.value.channel.name}`}</div>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                        {this.state.showEdit ? <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i> : <i style={{ fontSize: "20px" }} class="bi bi-caret-down-fill"></i>}
                    </div>
                </div>


                {this.state.showEdit ?
                    <div className="announcement-content">
                        <CommandAdd
                            embed={true}
                            name_input={true}
                            name="Announcement Name"
                            saveText="Save"
                            title="Announcement Message"
                            save={this.saveAnnouncement}
                            announcement={true}
                            customVariables={true}
                            value={{
                                name_input: this.props.value.name,
                                channel: this.props.value.channel,
                                announcement_type: this.props.type,
                                type: this.props.value.type == "embed" ? true : false,
                                embed: this.props.value.embed,
                                text: this.props.value.content,
                            }}
                            variableEditor={true}
                            delete={true}
                            reset={false}
                            onDelete={this.delete}
                        ></CommandAdd>
                    </div> :
                    null}

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    announcements: state.data.bot.commands.announcements

})

const mapDispatchToProps = {
    setBotModule
}

export default connect(mapStateToProps, mapDispatchToProps)(Announcement)
