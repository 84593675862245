import React, { Component } from 'react';
import { DocsLink } from './DocsLink';
import { connect } from 'react-redux';
import RefreshBotData from "./RefreshBotData";

class SectionHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>
                <p class="section-pretitle">{this.props.pretitle} </p>
                <div className='flex items-center'>
                    <h2 class="section-title">{this.props.title} {this.props.refresh ? <RefreshBotData /> : null} {this.props.docs == true ? <DocsLink location={this.props.docs_link} /> : null}</h2>
                    {this.props.module && this.props.premiumRequired && !this.props.premium ?
                        <div className='ml-2 premium-badge'>
                            <span className="">Premium</span>
                        </div> : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    premium: state.data.premium
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SectionHeader);;