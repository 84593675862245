import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactGA from 'react-ga';

import history from "../../../history"

export class PremiumBanner extends Component {
    constructor(props) {
        super(props)

        this.state = {
            color: "black",
        }
    }

    componentDidMount() {
        this.renderColor()
        // console.log(this.props)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps != this.props) {
            this.renderColor()
        }
        // this.renderColor()
    }

    renderColor = () => {
        if (this.props.premium == true || this.props.tokens >= 1) {
            this.setState({ color: "#28a745" })
        }
    }

    changePage = () => {
        if (this.props.premium == false && this.props.tokens >= 1) {
            history.push("/dashboard/settings")
        } else if (this.props.premium == false) {
            history.push("/dashboard/premium")
        } else {

        }
    }
    bannerClick = () => {
        ReactGA.initialize('UA-122665050-1');
        ReactGA.event({
            category: "Server Join",
            label: "Banner",
            action: "New Dashboard"
        })
    }

    renderText = () => {
        if (this.props.premium == false) {
            return (<a onClick={(e) => { this.bannerClick() }} href="https://discord.gg/botghost" target="_blank" style={{ color: "#fff", fontWeight: "800" }}>Click here to join the Discord Server for <span style={{ color: "red" }}>Premium Giveways</span> and more...</a>)
        } else if (this.props.premium == false && this.props.tokens >= 1) {
            return (<span>BOT STATUS <span >BASIC</span> - CLICK HERE TO UPGRADE BOT</span>)
        } else {
            return (<span>BOT STATUS <span>PREMIUM</span></span>)

        }
    }
    render() {
        return (

            <div className="premium-banner-container" style={{ backgroundColor: this.state.color }} onClick={(e) => this.changePage()}>

                <div className="premium-banner">
                    {this.renderText()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    premium: state.data.premium,
    tokens: state.data.user.tokens
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PremiumBanner)
