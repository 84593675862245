import axios from 'axios'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setBotModule } from '../../../actions'
import RefreshBotData from './RefreshBotData'
import Select from './Select'

export class OpenSeaAlert extends Component {

    constructor(props) {
        super(props)

        this.state = {
            disabled: false,
            type: "item_sold",
            slug: "",
            channel: {

            }
        }
    }

    componentDidMount() {
        if (this.props.alert != undefined) {
            Object.keys(this.props.alert).forEach((key) => {
                this.setState({ [key]: this.props.alert[key] });
            });
        }
    }

    addAlert = async () => {
        var opensea_alerts = { ...this.props.opensea_alerts };


        var alert = {
            type: this.state.type,
            slug: this.state.slug,
            channel: this.state.channel
        }

        if (this.props.index == 'new') {
            opensea_alerts.alerts.push(alert)
            this.props.setBotModule({
                module: "opensea_alerts",
                module_data: opensea_alerts,
            });
        } else {
            opensea_alerts.alerts[this.props.index] = alert
            this.props.setBotModule({
                module: "opensea_alerts",
                module_data: opensea_alerts,
            });
            this.props.close()
        }

        this.setState({
            type: "item_sold",
            slug: "",
            channel: {

            }
        })
    }
    render() {
        return (
            <div>
                <form onSubmit={async (e) => {
                    e.preventDefault()
                    this.setState({ disabled: true, error: "" })
                    var response = await axios.get(`https://api.opensea.io/api/v1/collection/${this.state.slug}`).catch(e => {

                        this.setState({ disabled: false, error: "This collection slug could not be found. Make sure you are using the slug and not the collection name." })
                        return;
                    })

                    this.addAlert()
                    if (this.props.index == 'new') {



                        // opensea_sales.alerts.push(alert)
                        // this.props.setBotModule({
                        //     module: "opensea_sales",
                        //     module_data: opensea_sales,
                        // });


                    }
                    this.setState({ disabled: false })
                    // console.log(response)

                }}>
                    <div class="mb-15">
                        <h3 style={{ marginBottom: "0px" }}>Collection Slug </h3>

                        <div className="section-content-header">
                            <p className='m-w-100 m-breakword'>The collection slug of the opensea collection. You can find the slug of the collection in the OpenSea URL. For example https://opensea.io/collection/<span style={{ color: "#ff1500", fontWeight: "800" }}>boredapeyachtclub</span>.</p>
                        </div>

                        <span style={{ color: "red" }} className="mb-15">{this.state.error}</span>
                        <div className="long-input mb-15">
                            <label>Collection</label>
                            <input
                                required
                                type="text"
                                value={this.state.slug}
                                placeholder="boredapeyachtclub"
                                onChange={(e) => this.setState({ slug: e.target.value.toLowerCase() })}
                            ></input>
                        </div>
                    </div>


                    <div className='mb-15'>
                        <h3 style={{ marginBottom: "0px" }}>Channel <RefreshBotData /> </h3>
                        <div className="section-content-header">
                            <p>The channel to post the new alert into.</p>
                        </div>

                        <Select value={JSON.stringify(this.state.channel)} onChange={(value) => {
                            var channel = JSON.parse(value);
                            this.setState({ channel: channel })
                            // console.log(value)
                        }} type="channel" />
                    </div>

                    <div className='mb-15'>
                        <h3 style={{ marginBottom: "0px" }}>Alert Type </h3>
                        <div className="section-content-header">
                            <p>Select an alert type</p>
                        </div>

                        <Select value={this.state.type} options={[
                            { label: "Item Sold", value: "item_sold" },
                        ]} />
                    </div>
                    <hr class="slashcommand-hr" />
                    <div style={{ display: "flex" }}>
                        <div style={{ marginLeft: "auto" }} className="m-buttons m-mt-0">
                            {
                                this.props.index != 'new' ? <button onClick={() => {
                                    this.props.delete()
                                }} className="btn btn-gray mt-15 mr-2 m-mt-0" type="button n ">
                                    Delete Alert
                                </button> : null

                            }
                            <button className="btn btn-red mt-15 m-mt-0" type="submit">
                                {this.props.index == 'new' ? 'Add' : "Edit"} Alert
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    opensea_alerts: state.data.bot.commands.opensea_alerts
})

const mapDispatchToProps = {
    setBotModule
}

export default connect(mapStateToProps, mapDispatchToProps)(OpenSeaAlert)