import React, { Component } from "react";
import { connect } from "react-redux";
import { checkErrorChildVariable } from "../commandbuilder/eventUtils";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

const VARIABLES = [
	{ variable: "{null}", label: "null", description: "Returns null for comparison conditions." },
	{ variable: "{user}", label: "user", description: "The user triggering the command.", announcement: true },
	{ variable: "{server}", label: "server", description: "The server name.", announcement: true },
	{ variable: "{channel}", label: "channel", description: "The channel the command was used in." },
	// { variable: "{@userID}", label: "userID", announcement: true, description: "Mention a specific user by their user ID. Replace userID with their ID." },
	{ variable: "{^role}", label: "role", announcement: true, description: "Mention a specific role by their user name. Replace role with a name." },
	{ variable: "{#channel}", announcement: true, label: "#channel", description: "Mention a specific channel by its name. Replace channel with a name." },
	{ variable: "{channel_name}", label: "channel name", description: "The name of the channel that the message was posted in." },
	{ variable: "{channel_type}", label: "channel type", description: "The type of the channel that the message was posted in." },
	{ variable: "{channel_id}", label: "channel id", description: "The id of the channel that the message was posted in." },
	{ variable: "{first_channel_id}", label: "first channel id", description: "The id of the first channel in a server." },
	{ variable: "{server_members}", announcement: true, label: "server members", description: "The amount of members in the server." },
	{ variable: "{server_icon}", announcement: true, label: "server icon", description: "The icon of the guild." },
	{ variable: "{server_id}", slash: true, label: "server id", description: "The id of the guild." },
	{ variable: "{server_owner}", announcement: true, label: "server owner", description: "Tags the owner of the server." },
	{ variable: "{server_owner_id}", announcement: false, label: "server owner id", description: "The ID of the server owner." },
	{ variable: "{user_icon}", announcement: true, label: "user icon", description: "The icon of the user." },
	{ variable: "{user_name}", announcement: true, label: "user name", description: "The username of the user" },
	{ variable: "{user_id}", announcement: true, label: "user id", description: "The id of the user" },
	{ variable: "{user_tag}", announcement: true, label: "user tag", description: "The tag of the user" },
	{ variable: "{user_discriminator}", announcement: true, label: "user discriminator", description: "The discriminator of the user." },
	{ variable: "{user_createdAt}", announcement: true, label: "user created at", description: "When this user created their account." },
	{ variable: "{user_joined}", announcement: true, label: "user joined", description: "When this user joined the server." },
	{ variable: "{user_displayName}", announcement: true, label: "user display name", description: "The display name of the user." },
	{ variable: "{user_status}", announcement: true, label: "user status", description: "The status of a user." },
	{ variable: "{random[min,max]}", label: "random", description: "Returns a random number. Replace min and max with values." },
	{ variable: "{isNumber(10)}", label: "number check", description: "Returns whether the content is a number or not." },
	{ variable: "{randomCharacters(10)}", label: "random characters", description: "Returns X amount of random characters." },
	{ variable: "{charCount(Hello)}", label: "char count", description: "Returns the character count of supplied content." },
	{ variable: "{isBot}", label: "bot check", description: "Returns whether a user is a bot or not." },
	{ variable: "{member_mention}", slash: false, label: "member mention", description: "The member who was mentioned in the command." },
	{ variable: "{bot_serverCount}", slash: true, label: "server count", description: "Returns the server count for the bot." },
	// { variable: "{bot_userCount}", slash: true, label: "user count", description: "Returns the user count for the bot." },
	{ variable: "{currency_balance}", slash: true, label: "currency balance", description: "Returns the currency balance of a user." },
	{ variable: "{user_name_encoded}", slash: true, label: "username encoded", description: "Returns a URL encoded version of a users name. Useful for API links." },
	{ variable: "{time_now}", slash: true, label: "current time", description: "Returns a discord formatted timestamp of the current time." },
	{ variable: "{time_ahead_h_HOURS}", slash: true, announcement: true, label: "Hours ahead", description: "Returns a discord formatted timestamp X hours ahead. Replace HOURS." },
	{ variable: "{time_ahead_m_MINS}", slash: true, announcement: true, label: "Minutes ahead", description: "Returns a discord formatted timestamp X minutes ahead. Replace MINS." },
	{ variable: "{time_now_unix}", slash: true, label: "current time (unix)", description: "Returns a unix timestamp of the current time." },
	{ variable: "{time_ahead_unix_h_HOURS}", slash: true, announcement: true, label: "Hours ahead (unix)", description: "Returns a unix timestamp X hours ahead. Replace HOURS." },
	{ variable: "{time_ahead_unix_m_MINS}", slash: true, announcement: true, label: "Minutes ahead (unix)", description: "Returns a unix timestamp X minutes ahead. Replace MINS." },
	{ variable: "{time_ahead_unix_s_SECS}", slash: true, announcement: true, label: "Seconds ahead (unix)", description: "Returns a unix timestamp X seconds ahead. Replace SECS." },
	{ variable: "{encode_text[TEXT]}", slash: true, announcement: true, label: "Encode Text", description: "Returns URI encoded text for API requests." },
	{ variable: "{ISO_to_UNIX[Timestamp]}", slash: true, announcement: true, label: "ISO to UNIX", description: "Converts an ISO timestamp to a UNIX timestamp" },
	{ variable: "{UNIX_to_ISO[Timestamp]}", slash: true, announcement: true, label: "UNIX to ISO", description: "Converts an UNIX timestamp to a ISO timestamp" },
	{ variable: "{HEX_to_DECIMAL[HEX]}", slash: true, announcement: true, label: "HEX to Decimal", description: "Converts a Hex number to a Decimal number" },
	{ variable: "{solve_equation[1+1]}", slash: true, announcement: true, label: "Solve Equation", description: "Solves any math equation" },
	{ variable: "{previous_numbers(10)}", slash: true, announcement: true, label: "Previous Numbers", description: "Returns all numbers before a given number" },
	{ variable: "{bot_id}", slash: true, announcement: true, label: "Bot Id", description: "Returns the id of the bot." },
	{ variable: "{hasRole([ROLE_ARRAY])}", slash: true, announcement: true, label: "Has Role", description: "Returns true or false if the user has a role in the role array" },
	{ variable: "{isChannel([CHANNEL_ARRAY])}", slash: true, announcement: true, label: "Is Channel", description: "Returns true or false if the channel is in the channel array" },
	{ variable: "{subString(Hello World),[0,5]}", slash: true, announcement: true, label: "Returns a substring", description: "Returns a substring of a supplied string" },
	{ variable: "{toUpperCase(hello)}", slash: true, announcement: true, label: "Uppercase", description: "Converts text to uppercase" },
	{ variable: "{escape_characters[hello]}", slash: true, announcement: true, label: "Escape Characters", description: "Escapes text" },

	// { variable: "{economy_currency_prefix}", slash: true, announcement: true, label: "Currency Prefix", description: "Economy currency prefix" },
	// { variable: "{economy_currency_name}", slash: true, announcement: true, label: "Currency Name", description: "Economy currency name" },
	// { variable: "{economy_inventory}", slash: true, announcement: true, label: "User Inventory", description: "The Users inventory as an array of objects" },

	{ variable: "{interaction_token}", beta: true, slash: true, announcement: true, label: "Interaction Token", description: "The interactions token" },
	{ variable: "{interaction_id}", beta: true, slash: true, announcement: true, label: "Interaction Id", description: "The interactions id" },
	{ variable: "{interaction_custom_id}", beta: true, slash: true, announcement: true, label: "Interaction Custom Id", description: "The interactions custom id" }
];

export class VariableEditor extends Component {
	constructor(props) {
		super(props);

		this.state = {
			length: 0,
			filter: ""
		};
	}

	getSlashOptions = () => {
		var options = [];
		this.props.elements.forEach((element) => {
			if (element.type == "option" && element.data.data != undefined) {
				options.push(element.data.data);
			}
		});

		return options;
	};

	renderVariables = () => {
		var returnArray = [];
		var slashOptions = this.getSlashOptions();
		var variableArray = [];
		if (this.props.customVariables == true) {
			var section = [];
			this.props.customVariables.variables.forEach((variable) => {
				if (variable.type == "object") {
					variable.defaultValue.forEach((defaultValues) => {
						section.push({
							name: defaultValues.name,
							variable: `{BGVAR_${variable.reference}.${defaultValues.key}}`,
							description: "Custom Variable"
						});
					});
				} else {
					section.push({
						name: variable.name,
						variable: `{BGVAR_${variable.reference}}`,
						description: "Custom Variable"
					});
				}

				// returnArray.push(
				//     <li onClick={() => { this.props.click({ variable: `{BGVAR_${variable.reference}}` }, false); }}>
				//         <div>{variable.name}</div>
				//         <div className="variable-editor-description">Custom Variable</div>
				//     </li>
				// );
			});
			variableArray.push({
				name: "Custom Variables",
				variables: section
			});
		}
		if (this.props.slashcommand != true) {
			VARIABLES.forEach((variable) => {
				if (this.props.announcement != true || (this.props.announcement == true && variable.announcement == true)) {
					if (variable.beta != true || (variable.beta == true && this.props.beta == true)) {
						returnArray.push(
							<li
								onClick={() => {
									this.props.click(variable);
								}}
							>
								<div style={{ marginRight: "15px" }}>{variable.label}</div>
								<div className="variable-editor-description">{variable.description}</div>
							</li>
						);
					}
				}
			});

			return returnArray;
		} else {
			// Error Handling Variables

			if (this.props.selected && this.props.elements && checkErrorChildVariable(this.props.elements, this.props.selected)) {
				var section = [];
				section.push({
					name: "error reason",
					variable: "{error_reason}",
					description: "Error reason"
				});
				section.push({
					name: "error message",
					variable: "{error_message}",
					description: "Error message"
				});

				section.push({
					name: "error block",
					variable: "{error_block}",
					description: "Error block"
				});

				variableArray.push({
					name: `Error Variables`,
					variables: section
				});
			}

			if (this.props.module_id != null && this.props.moduleSettings[this.props.module_id] != undefined) {
				var section = [];
				Object.keys(this.props.moduleSettings[this.props.module_id].settings).forEach((key) => {
					if (this.props.moduleSettings[this.props.module_id].settings[key].type == "slot") {
						var slot_inputs = this.props.moduleSettings[this.props.module_id].settings[key].inputs;
						slot_inputs.forEach((input) => {
							section.push({
								name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} ${input.name}`,
								variable: `{module_setting.${input.name}}`,
								description: "Module Setting"
							});
						});
					} else if (this.props.moduleSettings[this.props.module_id].settings[key].type == "emoji_input") {
						section.push({
							name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} ids`,
							variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.ids}`,
							description: "Module Setting"
						});

						section.push({
							name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} natives`,
							variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.natives}`,
							description: "Module Setting"
						});

						section.push({
							name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} names`,
							variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.names}`,
							description: "Module Setting"
						});

						section.push({
							name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} encoded`,
							variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.encoded}`,
							description: "Module Setting"
						});
					} else if (this.props.moduleSettings[this.props.module_id].settings[key].type == "shop") {
						console.log("INSIDE SHOP??");
						// Variable for each in the below array
						var shopItemKeys = ["name", "itemLimit", "description", "id", "img", "type", "price", "multiple", "useOnBuy", "destroyOnUse", "roles", "roles.ids", "roles.names"];

						shopItemKeys.forEach((shopItemKey) => {
							section.push({
								name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} item ${shopItemKey}`,
								variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.INDEX.${shopItemKey}}`,
								description: "Module Setting"
							});
						});

						// Also add a variable for the length of the shop
						section.push({
							name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} length`,
							variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.length}`,
							description: "Module Setting"
						});
					} else if (this.props.moduleSettings[this.props.module_id].settings[key].type != "input_switch") {
						section.push({
							name: this.props.moduleSettings[this.props.module_id].settings[key].name,
							variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}}`,
							description: "Module Setting"
						});
					} else {
						console.log();
						var moduleValue = this.props.moduleSettings[this.props.module_id].settings[key].value;
						section.push({
							name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} type`,
							variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.type}`,
							description: "Module Setting"
						});
						if (moduleValue.embed) {
							// Variable for title, description,color, thumbnail, link, image, footer, author,
							section.push({
								name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} embed title`,
								variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.title}`,
								description: "Module Setting"
							});

							section.push({
								name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} embed description`,
								variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.description}`,
								description: "Module Setting"
							});

							section.push({
								name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} embed color`,
								variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.color}`,
								description: "Module Setting"
							});

							section.push({
								name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} embed thumbnail`,
								variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.thumbnail}`,
								description: "Module Setting"
							});

							section.push({
								name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} embed link`,
								variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.link}`,
								description: "Module Setting"
							});

							section.push({
								name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} embed image`,
								variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.image}`,
								description: "Module Setting"
							});

							section.push({
								name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} embed footer`,
								variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.footer}`,
								description: "Module Setting"
							});

							section.push({
								name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} embed author`,
								variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.author}`,
								description: "Module Setting"
							});
						} else {
							section.push({
								name: `${this.props.moduleSettings[this.props.module_id].settings[key].name} text`,
								variable: `{module_setting.${this.props.moduleSettings[this.props.module_id].settings[key].name}.text}`,
								description: "Module Setting"
							});
						}
					}
				});

				variableArray.push({
					name: `Module Settings`,
					variables: section
				});
			}
			var hasMenu = false;
			this.props.elements.forEach((element) => {
				console.log(element, "ELEMENT");
				if (element && element.data?.data?.type == "adv_select_menu" && element.data?.data?.customVariableName) {
					var section = [];

					hasMenu = true;

					section.push({
						name: `${element.data.data.customVariableName}`,
						variable: `{${element.data.data.customVariableName}}`,
						description: `${element.data.data.customVariableName} value`
					});

					// If multiselect is true, add a variable for the length of the array
					if (element.data.data.multiselect) {
						section.push({
							name: `${element.data.data.customVariableName} length`,
							variable: `{${element.data.data.customVariableName}.length}`,
							description: `${element.data.data.customVariableName} length`
						});
					}



					variableArray.push({
						name: `${element.data.data.customVariableName} Select Menu`,
						variables: section
					});

				}
				else if (element && element.data && element.id != this.props.id && element.type == "action" && element.data.node_options.title == "Send an API Request" && "data" in element.data) {
					var section = [];
					// returnArray.push(
					//     <li className="variable-editor-header">{element.data.data.name} API Action Variables</li>
					// );
					section.push({
						name: `${element.data.data.name} response`,
						variable: `{${element.data.data.name}.response}`,
						description: "api"
					});
					// returnArray.push(
					//     <li onClick={() => { this.props.click({ variable: `{${element.data.data.name}.response}` }, false); }}>
					//         <div>{element.data.data.name} response</div>
					//         <div className="variable-editor-description">api</div>
					//     </li>
					// );
					section.push({
						name: `${element.data.data.name} status`,
						variable: `{${element.data.data.name}.status}`,
						description: "api"
					});
					// returnArray.push(
					//     <li onClick={() => { this.props.click({ variable: `{${element.data.data.name}.status}` }, false); }}>
					//         <div>{element.data.data.name} status</div>
					//         <div className="variable-editor-description">api</div>
					//     </li>
					// );
					section.push({
						name: `${element.data.data.name} status text`,
						variable: `{${element.data.data.name}.statusText}`,
						description: "api"
					});

					section.push({
						name: `${element.data.data.name} text response`,
						variable: `{${element.data.data.name}.textResponse}`,
						description: "api"
					});
					// returnArray.push(
					//     <li onClick={() => { this.props.click({ variable: `{${element.data.data.name}.statusText}` }, false); }}>
					//         <div>{element.data.data.name} status text</div>
					//         <div className="variable-editor-description">api</div>
					//     </li>
					// );
					variableArray.push({
						name: `${element.data.data.name} API Action Variables`,
						variables: section
					});
				} else if (element && element.data && element.id != this.props.id && element.type == "action" && element.data.node_options.title == "Send a Form" && "data" in element.data) {
					var section = [];

					element.data.data.modal_settings.components.forEach((component) => {
						section.push({
							name: `${component.label} response`,
							variable: `{${element.data.data.modal_id}.${component.variable}}`,
							description: `${component.label} response`
						});
					});

					variableArray.push({
						name: `${element.data.data.modal_id} Form Response Variables`,
						variables: section
					});
				} else if (element && element.data && element.id != this.props.id && element.type == "action" && element.data.node_options.title == "Create an Emoji" && "data" in element.data) {
					var section = [];

					// components.variables.push({
					//     variable: `{${action_config.variable}.id}`,
					//     value: emoji.id
					// });

					// components.variables.push({
					//     variable: `{${action_config.variable}.name}`,
					//     value: emoji.name
					// });

					// components.variables.push({
					//     variable: `{${action_config.variable}.emoji}`,
					//     value: `<:${emoji.name}:${emoji.id}>`
					// });

					// components.variables.push({
					//     variable: `{${action_config.variable}.animated}`,
					//     value: emoji.animated
					// });

					section.push({
						name: `New Emoji: ${element.data.data.name} Id`,
						variable: `{${element.data.data.variable}.id}`,
						description: `Emoji ${element.data.data.name} id`
					});

					section.push({
						name: `New Emoji: ${element.data.data.name} Name`,
						variable: `{${element.data.data.variable}.name}`,
						description: `Emoji ${element.data.data.name} name`
					});

					section.push({
						name: `New Emoji: ${element.data.data.name} Emoji`,
						variable: `{${element.data.data.variable}.emoji}`,
						description: `Emoji ${element.data.data.name} emoji`
					});

					section.push({
						name: `New Emoji: ${element.data.data.name} Animated`,
						variable: `{${element.data.data.variable}.animated}`,
						description: `Emoji ${element.data.data.name} animated`
					});

					variableArray.push({
						name: `New Emoji: ${element.data.data.name}`,
						variables: section
					});
				} else if (element && element.data && element.id != this.props.id && element.type == "action" && element.data.node_options.title == "Manipulate some text" && "data" in element.data) {
					var section = [];

					section.push({
						name: `Text Result: ${element.data.data.variable}`,
						variable: `{${element.data.data.variable}}`,
						description: `Result`
					});

					variableArray.push({
						name: `Text Manipulation: ${element.data.data.variable}`,
						variables: section
					});
				}
			});

			if (hasMenu) {
				variableArray.push({
					name: "Select Menu Variables",
					variables: [
						{
							name: "select menu option",
							variable: "{selected_option}",
							description: "Selected option"
						},
						{
							name: "select menu options",
							variable: "{selected_options}",
							description: "Selected options"
						},
						// selected_options.length
						{
							name: "select menu options length",
							variable: "{selected_options.length}",
							description: "Selected options length"
						}
					]
				});
			}

			if (this.props.components && "variables" in this.props.components && this.props.components.variables.length > 0) {
				// returnArray.push(
				//     <li className="variable-editor-header">Event Variables</li>
				// );
				var section = [];
				this.props.components.variables.forEach((variable) => {
					// returnArray.push(
					//     <li onClick={() => { this.props.click({ variable: variable.variable }, false); }}>
					//         <div>{variable.name}</div>
					//         <div className="variable-editor-description">custom event</div>
					//     </li>
					// );

					section.push({
						name: variable.name,
						variable: variable.variable,
						description: "custom event"
					});
				});
				variableArray.push({
					name: "Event Variables",
					variables: section
				});
			}

			this.props.customVariables.variables.forEach((variable) => {
				var section = [];

				if (variable.type == "object") {
					variable.defaultValue.forEach((value) => {
						section.push({
							name: `${variable.name}.${value.key}`,
							variable: `{BGVAR_${variable.reference}.${value.key}}`,
							description: variable.type
						});
					});
				} else if (variable.dataType != "object") {
					section.push({
						name: variable.name,
						variable: `{BGVAR_${variable.reference}}`,
						description: variable.type
					});
				}

				if (variable.type == "user") {
					slashOptions.forEach((option) => {
						if (option.type == "user") {
							if (variable.dataType == "object") {
								variable.defaultValue.forEach((value) => {
									section.push({
										name: `${variable.name}.${value.key} (option_${option.name})`,
										variable: `{BGVAR_${variable.reference}.${value.key}[option_${option.name}]}`,
										description: `option_${option.name}`
									});
								});
							} else {
								section.push({
									name: `${variable.name} (option_${option.name})`,
									variable: `{BGVAR_${variable.reference}[option_${option.name}]}`,
									description: `option_${option.name}`
								});
							}
						}
					});

					if (variable.dataType == "object") {
						variable.defaultValue.forEach((value) => {
							section.push({
								name: `${variable.name}.${value.key} (User Id)`,
								variable: `{BGVAR_${variable.reference}.${value.key}[USER_ID]}`,
								description: "user id test"
							});
						});
					} else {
						section.push({
							name: `${variable.name} (User Id)`,
							variable: `{BGVAR_${variable.reference}[USER_ID]}`,
							description: "user id"
						});
					}
					if (variable.dataType == "number") {
						section.push({
							name: `${variable.name} (Highest User)`,
							variable: `{getHighestUser(BGVAR_${variable.reference})[1]}`,
							description: "highest user"
						});

						section.push({
							name: `${variable.name} (Highest Value)`,
							variable: `{getHighestValue(BGVAR_${variable.reference})[1]}`,
							description: "highest value"
						});

						section.push({
							name: `${variable.name} (Lowest User)`,
							variable: `{getLowestUser(BGVAR_${variable.reference})[1]}`,
							description: "lowest user"
						});

						section.push({
							name: `${variable.name} (Lowest Value)`,
							variable: `{getLowestValue(BGVAR_${variable.reference})[1]}`,
							description: "lowest value"
						});

						section.push({
							name: `${variable.name} (Average)`,
							variable: `{getAverage(BGVAR_${variable.reference})}`,
							description: "average"
						});

						section.push({
							name: `${variable.name} (Rank)`,
							variable: `{getRankOfUser(BGVAR_${variable.reference})}`,
							description: "rank"
						});
					} else if (variable.dataType == "array") {
						section.push({
							name: `${variable.name} (Lowest Value)`,
							variable: `{lowestValue({BGVAR_${variable.reference}})}`,
							description: "lowest value"
						});

						section.push({
							name: `${variable.name} (Highest Value)`,
							variable: `{highestValue({BGVAR_${variable.reference}})}`,
							description: "highest value"
						});

						section.push({
							name: `${variable.name} (Length of Collection)`,
							variable: `{lengthOfCollection({BGVAR_${variable.reference}})}`,
							description: "length"
						});

						section.push({
							name: `${variable.name} (Value at Position)`,
							variable: `{valueAtPosition({BGVAR_${variable.reference}}),[2]}`,
							description: "value at position"
						});

						section.push({
							name: `${variable.name} (Position of Value)`,
							variable: `{positionOfValue({BGVAR_${variable.reference}}),[dog]}`,
							description: "position of value"
						});

						section.push({
							name: `${variable.name} (Character Length)`,
							variable: `{collectionCharacterLength({BGVAR_${variable.reference}})}`,
							description: "character length"
						});

						section.push({
							name: `${variable.name} (Random)`,
							variable: `{getRandomFromCollection({BGVAR_${variable.reference}})}`,
							description: "random"
						});

						section.push({
							name: `${variable.name} (Print Values)`,
							variable: `{printAllValues({BGVAR_${variable.reference}}),[\nHello $value $index]}`,
							description: "print"
						});
					}
				} else if (variable.type == "array") {
					// Example Variables
					// Array: {array}\nLowest Value: {lowestValue({array})}\nHighest Value: {highestValue({array})}\nLength of Collection {lengthOfCollection({array})}\nValue at Position 2: {valueAtPosition({array})[2]}\n Position of Value: {positionOfValue({array})[dog]}\n Character Length: {collectionCharacterLength({array})}\n Random: {getRandomFromCollection({random})}\n Print All Values {printAllValues({array})[Hello $value]}

					section.push({
						name: `${variable.name} (Lowest Value)`,
						variable: `{lowestValue({BGVAR_${variable.reference}})}`,
						description: "lowest value"
					});

					section.push({
						name: `${variable.name} (Highest Value)`,
						variable: `{highestValue({BGVAR_${variable.reference}})}`,
						description: "highest value"
					});

					section.push({
						name: `${variable.name} (Length of Collection)`,
						variable: `{lengthOfCollection({BGVAR_${variable.reference}})}`,
						description: "length"
					});

					section.push({
						name: `${variable.name} (Value at Position)`,
						variable: `{valueAtPosition({BGVAR_${variable.reference}}),[2]}`,
						description: "value at position"
					});

					section.push({
						name: `${variable.name} (Position of Value)`,
						variable: `{positionOfValue({BGVAR_${variable.reference}}),[dog]}`,
						description: "position of value"
					});

					section.push({
						name: `${variable.name} (Character Length)`,
						variable: `{collectionCharacterLength({BGVAR_${variable.reference}})}`,
						description: "character length"
					});

					section.push({
						name: `${variable.name} (Random)`,
						variable: `{getRandomFromCollection({BGVAR_${variable.reference}})}`,
						description: "random"
					});

					section.push({
						name: `${variable.name} (Print Values)`,
						variable: `{printAllValues({BGVAR_${variable.reference}}),[\nHello $value]}`,
						description: "print"
					});
				} else if (variable.type == "channel") {
					if (variable.dataType == "array") {
						section.push({
							name: `${variable.name} (Lowest Value)`,
							variable: `{lowestValue({BGVAR_${variable.reference}})}`,
							description: "lowest value"
						});

						section.push({
							name: `${variable.name} (Highest Value)`,
							variable: `{highestValue({BGVAR_${variable.reference}})}`,
							description: "highest value"
						});

						section.push({
							name: `${variable.name} (Length of Collection)`,
							variable: `{lengthOfCollection({BGVAR_${variable.reference}})}`,
							description: "length"
						});

						section.push({
							name: `${variable.name} (Value at Position)`,
							variable: `{valueAtPosition({BGVAR_${variable.reference}}),[2]}`,
							description: "value at position"
						});

						section.push({
							name: `${variable.name} (Position of Value)`,
							variable: `{positionOfValue({BGVAR_${variable.reference}}),[dog]}`,
							description: "position of value"
						});

						section.push({
							name: `${variable.name} (Character Length)`,
							variable: `{collectionCharacterLength({BGVAR_${variable.reference}})}`,
							description: "character length"
						});

						section.push({
							name: `${variable.name} (Random)`,
							variable: `{getRandomFromCollection({BGVAR_${variable.reference}})}`,
							description: "random"
						});

						section.push({
							name: `${variable.name} (Print Values)`,
							variable: `{printAllValues({BGVAR_${variable.reference}}),[\nHello $value]}`,
							description: "print"
						});
					} else if (variable.dataType == "object") {
						// channel id

						variable.defaultValue.forEach((value) => {
							section.push({
								name: `${variable.name}.${value.key} (Channel ID)`,
								variable: `{BGVAR_${variable.reference}.${value.key}[channel_id]}`,
								description: "channel id"
							});
						});
					}
				}
				variableArray.push({
					name: `${variable.name} (Custom Variable)`,
					variables: section
				});
			});

			if (this.props.slashcommandVariables && this.props.slashcommandVariables.length > 0) {
				var section = [];
				this.props.slashcommandVariables.forEach((variable) => {
					// returnArray.push(
					//     <li onClick={() => { this.props.click(variable, true); }}>
					//         <div>{variable.name} - {`{option_${variable.name}}`}</div>
					//         <div className="variable-editor-description">{variable.description}</div>
					//     </li>
					// );
					section.push({
						name: `${variable.name} - {option_${variable.name}}`,
						variable: `{option_${variable.name}}`,
						description: variable.description
					});

					if (variable.type == "user") {
						section.push({
							name: `${variable.name} (User Id)`,
							variable: `{user_id[option_${variable.name}]}`,
							description: "user id"
						});

						section.push({
							name: `${variable.name} (User Name)`,
							variable: `{user_name[option_${variable.name}]}`,
							description: "user name"
						});

						// returnArray.push(
						//     <li onClick={() => { this.props.click({ variable: `{user_tag[option_${variable.name}]}` }, false); }}>
						//         <div>{variable.name} ({`User Tag`})</div>
						//         <div className="variable-editor-description">user tag</div>
						//     </li>
						// );
						section.push({
							name: `${variable.name} (User Tag)`,
							variable: `{user_tag[option_${variable.name}]}`,
							description: "user tag"
						});

						// returnArray.push(
						//     <li onClick={() => { this.props.click({ variable: `{user_discriminator[option_${variable.name}]}` }, false); }}>
						//         <div>{variable.name} ({`User Discriminator`})</div>
						//         <div className="variable-editor-description">user discriminator</div>
						//     </li>
						// );
						section.push({
							name: `${variable.name} (User Discriminator)`,
							variable: `{user_discriminator[option_${variable.name}]}`,
							description: "user discriminator"
						});

						// returnArray.push(
						//     <li onClick={() => { this.props.click({ variable: `{user_createdAt[option_${variable.name}]}` }, false); }}>
						//         <div>{variable.name} ({`User Created At`})</div>
						//         <div className="variable-editor-description">user created at</div>
						//     </li>
						// );
						section.push({
							name: `${variable.name} (User Created At)`,
							variable: `{user_createdAt[option_${variable.name}]}`,
							description: "user created"
						});

						// returnArray.push(
						//     <li onClick={() => { this.props.click({ variable: `{user_joined[option_${variable.name}]}` }, false); }}>
						//         <div>{variable.name} ({`User Joined`})</div>
						//         <div className="variable-editor-description">user joined</div>
						//     </li>
						// );
						section.push({
							name: `${variable.name} (User Joined)`,
							variable: `{user_joined[option_${variable.name}]}`,
							description: "user joined"
						});

						// returnArray.push(
						//     <li onClick={() => { this.props.click({ variable: `{user_displayName[option_${variable.name}]}` }, false); }}>
						//         <div>{variable.name} ({`User Display Name`})</div>
						//         <div className="variable-editor-description">user display name</div>
						//     </li>
						// );
						section.push({
							name: `${variable.name} (User Display Name)`,
							variable: `{user_displayName[option_${variable.name}]}`,
							description: "user display name"
						});

						// returnArray.push(
						//     <li onClick={() => { this.props.click({ variable: `{user_icon[option_${variable.name}]}` }, false); }}>
						//         <div>{variable.name} ({`User Icon`})</div>
						//         <div className="variable-editor-description">user icon</div>
						//     </li>
						// );
						section.push({
							name: `${variable.name} (User Icon)`,
							variable: `{user_icon[option_${variable.name}]}`,
							description: "user icon"
						});

						// returnArray.push(
						//     <li onClick={() => { this.props.click({ variable: `{user_name_encoded[option_${variable.name}]}` }, false); }}>
						//         <div>{variable.name} ({`User Name Encoded`})</div>
						//         <div className="variable-editor-description">user name encoded</div>
						//     </li>
						// );
						section.push({
							name: `${variable.name} (User Name Encoded)`,
							variable: `{user_name_encoded[option_${variable.name}]}`,
							description: "user name encoded"
						});

						// returnArray.push(
						//     <li onClick={() => { this.props.click({ variable: `{currency_balance[option_${variable.name}]}` }, false); }}>
						//         <div>{variable.name} ({`Currency Balance`})</div>
						//         <div className="variable-editor-description">currency balance</div>
						//     </li>
						// );
						section.push({
							name: `${variable.name} (Currency Balance)`,
							variable: `{currency_balance[option_${variable.name}]}`,
							description: "currency balance"
						});
					} else if (variable.type == "channel") {
						// returnArray.push(
						//     <li onClick={() => { this.props.click({ variable: `{channel_id[option_${variable.name}]}` }, false); }}>
						//         <div>{variable.name} ({`Channel Id`})</div>
						//         <div className="variable-editor-description">channel id</div>
						//     </li>
						// );
						section.push({
							name: `${variable.name} (Channel Id)`,
							variable: `{channel_id[option_${variable.name}]}`,
							description: "channel id"
						});

						// returnArray.push(
						//     <li onClick={() => { this.props.click({ variable: `{channel_name[option_${variable.name}]}` }, false); }}>
						//         <div>{variable.name} ({`Channel Name`})</div>
						//         <div className="variable-editor-description">channel name</div>
						//     </li>
						// );
						section.push({
							name: `${variable.name} (Channel Name)`,
							variable: `{channel_name[option_${variable.name}]}`,
							description: "channel name"
						});

						// returnArray.push(
						//     <li onClick={() => { this.props.click({ variable: `{channel_type[option_${variable.name}]}` }, false); }}>
						//         <div>{variable.name} ({`Channel Type`})</div>
						//         <div className="variable-editor-description">channel type</div>
						//     </li>
						// );
						section.push({
							name: `${variable.name} (Channel Type)`,
							variable: `{channel_type[option_${variable.name}]}`,
							description: "channel type"
						});
					} else if (variable.type == "role") {
						// returnArray.push(
						//     <li onClick={() => { this.props.click({ variable: `{role_id[option_${variable.name}]}` }, false); }}>
						//         <div>{variable.name} ({`Role Id`})</div>
						//         <div className="variable-editor-description">role id</div>
						//     </li>
						// );
						section.push({
							name: `${variable.name} (Role Id)`,
							variable: `{role_id[option_${variable.name}]}`,
							description: "role id"
						});

						// returnArray.push(
						//     <li onClick={() => { this.props.click({ variable: `{role_name[option_${variable.name}]}` }, false); }}>
						//         <div>{variable.name} ({`Role Name`})</div>
						//         <div className="variable-editor-description">role name</div>
						//     </li>
						// );
						section.push({
							name: `${variable.name} (Role Name)`,
							variable: `{role_name[option_${variable.name}]}`,
							description: "role name"
						});
					}
				});
				// returnArray.push(
				//     <li className="variable-editor-header">Options</li>
				// );
				variableArray.push({
					name: "Options",
					variables: section
				});
			}

			// returnArray.push(
			//     <li className="variable-editor-header">Basic Variables</li>
			// );
			var section = [];
			VARIABLES.forEach((variable) => {
				if (variable.slash != false) {
					if (variable.beta != true || (variable.beta == true && this.props.beta == true)) {
						// returnArray.push(
						//     <li onClick={() => { this.props.click(variable); }}>
						//         <div style={{ marginRight: "15px" }}>{variable.label}</div>
						//         <div className="variable-editor-description">{variable.description}</div>
						//     </li>
						// );
						section.push({
							name: variable.label,
							variable: variable.variable,
							description: variable.description
						});
					}
				}
			});

			variableArray.push({
				name: "Basic Variables",
				variables: section
			});
		}

		var objVarSection = [];
		var objectVariables = ["Guild", "Channel", "Member", "User", "Role", "Invite"];
		objectVariables.forEach((objVar) => {
			//
			if (this.props.components[objVar]) {
				objVarSection.push({
					name: (
						<div className="flex flex-row items-center">
							<span className="!text-[#000000] !opacity-100 !mb-0">{objVar} Object</span>
							<InformationCircleIcon
								className="h-5 w-5 ml-1"
								onClick={(e) => {
									e.stopPropagation();
									// Go to https://docs.botghost.com/custom-commands-and-events/variables#channel-variable

									window.open(`https://docs.botghost.com/custom-commands-and-events/variables#${objVar.toLowerCase()}-variable`, "_blank");

									// this.props.click({ variable: "{Guild.}", description: "Guild Object" }, false);
								}}
							/>
						</div>
					),
					variable: `{${objVar}.}`,
					description: `${objVar} Object`
				});
			} else if (!this.props.components[objVar]) {
				objVarSection.push({
					name: (
						<div className="flex flex-row items-center">
							<span className="!text-[#212529] !opacity-100 !mb-0">{objVar} Object</span>
							<InformationCircleIcon
								className="h-5 w-5 ml-1"
								onClick={(e) => {
									e.stopPropagation();
									// Go to https://docs.botghost.com/custom-commands-and-events/variables#channel-variable

									window.open(`https://docs.botghost.com/custom-commands-and-events/variables#${objVar.toLowerCase()}-variable`, "_blank");

									// this.props.click({ variable: "{Guild.}", description: "Guild Object" }, false);
								}}
							/>
						</div>
					),
					variable: `{${objVar}[id].}`,
					description: `${objVar} Object`
				});
			}
		});

		// To the start
		variableArray.unshift({
			name: "Object Variables",
			variables: objVarSection
		});

		var listArray = [];
		variableArray.forEach((section) => {
			var tempArray = [];

			section.variables.forEach((variable) => {
				// if (this.state.filter != "" && typeof variable.name == "object") {
				// 	console.log(variable, "VARIABLE");
				// 	return;
				// }
				if (this.state.filter == "" || (typeof variable.name == "object" && variable.description.toLowerCase().includes(this.state.filter.toLocaleLowerCase())) || (typeof variable.name !== "object" && variable.name.toLowerCase().includes(this.state.filter.toLowerCase())) || variable.description.toLowerCase().includes(this.state.filter.toLowerCase()) || (variable.variable && variable.variable.toLowerCase().includes(this.state.filter.toLowerCase()))) {
					tempArray.push(
						<li
							onClick={() => {
								this.props.click({ variable: variable.variable }, false);
							}}
						>
							<div style={{ marginRight: "15px" }}>{variable.name}</div>
							<div className="variable-editor-description">{variable.description}</div>
						</li>
					);
				}
			});
			if (tempArray.length > 0) {
				listArray.push(
					<>
						<li className="variable-editor-header">{section.name}</li>
						{tempArray}
					</>
				);
			}
		});

		if (this.state.length != listArray.length) {
			this.setState({ length: listArray.length });
		}
		return listArray;
	};

	render() {
		return (
			<ul className={`variable-editor text-[#212529] ${this.props.top ? "!top-[55px]" : null}`} style={{ height: this.state.length > 10 ? "400px" : "250px" }}>
				{this.props.slashcommand == true ? (
					<li className="variable-editor-search">
						<input
							type="text"
							value={this.state.filter}
							onChange={(e) => {
								this.setState({ filter: e.target.value });
							}}
							placeholder="Search"
						></input>
					</li>
				) : null}
				{/* <li className="variable-editor-header">Available Variables</li> */}
				{this.renderVariables()}
			</ul>
		);
	}
}

const mapStateToProps = (state) => ({
	beta: state.data.beta,
	customVariables: state.data.bot.commands.variables,
	selected: state.builder.selected,
	elements: state.builder.elements,
	components: state.builder.components,
	module_id: state.builder.module_id,
	moduleSettings: state.data.bot.commands.moduleSettings
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VariableEditor);
