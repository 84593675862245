import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";

import Dashboard from "../Dashboard";

import { setBotModule } from "../../../actions";
import PremiumBadge from "../elements/PremiumBadge";
import { DocsLink } from "../elements/DocsLink";
import { ChromePicker } from "react-color";

export class Usage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      online: false,
      openColorPicker: "",
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  toggleChange = (value, type) => {
    var usage = { ...this.props.usage };

    usage[type].enabled = value;
    this.props.setBotModule({
      module: "settings",
      module_data: usage,
    });
  };

  textChange = (value) => {
    var usage = { ...this.props.usage };
    usage.errorlogs.channel_name = value;
    this.props.setBotModule({
      module: "settings",
      module_data: usage,
    });
  };

  enableModule = (value) => { };

  onColorChange = (color, key) => {
    const usage = { ...this.props.usage };

    usage.embeds.colors[key] = color.hex;

    this.props.setBotModule({
      module: "settings",
      module_data: usage,
    });
  };

  renderColorPicker = (colorPickerKey, color) => {
    if (this.state.openColorPicker == colorPickerKey) {
      return (
        <div
          className=""
          style={{ zIndex: "2", position: "absolute", right: "150px" }}
        >
          <ChromePicker
            color={color}
            onChangeComplete={(color) => {
              this.onColorChange(color, colorPickerKey);
            }}
          ></ChromePicker>
        </div>
      );
    } else {
      return null;
    }
  };
  render() {
    return (
      <Dashboard>
        <ModuleHeader
          tutorial="https://docs.botghost.com/server-management/usage"
          showEnabled={false}
          title="Usage"
          docs="https://docs.botghost.com/server-management/usage"
          description="Change how your bot operates and how members use your bot"
          icon="operation.png"
        ></ModuleHeader>

        <section>
          <SectionHeader
            title="Embed Colors"
            pretitle="Usage"
            docs={true}
            docs_link="https://docs.botghost.com/server-management/usage"
          ></SectionHeader>
          <div className="section-content">
            <div>
              <h3>
                Enable Custom Embed colors <PremiumBadge />
              </h3>
              <div className="section-content-header">
                Override and change the default embed colors for your bot
              </div>
            </div>

            {this.props.premium == true ||
              this.props.usage.embeds.enabled == true ? (
              <div style={{ marginLeft: "auto" }}>
                <Toggle
                  value={this.props.usage.embeds.enabled}
                  update={(value) => {
                    var usage = { ...this.props.usage };
                    usage.embeds.enabled = value;
                    this.props.setBotModule({
                      module: "settings",
                      module_data: usage,
                    });
                  }}
                  type="mention"
                  disabled={this.props.premium != true}
                ></Toggle>
              </div>
            ) : null}
          </div>

          {this.props.usage.embeds.enabled == true ? (
            <div>
              <div className="section-content">
                <div>
                  <h3>Default Embed Colors</h3>
                  <div className="section-content-header">
                    Change the default color for your bot's embed messages
                  </div>
                </div>

                <div style={{ marginLeft: "auto" }}>
                  <div className="levels-color-picker">
                    <div
                      class="embed-color-picker-selected"
                      style={{
                        backgroundColor: this.props.usage.embeds.colors.main,
                      }}
                      onClick={(e) => {
                        this.state.openColorPicker == "main"
                          ? this.setState({ openColorPicker: "" })
                          : this.setState({ openColorPicker: "main" });
                      }}
                    >
                      <i class="bi bi-eyedropper"></i>
                    </div>
                    {this.renderColorPicker(
                      `main`,
                      this.props.usage.embeds.colors.main
                    )}
                  </div>
                </div>
              </div>

              <div className="section-content">
                <div>
                  <h3>Mod Logs Embed Colors</h3>
                  <div className="section-content-header">
                    Change the mod logs embed colors
                  </div>
                </div>

                <div style={{ marginLeft: "auto" }}>
                  <div className="levels-color-picker">
                    <div
                      class="embed-color-picker-selected"
                      style={{
                        backgroundColor: this.props.usage.embeds.colors.modlogs,
                      }}
                      onClick={(e) => {
                        this.state.openColorPicker == "modlogs"
                          ? this.setState({ openColorPicker: "" })
                          : this.setState({ openColorPicker: "modlogs" });
                      }}
                    >
                      <i class="bi bi-eyedropper"></i>
                    </div>
                    {this.renderColorPicker(
                      `modlogs`,
                      this.props.usage.embeds.colors.modlogs
                    )}
                  </div>
                </div>
              </div>

              <div className="section-content">
                <div>
                  <h3>Warning Embed Colors</h3>
                  <div className="section-content-header">
                    Change the warning embed colors
                  </div>
                </div>

                <div style={{ marginLeft: "auto" }}>
                  <div className="levels-color-picker">
                    <div
                      class="embed-color-picker-selected"
                      style={{
                        backgroundColor: this.props.usage.embeds.colors.warning,
                      }}
                      onClick={(e) => {
                        this.state.openColorPicker == "warning"
                          ? this.setState({ openColorPicker: "" })
                          : this.setState({ openColorPicker: "warning" });
                      }}
                    >
                      <i class="bi bi-eyedropper"></i>
                    </div>
                    {this.renderColorPicker(
                      `warning`,
                      this.props.usage.embeds.colors.warning
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="section-content">
                <div>
                  <h3>Music Embed Colors</h3>
                  <div className="section-content-header">
                    Change the music embed colors
                  </div>
                </div>

                <div style={{ marginLeft: "auto" }}>
                  <div className="levels-color-picker">
                    <div
                      class="embed-color-picker-selected"
                      style={{
                        backgroundColor: this.props.usage.embeds.colors.music,
                      }}
                      onClick={(e) => {
                        this.state.openColorPicker == "music"
                          ? this.setState({ openColorPicker: "" })
                          : this.setState({ openColorPicker: "music" });
                      }}
                    >
                      <i class="bi bi-eyedropper"></i>
                    </div>
                    {this.renderColorPicker(
                      `music`,
                      this.props.usage.embeds.colors.music
                    )}
                  </div>
                </div>
              </div> */}

              <div className="section-content">
                <div>
                  <h3>Games Embed Colors</h3>
                  <div className="section-content-header">
                    Change the games embed colors
                  </div>
                </div>

                <div style={{ marginLeft: "auto" }}>
                  <div className="levels-color-picker">
                    <div
                      class="embed-color-picker-selected"
                      style={{
                        backgroundColor: this.props.usage.embeds.colors.games,
                      }}
                      onClick={(e) => {
                        this.state.openColorPicker == "games"
                          ? this.setState({ openColorPicker: "" })
                          : this.setState({ openColorPicker: "games" });
                      }}
                    >
                      <i class="bi bi-eyedropper"></i>
                    </div>
                    {this.renderColorPicker(
                      `games`,
                      this.props.usage.embeds.colors.games
                    )}
                  </div>
                </div>
              </div>

              <div className="section-content">
                <div>
                  <h3>Ticket Success Embed Colors</h3>
                  <div className="section-content-header">
                    Change the ticket success message colors
                  </div>
                </div>

                <div style={{ marginLeft: "auto" }}>
                  <div className="levels-color-picker">
                    <div
                      class="embed-color-picker-selected"
                      style={{
                        backgroundColor: this.props.usage.embeds.colors.ticket_success,
                      }}
                      onClick={(e) => {
                        this.state.openColorPicker == "ticket_success"
                          ? this.setState({ openColorPicker: "" })
                          : this.setState({ openColorPicker: "ticket_success" });
                      }}
                    >
                      <i class="bi bi-eyedropper"></i>
                    </div>
                    {this.renderColorPicker(
                      `ticket_success`,
                      this.props.usage.embeds.colors.ticket_success
                    )}
                  </div>
                </div>
              </div>

              <div className="section-content">
                <div>
                  <h3>Ticket Error Embed Colors</h3>
                  <div className="section-content-header">
                    Change the ticket error message colors
                  </div>
                </div>

                <div style={{ marginLeft: "auto" }}>
                  <div className="levels-color-picker">
                    <div
                      class="embed-color-picker-selected"
                      style={{
                        backgroundColor: this.props.usage.embeds.colors.ticket_error,
                      }}
                      onClick={(e) => {
                        this.state.openColorPicker == "ticket_error"
                          ? this.setState({ openColorPicker: "" })
                          : this.setState({ openColorPicker: "ticket_error" });
                      }}
                    >
                      <i class="bi bi-eyedropper"></i>
                    </div>
                    {this.renderColorPicker(
                      `ticket_error`,
                      this.props.usage.embeds.colors.ticket_error
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </section>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  usage: state.data.bot.commands.settings,
  premium: state.data.premium,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Usage);
