import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocsLink from '../DocsLink'
import DiscordMessagePreview from '../DiscordMessagePreview'

import TextArea from "../TextArea"

export class PlainText_Action extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }


    componentDidMount() {
        if(!("response" in this.props.data)){
            this.props.update("response","")
        }   
    }
    
    render() {
        return (
            <div className="mb-15">
                <h3 style={{ marginBottom: "0px" }}>Plain Text Response <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions/plain-text-response"}></DocsLink></h3>
                <div className="section-content-header mb-15">The response of this action</div>
                <TextArea variableEditor={true} slash={true} slash_options ={this.props.options} required={true} value={this.props.data.response} onChange={(value) =>{this.props.update("response",value)}}/>
                <div className="mb-15 slash-action">
                    <h4>Preview </h4>
                    <span>A preview of your action</span>
                </div>

                <DiscordMessagePreview
                type="plain_text"
                response={this.props.data.response}
                interaction={this.props.preview_interaction || false}
                interaction_name={this.props.name}
                buttons={this.props.buttons}
            />



        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(PlainText_Action)
