import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";

import { updateElementData } from "../../../../actions";

import TextArea from "../../elements/TextArea";

import Select from "../../elements/Select";

import { checkIntegrationChild } from "../eventUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import VariableTextInput from "../VariableTextInput";
import BuilderVarEditor from "../BuilderVarEditor";

export class SetVariable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dataType: "text",
			showVariableEditor: false,
			user: false,
			channel: false
		};
	}

	componentDidMount() {
		if (this.props.data == undefined) {
			var target = { user_id: "" };
			if (this.props.components["User"]) {
				target = { self: true };
			}
			this.props.updateElementData({
				data: {
					type: "set_var",
					reference: "",
					value: "",
					key: "",
					target: target,
					operation: "unshift",
					validated: false
				},
				id: this.props.id
			});
		} else {
			var reference = this.props.data.reference;
			var variables_array = this.props.custom_variables.variables;
			var variable_settings = variables_array.find((v) => v.reference == reference);
			if (!variable_settings) return;
			var type = variable_settings.type;
			var user = false;
			var channel = false;
			if (type == "user") {
				type = variable_settings.dataType;
				user = true;
			} else if (type == "channel") {
				type = variable_settings.dataType;
				channel = true;
			}
			this.setState({ user: user, channel: channel, dataType: type });
		}
	}

	targetOnChange = (value) => {
		if (value == this.state.user) {
			return;
		}
		// {label:"User who used the command",value:"self"},
		// {label:"User variable",value:"variable"},
		// {label:"User ID",value:"id"},
		if (value == "self") {
			var data = { ...this.props.data };
			data.target = {
				self: true
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		} else if (value == "variable") {
			var data = { ...this.props.data };
			data.target = {
				variable: ""
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		} else if (value == "id") {
			var data = { ...this.props.data };
			data.target = {
				user_id: ""
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		}

		this.setState({ user: value });
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "set_var",
					reference: "",
					value: "",
					target: { self: true },
					validated: false
				},
				id: this.props.id
			});
		} else {
			// VALIDATE
			if (this.props.id != prevProps.id && this.props.data && this.props.data.reference) {
				var reference = this.props.data.reference;
				var variables_array = this.props.custom_variables.variables;
				var variable_settings = variables_array.find((v) => v.reference == reference);

				if (!variable_settings) return;

				var type = variable_settings.type;
				var user = false;
				var channel = false;
				if (type == "user") {
					type = variable_settings.dataType;
					user = true;
				} else if (type == "channel") {
					type = variable_settings.dataType;
					channel = true;
				}

				if (type == "object") {
					// Set key
					if (!this.props.data.key) {
						this.updateData("key", "");
					}
				}
				this.setState({ user: user, channel: channel, dataType: type });
			}

			// if (this.state.dataType == "object" && this.props.data.key != "") {
			// 	if (this.props.data.validated == false) {
			// 		this.updateData("validated", true);
			// 	}
			// } else if (this.state.dataType == "object" && this.props.data.validated == true) {
			// 	this.updateData("validated", false);
			// }

			if (((this.props.data.operation === "remove_at_position" && this.props.data.position) || (this.props.data.operation === "set_at_position" && this.props.data.position && this.props.data.value) || this.props.data.value != "" || this.props.data.operation == "remove_all" || this.props.data.operation == "shift" || this.props.data.operation == "pop") && this.props.data.reference != "") {
				if (this.props.data.validated == false) {
					this.updateData("validated", true);
				}
			} else if (this.props.data.validated == true) {
				this.updateData("validated", false);
			}

			// if (this.state.dataType == "object" && this.props.data.key) {
			// 	if (this.props.data.validated == false) {
			// 		this.updateData("validated", true);
			// 	}
			// } else if (this.props.data.validated == true) {
			// 	this.updateData("validated", false);
			// }
		}
	}

	nameChange = (value) => {
		value = value.toLowerCase();
		value = value.substring(0, 32);
		value = value.replace(" ", "-");
		const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/gu);
		value = value.replace(regexForNonAlphaNum, "");
		this.updateData("name", value);
	};

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	renderResponseTypes = () => {
		var user = "User who used the command";
		if (this.props.mode == "event" && this.props.components["User"]) {
			user = "The user.";
		}
		// const responseTypes = [
		//     { value: "reply", label: "Reply to the command", disabled: this.props.components.Interaction? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
		//     { value: "trigger_channel", label: `Send the message to the ${channel}`, disabled: this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true }
		// ];

		const responseTypes = [
			{ label: user, value: "self", disabled: this.props.components.Interaction || this.props.components.User ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "User option", value: "variable", disabled: this.props.mode == "event" ? true : false },
			{ label: "User ID", value: "id" }
		];

		return responseTypes;
	};

	checkUserOption = () => {
		// Check to see if a user option exists in the command tree
	};

	renderCollectionOperations = () => {
		var elements = [];
		var operation = this.props.data.operation;
		// value is required
		if (operation == "unshift" || operation == "push" || operation == "set_at_position" || operation == "remove_value") {
			var description = "";
			if (operation == "unshift") {
				description = "Add this value to the beginning of the collection.";
			} else if (operation == "push") {
				description = "Add this value to the end of the collection.";
			} else if (operation == "set_at_position") {
				description = "Set this value at the specified position.";
			} else if (operation == "remove_value") {
				description = "Remove this value from the collection.";
			}

			elements.push(
				<div className="mb-15 slash-action">
					<h4>
						Value <DocsLink location="https://docs.botghost.com/data-storage/data-storage/collections#set-variable-options" />
					</h4>
					<span>{description}</span>

					<TextArea
						variableEditor={true}
						slash={true}
						slash_options={this.props.options}
						value={this.props.data.value}
						onChange={(value) => {
							this.updateData("value", value);
						}}
					/>
				</div>
			);
		}
		// POSITION required
		if (operation == "set_at_position" || operation == "remove_at_position") {
			var description = "";
			if (operation == "set_at_position") {
				description = "The position to set this value at. Collection positions start at 0.";
			} else if (operation == "remove_at_position") {
				description = "The position to remove a value from. Collection positions start at 0.";
			}
			elements.push(
				<>
					<h4 style={{ marginBottom: "0px" }}>
						Position
						<DocsLink location="https://docs.botghost.com/data-storage/data-storage/collections#set-variable-options" />
					</h4>

					<div className="section-content-header mb-15">{description}</div>

					<VariableTextInput
						required={true}
						slash_options={this.props.options}
						label="Position"
						placeholder="0"
						value={this.props.data.position}
						onChange={(value) => {
							this.updateData("position", value);
						}}
					/>

					{/* <div className="long-input mb-15" style={{ width: "100%" }}>
                        <label>Position</label>
                        <input value={this.props.data.position} className={`${this.props.data.position == "" ? "command-required" : null}`} placeholder="0" required type="text" onChange={(e) => {

                            this.updateData("position", e.target.value);
                        }}></input>
                    </div> */}
				</>
			);
		}
		return elements;
	};

	disableCheck = () => {
		return this.props.components.Interaction || this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true;
	};

	setVariable = (value) => {
		var variables_array = this.props.custom_variables.variables;
		var variable_settings = variables_array.find((v) => v.reference == value);
		if (!variable_settings) return;
		var type = variable_settings.type;
		var user = false;
		var channel = false;
		if (type == "user") {
			type = variable_settings.dataType;
			user = true;
		} else if (type == "channel") {
			type = variable_settings.dataType;
			channel = true;
		}

		var data = { ...this.props.data };
		data.reference = value;

		if (channel) {
			data.target = this.disableCheck() ? { id: "" } : { self: true };
		}

		this.props.updateElementData({
			data: data,
			id: this.props.id
		});

		this.setState({ dataType: type, user: user, channel: channel });
	};

	renderChannelTypes = () => {
		var channel = "Channel the command was used in";
		if (this.props.mode == "event" && this.props.components["Channel"]) {
			channel = "The channel.";
		}
		// const responseTypes = [
		//     {value: "reply", label: "Reply to the command", disabled: this.props.components.Interaction? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
		//     {value: "trigger_channel", label: `Send the message to the ${channel}`, disabled: this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true }
		// ];

		const responseTypes = [{ label: "Specific channel or variable", value: "id" }];

		if (!this.disableCheck()) {
			responseTypes.push({ label: channel, value: "self", disabled: this.props.components.Interaction || this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true });
		}

		return responseTypes;
	};

	renderDisableField = () => {
		var disabled = true;
		if (this.props.data.target.reply == true && (this.props.components.Interaction || checkIntegrationChild(this.props.elements, this.props.id))) {
			disabled = false;
		} else if (this.props.data.target.trigger_channel == true && (this.props.components.Channel || checkIntegrationChild(this.props.elements, this.props.id))) {
			disabled = false;
		}
		return disabled;
	};

	render() {
		if (this.props.data != undefined) {
			return (
				<div>
					<BuilderVarEditor
						close={(newVar) => {
							this.setState({ showVariableEditor: false });
							if (newVar) {
								this.setVariable(newVar);
							}
						}}
						showModal={this.state.showVariableEditor}
					/>
					<div className="mb-15 flex flex-row itesm-center">
						<div>
							<h4>Set Variable</h4>
							<p>Set the value of a custom variable.</p>
						</div>

						<div className="ml-auto">
							<button
								className="btn btn-red px-2 text-sm"
								onClick={() => {
									this.setState({ showVariableEditor: true });
								}}
							>
								New Variable
							</button>
						</div>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Custom Variable <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/set-variable#custom-variable" />
						</h4>
						<span>The Custom Variable to set the value of. </span>
						<Select
							type="custom_variables"
							value={this.props.data.reference}
							onChange={(value) => {
								// console.log("run here number 1");
								// this.updateData("reference", value);
								console.log(value);
								this.setVariable(value);
							}}
						></Select>
					</div>

					{this.state.user ? (
						<>
							<div className="mb-15 slash-action">
								<h4>
									User Target <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/set-variable#user-target" />
								</h4>
								<span>Which user to set the custom variable of.</span>
								<Select
									value={"self" in this.props.data.target ? "self" : "variable" in this.props.data.target ? "variable" : "user_id" in this.props.data.target ? "id" : ""}
									onChange={(value) => {
										this.targetOnChange(value);
										// value = JSON.parse(value);
										// this.setState({value:value})
									}}
									options={this.renderResponseTypes()}
								></Select>
							</div>

							{"variable" in this.props.data.target ? (
								<div className="mb-15 slash-action">
									<h4>User Option </h4>
									<span>The user option to set the custom variable of.</span>

									<VariableTextInput
										required={true}
										slash_options={this.props.options}
										label="Option"
										placeholder="{option_user}"
										value={this.props.data.target.variable}
										onChange={(value) => {
											var data = { ...this.props.data };
											data.target = {
												variable: value
											};
											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										}}
									/>
									{/* <div class="long-input mt-15">
                                            <label>Option</label>
                                            <input onChange={(e) => {

                                                var data = { ... this.props.data };
                                                data.target = {
                                                    variable: e.target.value,
                                                };
                                                this.props.updateElementData(
                                                    {
                                                        data: data,
                                                        id: this.props.id
                                                    }
                                                );

                                            }} type="text" placeholder={"{option_user}"} value={this.props.data.target.variable} />
                                        </div> */}
								</div>
							) : null}

							{"user_id" in this.props.data.target ? (
								<div className="mb-15 slash-action">
									<h4>User ID </h4>
									<span>The user id to set the custom variable of.</span>
									<VariableTextInput
										required={true}
										slash_options={this.props.options}
										label="ID"
										placeholder="136327647792726016"
										value={this.props.data.target.user_id}
										onChange={(value) => {
											var data = { ...this.props.data };
											data.target = {
												user_id: value
											};
											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										}}
									/>
									{/* <div class="long-input mt-15">
                                            <label>ID</label>
                                            <input onChange={(e) => {

                                                var data = { ... this.props.data };
                                                data.target = {
                                                    user_id: e.target.value,
                                                };
                                                this.props.updateElementData(
                                                    {
                                                        data: data,
                                                        id: this.props.id
                                                    }
                                                );

                                            }} type="text" placeholder={"136327647792726016"} value={this.props.data.target.user_id} />
                                        </div> */}
								</div>
							) : null}

							<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>
						</>
					) : null}

					{this.state.channel ? (
						<>
							<div className="mb-15 slash-action">
								<h4>Channel Target </h4>
								<span>Which channel to set the custom variable of.</span>
								<Select
									value={"self" in this.props.data.target ? "self" : "id"}
									onChange={(value) => {
										if (value == this.state.channel) {
											return;
										}
										// {label:"User who used the command",value:"self"},
										// {label:"User variable",value:"variable"},
										// {label:"User ID",value:"id"},
										if (value == "self") {
											var data = { ...this.props.data };
											data.target = {
												self: true
											};
											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										} else if (value == "id") {
											var data = { ...this.props.data };
											data.target = {
												id: ""
											};
											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										}

										this.setState({ channel: value });

										// value = JSON.parse(value);
										// this.setState({value:value})
									}}
									options={this.renderChannelTypes()}
								></Select>
							</div>

							{"id" in this.props.data.target ? (
								<div className="mb-15 slash-action">
									<h4>Channel ID or Variable </h4>
									<span>The id or variable of a channel to set the custom variable of.</span>

									<VariableTextInput
										required={true}
										slash_options={this.props.options}
										label="ID or Variable"
										placeholder="136327647792726016"
										value={this.props.data.target.id}
										onChange={(value) => {
											var data = { ...this.props.data };
											data.target = {
												id: value
											};
											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										}}
									/>

									{/* <div class="long-input mt-15">
                                        <label>ID or Variable</label>
                                        <input onChange={(e) => {

                                            var data = { ... this.props.data };
                                            data.target = {
                                                id: e.target.value,
                                            };
                                            this.props.updateElementData(
                                                {
                                                    data: data,
                                                    id: this.props.id
                                                }
                                            );

                                        }} type="text" placeholder={"136327647792726016"} value={this.props.data.target.id} />
                                    </div> */}
								</div>
							) : null}
						</>
					) : null}

					{/* Text Value */}
					{this.state.dataType == "text" ? (
						<div className="mb-15 slash-action">
							<h4>
								Value <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/set-variable#value" />
							</h4>
							<span>The value to set this variable to.</span>

							<TextArea
								variableEditor={true}
								slash={true}
								slash_options={this.props.options}
								value={this.props.data.value}
								onChange={(value) => {
									this.updateData("value", value);
								}}
							/>
						</div>
					) : null}

					{/* Number */}
					{this.state.dataType == "number" ? (
						<div className="mb-15 slash-action">
							<h4>
								Value <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/set-variable#value" />
							</h4>
							<span>The value to set this variable to.</span>

							<VariableTextInput
								required={true}
								slash_options={this.props.options}
								label="Value"
								placeholder="0"
								value={this.props.data.value}
								onChange={(value) => {
									this.updateData("value", value);
								}}
							/>
							{/* <div class="long-input mt-15">
                                <label>Value</label>
                                <input className={`${this.props.data.value == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {

                                    this.updateData("value", e.target.value);

                                }} type="text" value={this.props.data.value} />
                            </div> */}
						</div>
					) : null}

					{this.state.dataType == "array" ? (
						<>
							<div className="mb-15 slash-action">
								<h4>
									Collection Operation
									<DocsLink location="https://docs.botghost.com/data-storage/data-storage/collections#set-variable-options" />
								</h4>
								<span>What type of operation to perform on the collection.</span>
								<Select
									value={this.props.data.operation}
									onChange={(value) => {
										this.updateData("operation", value);
									}}
									options={[
										{ label: "Push value to front", value: "unshift" },
										{ label: "Push value to end", value: "push" },
										{ label: "Remove first value", value: "shift" },
										{ label: "Remove last value", value: "pop" },
										{ label: "Set value at position", value: "set_at_position" },
										{ label: "Remove value at position", value: "remove_at_position" },
										{ label: "Remove value in collection", value: "remove_value" },
										{ label: "Clear collection", value: "remove_all" }
									]}
								></Select>
							</div>

							<div>{this.renderCollectionOperations()}</div>
						</>
					) : null}

					{this.state.dataType == "object" ? (
						<>
							{/* Key Input */}
							<div className="mb-15 slash-action">
								<h4>
									Key <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/set-variable#key" />
								</h4>
								<span>The key to set the value to in the object.</span>

								<VariableTextInput
									required={true}
									slash_options={this.props.options}
									label="Key"
									placeholder="key"
									value={this.props.data.key}
									onChange={(value) => {
										// value = value.substring(0, 100);
										// // value = value.replace(" ","-")
										// value = value.split(" ").join("_");
										// const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_]+/gu);
										// value = value.replace(regexForNonAlphaNum, "");
										// // console.log(value, 'VALUE NOW ');

										this.updateData("key", value);
									}}
								/>
							</div>

							{/* Value Input */}

							<div className="mb-15 slash-action">
								<h4>
									Value <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/set-variable#value" />
								</h4>
								<span>The value to set this variable to.</span>

								<TextArea
									variableEditor={true}
									slash={true}
									slash_options={this.props.options}
									value={this.props.data.value}
									onChange={(value) => {
										this.updateData("value", value);
									}}
								/>
							</div>
						</>
					) : null}

					<div className="slash-action">
						{this.state.dataType != "object" ? (
							<span>
								You can use this variable by using <span style={{ color: "#f45142", opacity: 1, fontWeight: "800" }}>{`{BGVAR_${this.props.data.reference}}`}</span>{" "}
								<FontAwesomeIcon
									icon={faClipboard}
									fixedWidth
									className="text-[#9d9b9b] text-sm hover:text-red hover:cursor-pointer"
									onClick={() => {
										navigator.clipboard.writeText(`{BGVAR_${this.props.data.reference}}`);
										toast.success("Copied variable to clipboard");
									}}
								/>{" "}
								in other actions.
							</span>
						) : (
							<span>
								You can use this variable by using <span style={{ color: "#f45142", opacity: 1, fontWeight: "800" }}>{`{BGVAR_${this.props.data.reference}.${this.props.data.key}}`}</span>{" "}
								<FontAwesomeIcon
									icon={faClipboard}
									fixedWidth
									className="text-[#9d9b9b] text-sm hover:text-red hover:cursor-pointer"
									onClick={() => {
										navigator.clipboard.writeText(`{BGVAR_${this.props.data.reference}.${this.props.data.key}}`);
										toast.success("Copied variable to clipboard");
									}}
								/>{" "}
								in other actions.
							</span>
						)}
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	saveFailed: state.builder.saveFailed,
	custom_variables: state.data.bot.commands.variables,
	components: state.builder.components,
	mode: state.builder.mode
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(SetVariable);
