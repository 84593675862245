import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setElements } from '../../../../actions';
import getLayoutedElements from "../getLayoutElements";
import Embed from '../../elements/Embed';
import TextArea from '../../elements/TextArea';
import Select from '../../elements/Select';
import RefreshBotData from '../../elements/RefreshBotData';
import { addEdge, getOutgoers } from 'react-flow-renderer';
import { EmojiAdder } from './EmojiAdder';
import RoleAdd from "../../elements/RoleAdd";
import splitElements from '../splitElements';
import { checkIntegrationChild } from '../eventUtils';
import VariableTextInput from '../VariableTextInput';

const responseTypes = [
    { value: "reply", label: "Reply to the command" },
    { value: "targeted", label: "Send the message to a specific text-channel" }
];


export class ComparisonCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dataType: "text",
            type: "less_than",
            comparison_value: "",
            value: ""
        };
    }

    componentDidMount() {
        if (this.props.data == undefined) {
            var target = { user_id: "" };
            if (this.props.components['User']) {
                target = { self: true };
            }
            this.props.updateElementData(
                {
                    data: {
                        type: "condition",
                        condition_type: "comparison",
                        base_value: "10",
                        multiple: false,
                        target: target,
                        validated: true
                    },
                    id: this.props.id
                }
            );
        } else {

            var reference = this.props.data.reference;
            var variables_array = this.props.custom_variables.variables;
            var variable_settings = variables_array.find(v => v.reference == reference);
            if (!variable_settings) return;
            var type = variable_settings.type;
            var user = false;
            if (type == 'user') {
                type = variable_settings.dataType;
                user = true;
            }
            this.setState({ user: user, dataType: type });

        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "condition",
                        condition_type: "comparison",
                        multiple: false,
                        validated: true
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE
        }
    }





    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };


    renderOptions = () => {
        var options = [
            { value: "", label: "" }
        ];
        this.props.options.forEach(option => {
            options.push({ value: option.name, label: option.name });
        });
        return options;
    };

    selectUpdate = (value) => {
        value = JSON.parse(value);
        var response_options = { ...this.props.data.response_options };
        response_options.target = value;
        this.updateData("response_options", response_options);
    };

    addCondition = () => {
        var elements = [...this.props.elements];
        var element = this.props.elements.find(element => element.id == this.props.selected);
        var existingConditions = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);


        var id = `conChild${elements.length + 1}_${s4()}`;
        var lastChild = existingConditions[existingConditions.length - 1];

        var docuElement = document.getElementById(lastChild.id);
        var width = 0;
        if (docuElement) {
            width = docuElement.clientWidth;
        }



        elements.push(
            {
                id: id,
                type: 'conditionChild',
                data: {
                    node_options: {
                        type: "comparison"
                    },
                    data: {
                        type: "less_than",
                        comparison_value: this.state.comparison_value,
                        actions: []
                    }

                },
                // target:"3",
                draggable: true,
                position: { x: lastChild.position.x + width + 20, y: lastChild.position.y },
            },
        );
        var newEdges = addEdge({
            source: element.id,
            target: id,
            type: "step",
            animated: false,
            arrowHeadType: "arrowclosed"

        }, splitElements(elements).edges);
        newEdges.forEach(newEdge => {
            var index = elements.findIndex(element => element.id == newEdge.id);
            if (index == -1) {
                elements.push(newEdge);
            }
            // elements[index] = newNode;
        });
        // elements = getLayoutedElements(elements)
        this.props.setElements(elements);
        this.setState({ comparison_value: "" });



    };

    renderSelectOptions = (type) => {
        var options = [
            // {value:"trigger",label:"The member who triggered the command"}
        ];
        this.props.options.forEach(option => {
            if (option.type == 'role') {
                options.push({ value: { id: `{option_${option.name}}`, name: option.name }, label: `Role Option: ${option.name}` });

            }

        });
        return options;
    };

    targetOnChange = (value) => {
        if (value == this.state.user) {
            return;
        }
        // {label:"User who used the command",value:"self"},
        // {label:"User variable",value:"variable"},
        // {label:"User ID",value:"id"},
        if (value == "self") {
            var data = { ... this.props.data };
            data.target = {
                self: true,
            };
            this.props.updateElementData(
                {
                    data: data,
                    id: this.props.id
                }
            );

        } else if (value == "variable") {
            var data = { ... this.props.data };
            data.target = {
                variable: "",
            };
            this.props.updateElementData(
                {
                    data: data,
                    id: this.props.id
                }
            );
        } else if (value == "id") {
            var data = { ... this.props.data };
            data.target = {
                user_id: '',
            };
            this.props.updateElementData(
                {
                    data: data,
                    id: this.props.id
                }
            );
        }

        this.setState({ user: value });

    };

    renderResponseTypes = () => {
        var user = "User who used the command";
        if (this.props.mode == "event" && this.props.components['User']) {
            user = "The user.";
        }
        // const responseTypes = [
        //     { value: "reply", label: "Reply to the command", disabled: this.props.components.Interaction? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
        //     { value: "trigger_channel", label: `Send the message to the ${channel}`, disabled: this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true }
        // ];

        const responseTypes = [
            { label: user, value: "self", disabled: this.props.components.Interaction || this.props.components.User ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
            { label: "User option", value: "variable", disabled: this.props.components.Interaction ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
            { label: "User ID", value: "id" },
        ];





        return responseTypes;

    };
    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Comparison Condition <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/comparison-condition" /></h4>
                        <p>Run actions based on the difference between two values. This condition acts as an If statement.</p>
                    </div>

                    <div className='mb-15 slash-action'>
                        <h4>Run multiple actions <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/comparison-condition#run-multiple-actions" /> </h4>
                        <span>Should the condition run multiple actions if multiple conditions are met.</span>
                        <Select value={this.props.data.multiple} onChange={(value) => {

                            this.updateData('multiple', value == "true" ? true : false);
                            // value = JSON.parse(value);
                            // this.setState({value:value})
                        }} options={[
                            { label: "Yes", value: true },
                            { label: "No", value: false },

                        ]} ></Select>

                    </div>






                    {/* Number */}

                    <div className='mb-15 slash-action'>
                        <h4>Base Value <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/comparison-condition#base-value" /></h4>
                        <span>The value to base this condition. All variables and options can be used. All comparisons will be made against this value.</span>


                        <VariableTextInput label={"Base Value"} requried={true} slash_options={this.props.options} value={this.props.data.base_value} onChange={(value) => {
                            this.updateData("base_value", value);
                        }} />

                        {/* <div class="long-input mt-15">
                            <label>Base Value</label>
                            <input className={`${this.props.data.base_value == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                this.updateData('base_value', e.target.value);

                            }} type="text" value={this.props.data.base_value} />
                        </div> */}
                    </div>

                    {/* <div className='mb-15 slash-action'>
                        <h4>Condition Type </h4>
                        <span>The condition type.</span>
                        <Select value={this.state.type} onChange={(value) => {
                            this.setState({ type: value });
                            // value = JSON.parse(value);
                            // this.setState({value:value})
                        }} options={[
                            { label: "Less than", value: "less_than" },
                            { label: "Less than or equal to", value: "less_than_equal_to" },
                            { label: "Greater than", value: "greater_than" },
                            { label: "Greater than or equal to", value: "greater_than_equal_to" },
                            { label: "Equal to", value: "equal_to" },
                            { label: "Not equal to", value: "not" },

                        ]} ></Select>

                    </div> */}
                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>

                    <div className='mb-15 slash-action'>
                        <h4>Comparison Value <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/comparison-condition#comparison-value" /></h4>
                        <span>The value to compare against the base value of this condition.</span>

                        <VariableTextInput label={"Comparison Value"} requried={true} slash_options={this.props.options} value={this.state.comparison_value} onChange={(value) => {
                            this.setState({ comparison_value: value });
                        }} />

                        {/* <div class="long-input mt-15">
                            <label>Comparison Value</label>
                            <input className={`${this.state.comparison_value == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                this.setState({ comparison_value: e.target.value });

                            }} type="text" value={this.state.comparison_value} />
                        </div> */}
                    </div>
                    <div>
                        <button disabled={this.state.comparison_value == "" || this.props.data.base_value == "" ? true : false} onClick={() => { this.addCondition(); }} className="btn btn-red add-button">Add Condition</button>

                    </div>


                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    custom_variables: state.data.bot.commands.variables,
    components: state.builder.components,
    mode: state.builder.mode,
});
const mapDispatchToProps = {
    updateElementData,
    setElements
};

let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
};

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonCondition);
