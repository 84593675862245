import React, { Component } from "react";
import { connect } from "react-redux";

import Dashboard from "../Dashboard";
import ModuleHeader from "../elements/ModuleHeader";
import SectionHeader from "../elements/SectionHeader";

import PremiumBadge from "../elements/PremiumBadge";

import { CopyToClipboard } from "react-copy-to-clipboard";
import PremiumSection from "../elements/PremiumSection";
import { setBotSettings, setBotModule } from "../../../actions";
import Select from "../elements/Select";

const STATUS_OPTIONS = [
  { label: "Listening", value: "LISTENING" },
  { label: "Playing", value: "PLAYING" },
  { label: "Watching", value: "WATCHING" },
];

const STATUS_MODES = [
  { label: "Fixed", value: "fixed" },
  { label: "Rotating", value: "rotating" },
];

const STATUS_PRESENCE = [
  { label: "ONLINE", value: "online" },
  { label: "INVISIBLE", value: "invisible" },
  { label: "IDLE", value: "idle" },
  { label: "DND", value: "dnd" },
];

export class Status extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
      mode: "fixed",
      new_status: "",
      duration: 10,
      new_status_type: "LISTENING",
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    if (
      "status" in this.props.bot.commands &&
      this.props.bot.commands.status.enabled == true &&
      this.props.premium == true
    ) {
      this.setState({
        mode: "rotating",
        duration: this.props.bot.commands.status.duration,
      });
    }
  }

  handleChange = (value) => {
    const type = { type: value };
    this.props.setBotSettings({ value: type, key: "statusopt" });
  };

  renderStatuses = (status) => {
    var statuses = [];
    this.props.bot.commands.status.statuses.forEach((status, index) => {
      const deleteStatus = () => {
        var statusObj = { ...this.props.bot.commands.status };
        statusObj.statuses.splice(index, 1);
        this.props.setBotModule({
          module: "status",
          module_data: statusObj,
        });
      };
      statuses.push(
        <div className="section-content-normal" style={{ display: "flex" }}>
          <div>
            <h3 className="mb-0">{status.status}</h3>
            <div className="section-content-header">{status.type}</div>
          </div>

          <div style={{ marginLeft: "auto" }}>
            <button
              className="btn btn-red"
              onClick={() => {
                deleteStatus();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      );
    });
    return statuses;
  };

  addRotatingStatus = (e) => {
    e.preventDefault();
    var status = { ...this.props.bot.commands.status };

    var new_status = {
      status: this.state.new_status,
      type: this.state.new_status_type,
    };
    status.statuses.push(new_status);
    this.setState({
      new_status: "",
      new_status_type: "LISTENING",
    });
    this.props.setBotModule({
      module: "status",
      module_data: status,
    });
  };

  changeMode = (value) => {
    var status = { ...this.props.bot.commands.status };
    if (
      this.props.premium == true &&
      value == "rotating" &&
      status.enabled == false
    ) {
      var status = { ...this.props.bot.commands.status };
      status.enabled = true;
      this.props.setBotModule({
        module: "status",
        module_data: status,
      });
    } else if (status.enabled == true) {
      var status = { ...this.props.bot.commands.status };
      status.enabled = false;
      this.props.setBotModule({
        module: "status",
        module_data: status,
      });
    }

    this.setState({ mode: value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.mode != "rotating" &&
      "status" in this.props.bot.commands &&
      this.props.bot.commands.status.enabled == true &&
      this.props.premium == true
    ) {
      this.setState({
        mode: "rotating",
        duration: this.props.bot.commands.status.duration,
      });
    }
  }

  intervalTime = (value) => {
    var status = { ...this.props.bot.commands.status };
    var duration = this.state.duration;
    if (duration <= 10) {
      duration = 10;
      this.setState({ duration: 10 });
    }

    status.duration = parseInt(duration);
    this.props.setBotModule({
      module: "status",
      module_data: status,
    });
  };

  changePresence = (value) => {
    var status = { ...this.props.bot.statusopt };

    status.status = value;
    this.props.setBotSettings({ value: status, key: "statusopt" });
  };

  render() {
    return (
      <Dashboard>
        <ModuleHeader
          premiumRequired={true}
          showEnabled={true}
          enabledValue={this.props.bot.commands.status.enabled}
          change={value => {
            var status = { ...this.props.bot.commands.status };
            status.enabled = value;
            this.props.setBotModule({
              module: "status",
              module_data: status,
            });

          }}
          icon="wrench.png"
          title="Status"
          description="Replace the 'Made with BotGhost.com' status with your own"
        ></ModuleHeader>

        {/* <PremiumSection>
            <SectionHeader title="Bot Presence" pretitle="Status"></SectionHeader>
                <div className="section-content-normal">
                    <div class="mb-15">

                        <h3 style={{ marginBottom: "0px" }}>Bot Presence </h3>
                        <div className="section-content-header">How your bot should appear. Either Online, Invisible, DND or Idle. This may take up to 5 minutes to take effect.</div>
                        </div>
                    <Select options={STATUS_PRESENCE} value={this.props.bot.statusopt.status} onChange={(value) =>{this.changePresence(value)}}/>
                </div>

            </PremiumSection> */}

        <PremiumSection>
          <SectionHeader title="Status Mode" pretitle="Status"></SectionHeader>
          <div className="section-content-normal">
            <div class="mb-15">
              <h3 style={{ marginBottom: "0px" }}>Status Mode </h3>
              <div className="section-content-header">
                Select either a fixed or rotating status message
              </div>
            </div>
            <Select
              disabled={this.props.premium == false}
              options={STATUS_MODES}
              value={this.state.mode}
              onChange={(value) => {
                this.changeMode(value);
              }}
            />
          </div>
        </PremiumSection>
        {this.state.mode == "fixed" ? (
          <PremiumSection>
            <SectionHeader
              title="Fixed Status"
              pretitle="Status"
            ></SectionHeader>

            <div className="section-content-normal">
              <div class="mb-15">
                <h3 style={{ marginBottom: "0px" }}>Bot Presence </h3>
                <div className="section-content-header">
                  Your bot's presence/status text{" "}
                  {this.props.beta == true
                    ? "The variable {bot_serverCount} can be used"
                    : null}
                </div>
              </div>

              <div class="long-input">
                <label>Bot Presence</label>
                <input
                  disabled={this.props.premium == false}
                  value={this.props.data.bot.status}
                  type="text"
                  onChange={(e) => {
                    // if(this.props.premium){
                    this.props.setBotSettings({
                      value: e.target.value,
                      key: "status",
                    });
                    // }
                  }}
                ></input>
              </div>
            </div>

            <div className="section-content-normal">
              <div class="mb-15">
                <h3 style={{ marginBottom: "0px" }}>Bot Presence </h3>
                <div className="section-content-header">
                  Your bot's presence/status text
                </div>
              </div>

              <Select
                disabled={this.props.premium == false}
                value={this.props.bot.statusopt.type}
                onChange={this.handleChange}
                options={STATUS_OPTIONS}
              ></Select>
            </div>
          </PremiumSection>
        ) : (
          <PremiumSection>
            <SectionHeader title="Rotating Status" pretitle="Status" />

            <div className="section-content-normal">
              <div class="mb-15">
                <h3 style={{ marginBottom: "0px" }}>
                  Update Duration <PremiumBadge />{" "}
                </h3>
                <div className="section-content-header">
                  How often your bot's status message should change in seconds
                </div>
              </div>

              <div class="long-input">
                <label>Update Duration</label>
                <input
                  disabled={this.props.premium == false}
                  placeholder="10"
                  min="10"
                  value={this.state.duration}
                  type="number"
                  onChange={(e) => {
                    this.setState({ duration: e.target.value });
                  }}
                ></input>
              </div>

              <button
                disabled={this.props.premium == false}
                className="btn btn-red mt-15"
                onClick={() => {
                  this.intervalTime();
                }}
              >
                Set Duration
              </button>
            </div>

            <div className="section-content-normal">
              <div class="mb-15">
                <h3 style={{ marginBottom: "0px", fontSize: "26px" }}>
                  Add Status Message <PremiumBadge />
                </h3>
                <div className="section-content-header">
                  Add a status message to your rotating status queue
                </div>
              </div>

              <hr class="slashcommand-hr" />

              <form
                className="mb-15"
                onSubmit={(e) => {
                  this.addRotatingStatus(e);
                }}
              >
                <div class="mb-15">
                  <h3 style={{ marginBottom: "0px" }}>Status message </h3>
                  <div className="section-content-header">
                    The content of your status message{" "}
                    {this.props.beta == true
                      ? "The variables {bot_serverCount} can be used"
                      : null}
                  </div>
                </div>

                <div className="long-input mb-15">
                  <label>Status</label>
                  <input
                    disabled={this.props.premium == false}
                    required
                    type="text"
                    value={this.state.new_status}
                    onChange={(e) =>
                      this.setState({ new_status: e.target.value })
                    }
                  ></input>
                </div>

                <div class="mb-15">
                  <h3 style={{ marginBottom: "0px" }}>Status type </h3>
                  <div className="section-content-header">
                    The type of the status message
                  </div>
                </div>

                <Select
                  disabled={this.props.premium == false}
                  required
                  value={this.state.new_status_type}
                  onChange={(value) => {
                    this.setState({ new_status_type: value });
                  }}
                  options={STATUS_OPTIONS}
                ></Select>
                <button
                  disabled={this.props.premium == false}
                  className="btn btn-red mt-15"
                  type="submit"
                >
                  Add Status Message
                </button>
              </form>
            </div>

            <section>
              <SectionHeader
                title="Active Rotating Statuses"
                pretitle="Status"
              />

              {this.renderStatuses()}
            </section>
          </PremiumSection>
        )}
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  premium: state.data.premium,
  bot: state.data.bot,
  beta: state.data.beta,
});

const mapDispatchToProps = {
  setBotSettings,
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Status);
