import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Select from '../../elements/Select';
import { updateElementData, setHighlightAction } from '../../../../actions';
import EmojiAdder from './EmojiAdder';
import { renderResponseActionOptions } from '../builderUtils';
import VariableTextInput from '../VariableTextInput';


export class ReactMessageAction extends Component {
    componentDidMount() {
        if (this.props.data === undefined) {
            this.props.updateElementData({
                data: {
                    type: "react_message",
                    reactions: [],
                    additionalReactions: "",
                    target: { action: true },
                    validated: false
                },
                id: this.props.id
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data === undefined) {
            this.props.updateElementData({
                data: {
                    type: "react_message",
                    reactions: [],
                    additionalReactions: "",
                    target: { action: true },
                    validated: false
                },
                id: this.props.id
            });
        } else {
            // Validate
            const { reactions, additionalReactions, target, validated } = this.props.data;
            const hasReactions = reactions.length > 0 || additionalReactions !== "";
            const isTargetValid = target.action ? !!target.editId : (!!target.channel_id && !!target.message_id);
            console.log(hasReactions, isTargetValid, validated, target, "VALIDATION");
            if (hasReactions && isTargetValid && !validated) {
                this.updateData("validated", true);
            } else if (validated && (!hasReactions || !isTargetValid)) {
                this.updateData("validated", false);
            }
        }
    }

    updateData = (key, value) => {
        const data = { ...this.props.data, [key]: value };
        this.props.updateElementData({ data, id: this.props.id });
    };

    selectChange = (value) => {
        if (!value) return;
        this.props.setHighlightAction(value);
        const action = this.props.actions.find(action => action.id === value);

        if (!("editId" in action.data.data)) {
            const data = { ...action.data.data };
            data.editId = `${Date.now()}_${Math.floor(Math.random() * 100000)}`;
            this.props.updateElementData(
                {
                    data: data,
                    id: value
                }
            );
            action.data.data.editId = data.editId;
        }
        const id = action.data.data.editId;

        var target = { ...this.props.data.target };
        target.action = true;
        target.editId = id;
        console.log(target, "TARGET");
        const data = { ...this.props.data, target: target, action: value };
        this.props.updateElementData({ data, id: this.props.id });
    };

    render() {
        if (!this.props.data) return null;

        return (
            <div>
                <div className="mb-15">
                    <h4>React to Message</h4>
                    <p>Add reactions to a message.</p>
                </div>

                <div>
                    <h4 style={{ marginBottom: "0px" }}>Target Message<DocsLink location={"#"}></DocsLink></h4>
                    <div className="section-content-header mb-15">Select where to add the reactions.</div>
                    <Select
                        onChange={(value) => {

                            if (value === "action") {
                                this.updateData("target", { action: true, editId: "" });
                                this.props.setHighlightAction(null);
                            } else {
                                this.updateData("target", { specific: true, channel_id: "", message_id: "" });
                            }
                        }}
                        value={Object.keys(this.props.data.target)[0]}
                        options={[
                            { value: "action", label: "Message sent by another action" },
                            { value: "specific", label: "Specific message ID" }
                        ]}
                    />
                </div>

                <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>
                {this.props.data.target.action && (
                    <div className="mt-15">
                        <h4 style={{ marginBottom: "0px" }}>Response Action<DocsLink location={"#"}></DocsLink></h4>
                        <div className="section-content-header mb-15">Select one of the response actions in this command to react to the message sent by that action.</div>
                        <Select
                            saveFailed={this.props.data.target.action === "" && this.props.saveFailed}
                            value={this.props.data.action}
                            options={renderResponseActionOptions(this.props.actions, this.props.selected)}
                            onChange={this.selectChange}
                        />
                    </div>
                )}

                {this.props.data.target.specific && (
                    <>
                        {/* Add an input for Channel Id */}

                        <div className="mt-15">
                            <h4 style={{ marginBottom: "0px" }}>Channel ID<DocsLink location={"#"}></DocsLink></h4>
                            <div className="section-content-header mb-15">Enter the ID of the channel to react to.</div>
                            <VariableTextInput
                                value={this.props.data.target.channel_id}
                                onChange={(value) => {
                                    const target = { ...this.props.data.target, channel_id: value };
                                    this.updateData("target", target);
                                }}
                                placeholder="Enter channel ID"
                            />
                        </div>

                        <div className="mt-15">
                            <h4 style={{ marginBottom: "0px" }}>Message ID<DocsLink location={"#"}></DocsLink></h4>
                            <div className="section-content-header mb-15">Enter the ID of the message to react to.</div>
                            <VariableTextInput
                                value={this.props.data.target.message_id}
                                onChange={(value) => {
                                    const target = { ...this.props.data.target, message_id: value };
                                    this.updateData("target", target);
                                }}
                                placeholder="Enter message ID"
                            />
                        </div>
                    </>
                )}

                <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>

                <div className='mt-15'>
                    <h4 style={{ marginBottom: "0px" }}>Reactions<DocsLink location={"#"}></DocsLink></h4>
                    <div className="section-content-header mb-15">Reactions to add to the message.</div>
                    <EmojiAdder
                        emojis={this.props.data.reactions || []}
                        customEmojis={true}
                        update={(newEmojis) => this.updateData("reactions", newEmojis)}
                    />
                </div>

                <div className='mt-15'>
                    <h4 style={{ marginBottom: "0px" }}>Additional Reactions<DocsLink location={"#"}></DocsLink></h4>
                    <div className="section-content-header mb-15">Add other reactions that are not in the picker. Separate multiple reactions with commas.</div>
                    <VariableTextInput
                        value={this.props.data.additionalReactions}
                        onChange={(value) => this.updateData("additionalReactions", value)}
                        placeholder="e.g. 👍,🎉,custom_emoji_name"
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    components: state.builder.components,
    mode: state.builder.mode,
});

const mapDispatchToProps = {
    updateElementData,
    setHighlightAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ReactMessageAction);;