import React, { Component } from 'react'
import { connect } from 'react-redux'

import ReactGA from 'react-ga';
import history from '../../../history';


export class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    componentDidMount() {
    }

    
    //   componentDidCatch = (error, info) => {
    //         var properties = ""
    //         properties += (error.message) ? 'Message: ' + error.message + '\n' : ''
    //         properties += (error.fileName) ? 'File Name: ' + error.fileName + '\n' : ''
    //         properties += (error.lineNumber) ? 'Line Number: ' + error.lineNumber + '\n' : ''
    //         properties += (error.stack) ? 'Stack Trace: ' + error.stack + '\n' : ''
    //         properties += `User id ${this.props.user.id} Bot Id ${this.props.bot.id}`
    //         properties += `Page : ${window.location.pathname}`
    //         ReactGA.initialize('UA-122665050-1');
    //         ReactGA.event({
    //             category:"Dashboard Error",
    //             label:properties,
    //             action:"click"
    //         })
    //         history.push("/dashboard/modules")

    //     this.setState({ error, info });
    //   };

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    bot:state.data.bot,
    user:state.data.user
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)
