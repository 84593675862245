import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";

import { setBotModule } from "../../../actions";

import Dashboard from "../Dashboard";
import PremiumRedirect from "../elements/PremiumRedirect";
import PremiumSection from "../elements/PremiumSection";

export class Fortnite extends Component {
  commandUpdate = (value) => {
    var games = { ...this.props.games };
    games.fortnite = value;
    this.props.setBotModule({
      module: "games",
      module_data: games,
    });
  };

  enableChange = (value) => {
    var games = { ...this.props.games };
    games.fortnite.enabled = value;
    this.props.setBotModule({
      module: "games",
      module_data: games,
    });
  };

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  render() {
    return (
      <Dashboard>
        <PremiumRedirect></PremiumRedirect>
        <ModuleHeader
          youtube="https://www.youtube.com/watch?v=xdbCHE6oOCQ"
          tutorial="hhttps://docs.botghost.com/game-integrations/fortnite"
          premiumRequired={true}
          description="Get Fortnite stats directly from Fortnite Tracker"
          change={this.enableChange}
          enabledValue={this.props.games.fortnite.enabled}
          title="Fortnite"
          icon="fortnite.png"
        ></ModuleHeader>

        <PremiumSection>
          <SectionHeader title="Commands" pretitle="Fortnite"></SectionHeader>

          <div className="row">
            <div className="col-xl-6">
              <Command
                name="fortnite"
                description="Receives Fortnite Stats for a specific user from a specific platform (PC, X-BOX One or PlayStation)"
                update={this.commandUpdate}
                options={this.props.games.fortnite}
                module="games"
                showToggle={false}
                slashOnly={true}
                toggleValue={this.props.games.fortnite.enabled}
                showPrefix={true}
              ></Command>
            </div>
          </div>
        </PremiumSection>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  games: state.data.bot.commands.games,
  beta: state.data.beta,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fortnite);
