import React, { Component } from 'react'
import { connect } from 'react-redux'
import history from '../../../history'

export class NotificationItem extends Component {

    handleClick = () =>{
        if(this.props.onClick != undefined){
            if(this.props.redirect == true){
                window.location.replace(this.props.onClick)
            }else{
                history.push(this.props.onClick)
            }
        }
    }
    render() {
        return (
            <div className={`dropdown-list-item ${this.props.unread ? "unread":null}`} onClick={(e) =>{this.handleClick()}}>
            <div className="notification">
                <div className="notification-image-container">
                    <img className="notification-image" src={process.env.PUBLIC_URL + "/images/logo-red.png"}></img>
                </div>
                <p className="notification-title">{this.props.text}</p>

                <p></p>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationItem)
