import { faBan } from '@fortawesome/free-solid-svg-icons'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CommandAdd from './CommandAdd'

import { setBotModule } from "../../../actions"


export class TabAnnouncementHeader extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <div
                className={`announcement-header ${this.state.open ? "announcement-header-open" : ""}`}
                onClick={(e) => {
                    this.props.handleClick(this.props.tag);
                    this.setState({ open: this.state.open ? false : true });
                }}
            >
                <div>
                    <h3 className="mb-0">{this.props.title}</h3>
                    <div className="section-content-header">{this.props.description}</div>
                </div>
                <div style={{ marginLeft: "auto" }}>
                    {this.state.open ? <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill" /> : <i style={{ fontSize: "20px" }} class="bi bi-caret-down-fill" />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    setBotModule
}

export default connect(mapStateToProps, mapDispatchToProps)(TabAnnouncementHeader)
