import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal';

import Select from "./Select"
import RoleAdd from "./RoleAdd"
import SectionHeader from './SectionHeader';
import { updateCommandOptions, updateSubmoduleCommandOptions, updateCustomCommandOptions } from "../../../actions"


const cooldownOptions = [
    { value: "none", label: "No Cooldown" },
    { value: "user", label: "User" },
    { value: "channel", label: "Channel" },
    { value: "server", label: "Server" },
]

const intervalOptions = [
    { value: "minute", label: "Minutes" },
    { value: "hour", label: "Hours" },
    { value: "day", label: "Days" }
]

const slashOptions = [
    { value: false, label: "Disabled" },
    { value: true, label: "Enabled" },
]


export class CommandEditor extends Component {

    constructor(props) {
        super(props)

        this.state = {
            hidden: false,
            slash: false,
            permissions: {
                allowed_roles: [],
                banned_roles: [],
                banned_channels: [],
                banned_users: [],
                required_permissions: []
            },
            trigger: "",
            cooldown: {
                type: "none",
                int: 0,
                int_type: "minute"
            },
            help: {
                show: false,
                txt: ""
            }

        }
    }

    updateSlash = (value) => {
        if (value == "false") {
            this.setState({ slash: false })
        } else {
            this.setState({ slash: true })
        }
    }

    componentDidMount() {
        var options;
        // console.log(this.props.submodule, this.props.options)
        if (this.props.submodule != undefined) {
            options = this.props.bot.commands[this.props.module][this.props.submodule][this.props.command];
        } else {
            // console.log(options)
            options = this.props.bot.commands[this.props.module][this.props.command];
        }
        const state = { ...this.state };
        if (options == undefined) {
            if (this.props.options !== undefined) {
                options = this.props.options;
            } else {
                options = {
                    permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
                }
            }
        }
        Object.keys(options).forEach(key => {
            state[key] = options[key]
        })
        if (state.trigger == "") {
            if (this.props.defaultTrigger != undefined && this.props.defaultTrigger != "") {
                state.trigger = this.props.defaultTrigger;
            } else {
                state.trigger = this.props.command;
            }
        }

        if (state.cooldown.int_type == "") {
            state.cooldown.int_type = "minute"
        }

        if (Array.isArray(state.permissions.allowed_roles) == false) {
            // console.log("IS OBJECT?", state.permissions.allowed_roles)
            state.permissions.allowed_roles = [{ name: "@everyone", id: "everyone" }]
        }
        this.setState(state)
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.state)
    }

    closeModal = (e) => {
        this.setState({ hidden: true })
        this.props.close()
    }

    modaBackgroundClick = (e) => {
        if (e.currentTarget == e.target) {
            this.closeModal(e)
        }
    }

    roleAddChange = (array, key) => {
        const permissions = { ...this.state.permissions };
        permissions[key] = array;
        this.setState({ permissions })
        // this.props.updateCommandOptions(this.props.module,this.props.command,options)
    }

    commandCooldownTypeChange = (value) => {
        const cooldown = { ...this.state.cooldown };
        cooldown.type = value;
        this.setState({ cooldown: cooldown })
    }

    commandCooldownIntervalChange = (value) => {
        const cooldown = { ...this.state.cooldown };
        cooldown.int = value;
        this.setState({ cooldown: cooldown })
    }

    commandCoolDownIntervalTypeChange = (value) => {
        const cooldown = { ...this.state.cooldown };
        cooldown.int_type = value;
        // console.log(cooldown)
        this.setState({ cooldown: cooldown })
    }

    commandDisplayInHelpChange = (value) => {
        const help = { ...this.state.help };
        help.show = value;
        this.setState({ help: help })
    }

    commandHelpTxtChange = (value) => {
        const help = { ...this.state.help };
        help.txt = value;
        this.setState({ help: help })
    }

    save = () => {
        const options = { ...this.state };

        delete options.hidden;

        // console.log(options)
        if (this.props.submodule == undefined) {
            if (this.props.custom_command == true) {
                // console.log(options, "U8A9SD8U9AS89UDU89SD")
                this.props.updateCustomCommandOptions(options, this.props.options.index)
            } else {
                this.props.updateCommandOptions(this.props.module, this.props.command, options)
            }
        } else {
            this.props.updateSubmoduleCommandOptions(this.props.module, this.props.submodule, this.props.command, options)

        }
        this.closeModal()

    }

    changeTrigger = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32)
        value = value.replace(" ", "-")
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "")
        this.setState({ trigger: value })
    }

    renderSubcommands = () => {
        if (this.props.extraDescriptions == undefined) {
            return null;
        } else {
            var extra_descriptions = []

            this.props.extraDescriptions.forEach(description => {
                extra_descriptions.push(
                    <div className="command-edit-section col-lg-6 mb-15">
                        <div className="mb-15 command-edit-section-header">
                            <h3>{description.name}</h3>
                            <span>Overrides the default description of the {description.name} sub-command.</span>
                        </div>

                        <div className="long-input">
                            <label>Description</label>
                            <input maxLength={99} type="text" value={this.state[description.key]} onChange={(e) => {
                                this.setState({ [description.key]: e.target.value })
                            }}></input>
                        </div>
                    </div>)
            })
            return extra_descriptions;
        }
    }

    render() {
        if (this.state.hidden == false) {
            return (
                <div>
                    <div class={`modal fade show modal-background`} onClick={(e) => { this.modaBackgroundClick(e) }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog command-edit-modal" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Edit Command</h5>
                                    <button onClick={(e) => { this.closeModal(e) }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body custom-scrollbar command-edit-body">

                                    <div className="command-edit-content">


                                        <div className="row">
                                            <div className="command-edit-section col-lg-6 mb-15">
                                                <div className="mb-15 command-edit-section-header">
                                                    <h3>Command Trigger</h3>
                                                    <span>Overrides the default trigger of the command.</span>
                                                </div>

                                                <div className="long-input">
                                                    <label>Trigger</label>
                                                    <input type="text" value={this.state.trigger} onChange={(e) => { this.changeTrigger(e.target.value) }}></input>
                                                </div>
                                            </div>

                                            <div className="command-edit-section col-lg-6 mb-15">
                                                <div className="mb-15 command-edit-section-header">
                                                    <h3>Command Description</h3>
                                                    <span>Overrides the default description of the command.</span>
                                                </div>

                                                <div className="long-input">
                                                    <label>Description</label>
                                                    <input maxLength={99} type="text" value={this.state.description} onChange={(e) => {
                                                        this.setState({ description: e.target.value })
                                                    }}></input>
                                                </div>
                                            </div>

                                            {this.renderSubcommands()}

                                            {/* <div className="command-edit-section col-lg-6 mb-15">
                                                                            <div className="mb-15 command-edit-section-header">
                                                                                <h3>Command Cooldown</h3>
                                                                                <span>Set either a User, Channel or Server cooldown.</span>
                                                                            </div>
                                                                            
                                                                            <Select value={this.state.cooldown.type} onChange={this.commandCooldownTypeChange} options={cooldownOptions}></Select>
                                                                        </div>
                                    
                                                                        <div className="command-edit-section col-lg-6 mb-15">
                                                                            <div className="mb-15 command-edit-section-header">
                                                                                <h3>Cooldown Interval</h3>
                                                                                <span>The interval of the cooldown.</span>
                                                                            </div>
                                    
                                                                            <div className="long-input">
                                                                                <label>Interval</label>
                                                                                <input type="number" value={this.state.cooldown.int} onChange={(e) =>{this.commandCooldownIntervalChange(e.target.value)}}></input>
                                                                            </div>
                                                                        </div>
                                    
                                                                        <div className="command-edit-section col-lg-6 mb-15">
                                                                            <div className="mb-15 command-edit-section-header">
                                                                                <h3>Cooldown Interval Type</h3>
                                                                                <span>The interval of the cooldown.</span>
                                                                            </div>
                                                                            
                                                                            <Select value={this.state.cooldown.int_type} onChange={this.commandCoolDownIntervalTypeChange} options={intervalOptions}></Select>
                                                                        </div> */}

                                        </div>


                                    </div>

                                    <div className="command-edit-content" style={{ borderBottom: "none" }}>
                                        <div className="row">

                                            <div className="command-edit-section col-lg-6 mb-15">
                                                <div className="mb-15 command-edit-section-header">
                                                    <h3>Allowed Roles</h3>
                                                    <span>Users with these roles can use the command. Adding @everyone will mean anyone can use the command.</span>
                                                </div>

                                                <RoleAdd
                                                    type="role"
                                                    items={this.state.permissions.allowed_roles}
                                                    update={this.roleAddChange}
                                                    option="allowed_roles"
                                                    bottom={true}
                                                ></RoleAdd>
                                            </div>

                                            <div className="command-edit-section col-lg-6 mb-15">
                                                <div className="mb-15 command-edit-section-header">
                                                    <h3>Banned Roles</h3>
                                                    <span>Users with these roles can't use the command.</span>
                                                </div>

                                                <RoleAdd
                                                    type="role"
                                                    items={this.state.permissions.banned_roles}
                                                    update={this.roleAddChange}
                                                    option="banned_roles"
                                                    bottom={true}
                                                ></RoleAdd>
                                            </div>


                                            <div className="command-edit-section col-lg-6 mb-15">
                                                <div className="mb-15 command-edit-section-header">
                                                    <h3>Required Permissions</h3>
                                                    <span>Users require these server permissions to use this command.</span>
                                                </div>

                                                <RoleAdd
                                                    type="permissions"
                                                    items={this.state.permissions.required_permissions}
                                                    update={this.roleAddChange}
                                                    option="required_permissions"
                                                    bottom={true}
                                                ></RoleAdd>
                                            </div>

                                            <div className="command-edit-section col-lg-6 mb-15">
                                                <div className="mb-15 command-edit-section-header">
                                                    <h3>Banned Channels</h3>
                                                    <span>The command will not work in these channels.</span>
                                                </div>

                                                <RoleAdd
                                                    type="channels"
                                                    items={this.state.permissions.banned_channels}
                                                    update={this.roleAddChange}
                                                    option="banned_channels"
                                                    bottom={true}
                                                ></RoleAdd>
                                            </div>

                                        </div>


                                    </div>





                                </div>
                                <div class="modal-footer">
                                    <button onClick={(e) => { this.closeModal() }} type="button" class="btn btn-gray" data-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-red" onClick={(e) => { this.save() }}>Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
    beta: state.data.beta
})

const mapDispatchToProps = {
    updateCommandOptions,
    updateSubmoduleCommandOptions,
    updateCustomCommandOptions
}

export default connect(mapStateToProps, mapDispatchToProps)(CommandEditor)
