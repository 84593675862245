import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";
import RoleAdd from "../elements/RoleAdd";

import { setBotModule } from "../../../actions";

import Dashboard from "../Dashboard";
import { ModuleDisabled } from "../elements/ModuleDisabled";
import { DocsLink } from "../elements/DocsLink";
import PremiumSection from "../elements/PremiumSection";
import PremiumBadge from "../elements/PremiumBadge";
import { Select } from "../elements/Select";

const ENABLEMEMBERSHIP = [
  { label: "Enabled", value: true },
  { label: "Disabled", value: false },
];

export class AutoRole extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  roleAddChange = (roles) => {
    var autoroles = { ...this.props.autorole };
    autoroles.roles = roles;
    this.props.setBotModule({
      module: "autorole",
      module_data: autoroles,
    });
  };

  enableModule = (value) => {
    var autoroles = { ...this.props.autorole };
    autoroles.enabled = value;
    this.props.setBotModule({
      module: "autorole",
      module_data: autoroles,
    });
  };

  screeningRoleChange = (roles) => {
    var membershipScreening = { ...this.props.membershipScreening };
    membershipScreening.roles = roles;
    if (membershipScreening.enabled == false) {
      membershipScreening.enabled = true;
    }
    this.props.setBotModule({
      module: "membershipScreening",
      module_data: membershipScreening,
    });
  };

  enableSubModule = (value) => {
    var membershipScreening = { ...this.props.membershipScreening };
    if (value == "false") {
      value = false;
    } else {
      value = true;
    }
    membershipScreening.enabled = value;
    this.props.setBotModule({
      module: "membershipScreening",
      module_data: membershipScreening,
    });
  };
  render() {
    return (
      <Dashboard>
        <ModuleHeader
          youtube="https://www.youtube.com/watch?v=A2iaEW2EeJ0"
          tutorial="https://docs.botghost.com/modules/server-management/auto-role"
          enabledValue={this.props.autorole.enabled != false ? true : false}
          change={this.enableModule}
          title="Auto Role"
          docs="https://docs.botghost.com/modules/server-management/auto-role"

          description="Automatically assign roles to users when they join your server"
          icon="autorole.png"
        ></ModuleHeader>
        <ModuleDisabled disabled={!this.props.autorole.enabled}>
          <section>
            <SectionHeader title="Default Auto Role" pretitle="Auto Role" />
            <div className="section-content-normal">
              <div className="mb-15">
                <h3>
                  New Users{" "}
                  <DocsLink location="https://docs.botghost.com/modules/auto-role" />
                </h3>
                <div className="section-content-header">
                  Add roles to assign to new members. The bot will automatically
                  assign these roles to anyone who joins the server. If your bot
                  is in multiple servers, ensure that you add different roles
                  for each server
                </div>
              </div>

              <RoleAdd
                type="role"
                items={this.props.autorole.roles}
                update={this.roleAddChange}
              ></RoleAdd>
            </div>
          </section>

          <PremiumSection>
            <SectionHeader
              title="Membership Verification"
              pretitle="Auto Role"
            />

            <div className="section-content-normal">
              <div className="mb-15">
                <h3>
                  Enable Membership Screening Verification <PremiumBadge />
                </h3>
                <div className="section-content-header">
                  Enable the Membership Screening submodule
                </div>
              </div>
              <Select
                disabled={this.props.premium == false}
                value={this.props.membershipScreening.enabled}
                onChange={this.enableSubModule}
                options={ENABLEMEMBERSHIP}
              />
            </div>
            <div className="section-content-normal">
              <div className="mb-15">
                <h3>
                  Membership Screening Verification Roles <PremiumBadge />
                </h3>
                <div className="section-content-header">
                  Automatically apply roles to members when they accept your
                  servers rules{" "}
                </div>
              </div>

              <RoleAdd
                type="role"
                disabled={this.props.premium == false}
                items={this.props.membershipScreening.roles}
                update={this.screeningRoleChange}
              ></RoleAdd>
            </div>
          </PremiumSection>
        </ModuleDisabled>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  autorole: state.data.bot.commands.autorole,
  premium: state.data.premium,
  beta: state.data.beta,
  membershipScreening: state.data.bot.commands.membershipScreening,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoRole);
