import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from "./Select"
import RoleAdd from "./RoleAdd"
import { setBotModule, updateEmptyBlacklist } from "../../../actions"
import { DocsLink } from './DocsLink';

const actions = [
    { value: "disabled", label: "Disabled" },
    { value: "delete", label: "Delete Message" },
    { value: "warn", label: "Warn the Member" },
    { value: "delete-warn", label: "Delete Message and Warn" }
]

const defaultBlacklist = [
    { value: "false", label: "Enabled" },
    { value: "true", label: "Disabled" }
]
export class AutoModModule extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showModal: false,
            badword: "",
            goodword: "",
            value: {

            },
            whitelistArray: []
        }
    }

    componentDidMount() {
        this.setState({ value: this.props.value, whitelistArray: this.props.whitelistArray })
    }

    modaBackgroundClick = (e) => {
        if (e.currentTarget == e.target) {
            this.closeModal()
        }
    }

    closeModal = () => {
        this.setState({ showModal: false, whitelistArray: this.props.whitelistArray })
    }


    selectChange = (value) => {
        // console.log(this.props)
        const automod = { ...this.props.automod };
        automod.automod.options[this.props.type].action = value;
        this.props.setBotModule({
            module: "automod",
            module_data: automod
        })
    }

    roleAddChange = (value, type) => {
        const values = { ...this.state.value };
        values[type] = value;
        // console.log(values)
        this.setState({ value: values })
    }

    defaultBlacklistChange = (value) => {
        if (value == "true") {
            this.props.updateEmptyBlacklist(true)
        } else {
            this.props.updateEmptyBlacklist(false)
        }
    }

    addWord = (e, type) => {
        e.preventDefault();
        var words = []
        if (type == "bad") {
            var string = this.state.badword.trim()
            words = string.split(" ")
            var value = { ...this.state.value };
            value.words = value.words.concat(words)
            this.setState({ value: value, badword: "" })
        } else {
            var string = this.state.goodword.trim()
            words = string.split(" ")
            var whitelistArray = [...this.state.whitelistArray]
            whitelistArray = whitelistArray.concat(words)
            this.setState({ whitelistArray: whitelistArray, goodword: "" })
        }
    }

    wordsUpdate = (values, type) => {
        if (type == "badwords") {
            var value = { ...this.state.value };
            value.words = values;
            this.setState({ value: value })
        } else {
            this.setState({ whitelistArray: values })
        }
    }

    allowedChange = (number) => {
        var value = { ...this.state.value }
        value.allowed = number;
        this.setState({ value: value })
    }

    renderOptions = () => {
        if (this.props.type == "blacklist") {
            return (

                <div className="row">
                    <div className="command-edit-section col-lg-12 mb-15">
                        <div className="mb-15 command-edit-section-header">
                            <h3>Additional Bad Words</h3>
                            <span>Additional words to add to the blacklist.</span>
                        </div>

                        <div className="economy-roles mb-15">
                            <div class="long-input economy-roles-input">
                                <form onSubmit={(e) => { this.addWord(e, "bad") }}>
                                    <label>Words</label>
                                    <input value={this.state.badword} onChange={(e) => { this.setState({ badword: e.target.value }) }} name="bad_word" type="text" />
                                </form>
                            </div>

                            <div className="economy-roles-container">
                                <RoleAdd option="badwords" update={this.wordsUpdate} type="words" dropdown={false} items={this.state.value.words}></RoleAdd>
                            </div>
                        </div>
                    </div>

                    <div className="command-edit-section col-lg-12 mb-15">
                        <div className="mb-15 command-edit-section-header">
                            <h3>Whitelisted Words</h3>
                            <span>Words to remove from the default blacklist.</span>
                        </div>

                        <div className="economy-roles mb-15">
                            <div class="long-input economy-roles-input">
                                <form onSubmit={(e) => { this.addWord(e, "whitelist") }}>
                                    <label>Words</label>
                                    <input value={this.state.goodword} onChange={(e) => { this.setState({ goodword: e.target.value }) }} type="text" />
                                </form>
                            </div>

                            <div className="economy-roles-container">
                                <RoleAdd option="whitelist" update={this.wordsUpdate} type="words" dropdown={false} items={this.state.whitelistArray}></RoleAdd>
                            </div>
                        </div>

                    </div>

                    <div className="command-edit-section col-lg-6 mb-15">
                        <div className="mb-15 command-edit-section-header">
                            <h3>Enable Default Blacklist</h3>
                            <span>Enable the default blacklist. These <a href="https://github.com/web-mech/badwords/blob/master/lib/lang.json" target="_blank">words</a> are added by default.</span>
                        </div>

                        <Select value={this.props.empty} onChange={this.defaultBlacklistChange} options={defaultBlacklist}></Select>
                    </div>
                </div>
            )
        } else if (this.props.type == "emoji") {
            return (
                <div className="row">
                    <div className="command-edit-section col-lg-12 mb-15">
                        <div className="mb-15 command-edit-section-header">
                            <h3>Max Emojis per message</h3>
                            <span>The max amount of emojis allowed per message.</span>
                        </div>

                        <div className="long-input">
                            <label>Max Emojis</label>
                            <input value={this.state.value.allowed} onChange={(e) => { this.allowedChange(e.target.value) }} type="number" min={0}></input>
                        </div>

                    </div>
                </div>
            )
        } else if (this.props.type == "mentions") {
            return (
                <div className="row">
                    <div className="command-edit-section col-lg-12 mb-15">
                        <div className="mb-15 command-edit-section-header">
                            <h3>Max Mentions per message</h3>
                            <span>The max amount of mentions allowed per message.</span>
                        </div>

                        <div className="long-input">
                            <label>Max Mentions</label>
                            <input value={this.state.value.allowed} onChange={(e) => { this.allowedChange(e.target.value) }} type="number" min={0}></input>
                        </div>

                    </div>
                </div>
            )
        }
    }

    save = () => {
        this.props.update(this.state, this.props.type)
        this.closeModal()
    }

    renderModal = () => {
        if (this.state.showModal == true) {
            return (
                <div>
                    <div class={`modal fade show modal-background`} onClick={(e) => { this.modaBackgroundClick(e) }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog command-edit-modal" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Edit Command</h5>
                                    <button onClick={(e) => { this.closeModal(e) }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body command-edit-body">

                                    <div className="command-edit-content">
                                        {this.renderOptions()}


                                    </div>

                                    <div className="command-edit-content">
                                        <div className="row">

                                            <div className="command-edit-section col-lg-6 mb-15">
                                                <div className="mb-15 command-edit-section-header">
                                                    <h3>Ignored Channels</h3>
                                                    <span>The Auto-moderator will ignore these channels for this module.</span>
                                                </div>

                                                <RoleAdd
                                                    type="channels"
                                                    items={this.state.value.ignored_channels}
                                                    update={this.roleAddChange}
                                                    option="ignored_channels"
                                                    bottom={true}
                                                ></RoleAdd>
                                            </div>

                                            <div className="command-edit-section col-lg-6 mb-15">
                                                <div className="mb-15 command-edit-section-header">
                                                    <h3>Ignored Roles</h3>
                                                    <span>The Auto-moderator will ignore members with these roles.</span>
                                                </div>

                                                <RoleAdd
                                                    type="roles"
                                                    items={this.state.value.ignored_roles}
                                                    update={this.roleAddChange}
                                                    option="ignored_roles"
                                                    bottom={true}
                                                ></RoleAdd>
                                            </div>

                                        </div>


                                    </div>





                                </div>
                                <div class="modal-footer">
                                    <button onClick={(e) => { this.closeModal() }} type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary" onClick={(e) => { this.save() }}>Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }


    render() {
        return (

            <div className="col-lg-4">
                <div>
                    {this.renderModal()}
                </div>
                <div className='automod-module'>
                    <div className="automod-header">
                        <h4>{this.props.name} <DocsLink location={this.props.docs_link} /></h4>
                        <button className="btn automod-btn" onClick={(e) => { this.setState({ showModal: true }) }}>Settings</button>
                    </div>

                    <Select value={this.props.value.action} onChange={this.selectChange} options={actions} ></Select>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    automod: state.data.bot.commands.automod
})

const mapDispatchToProps = {
    setBotModule,
    updateEmptyBlacklist
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoModModule)
