import React, { Component } from 'react'
import { connect } from 'react-redux'

import history from "../../../history"

export class IntentsFailedModal extends Component {
    modalBackgroundClick = (e) => {
        // console.log("MODAL BACKGROUND CLICK")
        if (e.currentTarget == e.target) {
            this.props.closeModal(e)
        }
    }
    componentDidMount() {
        // console.log("PREMIUM MODAL MOUNTED")
    }



    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} onClick={(e) => { this.modalBackgroundClick(e) }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div class="modal-dialog premium-modal-dialog" role="document">
                        <div class="modal-content premium-modal" style={{ backgroundColor: "#f8f8fb" }}>


                            <div class="modal-body command-edit-body premium-modal-body">
                                <div className="premium-modal-content">
                                    <img src={process.env.PUBLIC_URL + "/images/logo-red.png"}></img>
                                    <h2>Enable Gateway Intents</h2>
                                    <p style={{ textAlign: "center" }}>Privledged Gateway Intents are required for some bot functions to work correctly. Enable both Presence Intent and Server Members Intent in your Discord Developer portal. </p>

                                    <div>
                                        <button className="btn btn-gray" onClick={() => { this.props.closeModal() }}>Close</button>
                                        <a className="btn btn-red ml-2" href={`https://www.youtube.com/watch?v=pzxVzEHk4ww&feature=youtu.be`} target="_blank">How to Fix</a>

                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(IntentsFailedModal)
