import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setElements } from '../../../../actions';
import getLayoutedElements from "../getLayoutElements";
import Embed from '../../elements/Embed';
import TextArea from '../../elements/TextArea';
import Select from '../../elements/Select';
import RefreshBotData from '../../elements/RefreshBotData';
import { addEdge, getOutgoers } from 'react-flow-renderer';
import { EmojiAdder } from './EmojiAdder';
import splitElements from '../splitElements';
import VariableTextInput from '../VariableTextInput';

const responseTypes = [
    { value: "reply", label: "Reply to the command" },
    { value: "targeted", label: "Send the message to a specific text-channel" }
];


export class ComparisonConditionChild extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ""
        };
    }

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "condition",
                        condition_type: "comparison",
                        not: false,
                        option: "",
                        ignoreCase: false,
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data != undefined) {

            // if (this.props.data.comparison_value != "") {
            //     if (this.props.data.validated = false) {
            //         this.updateData("validated", true);
            //     }
            // } else if (this.props.data.validated == true) {
            //     this.updateData("validated", false);
            // }
            //     if((
            //         ('response' in this.props.data.response_options && this.props.data.response_options.response != "") ||
            //         ("embed" in this.props.data.response_options && "description" in this.props.data.response_options.embed &&  this.props.data.response_options.embed.description != "")) &&
            //         (this.props.data.response_options.target.reply != undefined || ("variable" in this.props.data.response_options.target && this.props.data.response_options.target.variable != "") || ('id' in this.props.data.response_options.target && this.props.data.response_options.target.id != "")
            //     )){
            //         if(this.props.data.validated == false){
            //             this.updateData("validated",true)
            //         }

            //     }else if(this.props.data.validated == true){
            //         this.updateData("validated",false)

            //     }
        }
        // if(this.props.data == undefined){
        //     this.props.updateElementData(
        //         {                
        //             data:{
        //                 type:"button_response",
        //                 response_options:{
        //                     response:"",
        //                     target: { reply: true },
        //                     emojis:[]
        //                 },
        //                 validated:false
        //             },
        //             id:this.props.id
        //         }
        //     )
        // }else{
        //     // VALIDATE
        //     if((
        //         ('response' in this.props.data.response_options && this.props.data.response_options.response != "") ||
        //         ("embed" in this.props.data.response_options && "description" in this.props.data.response_options.embed &&  this.props.data.response_options.embed.description != "")) &&
        //         (this.props.data.response_options.target.reply != undefined || ("variable" in this.props.data.response_options.target && this.props.data.response_options.target.variable != "") || ('id' in this.props.data.response_options.target && this.props.data.response_options.target.id != "")
        //     )){
        //         if(this.props.data.validated == false){
        //             this.updateData("validated",true)
        //         }

        //     }else if(this.props.data.validated == true){
        //         this.updateData("validated",false)

        //     }
        // }
    }


    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };


    renderOptions = () => {
        var options = [
            // {value:"trigger",label:"The member who triggered the command"}
        ];
        this.props.options.forEach(option => {
            options.push({ value: option.name, label: option.name });
        });
        return options;
    };

    selectUpdate = (value) => {
        value = JSON.parse(value);
        var response_options = { ...this.props.data.response_options };
        response_options.target = value;
        this.updateData("response_options", response_options);
    };


    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    {this.props.data.type != "else" ?
                        <div className="mb-15">
                            <h4>Comparison Condition</h4>
                            <p>Run actions based on the value of a custom variable.</p>
                        </div> :
                        <div className="mb-15">
                            <h4>Else Conditon</h4>
                            <p>Run connected actions when the option equals no other condition value. </p>
                        </div>}



                    {this.props.data.type != "else" ?
                        <div className="mb-15">

                            <div className='mb-15 slash-action'>
                                <h4>Comparison Value <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/comparison-condition#comparison-value" /></h4>
                                <span>The value to compare against the base value of this condition.</span>


                                <VariableTextInput required={true} label="Comparison Value" placeholder="Comparison Value" value={this.props.data.comparison_value} onChange={(value) => {
                                    this.updateData("comparison_value", value);
                                }} slash_options={this.props.options} />
                                {/* <div class="long-input mt-15">
                                    <label>Comparison Value</label>
                                    <input className={`${this.props.data.comparison_value == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {

                                        this.updateData("comparison_value", e.target.value);

                                    }} type="text" value={this.props.data.comparison_value} />
                                </div> */}
                            </div>

                            <div className='mb-15 slash-action'>
                                <h4>Comparison Type <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/comparison-condition#comparison-type" /> </h4>
                                <span>The comparison type for this condition.</span>
                                <Select value={this.props.data.type} onChange={(value) => {
                                    this.updateData("type", value);
                                    // value = JSON.parse(value);
                                    // this.setState({value:value})
                                }} options={[
                                    { label: "Less than", value: "less_than" },
                                    { label: "Less than or equal to", value: "less_than_equal_to" },
                                    { label: "Greater than", value: "greater_than" },
                                    { label: "Greater than or equal to", value: "greater_than_equal_to" },
                                    { label: "Equal to", value: "equal_to" },
                                    { label: "Not equal to", value: "not" },
                                    { label: "Contains", value: "contains" },
                                    { label: "Does not contain", value: "not_contains" },
                                    { label: "Starts with", value: "starts_with" },
                                    { label: "Ends with", value: "ends_with" },
                                    { label: "Does not start with", value: "not_starts_with" },
                                    { label: "Does not end with", value: "not_ends_with" },
                                    { label: "Collection contains", value: "array_contains" },
                                    { label: "Collection does not contain", value: "array_not_contain" },
                                ]} ></Select>

                            </div>

                            <div className='mb-15 slash-action'>
                                <h4>Ignore Case</h4>
                                <span className="mb-15">Ignore case when comparing values.</span>
                                <div className="mt-15">
                                    <Toggle value={'ignoreCase' in this.props.data ? this.props.data.ignoreCase : false} update={(value) => {
                                        this.updateData("ignoreCase", value);
                                    }}></Toggle>
                                </div>

                            </div>


                        </div> : null}



                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
    updateElementData,
    setElements
};

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonConditionChild);
