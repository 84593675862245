import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactDOM from "react-dom"
import RoleAdd from './RoleAdd'

export class SlashCommandPermissions extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showContents: false
        }
    }

    roleAddChange = (array, key) => {
        const permissions = { ...this.props.data };
        permissions[key] = array;

        // console.log(permissions)
        this.props.update(permissions)
        // this.setState({permissions})

        // this.props.updateCommandOptions(this.props.module,this.props.command,options)
    }

    componentDidMount() {
        if (this.props.edit == true) {
            this.setState({ showContents: false })
        }
        document.addEventListener("click", this.handleClick, true)
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClick, true)
    }

    handleClick = (event) => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({ showContents: false })
        }
    }


    render() {
        return (

            <div className="announcement slashcommand-option-container">
                <div className="announcement-header" onClick={(e) => { this.setState({ showContents: !this.state.showContents }) }}>
                    <div>
                        <h3 className="mb-0">Permission Options</h3>
                        <div className="section-content-header">Set permissions for your command to restrict who can use it.</div>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                        {this.state.showContents == true ? <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i> : <i style={{ fontSize: "20px" }} class="bi bi-caret-down-fill"></i>}
                    </div>
                </div>

                <div>
                    {this.state.showContents == true ?
                        <div className="slashcommand-option slash-action">
                            <div className="row">

                                <div className="command-edit-section col-lg-12 mb-15">
                                    <div className="mb-15 command-edit-section-header">
                                        <h3>Allowed Roles</h3>
                                        <span>Users with these roles can use the command. Adding @everyone will mean anyone can use the command.</span>
                                    </div>

                                    <RoleAdd
                                        type="role"
                                        items={this.props.data.allowed_roles}
                                        update={this.roleAddChange}
                                        option="allowed_roles"
                                    ></RoleAdd>
                                </div>

                                <div className="command-edit-section col-lg-12 mb-15">
                                    <div className="mb-15 command-edit-section-header">
                                        <h3>Banned Roles</h3>
                                        <span>Users with these roles can't use the command.</span>
                                    </div>

                                    <RoleAdd
                                        type="role"
                                        items={this.props.data.banned_roles}
                                        update={this.roleAddChange}
                                        option="banned_roles"
                                    ></RoleAdd>
                                </div>


                                <div className="command-edit-section col-lg-12 mb-15">
                                    <div className="mb-15 command-edit-section-header">
                                        <h3>Required Permissions</h3>
                                        <span>Users require these server permissions to use this command.</span>
                                    </div>

                                    <RoleAdd
                                        type="permissions"
                                        items={this.props.data.required_permissions}
                                        update={this.roleAddChange}
                                        option="required_permissions"
                                    ></RoleAdd>
                                </div>

                                <div className="command-edit-section col-lg-12 mb-15">
                                    <div className="mb-15 command-edit-section-header">
                                        <h3>Banned Channels</h3>
                                        <span>The command will not work in these channels.</span>
                                    </div>

                                    <RoleAdd
                                        type="channels"
                                        items={this.props.data.banned_channels}
                                        update={this.roleAddChange}
                                        option="banned_channels"
                                    ></RoleAdd>
                                </div>

                            </div>
                        </div>
                        : null
                    }
                </div>



            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SlashCommandPermissions)
