import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer } from 'react-toastify';
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Select from '../elements/Select';
import RefreshBotData from '../elements/RefreshBotData';
import LongInput from '../elements/LongInput';
import { faExclamationCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { MarkdownRender } from "../elements/MarkdownRender";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import EmbedBuilderPreview from "../elements/EmbedBuilderPreview";
import { uuid4 } from "@sentry/utils";
import toast, { Toaster } from 'react-hot-toast';

import Dashboard from "../Dashboard";

import { setBotModule } from "../../../actions";
import { ChromePicker } from "react-color";
import server from '../../../api/server';
import EmbedBuilderSave from "../elements/EmbedBuilderSave";
import { EmbedBuilderDelete } from "../elements/EmbedBuilderDelete";
import EmbedBuilderJson from "../elements/EmbedBuilderJson";
import TabAnnouncementHeader from "../elements/TabAnnouncementHeader";

const MAX_FIELDS_PER_ROW = 3;
const FIELD_GRID_SIZE = 12;

const imageReg = /((?:https?\:\/\/|www\.)[-a-zA-Z0-9@:%_\+.~#?&//=]+)\.(jpg|jpeg|gif|png|bmp|tiff|tga|svg|webp)/;
const urlReg = /((?:https?\:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/i;
const numberReg = /^[0-9]*$/;
// /(https?:\/\/)?(www\.)?(canary\.)?(ptb\.)?(discord\.com)\/channels\/.+[0-9].\/.+[0-9]/;
// /Add discordapp.com to the regex
const discordReg = /(https?:\/\/)?(www\.)?(canary\.)?(ptb\.)?(discord\.com)\/channels\/.+[0-9].\/.+[0-9]/;
// const discordReg = 

export class BuilderEmbed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedEmbed: false,
            bodyError: false,
            authorError: false,
            authorImageError: false,
            footerError: false,
            footerTextError: false,
            imageError: false,
            messageError: false,
            thumbnailError: false,
            embedName: "",
            embedID: "",
            tEmbed: "",
            messageLink: "",
            saveModal: false,
            deleteModal: false,
            colorOpen: false,
            jsonModal: false,
            showIndex: "info",
            showDrop: [],
            channel: {},
            fieldCount: 0,
            fields: [],
            fieldsRender: [],
            content: "",
            embed: {
                title: "",
                description: "",
                color: "",
                url: "",
                author: {
                    name: "",
                    url: "",
                    icon_url: ""
                },
                image: {
                    url: ""
                },
                thumbnail: {
                    url: ""
                },
                footer: {
                    text: "",
                    icon_url: "",
                    current_timestamp: false
                },
                fields: []
            }
        };
        this.wrapperRef = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.contains && !this.wrapperRef.contains(event.target)) {
            this.setState({ colorOpen: false });
        }
    }

    onColorChange = (color) => {
        var embed = { ...this.state.embed };
        embed.color = color;
        this.setState({ embed: embed });
    };

    closeSaveModal = () => {
        this.setState({ saveModal: false });
    };

    closeDeleteModal = () => {
        this.setState({ deleteModal: false });
    };

    closeJsonModal = () => {
        this.setState({ jsonModal: false });
    };

    // send embed logic
    sendEmbed = async () => {
        const errorArray = [];

        this.validateFields();

        if (this.state.bodyError) errorArray.push("Body URL is invalid. Check the body tab.\n");
        if (this.state.authorError) errorArray.push("Author Image URL is invalid. Check the author tab.\n");
        if (this.state.footerError) errorArray.push("Footer Image URL is invalid. Check the footer tab.\n");
        if (this.state.imageError) errorArray.push("Image URL is invalid. Check the image tab.\n");
        if (this.state.thumbnailError) errorArray.push("Thumbnail Image URL is invalid. Check the image tab.\n");
        if (this.state.messageError) errorArray.push("You have provided an invalid Discord Message URL.\n");
        if (!this.state.embed.description.trim()) errorArray.push("There are only spaces in the body of the embed. Put some other content\n");

        if (!this.state.channel.id) {
            return this.errorToast("You need to select a channel to send the embed to!");
        } else if (errorArray.length !== 0) {
            return this.errorToast(`Cannot send embed!\n\n${errorArray.join(' ')}`);
        } else if (!this.validEmbed() && !this.state.content) {
            return this.errorToast('You cannot send en empty embed!');
        } else if (!this.validateFields()) {
            return this.errorToast('Your fields are formatted incorrectly. Check that all your fields have their name and content set.');
        } else {
            var obj = {
                bot: this.props.bot,
                embed: this.validEmbed() ? this.prepareEmbed() : null,
                content: this.state.content,
                collab: this.props.collab,
                channel: this.state.channel
            };
            if (this.state.messageLink !== "") {
                obj.message = this.state.messageLink;
            }

            const response = await server.post("/dashboard/embed", obj);
            if (response.data.success) {
                return this.successToast("Successfully sent embed!");
            } else {
                return this.errorToast(response.data.message ? response.data.message : "Check that the embed is formatted correctly.");
            }
        }
    };

    errorToast = (message) => {
        return toast.error(message,
            {
                style: {
                    borderRadius: '10px',
                    background: '#d00000',
                    color: '#fff',
                },
            });
    };

    successToast = (message) => {
        return toast.success(message,
            {
                style: {
                    borderRadius: '10px',
                    background: '#38b000',
                    color: '#fff',
                },
            });
    };

    // save embed logic
    saveEmbed = (name) => {
        var data = [];
        if (this.props.bot.commands.embeds && this.props.bot.commands.embeds.embeds) {
            data = this.props.bot.commands.embeds.embeds;
        }

        if (this.state.loadedEmbed) {
            const eID = this.state.embedID;
            const eName = this.state.embedName;

            const embeds = data;
            const fEmbed = embeds.find(e => e.id === eID);

            if (fEmbed) {
                var newEmbed = this.prepareEmbed();
                newEmbed.id = eID;
                newEmbed.name = eName;
                newEmbed.content = this.state.content;

                data[this.props.bot.commands.embeds.embeds.findIndex(embed => embed.id === eID)] = newEmbed;

                var obj = {
                    embeds: data
                };

                this.props.setBotModule({
                    module: "embeds",
                    module_data: obj,
                });
            } else {
                var embed = this.prepareEmbed();
                embed.id = uuid4();
                embed.name = this.state.embedName;
                embed.content = this.state.content;

                embed.id = uuid4();

                data.push(embed);

                var obj = {
                    embeds: data
                };

                this.props.setBotModule({
                    module: "embeds",
                    module_data: obj,
                });
            }

        } else {
            const embed = this.prepareEmbed();
            embed.content = this.state.content;
            !name ? embed.name = this.state.embedName : embed.name = name;
            embed.id = uuid4();

            data.push(embed);

            var obj = {
                embeds: data
            };

            this.props.setBotModule({
                module: "embeds",
                module_data: obj,
            });
        }
    };

    deleteEmbed = (eID) => {
        var data = [];
        if (this.props.bot.commands.embeds && this.props.bot.commands.embeds.embeds) {
            data = this.props.embeds.embeds;
        }

        data = data.filter(object => {
            return object.id !== eID;
        });

        var obj = {
            embeds: data
        };

        this.props.setBotModule({
            module: "embeds",
            module_data: obj,
        });
    };

    renderColorPicker = () => {
        if (this.state.colorOpen) {
            return (
                <div
                    className=""
                    style={{ zIndex: "2", position: "absolute", right: "150px" }}
                >
                    <ChromePicker
                        color={this.state.embed.color.hex}
                        onChangeComplete={(color) => {
                            this.onColorChange(color);
                        }}
                    ></ChromePicker>
                </div>
            );
        } else {
            return null;
        }
    };

    // inline formatting
    getFieldGridColumn = (fields, fieldIndex) => {
        const field = fields[fieldIndex];

        if (!field.inline) {
            return `1 / ${FIELD_GRID_SIZE + 1}`;
        }

        let startingField = fieldIndex;
        while (startingField > 0 && fields[startingField - 1].inline) {
            startingField -= 1;
        }

        let totalInlineFields = 0;
        while (fields.length > startingField + totalInlineFields && fields[startingField + totalInlineFields].inline) {
            totalInlineFields += 1;
        }

        const indexInSequence = fieldIndex - startingField;
        const currentRow = indexInSequence / MAX_FIELDS_PER_ROW;
        const indexOnRow = indexInSequence % MAX_FIELDS_PER_ROW;
        const totalOnLastRow = totalInlineFields % MAX_FIELDS_PER_ROW || MAX_FIELDS_PER_ROW;
        const fullRows = (totalInlineFields - totalOnLastRow) / MAX_FIELDS_PER_ROW;
        const totalOnRow = currentRow >= fullRows ? totalOnLastRow : MAX_FIELDS_PER_ROW;

        const columnSpan = FIELD_GRID_SIZE / totalOnRow;
        const start = indexOnRow * columnSpan + 1;
        const end = start + columnSpan;

        return `${start} / ${end}`;
    };




    // update field
    updateField = (fieldID, type, value) => {
        var fields = this.getFields();
        if (type === 0) {
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].id === fieldID) {
                    fields[i].name = value;
                    break;
                }
            }
            this.saveFields(fields);
        } else if (type === 1) {
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].id === fieldID) {
                    fields[i].value = value;
                    break;
                }
            }
            this.saveFields(fields);
        } else if (type === 2) {
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].id === fieldID) {
                    fields[i].inline = value;
                    break;
                }
            }
            // this.setState({ fields: fields });
            this.saveFields(fields);
        }

        const array = fields.map((field, index) => {
            if (field.name && field.value) {
                return (
                    <div className="discord-embed-field" key={field.id} style={{ gridColumn: this.getFieldGridColumn(this.getFields(), index) }}>
                        <div className="discord-embed-field-title"><b>{field.name}</b></div>
                        <div className="discord-embed-field-content"><MarkdownRender text={field.value} /></div>
                    </div>
                );
            }
        });

        this.setState({ fieldsRender: array });
    };

    validateFields = () => {
        const fields = this.state.embed.fields;

        for (const field of fields) {
            if (!field.name || !field.value) {
                return false;
            }
        }

        return true;
    };

    // checks whether embed is valid or not
    validEmbed = () => {
        var valid = false;

        if (this.state.embed.title != "") valid = true;
        if (this.state.embed.description != "") valid = true;
        if (this.state.embed.url != "") valid = true;
        if (this.state.embed.footer.text != "") valid = true;
        if (this.state.embed.footer.icon_url != "") valid = true;
        if (this.state.embed.image.url != "") valid = true;
        if (this.state.embed.thumbnail.url) valid = true;
        if (this.state.embed.author.name != "") valid = true;
        if (this.state.embed.author.url != "") valid = true;
        if (this.state.embed.author.icon_url != "") valid = true;
        if (this.state.embed.fields.length > 0) valid = true;

        return valid;
    };

    // prepares embed for sending
    prepareEmbed = () => {
        const embed = {
            title: this.state.embed.title ? this.state.embed.title : null,
            description: this.state.embed.description ? this.state.embed.description : null,
            url: this.state.embed.url ? this.state.embed.url : null,
            timestamp: this.state.embed.footer.current_timestamp ? new Date().toISOString() : null, // new Date(data.footer?.timestamp || new Date()).toISOString(),
            color: this.state.embed.color.hex ? Number(`0x${this.state.embed.color.hex.charAt(0) === '#' ? this.state.embed.color.hex.slice(1) : this.state.embed.color.hex}`) : Number('0xfff'),
            footer: {
                text: this.state.embed.footer.text ? this.state.embed.footer.text : null,
                icon_url: this.state.embed.footer.icon_url ? this.state.embed.footer.icon_url : null,
            },
            image: {
                url: this.state.embed.image.url ? this.state.embed.image.url : null
            },
            thumbnail: {
                url: this.state.embed.thumbnail.url ? this.state.embed.thumbnail.url : null,
            },
            author: {
                name: this.state.embed.author.name ? this.state.embed.author.name : null,
                url: this.state.embed.author.url ? this.state.embed.author.url : null,
                icon_url: this.state.embed.author.icon_url ? this.state.embed.author.icon_url : null
            },
            fields: this.state.embed.fields ? this.state.embed.fields : null,
        };
        return embed;
    };

    prepareCode = () => {
        return `{
            title: ${this.state.embed.title ? this.state.embed.title : null},
            description: ${this.state.embed.description ? this.state.embed.description : null},
            url: ${this.state.embed.url ? this.state.embed.url : null},
            timestamp: ${this.state.embed.footer.current_timestamp ? new Date().toISOString() : null},
            color: ${this.state.embed.color.hex ? Number(`0x${this.state.embed.color.hex.charAt(0) === '#' ? this.state.embed.color.hex.slice(1) : this.state.embed.color.hex} `) : Number('0xfff')},
            footer: {
                text: ${this.state.embed.footer.text ? this.state.embed.footer.text : null},
                icon_url: ${this.state.embed.footer.icon_url ? this.state.embed.footer.icon_url : null},
            },
            image: {
                url: ${this.state.embed.image.url ? this.state.embed.image.url : null}
            },
            thumbnail: {
                url: ${this.state.embed.thumbnail.url ? this.state.embed.thumbnail.url : null},
            },
            author: {
                name: ${this.state.embed.author.name ? this.state.embed.author.name : null},
                url: ${this.state.embed.author.url ? this.state.embed.author.url : null},
                icon_url: ${this.state.embed.author.icon_url ? this.state.embed.author.icon_url : null}
            },
            fields: ${this.state.embed.fields ? this.state.embed.fields : null},
        }`;
    };

    // gets field
    getField = (fieldID) => {
        const fields = this.getFields();
        const found = fields.find(field => field.id === fieldID);
        return found;
    };

    // save function for fields
    saveFields = (data) => {
        this.setState(prevState => ({
            ...prevState,
            embed: {
                ...prevState.embed,
                fields: data
            }
        }));
    };

    // return all fields
    getFields = () => {
        return this.state.embed.fields;
    };

    // delete a field
    deleteField = (fieldID) => {
        var fields = this.getFields();
        fields = fields.filter((obj) => {
            return obj.id !== fieldID;
        });

        this.setState({ fieldCount: this.state.fieldCount - 1 });

        var counter = 0;
        for (var i = 0; i < this.state.fieldCount; i++) {
            if (fields[i] != null) {
                fields[i].number = counter++;
            }
        }

        const array = fields.map((field, index) => {
            if (field.name != undefined && field.value != undefined) {
                return (
                    <div className="discord-embed-field" key={field.id} style={{ gridColumn: this.getFieldGridColumn(this.getFields(), index) }}>
                        <div className="discord-embed-field-title"><b>{field.name}</b></div>
                        <div className="discord-embed-field-content">{field.value}</div>
                    </div>
                );
            }
        });
        this.setState({ fieldsRender: array });

        this.saveFields(fields);

    };

    // creates dom for fields
    renderFields = () => {
        return (
            <div className="embed-builder-field-section">
                <div style={{ color: "white" }}>{this.state.fieldCount} / 25 fields</div>
                {this.state.embed.fields.length > 0 && <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                    {this.getFields().map((field, index) => {
                        return <div className="embed-builder-field" key={field.id}>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton style={{ backgroundColor: '#232529', borderBottom: "1px solid #070709" }}>
                                        Field #{index + 1}
                                        <span style={{ float: "right" }}><FontAwesomeIcon icon={faTrash} className="embed-field-delete" fixedWidth onClick={() => {
                                            this.deleteField(field.id);
                                        }} /></span>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <LongInput

                                        variables={false}
                                        title={"Field Name"}
                                        placeholder={"BotGhost"}
                                        maxLength={256}
                                        value={this.getField(field.id).name}
                                        change={(value) => {
                                            this.updateField(field.id, 0, value);
                                        }}
                                    />
                                    <>
                                        <h3 style={{ marginBottom: "0px" }}>Field Content </h3>
                                        <div className="textarea-container mb-15">
                                            <textarea maxLength={1024} onChange={(event) => {
                                                if (this.getField(field.id).value.length < 1024 || event.target.value.length < this.getField(field.id).value.length) {
                                                    this.updateField(field.id, 1, event.target.value);
                                                }
                                            }}
                                                placeholder={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."}
                                                value={this.getField(field.id).value}
                                                lines={4}
                                                className={`text-area full-width ${this.props.extra_classNames != undefined ? this.props.extra_classNames : ""}`}>
                                            </textarea>

                                            <div className="textarea-counter" style={{ color: this.getField(field.id).value.length >= 1024 ? "#f45142" : "" }}>
                                                {`${this.getField(field.id).value.length}/1024`}
                                            </div>
                                        </div>
                                    </>
                                    <h4 style={{ color: "white" }}>Inline Field</h4>
                                    <Toggle
                                        value={this.getField(field.id).inline}
                                        update={(value) => {
                                            this.updateField(field.id, 2, value);
                                        }}
                                        type="mention"
                                    ></Toggle>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </div>;
                    })}
                </Accordion>}
                <button className="btn btn-red" onClick={(e) => {
                    if (this.state.fieldCount === 25) {

                    } else {
                        const fields = this.getFields();
                        fields.push({
                            name: "",
                            value: "",
                            inline: false,
                            number: this.state.fieldCount,
                            id: uuid4()
                        });

                        this.setState({ fieldCount: this.state.fieldCount + 1 });
                        this.saveFields(fields);

                    }
                }}>Add Field</button>
            </div >
        );
    };

    removeItemOnce = (arr, value) => {
        var index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    };

    handleDropdown = (item) => {
        var drops = this.state.showDrop;
        drops.includes(item) ? drops = this.removeItemOnce(drops, item) : drops.push(item);
        this.setState({ showDrop: drops });
    };

    isInDropdown = (item) => {
        return this.state.showDrop.includes(item);
    };

    render() {
        return (
            <Dashboard>
                <ToastContainer />
                <ModuleHeader
                    tutorial="https://docs.botghost.com/server-management/embedbuilder"
                    showEnabled={false}
                    title="Embed Builder"
                    docs="https://docs.botghost.com/server-management/embedbuilder"
                    description="Create and send highly customized embeds to your server"
                    icon="embedbuilder.png"
                ></ModuleHeader>

                <div className="row embed-builder-div m-section-content-reverse">
                    <section className="col">

                        <div className="section-content-normal">
                            <div>
                                <div>
                                    <h3 className='mb-0'><RefreshBotData /> Embed Selector</h3>
                                    <div className="section-content-header">Select a saved embed to edit</div>
                                </div>

                                <div className="mt-15">
                                    <Select
                                        type="embeds"
                                        value={this.state.tEmbed}
                                        onChange={(value) => {
                                            this.setState({ tEmbed: value });
                                        }}
                                    ></Select>
                                </div>
                            </div>
                            <div className="mt-15 button-group">
                                <button className="btn btn-red" onClick={(e) => {
                                    !this.state.saveModal && this.setState({ saveModal: true });
                                }}>Save current embed</button>

                                <button className="btn btn-red" onClick={(e) => {
                                    const embed = this.props.bot.commands.embeds.embeds.find((embed) => embed.id === this.state.tEmbed);
                                    if (!embed) {
                                        this.errorToast("Please select an embed from the embed selector!");
                                    } else {
                                        var content = embed.content;
                                        var name = embed.name;
                                        var id = embed.id;
                                        if (!embed.content) content = "";

                                        const fe = { ...embed };
                                        delete fe.content;
                                        delete fe.id;
                                        delete fe.name;
                                        if (fe.color) {
                                            var hexString = fe.color.toString(16);
                                            fe.color = {
                                                hex: "#" + hexString,
                                            };
                                        }
                                        if (!fe.author.name) fe.author.name = "";
                                        if (!fe.author.icon_url) fe.author.icon_url = "";
                                        if (!fe.author.url) fe.author.url = "";

                                        if (!fe.description) fe.description = "";
                                        if (!fe.title) fe.title = "";

                                        if (!fe.footer.text) fe.footer.text = "";
                                        if (!fe.footer.icon_url) fe.footer.icon_url = "";

                                        if (!fe.image.url) fe.image.url = "";
                                        if (!fe.thumbnail.url) fe.thumbnail.url = "";

                                        if (!fe.footer.current_timestamp) fe.footer.current_timestamp = false;

                                        const fieldsArray = fe.fields.map((field, index) => {
                                            if (field.name != undefined && field.value != undefined) {
                                                return (
                                                    <div className="discord-embed-field" key={field.id} style={{ gridColumn: this.getFieldGridColumn(fe.fields, index) }}>
                                                        <div className="discord-embed-field-title"><b>{field.name}</b></div>
                                                        <div className="discord-embed-field-content">{field.value}</div>
                                                    </div>
                                                );
                                            }
                                        });
                                        this.setState({ content: content, fieldsRender: fieldsArray, embed: fe, loadedEmbed: true, embedName: name, embedID: id });

                                    }
                                }}>Load embed</button>

                                <button className="ml-2 btn btn-red m-ml-0 m-mr-0" onClick={(e) => {
                                    !this.state.saveModal && this.setState({ saveModal: true });
                                }}>Save Embed</button>

                                <button className="ml-2 btn btn-red m-ml-0 m-mr-0" onClick={(e) => {
                                    !this.state.deleteModal && this.setState({ deleteModal: true });
                                }}>Delete a saved embed</button>

                                <button className="btn btn-outline" onClick={(e) => {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        content: "",
                                        embed: {
                                            title: "",
                                            description: "",
                                            color: "",
                                            url: "",
                                            author: {
                                                name: "",
                                                url: "",
                                                icon_url: ""
                                            },
                                            image: {
                                                url: ""
                                            },
                                            thumbnail: {
                                                url: ""
                                            },
                                            footer: {
                                                text: "",
                                                icon_url: ""
                                            },
                                            fields: []
                                        },
                                        fieldCount: 0,
                                        fieldsRender: [],
                                    }), () => {
                                        // force rerender


                                        this.successToast("Successfully cleared embed.");
                                    });
                                }}>Clear embed</button>
                            </div>
                        </div>

                        {/* begin info */}
                        <div className="announcement" style={{ border: `${this.state.messageError ? "2px solid #f45142" : "none"}` }}>
                            <TabAnnouncementHeader tag='info' title='Basic Info' description='Select a channel, input a message link and add a message' handleClick={this.handleDropdown} />
                            {this.isInDropdown('info') &&
                                <div className="section-content-normal" style={{ paddingTop: 0 }}>
                                    <div>
                                        <div>
                                            <h3 className='mb-0'><RefreshBotData /> Channel</h3>
                                            <div className="section-content-header">The channel that you want to send the embed to</div>
                                        </div>

                                        <div className="mt-15">
                                            <Select
                                                type="channel"
                                                value={JSON.stringify(this.state.channel)}
                                                onChange={(value) => {
                                                    var channel = JSON.parse(value);
                                                    this.setState({
                                                        channel: channel
                                                    });
                                                }}
                                            ></Select>
                                        </div>
                                    </div>
                                    <div className="mt-15">                                    <LongInput
                                        variables={false}
                                        title={"Message Link"}
                                        description={`Want to update an existing embed? Paste the message link, and select the channel that the embed is currently in`}
                                        placeholder={"https://discord.com/channels/822426820447567872/889338817281134612/1019014695979585556"}
                                        maxLength={256}
                                        value={this.state.messageLink}
                                        iconColor={"#f45142"}
                                        icon={faExclamationCircle}
                                        showIcon={this.state.messageError}
                                        change={(value) => {
                                            this.setState({ messageLink: value });
                                            if (value !== "") {
                                                this.setState({ messageError: false });
                                                const u = new URL(value);
                                                var url = u.pathname.split('/');
                                            } else {
                                                this.setState({ messageError: false });
                                            }
                                        }}
                                    />
                                    </div>
                                    <div className="mt-15">
                                        <><h3 style={{ marginBottom: "0px" }}>Content </h3>
                                            <div className="textarea-container mb-15">
                                                <textarea maxLength={4096} onChange={(event) => {
                                                    if (this.state.content.length < 4096 || event.target.value.length < this.state.content.length) {
                                                        this.setState({ content: event.target.value });
                                                    }
                                                }}
                                                    placeholder={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."}
                                                    value={this.state.content}
                                                    lines={4}
                                                    className={`text-area full-width ${this.props.extra_classNames != undefined ? this.props.extra_classNames : ""}`}>
                                                </textarea>

                                                <div className="textarea-counter" style={{ color: this.state.content.length >= 4096 ? "#f45142" : "" }}>
                                                    {`${this.state.content.length}/4096`}
                                                </div>
                                            </div></>
                                    </div>
                                </div>
                            }
                        </div>

                        {/* Author */}
                        <div className="announcement" style={{ border: `${this.state.authorError || this.state.authorImageError ? "2px solid #f45142" : "none"}` }}>
                            <TabAnnouncementHeader tag='author' title='Author' description='Edit the author fields for your embed' handleClick={this.handleDropdown} />
                            {this.isInDropdown("author") &&
                                <div className="section-content-normal" style={{ paddingTop: 0 }}>
                                    <>                                    <LongInput
                                        variables={false}
                                        title={"Name"}
                                        description={`Length: ${this.state.embed.author.name.length}/256`}
                                        placeholder={"BotGhost"}
                                        maxLength={256}
                                        value={this.state.embed.author.name}
                                        change={(value) => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                embed: {
                                                    ...prevState.embed,
                                                    author: {
                                                        ...prevState.embed.author,
                                                        name: value
                                                    }
                                                }
                                            }));
                                        }}
                                    /></>

                                    <>                                    <LongInput
                                        variables={false}
                                        title={"URL"}
                                        description={"A valid url for the author click"}
                                        placeholder={"https://botghost.com/"}
                                        maxLength={256}
                                        value={this.state.embed.author.url}
                                        iconColor={"#f45142"}
                                        icon={faExclamationCircle}
                                        showIcon={this.state.authorError}
                                        change={(value) => {
                                            if (value !== "" && !urlReg.test(value)) {
                                                this.setState({ authorError: true });
                                            } else {
                                                this.setState({ authorError: false });
                                            }
                                            this.setState(prevState => ({
                                                ...prevState,
                                                embed: {
                                                    ...prevState.embed,
                                                    author: {
                                                        ...prevState.embed.author,
                                                        url: value
                                                    }
                                                }
                                            }));
                                        }}
                                    /></>

                                    <>                                    <LongInput
                                        variables={false}
                                        title={"Image URL"}
                                        description={"The image url for the author"}
                                        label={"Name"}
                                        placeholder={"https://botghost.com/image.png"}
                                        maxLength={256}
                                        value={this.state.embed.author.icon_url}
                                        iconColor={"#f45142"}
                                        icon={faExclamationCircle}
                                        showIcon={this.state.authorImageError}
                                        change={(value) => {
                                            if (value !== "" && !imageReg.test(value)) {
                                                this.setState({ authorImageError: true });
                                            } else {
                                                this.setState({ authorImageError: false });
                                            }
                                            this.setState(prevState => ({
                                                ...prevState,
                                                embed: {
                                                    ...prevState.embed,
                                                    author: {
                                                        ...prevState.embed.author,
                                                        icon_url: value
                                                    }
                                                }
                                            }));
                                        }}
                                    /></>
                                </div>
                            }
                        </div>

                        {/* Body */}
                        <div className="announcement" style={{ border: `${this.state.bodyError ? "2px solid #f45142" : "none"}` }}>
                            <TabAnnouncementHeader tag='body' title='Body' description='Edit the body of your embed' handleClick={this.handleDropdown} />
                            {this.isInDropdown('body') &&
                                <div className="section-content-normal" style={{ paddingTop: 0 }}>
                                    <>                                    <LongInput
                                        variables={false}
                                        title={"Title"}
                                        description={`Length: ${this.state.embed.title.length}/256`}
                                        placeholder={"BotGhost"}
                                        maxLength={257}
                                        value={this.state.embed.title}
                                        change={(value) => {
                                            if (this.state.embed.title.length < 256 || value.length < this.state.embed.title.length) {
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    embed: {
                                                        ...prevState.embed,
                                                        title: value
                                                    }
                                                }));
                                            }
                                        }}
                                    /></>

                                    <><h3 style={{ marginBottom: "0px" }}>Description </h3>
                                        <div className="textarea-container mb-15">
                                            <textarea maxLength={4096} onChange={(event) => {
                                                if (this.state.embed.description.length < 4096 || event.target.value.length < this.state.embed.description.length) {
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        embed: {
                                                            ...prevState.embed,
                                                            description: event.target.value
                                                        }
                                                    }));
                                                }

                                            }}
                                                placeholder={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."}
                                                value={this.state.embed.description}
                                                lines={4}
                                                className={`text-area full-width ${this.props.extra_classNames != undefined ? this.props.extra_classNames : ""}`}>
                                            </textarea>

                                            <div className="textarea-counter" style={{ color: this.state.embed.description.length >= 4096 ? "#f45142" : "" }}>
                                                {`${this.state.embed.description.length}/4096`}
                                            </div>
                                        </div></>

                                    <>                                    <LongInput
                                        variables={false}
                                        title={"URL"}
                                        description={""}
                                        label={""}
                                        placeholder={"https://botghost.com/"}
                                        maxLength={256}
                                        iconColor={"#f45142"}
                                        icon={faExclamationCircle}
                                        showIcon={this.state.bodyError}
                                        value={this.state.embed.url}
                                        change={(value) => {
                                            if (!urlReg.test(value)) {
                                                this.setState({ bodyError: true });
                                            } else {
                                                this.setState({ bodyError: false });
                                            }

                                            if (value === "") {
                                                this.setState({ bodyError: false });
                                            }

                                            this.setState(prevState => ({
                                                ...prevState,
                                                embed: {
                                                    ...prevState.embed,
                                                    url: value
                                                }
                                            }));
                                        }}
                                    /></>

                                    <div className="section-content">
                                        <div>
                                            <h3>Embed Color</h3>
                                            <div className="section-content-header">
                                                Change the color of the embed
                                            </div>
                                        </div>

                                        <div style={{ marginLeft: "auto" }}>
                                            <div className="levels-color-picker" ref={this.setWrapperRef}>
                                                <div
                                                    class="embed-color-picker-selected"
                                                    style={{
                                                        backgroundColor: this.state.embed.color.hex,
                                                    }}
                                                    onClick={(e) => {
                                                        this.setState({ colorOpen: true });
                                                    }}

                                                >
                                                    <i class="bi bi-eyedropper"></i>
                                                </div>
                                                {this.renderColorPicker()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        {/* Fields */}
                        <div className="announcement">
                            <TabAnnouncementHeader tag='fields' title='Embed Fields' description='Add fields to your embed' handleClick={this.handleDropdown} />
                            {this.isInDropdown('fields') ? (
                                <div className="section-content-normal" style={{ paddingTop: 0 }}>
                                    {this.renderFields()}
                                </div>
                            ) : null}
                        </div>

                        {/* Image */}
                        <div className="announcement" style={{ border: `${this.state.imageError || this.state.thumbnailError ? "2px solid #f45142" : "none"}` }}>
                            <TabAnnouncementHeader tag='images' title='Images' description='Add images to your embed' handleClick={this.handleDropdown} />
                            {this.isInDropdown('images') &&
                                <div className="section-content-normal" style={{ paddingTop: 0 }}>
                                    <LongInput
                                        variables={false}
                                        title={"Image URL"}
                                        placeholder={"https://botghost.com/image.png"}
                                        maxLength={2048}
                                        value={this.state.embed.image.url}
                                        iconColor={"#f45142"}
                                        icon={faExclamationCircle}
                                        showIcon={this.state.imageError}
                                        change={(value) => {
                                            if (value !== "" && !imageReg.test(value)) {
                                                this.setState({ imageError: true });
                                            } else {
                                                this.setState({ imageError: false });
                                            }
                                            this.setState(prevState => ({
                                                ...prevState,
                                                embed: {
                                                    ...prevState.embed,
                                                    image: {
                                                        ...prevState.embed.image,
                                                        url: value
                                                    }
                                                }
                                            }));
                                        }}
                                    />

                                    <>                                    <LongInput
                                        variables={false}
                                        title={"Thumbnail URL"}
                                        placeholder={"https://botghost.com/image.png"}
                                        maxLength={2048}
                                        value={this.state.embed.thumbnail.url}
                                        iconColor={"#f45142"}
                                        icon={faExclamationCircle}
                                        showIcon={this.state.thumbnailError}
                                        change={(value) => {
                                            if (value !== "" && !imageReg.test(value)) {
                                                this.setState({ thumbnailError: true });
                                            } else {
                                                this.setState({ thumbnailError: false });
                                            }
                                            this.setState(prevState => ({
                                                ...prevState,
                                                embed: {
                                                    ...prevState.embed,
                                                    thumbnail: {
                                                        ...prevState.embed.thumbnail,
                                                        url: value
                                                    }
                                                }
                                            }));
                                        }}
                                    /></>
                                </div>
                            }
                        </div>

                        {/* Footer */}
                        <div className="announcement" style={{ border: `${this.state.footerError ? "2px solid #f45142" : "none"}` }}>
                            <TabAnnouncementHeader tag='footer' title='Footer' description='Edit the footer for your embed' handleClick={this.handleDropdown} />
                            {this.isInDropdown('footer') &&
                                <div className="section-content-normal " style={{ paddingTop: 0 }}>
                                    <>                                    <LongInput
                                        variables={false}
                                        title={"Footer Text"}
                                        description={`Length: ${this.state.embed.footer.text.length}/2048`}
                                        label={"Name"}
                                        placeholder={"This is footer text"}
                                        maxLength={2048}
                                        value={this.state.embed.footer.text}
                                        change={(value) => {
                                            if (this.state.embed.footer.text.length < 2048) {
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    embed: {
                                                        ...prevState.embed,
                                                        footer: {
                                                            ...prevState.embed.footer,
                                                            text: value
                                                        }
                                                    }
                                                }));
                                            }
                                        }}
                                    /></>

                                    <>                                    <LongInput
                                        variables={false}
                                        title={"Image URL"}
                                        description={"URL of the image to display"}
                                        label={"Image URL"}
                                        placeholder={"https://botghost.com/image.png"}
                                        maxLength={256}
                                        iconColor={"#f45142"}
                                        icon={faExclamationCircle}
                                        showIcon={this.state.footerError}
                                        value={this.state.embed.footer.icon_url}
                                        change={(value) => {
                                            if (value !== "" && !imageReg.test(value)) {
                                                this.setState({ footerError: true });
                                            } else {
                                                this.setState({ footerError: false });
                                            }
                                            this.setState(prevState => ({
                                                ...prevState,
                                                embed: {
                                                    ...prevState.embed,
                                                    footer: {
                                                        ...prevState.embed.footer,
                                                        icon_url: value
                                                    }
                                                }
                                            }));
                                        }}
                                    />
                                    </>
                                    <h3 style={{ color: "white" }}>Display Current Timestamp</h3>
                                    <Toggle
                                        value={this.state.embed.footer.current_timestamp}
                                        update={(value) => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                embed: {
                                                    ...prevState.embed,
                                                    footer: {
                                                        ...prevState.embed.footer,
                                                        current_timestamp: value
                                                    }
                                                }
                                            }));
                                        }}
                                        type="mention"
                                    ></Toggle>
                                </div>
                            }
                        </div>

                    </section>
                    <section className="col">
                        <EmbedBuilderPreview content={this.state.content} embed={this.state.embed} fieldsRender={this.state.fieldsRender} />
                        <button className="mt-15 btn btn-red" onClick={(e) => {
                            this.sendEmbed();
                        }}>Send Embed to Channel</button>
                    </section>
                </div>

                {this.state.saveModal && <EmbedBuilderSave closeModal={this.closeSaveModal} saveEmbed={this.saveEmbed} loadedEmbed={this.state.loadedEmbed} />}
                {this.state.deleteModal && <EmbedBuilderDelete closeModal={this.closeDeleteModal} embeds={this.props.bot.commands.embeds.embeds} deleteEmbed={this.deleteEmbed} />}

            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    premium: state.data.premium,
    bot: state.data.bot,
    embeds: state.data.bot.commands.embeds,
    collab: state.data.collab
});

const mapDispatchToProps = {
    setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuilderEmbed);
