import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../../../api/server';

export class DeleteBotModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteDisabled: false
        };
    }

    modalBackgroundClick = (e) => {
        if (e.currentTarget == e.target) {
            this.props.closeModal(e);
        }
    };

    delete = async () => {
        this.setState({ deleteDisabled: true });
        const response = await server.post("/dashboard/delete", { bot: this.props.bot });
        if (response.data == true) {
            window.location.replace("/dashboard");
        } else {
            this.setState({ deleteDisabled: false });
            this.props.closeModal();
        }
    };

    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} onClick={(e) => { this.modalBackgroundClick(e); }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">

                        <div class="modal-content premium-modal" style={{ backgroundColor: "#2f3136 !important" }}>
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Delete Bot</h5>
                                <button onClick={(e) => { this.props.closeModal(e); }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div class="modal-body command-edit-body text-white" style={{ overflowY: "hidden" }}>
                                <p>Are you sure you wish to delete this bot?</p>
                            </div>

                            <div class="modal-footer">
                                <button onClick={(e) => { this.props.closeModal(); }} type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button disabled={this.state.deleteDisabled} type="button" class="btn btn-danger" onClick={(e) => { this.delete(); }}>Delete Bot</button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBotModal);
