import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";

import { setBotModule } from "../../../actions";

import Dashboard from "../Dashboard";
import PremiumRedirect from "../elements/PremiumRedirect";
import PremiumSection from "../elements/PremiumSection";

// "textChannel": {
//     "enabled": false,
// },
// "voiceChannel": {
//     "enabled": false,
// },
// "humanChannel": {
//     "enabled": false,
// },
// "botChannel": {
//     "enabled": false,
// },
// "membersChannel": {
//     "enabled": false,
// },
// "roleChannel": {
//     "enabled": false,
// },
// "emojiChannel": {
//     "enabled": false,
// },
// "categoryChannel": {
//     "enabled": false,
// }
const CHANNEL_TYPES = [
  { type: "textChannel", text: "Text Channels" },
  { type: "voiceChannel", text: "Voice Channels" },
  // {type:"humanChannel",text:"Humans"},
  // {type:"botChannel",text:"Bots"},
  { type: "membersChannel", text: "Members" },
  { type: "roleChannel", text: "Roles" },
  { type: "emojiChannel", text: "Emojis" },
  { type: "categoryChannel", text: "Categories" },
];

export class StatsChannels extends Component {
  enableChange = (value) => {
    var statsChannels = { ...this.props.statsChannels };
    statsChannels.enabled = value;
    this.props.setBotModule({
      module: "statsChannels",
      module_data: statsChannels,
    });
    // utils.weather.enabled = value
    // this.props.setBotModule({
    //     module:"utils",
    //     module_data:utils
    // })
  };

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  changeName = (type, value) => {
    var statsChannels = { ...this.props.statsChannels };
    statsChannels.channels[type].name = value;
    this.props.setBotModule({
      module: "statsChannels",
      module_data: statsChannels,
    });
  };

  renderChannels = () => {
    var channels = [];

    CHANNEL_TYPES.forEach((channel) => {
      channels.push(
        <div className="announcement ">
          <div className="announcement-header">
            <div>
              <h3 className="mb-0">{channel.text} Counter</h3>
              <div className="section-content-header">
                Count the number of {channel.text} in your server
              </div>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <Toggle
                disabled={this.props.premium == false}
                value={this.props.statsChannels.channels[channel.type].enabled}
                update={(value) => {
                  this.enableChannel(channel.type, value);
                }}
              />
            </div>
          </div>

          {this.props.statsChannels.channels[channel.type].enabled == true ? (
            <div className="announcement-content">
              <div class="long-input mb-15">
                <label>Channel Name</label>
                <input
                  disabled={this.props.premium == false}
                  type="text"
                  onChange={(e) => {
                    this.changeName(channel.type, e.target.value);
                  }}
                  value={
                    this.props.statsChannels.channels[channel.type].name || ""
                  }
                />
              </div>
            </div>
          ) : null}
        </div>
      );
    });
    return channels;
  };

  enableChannel = (type, value) => {
    if (this.props.premium == false) {
      return;
    }
    var statsChannels = { ...this.props.statsChannels };
    // console.log(value);
    statsChannels.channels[type].enabled = value;

    this.props.setBotModule({
      module: "statsChannels",
      module_data: statsChannels,
    });
  };

  changeCatName = (value) => {
    var statsChannels = { ...this.props.statsChannels };
    statsChannels.category_name = value;

    this.props.setBotModule({
      module: "statsChannels",
      module_data: statsChannels,
    });
  };
  render() {
    return (
      <Dashboard>
        <PremiumRedirect></PremiumRedirect>
        <ModuleHeader
          youtube="https://www.youtube.com/watch?v=5Rv9Z2jPJMk"
          premiumRequired={true}
          tutorial="https://docs.botghost.com/server-management/statistics-channels"
          description="Create channels to track metrics of your server"
          change={this.enableChange}
          enabledValue={this.props.statsChannels.enabled}
          title="Statistics Channels"
          icon="statistics.png"
        ></ModuleHeader>

        <PremiumSection>
          <SectionHeader
            title="Channels"
            pretitle="Statistics Channels"
          ></SectionHeader>

          <div className="commands">{this.renderChannels()}</div>
        </PremiumSection>

        <PremiumSection>
          <SectionHeader
            title="Category"
            pretitle="Statistics Channels"
          ></SectionHeader>
          <div className="section-content-normal">
            <div className="mb-15">
              <h3 class="mb-0">Statistics Category</h3>
              <div class="section-content-header">
                The name of the category you wish to house your statistics
                channels in
              </div>
            </div>

            <div class="long-input">
              <label>Category Name</label>
              <input
                disabled={this.props.premium == false}
                type="text"
                onChange={(e) => {
                  this.changeCatName(e.target.value);
                }}
                value={this.props.statsChannels.category_name || ""}
              />
            </div>
          </div>
        </PremiumSection>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  statsChannels: state.data.bot.commands.statsChannels,
  beta: state.data.beta,
  premium: state.data.premium,
});

const mapDispatchToProps = {
  setBotModule,
};
export default connect(mapStateToProps, mapDispatchToProps)(StatsChannels);
