import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';

import TextArea from '../../elements/TextArea';
import Select from '../../elements/Select';
import VariableTextInput from '../VariableTextInput';


export class ChangeStatus extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "status_change",
                        status: "",
                        activity: "LISTENING",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "status_change",
                        status: "",
                        activity: "LISTENING",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

            if ((this.props.data.status != "") && this.props.data.validated != true) {
                this.updateData("validated", true);
            } else if (this.props.data.validated == true && (this.props.data.status == "")) {
                this.updateData("validated", false);

            }
        }
    }

    updateData = (key, value) => {

        // console.log("VALUE", value);
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    nameChange = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "_");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("reference", value);
    };


    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Change the Bot's Status</h4>
                        <p>Change the bot's status or presence message. <strong className='text-red'>Only premium users can change their bots status!</strong></p>
                    </div>

                    <h4 style={{ marginBottom: "0px" }}>Status</h4>
                    <div className="section-content-header mb-15">The bot's status message. All variables can be used.</div>
                    {/* <VariableTextInput slash_options={this.props.options} value={this.props.data.response_options.target.user_option} onChange={(value) => {
                                var response_options = { ...this.props.data.response_options };
                                response_options.target.user_option = value;
                                this.updateData("response_options", response_options);
                            }} /> */}

                    <VariableTextInput required={true} slash_options={this.props.options} label="Status" placeholder="Status" value={this.props.data.status} onChange={(value) => {
                        this.updateData("status", value);
                    }} />

                    {/* <div class="long-input mb-15">
                        <label>Status</label>
                        <input className={`${this.props.data.status == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                            // if (!isNaN(e.target.value) && e.target.value <= 850) {
                            this.updateData("status", e.target.value);
                            // }
                        }} type="text" value={this.props.data.status} />
                    </div> */}

                    <h4 className='mt-15' style={{ marginBottom: "0px" }}>Status Type</h4>
                    <div className="section-content-header mb-15">Your Bot's status type.</div>
                    <Select value={this.props.data.activity} onChange={(value) => {
                        this.updateData("activity", value);
                    }} options={[{ label: "Listening", value: "LISTENING" },
                    { label: "Playing", value: "PLAYING" },
                    { label: "Watching", value: "WATCHING" },]} />






                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatus);
