import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setElements } from '../../../../actions';
import getLayoutedElements from "../getLayoutElements";
import Embed from '../../elements/Embed';
import TextArea from '../../elements/TextArea';
import Select from '../../elements/Select';
import RefreshBotData from '../../elements/RefreshBotData';
import { addEdge, getOutgoers } from 'react-flow-renderer';
import { EmojiAdder } from './EmojiAdder';
import RoleAdd from "../../elements/RoleAdd";
import splitElements from '../splitElements';
import VariableTextInput from '../VariableTextInput';

const responseTypes = [
    { value: "reply", label: "Reply to the command" },
    { value: "targeted", label: "Send the message to a specific text-channel" }
];


export class ChannelCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ""
        };
    }

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "condition",
                        condition_type: "channel",
                        multiple: false,
                        validated: true
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "condition",
                        condition_type: "channel",
                        multiple: false,
                        validated: true
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE
        }
    }





    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };


    renderOptions = () => {
        var options = [
            { value: "", label: "" }
        ];
        this.props.options.forEach(option => {
            options.push({ value: option.name, label: option.name });
        });
        return options;
    };

    selectUpdate = (value) => {
        value = JSON.parse(value);
        var response_options = { ...this.props.data.response_options };
        response_options.target = value;
        this.updateData("response_options", response_options);
    };

    addCondition = () => {
        var elements = [...this.props.elements];
        var element = this.props.elements.find(element => element.id == this.props.selected);
        var existingConditions = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);


        var id = `conChild${elements.length + 1}_${Math.random().toString(36).substr(2, 9)}`;
        var lastChild = existingConditions[existingConditions.length - 1];

        var docuElement = document.getElementById(lastChild.id);
        var width = 0;
        if (docuElement) {
            width = docuElement.clientWidth;
        }



        elements.push(
            {
                id: id,
                type: 'conditionChild',
                data: {
                    node_options: {
                        type: "channel"
                    },
                    data: {
                        id: this.state.value.id,
                        name: this.state.value.name,
                        actions: [],
                        option: "channel",
                        not: false
                    }

                },
                // target:"3",
                draggable: true,
                position: { x: lastChild.position.x + width + 20, y: lastChild.position.y },
            },
        );
        var newEdges = addEdge({
            source: element.id,
            target: id,
            type: "step",
            animated: false,
            arrowHeadType: "arrowclosed"

        }, splitElements(elements).edges);
        newEdges.forEach(newEdge => {
            var index = elements.findIndex(element => element.id == newEdge.id);
            if (index == -1) {
                elements.push(newEdge);
            }
            // elements[index] = newNode;
        });
        // elements = getLayoutedElements(elements)
        this.props.setElements(elements);
        this.setState({ value: "" });



    };

    renderSelectOptions = (type) => {
        var options = [
            // {value:"trigger",label:"The member who triggered the command"}
        ];
        this.props.options.forEach(option => {
            if (option.type == 'channel') {
                options.push({ value: { id: `{option_${option.name}}`, name: option.name }, label: `Channel Option: ${option.name}` });

            }

        });
        return options;
    };

    renderConditionValue = () => {
        if (this.props.data.option != "") {
            var option = this.props.options.find(option => { return option.name == this.props.data.option; });
            if (option == undefined) {

            } else if (option.type == "string" || option.type == "int") {
                return (
                    <div className="mb-15">
                        <div className="mb-15 slash-action">
                            <h4>Conditions </h4>
                            <span>Add a condition. Enter the value for the option</span>
                            <VariableTextInput label="Value" placeholder="Value" slash_options={this.props.options} value={this.state.value} onChange={(value) => {
                                this.setState({ value: value });
                            }}></VariableTextInput>

                            {/* <div class="long-input mt-15">
                                <label>Value</label>
                                <input onChange={(e) => {

                                    this.setState({ value: e.target.value });

                                }} type="text" value={this.state.value} />
                            </div> */}
                        </div>
                        <button disabled={this.state.value == "" || this.state.value.id == "" ? true : false} onClick={() => { this.addCondition(); }} className="btn btn-red add-button">Add Condition</button>
                    </div>
                );

            } else if (option.type == "user") {
                return (
                    <div className="mb-15">
                        <div className="mb-15 slash-action">
                            <h4>Conditions </h4>
                            <span>Add a condition. Enter the user id for the option.</span>
                            <VariableTextInput label="User ID" placeholder="User ID" slash_options={this.props.options} value={this.state.value} onChange={(value) => {
                                this.setState({ value: value });
                            }}></VariableTextInput>
                            {/* <div class="long-input mt-15">
                                <label>User ID</label>
                                <input onChange={(e) => {

                                    this.setState({ value: e.target.value });

                                }} type="text" value={this.state.value} />
                            </div> */}
                        </div>
                        <button disabled={this.state.value == "" ? true : false} onClick={() => { this.addCondition(); }} className="btn btn-red add-button">Add Condition</button>
                    </div>
                );
            } else if (option.type == "role") {
                return (
                    <div className="mb-15">
                        <div className="mb-15 slash-action">
                            <h4>Conditions <RefreshBotData /></h4>
                            <span>Add a condition. Enter the value for the option</span>
                            <div className='mt-15'>
                                <Select value={JSON.stringify(this.state.value)} onChange={(value) => {
                                    value = JSON.parse(value);
                                    this.setState({ value: value });
                                }} type="role" ></Select>
                            </div>


                        </div>
                        <button disabled={this.state.value == "" ? true : false} onClick={() => { this.addCondition(); }} className="btn btn-red add-button">Add Condition</button>
                    </div>
                );
            } else if (option.type == "channel") {
                return (
                    <div className="mb-15">
                        <div className="mb-15 slash-action">
                            <h4>Conditions <RefreshBotData /></h4>
                            <span>Add a condition. Enter the value for the option</span>
                            <div className='mt-15'>
                                <Select value={JSON.stringify(this.state.value)} onChange={(value) => {
                                    value = JSON.parse(value);
                                    this.setState({ value: value });
                                }} type="channel" ></Select>
                            </div>

                        </div>
                        <button disabled={this.state.value == "" ? true : false} onClick={() => { this.addCondition(); }} className="btn btn-red add-button">Add Condition</button>
                    </div>
                );
            } else if (option.type == "boolean") {
                return (
                    <div className="mb-15">
                        <div className="mb-15 slash-action">
                            <h4>Conditions </h4>
                            <span>Add a condition. Enter the value for the option</span>
                            <div className='mt-15'>
                                <Select options={[
                                    { label: "True", value: "true" },
                                    { label: "False", value: "false" },
                                ]} value={this.state.value} onChange={(value) => {
                                    this.setState({ value: value });
                                }} ></Select>
                            </div>


                        </div>
                        <button disabled={this.state.value == "" ? true : false} onClick={() => { this.addCondition(); }} className="btn btn-red add-button">Add Condition</button>
                    </div>
                );
            }
        }
    };

    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Channel Condition <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/channel-condition" /></h4>
                        <p>{`Run actions based on the ${this.props.mode == "event" ? "channel." : "channel the command was used in."}`} </p>
                    </div>



                    <div className='mb-15 slash-action'>
                        <h4>Run multiple actions <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/channel-condition#run-multiple-actions" /> </h4>
                        <span>Should the condition run multiple actions if multiple conditions are met.</span>
                        <Select value={this.props.data.multiple} onChange={(value) => {

                            this.updateData('multiple', value == "true" ? true : false);
                            // value = JSON.parse(value);
                            // this.setState({value:value})
                        }} options={[
                            { label: "Yes", value: true },
                            { label: "No", value: false },

                        ]} ></Select>

                    </div>


                    <div className="mb-15">
                        <div className="mb-15 slash-action">
                            <h4>Channel <RefreshBotData /> <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/channel-condition#channel" /></h4>
                            <span>The channel to base this condition on.</span>
                            <div className='mt-15'>
                                <Select slash_options={this.renderSelectOptions()} value={JSON.stringify(this.state.value)} onChange={(value) => {
                                    value = JSON.parse(value);
                                    this.setState({ value: value });
                                }} type="channel" ></Select>
                            </div>

                        </div>
                        <button disabled={this.state.value == "" ? true : false} onClick={() => { this.addCondition(); }} className="btn btn-red add-button">Add Condition</button>
                    </div>


                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    mode: state.builder.mode,
});
const mapDispatchToProps = {
    updateElementData,
    setElements
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelCondition);
