import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";

import Embed from "../../elements/Embed";
import Select from "../../elements/Select";
import VariableTextInput from "../VariableTextInput";

const STYLES = [
	{ value: "PRIMARY", label: "Blue" },
	{ value: "SECONDARY", label: "Gray" },
	{ value: "SUCCESS", label: "Green" },
	{ value: "DANGER", label: "Red" },
	{ value: "LINK", label: "Link" }
];

export class AdvButton extends Component {
	componentDidMount() {
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "button",
					label: "",
					style: "",
					emoji_id: "",
					orderComponent: false,
					weight: "",
					row: "",
					validated: false
				},
				id: this.props.id
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "button",
					label: "",
					emoji_id: "",
					style: "",
					orderComponent: false,
					weight: "",
					row: "",
					validated: false
				},
				id: this.props.id
			});
		} else {

			// Validate button

			if ((this.props.data.label || this.props.data.emoji_id) && this.props.data.style && !this.props.data.validated) {
				if (this.props.data.style === "LINK") {
					if (this.props.data.URL) {
						this.updateData("validated", true);
					}
				} else {
					this.updateData("validated", true);
				}
			} else if ((!this.props.data.label && !this.props.data.emoji_id || !this.props.data.style || (this.props.data.style === "LINK" && !this.props.data.URL)) && this.props.data.validated) {
				this.updateData("validated", false);
			}
			// VALIDATE
			// if ((this.props.data.embed.title != "" && this.props.data.embed.description != "") && this.props.data.validated != true) {
			//     this.updateData("validated", true)
			// } else if (this.props.data.validated == true && (this.props.data.embed.title == "" || this.props.data.embed.description == "")) {
			//     this.updateData("validated", false)
			// }
		}
	}

	nameChange = (value) => {
		value = value.toLowerCase();
		value = value.substring(0, 32);
		value = value.replace(" ", "-");
		const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/gu);
		value = value.replace(regexForNonAlphaNum, "");
		this.updateData("name", value);
	};

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	// updateType = (type) => {
	//         var buttons = [...this.props.data.buttons];
	//         buttons[index].style = type;
	//         this.props.update("buttons", buttons)
	//     }

	renderCompoentOrdering = () => {
		if (this.props.data.orderComponent) {
			// Row  Input
			return (
				<>
					<div>
						<div>
							<h4 className="mb-2">Row</h4>
							<p>Which row to place this button on. </p>
						</div>
						<VariableTextInput
							label={"Row"}
							placeholder="1"
							slash_options={this.props.options}
							required={false}
							value={this.props.data.row || ""}
							onChange={(value) => {
								this.updateData("row", value);
							}}
						/>
					</div>

					<div className="mt-3">
						<div>
							<h4 className="mb-2">Position Weight</h4>
							<p>Which position to place this button on the row. Buttons with a higher weight will be placed first. </p>
						</div>
						<VariableTextInput
							label={"Weight"}
							placeholder="1"
							slash_options={this.props.options}
							required={false}
							value={this.props.data.weight || ""}
							onChange={(value) => {
								this.updateData("weight", value);
							}}
						/>
						<p className="text-sm text-gray-500 mt-1">Note: A weight of -1 will cause the button to be ignored.</p>
					</div>
				</>
			);
		}
	};
	render() {
		if (this.props.data != undefined) {
			return (
				<div>
					<div className="mb-15">
						<h4>Button</h4>
						<p>Run attached Actions when a user clicks this button.</p>
					</div>

					<div className="mb-15">
						<h4 style={{ marginBottom: "0px" }}>
							Button Text <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-buttons#button-text" />{" "}
						</h4>
						<div className="section-content-header mb-15">The text of this button. All options and variables can be used.</div>
						<div className="long-input">
							<label>Text</label>
							<input
								required
								type="text"
								value={this.props.data.label}
								onChange={(e) => {
									var value = e.target.value;
									// value = value.substr(0, 32);
									this.updateData("label", value);
								}}
							></input>
						</div>
					</div>

					<>
						<div className="mb-15">
							<h4 style={{ marginBottom: "0px" }}>
								Emoji Id
								<DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-buttons#button-text" />{" "}
							</h4>
							<div className="section-content-header mb-15">An optional emoji id to set for this button.</div>
							<div className="long-input">
								<label>Emoji</label>
								<input
									required
									type="text"
									value={this.props.data.emoji_id}
									onChange={(e) => {
										var value = e.target.value;
										// value = value.substr(0, 32);
										this.updateData("emoji_id", value);
									}}
								></input>
							</div>
						</div>
					</>
					<hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />
					<div className="mb-15 slash-action">
						<h4>
							Button Style <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-buttons#button-style"} />
						</h4>
						<span>The style and color of this button.</span>
						<div className="mt-15">
							<Select
								value={this.props.data.style}
								onChange={(value) => {
									this.updateData("style", value);

								}}
								type=""
								options={STYLES}
							></Select>
						</div>
					</div>
					<div className="mb-15">
						<h4 style={{ marginBottom: "0px" }}>Custom Button Style</h4>
						<div className="section-content-header mb-15">Optionally enter a custom style for the button. This will override the selected style above.</div>
						<VariableTextInput
							label="Custom Style"
							value={this.props.data.customStyle || ""}
							onChange={(value) => {
								this.updateData("customStyle", value);
							}}
							placeholder="Enter custom style (e.g. PRIMARY, SECONDARY)"
							required={false}
							slash_options={this.props.slash_options}
						/>
						<p className="text-sm text-gray-400 mt-1">
							Enter a custom style to override the selected style. Leave blank to use the selected style above.
						</p>
					</div>
					<hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />
					<div className="mb-15">
						<h4 style={{ marginBottom: "0px" }}>Disable Button by Default</h4>
						<div className="section-content-header mb-15">Set to 'true' to disable this button by default.</div>
						<VariableTextInput
							label="Disabled"
							value={this.props.data.disabled || ""}
							onChange={(value) => {
								this.updateData("disabled", value);
							}}
							placeholder="true or false"
							required={false}
							slash_options={this.props.slash_options}
						/>
						<p className="text-sm text-gray-400 mt-1">
							Enter 'true' to disable this button by default, or 'false' to keep it enabled.
						</p>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Show Button Replies <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-buttons#button-replies"} />
						</h4>
						<span>Choose whether to show or hide the button replies.</span>
						<div className="mt-15">
							<Select
								value={this.props.data.showReplies || "show"}
								onChange={(value) => {
									this.updateData("showReplies", value);
								}}
								type=""
								options={[
									{ value: "show", label: "Show Replies" },
									{ value: "hide", label: "Hide Replies" }
								]}
							></Select>
						</div>
					</div>
					<hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />
					{this.props.data.style == "LINK" ? (
						<div className="mb-15">
							<h4 style={{ marginBottom: "0px" }}>Button URL </h4>
							<div className="section-content-header mb-15">The URL that you will go to when you click the button. It needs to be a valid URL.</div>
							<div className="long-input">
								<label>URL</label>
								<input
									required
									type="text"
									value={this.props.data.URL ? this.props.data.URL : ""}
									onChange={(e) => {
										var value = e.target.value;
										this.updateData("URL", value);
									}}
								></input>
							</div>
						</div>
					) : null}






					{this.props.data.style != "LINK" ? (
						<>
							{this.props.data.disableOnUse == true ? (
								<>
									<hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />

									<div className="mb-15 slash-action">
										<h4>
											Clicks Required <DocsLink location={"https://docs.botghost.com/custom-commands/actions-options-conditions"} />
										</h4>
										<span>Disable the button after being clicked by this many users.</span>
										<div className="long-input mt-15">
											<label>Clicks</label>
											<input
												required
												type="number"
												value={this.props.data.disableAfter}
												onChange={(e) => {
													var value = e.target.value;
													this.updateData("disableAfter", value);
												}}
											></input>
										</div>
									</div>

									<div className="mb-15 slash-action">
										<h4>
											Disabled Text <DocsLink location={"https://docs.botghost.com/custom-commands/actions-options-conditions"} />
										</h4>
										<span>The text of the button after it has been disabled.</span>
										<div className="long-input mt-15">
											<label>Text</label>
											<input
												required
												type="text"
												value={this.props.data.disableText}
												onChange={(e) => {
													var value = e.target.value;
													// value = value.substr(0, 32);
													this.updateData("disableText", value);
												}}
											></input>
										</div>
									</div>

									<div className="mb-15 slash-action">
										<h4>
											Disabled Style <DocsLink location={"https://docs.botghost.com/custom-commands/actions-options-conditions"} />
										</h4>
										<span>The style of the button after it has been disabled.</span>
										<div className="mt-15">
											<Select
												value={this.props.data.disableStyle}
												onChange={(value) => {
													this.updateData("disableStyle", value);
												}}
												type=""
												options={[
													{ value: "PRIMARY", label: "Blue" },
													{ value: "SECONDARY", label: "Gray" },
													{ value: "SUCCESS", label: "Green" },
													{ value: "DANGER", label: "Red" }
												]}
											></Select>
										</div>
									</div>
								</>
							) : null}
						</>
					) : null}

					{this.props.data.cooldownEnabled == true && this.props.data.style != "LINK" ? (
						<>
							<hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />

							<div className="mb-15 slash-action">
								<h4>
									Cooldown Type <DocsLink location={"https://docs.botghost.com/custom-commands/actions-options-conditions"} />
								</h4>
								<span>Whether this button's cooldown should be a global or user specific cooldown.</span>
								<div className="mt-15">
									<Select
										value={this.props.data.cooldownType}
										onChange={(value) => {
											this.updateData("cooldownType", value);
										}}
										type=""
										options={[
											{ value: "", label: "" },
											{ value: "global", label: "Global" },
											{ value: "user", label: "User" }
										]}
									></Select>
								</div>
							</div>

							<div className="mb-15 slash-action">
								<h4>
									Cooldown Duration (Seconds) <DocsLink location={"https://docs.botghost.com/custom-commands/actions-options-conditions"} />
								</h4>
								<span>The cooldown duration of this button in seconds.</span>
								<div className="long-input mt-15">
									<label>Seconds</label>
									<input
										required
										type="number"
										value={this.props.data.cooldownDuration}
										onChange={(e) => {
											this.updateData("cooldownDuration", e.target.value);
										}}
									></input>
								</div>
							</div>
						</>
					) : null}

					{/* Ordering. */}
					<div className=" flex flex-row items-center gap-x-3">
						<div>
							<h4 className="mb-2">Enable Component Ordering</h4>
							<p>Order this button amongst other message components</p>
						</div>
						<Toggle
							value={this.props.data.orderComponent}
							update={(value) => {
								this.updateData("orderComponent", value);
							}}
						/>
					</div>

					{this.renderCompoentOrdering()}
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	beta: state.data.beta
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvButton);
