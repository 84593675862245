import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";

import Dashboard from "../Dashboard";

import { setBotModule } from "../../../actions";
import PremiumBadge from "../elements/PremiumBadge";
import { DocsLink } from "../elements/DocsLink";
import { ChromePicker } from "react-color";
import PremiumSection from "../elements/PremiumSection";
import Select from "../elements/Select";
import RefreshBotData from "../elements/RefreshBotData";
import Command from "../elements/Command";

var EVENTS = [
  "messageDelete",
  "messageUpdate",
  "messagePinned",
  "guildUpdate",
  "guildMemberJoin",
  "guildMemberRemove",
  "guildMemberUpdate",
  "guildEventCreate",
  "guildEventDelete",
  "guildEventUpdate",
  "memberRoleAdd",
  "memberRoleRemove",
  "memberNicknameUpdate",
  "userAvatarUpdate",
  "userUsernameUpdate",
  "userDiscriminatorUpdate",
  "channelCreate",
  "channelRemove",
  "channelUpdate",
  "roleCreate",
  "roleDelete",
  "roleUpdate",
  "emojiCreate",
  "emojiDelete",
  "emojiUpdate",
  "threadCreate",
  "threadDelete",
  "threadUpdate",
  "vcJoin",
  "vcLeave",
  "vcSwitch",
  "vcMute",
  "vcUnMute",
  "vcDeafen",
  "vcUnDeafen",
  "guildBoostLevelUp",
  "guildBoostLevelDown",
  "guildPartnerAdd",
  "guildPartnerRemove",
  "guildMemberBoost",
  "guildMemberUnBoost",
  "guildBanAdd",
];

export class Crypto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      online: false,
      openColorPicker: "",
      channel: { id: "", name: "" },
      enabled: false,
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    if (typeof window.ethereum !== 'undefined') {
      
    }
  }
  
  // metamaskTest = async () =>{
  //   if (typeof window.ethereum !== 'undefined') {
  //     var test = await window.ethereum.request({ method: 'eth_requestAccounts' });
  //     console.log(test);
  //   }
  // }

  render() {

      return (
        <Dashboard>
          <ModuleHeader
            tutorial="https://docs.botghost.com/crypto-and-nfts/crypto"
            showEnabled={true}
            title="Crypto"
            docs="https://docs.botghost.com/crypto-and-nfts/crypto"
            description="Get coin information and prices"
            icon="bitcoin.png"
            enabledValue={this.props.crypto.enabled}
            change={(value) =>{{
                var crypto = { ...this.props.crypto };
                crypto.enabled = value;
                this.props.setBotModule({
                  module: "crypto",
                  module_data: crypto,
                });
            }}}
          ></ModuleHeader>

          <section>
            {/* <button onClick={() =>{
                this.metamaskTest();
            }}>TEST</button> */}
              <SectionHeader title="Commands" pretitle="Crypto" />
              <Command
              update={(value) =>{
                var crypto = { ...this.props.crypto };
                crypto.price = value;
                this.props.setBotModule({
                  module: "crypto",
                  module_data: crypto,
                });
              }}
              options={this.props.crypto.price}
              module="crypto"
              name={"price"}
              description={
                "Get market info for a coin or coin pair."
              }
              showToggle={true}
              toggleValue={this.props.crypto.price.enabled}
              showPrefix={true}
              slashOnly={true}

              slash={true}
            ></Command>
          </section>

        </Dashboard>
      );
    }
}

const mapStateToProps = (state) => ({
    crypto: state.data.bot.commands.crypto,
  premium: state.data.premium,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Crypto);
