import React, { Component } from 'react';
import { connect } from 'react-redux';
import RefreshBotData from '../elements/RefreshBotData';
import Select from '../elements/Select';
import Toggle from '../elements/Toggle';
import InputTitle from './InputTitle';

export class ChannelSelect extends Component {
    renderDescription = () => {
        if (this.props.settings.html) {
            return <div dangerouslySetInnerHTML={{ __html: this.props.settings.description }}></div>;
        } else {
            return <p>{this.props.settings.description}</p>;
        }
    };

    render() {
        return (
            <>
                <div className={`${this.props.settings.premium && !this.props.premium ? "opacity-75 pointer-events-none" : ""} ${!this.props.slot ? "section-content-normal" : "mb-15"}`}>
                    <div class="">
                        <InputTitle settings={this.props.settings} refresh={true} />
                        <div className="section-content-header mb-2">
                            {this.renderDescription()}
                        </div>
                        <div>
                            <Select type="channel" channelFilter={this.props.settings.channel_filter ? this.props.settings.channel_filter : undefined} value={JSON.stringify(this.props.value)} onChange={(value) => {
                                this.props.change(JSON.parse(value));

                            }} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelSelect);