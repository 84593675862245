import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";

import Dashboard from "../Dashboard";

import { setBotModule, setBotData } from "../../../actions";
import PremiumBadge from "../elements/PremiumBadge";
import { DocsLink } from "../elements/DocsLink";
import { ChromePicker } from "react-color";
import PremiumSection from "../elements/PremiumSection";
import Select from "../elements/Select";
import RefreshBotData from "../elements/RefreshBotData";
import server from "../../../api/server";

var EVENTS = [
  "messageDelete",
  "messageUpdate",
  "messagePinned",
  "guildUpdate",
  "guildMemberJoin",
  "guildMemberRemove",
  "guildMemberUpdate",
  "guildEventCreate",
  "guildEventDelete",
  "guildEventUpdate",
  "memberRoleAdd",
  "memberRoleRemove",
  "memberNicknameUpdate",
  "userAvatarUpdate",
  "userUsernameUpdate",
  "userDiscriminatorUpdate",
  "channelCreate",
  "channelRemove",
  "channelUpdate",
  "roleCreate",
  "roleDelete",
  "roleUpdate",
  "emojiCreate",
  "emojiDelete",
  "emojiUpdate",
  "threadCreate",
  "threadDelete",
  "threadUpdate",
  "vcJoin",
  "vcLeave",
  "vcSwitch",
  "vcMute",
  "vcUnMute",
  "vcDeafen",
  "vcUnDeafen",
  "guildBoostLevelUp",
  "guildBoostLevelDown",
  "guildPartnerAdd",
  "guildPartnerRemove",
  "guildMemberBoost",
  "guildMemberUnBoost",
  "guildBanAdd",
];

export class Logging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      online: false,
      openColorPicker: "",
      channel: { id: "", name: "" },
      enabled: false,
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.handleClick();
  }


  handleClick = async () => {

    this.setState({ loading: true });
    const response = await server.post("/dashboard/botData", {
      id: this.props.bot.id,
      token: this.props.bot.token
    }).catch((e) => {
      this.setState({ loading: false });
    });

    if (response.data && response.data.success == true) {
      const bot_data = response.data.bot_data;
      this.props.setBotData(bot_data);
      this.setState({ loading: false });
    }


  };

  toggleChange = (value, type) => {
    var Logging = { ...this.props.Logging };

    Logging[type].enabled = value;
    this.props.setBotModule({
      module: "settings",
      module_data: Logging,
    });
  };

  textChange = (value) => {
    var Logging = { ...this.props.Logging };
    Logging.errorlogs.channel_name = value;
    this.props.setBotModule({
      module: "settings",
      module_data: Logging,
    });
  };

  enableModule = (value) => { };

  renderChannels = () => {
    var events = [];

    EVENTS.forEach((event) => {
      // console.log(this.props.logging[event].enabled, "ENABVLED");
      events.push(
        <div className="announcement ">
          <div className="announcement-header">
            <div>
              <h3 className="mb-0">
                {event} <RefreshBotData />
              </h3>
              <div className="section-content-header">
                Enable and select the logging channel for the {event} event
              </div>
            </div>
            <div style={{ marginLeft: "auto" }}>
              {this.props.premium == true ? (
                <Toggle
                  disabled={this.props.premium == false}
                  value={this.props.logging[event].enabled}
                  update={(value) => {
                    var logging = { ...this.props.logging };
                    logging[event].enabled = value;
                    this.props.setBotModule({
                      module: "logging",
                      module_data: logging,
                    });
                    //  this.enableChannel(event,value)
                  }}
                />
              ) : null}
            </div>
          </div>

          {this.props.premium && this.props.logging[event].enabled == true ? (
            <div className="announcement-content">
              <Select
                disabled={this.props.premium != true}
                value={JSON.stringify({
                  id: this.props.logging[event].channel,
                  name: this.props.logging[event].channel_name,
                  type: 0
                })}
                onChange={(value) => {
                  var channel = JSON.parse(value);
                  var logging = { ...this.props.logging };
                  logging[event].channel = channel.id;
                  logging[event].channel_name = channel.name;

                  this.props.setBotModule({
                    module: "logging",
                    module_data: logging,
                  });
                }}
                type="channel"
              />
            </div>
          ) : null}
        </div>
      );
    });
    return events;
  };

  onColorChange = (color, key) => {
    const Logging = { ...this.props.Logging };

    Logging.embeds.colors[key] = color.hex;

    this.props.setBotModule({
      module: "settings",
      module_data: Logging,
    });
  };

  renderColorPicker = (colorPickerKey, color) => {
    if (this.state.openColorPicker == colorPickerKey) {
      return (
        <div
          className=""
          style={{ zIndex: "2", position: "absolute", right: "150px" }}
        >
          <ChromePicker
            color={color}
            onChangeComplete={(color) => {
              this.onColorChange(color, colorPickerKey);
            }}
          ></ChromePicker>
        </div>
      );
    } else {
      return null;
    }

  };


  populateDefault = () => {
    if (!this.props.logging.default?.name) {
      return JSON.stringify(this.state.channel);
    }
    return JSON.stringify(this.props.logging.default);
  }

  render() {
    // console.log(this.populateDefault())
    if (this.props.logging == undefined) {
      return null;
    } else {
      return (
        <Dashboard>
          <ModuleHeader
            tutorial="https://docs.botghost.com/server-management/logging"
            showEnabled={false}
            title="Logging"
            docs="https://docs.botghost.com/server-management/logging"
            description="Get detailed information and log all server events"
            icon="logs.png"
          ></ModuleHeader>

          <PremiumSection>
            <SectionHeader
              title="Update All Events"
              pretitle="Logging"
            ></SectionHeader>

            <div className="section-content-normal">
              <div className="mb-15">
                <h3 style={{ marginBottom: "0px" }}>
                  Update logging channel for all events <RefreshBotData />
                </h3>
                <div class="section-content-header">
                  Set the logging channel for all events
                </div>
              </div>


              <Select
                disabled={this.props.premium != true}
                value={this.populateDefault()}
                onChange={(value) => {

                  var channel = JSON.parse(value);
                  this.setState({ channel });
                  var logging = { ...this.props.logging };
                  EVENTS.forEach((event) => {
                    logging[event].channel = channel.id;
                    logging[event].channel_name = channel.name;
                  });

                  logging.default = channel

                  this.props.setBotModule({
                    module: "logging",
                    module_data: logging,
                  });
                }}
                type="channel"
              />
            </div>

            <div className="section-content-normal justify-space-between m-section-content">
              <div>
                <h3 style={{ marginBottom: "0px" }}>
                  Enable or Disable all events
                </h3>
                <div class="section-content-header">
                  Enable or disable all logging events below
                </div>
              </div>

              <div className="m-buttons m-col-reverse">
                <button
                  className="btn btn-red"
                  disabled={this.props.premium != true}
                  style={{ marginRight: "15px" }}
                  onClick={() => {
                    var logging = { ...this.props.logging };
                    EVENTS.forEach((event) => {
                      logging[event].enabled = false;
                    });

                    this.props.setBotModule({
                      module: "logging",
                      module_data: logging,
                    });
                  }}
                >
                  Disable All
                </button>
                <button
                  className="btn btn-red"
                  disabled={this.props.premium != true}
                  onClick={() => {
                    var logging = { ...this.props.logging };
                    EVENTS.forEach((event) => {
                      logging[event].enabled = true;
                    });

                    this.props.setBotModule({
                      module: "logging",
                      module_data: logging,
                    });
                  }}
                >
                  Enable All
                </button>
              </div>
            </div>
          </PremiumSection>
          <PremiumSection>
            <SectionHeader title="Events" pretitle="Logging"></SectionHeader>

            <div className="commands">{this.renderChannels()}</div>
          </PremiumSection>
        </Dashboard>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  logging: state.data.bot.commands.logging,
  premium: state.data.premium,
  bot: state.data.bot
});

const mapDispatchToProps = {
  setBotModule,
  setBotData
};

export default connect(mapStateToProps, mapDispatchToProps)(Logging);
