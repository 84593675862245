import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setElements } from '../../../../actions';
import getLayoutedElements from "../getLayoutElements";
import Embed from '../../elements/Embed';
import TextArea from '../../elements/TextArea';
import Select from '../../elements/Select';
import RefreshBotData from '../../elements/RefreshBotData';
import { addEdge, getOutgoers } from 'react-flow-renderer';
import { EmojiAdder } from './EmojiAdder';
import RoleAdd from "../../elements/RoleAdd";
import splitElements from '../splitElements';
import VariableTextInput from '../VariableTextInput';

const responseTypes = [
    { value: "reply", label: "Reply to the command" },
    { value: "targeted", label: "Send the message to a specific text-channel" }
];


export class OptionsCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ""
        };
    }

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "condition",
                        condition_type: "option",
                        option: "",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "condition",
                        condition_type: "option",
                        option: "",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE
            if (this.props.data.option != "") {
                if (this.props.data.validated == false) {
                    this.updateData("validated", true);
                }

            } else if (this.props.data.validated == true) {
                this.updateData("validated", false);

            }
        }
    }


    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };


    renderOptions = () => {
        var options = [
            { value: "", label: "" }
        ];
        this.props.options.forEach(option => {
            options.push({ value: option.name, label: option.name });
        });
        return options;
    };

    selectUpdate = (value) => {
        value = JSON.parse(value);
        var response_options = { ...this.props.data.response_options };
        response_options.target = value;
        this.updateData("response_options", response_options);
    };

    addCondition = () => {
        var elements = [...this.props.elements];
        var element = this.props.elements.find(element => element.id == this.props.selected);
        var existingConditions = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);


        var id = `conChild${elements.length + 1}_${Math.random().toString(36).substr(2, 9)}`;
        var lastChild = existingConditions[existingConditions.length - 1];

        var docuElement = document.getElementById(lastChild.id);
        var width = 0;
        if (docuElement) {
            width = docuElement.clientWidth;
        }
        var value = this.state.value;
        var name = "";

        if (typeof this.state.value == "object" && 'id' in this.state.value) {
            value = this.state.value.id;
            name = this.state.value.name;
        }


        elements.push(
            {
                id: id,
                type: 'conditionChild',
                data: {
                    node_options: {
                        type: "option"
                    },
                    data: {
                        value: value,
                        name: name,
                        actions: [],
                        option: this.props.data.option
                    }

                },
                // target:"3",
                draggable: true,
                position: { x: lastChild.position.x + width + 20, y: lastChild.position.y },
            },
        );
        var newEdges = addEdge({
            source: element.id,
            target: id,
            type: "step",
            animated: false,
            arrowHeadType: "arrowclosed"

        }, splitElements(elements).edges);
        newEdges.forEach(newEdge => {
            var index = elements.findIndex(element => element.id == newEdge.id);
            if (index == -1) {
                elements.push(newEdge);
            }
            // elements[index] = newNode;
        });
        // elements = getLayoutedElements(elements)
        this.props.setElements(elements);
        this.setState({ value: "" });



    };

    renderSelectOptions = (type) => {
        var options = [
            // {value:"trigger",label:"The member who triggered the command"}
        ];
        this.props.options.forEach(option => {
            if (option.type == type) {
                options.push({ value: { variable: option.name }, label: `Role Option: ${option.name}` });

            }

        });
        return options;
    };

    renderConditionValue = () => {
        if (this.props.data.option != "") {
            var option = this.props.options.find(option => { return option.name == this.props.data.option; });
            if (option == undefined) {

            } else if (option.type == "string" || option.type == "int") {
                return (
                    <div className="mb-15">
                        <div className="mb-15 slash-action">
                            <h4>Conditions </h4>
                            <span>Add a condition. Enter the value for the option</span>
                            {/* <VariableTextInput slash_options={this.props.options} value={this.props.data.response_options.target.user_option} onChange={(value) => {
                                var response_options = { ...this.props.data.response_options };
                                response_options.target.user_option = value;
                                this.updateData("response_options", response_options);
                            }} /> */}

                            <VariableTextInput label={"Value"} placeholder="Value" slash_options={this.props.options} value={this.state.value} onChange={(value) => {
                                this.setState({ value: value });
                            }} />
                            {/* <div class="long-input mt-15">
                                <label>Value</label>
                                <input onChange={(e) => {

                                    this.setState({ value: e.target.value });

                                }} type="text" value={this.state.value} />
                            </div> */}
                        </div>
                        <button disabled={this.state.value == "" ? true : false} onClick={() => { this.addCondition(); }} className="btn btn-red add-button">Add Condition</button>
                    </div>
                );

            } else if (option.type == "user") {
                return (
                    <div className="mb-15">
                        <div className="mb-15 slash-action">
                            <h4>Conditions </h4>
                            <span>Add a condition. Enter the user id for the option.</span>
                            <VariableTextInput label={"User ID"} placeholder="User ID" slash_options={this.props.options} value={this.state.value} onChange={(value) => {
                                this.setState({ value: value });
                            }} />

                            {/* <div class="long-input mt-15">
                                <label>User ID</label>
                                <input onChange={(e) => {

                                    this.setState({ value: e.target.value });

                                }} type="text" value={this.state.value} />
                            </div> */}
                        </div>
                        <button disabled={this.state.value == "" ? true : false} onClick={() => { this.addCondition(); }} className="btn btn-red add-button">Add Condition</button>
                    </div>
                );
            } else if (option.type == "role") {
                return (
                    <div className="mb-15">
                        <div className="mb-15 slash-action">
                            <h4>Conditions <RefreshBotData /></h4>
                            <span>Add a condition. Enter the value for the option</span>
                            <div className='mt-15'>
                                <Select value={JSON.stringify(this.state.value)} onChange={(value) => {
                                    value = JSON.parse(value);
                                    this.setState({ value: value });
                                }} type="role" ></Select>
                            </div>


                        </div>
                        <button disabled={this.state.value == "" ? true : false} onClick={() => { this.addCondition(); }} className="btn btn-red add-button">Add Condition</button>
                    </div>
                );
            } else if (option.type == "channel") {
                return (
                    <div className="mb-15">
                        <div className="mb-15 slash-action">
                            <h4>Conditions <RefreshBotData /></h4>
                            <span>Add a condition. Enter the value for the option</span>
                            <div className='mt-15'>
                                <Select value={JSON.stringify(this.state.value)} onChange={(value) => {
                                    value = JSON.parse(value);
                                    this.setState({ value: value });
                                }} type="channel" ></Select>
                            </div>

                        </div>
                        <button disabled={this.state.value == "" ? true : false} onClick={() => { this.addCondition(); }} className="btn btn-red add-button">Add Condition</button>
                    </div>
                );
            } else if (option.type == "boolean") {
                return (
                    <div className="mb-15">
                        <div className="mb-15 slash-action">
                            <h4>Conditions <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/option-condition#conditions" /> </h4>
                            <span>Add a condition. Enter the value for the option</span>
                            <div className='mt-15'>
                                <Select options={[
                                    { label: "", value: "" },
                                    { label: "True", value: "true" },
                                    { label: "False", value: "false" },
                                ]} value={this.state.value} onChange={(value) => {
                                    this.setState({ value: value });
                                }} ></Select>
                            </div>


                        </div>
                        <button disabled={this.state.value == "" ? true : false} onClick={() => { this.addCondition(); }} className="btn btn-red add-button">Add Condition</button>
                    </div>
                );
            }
        }
    };

    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Options Condition</h4>
                        <p>Run certain actions based on an options value. </p>
                    </div>


                    <div className="mt-15 mb-15 slash-action">
                        <h4 style={{ marginBottom: "0px" }}>Option <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/option-condition#option" /></h4>
                        <div className="section-content-header mb-15">Which option to base this condition on.</div>
                        {this.props.options.length == 0 ? <div className="section-content-header mb-15" style={{ color: "#f45142", fontWeight: "800" }}>Please first add an option to your command.</div> : null}

                        <Select saveFailed={this.props.saveFailed && this.props.data.option == ""} value={this.props.data.option} onChange={(value) => {

                            var elements = [...this.props.elements];
                            var element = this.props.elements.find(element => element.id == this.props.selected);
                            var existingConditions = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
                            existingConditions.forEach(conditionChild => {
                                conditionChild.data.data.option = value;
                            });

                            this.props.setElements(elements);
                            this.updateData("option", value);
                        }} options={this.renderOptions()} />
                    </div>


                    {this.renderConditionValue()}

                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
    updateElementData,
    setElements
};

let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionsCondition);
