import React, { Component } from 'react';
import { connect } from 'react-redux';
import VariableEditor from '../elements/VariableEditor';


export class VariableTextInput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showVariableEditor: false
        };
    }

    variableEditorClick = (variable, slash) => {
        // console.log("Var editor click");
        var value = this.props.value;
        if (value != undefined) {
            if (slash != true) {
                // value = value.slice(0,this.state.position) + " "+variable.variable + value.slice(this.state.position);
                if (!value) value = value + variable.variable;
                else value = value + " " + variable.variable;
            } else {
                // value = value.slice(0,this.state.position) + " "+`{option_${variable.name}}` + value.slice(this.state.position);
                if (!value) value = value + `{option_${variable.name}}`;
                else value = value + " " + `{option_${variable.name}}`;
            }
            this.props.onChange(value);
            this.setState({ showVariableEditor: false });
        }
    };
    // this.props.saveFailed && this.props.data.target.variable == "" ? "command-required" : ""
    render() {
        return (
            <div className='relative'>
                <div className="long-input mt-15">

                    <label>{this.props.label}</label>
                    <input placeholder={this.props.placeholder} className={`${this.props.saveFailed && this.props.required != false ? "command-required" : ""} !pr-[40px]`} required type="text" value={this.props.value} onChange={(e) => {
                        this.props.onChange(e.target.value);

                    }}></input>
                    <div className={`emoji-container ${this.state.showVariableEditor == false ? "hithere" : null}`}>
                        <i class={`bi bi-clipboard ${this.state.showVariableEditor == true ? "icon-red" : null}`} style={{ background: "none" }} onClick={(e) => { this.setState({ showVariableEditor: !this.state.showVariableEditor }); }}></i>
                    </div>
                </div>
                <div className='!top-[50px]'>
                    {this.state.showVariableEditor == true ? <VariableEditor top="50px" slashcommand={true} slashcommandVariables={this.props.slash_options} announcement={false} click={this.variableEditorClick} /> : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    saveFailed: state.builder.saveFailed,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VariableTextInput);