import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../../../api/server';
import SectionHeader from '../elements/SectionHeader';
import moment from 'moment';
import CustomVariable from './CustomVariable';
import { setBotSettings, setBotModule } from "../../../actions";
import RippleLoader from "../elements/RippleLoader";
export class VariablePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            variable: {

            },
            entries: [],
            loading: true
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        this.setState({ loading: true });
        var response = await server.post("/datastorage/variable", {
            reference: this.props.variable.reference,
            bot_id: this.props.bot_id,
            user_id: this.props.user.id,
            object_var: this.props.variable.type == "object" || this.props.variable.dataType == "object" ? true : false,
        });
        if (response && response.data && response.data.success) {
            this.setState({
                entries: response.data.entries,
                loading: false
            });
        } else {
            this.setState({ loading: false });
        }
    };

    renderEntries = () => {
        var entries = [];
        this.state.entries.forEach((entry, index) => {
            var rows = [];
            if (this.props.variable.type == "object") {
                rows.push(<td>{entry.reference}</td>);
            }
            if (entry.type == "array" || entry.dataType == "array") {
                rows.push(<td>{JSON.stringify(entry.value.value)}</td>);

            } else {

                rows.push(<td>{entry.value.value}</td>);
            }


            if (this.props.variable.type == "user") {
                rows.push(<td>{entry.user_val}</td>);
            }

            if (!this.props.variable.global) {
                rows.push(<td>{entry.guild_id}</td>);
            }

            rows.push(<td>{moment(entry.updated).fromNow()}</td>);

            entries.push(
                <tr>
                    {rows}
                </tr>
            );
        });
        return entries;
    };
    render() {
        return (
            <>
                <section>
                    <div style={{
                        display: 'flex'
                    }}>
                        <div><SectionHeader title={`Most Recent ${this.props.variable.name} Update`} pretitle="Data Storage" /></div>
                        <div className='ml-auto flex gap-3 items-center'>
                            <button style={{ height: "45px" }} className='btn btn-red' onClick={() => {
                                this.getData();
                            }}>Refresh</button>
                            <button style={{ height: "45px" }} className='btn btn-red' onClick={() => {
                                this.props.back();
                            }}>Go back</button>
                        </div>
                    </div>


                    {this.state.loading ? <RippleLoader /> : <div>
                        {this.state.entries.length > 0 ?
                            <table class="table error-logs-table">
                                <thead>
                                    <tr>
                                        {this.props.variable.type == "object" ? <th scope="col">Key</th> : null}
                                        <th scope="col">Value</th>
                                        {this.props.variable.type == "user" ? <th scope="col" style={{ width: "25%" }}>User ID</th> : null}
                                        {!this.props.variable.global ? <th scope="col" style={{ width: "25%" }}>Guild ID</th> : null}
                                        <th scope="col ">Last Updated</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderEntries()}
                                </tbody>
                            </table> : <div style={{ color: "#fff", opacity: "0.7" }}>This variable has no entries in data storage yet. Use one of the custom variable actions to set a value for this variable.</div>}
                    </div>}
                </section>

                <section>
                    <SectionHeader title={`Edit Variable`} pretitle="Data Storage" />
                    <div className='section-content-normal'>
                        <CustomVariable variable={this.props.variable} index={this.props.index} editVariable={(variable, index) => {

                            var variables = { ...this.props.variables };
                            variables.variables[this.props.index] = variable;
                            // console.log(variables, 'VARIABLES');
                            this.props.setBotModule({
                                module: "variables",
                                module_data: variables
                            });
                            this.setState({
                                variable: variable
                            });

                        }} delete={(index) => {
                            var variables = { ...this.props.variables };
                            variables.variables.splice(index, 1);
                            this.props.setBotModule({
                                module: "variables",
                                module_data: variables
                            });
                            this.props.back();
                        }} />

                    </div>
                </section>

            </>
        );
    }
}

const mapStateToProps = (state) => ({
    bot_id: state.data.bot.id,
    user: state.data.user,
    variables: state.data.bot.commands.variables,
});

const mapDispatchToProps = {
    setBotModule
};

export default connect(mapStateToProps, mapDispatchToProps)(VariablePage);