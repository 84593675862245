import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModuleHeader from '../elements/ModuleHeader'
import SectionHeader from '../elements/SectionHeader'
import Command from '../elements/Command'

import { setBotModule } from "../../../actions"

import Dashboard from "../Dashboard"
import PremiumRedirect from '../elements/PremiumRedirect'
import PremiumSection from '../elements/PremiumSection'
import Select from '../elements/Select'

export class Giveaways extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tryModal: true
        }
    }

    moduleEnable = (value) => {
        var giveaways = { ...this.props.giveaways };

        giveaways.enabled = value;
        this.props.setBotModule({
            module: "giveaways",
            module_data: giveaways
        })

    }

    keyChange = (value) => {
        var giveaways = { ...this.props.giveaways };

        giveaways.enabled = value;
        this.props.setBotModule({
            module: "giveaways",
            module_data: giveaways
        })
    }

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    render() {
        return (
            <Dashboard>
                <ModuleHeader tutorial="https://docs.botghost.com/server-management/giveaway-module" premiumRequired={true} enabledValue={this.props.giveaways.enabled} change={this.moduleEnable} description="Run giveaways in your servers" title="Giveaways" icon="giveaways.png"></ModuleHeader>

                <PremiumSection>
                <SectionHeader title="Settings" pretitle="Giveaways " ></SectionHeader>

                    <div className="section-content-normal">
                        <div className="mb-15">
                            <h3 style={{ marginBottom: "0px" }}>Giveaway Button Text</h3>
                            <div className="section-content-header mb-15">
                                Customize the join giveaway button text.
                            </div>

                            <div className="long-input">
                                <label>Text</label>
                                <input
                                    disabled={!this.props.premium}
                                    type="text"
                                    value={this.props.giveaways.buttonText}
                                    onChange={(e) => {
                                        var giveaways = { ...this.props.giveaways };

                                        giveaways.buttonText = e.target.value;
                                        this.props.setBotModule({
                                            module: "giveaways",
                                            module_data: giveaways
                                        })
                                    }}
                                ></input>
                            </div>
                        </div>
                    </div>

                    <div className="section-content-normal">
                        <div className="mb-15">
                            <h3 style={{ marginBottom: "0px" }}>Participants Location</h3>
                            <div className="section-content-header mb-15">
                                Where to display the participants of the giveaway.
                            </div>

                                <Select onChange={(value) =>{
                                    var giveaways = { ...this.props.giveaways };
                                    if(value == "true"){
                                        value = true;
                                    }else{
                                        value = false;
                                    }
                                    giveaways.buttons = value;
                                    this.props.setBotModule({
                                        module: "giveaways",
                                        module_data: giveaways
                                    })
                                
                                }} value={this.props.giveaways.buttons} options = {[
                                    {label:"In a Button",value:true},
                                    {label:"In the message",value:false},
                                ]}>

                                </Select>
                        </div>
                    </div>
                </PremiumSection>
                <PremiumSection>
                    <SectionHeader title="Commands" pretitle="Giveaways " ></SectionHeader>
                    <div className="row">
                        <div className="col-xl-6">
                            <Command
                                name="giveaway"
                                description="Start the giveaway system"
                                options={this.props.giveaways.giveaway}
                                showToggle={false}
                                slashOnly={true}
                                module="giveaways"
                                showPrefix={true}
                            ></Command>
                        </div>

                    </div>
                </PremiumSection>


            </Dashboard>
        )
    }
}

const mapStateToProps = (state) => ({
    giveaways: state.data.bot.commands.giveaways,
    premium: state.data.premium,
    beta: state.data.beta
})

const mapDispatchToProps = {
    setBotModule
}

export default connect(mapStateToProps, mapDispatchToProps)(Giveaways)
