import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";

import Dashboard from "../Dashboard";

import { setBotModule } from "../../../actions";

const commands = [
  // {
  //     name:"help",
  //     description:"Displays all the commands of the server",
  //     slash:true
  // },
  {
    name: "ban",
    description: "Bans a user from the Server",
    slash: true,
  },
  {
    name: "mute",
    description: "Mutes a user in the server",
    slash: true,
  },
  {
    name: "unmute",
    description: "Unmutes a user in the server",
    slash: true,
  },
  // {
  //     name:"afk",
  //     description:"Sets an AFK message for a user"
  // },
  {
    name: "warn",
    description: "Warns a user",
    slash: true,
  },
  {
    name: "warnings",
    description: "Displays all the past warnings of a user",
    slash: true,
  },
  {
    name: "clearwarnings",
    description: "Clears all the warnings of a user",
    slash: true,
  },
  {
    name: "report",
    description:
      "Allows users to report an another user, the reports will be stored in a channel named 'reports'",
    slash: true,
  },
  {
    name: "invite",
    description:
      "Generates the invite link for the bot and posts it in the server",
    slash: true,
  },
  {
    name: "kick",
    description: "Kicks a user from the server",
    slash: true,
  },
  {
    name: "say",
    description: "Broadcasts a message in the channel",
    slash: true,
  },
  {
    name: "addrole",
    description: "Adds a role to a user",
    slash: true,
  },
  {
    name: "removerole",
    description: "Removes a role from a user",
    slash: true,
  },
  {
    name: "softban",
    description:
      "Soft bans a user from the server, removes all their messages from chat and kicks them",
    slash: true,
  },
  {
    name: "purge",
    description: "Deletes a number of messages in a channel",
    slash: true,
  },
  {
    name: "ping",
    description: "Gets the ping of the server and the bot",
    slash: true,
  },
  {
    name: "timeout",
    description: "Gives a user a time-out",
    slash: true,
  },
  {
    name: "removetimeout",
    description: "Removes a time-out from a user",
    slash: true,
  },
  {
    name: "avatar",
    description: "Get a user's avatar",

    slash: true,
  },
  {
    name: "deafen",
    description: "Deafens a user",

    slash: true,
  },
  {
    name: "undeafen",
    description: "Undeafens a user",

    slash: true,
  },
  {
    name: "move",
    description: "Moves a user to a voice channel",

    slash: true,
  },
  {
    name: "slowmode",
    description: "Changes a channel's slowmode",

    slash: true,
  },
  {
    name: "vkick",
    description: "Voice kicks a user",

    slash: true,
  },
  {
    name: "vmute",
    description: "Voice mutes a user",

    slash: true,
  },
  {
    name: "vunmute",
    description: "Voice unmutes a user",
    slash: true,
  },
  {
    name: "unban",
    description: "Unbans a user",
    slash: true,
  },
];

class Moderation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // console.log(this.props);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  commandUpdate = (value, type) => {
    var moderation = { ...this.props.moderation };
    moderation[type] = value;
    this.props.setBotModule({
      module: "moderation",
      module_data: moderation,
    });
  };

  updateModerationLogging = (value) => {
    // console.log(value);
    var logging = { ...this.props.logging };
    logging.mod = value;
    this.props.setBotModule({
      module: "logging",
      module_data: logging,
    });
  };

  renderCommands = () => {
    const renderedCommands = [];
    commands.forEach((command) => {
      renderedCommands.push(
        <div className="col-xl-6">
          <Command
            update={this.commandUpdate}
            options={this.props.moderation[command.name]}
            module="moderation"
            name={command.name}
            description={command.description}
            showToggle={true}
            betaOnly={command.betaOnly != undefined ? command.betaOnly : false}
            toggleValue={this.props.moderation[command.name].enabled}
            showPrefix={true}
            slashOnly={true}
            slash={command.slash}
          ></Command>
        </div>
      );
    });
    return renderedCommands;
  };
  render() {
    return (
      <Dashboard>
        <ModuleHeader
          youtube="https://www.youtube.com/watch?v=2YmdUobv9BQ"
          tutorial="https://docs.botghost.com/server-management/moderation"
          showEnabled={true}
          docs="https://docs.botghost.com/server-management/moderation"
          title="Moderation"
          description="Moderate your server with a set of powerful commands"
          icon="moderation.png"
          // enabledValue={this.props.moderation.enabled}
          // Enabled = true if there is no enabled or enabled == true
          enabledValue={this.props.moderation.enabled == false ? false : true}
          change={(value) => {
            var moderation = { ...this.props.moderation };
            moderation.enabled = value;
            this.props.setBotModule({
              module: "moderation",
              module_data: moderation,
            });


            
            // Disable custom module version
            if (this.props.moduleSettings && this.props.moduleSettings["ce84a76f-f0cc-42ce-8ebb-3790db06e943"]) {
              var settings = { ...this.props.moduleSettings };
              settings["ce84a76f-f0cc-42ce-8ebb-3790db06e943"].enabled = !value;
              this.props.setBotModule({
                module: "moduleSettings",
                module_data: settings,
              });
            }
          }}
        ></ModuleHeader>

        <section>
          <SectionHeader
            title="Logging"
            pretitle="Moderation"
            docs={true}
            docs_link={
              "https://docs.botghost.com/server-management/moderation"
            }
          ></SectionHeader>

          <div className="section-content">
            <div>
              <h3>Moderation Logging</h3>
              <div className="section-content-header">
                All moderation command usage will be logged to a channel named
                mod-logs
              </div>
            </div>

            <div style={{ marginLeft: "auto" }}>
              <Toggle
                value={this.props.logging.mod}
                update={this.updateModerationLogging}
              ></Toggle>
            </div>
          </div>
        </section>

        <section>
          <SectionHeader
            title="Commands"
            pretitle="Moderation"
            docs={true}
            docs_link={"https://docs.botghost.com/server-management/moderation"}
          ></SectionHeader>

          <div className="commands row">{this.renderCommands()}</div>
        </section>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  moderation: state.data.bot.commands.moderation,
  logging: state.data.bot.commands.logging,
});

export default connect(mapStateToProps, { setBotModule })(Moderation);
