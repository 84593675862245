import React, { Component } from 'react'
import { connect } from 'react-redux'


import SectionHeader from "../elements/SectionHeader"
import ModuleHeader from "../elements/ModuleHeader"
import Toggle from "../elements/Toggle"
import Command from "../elements/Command"
import { TextArea } from '../elements/TextArea'
import { Select } from '../elements/Select'
import  YouTubeEditor  from '../elements/YouTubeEditor'

import Dashboard from "../Dashboard"

import {setBotModule} from "../../../actions"
import  PremiumRedirect  from '../elements/PremiumRedirect'
import  PremiumSection  from '../elements/PremiumSection'

export class YouTube extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             showEdit:null
        }
    }

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    commandAdd = (value,index) =>{
        const youtube = {...this.props.youtube};
        if(index == undefined){
            youtube.tracking.push(value)
        }else{
            youtube.tracking[index] = value;
        }

        this.props.setBotModule({
            module:"youtube",
            module_data:youtube
        })

        this.setState({showEdit:null})
    }

    renderYouTubeChannels = () =>{
        var channels = [];
        this.props.youtube.tracking.forEach((channel,index) =>{
            channels.push(
                <div className="announcement">
                    <div className="announcement-header" onClick={(e) =>{this.setState({showEdit:index})}}>
                        <div>
                            <h3 className="mb-0">{channel.name}</h3>
                            <div className="section-content-header">{`Posting an announcement in ${channel.channel}`} </div>
                        </div>
                        <div style={{marginLeft:"auto"}}>
                            {this.state.showEdit == index ? <i style={{fontSize:"20px"}} class="bi bi-caret-up-fill"></i>: <i style={{fontSize:"20px"}} class="bi bi-caret-down-fill"></i>}
                        </div>
                    </div>

                    <div>
                        {this.state.showEdit == index ?
                            <YouTubeEditor
                            saveText="Save"
                            save={this.commandAdd}
                            index={index}
                            value={channel}
                            showDelete={true}
                            delete={this.delete}
                            />
                        :null
                        }
                    </div>


                
            </div>
            )
        })
        return channels
    }

    moduleEnable = (value) => {
        const youtube = { ...this.props.youtube };
        youtube.enabled = value;

        this.props.setBotModule({
            module: "youtube",
            module_data: youtube
        })

    }

    delete = (index) =>{
        const youtube = { ...this.props.youtube };

        youtube.tracking.splice(index,1)

        this.props.setBotModule({
            module: "youtube",
            module_data: youtube
        })

        this.setState({showEdit:null})
    }
    
    render() {
        return (
            <Dashboard>
                <PremiumRedirect></PremiumRedirect>
                <ModuleHeader tutorial="https://docs.botghost.com/social-integrations/youtube" enabledValue={this.props.youtube.enabled} change={this.moduleEnable} premiumRequired={true} docs="https://docs.botghost.com/modules/youtube" description="Receive notifications when you or someone else posts a new video" title="YouTube" icon="youtube.png"></ModuleHeader>

                <PremiumSection>
                    <SectionHeader title="Add Channel" pretitle="YouTube" ></SectionHeader>


                    <YouTubeEditor
                        saveText="Add"
                        save={this.commandAdd}
                    
                    ></YouTubeEditor>

                </PremiumSection>

                <PremiumSection>
                <SectionHeader title="Tracked Channels" pretitle="YouTube" ></SectionHeader>

                    {this.renderYouTubeChannels()}

                </PremiumSection>
            </Dashboard>
        )
    }
}

const mapStateToProps = (state) => ({
    youtube:state.data.bot.commands.youtube,
    premium:state.data.premium
})

const mapDispatchToProps = {
    setBotModule
}

export default connect(mapStateToProps, mapDispatchToProps)(YouTube)
