import React, { Component } from 'react';
import { connect } from 'react-redux';
import SlashCommandEditor from './SlashCommandEditor';
import Toggle from './Toggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore } from '@fortawesome/free-solid-svg-icons';
import { setBotModule } from '../../../actions';
export class SlashCommand extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showEditor: false
        };
    }

    commandClick = () => {
        // console.log();
    };

    renderEditor = () => {
        if (this.state.showEditor == true) {
        } else {

            return null;
        }
    };
    render() {
        return (
            <div className="announcement" style={{ opacity: this.props.command.enabled == false ? "0.5" : "1" }}>
                <div className="announcement-header m-section-content" onClick={(e) => {
                    // Check if the click target is the select element
                    if (e.target.tagName.toLowerCase() !== 'select') {
                        this.setState({ showEditor: !this.state.showEditor });
                    }
                }}>
                    <div className='command-block'>
                        <div className='m-section-content'>
                            <div>
                                <h3 className="mb-0">/{this.props.command.name}</h3>
                                <div className="section-content-header">{this.props.command.description} </div>
                            </div>
                        </div>
                        <div className='m-section-content-row'>
                            <div className="mr-4">
                                <select
                                    className="bg-[#40444b] !p-4 !pl-4 text-white opacity-70 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    value={this.props.command.groupId || ''}
                                    onChange={(e) => {
                                        e.stopPropagation(); // Prevent event from bubbling up
                                        const newGroupId = e.target.value;
                                        const data = { ...this.props.slash };
                                        const commands = [...data.commands];
                                        commands[this.props.index] = {
                                            ...this.props.command,
                                            groupId: newGroupId
                                        };
                                        data.commands = commands;
                                        this.props.setBotModule(
                                            {
                                                module: "slash",
                                                module_data: data
                                            }
                                        );
                                    }}
                                >
                                    <option value="">No Group</option>
                                    {this.props.groups.map((group) => (
                                        <option key={group.id} value={group.id}>
                                            {group.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                {this.state.showEditor == true ? <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i> : <i style={{ fontSize: "20px" }} class="bi bi-caret-down-fill"></i>}
                            </div>
                        </div>
                    </div>

                </div>

                <div>
                    {this.state.showEditor == true ?
                        <SlashCommandEditor
                            command={this.props.command}
                            index={this.props.index}
                            edit={true}
                            close={() => { this.setState({ showEditor: false }); }}
                        />
                        : null
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    groups: state.data.bot.commands.commandGroups || [],
    slash: state.data.bot.commands.slash || {},

});

const mapDispatchToProps = {

    setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(SlashCommand);