import React, { Component } from 'react'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas, faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import server from "../../../api/server"
import { setBotData } from "../../../actions"








export class RefreshBotData extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false
        }
    }

    handleClick = async () => {
        if (this.state.loading == false) {
            this.setState({ loading: true })
            const response = await server.post("/dashboard/botData", {
                id: this.props.bot.id,
                token: this.props.bot.token
            }).catch((e) => {
                this.setState({ loading: false })
            })

            if (response.data && response.data.success == true) {
                const bot_data = response.data.bot_data;
                this.props.setBotData(bot_data);
                this.setState({ loading: false })
            }
        }

    }

    render() {
        return (
            <FontAwesomeIcon onClick={() => { this.handleClick() }} icon={faSyncAlt} className={`refresh-bot-data ${this.props.absolute ? "icon-role-adder" : null} ${this.state.loading ? "refresh-loading animate-spin" : null}`} color={"#fff"}></FontAwesomeIcon>
        )
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot
})

const mapDispatchToProps = {
    setBotData
}

export default connect(mapStateToProps, mapDispatchToProps)(RefreshBotData)
