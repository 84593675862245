import React, { Component } from "react";
import { connect } from "react-redux";
import { updateServerPopup } from "../../../actions";
import server from "../../../api/server";
import ReactGA from "react-ga";
import Cookies from "universal-cookie";

import history from "../../../history";

export class SaleModal extends Component {

	constructor(props) {
		super(props);

		this.state = {
			showModal: false
		};
	}
	modalBackgroundClick = (e) => {
		// console.log("MODAL BACKGROUND CLICK");
		if (e.currentTarget == e.target) {
			this.props.closeModal(true);
		}
	};
	componentDidMount() {

		// 	var expiresTime = new Date();
		// 	expiresTime = new Date(expiresTime + 12 * 60 * 60 * 1000);
		// 	cookies.set("150kSale", true, {
		// 		expires: new Date(Date.now() + 12 * 60 * 60 * 1000)
		// 	});
		// 	this.setState({
		// 		saleModal: false
		// 	});
		// }}
		// console.log("PREMIUM MODAL MOUNTED");
	}



	render() {
		if (this.props.current_sale?.banner_url) {
			return (
				<div>
					<div
						class={`modal fade show modal-background`}
						onClick={(e) => {
							this.modalBackgroundClick(e);
						}}
						style={{ display: "block" }}
						id="exampleModal"
						tabindex="-1"
						role="dialog"
						aria-labelledby="exampleModalLabel"
					>
						<div onClick={(e) => {
							// Go to Premium Page
							history.push("/dashboard/premium");
							this.props.closeModal(true);
						}} class="modal-dialog hover:cursor-pointer flex justify-center align-center items-center  lg:h-full max-w-none lg:px-[250px]" role="document">
							<div class="modal-content premium-modal !h-auto">
								<div class="modal-content premium-modal !h-auto" style={{}}>
									<img src={this.props.current_sale.banner_url}></img>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}


	}
}

const mapStateToProps = (state) => ({
	current_sale: state.data.current_sale
});

const mapDispatchToProps = {
	updateServerPopup
};

export default connect(mapStateToProps, mapDispatchToProps)(SaleModal);
