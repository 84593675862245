import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import React, { Component } from "react";
import { connect } from "react-redux";
import server from "../../../api/server";
import Loader from "../../Loader";
import { copiedToast, errorToast } from "../commandbuilder/toast";
import Dashboard from "../Dashboard";
import ModuleHeader from "../elements/ModuleHeader";
import SectionHeader from "../elements/SectionHeader";
import moment from "moment";
import history from "../../../history";

export class Collaborate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
			url: "",
			collaborators: [

			],
			bots: []
		};
	}

	componentDidMount() {
		if (this.props.bot?.id) {
			this.fetchInvite();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.bot.id !== this.props.bot.id) {
			this.fetchInvite();
		}

		if (this.props.collab) {
			// redirect to /dashboard
			history.push("/dashboard");
		}
	}

	fetchInvite = async () => {
		const response = await server
			.post(`/collab`, {
				bot_id: this.props.bot.id,
				user_id: this.props.user.id
			})
			.catch((e) => {
				this.setState({ loaded: true });
				// error toast
				errorToast("Error", "An error occurred while fetching the invite link.");
			});

		if (response.data) {
			this.setState({ url: response.data.invite, loaded: true, collaborators: response.data.collaborators, bots: response.data.bots });
		}
	};

	generateInvite = async () => {
		this.setState({ loaded: false });
		const response = await server.post(`/collab/invite/`, {
			bot_id: this.props.bot.id,
			user_id: this.props.user.id
		});
		if (response.data) {
			this.fetchInvite();
		}
	};

	renderInvite = () => {
		if (this.state.url) {
			return (
				<>
					<div className="section-content-normal mb-0 justify-left flex flex-col md:flex-row  align-center">
						<div className="">
							<h3 style={{ marginBottom: "0px" }}>Invite Link</h3>
							<div class="section-content-header">Your Collaborate invite link. Give this link to others to allow them to edit this bot.</div>
						</div>

						<div className="ml-0 md:!ml-auto">
							<div className="grid grid-cols-2 mt-2 md:mt-0 md:grid-cols-2 gap-x-1">
								<button
									className="btn btn-blue !bg-blue"
									onClick={() => {
										navigator.clipboard.writeText(`https://dashboard.botghost.com/collab/${this.state.url}`).then(() => {
											// todo - add toast
											copiedToast();
										});
									}}
								>
									Copy
								</button>
								<button
									disabled={this.state.disableButton}
									onClick={(e) => {
										this.generateInvite();
									}}
									className="btn btn-red"
								>
									Regenerate
								</button>
							</div>
						</div>
					</div>
				</>
			);
		} else {
			return (
				<>
					<div className="bg-gray text-white p-5 rounded-sm flex flex-col justify-center text-center gap-y-2 mt-3">
						<div className="h-10 w-10">
							<ExclamationCircleIcon></ExclamationCircleIcon>
						</div>
						<div>
							<h3 className="text-xl font-bold">Generate Invite Link</h3>
							<p className="text-muted">Generate an invite link to invite other users to collaborate on your bot.</p>
						</div>

						<div>
							<button
								className="btn btn-red"
								onClick={() => {
									this.generateInvite();
								}}
							>
								Generate Invite
							</button>
						</div>
					</div>
				</>
			);
		}
	};

	renderLastUserUpdate = (last_update) => {
		if (!last_update) {
			return "never";
		} else {
			var time = moment(last_update).fromNow();
			return time;
		}
	};
	render() {
		if (!this.state.loaded) {
			return (
				<Dashboard>
					<ModuleHeader showEnabled={false} description="Invite other people to edit and work on your bot" title="Collab" icon="co-create.png"></ModuleHeader>
					<section>
						{/* Loader */}
						<div className="flex justify-center items-center h-64">
							{/* Tailwind loader */}
							<div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-red"></div>
						</div>
					</section>
				</Dashboard>
			);
		} else {
			return (
				<Dashboard>
					<ModuleHeader showEnabled={false} description="Invite other people to edit and work on your bot" title="Collab" icon="co-create.png"></ModuleHeader>

					<section>
						<SectionHeader title="Invite Link" pretitle="Collab"></SectionHeader>
						{this.renderInvite()}
					</section>

					<section>
						{/* Collaborators */}
						<SectionHeader title="Collaborators" pretitle="Collab"></SectionHeader>
						{/* List of users, with profile pic, name, remove button */}

						<div className="grid grid-cols-1 mt-3 gap-4 md:grid-cols-2">
							{this.state.collaborators.map((user) => (
								<div key={user.id} className=" relative text-white flex items-center space-x-3 rounded-lg  bg-gray px-4 py-3 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2">
									<div className="flex-shrink-0">
										<img className="h-10 w-10 rounded-full" src={`https://cdn.discordapp.com/avatars/${user.id}/${user.img}.png?size=128`} alt=""
											onError={({ currentTarget }) => {
												currentTarget.onerror = null; // prevents looping
												currentTarget.src = process.env.PUBLIC_URL + "/images/discord.jpg";
											}} />
									</div>
									<div className="min-w-0 flex-1 align-center flex">
										<div>
											<span className="" aria-hidden="true" />
											<p className="text-sm font-bold text-white">
												{user.name} <span className="text-muted font-medium">{`${user.id}`}</span>
											</p>
											<p className="truncate text-sm text-muted">Last update {this.renderLastUserUpdate(user.last_updated)}</p>
										</div>

										<div className="ml-auto">
											<button
												className="btn btn-red hover:cursor-pointer hover:opacity-70"
												onClick={async () => {
													this.setState({ loaded: false });
													var response = await server.post("/collab/user/remove", {
														bot_id: this.props.bot.id,
														user_id: user.id

													}).catch(e => {
														errorToast("Error", "An error occurred while removing the user.");

													});

													if (response.data) {
														// todo - remove user
													}
													this.fetchInvite();
													copiedToast("User removed");

												}}
											>
												Remove
											</button>
										</div>
									</div>
								</div>
							))}
						</div>
					</section>

					<section>
						{/* Bots */}
						<SectionHeader title="Bot Collaborations" pretitle="Collab"></SectionHeader>
						{/* List all bots the user is working on */}
						<div className="grid grid-cols-1 mt-3 gap-4 md:grid-cols-2">
							{this.state.bots.map((user) => (
								<div key={user.id} className=" relative text-white flex items-center space-x-3 rounded-lg  bg-gray px-4 py-3 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2">
									<div className="flex-shrink-0">
										<img onError={({ currentTarget }) => {
											currentTarget.onerror = null; // prevents looping
											currentTarget.src = process.env.PUBLIC_URL + "/images/discord.jpg";
										}} className="h-10 w-10 rounded-full" src={user.img ? user.img : process.env.PUBLIC_URL + "/images/discord.jpg"} alt="" />
									</div>
									<div className="min-w-0 flex-1 align-center flex">
										<div>
											<span className="" aria-hidden="true" />
											<p className="text-sm font-medium text-white">{user.name}</p>
											<p className="truncate text-sm text-muted">{user.id}</p>
										</div>

										<div className="ml-auto">
											<button
												className="btn btn-red hover:cursor-pointer hover:opacity-70"
												onClick={async () => {
													this.setState({ loaded: false });
													var response = await server.post("/collab/bot/remove", {
														bot_id: user.id,
														user_id: this.props.user.id

													}).catch(e => {
														errorToast("Error", "An error occurred while this collaboration.");

													});

													if (response.data) {
														// todo - remove user
													}
													this.fetchInvite();
													copiedToast("Collaboration Left");

												}}
											>
												Leave
											</button>
										</div>
									</div>
								</div>
							))}
						</div>
					</section>
				</Dashboard>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	bot: state.data.bot,
	user: state.data.user,
	collab: state.data.collab
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Collaborate);
