import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextArea from '../elements/TextArea';
import InputTitle from './InputTitle';

export class LongText extends Component {

    renderDescription = () => {

        if (this.props.settings.html) {
            return <div dangerouslySetInnerHTML={{ __html: this.props.settings.description }}>
            </div>;
        } else {
            return <p>{this.props.settings.description}</p>;
        }
    };

    render() {
        return (
            <>
                <div className={`${this.props.settings.premium && !this.props.premium ? "opacity-75 pointer-events-none" : ""} ${!this.props.slot ? "section-content-normal" : "mb-15"}`}>
                    <div class="">
                        <InputTitle settings={this.props.settings} />
                        <div className="section-content-header mb-2">
                            {this.renderDescription()}
                        </div>
                        <div>
                            <TextArea
                                disabled={this.props.settings.premium && !this.props.premium}
                                placeholder={this.props.settings.placeholder || ""}
                                value={this.props.value}
                                maxLength={this.props.settings.maxLength}
                                onChange={(value) => { this.props.change(value); }}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    premium: state.data.premium
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LongText);