import React, { Component } from 'react'
import { connect } from 'react-redux'

export class PremiumFeature extends Component {
    render() {
        return (
            <div className="col-lg-6 premium-feature">
                <div className="premium-feature-inner">
                    <img src={process.env.PUBLIC_URL + `/images/icons/${this.props.icon}`}/>

                    <div className="premium-feature-text">
                        <h4>{this.props.title}</h4>
                        <span>{this.props.info}</span>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(PremiumFeature)
