import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";

import { setBotModule } from "../../../actions";

import Dashboard from "../Dashboard";
import PremiumRedirect from "../elements/PremiumRedirect";
import PremiumSection from "../elements/PremiumSection";

export class Trivia extends Component {
  commandUpdate = (value, type) => {
    // console.log(value, type);
    // var search = {...this.props.search}
    // search[type] = value
    // this.props.setBotModule({
    //     module:"search",
    //     module_data:search
    // })
  };

  moduleEnable = (value) => {
    const fun = { ...this.props.fun };
    fun.trivia.enabled = value;

    this.props.setBotModule({
      module: "fun",
      module_data: fun,
    });
  };

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  render() {
    return (
      <Dashboard>
        <PremiumRedirect></PremiumRedirect>
        <ModuleHeader
          tutorial="https://docs.botghost.com/fun-modules/trivia"
          premiumRequired={true}
          enabledValue={this.props.trivia.enabled}
          change={this.moduleEnable}
          description="Test your skills against fellow members"
          title="Trivia"
          docs="https://docs.botghost.com/fun-modules/trivia"
          icon="trivia.png"
        ></ModuleHeader>

        <PremiumSection>
          <SectionHeader title="Commands" pretitle="Trivia"></SectionHeader>

          <div className="row">
            <div className="col-xl-6">
              <Command
                name="start"
                description="Starts a new game of trivia"
                module="fun"
                defaultTrigger="start_trivia"
                submodule="trivia"
                update={this.commandUpdate}
                options={this.props.trivia.start}
                showToggle={false}
                showPrefix={true}
                slashOnly={true}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="join"
                description="Joins a currently active game of trivia"
                module="fun"
                defaultTrigger="join_trivia"
                submodule="trivia"
                options={this.props.trivia.join}
                showPrefix={true}
                slashOnly={true}
                showToggle={false}
              ></Command>
            </div>
          </div>
        </PremiumSection>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  trivia: state.data.bot.commands.fun.trivia,
  fun: state.data.bot.commands.fun,
  beta: state.data.beta,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trivia);
