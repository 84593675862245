import React, { Component } from 'react'
import { connect } from 'react-redux'

export class PremiumBadge extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    componentDidMount() {
        // console.log(this.props)
    }
    render() {
        if (this.props.premium == false) {
            return (
                <div className="premium-badge">
                    Premium
                </div>
            )
        } else {
            return (null)
        }
    }
}

const mapStateToProps = (state) => ({
    premium: state.data.premium
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PremiumBadge)
