import React, { Component } from "react";
import { connect } from "react-redux";

import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import SectionHeader from "../elements/SectionHeader";
import CustomVariable from "../modules/CustomVariable";
import { setBotModule, setUsage } from "../../../actions";

export class BuilderVarEditor extends Component {
	constructor(props) {
		super(props);

		this.state = {
			variable: {
				name: "",
				reference: "",
				type: "text",
				dataType: "text",
				defaultValue: "",
				global: false
			}
		};
	}


	render() {
		return (
			<>
				<Transition.Root show={this.props.showModal} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={() => {
							this.props.close();
							// this.setState({ showDeleteModal: false });
						}}
					>
						<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
							<div className="fixed inset-0 bg-[#0000008a] transition-opacity" />
						</Transition.Child>

						<div className="fixed inset-0 z-10 overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
									<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#2f3136]   pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
										<div className="absolute top-0 right-0 pt-4 pr-4">
											<button
												type="button"
												className="rounded-md bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-offset-2"
												onClick={() => this.props.close()}
											>
												<span className="sr-only">Close</span>
												<svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
												</svg>
											</button>
										</div>
										<section className="mt-0">
											<div className="section-content-normal mt-0">
												<div class="mb-15">
													<h3 style={{ marginBottom: "0px", fontSize: "26px" }}>New Custom Variable</h3>
													<div className="section-content-header">Create a new Custom Variable. These can be used in your custom commands and events to store data.</div>
												</div>
												<span style={{ color: "red", fontWeight: "600" }}>{this.state.error}</span>

												<hr class="slashcommand-hr" />
												<div>
													<CustomVariable
														variable={this.state.variable}
														index="new"
														editVariable={(variable, index) => {
															if (index == "new") {
																var variables = { ...this.props.variables };

																var variable_settings = variables.variables.find((v) => v.reference == variable.reference);
																if (variable_settings) {
																	return;
																}

																variables.variables.push(variable);
																this.props.setBotModule({
																	module: "variables",
																	module_data: variables
																});
																var newVariable = {
																	name: "",
																	reference: "",
																	type: "text",
																	dataType: "text",
																	defaultValue: "",
																	global: false
																};
																this.setState({
																	variable: newVariable
																});
																this.props.close(variable.reference);
															}
														}}
													></CustomVariable>
												</div>
											</div>
										</section>
										{/* <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
											<button type="button" className="inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm" disabled={this.state.disableDeleteButton} onClick={async () => {}}>
												Delete Template
											</button>
											<button type="button" className=" inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm" onClick={() => this.setState({ showDeleteModal: false })}>
												Cancel
											</button>
										</div> */}
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	variables: state.data.bot.commands.variables,
	user: state.data.user,
	premium: state.data.premium
});

const mapDispatchToProps = {
	setBotModule,
	setUsage
};

export default connect(mapStateToProps, mapDispatchToProps)(BuilderVarEditor);