import React, { Component } from 'react';
import { connect } from 'react-redux';

import { changeBot, setQuickStart } from "../../../actions";
import history from "../../../history";
export class BotSwitcher extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hidden: true
        };
    }


    componentDidMount() {
        // console.log(this.props, 'BOT SWITCHER');
    }


    closeModal = (e) => {
        this.setState({ hidden: true });
        this.props.close();
    };

    modaBackgroundClick = (e) => {
        if (e.currentTarget == e.target) {
            this.closeModal(e);
        }
    };

    changeBot = (id, cocreator, host_user_id, beta) => {
        if (this.props.bot.id != id) {
            this.props.changeBot(id, cocreator, host_user_id, beta);
            var quick_start = { ...this.props.quick_start };
            quick_start["change_bot"] = true;
            this.props.setQuickStart(quick_start);
            history.push("/dashboard");
            this.closeModal();
        } else {
            this.closeModal();
        }
    };

    renderImage = (bot) => {
        var img = "";
        if (bot.img != "" && bot.img != null) {
            return bot.img;
        } else {
            return bot.avatar;
        }

    };

    renderBots = () => {
        const bots = [];

        this.props.bots.forEach(bot => {
            bots.push(
                <div className="col-xl-6">
                    <div className={`module-item ${this.props.bot.id == bot.id ? "bot-active" : null}`} onClick={(e) => { this.changeBot(bot.id, bot.cocreator, bot.host_user_id, bot.beta); }}>
                        <img onError={(target) => {
                            target.onError = null;
                            target.target.src = process.env.PUBLIC_URL + "/images/logo-red.png";
                        }} src={this.renderImage(bot) || process.env.PUBLIC_URL + "/images/logo-red.png"}></img>
                        <h3>{bot.username}</h3>
                        <div style={{ width: "100%", textAlign: "center" }}>
                            {bot.cocreator ? <span style={{ color: "red", fontWeight: "600" }}>Cocreator</span> : null}
                        </div>
                    </div>
                </div>
            );
        });

        // bots.push(
        //     <div className="col-xl-6">
        //     <div className="module-item">
        //         <img src={process.env.PUBLIC_URL + "/images/logo-red.png"}></img>
        //         <h3>New Bot</h3>
        //     </div>
        // </div>
        // )

        return bots;
    };

    render() {

        if (this.state.hidden == true) {
            return (
                <div>
                    <div class={`modal fade show modal-background`} onClick={(e) => { this.modaBackgroundClick(e); }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog bot-switcher-modal" role="document">
                            <div class="modal-content" style={{ backgroundColor: "#36393f" }}>
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Change Bot</h5>
                                    <button onClick={(e) => { this.closeModal(e); }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>


                                <div class="modal-body command-edit-body">
                                    <div className="row">

                                        {this.renderBots()}


                                    </div>

                                </div>

                                <div className="modal-footer new-bot-button" onClick={(e) => { history.push("/dashboard/new"); }}>
                                    {/* <div className="new-bot-button"> */}
                                    <span onClick={(e) => { history.push("/dashboard/new"); }}>New Bot</span>
                                    {/* </div> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }

    }
}

const mapStateToProps = (state) => ({
    bots: state.data.bots,
    bot: state.data.bot
});

const mapDispatchToProps = {
    changeBot,
    setQuickStart
};

export default connect(mapStateToProps, mapDispatchToProps)(BotSwitcher);
