import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../../../api/server';

import ReactGA from 'react-ga';
import { setServerIntents } from '../../../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';



export class IntentCheck extends Component {
    constructor(props) {
        super(props);

        this.state = {
            server: false,
            intent: false,
            disabled: false,
            hidden: true,
            error: "",
            ratelimited: false
        };
    }

    componentDidMount() {
        // console.log(this.state)
        // if(this.props.intent == false && this.state.intent == false){
        //     this.getServers()
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.state);
    }

    getServers = async () => {
        this.setState({ disabled: true });
        const response = await server.post("/intents", { token: this.props.token, id: this.props.id });
        this.setState({ disabled: false });
        if (response.data) {
            if (response.data.response == false && response.data.reason == "intent") {
                if (this.state.intent == false) {
                    ReactGA.initialize('UA-122665050-1');
                    ReactGA.event({
                        category: "Bot Check",
                        label: response.data.reason,
                        action: "Dashboard"
                    });
                }
                this.setState({ intent: true, server: false });
            } else if (response.data.response == true) {
                if (this.state.intent == false && response.data) {
                    ReactGA.initialize('UA-122665050-1');
                    ReactGA.event({
                        category: "Bot Check",
                        label: "Fixed Intent",
                        action: "Dashboard"
                    });
                }
                this.setState({ server: false, intent: false });
                this.props.setServerIntents(true);
                // SET INTENT TO TRUE;
            } else if (response.data.reason == "429") {
                this.setState({ disabled: true, ratelimited: true });
                setTimeout(() => {
                    this.setState({ disabled: false, ratelimited: false });
                }, 30000);
            } else if (response.data.reason == "server") {
                this.setState({ intent: false, server: true });
            }
        }
    };

    verifyIntents = async (ignore) => {
        this.setState({ disabled: true, error: "" });
        const response = await server.post("/intents", { token: this.props.token, ignore: ignore ? true : false, id: this.props.id });
        if (response.data == true) {
            this.props.setServerIntents(true);
            ReactGA.initialize('UA-122665050-1');
            ReactGA.event({
                category: "Intents",
                label: "Fixed",
                action: "New Dashboard"
            });
        } else {
            this.setState({ disabled: false, error: "Please click the How to Fix button and follow the video tutorial to learn how to enable Gateway Intents." });

        }
    };
    render() {
        if (this.props.intent == false) {
            return (
                <div class="intents-banner mb-5">
                    <div class="header">
                        <FontAwesomeIcon icon={faExclamationCircle} />Privileged Gateway Intents required for your Bot to Function
                    </div>
                    <p>Privileged Gateway Intents are required for some bot functions you have enabled to work. Enable <strong>Server Members Intent, Presence Intent and Message Content Intent</strong> in your Discord Developer portal or disable the module requiring privileged intents. Once complete restart your bot by clicking <strong>Verify Intents.</strong></p>
                    {this.state.error != "" ? <span style={{ color: "#fff", fontWeight: "800" }}>{this.state.error}</span> : null}
                    <div className="buttons m-buttons">
                        <a className="btn btn-gray mr-3" style={{ backgroundColor: "#2f3136" }} target="_blank" onClick={(e) => { this.setState({ hidden: false }); }} href={`https://www.youtube.com/watch?v=Zd459DyJ0ZU`}>How To Fix</a>
                        <a className="btn btn-gray mr-3" style={{ backgroundColor: "#2f3136" }} target="_blank" onClick={(e) => { this.setState({ hidden: false }); }} href={`https://discord.com/developers/applications/${this.props.id}/bot`}>Discord Developer Portal</a>
                        <button style={{ backgroundColor: "#fff", padding: "5px 25px" }} disabled={this.state.disabled} onClick={(e) => { this.verifyIntents(true); }} className="btn mr-3">Ignore</button>
                        <button style={{ backgroundColor: "#fff", padding: "5px 25px" }} disabled={this.state.disabled} onClick={(e) => { this.verifyIntents(false); }} className="btn">Verify Intents</button>
                    </div>
                </div>
            );
        } else {
            return null;
        }
        //     if(this.state.server == true || this.state.intent == true){
        //         return (
        //             <div>
        //             <div class={`modal fade show modal-background`} style={{display:"block"}} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        //             <div class="modal-dialog premium-modal-dialog" role="document" style={{maxWidth:"800px"}}>
        //                 <div class="modal-content premium-modal" style={{backgroundColor:"#f8f8fb"}}>


        //                 <div class="modal-body command-edit-body premium-modal-body">
        //                     <div className="premium-modal-content server_check_modal">
        //                         <img src={process.env.PUBLIC_URL + "/images/logo-red.png"} style={{height:"50px"}} className="mb-2"></img>
        //                         {this.state.server == true ? <div style={{textAlign:"center"}}>
        //                             <h2>Invite your bot to a server</h2>
        //                             <p>Invite your  bot to a server to get started. Click on the invite button below to invite your bot. Once your bot is in a server, click the Get Started button to continue. </p>
        //                             </div>:
        //                             <div style={{textAlign:"center"}}>
        //                                 <h2>Privledged Gateway Intents required</h2>
        //                                 <img src={process.env.PUBLIC_URL + "/images/intents.PNG"} style={{width:"750px"}}></img>
        //                                 <p className="mt-2">Privledged Gateway Intents are required for some bot functions to work correctly. Enable both <strong>Presence Intent</strong> and <strong>Server Members Intent</strong> in your Discord Developer portal. Once enabled click Verify Intents to continue.</p>
        //                             </div>

        //                             }

        //                             {this.state.ratelimited == true ? <span style={{color:"red",fontWeight:"800"}}>Please invite your bot to a server and enable gateway intents. You have been timed out for 30 seconds.</span>:null}


        //                         <div className="mt-2">
        //                             <div>
        //                                 {this.state.server == true ? <a className="btn btn-gray mr-3" onClick={(e) =>{this.setState({hidden:false})}} href={`https://discord.com/oauth2/authorize?client_id=${this.props.id}&scope=bot&permissions=8`} target="_blank">Invite Bot</a>:<a className="btn btn-gray mr-3" target="_blank" onClick={(e) =>{this.setState({hidden:false})}} href={`https://discord.com/developers/applications/${this.props.id}/bot`}>Discord Developer Portal</a>}
        //                                 {this.state.server == true ? <button disabled={this.state.disabled || this.state.ratelimited || this.state.hidden} onClick={(e) =>{this.getServers()}} className="btn btn-red">Verify Server</button>:<button disabled={this.state.disabled || this.state.ratelimited || this.state.hidden} onClick={(e) =>{this.getServers()}} className="btn btn-red">Verify Intents</button>}
        //                             </div>


        //                             {/* <button className="btn btn-red mr-2" onClick={() =>{history.push("/dashboard/premium")}}>Upgrade</button>
        //                             <button className="btn btn-gray" onClick={() =>{this.props.closeModal()}}>Close</button> */}
        //                         </div>
        //                     </div>

        //                 </div>


        //                 </div>
        //             </div>
        //             </div>
        //         </div>
        //         )
        //     }else{
        //         return null;
        //     }
    }
}

const mapStateToProps = (state) => ({
    token: state.data.bot.token,
    id: state.data.bot.id,
    intent: state.data.server_members_intent
});

const mapDispatchToProps = {
    setServerIntents
};

export default connect(mapStateToProps, mapDispatchToProps)(IntentCheck);
