import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";

import Embed from "../../elements/Embed";
import RoleAdd from "../../elements/RoleAdd";
import Select from "../../elements/Select";
import { checkIntegrationChild } from "../eventUtils";
import LongText from "../../inputs/LongText";
import LongInput from "../../elements/LongInput";
import VariableTextInput from "../VariableTextInput";

export class VoiceJoin extends Component {
	componentDidMount() {
		if (this.props.data == undefined) {
			var target = { id: "" };
			this.props.updateElementData({
				data: {
					validated: false,
					type: "voice_join",
					target: target,
					success_handles: false
				},
				id: this.props.id
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data != undefined && this.props.data.success_handles == undefined) {
			this.updateData("success_handles", false);
		}
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					validated: false,
					type: "voice_join",
					target: {
						id: ""
					},
					validated: false,
					success_handles: false
				},
				id: this.props.id
			});
		} else if (this.props.data && !this.props.data.target) {
			if (this.props.components["Member"]) {
				this.updateData("target", { self: true });
			} else {
				this.updateData("target", { id: "" });
			}
		} else if (this.props.data && typeof this.props.data.target === "string") {
			console.log("UPDATE TARGET");
			if (this.props.components["Member"]) {
				this.updateData("target", { self: true });
			} else {
				this.updateData("target", { id: "" });
			}
		} else {
			// VALIDATE
			if (this.validateTarget() && this.props.data.validated != true) {
				this.updateData("validated", true);
			} else if (this.props.data.validated && !this.validateTarget()) {
				this.updateData("validated", false);
				console.log("validating target");
			}
		}
	}

	validateTarget = () => {
		if (typeof this.props.data.target === "string") {
			return true;
		} else if ("id" in this.props.data.target && this.props.data.target.id != "") {
			return true;
		} else if ("self" in this.props.data.target && this.props.data.target.self) {
			return true;
		} else if ("variable" in this.props.data.target && this.props.data.target.variable != "") {
			return true;
		} else {
			return false;
		}
	};

	nameChange = (value) => {
		value = value.toLowerCase();
		value = value.substring(0, 32);
		value = value.replace(" ", "-");
		const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/gu);
		value = value.replace(regexForNonAlphaNum, "");
		this.updateData("name", value);
	};

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	renderRoleOptions = () => {
		var options = [
			// {value:"trigger",label:"The member who triggered the command"}
		];
		this.props.options.forEach((option) => {
			if (option.type == "role") {
				options.push({ type: "role", name: option.name });
			}
		});
		return options;
	};

	renderResponseTypes = () => {
		var user = "The voice channel of the user.";
		if (this.props.mode == "event" && this.props.components["VoiceChannel"]) {
			user = "The voice channel associated with this event.";
		}

		const responseTypes = [
			{ label: user, value: "self", disabled: this.props.components.Interaction || this.props.components.User ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "Channel option", value: "variable", disabled: !this.props.components.Interaction },
			{ label: "Voice Channel ID", value: "id" }
		];
		return responseTypes;
	};

	renderUserOptions = () => {
		var member = "The member who triggered the command";
		if (this.props.mode == "event" && this.props.components["Channel"]) {
			member = "The user.";
		}
		var options = [{ value: "trigger", label: member, disabled: this.props.components.Interaction || this.props.components.Member ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true }];
		this.props.options.forEach((option) => {
			if (option.type == "user") {
				options.push({ value: option.name, label: `User Option: ${option.name}` });
			}
		});
		if (this.props.mode == "event") {
			options.unshift({ value: "", label: "", disabled: true });
		}
		console.log(options);
		return options;
	};

	targetOnChange = (value) => {
		// {label:"User who used the command",value:"self"},
		// {label:"User variable",value:"variable"},
		// {label:"User ID",value:"id"},
		if (value == "self") {
			var data = { ...this.props.data };
			data.target = {
				self: true
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		} else if (value == "variable") {
			var data = { ...this.props.data };
			data.target = {
				variable: ""
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		} else if (value == "id") {
			var data = { ...this.props.data };
			data.target = {
				id: ""
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		}
	};

	renderOptions = () => {
		var options = [];
		this.props.options.forEach((option) => {
			if (option.type === "channel") {
				options.push({ value: option.name, label: `User Option: ${option.name}` });
			}
		});
		return options;
	};

	render() {
		if (this.props.data != undefined && typeof this.props.data.target === "object") {
			return (
				<div>
					<div className="mb-15">
						<h4>Join a Voice Channel</h4>
						<p>Force the bot to join a voice channel. This can be either a channel id, a channel option or the channel the executing user is in.</p>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Voice Channel <DocsLink location={`https://docs.botghost.com/custom-commands-and-events/actions/${this.props.type == "role_add" ? "add" : "remove"}-role#member`} />
						</h4>
						<span>The voice channel to join.</span>
						<Select
							value={this.props.data.target && "self" in this.props.data.target ? "self" : this.props.data.target && "variable" in this.props.data.target ? "variable" : this.props.data.target && "id" in this.props.data.target ? "id" : ""}
							onChange={(value) => {
								this.targetOnChange(value);
							}}
							options={this.renderResponseTypes()}
						></Select>
					</div>

					{this.props.data.target && "variable" in this.props.data.target ? (
						<div className="mb-15 slash-action">
							<h4>Channel Option </h4>
							<span>The channel option you want to have the bot join.</span>
							<br />
							{this.renderOptions().length === 0 && <span style={{ color: "orange", weight: "bold" }}>You need to have a user option added to this command.</span>}

							<VariableTextInput
								label="Option"
								placeholder="{option_channel}"
								value={this.props.data.target.variable}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										variable: value
									};
									this.props.updateElementData({
										data: data,
										id: this.props.id
									});
								}}
								required={true}
								slash_options={this.props.options}
							/>
							{/* <div class="long-input mt-15">
                                    <label>Option</label>
                                    <input className={this.props.saveFailed && this.props.data.target.variable == "" ? "command-required" : ""} onChange={(e) => {
                                        var data = { ... this.props.data };
                                        data.target = {
                                            variable: e.target.value,
                                        };
                                        this.props.updateElementData(
                                            {
                                                data: data,
                                                id: this.props.id
                                            }
                                        );

                                    }} type="text" placeholder={"{option_channel}"} value={this.props.data.target.variable} />
                                </div> */}
						</div>
					) : null}
					{this.props.data.target && "id" in this.props.data.target ? (
						<div className="mb-15 slash-action">
							<h4>Channel ID </h4>
							<span>The ID of the channel that you want the bot to join.</span>
							<VariableTextInput
								slash_options={this.props.options}
								label="ID"
								placeholder="1134000586010939403"
								value={this.props.data.target.id}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										id: value
									};
									this.props.updateElementData({
										data: data,
										id: this.props.id
									});
								}}
								required={true}
							/>
							{/* <div class="long-input mt-15">
                                    <label>ID</label>
                                    <input className={this.props.saveFailed && this.props.data.target.id == "" ? "command-required" : ""} onChange={(e) => {

                                        var data = { ... this.props.data };
                                        data.target = {
                                            id: e.target.value,
                                        };
                                        this.props.updateElementData(
                                            {
                                                data: data,
                                                id: this.props.id
                                            }
                                        );

                                    }} type="text" placeholder={"1134000586010939403"} value={this.props.data.target.id} />
                                </div> */}
						</div>
					) : null}
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	saveFailed: state.builder.saveFailed,
	components: state.builder.components,
	mode: state.builder.mode
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(VoiceJoin);
