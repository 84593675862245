import React, { Component } from 'react'
import { connect } from 'react-redux'

import Embed from "../Embed"
import DocsLink from "../DocsLink"
import DiscordMessagePreview from '../DiscordMessagePreview'

export class Embed_Action extends Component {

    componentDidMount() {
        if (!("embed" in this.props.data)) {
            this.props.update("embed", {
                title: "",
                description: ""
            })
        }
    }

    embedUpdate = (value) => {
        var embed = { ...this.props.data.embed };
        embed = value;
        this.props.update("embed", embed)
    }
    render() {
        if (this.props.data.embed == undefined) {
            return null
        } else {
            return (
                <div>                
                    <div className="mb-15">
                    <h3 style={{ marginBottom: "0px" }}>Embed Response <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions/embed-response"} /></h3>
                    <div className="section-content-header mb-15">The response of this action</div>

                    <Embed variableEditor={true} slash={true} required={true} options={this.props.options} update={this.embedUpdate} value={this.props.data.embed} />

                </div>

                    <div className="mb-15">
                        <div className="slash-action">
                        <h4>Preview </h4>
                        <span>A preview of your action</span>
                        </div>
                        <DiscordMessagePreview
                         type={"embed"} 
                         embed={this.props.data.embed}
                         interaction={this.props.preview_interaction || false}
                         interaction_name={this.props.name}
                         buttons={this.props.buttons}
                         
                         />

                    </div>
                </div>

            )
        }
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Embed_Action)
