import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setElements } from '../../../../actions';
import getLayoutedElements from "../getLayoutElements";
import Embed from '../../elements/Embed';
import TextArea from '../../elements/TextArea';
import Select from '../../elements/Select';
import RefreshBotData from '../../elements/RefreshBotData';
import { addEdge, getOutgoers } from 'react-flow-renderer';
import { EmojiAdder } from './EmojiAdder';
import RoleAdd from "../../elements/RoleAdd";
import splitElements from '../splitElements';
import { checkIntegrationChild } from '../eventUtils';
import VariableTextInput from '../VariableTextInput';
const responseTypes = [
    { value: "reply", label: "Reply to the command" },
    { value: "targeted", label: "Send the message to a specific text-channel" }
];


export class CurrencyCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            amount: 0,
            type: "less_than"
        };
    }

    componentDidMount() {
        if (this.props.data == undefined) {
            var target = { user_id: "" };
            if (this.props.components['User']) {
                target = { self: true };
            }
            this.props.updateElementData(
                {
                    data: {
                        type: "condition",
                        condition_type: "currency",
                        multiple: false,
                        validated: true,
                        target: target,
                    },
                    id: this.props.id
                }
            );
        } else {
            if (!this.props.data.target) {
                var data = { ...this.props.data };
                data.target = {
                    self: true
                };
                this.props.updateElementData(
                    {
                        data: data,
                        id: this.props.id
                    }
                );
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data == undefined) {
            var target = { user_id: "" };
            if (this.props.components['User']) {
                target = { self: true };
            }
            this.props.updateElementData(
                {
                    data: {
                        type: "condition",
                        condition_type: "currency",
                        multiple: false,
                        validated: true,
                        target: target,
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE
        }
    }





    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };


    renderOptions = () => {
        var options = [
            { value: "", label: "" }
        ];
        this.props.options.forEach(option => {
            options.push({ value: option.name, label: option.name });
        });
        return options;
    };

    selectUpdate = (value) => {
        value = JSON.parse(value);
        var response_options = { ...this.props.data.response_options };
        response_options.target = value;
        this.updateData("response_options", response_options);
    };

    addCondition = () => {
        var elements = [...this.props.elements];
        var element = this.props.elements.find(element => element.id == this.props.selected);
        var existingConditions = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);


        var id = `conChild${elements.length + 1}_${Math.random().toString(36).substr(2, 9)}`;
        var lastChild = existingConditions[existingConditions.length - 1];

        var docuElement = document.getElementById(lastChild.id);
        var width = 0;
        if (docuElement) {
            width = docuElement.clientWidth;
        }



        elements.push(
            {
                id: id,
                type: 'conditionChild',
                data: {
                    node_options: {
                        type: "currency"
                    },
                    data: {
                        amount: this.state.amount,
                        type: this.state.type,
                        actions: [],
                        option: "currency",
                    }

                },
                // target:"3",
                draggable: true,
                position: { x: lastChild.position.x + width + 20, y: lastChild.position.y },
            },
        );
        var newEdges = addEdge({
            source: element.id,
            target: id,
            type: "step",
            animated: false,
            arrowHeadType: "arrowclosed"

        }, splitElements(elements).edges);
        newEdges.forEach(newEdge => {
            var index = elements.findIndex(element => element.id == newEdge.id);
            if (index == -1) {
                elements.push(newEdge);
            }
            // elements[index] = newNode;
        });
        // elements = getLayoutedElements(elements)
        this.props.setElements(elements);
        this.setState({ amount: 0 });



    };

    renderSelectOptions = (type) => {
        var options = [
            // {value:"trigger",label:"The member who triggered the command"}
        ];
        this.props.options.forEach(option => {
            if (option.type == 'role') {
                options.push({ value: { id: `{option_${option.name}}`, name: option.name }, label: `Role Option: ${option.name}` });

            }

        });
        return options;
    };

    renderResponseTypes = () => {
        var user = "User who used the command";
        if (this.props.mode == "event" && this.props.components['User']) {
            user = "The user.";
        }
        // const responseTypes = [
        //     { value: "reply", label: "Reply to the command", disabled: this.props.components.Interaction? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
        //     { value: "trigger_channel", label: `Send the message to the ${channel}`, disabled: this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true }
        // ];

        const responseTypes = [
            { label: user, value: "self", disabled: this.props.components.Interaction || this.props.components.User ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
            { label: "User option", value: "variable", disabled: this.props.components.Interaction ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
            { label: "User ID", value: "id" },
        ];

        return responseTypes;

    };

    targetOnChange = (value) => {
        if (value == this.state.user) {
            return;
        }
        if (value == "self") {
            var data = { ... this.props.data };
            data.target = {
                self: true,
            };
            this.props.updateElementData(
                {
                    data: data,
                    id: this.props.id
                }
            );

        } else if (value == "variable") {
            var data = { ... this.props.data };
            data.target = {
                variable: "",
            };
            this.props.updateElementData(
                {
                    data: data,
                    id: this.props.id
                }
            );
        } else if (value == "id") {
            var data = { ... this.props.data };
            data.target = {
                user_id: '',
            };
            this.props.updateElementData(
                {
                    data: data,
                    id: this.props.id
                }
            );
        }

        this.setState({ user: value });

    };

    render() {
        if (this.props.data != undefined && this.props.data.target != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Currency Condition <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/currency-condition" /></h4>
                        <p>Run actions based on the currency balance of the user.</p>
                    </div>

                    <div className='mb-15 slash-action'>
                        <h4>Run multiple actions <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/currency-condition#run-multiple-actions" /> </h4>
                        <span>Should the condition run multiple actions if multiple conditions are met.</span>
                        <Select value={this.props.data.multiple} onChange={(value) => {

                            this.updateData('multiple', value == "true" ? true : false);
                            // value = JSON.parse(value);
                            // this.setState({value:value})
                        }} options={[
                            { label: "Yes", value: true },
                            { label: "No", value: false },

                        ]} ></Select>

                    </div>


                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>

                    <>
                        <div className='mb-15 slash-action'>
                            <h4>User Target <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/currency-condition#user-target" /> </h4>
                            <span>Which user to check the the currency balance of.</span>
                            <Select value={
                                'self' in this.props.data.target ? 'self' : 'variable' in this.props.data.target ? 'variable' : 'user_id' in this.props.data.target ? 'id' : ''
                            } onChange={(value) => {
                                this.targetOnChange(value);
                                // value = JSON.parse(value);
                                // this.setState({value:value})
                            }} options={this.renderResponseTypes()} ></Select>

                        </div>

                        {
                            'variable' in this.props.data.target ?
                                <div className="mb-15 slash-action">
                                    <h4>User Option </h4>
                                    <span>The user option to check the roles of.</span>
                                    <VariableTextInput label="Option" placeholder="{option_user}" value={this.props.data.target.variable} onChange={(value) => {
                                        var data = { ... this.props.data };
                                        data.target = {
                                            variable: value,
                                        };
                                        this.props.updateElementData(
                                            {
                                                data: data,
                                                id: this.props.id
                                            }
                                        );
                                    }} slash_options={this.props.options} />
                                    {/* <div class="long-input mt-15">
                                        <label>Option</label>
                                        <input onChange={(e) => {

                                            var data = { ... this.props.data };
                                            data.target = {
                                                variable: e.target.value,
                                            };
                                            this.props.updateElementData(
                                                {
                                                    data: data,
                                                    id: this.props.id
                                                }
                                            );

                                        }} type="text" placeholder={"{option_user}"} value={this.props.data.target.variable} />
                                    </div> */}

                                </div> : null
                        }


                        {
                            'user_id' in this.props.data.target ?
                                <div className="mb-15 slash-action">
                                    <h4>User ID </h4>
                                    <span>The user id to check the roles of.</span>
                                    <VariableTextInput label="User ID" placeholder="136327647792726016" value={this.props.data.target.user_id} onChange={(value) => {
                                        var data = { ... this.props.data };
                                        data.target = {
                                            user_id: value,
                                        };
                                        this.props.updateElementData(
                                            {
                                                data: data,
                                                id: this.props.id
                                            }
                                        );
                                    }} slash_options={this.props.options} />
                                    {/* <div class="long-input mt-15">
                                        <label>ID</label>
                                        <input onChange={(e) => {

                                            var data = { ... this.props.data };
                                            data.target = {
                                                user_id: e.target.value,
                                            };
                                            this.props.updateElementData(
                                                {
                                                    data: data,
                                                    id: this.props.id
                                                }
                                            );

                                        }} type="text" placeholder={"136327647792726016"} value={this.props.data.target.user_id} />
                                    </div> */}

                                </div> : null
                        }



                    </>
                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>

                    <div className="mb-15">
                        <div className="mb-15 slash-action">
                            <h4>Currency Amount <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/currency-condition#currency-amount" /> </h4>
                            <span>The amount of currency for this condition.</span>
                            <VariableTextInput label="Amount" placeholder="100" value={this.state.amount} onChange={(value) => { this.setState({ amount: value }); }} slash_options={this.props.options} />

                            {/* <div class="long-input mt-15">
                                <label>Amount</label>
                                <input onChange={(e) => {

                                    this.setState({ amount: e.target.value });

                                }} type="text" value={this.state.amount} />
                            </div> */}

                        </div>
                    </div>

                    <div className='mb-15 slash-action'>
                        <h4>Condition Type </h4>
                        <span>The condition type.</span>
                        <Select value={this.state.type} onChange={(value) => {
                            this.setState({ type: value });
                            // value = JSON.parse(value);
                            // this.setState({value:value})
                        }} options={[
                            { label: "Less than", value: "less_than" },
                            { label: "Less than or equal to", value: "less_than_equal_to" },
                            { label: "Greater than", value: "greater_than" },
                            { label: "Greater than or equal to", value: "greater_than_equal_to" },
                            { label: "Equal to", value: "equal_to" },
                            { label: "Doesn't equal", value: "not" },

                        ]} ></Select>

                    </div>
                    <div>
                        <button disabled={this.state.amount == "" ? true : false} onClick={() => { this.addCondition(); }} className="btn btn-red add-button">Add Condition</button>

                    </div>


                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    components: state.builder.components,
});
const mapDispatchToProps = {
    updateElementData,
    setElements
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyCondition);
