import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocsLink from '../DocsLink'


import TextArea from "../TextArea"
import Toggle from "../Toggle"
import Embed_Action from './Embed_Action'
import PlainText_Action from './PlainText_Action'

export class Random_Response_Action extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             type:"plaintext"
        }
    }


    componentDidMount() {
        if(!("responses" in this.props.data)){
            this.props.update("responses",[
                {response:""}
            ])
        }   
    }

    addResponse = () =>{
        var responses = [...this.props.data.responses];
        if(responses.length <25){
            responses.push(
                {
                    response:""
                }
            )
    
            this.props.update("responses",responses);
        }
    }

    renderResponses = () =>{
        var responses = [];

        this.props.data.responses.forEach((response,index) =>{

            const changeType = () =>{
                if("response" in response){
                    response = {
                        embed:{

                        }
                    }
                }else{
                    response = {
                        response:""
                    }
                }
                var responses = [...this.props.data.responses];
                responses[index] = response;
                this.props.update("responses",responses);
                
            }

            const updateResponse = (key,value) =>{
                var responses = [...this.props.data.responses];
                response[key] = value;
                responses[index] = response;
                this.props.update("responses",responses);
            }

            const deleteResponse = () =>{
                var responses = [...this.props.data.responses];
                if(responses.length >=2){
                    responses.splice(index,1);
                    this.props.update("responses",responses);
                }


            }
            responses.push(
                <div style={{ width: "100%", marginRight: "20px" }}>
                    <div className="justify-space-between">
                        <h3>{`Response ${index+1}`}</h3>
                        {/* <div className="section-content-header">Create an announcement when someone joins your server.</div> */}

                        <div className="section-content-title justify-space-between">
                            <button disabled={this.props.data.responses.length <=1} type="button" className="btn btn-red" onClick={(e) =>{deleteResponse()}} style={{marginRight:"15px",padding:"5px",fontSize:"15px",fontWeight:"800"}}>Delete Response</button>
                            <span style={{ marginInlineEnd: "12px", marginBottom: "0px" }}>{response.embed != undefined ? "Embed" : "Plain Text"}</span>
                            <Toggle value={response.response != undefined ? false:true} update={(value) => {changeType() }} type="type"></Toggle>
                        </div>
                    </div>

                    {'response' in response ? <PlainText_Action options={this.props.options} data={response} update={updateResponse}/>:<Embed_Action options={this.props.options} data={response} update={updateResponse}/>}
                    <hr className="slashcommand-hr"></hr>

                    {/* {this.state.role_message_type ? <Embed value={this.state.embed} update={this.embedUpdate}></Embed> : <TextArea variableEditor={true} value={this.state.response} onChange={this.responseOnChange}></TextArea>} */}
                </div>
            )
        })

        return responses;
    }
    
    render() {
        if(this.props.data.responses != undefined){
            return (
                <div className="mb-15">
                    <h3 style={{ marginBottom: "0px" }}>Responses </h3>
                    <div className="section-content-header mb-15">Click the add response button to add a response to the random response list. The bot will pick a response randomly to respond with.</div>                
    
                    <div>
                        {this.renderResponses()}
                    </div>
                        <button className="btn btn-gray" type="button" onClick={(e) =>{this.addResponse()}}>Add Response</button>
    
                        <hr className="slashcommand-hr"></hr>
    
    
                    {/* <TextArea variableEditor={true} slash={true} slash_options ={this.props.options} required={true} value={this.props.data.response} onChange={(value) =>{this.props.update("response",value)}}/> */}
            </div>
            )
        }else{
            return null
        }
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Random_Response_Action)
