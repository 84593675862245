import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../../../../api/server';
import Toggle from '../../elements/Toggle';
import DocsLink from '../../elements/DocsLink';
import LongInput from '../../elements/LongInput';





export class ModalBuilder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: "params",
            loaded: false,
            loading: false,
            variable: "",
            api_data: {
                response: { test: "dog" },
                status: "",
                statusText: "",
            }
        };
    }

    componentDidMount() {
        this.setState({ variable: `{${this.props.name}}` });

        // navigator.clipboard.readText()
        //     .then(text =>{
        //         console.log(text)
        //     })
        //     .catch((e) =>{
        //         console.log(e)
        //     })
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log("Updating data");
    }

    testRequest = async () => {

        this.setState({ loaded: false, loading: true });
        var response = await server.post("/dashboard/test_api_action", {
            request_options: this.props.request_options,
            token: this.props.token
        });

        if (response && response.data && response.data.success == true) {
            var variable = `{${this.props.name}}`;
            if ("response" in response.data.api_data) {
                variable = `{${this.props.name}.response.${Object.keys(response.data.api_data.response)[0]}}`;
            }
            this.setState({
                api_data: response.data.api_data,
                loaded: true,
                loading: false,
                variable: variable
            });
        }



        // if (response && response.data) {
        //     var api_data = {
        //         response: response.data,
        //         status: response.status,
        //         statusText: response.statusText,
        //     };
        //     this.setState({ api_data: api_data });
        // }
    };

    modalBackgroundClick = (e) => {
        // console.log("MODAL BACKGROUND CLICK");
        if (e.currentTarget == e.target) {
            this.props.closeModal(e);
        }
    };

    import = async () => {
        this.setState({ disabled: true, error: "" });
        const response = await server.post("/dashboard/importcommand", { id: this.state.code });
        // console.log(response, 'response');
        if (response.data == false || response.data.command == undefined) {
            // ERROR
            this.setState({ disabled: false, error: "Could not find a command with that share code." });
        } else {
            this.props.importCommand(response.data.command);
        }
    };

    nameChange = (value) => {



        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "-");
        // value = value.replace(/[^\w]+/gi,"")
        // value = value.replace(/[^[A-zÀ-Ÿ\d-]+/gi,"")
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        return value;
    };


    renderOptions = (mode) => {
        var array = mode == "headers" ? this.props.request_options.headers : mode == 'params' ? this.props.request_options.params : this.props.request_options.body;
        var options = [];
        array.forEach((option, index) => {
            options.push(
                <div className='requestbuilder-option mb-15'>
                    <div className="long-input" style={{ width: "50%", paddingRight: "5px" }}>
                        <label>Key</label>
                        <input placeholder="key" required type="text" value={option.key} onChange={(e) => {
                            var request_options = { ...this.props.request_options };
                            request_options[mode][index].key = e.target.value;
                            this.props.updateData(request_options);
                        }}></input>
                    </div>

                    <div className="long-input " style={{ width: "50%", paddingLeft: "5px" }}>
                        <label>Value</label>
                        <input placeholder="value" required type="text" value={option.value} onChange={(e) => {
                            var request_options = { ...this.props.request_options };
                            request_options[mode][index].value = e.target.value;
                            this.props.updateData(request_options);
                        }}></input>
                    </div>

                    <div style={{ marginLeft: "5px" }}>
                        <button className='btn btn-secondary' onClick={() => {
                            var request_options = { ...this.props.request_options };
                            request_options[mode].splice(index, 1);
                            this.props.updateData(request_options);
                        }}><FontAwesomeIcon icon={faTimes} /></button>
                    </div>
                </div>
            );
        });

        return options;
    };


    renderResult = () => {
        // if (!this.state.api_data || this.props.name == "" || this.state.variable == "") return null;
        // var data = {
        //     [this.props.name]: this.state.api_data
        // };
        // var variable = this.state.variable;
        // var value = _.get(data, variable.slice(1, -1));
        // if (value && typeof value == "object") {
        //     return '[object Object]';
        // } else if (!value && value != false) {
        //     return 'undefined';
        // } else {
        //     console.log(value, 'VALUE');
        //     return `${value}`;
        // }
        // // console.log(JSON.stringify(value), value.type == 'object', 'RESULT VALUE');
        // // if (value && value.type == 'object') {
        // //     return JSON.stringify(value);
        // // } else if (!value) {
        // //     return 'undefined';
        // // } else {
        // //     return value;
        // // }

        // // return value;
    };

    renderInputs = () => {

        var inputs = [];
        this.props.modal_settings.components.forEach((component, index) => {

            // label: "Why is this so difficult",
            // variable: "blank",
            // maxLength: 25,
            // minLength: 1,
            // placeholder: "Please help me this is not fun",
            // required: false,
            // inputStyle: "SHORT",
            // defaultValue: "Default Value"
            var changeValue = (value, key) => {
                var modal_settings = { ...this.props.modal_settings };
                modal_settings.components[index][key] = value;
                this.props.updateData(modal_settings);
            };


            inputs.push(
                <div className="mb-3">
                    <div>
                        <div className='mb-2'>
                            <h3 className='font-semibold text-lg'>Field {index + 1} {`${component.label != "" ? `(${component.label})` : ""}`} - <span className='text-red'>{`{${this.props.modal_id}.${component.variable}}`}</span></h3>
                            <p className='text-red font-semibold'>{this.validateVariableNames()}</p>
                        </div>
                        <div className='grid grid-cols-2 gap-x-2'>
                            <LongInput
                                inputRequired={true}
                                title="Input Label"
                                docs="https://docs.botghost.com/custom-commands-and-events/actions/send-a-form#input-label"
                                description="The label or text of this field."
                                maxLength={45}
                                value={component.label}
                                change={value => {
                                    changeValue(value, 'label');
                                }}
                            />

                            <LongInput
                                title="Placeholder"
                                description="Optional placeholder text for the field"
                                value={component.placeholder}
                                maxLength={100}
                                docs="https://docs.botghost.com/custom-commands-and-events/actions/send-a-form#placeholder"
                                change={value => {
                                    changeValue(value, 'placeholder');
                                }}
                            />

                            <LongInput
                                title="Minimum Length"
                                docs="https://docs.botghost.com/custom-commands-and-events/actions/send-a-form#minimum-length"
                                description="The minimum length the input can be."
                                value={component.minLength}
                                type="text"

                                change={value => {

                                    changeValue(value, 'minLength');
                                }}
                            />

                            <LongInput
                                title="Maximum Length"
                                docs="https://docs.botghost.com/custom-commands-and-events/actions/send-a-form#maximum-length"
                                description="The maximum length the input can be."
                                value={component.maxLength}
                                type="text"
                                // maxLength={4000}

                                change={value => {

                                    changeValue(value, 'maxLength');
                                }}
                            />

                            <div className='mb-15'>
                                <h3>Input Type <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-form#input-style" /></h3>
                                <p>The text input type for this field</p>
                                <select value={component.inputStyle} onChange={(e) => {
                                    changeValue(e.target.value, 'inputStyle');
                                }}>
                                    <option value="SHORT">Short</option>
                                    <option value="PARAGRAPH">Long</option>
                                </select>
                            </div>

                            <div className='mb-15'>
                                <h3>Required <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-form#required" /></h3>
                                <p>Should this field be required?</p>
                                <select value={component.required} onChange={(e) => {
                                    if (e.target.value == 'true')
                                        changeValue(true, 'required');
                                    else
                                        changeValue(false, 'required');
                                }}>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>

                            <LongInput
                                title="Default Value"
                                docs="https://docs.botghost.com/custom-commands-and-events/actions/send-a-form#default-value"
                                description="The default value of this field"
                                value={component.defaultValue}
                                maxLength={4000}
                                change={value => {
                                    changeValue(value, 'defaultValue');
                                }}
                            />

                            <LongInput
                                title="Variable Name"
                                docs="https://docs.botghost.com/custom-commands-and-events/actions/send-a-form#variable-name"
                                description="How this field will be referenced"
                                value={component.variable}
                                maxLength={25}
                                inputRequired={true}
                                change={value => {
                                    var cleanValue = this.nameChange(value);

                                    changeValue(cleanValue, 'variable');
                                }}
                            />


                        </div>
                        <button disabled={this.props.modal_settings.components.length == 1} className='btn btn-red ml-auto' onClick={() => {
                            if (this.props.modal_settings.components.length == 1) return;
                            var modal_settings = { ...this.props.modal_settings };
                            modal_settings.components.splice(index, 1);
                            this.props.updateData(modal_settings);
                        }}>Delete Field</button>
                    </div>
                    <hr className='bg-[#fff] opacity-50'></hr>
                </div>
            );
        });

        return inputs;
    };

    renderModalFields = () => {
        var fields = [];

        this.props.modal_settings.components.forEach(component => {
            if (component.inputStyle == "SHORT") {
                fields.push(
                    <div className="flex flex-col mb-3">
                        <span className="uppercase font-bold text-[14px] mb-1 text-[#b8b9bf]">{component.label} {component.required ? <span className="text-red">*</span> : null}</span>
                        <input placeholder={component.placeholder} className="h-[40px] p-[10px] rounded-sm bg-[#1e1f22] border-none" value={component.defaultValue || ""} type="text" />
                    </div>
                );
            } else {
                fields.push(
                    <div className="flex flex-col mb-3">
                        <span className="uppercase font-bold text-[14px] mb-1 text-[#b8b9bf]">{component.label}  {component.required ? <span className="text-red">*</span> : null}</span>
                        <textarea placeholder={component.placeholder} value={component.defaultValue || ""} className="p-[10px] rounded-sm !bg-[#1e1f22] border-none" style={{ backgroundColor: "#1e1f22" }} />
                        {/* <input className="h-[40px] p-[10px] rounded-sm bg-[#1e1f22] border-none" value="Default Value" type="text" /> */}
                    </div>
                );
            }
        });

        return fields;
    };


    validateVariableNames = () => {
        var variables = [];
        var error = null;
        this.props.modal_settings.components.forEach(component => {
            if (variables.includes(component.variable)) {
                error = "Field variable names must be unique!";
            }
            variables.push(component.variable);
        });

        return error;
    };


    render() {
        return (
            <div className="h-full flex flex-col" style={{ width: "100%" }}>
                <div className=" text-center h-[100px]">
                    <h2 className='text-3xl font-bold'>Form Builder<DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-form#form-builder" /></h2>
                    <p>Build the form by adding inputs.</p>
                    <span style={{ color: "red", fontWeight: "800" }}>{this.state.error}</span>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-2 h-full flex flex-col'>

                    <div className='h-full flex flex-col overflow-y-scroll'>
                        <h3 className="text-xl font-bold text-center mb-2">Options</h3>

                        <div className='p-3 mb-3 h-full overflow-y-scroll'>
                            <div className='mb-3'>
                                <LongInput
                                    title="Form Title"
                                    description="The title of the form."
                                    docs="https://docs.botghost.com/custom-commands-and-events/actions/send-a-form#form-title"
                                    inputRequired={true}
                                    value={this.props.modal_settings.title}
                                    change={value => {
                                        var modal_settings = { ...this.props.modal_settings };
                                        modal_settings.title = value;
                                        this.props.updateData(modal_settings);
                                    }}
                                />
                            </div>
                            <hr className='bg-[#fff] opacity-50'></hr>
                            <div className='flex'>
                                <div>
                                    <h3>Form Fields <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-form#adding-form-fields" /></h3>
                                    <p className='mb-0'>Add input fields to the form.</p>
                                </div>

                                <div className='ml-auto'>
                                    <button disabled={this.props.modal_settings.components.length >= 5} className='btn btn-red' onClick={() => {
                                        var modal_settings = { ...this.props.modal_settings };
                                        modal_settings.components.push({
                                            label: "Input Label",
                                            variable: "",
                                            maxLength: "",
                                            minLength: "",
                                            placeholder: "",
                                            required: false,
                                            inputStyle: "SHORT",
                                            defaultValue: ""
                                        });
                                        this.props.updateData(modal_settings);
                                    }}>Add Field</button>
                                </div>
                            </div>
                            <hr className='bg-[#fff] opacity-50'></hr>
                            {this.renderInputs()}

                        </div>
                    </div>


                    <div className='ml-4 order-first mb-4 lg:mb-0 lg:order-last'>
                        <h3 className="text-xl font-bold mb-3 text-center">Preview</h3>

                        <div className="bg-[#313338] p-4 overflow-y-scroll max-h-[600px]">
                            <div className="flex mb-3">
                                <img className="h-[24px] w-[24px] rounded-full mr-2" src={this.props.bot.img != null && this.props.bot.img != undefined ? this.props.bot.img : "https://cdn.discordapp.com/embed/avatars/1.png"}></img>
                                <h3 className="text-[24px]">{this.props.modal_settings.title || ""}</h3>
                            </div>


                            <div>

                                {this.renderModalFields()}


                            </div>





                        </div>
                        <div className="bg-[#2b2d31] p-4 flex">

                            <div className='ml-auto'>
                                <a className='hover:text-[#fff] font-semibold hover:cursor-pointer'>Cancel</a>
                                <button className="ml-4 py-2 px-4 bg-[#5865f2] rounded-sm">Submit</button>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ModalBuilder);
