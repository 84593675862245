import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setElements, setHighlightAction } from '../../../../actions';
import { addEdge, getOutgoers } from 'react-flow-renderer';
import { EmojiAdder } from './EmojiAdder';
import splitElements from '../splitElements';
import TextArea from '../../elements/TextArea';


export class BreakLoop extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "break_loop",
                        validated: true
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "break_loop",
                        validated: true
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

        }
    }

    updateData = (key, value) => {

        // console.log("VALUE", value);
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    nameChange = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "_");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("reference", value);
    };

    addElements = () => {
        var elements = [...this.props.elements];
        var element = this.props.elements.find(element => element.id == this.props.selected);

        // Loop Actions
        var id = `loop_actions${elements.length + 1}_${s4()}_${s4()}`;
        elements.push(
            {
                id: id,
                type: 'loopChild',
                data: {
                    type: "loop_actions"
                },
                // target:"3",
                draggable: true,
                position: { x: element.position.x + 300, y: element.position.y + 100 },
            },
        );
        var newEdges = addEdge({
            source: element.id,
            target: id,
            type: "step",
            sourceHandle: "loop_actions",
            animated: false,
            arrowHeadType: "arrowclosed"

        }, splitElements(elements).edges);

        newEdges.forEach(newEdge => {
            var index = elements.findIndex(element => element.id == newEdge.id);
            if (index == -1) {
                elements.push(newEdge);
            }
        });

        // After Loop Actions
        var id = `loop_after${elements.length + 1}_${s4()}_${s4()}`;
        elements.push(
            {
                id: id,
                type: 'loopChild',
                data: {
                    type: "loop_after"
                },
                // target:"3",
                draggable: true,
                position: { x: element.position.x - 150, y: element.position.y + 100 },
            },
        );
        var newEdges = addEdge({
            source: element.id,
            target: id,
            type: "step",
            sourceHandle: "loop_after",
            animated: false,
            arrowHeadType: "arrowclosed"

        }, splitElements(elements).edges);

        newEdges.forEach(newEdge => {
            var index = elements.findIndex(element => element.id == newEdge.id);
            if (index == -1) {
                elements.push(newEdge);
            }
        });

        this.props.setElements(elements);

    };


    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Stop a Loop</h4>
                        <p>Use this action in tandem with conditions to conditionally break out of loops.</p>
                    </div>





                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
    updateElementData,
    setElements,
    setHighlightAction
};

export default connect(mapStateToProps, mapDispatchToProps)(BreakLoop);

let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
};