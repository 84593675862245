import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import { updateElementData, setHighlightAction } from "../../../../actions";
import Select from "../../elements/Select";
import { checkIntegrationChild } from "../eventUtils";
import VariableTextInput from "../VariableTextInput";

export class ThreadDeleteAction extends Component {
	DEFAULT_THREAD_OBJECT = {
		type: "thread_delete",
		subType: this.props.type,
		success_handles: false
	};

	componentDidMount() {
		if (this.props.data === undefined) {
			var target = {
				channelID: "",
				threadID: ""
			};
			if (this.props.components["Channel"]) {
				target = { self: true };
			}
			this.props.updateElementData({
				data: { ...this.DEFAULT_THREAD_OBJECT, target: target },
				id: this.props.id
			});
		}
	}

	componentDidUpdate() {
		if (this.props.data != undefined && this.props.data.success_handles == undefined) {
			this.updateData("success_handles", false);
		}
		if (this.props.data === undefined) {
			var target = {
				channelID: "",
				threadID: ""
			};
			if (this.props.components["Channel"]) {
				target = { self: true };
			}
			this.props.updateElementData({
				data: { ...this.DEFAULT_THREAD_OBJECT, target: target },
				id: this.props.id
			});
		} else {
			// console.log("here", this.props.data);
			// validate
			if (("channelID" in this.props.data.target && this.props.data.target.channelID && "threadID" in this.props.data.target && this.props.data.target.threadID) || "self" in this.props.data.target || ("variable" in this.props.data.target && this.props.data.target.variable && "threadID" in this.props.data.target && this.props.data.target.threadID)) {
				if (this.props.data.validated === false) {
					this.updateData("validated", true);
				}
			} else {
				if (this.props.data.validated === true) {
					this.updateData("validated", false);
				}
			}
		}
	}

	type = this.props.type === "thread" ? "thread" : "forum post";
	heading = this.props.type === "thread" ? "Thread" : "Forum Post";

	renderURL = (section) => {
		return `https://docs.botghost.com/custom-commands-and-events/actions/delete-a-thread#${section}`;
	};

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	renderTargetTypes = () => {
		var channel = "Thread the command was run in";
		if (this.props.mode == "event" && this.props.components["Channel"]) {
			channel = "The thread.";
		}
		const targetTypes = [
			{ label: channel, value: "self", disabled: this.props.components.Interaction || this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "Channel and Thread ID", value: "id" },
			{ label: "Channel Option and Thread ID", value: "variable", disabled: !this.props.components.Interaction }
		];

		return targetTypes;
	};

	renderOptions = () => {
		var options = [{ value: "", label: "" }];
		this.props.options.forEach((option) => {
			if (option.type === "channel") options.push({ value: `{option_${option.name}}`, label: option.name });
		});

		if (options.length === 1) {
			options = [{ value: ``, label: "Please add a channel option", disabled: true }];
		}

		return options;
	};

	targetOnChange = (value) => {
		if (value == "self") {
			var data = { ...this.props.data };
			data.target = {
				self: true
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		} else if (value == "id") {
			var data = { ...this.props.data };
			data.target = {
				channelID: "",
				threadID: ""
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		} else if (value == "variable") {
			var data = { ...this.props.data };
			data.target = {
				variable: "",
				threadID: ""
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		}
	};

	render() {
		if (this.props.data != undefined) {
			return (
				<>
					<div className="mb-15">
						<h4>Delete a {this.props.type === "thread" ? "Thread" : "Forum Post"}</h4>
					</div>

					{/* Targetting */}
					<div className="mb-15 slash-action">
						<h4>
							Channel <DocsLink location={this.renderURL("channel")} />
						</h4>
						<span>Which channel do you want to delete the {this.type} in.</span>
						<Select
							value={"self" in this.props.data.target ? "self" : "channelID" in this.props.data.target ? "id" : "variable" in this.props.data.target ? "variable" : ""}
							onChange={(value) => {
								this.targetOnChange(value);
							}}
							options={this.renderTargetTypes()}
						></Select>
					</div>

					{"channelID" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>
								Channel ID <DocsLink location={this.renderURL("channel-id")} />
							</h4>
							<span>The Channel ID that the {this.type} is in.</span>
							{/* <VariableTextInput slash_options={this.props.options} value={this.props.data.response_options.target.user_option} onChange={(value) => {
                                var response_options = { ...this.props.data.response_options };
                                response_options.target.user_option = value;
                                this.updateData("response_options", response_options);
                            }} /> */}

							<VariableTextInput
								placeholder="Channel"
								required={true}
								slash_options={this.props.options}
								label={"Channel Id or Variable"}
								value={this.props.data.target.channelID}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										...data.target,
										channelID: value
									};
									this.props.updateElementData({ data: data, id: this.props.id });
								}}
							/>

							{/* <div className="long-input mt-15">
                            <label>Channel ID</label>
                            <input className={this.props.saveFailed && this.props.data.target.variable == "" ? "command-required" : ""} onChange={(e) => {
                                var data = { ...this.props.data };
                                data.target = {
                                    ...data.target,
                                    channelID: e.target.value
                                };
                                this.props.updateElementData({ data: data, id: this.props.id });
                            }} type="text" placeholder="911634771019444224" value={this.props.data.target.channelID} />
                        </div> */}
						</div>
					)}

					{"variable" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>
								Channel Option <DocsLink location={this.renderURL("channel-option")} />
							</h4>
							<span>The channel option to delete the {this.type} in.</span>
							<Select
								saveFailed={(this.props.saveFailed && this.props.data.target.variable == "") || this.renderOptions().length === 1}
								value={this.props.data.target.variable}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										...data.target,
										variable: value
									};
									this.props.updateElementData({ data: data, id: this.props.id });
								}}
								options={this.renderOptions()}
							/>
						</div>
					)}

					{"threadID" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>
								{this.heading} ID <DocsLink location={this.renderURL("thread-id")} />
							</h4>
							<span>The {this.type} ID you want to delete.</span>
							<VariableTextInput
								placeholder={this.heading}
								required={true}
								slash_options={this.props.options}
								label={`${this.heading} ID`}
								value={this.props.data.target.threadID}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										...data.target,
										threadID: value
									};
									this.props.updateElementData({ data: data, id: this.props.id });
								}}
							/>

							{/* <div className="long-input mt-15">
                            <label>{this.heading} ID</label>
                            <input className={this.props.saveFailed && this.props.data.target.threadID == "" ? "command-required" : ""} onChange={(e) => {
                                var data = { ...this.props.data };
                                data.target = {
                                    ...data.target,
                                    threadID: e.target.value
                                };
                                this.props.updateElementData({ data: data, id: this.props.id });
                            }} type="text" placeholder="911634771019444224" value={this.props.data.target.threadID} />
                        </div> */}
						</div>
					)}
					{/* End of Targetting */}
				</>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	bot_data: state.data.bot_data,
	saveFailed: state.builder.saveFailed,
	beta: state.data.beta,
	components: state.builder.components
});
const mapDispatchToProps = {
	updateElementData,
	setHighlightAction
};

let s4 = () => {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
};

export default connect(mapStateToProps, mapDispatchToProps)(ThreadDeleteAction);
