import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";
import Select from "../../elements/Select";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { ThreadAutoArchiveDuration } from "discord-api-types/v10";
import { checkIntegrationChild } from "../eventUtils";
import VariableTextInput from "../VariableTextInput";

const AUTO_ARCHIVE_DURATION = [
	{ label: "", value: "" },
	{ label: "One Hour", value: ThreadAutoArchiveDuration.OneHour },
	{ label: "One Day", value: ThreadAutoArchiveDuration.OneDay },
	{ label: "Three Days", value: ThreadAutoArchiveDuration.ThreeDays },
	{ label: "One Week", value: ThreadAutoArchiveDuration.OneWeek }
];

export class ThreadEditAction extends Component {
	DEFAULT_THREAD_OBJECT = {
		type: "thread_edit",
		name: "",
		autoArchiveDuration: "",
		rateLimitPerUser: 0,
		invitable: false,
		validated: false,
		locked: false,
		archived: false,
		success_handles: false,
		subType: this.props.type
		// variable: `edited_thread_${s4()}`,
	};

	componentDidMount() {
		if (this.props.data === undefined) {
			var target = {
				channelID: "",
				threadID: ""
			};
			if (this.props.components["Channel"]) {
				target = { self: true };
			}
			this.props.updateElementData({
				data: { ...this.DEFAULT_THREAD_OBJECT, target: target, variable: `edited_thread_${s4()}` },
				id: this.props.id
			});
		}
	}

	componentDidUpdate() {
		if (this.props.data != undefined && this.props.data.success_handles == undefined) {
			this.updateData("success_handles", false);
		}
		if (this.props.data === undefined) {
			var target = {
				channelID: "",
				threadID: ""
			};
			if (this.props.components["Channel"]) {
				target = { self: true };
			}
			this.props.updateElementData({
				data: { ...this.DEFAULT_THREAD_OBJECT, target: target, variable: `edited_thread_${s4()}` },
				id: this.props.id
			});
		} else {
			if (!this.props.data.variable) {
				this.updateData("variable", `edited_thread_${s4()}`);
			}

			// console.log("here", this.props.data);
			// validate
			if (("channelID" in this.props.data.target && this.props.data.target.channelID && "threadID" in this.props.data.target && this.props.data.target.threadID) || "self" in this.props.data.target || ("variable" in this.props.data.target && this.props.data.target.variable && "threadID" in this.props.data.target && this.props.data.target.threadID)) {
				if (this.props.data.validated === false) {
					this.updateData("validated", true);
				}
			} else {
				if (this.props.data.validated === true) {
					this.updateData("validated", false);
				}
			}
		}
	}

	type = this.props.type === "thread" ? "thread" : "forum post";
	heading = this.props.type === "thread" ? "Thread" : "Forum Post";

	renderURL = (section) => {
		return `https://docs.botghost.com/custom-commands-and-events/actions/edit-a-thread#${section}`;
	};

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	renderOptions = () => {
		var options = [{ value: "", label: "" }];
		this.props.options.forEach((option) => {
			if (option.type === "channel") options.push({ value: `{option_${option.name}}`, label: option.name });
		});

		if (options.length === 1) {
			options = [{ value: ``, label: "Please add a channel option", disabled: true }];
		}

		return options;
	};

	renderTargetTypes = () => {
		var channel = "Thread the command was run in";
		if (this.props.mode == "event" && this.props.components["Channel"]) {
			channel = "The thread.";
		}
		const targetTypes = [
			{ label: channel, value: "self", disabled: this.props.components.Interaction || this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "Channel and Thread ID", value: "id" },
			{ label: "Channel Option and Thread ID", value: "variable", disabled: !this.props.components.Interaction }
		];
		return targetTypes;
	};

	targetOnChange = (value) => {
		if (value == "self") {
			var data = { ...this.props.data };
			data.target = {
				self: true
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		} else if (value == "id") {
			var data = { ...this.props.data };
			data.target = {
				channelID: "",
				threadID: ""
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		} else if (value == "variable") {
			var data = { ...this.props.data };
			data.target = {
				variable: "",
				threadID: ""
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		}
	};

	render() {
		if (this.props.data != undefined) {
			return (
				<>
					<div className="mb-15">
						<h4>
							Edit a {this.props.type === "thread" ? "Thread" : "Forum Post"} <DocsLink location={this.renderURL("")} />
						</h4>
						<p>
							You can use the{" "}
							<strong style={{ color: "#f45142" }}>
								{`{${this.props.data.variable}}`}{" "}
								<FontAwesomeIcon
									icon={faClipboard}
									fixedWidth
									className="text-[#9d9b9b] text-sm hover:text-red hover:cursor-pointer"
									onClick={() => {
										navigator.clipboard.writeText(`{${this.props.data.variable}}`);
										toast.success("Copied variable to clipboard");
									}}
								/>
							</strong>{" "}
							variable in other actions and conditions.
						</p>
					</div>

					{/* Targetting */}
					<div className="mb-15 slash-action">
						<h4>
							Channel <DocsLink location={this.renderURL("thread")} />
						</h4>
						<span>Which channel is the {this.type} located.</span>
						<Select
							value={"self" in this.props.data.target ? "self" : "channelID" in this.props.data.target ? "id" : "variable" in this.props.data.target ? "variable" : ""}
							onChange={(value) => {
								this.targetOnChange(value);
							}}
							options={this.renderTargetTypes()}
						></Select>
					</div>

					{"channelID" in this.props.data.target && "threadID" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>
								Channel ID <DocsLink location={this.renderURL("channel-id")} />
							</h4>
							<span>The Channel ID that the {this.type} is in.</span>
							<VariableTextInput
								required={true}
								slash_options={this.props.options}
								value={this.props.data.target.channelID}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										...data.target,
										channelID: value
									};
									this.props.updateElementData({ data: data, id: this.props.id });
								}}
							/>
							{/* <div className="long-input mt-15">
                            <label>Channel ID</label>
                            <input className={this.props.saveFailed && this.props.data.target.variable == "" ? "command-required" : ""} onChange={(e) => {
                                var data = { ...this.props.data };
                                data.target = {
                                    ...data.target,
                                    channelID: e.target.value
                                };
                                this.props.updateElementData({ data: data, id: this.props.id });
                            }} type="text" placeholder="911634771019444224" value={this.props.data.target.channelID} />
                        </div> */}
						</div>
					)}

					{"variable" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>
								Channel Option <DocsLink location={this.renderURL("channel-option")} />
							</h4>
							<span>The channel option to create the {this.type} in.</span>
							<Select
								saveFailed={(this.props.saveFailed && this.props.data.target.variable == "") || this.renderOptions().length === 1}
								value={this.props.data.target.variable}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										...data.target,
										variable: value
									};
									this.props.updateElementData({ data: data, id: this.props.id });
								}}
								options={this.renderOptions()}
							/>
						</div>
					)}

					{"threadID" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>
								{this.heading} ID <DocsLink location={this.renderURL("channel-id")} />
							</h4>
							<span>The {this.type} ID you want to edit.</span>
							<VariableTextInput
								required={true}
								slash_options={this.props.options}
								value={this.props.data.target.threadID}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										...data.target,
										threadID: value
									};
									this.props.updateElementData({ data: data, id: this.props.id });
								}}
							/>
							{/* <div className="long-input mt-15">
                            <label>{this.heading} ID</label>
                            <input className={this.props.saveFailed && this.props.data.target.variable == "" ? "command-required" : ""} onChange={(e) => {
                                var data = { ...this.props.data };
                                data.target = {
                                    ...data.target,
                                    threadID: e.target.value
                                };
                                this.props.updateElementData({ data: data, id: this.props.id });
                            }} type="text" placeholder="911634771019444224" value={this.props.data.target.threadID} />
                        </div> */}
						</div>
					)}

					{/* End of Targetting */}

					<div className="mb-15 slash-action">
						<h4>
							Name <DocsLink location={this.renderURL("name")} />
						</h4>
						<span>An optional name for the {this.type}.</span>
						<VariableTextInput
							slash_options={this.props.options}
							value={this.props.data.name}
							onChange={(value) => {
								this.updateData("name", value);
							}}
						/>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Auto Archive <DocsLink location={this.renderURL("auto-archive")} />
						</h4>
						<span>An optional auto archive duration for the {this.type}.</span>
						<div className="mt-15">
							<Select
								value={this.props.data.autoArchiveDuration}
								options={AUTO_ARCHIVE_DURATION}
								onChange={(value) => {
									this.updateData("autoArchiveDuration", value);
								}}
							/>
						</div>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Slowmode <DocsLink location={this.renderURL("slowmode")} />
						</h4>
						<span>An optional rate limit (in seconds) that you want to set the created {this.type} to.</span>
						<VariableTextInput
							required={true}
							slash_options={this.props.options}
							value={this.props.data.rateLimitPerUser}
							onChange={(value) => {
								this.updateData("rateLimitPerUser", value);
							}}
						/>
						{/* <div class="long-input mt-15">
                            <label>Slowmode</label>
                            <input onChange={(e) => {
                                this.updateData("rateLimitPerUser", e.target.value);
                            }} type="number" min={0} max={21600} value={this.props.data.rateLimitPerUser} placeholder={3} />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Moderators Invite <DocsLink location={this.renderURL("invitable")} />
						</h4>
						<span>
							An optional option, whether non-moderators can invite other users to the {this.type}. <span style={{ color: "yellow" }}>[This only works for Private Threads]</span>
						</span>
						<Toggle
							value={this.props.data.invitable}
							update={(value) => {
								this.updateData("invitable", value);
							}}
						/>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Locked <DocsLink location={this.renderURL("locked")} />
						</h4>
						<span>An optional option, whether you want the {this.type} to be locked, which means that only moderators can send messaged. </span>
						<Toggle
							value={this.props.data.locked}
							update={(value) => {
								this.updateData("locked", value);
							}}
						/>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Archived <DocsLink location={this.renderURL("archived")} />
						</h4>
						<span>An optional option, whether you want the {this.type} to be archived.</span>
						<Toggle
							value={this.props.data.archived}
							update={(value) => {
								this.updateData("archived", value);
							}}
						/>
					</div>
				</>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	bot_data: state.data.bot_data,
	saveFailed: state.builder.saveFailed,
	beta: state.data.beta,
	components: state.builder.components
});
const mapDispatchToProps = {
	updateElementData
};

let s4 = () => {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
};

export default connect(mapStateToProps, mapDispatchToProps)(ThreadEditAction);
