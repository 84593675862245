import React, { Component } from 'react';
import { connect } from 'react-redux';


import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import ReactionRoleEditor from "../elements/ReactionRoleEditor";

import { setBotModule } from "../../../actions";
import ReactionRole from '../elements/ReactionRole';

import PremiumRedirect from '../elements/PremiumRedirect';

import Dashboard from "../Dashboard";
import PremiumSection from '../elements/PremiumSection';


export class ReactionRoles extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    reactonRoleSave = (state) => {

        var reaction_roles = { ...this.props.reaction_roles };
        var roles = [];
        state.activeRoles.forEach(activeRole => {
            roles.push({
                name: activeRole.roles[0].name,
                role_id: activeRole.roles[0].id,
                emoji: activeRole.emoji_name
            });
        });


        reaction_roles.roles.push({
            name: state.name,
            guild_id: state.guild_id,
            channel_id: state.channel.id,
            embed: state.embed,
            react_type: state.react_role_config,
            roles: roles,
            config: state
        });

        this.props.setBotModule({
            module: "reaction_roles",
            module_data: reaction_roles
        });

    };

    renderReactionRoles = () => {
        var reactionRoles = [];

        this.props.reaction_roles.roles.forEach((role, index) => {
            reactionRoles.push(<ReactionRole
                value={role}
                index={index}
            ></ReactionRole>);
        });
        return reactionRoles;
    };

    moduleEnable = (value) => {
        const reaction_roles = { ...this.props.reaction_roles };
        reaction_roles.enabled = value;

        this.props.setBotModule({
            module: "reaction_roles",
            module_data: reaction_roles
        });

    };

    render() {
        return (
            <Dashboard>
                <PremiumRedirect></PremiumRedirect>
                <ModuleHeader tutorial="https://docs.botghost.com/server-management/reaction-roles" enabledValue={this.props.reaction_roles.enabled} change={this.moduleEnable} premiumRequired={true} title="Reaction Roles" docs="https://docs.botghost.com/server-management/reaction-roles" description="Setup a Reaction Role system" icon="reactroles.png"></ModuleHeader>

                <PremiumSection>
                    <SectionHeader title="New Reaction Role" pretitle="Reaction Roles" ></SectionHeader>

                    <ReactionRoleEditor save={this.reactonRoleSave}></ReactionRoleEditor>
                </PremiumSection>

                <PremiumSection>
                    <SectionHeader title="Active Reaction Roles" pretitle="Reaction Roles" ></SectionHeader>

                    <div>
                        {this.renderReactionRoles()}
                    </div>
                </PremiumSection>
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    reaction_roles: state.data.bot.commands.reaction_roles
});

const mapDispatchToProps = {
    setBotModule
};

export default connect(mapStateToProps, mapDispatchToProps)(ReactionRoles);
