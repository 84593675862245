import React, { Component } from "react";
import { connect } from "react-redux";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";
import RefreshBotData from "../../elements/RefreshBotData";
import Select from "../../elements/Select";
import RoleAdd from "../../elements/RoleAdd";
import DocsLink from "../../elements/DocsLink";
import { checkIntegrationChild } from "../eventUtils";
import VariableTextInput from "../VariableTextInput";

export class CreateServerInvite extends Component {
	componentDidMount() {
		if (!this.props.data) {
			var target = { id: "" };
			if (this.props.components["Channel"]) {
				target = { self: true };
			}
			this.props.updateElementData({
				data: {
					type: "create_invite",
					temporary: false,
					maxAge: "0",
					maxUses: "1",
					unique: true,
					variableName: "invite",
					guild_id: "",
					target: target,
					success_handles: false,
					validated: true
				},
				id: this.props.id
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data != undefined && this.props.data.success_handles == undefined) {
			this.updateData("success_handles", false);
		}
		if (!this.props.data) {
			this.props.updateElementData({
				data: {
					type: "create_invite",
					temporary: false,
					maxAge: 0,
					maxUses: 1,
					unique: true,
					variableName: "invite",
					guild_id: "",
					target: {
						self: true
					},
					validated: true
				},
				id: this.props.id
			});
		} else {
			if (this.props.data.target && "maxAge" in this.props.data && this.props.data.maxAge != "" && "maxUses" in this.props.data && this.props.data.maxUses != "") {
				if (!this.props.data.validated) {
					this.updateData("validated", true);
				}
			} else {
				if (this.props.data.validated) {
					this.updateData("validated", false);
				}
			}
		}
	}

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({ data: data, id: this.props.id });
	};

	selectUpdate = (value) => {
		value = JSON.parse(value);
		var target = { ...this.props.data.target };
		target = value;
		this.updateData("target", target);
	};

	renderRoleOptions = () => {
		var options = [];
		options.push({ value: JSON.stringify({ id: "" }), label: "" });
		this.props.options.forEach((option) => {
			if (option.type == "role") {
				options.push({ value: JSON.stringify({ variable: option.name }), label: `Role Option: ${option.name}` });
			}
		});
		if (this.props.bot_data || this.props.bot_data.length > 0) {
			this.props.bot_data.forEach((guild, index) => {
				guild.roles.forEach((role) => {
					options.push({ value: JSON.stringify(role), label: `${role.name} - ${guild.name}` });
				});
			});
		}
		return options;
	};

	renderTargetTypes = () => {
		var channel = "Channel the command was run in";
		if (this.props.mode == "event" && this.props.components["Channel"]) {
			channel = "The channel.";
		}
		const targetTypes = [
			{ label: channel, value: "self", disabled: this.props.components.Interaction || this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "Channel option", value: "variable", disabled: !this.props.components.Interaction },
			{ label: "Channel ID", value: "id" }
		];
		return targetTypes;
	};

	targetOnChange = (value) => {
		if (value == "self") {
			var data = { ...this.props.data };
			data.target = {
				self: true
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		} else if (value == "variable") {
			var data = { ...this.props.data };
			data.target = {
				variable: ""
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		} else if (value == "id") {
			var data = { ...this.props.data };
			data.target = {
				id: ""
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		}
	};

	render() {
		if (this.props.data) {
			return (
				<div>
					<div className="mb-15">
						<h4>Create Server Invite</h4>
						<p>Create an invite for the server.</p>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Channel <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/server-actions/create-server-invite" />
						</h4>
						<span>Which channel do you want to create the invite for?</span>
						<Select
							value={"self" in this.props.data.target ? "self" : "variable" in this.props.data.target ? "variable" : "id" in this.props.data.target ? "id" : ""}
							onChange={(value) => {
								this.targetOnChange(value);
							}}
							options={this.renderTargetTypes()}
						></Select>
					</div>

					{"variable" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>
								Channel Option <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/server-actions/create-server-invite-option" />
							</h4>
							<span>The channel option to create the invite for</span>
							<VariableTextInput
								slash_options={this.props.options}
								required={true}
								label="Option"
								placeholder="{option_channel}"
								value={this.props.data.target.variable}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										variable: value
									};
									this.props.updateElementData({ data: data, id: this.props.id });
								}}
							/>
							{/* <div className="long-input mt-15">
                            <label>Option</label>
                            <input className={this.props.saveFailed && this.props.data.target.variable == "" ? "command-required" : ""} onChange={(e) => {
                                var data = { ...this.props.data };
                                data.target = {
                                    variable: e.target.value
                                };
                                this.props.updateElementData({ data: data, id: this.props.id });
                            }} type="text" placeholder="{option_channel}" value={this.props.data.target.variable} />
                        </div> */}
						</div>
					)}

					{"id" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>
								Channel ID <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/server-actions/create-server-invite-id" />
							</h4>
							<span>The channel ID you want to create the invite for</span>
							<VariableTextInput
								slash_options={this.props.options}
								required={true}
								label="ID"
								placeholder="911634771019444224"
								value={this.props.data.target.id}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										id: value
									};
									this.props.updateElementData({ data: data, id: this.props.id });
								}}
							/>
							{/* <div className="long-input mt-15">
                            <label>ID</label>
                            <input className={this.props.saveFailed && this.props.data.target.variable == "" ? "command-required" : ""} onChange={(e) => {
                                var data = { ...this.props.data };
                                data.target = {
                                    id: e.target.value
                                };
                                this.props.updateElementData({ data: data, id: this.props.id });
                            }} type="text" placeholder="911634771019444224" value={this.props.data.target.id} />
                        </div> */}
						</div>
					)}

					<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>

					<div className="mb-15 slash-action">
						<h4>
							Invite Duration <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/server-actions/create-server-invite" />
						</h4>
						<span>How long the invite should last (in seconds, 0 for forever)</span>
						<VariableTextInput
							slash_options={this.props.options}
							required={true}
							label="Duration"
							placeholder="500"
							value={this.props.data.maxAge}
							onChange={(value) => {
								this.updateData("maxAge", value);
							}}
						/>
						{/* <div class="long-input mt-15">
                            <label>Duration</label>
                            <input className={``} onChange={(e) => {
                                this.updateData("maxAge", e.target.value);
                            }} type="text" value={this.props.data.maxAge} placeholder="500" />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Max Uses <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/server-actions/create-server-invite" />
						</h4>
						<span>Maximum number of uses for the created invite (0 for infinite)</span>
						<VariableTextInput
							slash_options={this.props.options}
							required={true}
							label="Uses"
							placeholder="1"
							value={this.props.data.maxUses}
							onChange={(value) => {
								this.updateData("maxUses", value);
							}}
						/>

						{/* <div class="long-input mt-15">
                            <label>Uses</label>
                            <input className={``} onChange={(e) => {
                                this.updateData("maxUses", e.target.value);
                            }} type="text" value={this.props.data.maxUses} placeholder="1" />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Temporary <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/server-actions/create-server-invite" />
						</h4>
						<span className="mb-15">Whether members that joined via the invite should be automatically kicked after 24 hours if they have not yet received a role</span>
						<Select
							value={this.props.data.temporary}
							onChange={(value) => {
								if (value == "true") {
									value = true;
								} else if (value == "false") {
									value = false;
								}
								this.updateData("temporary", value);
							}}
							options={[
								{ label: "Temporary users", value: true },
								{ label: "Non temporary users", value: false }
							]}
						></Select>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Unique Invite <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/server-actions/create-server-invite" />
						</h4>
						<span className="mb-15">Create a unique invite, or use an existing one with similar settings</span>
						<Select
							value={this.props.data.unique}
							onChange={(value) => {
								if (value == "true") {
									value = true;
								} else if (value == "false") {
									value = false;
								}
								this.updateData("unique", value);
							}}
							options={[
								{ label: "Unique Invite", value: true },
								{ label: "Use an existing invite", value: false }
							]}
						></Select>
					</div>

					<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>

					<div className="mb-15 slash-action">
						<h4>
							Optional Server ID <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/server-actions/create-server-invite" />
						</h4>
						<span>An optional server Id to search for the channel in. An Id or Variable can be used. Leave this blank to search for the channel in the server this {this.props.mode == "event" ? "event" : "command"} was triggered in.</span>
						<VariableTextInput
							slash_options={this.props.options}
							required={true}
							label="ID or Variable"
							placeholder="822426820447567872"
							value={this.props.data.guild_id}
							onChange={(value) => {
								this.updateData("guild_id", value);
							}}
						/>
						{/* <div class="long-input mt-15">
                            <label>ID or Variable</label>
                            <input onChange={(e) => {
                                this.updateData("guild_id", e.target.value);
                            }} maxLength={250} type="text" value={this.props.data.guild_id} placeholder="822426820447567872" />
                        </div> */}
					</div>

					<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>

					<div className="mb-15 slash-action">
						<h4>
							Invite Variable <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/server-actions/create-server-invite" />{" "}
						</h4>
						<span>An optional variable to use to return the invite URL after it has been created. </span>
						<VariableTextInput
							slash_options={this.props.options}
							required={true}
							label="Variable"
							placeholder="{invite_url}"
							value={this.props.data.variable}
							onChange={(value) => {
								this.updateData("variable", value);
							}}
						/>
						{/* <div className="long-input mt-15">
                            <label>Variable</label>
                            <input onChange={(e) => {
                                this.updateData("variable", e.target.value);
                            }} type="text" value={this.props.data.variable} placeholder="{invite_url}" />
                        </div> */}
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	bot_data: state.data.bot_data,
	saveFailed: state.builder.saveFailed,
	components: state.builder.components,
	beta: state.data.beta
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateServerInvite);
