import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DocsLink } from './DocsLink'
import { Embed } from './Embed'
import RoleAdd from './RoleAdd'
import { Select } from './Select'
import { TextArea } from './TextArea'
import Toggle from './Toggle'


const DM_options = [
    { value: false, label: "Post in Server" },
    { value: true, label: "Direct Message" }
]
export class CustomCommandEditor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: "",
            hidden: true,
            trigger: "",
            response: "",
            info: "",
            dm: false,
            embed: {

            },
            roles: [],
            role_message_type: false,
            random_text: "",
            random_embed: {},
            random_messages: []
        }
    }

    componentDidMount() {

        if (this.props.options != undefined) {
            const options = this.props.options
            if (this.props.type == "basic") {
                this.setState(
                    {
                        trigger: options.trigger,
                        info: options.info,
                        dm: options.dm,
                        response: options.response
                    }
                )
            } else if (this.props.type == "embed") {
                this.setState(
                    {
                        trigger: options.trigger,
                        info: options.info,
                        dm: options.dm,
                        embed: options.embed
                    }
                )
            } else if (this.props.type == "role") {
                if (options.roles == undefined) {
                    options.roles = []
                }
                this.setState(
                    {
                        trigger: options.trigger,
                        info: options.info,
                        dm: options.dm,
                        response: options.response,
                        roles: options.roles
                    }
                )
            } else if (this.props.type == "random") {
                this.setState(
                    {
                        trigger: options.trigger,
                        info: options.info,
                        dm: options.dm,
                        random_messages: options.random
                    }
                )
            }
        }
    }

    embedCheck = () => {
        if (!("description" in this.state.embed) || this.state.embed.description == "") {
            this.setState({ error: "Please set content for your Embed." })
            return false;
        } else if (!("title" in this.state.embed) || this.state.embed.title == "") {
            this.setState({ error: "Please set a title for your Embed." })
            return false;
        }
        return true;
    }

    save = () => {
        if (this.state.trigger == "") {
            this.setState({ error: "Please set a trigger for your command." })
        } else if (this.state.info == "") {
            this.setState({ error: "Please set a description for your command." })
        } else if (this.props.type == "basic" && this.state.response == "") {
            this.setState({ error: "Please set some content for your command." })
        } else if (this.props.type == "embed" && this.embedCheck() == false) {

        } else if (this.props.type == "role" && this.state.roles.length == 0) {
            this.setState({ error: "Please add atleast one role to add." })
        } else if (this.props.type == "role" && this.state.response == "") {
            this.setState({ error: "Please set some content for your command." })
        } else if (this.props.type == "random" && this.state.random_messages.length == 0) {
            this.setState({ error: "Please add atleast one random response." })
        } else {
            var index = undefined;
            if (this.props.options != undefined) {
                index = this.props.options.index;
            }

            const state = { ...this.state };
            if (this.props.type == "basic") {
                delete state.roles
                delete state.embed;
            } else if (this.props.type == "embed") {
                delete state.roles
            } else if (this.props.type == "role") {
                delete state.embed;
            } else if (this.props.type == "random") {
                delete state.roles
                delete state.embed;
            }

            state.type = this.props.type;

            // console.log("STATE")
            // console.log(state)
            this.props.save(state, index)
            this.closeModal()
        }


    }

    closeModal = (e) => {
        this.setState({ hidden: true })
        this.props.close()
    }

    modaBackgroundClick = (e) => {
        if (e.currentTarget == e.target) {
            this.closeModal(e)
        }
    }

    dmChange = (value) => {
        if (value == "false") {
            value = false
        } else {
            value = true;
        }
        this.setState({ dm: value })
    }

    embedUpdate = (value) => {
        // console.log(value)
        this.setState({ embed: value })
    }

    roleMessageToggle = (value) => {
        this.setState({ role_message_type: value })
    }

    responseOnChange = (value) => {
        this.setState({ response: value })
    }

    addRole = (roles) => {
        this.setState({ roles: roles })
    }


    addResponse = () => {
        const random_messages = [...this.state.random_messages];

        if (this.state.role_message_type == false) {
            if (this.state.response == "") {
                this.setState({ error: "Please set some content for your command." })
            } else {
                random_messages.push({
                    response: this.state.response
                })
                this.setState({ response: "" })
            }
        } else {
            if (this.embedCheck() == false) {

            } else {
                random_messages.push({
                    embed: this.state.embed
                })
                this.setState({ embed: {} })
            }
        }
        this.setState({ random_messages: random_messages })

    }
    removeRandomResponse = (response) => {
        var random_messages = [...this.state.random_messages];

        random_messages = random_messages.filter(item => item != response);

        this.setState({ random_messages: random_messages })
    }

    renderRandomResponses = () => {
        const responses = [];

        this.state.random_messages.forEach(message => {
            if ("embed" in message) {
                responses.push(
                    <div className="col-lg-12">
                        <div className="command">
                            <div className="command-info">
                                <h3>{message.embed.title}</h3>
                                <span>Embed</span>
                            </div>
                        </div>
                    </div>
                )
            } else {
                responses.push(
                    <div className="col-lg-12">
                        <div className="command" onClick={(e) => { (this.removeRandomResponse(message)) }}>
                            <div className="command-info">
                                <h3>{message.response.substring(0, 10)}...</h3>
                                <span>Text</span>
                            </div>
                        </div>
                    </div>
                )
            }
        })

        return (
            <div className="commands row">
                {responses}
            </div>
        )
    }




    renderContent = () => {
        if (this.props.type == "basic") {
            return (
                <div>
                    <div className="custom-command-section">
                        <div className="mb-3">
                            <h3>Command Content <DocsLink location="https://docs.botghost.com/modules/custom-commands#plain-text-commands" /></h3>
                            <span>The content of the command.</span>
                        </div>

                        <TextArea variableEditor={true} onChange={this.responseOnChange} value={this.state.response}></TextArea>
                    </div>
                </div>
            )
        } else if (this.props.type == "embed") {
            return (
                <div>
                    <div className="custom-command-section">
                        <div className="mb-3">
                            <h3>Command Content <DocsLink location="https://docs.botghost.com/modules/custom-commands#embed-message" /></h3>
                            <span>The content of the command.</span>
                        </div>

                        <Embed variableEditor={true} value={this.state.embed} update={this.embedUpdate}></Embed>
                    </div>
                </div>
            )
        } else if (this.props.type == "role") {
            return (
                <div>

                    <div>
                        <div className="custom-command-section">
                            <div className="mb-3">
                                <h3>Command Content <DocsLink location="https://docs.botghost.com/modules/custom-commands#role-command" /></h3>
                                <span>The content of the command.</span>
                            </div>

                            <TextArea onChange={this.responseOnChange} value={this.state.response} variableEditor={true}></TextArea>
                        </div>
                    </div>


                    <div className="custom-command-section">
                        <div className="mb-3">
                            <h3>Roles to add <DocsLink location="https://docs.botghost.com/modules/custom-commands#adding-roles-to-a-command" /></h3>
                            <span>The roles to add to the member when triggering this command.</span>
                        </div>

                        <RoleAdd items={this.state.roles} update={this.addRole}></RoleAdd>
                    </div>
                </div>
            )
        } else if (this.props.type == "random") {
            return (
                <div className="custom-command-section">
                    <div className="mb-3">
                        <h3>Random Response <DocsLink location="https://docs.botghost.com/modules/custom-commands#random-response" /></h3>
                        <span>Add a Random Response to this command.</span>
                    </div>
                    <div style={{ width: "100%", marginRight: "20px" }}>
                        <div className="justify-space-between">
                            <h3>{this.props.title || "Message"}</h3>
                            {/* <div className="section-content-header">Create an announcement when someone joins your server.</div> */}

                            <div className="section-content-title justify-space-between">
                                <span style={{ marginInlineEnd: "12px" }}>{this.state.role_message_type ? "Embed" : "Plain Text"}</span>
                                <Toggle value={this.state.role_message_type} update={this.roleMessageToggle} type="type"></Toggle>
                            </div>
                        </div>

                        {this.state.role_message_type ? <Embed value={this.state.embed} update={this.embedUpdate}></Embed> : <TextArea variableEditor={true} value={this.state.response} onChange={this.responseOnChange}></TextArea>}
                    </div>

                    <div className="fl">
                        <button onClick={(e) => { this.addResponse() }} className="btn btn-red mt-2" style={{ marginLeft: "auto" }}>Add Message</button>
                    </div>

                    <div>
                        <div className="mb-3">
                            <h3>Random Responses <DocsLink location="https://docs.botghost.com/modules/custom-commands#random-response" /></h3>
                            <span>This commands random responses.</span>
                        </div>

                        <div>
                            {this.renderRandomResponses()}
                        </div>
                    </div>
                </div>


            )
        }
    }

    render() {
        if (this.state.hidden == true) {
            return (
                <div>
                    <div class={`modal fade show modal-background`} onClick={(e) => { this.modaBackgroundClick(e) }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog custom-command-modal" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Custom Command Editor <DocsLink location="https://docs.botghost.com/modules/custom-commands#creating-a-custom-command" /></h5>
                                    <button onClick={(e) => { this.closeModal(e) }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>


                                <div class="modal-body command-edit-body">
                                    <div className="command-edit-content">
                                        {this.state.error != "" ?
                                            <div className="errors">
                                                <div class="alert alert-danger" role="alert">
                                                    {this.state.error}
                                                </div>
                                            </div> :
                                            null}
                                        <div className="custom-command-section">
                                            <div className="mb-3">
                                                <h3>Command Trigger <DocsLink location="https://docs.botghost.com/modules/custom-commands#creating-a-custom-command" /></h3>
                                                <span>Custom Command Trigger</span>
                                            </div>

                                            <div className="long-input">
                                                <label>Trigger</label>
                                                <input type="text" value={this.state.trigger} onChange={(e) => { this.setState({ trigger: e.target.value }) }}></input>
                                            </div>
                                        </div>

                                        <div className="custom-command-section">
                                            <div className="mb-3">
                                                <h3>Command Description <DocsLink location="https://docs.botghost.com/modules/custom-commands#creating-a-custom-command" /></h3>
                                                <span>A short description of your command.</span>
                                            </div>

                                            <div className="long-input">
                                                <label>Description</label>
                                                <input type="text" value={this.state.info} onChange={(e) => { this.setState({ info: e.target.value }) }}></input>
                                            </div>
                                        </div>

                                        <div className="custom-command-section">
                                            <div className="mb-3">
                                                <h3>Post Type <DocsLink location="https://docs.botghost.com/modules/custom-commands#creating-a-custom-command" /></h3>
                                                <span>Should this command direct message the member triggering the command?</span>
                                            </div>

                                            <Select value={this.state.dm} options={DM_options} onChange={this.dmChange}></Select>
                                        </div>


                                        {this.renderContent()}
                                    </div>

                                </div>

                                <div class="modal-footer">
                                    <button onClick={(e) => { this.closeModal() }} type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    {this.props.showDelete ? <button onClick={(e) => { this.props.delete(this.props.options.index) }} type="button" class="btn btn-red" data-dismiss="modal">Delete Command</button> : null}
                                    <button type="button" class="btn btn-primary" onClick={(e) => { this.save() }}>Save changes</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CustomCommandEditor)
