import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";
import RefreshBotData from "../../elements/RefreshBotData";
import Select from "../../elements/Select";
import RoleAdd from "../../elements/RoleAdd";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import VariableTextInput from "../VariableTextInput";

const CHANNEL_TYPES = [
	{ label: "Text Channel", value: "GUILD_TEXT" },
	{ label: "Voice Channel", value: "GUILD_VOICE" },
	{ label: "Category Channel", value: "GUILD_CATEGORY" },
	{ label: "News Channel", value: "GUILD_NEWS" }
];

export class ChannelCreateAction extends Component {
	componentDidMount() {
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "channel_create",
					name: "",
					channel_type: "GUILD_TEXT",
					permissions: [],
					topic: "",
					position: "",
					parent: "",

					variable: `created_channel_${s4()}`,
					guild_id: "",
					validated: false,
					success_handles: false,
					nsfw: false,
					rateLimitPerUser: 0,
					userLimit: 0
				},
				id: this.props.id
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data != undefined && this.props.data.success_handles == undefined) {
			this.updateData("success_handles", false);
		}
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "channel_create",
					name: "",
					channel_type: "GUILD_TEXT",
					permissions: [],
					topic: "",
					position: "",
					validated: false,
					nsfw: false,
					rateLimitPerUser: 0,
					userLimit: 0
				},
				id: this.props.id
			});
		} else {
			// VALIDATE

			if (!this.props.data.variable) {
				this.updateData("variable", `created_channel_${s4()}`);
			}

			if (this.props.data.name != "") {
				if (this.props.data.validated === false) {
					this.updateData("validated", true);
				}
			} else {
				if (this.props.data.validated === true) {
					this.updateData("validated", false);
				}
			}
		}
	}

	updateData = (key, value) => {
		// console.log("VALUE", value);
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	renderOptions = () => {
		var options = [
			// {value:"trigger",label:"The member who triggered the command"}
		];
		this.props.options.forEach((option) => {
			if (option.type == "channel") {
				options.push({ value: { variable: option.name }, label: `Channel Option: ${option.name}` });
			}
		});
		return options;
	};

	selectUpdate = (value) => {
		value = JSON.parse(value);
		var target = { ...this.props.data.target };
		target = value;
		this.updateData("target", target);
	};

	renderRoleOptions = () => {
		var options = [];
		options.push({ value: JSON.stringify({ id: "" }), label: "" });

		this.props.options.forEach((option) => {
			if (option.type == "role") {
				options.push({ value: JSON.stringify({ variable: option.name }), label: `Role Option: ${option.name}` });
			}
		});

		if (this.props.bot_data == undefined || this.props.bot_data.length == 0) {
			// options.push(<optgroup label="Refresh Server Data. Ensure your bot is in a server and click the refresh button above."></optgroup>)
		} else {
			this.props.bot_data.forEach((guild, index) => {
				const guild_options = [];
				guild.roles.forEach((role) => {
					// if(this.props.requireGuild == true){
					//     channel.guild = guild.id;
					// }
					options.push({ value: JSON.stringify(role), label: `${role.name} - ${guild.name}` });
				});
			});
		}
		return options;
	};

	renderPermissions = () => {
		var permissions = [];

		this.props.data.permissions.forEach((permission, index) => {
			permissions.push(
				<div>
					<hr class="slash-hr" style={{ borderTop: "1px solid rgb(173, 181, 189)" }} />

					<div style={{ display: "flex", alignItems: "center" }}>
						<h4 style={{ marginBottom: "0px" }}>Permission Overwrite {index + 1}</h4>
						<button
							onClick={() => {
								var permissions = [...this.props.data.permissions];
								permissions.splice(index, 1);
								this.updateData("permissions", permissions);
							}}
							className="btn btn-red"
							style={{ marginLeft: "auto", padding: "5px", fontSize: "15px", fontWeight: "800" }}
						>
							Delete
						</button>
					</div>

					<div className="mb-15 slash-action">
						<h4>Type</h4>
						<span>The type of this permission overwrite. Either Role or User.</span>

						<div className="mt-15">
							<Select
								value={permission.type}
								options={[
									{ value: "role", label: "Role" },
									{ value: "user", label: "User" }
								]}
								onChange={(value) => {
									var permissions = [...this.props.data.permissions];
									permissions[index].type = value;
									this.updateData("permissions", permissions);
								}}
							/>
						</div>
					</div>

					{permission.type == "role" ? (
						<div className="mb-15 slash-action">
							<h4>
								Role <RefreshBotData />
							</h4>
							<span>The role to apply this permission to.</span>

							<Select
								value={JSON.stringify(permission.id)}
								options={this.renderRoleOptions()}
								onChange={(value) => {
									var permissions = [...this.props.data.permissions];
									// console.log(permission)
									permissions[index].id = JSON.parse(value);
									this.updateData("permissions", permissions);
								}}
							/>
						</div>
					) : (
						<div className="mb-15 slash-action">
							<h4>User</h4>
							<span>The user to apply the permissions to. Either a User ID or User Option.</span>

							<VariableTextInput
								required={true}
								label="User"
								placeholder="136327647792726016"
								value={permission.id.id}
								onChange={(value) => {
									var permissions = [...this.props.data.permissions];
									permissions[index].id.id = value;
									this.updateData("permissions", permissions);
								}}
								slash_options={this.props.options}
							/>
							{/* <div class="long-input mt-15">
                                <label>User</label>
                                <input onChange={(e) => {
                                    var permissions = [...this.props.data.permissions];
                                    permissions[index].id.id = e.target.value;
                                    this.updateData("permissions", permissions);

                                }} type="text" value={permission.id.id} />
                            </div> */}
						</div>
					)}

					<div className="mb-15 slash-action">
						<h4>Allow</h4>
						<span>Permissions to allow</span>

						<RoleAdd
							update={(items) => {
								var permissions = [...this.props.data.permissions];
								permissions[index].allow = items;
								this.updateData("permissions", permissions);
							}}
							items={permission.allow}
							type="permissions"
							subType="channel"
						/>
					</div>

					<div className="mb-15 slash-action">
						<h4>Deny</h4>
						<span>Permissions to deny</span>

						<RoleAdd
							update={(items) => {
								var permissions = [...this.props.data.permissions];
								permissions[index].deny = items;
								this.updateData("permissions", permissions);
							}}
							items={permission.deny}
							type="permissions"
							subType="channel"
						/>
					</div>
				</div>
			);
		});

		return permissions;
	};

	render() {
		if (this.props.data != undefined) {
			return (
				<div>
					<div className="mb-15">
						<h4>Create a Channel</h4>
						<p>
							Create a Channel in the server. User and Role options can be used for permissions. You can use the{" "}
							<strong style={{ color: "#f45142" }}>
								{`{option_${this.props.data.variable}}`}{" "}
								<FontAwesomeIcon
									icon={faClipboard}
									fixedWidth
									className="text-[#9d9b9b] text-sm hover:text-red hover:cursor-pointer"
									onClick={() => {
										navigator.clipboard.writeText(`{option_${this.props.data.variable}}`);
										toast.success("Copied variable to clipboard");
									}}
								/>
							</strong>{" "}
							variable in other actions and conditions.
						</p>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Name <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-channel#name" />
						</h4>
						<span>The name of the new channel.</span>
						<VariableTextInput
							label="Name"
							placeholder="Channel Name"
							value={this.props.data.name}
							onChange={(value) => {
								this.updateData("name", value);
							}}
							slash_options={this.props.options}
						/>
						{/* <div class="long-input mt-15">
                            <label>Name</label>
                            <input className={`${this.props.data.name == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                this.updateData("name", e.target.value);
                            }} maxLength={50} type="text" value={this.props.data.name} />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Type <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-channel#type" />
						</h4>
						<span>The type of the new channel.</span>
						<div className="mt-15">
							<Select
								value={this.props.data.channel_type}
								options={CHANNEL_TYPES}
								onChange={(value) => {
									this.updateData("channel_type", value);
								}}
							/>
						</div>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Topic <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-channel#topic" />
						</h4>
						<span>An optional topic for the new channel.</span>
						<VariableTextInput
							label="Topic"
							placeholder="Channel Topic"
							value={this.props.data.topic}
							onChange={(value) => {
								this.updateData("topic", value);
							}}
							slash_options={this.props.options}
						/>
						{/* <div class="long-input mt-15">
                            <label>Topic</label>
                            <input onChange={(e) => {
                                this.updateData("topic", e.target.value);

                            }} maxLength={250} type="text" value={this.props.data.topic} />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Category <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-channel#category" />
						</h4>
						<span>An optional category to place the new channel in. An Id or Option can be used.</span>
						<VariableTextInput
							label="Category"
							placeholder="Category ID or Variable"
							value={this.props.data.parent}
							onChange={(value) => {
								this.updateData("parent", value);
							}}
							slash_options={this.props.options}
						/>
						{/* <div class="long-input mt-15">
                            <label>Category</label>
                            <input onChange={(e) => {
                                this.updateData("parent", e.target.value);

                            }} maxLength={250} type="text" value={this.props.data.parent} />
                        </div> */}
					</div>

					{this.props.data.channel_type === "GUILD_TEXT" && (
						<>
							<div className="mb-15 slash-action">
								<h4>
									NSFW <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-channel#nsfw" />
								</h4>
								<span>An optional toggle to make the channel NSFW (Not Safe For Work) or not.</span>
								<Toggle
									value={this.props.data.nsfw}
									update={(value) => {
										this.updateData("nsfw", value);
									}}
								/>
							</div>

							<div className="mb-15 slash-action">
								<h4>
									Slowmode <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-channel#slowmode" />
								</h4>
								<span>An optional rate limit (in seconds) that you want to set the created channel to.</span>
								<VariableTextInput
									label="Slowmode"
									placeholder="0"
									value={this.props.data.rateLimitPerUser}
									onChange={(value) => {
										this.updateData("rateLimitPerUser", value);
									}}
									slash_options={this.props.options}
								/>
								{/* <div class="long-input mt-15">
                                <label>Slowmode</label>
                                <input onChange={(e) => {
                                    this.updateData("rateLimitPerUser", e.target.value);
                                }} type="number" min={0} max={21600} value={this.props.data.rateLimitPerUser} placeholder={3} />
                            </div> */}
							</div>
						</>
					)}

					{this.props.data.channel_type === "GUILD_VOICE" && (
						<>
							<div className="mb-15 slash-action">
								<h4>
									User Limit <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-channel#user-limit" />
								</h4>
								<span>An optional limit to set the size of the voice channel</span>
								<VariableTextInput
									label="User Limit"
									placeholder="0"
									value={this.props.data.userLimit}
									onChange={(value) => {
										this.updateData("userLimit", value);
									}}
									slash_options={this.props.options}
								/>
								{/* <div class="long-input mt-15">
                                <label>User Limit</label>
                                <input onChange={(e) => {
                                    this.updateData("userLimit", e.target.value);
                                }} type="number" min={0} max={21600} value={this.props.data.userLimit} placeholder={3} />
                            </div> */}
							</div>
						</>
					)}

					<div className="mb-15 slash-action">
						<h4>
							Optional Server Id <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-channel#optional-server-id" />
						</h4>
						<span>An optional server Id to create the channel in. An Id or Variable can be used. Leave this blank to create the channel in the server this {this.props.mode == "event" ? "event" : "command"} was triggered in.</span>
						<VariableTextInput
							label="Server ID"
							placeholder="Server ID or Variable"
							value={this.props.data.guild_id}
							onChange={(value) => {
								this.updateData("guild_id", value);
							}}
							slash_options={this.props.options}
						/>
						{/* <div class="long-input mt-15">
                            <label>ID or Variable</label>
                            <input onChange={(e) => {
                                this.updateData("guild_id", e.target.value);

                            }} maxLength={250} type="text" value={this.props.data.guild_id} />
                        </div> */}
					</div>

					<hr class="slash-hr" style={{ borderTop: "1px solid rgb(173, 181, 189)" }} />

					<div className="mb-15 slash-action">
						<h4>
							Permissions <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-channel#permissions" />{" "}
						</h4>
						<span>Add Role and User permisions to this new channel. You can use User and Role options for any permission.</span>
						<button
							class="btn btn-red add-button mt-15"
							onClick={() => {
								var permissions = [...this.props.data.permissions];

								permissions.push({
									type: "role",
									id: {
										id: ""
									},
									allow: [],
									deny: []
								});
								this.updateData("permissions", permissions);
							}}
						>
							Add Permission
						</button>
					</div>
					<div>{this.renderPermissions()}</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	bot_data: state.data.bot_data,
	saveFailed: state.builder.saveFailed,
	beta: state.data.beta
});
const mapDispatchToProps = {
	updateElementData
};

let s4 = () => {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelCreateAction);
