import React, { Component } from "react";
import { connect } from "react-redux";
import ToolInput from "./ToolInput";
import ToolTextArea from "./ToolTextArea";
import { Switch } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon, ClipboardDocumentIcon, TrashIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { BlockPicker, ChromePicker, SketchPicker } from "react-color";
import DateTimePicker from 'react-datetime-picker';
import moment from "moment";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { faCalendar, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const imageReg = /((?:https?\:\/\/|www\.)[-a-zA-Z0-9@:%_\+.~#?&//=]+)\.(jpg|jpeg|gif|png|bmp|tiff|tga|svg|webp)/;
const urlReg = /((?:https?\:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/i;

export class DiscordEmbed extends Component {
	constructor(props) {
		super(props);

		this.state = {
			colorOpen: false,
			valid: false,
			errors: {
				descriptionRequired: {
					message: "At least one of Description, Title, or Author name is required.",
					error: false
				},
				url: {
					message: "Invalid URL",
					error: false
				},
				author: {
					message: "Author name is required when author url or icon is set.",
					error: false
				},
				imageUrl: {
					message: "Invalid Image URL",
					error: false
				},
				thumbnailUrl: {
					message: "Invalid Image URL",
					error: false
				},
				footerIconUrl: {
					message: "Invalid Image URL",
					error: false
				},
				footer: {
					message: "Footer text is required when footer icon is set.",
					error: false
				},
				fields: {
					message: "Each field requires a name and value.",
					error: false
				}
			}
		};

		this.wrapperRef = React.createRef();
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}
	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
		this.validateEmbed();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.embed !== this.props.embed || prevProps.currentEmbedErrorIndex !== this.props.currentEmbedErrorIndex) {
			this.validateEmbed();
		}
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ colorOpen: false });
		}
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	validateEmbed = () => {
		var errors = { ...this.state.errors };
		var valid = true;

		// Check if at least one of description, title, or author name is present
		if (!this.props.embed.description && !this.props.embed.title && !this.props.embed?.author?.name) {
			errors.descriptionRequired.error = true;
			valid = false;
		} else {
			errors.descriptionRequired.error = false;
		}

		// Each field if added requires a name and value;
		var fieldsValid = true;
		this.props.embed.fields.forEach((field, index) => {
			if (!field.name || !field.value) {
				fieldsValid = false;
			}
		});
		if (!fieldsValid) {
			errors.fields.error = true;
			valid = false;
		} else {
			errors.fields.error = false;
		}

		// If there is an author url OR author icon url, then the author name is required;
		var authorValid = true;
		if (this.props.embed.author?.url || this.props.embed.author?.icon_url) {
			if (!this.props.embed.author?.name) {
				authorValid = false;
			}
		}
		if (!authorValid) {
			errors.author.error = true;
			valid = false;
		} else {
			errors.author.error = false;
		}

		var footerValid = true;
		if (this.props.embed.footer?.icon_url) {
			if (!this.props.embed.footer?.text) {
				footerValid = false;
			}
		}
		if (!footerValid) {
			errors.footer.error = true;
			valid = false;
		} else {
			errors.footer.error = false;
		}

		this.setState({ errors, valid });

	};

	updateData = (value, key) => {
		var embed = { ...this.props.embed };
		embed[key] = value;
		this.props.onChange(embed);
	};

	renderFields = () => {
		var fields = [];

		this.props.embed.fields.forEach((field, index) => {
			fields.push(
				<div className="border border-1-[#adb5bd] grid gap-y-2 border-[#adb5bd]  rounded-sm p-3 relative">
					<div className="top-2 right-2 absolute flex hover:cursor-pointer text-white opacity-80 hover:text-red hover:opacity-70" onClick={(e) => e.stopPropagation()}>
						<TrashIcon
							className="h-5"
							onClick={(e) => {
								e.stopPropagation();
								var embed = { ...this.props.embed };
								embed.fields.splice(index, 1);
								this.props.onChange(embed);
							}}
						/>
						<ClipboardDocumentIcon
							className="h-5 ml-2"
							onClick={(e) => {
								e.stopPropagation();
								if (this.props.embed.fields.length >= 25) return;
								var newField = {
									name: field.name,
									value: field.value,
									inline: field.inline || false
								};
								var embed = { ...this.props.embed };
								embed.fields.push(newField);
								this.props.onChange(embed);
							}}
						/>
					</div>
					<div>
						<label htmlFor="email" className="block text-md mb-2 font-bold leading-6 text-white">
							Field {index + 1}
							<span className="muted-text opacity-50"> - {field.name}</span>
						</label>
					</div>
					<div>
						<ToolInput
							maxLength={256}
							bg="!bg-darkGray"
							label="Name"
							value={field.name}
							onChange={(value) => {
								var field = { ...this.props.embed.fields[index] };
								field.name = value;
								var embed = { ...this.props.embed };
								embed.fields[index] = field;
								this.props.onChange(embed);
							}}
						></ToolInput>
					</div>

					<div>
						<ToolTextArea
							maxLength={1024}
							bg="!bg-darkGray"
							label="Value"
							value={field.value}
							onChange={(value) => {
								var field = { ...this.props.embed.fields[index] };
								field.value = value;
								var embed = { ...this.props.embed };
								embed.fields[index] = field;
								this.props.onChange(embed);
							}}
						></ToolTextArea>
					</div>

					<div className="flex justify-end mt-2">
						<span className="font-bold text-white mr-2">Inline</span>
						<Switch
							checked={field.inline}
							onChange={() => {
								var field = { ...this.props.embed.fields[index] };
								field.inline = !field.inline;
								var embed = { ...this.props.embed };
								embed.fields[index] = field;
								this.props.onChange(embed);
							}}
							className={classNames(field.inline ? "bg-red" : "bg-gray-200", "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red focus:ring-offset-2")}
						>
							<span className="sr-only">Inline</span>
							<span aria-hidden="true" className={classNames(field.inline ? "translate-x-5" : "translate-x-0", "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out")} />
						</Switch>
					</div>
				</div>
			);
		});

		return fields;
	};

	renderError(errorType) {
		if (this.state.errors[errorType].error) {
			return (
				<div className="text-red mt-2 font-bold flex items-center">
					<ExclamationCircleIcon className="h-5 mr-1 text-red"></ExclamationCircleIcon>
					<span> {this.state.errors[errorType].message}</span>
				</div>
			);
		}
	}
	render() {
		return (
			<div className="p-2">
				<div
					style={{
						borderLeftColor: this.props.embed.color ? this.props.embed.color : "#000000"
					}}
					className="bg-lightGray border-l-4 border-l-red px-3 py-3 rounded-sm text-white relative"
				>
					<div
						className="flex align-center hover:cursor-pointer"
						onClick={() => {
							this.props.openClose();
						}}
					>
						{this.props.open ? (
							<div>
								<ChevronDownIcon className="h-5"></ChevronDownIcon>
							</div>
						) : (
							<div>
								<ChevronRightIcon className="h-5"></ChevronRightIcon>
							</div>
						)}
						<div className="overflow-clip max-w-[300px] flex">
							<h4 className="font-bold ">
								Embed {this.props.index + 1} - <span className="muted-text">{this.props.embed.title || "Embed"}</span>
							</h4>
						</div>
						{!this.state.valid ? (
							<div>
								<ExclamationCircleIcon className="h-5 text-red"></ExclamationCircleIcon>
							</div>
						) : null}
						<div className="ml-auto hover:cursor-pointer text-white opacity-80 hover:text-red hover:opacity-70">
							<div className="flex ">
								<ClipboardDocumentIcon
									className="h-5 mr-2 hover:text-red hover:opacity-70"
									onClick={(e) => {
										e.stopPropagation();
										//

										this.props.copyEmbed();
									}}
								/>
								<TrashIcon
									className=" h-5 hover:text-red hover:opacity-70"
									onClick={(e) => {
										e.stopPropagation();
										this.props.delete();
									}}
								/>
							</div>
						</div>
					</div>

					<div className={`grid gap-y-2 mt-3 ${this.props.open ? "show" : "hidden"}`}>
						{/* Author */}
						<div>
							<div className="mb-2">
								<ToolInput
									maxLength={256}
									bg="!bg-darkGray"
									label="Author"
									value={this.props.embed.author?.name || ""}
									onChange={(value) => {
										var author = { ...this.props.embed.author };
										if (!author)
											author = {
												name: "",
												url: "",
												icon_url: ""
											};
										author.name = value;
										console.log(author, "AUTHOR");
										this.updateData(author, "author");
									}}
								></ToolInput>
							</div>

							<div className="grid grid-cols-2 gap-x-3">
								<div>
									<ToolInput
										bg="!bg-darkGray"
										label="Author URL"
										showEmojis={false}
										value={this.props.embed?.author?.url}
										onChange={(value) => {
											var author = { ...this.props.embed.author };
											if (!author)
												author = {
													name: "",
													url: "",
													icon_url: ""
												};
											author.url = value;
											this.updateData(author, "author");
										}}
									></ToolInput>


								</div>

								<div>
									<ToolInput
										bg="!bg-darkGray"
										label="Author Icon URL"
										showEmojis={false}
										value={this.props.embed?.author?.icon_url}
										onChange={(value) => {
											var author = { ...this.props.embed.author };
											if (!author)
												author = {
													name: "",
													url: "",
													icon_url: ""
												};
											author.icon_url = value;
											this.updateData(author, "author");
										}}
									></ToolInput>


								</div>
							</div>
							{this.renderError("author")}
						</div>

						<div className="border-t-[1px] border-[#adb5bd] mb-2 mt-3"></div>

						{/* Title + Description */}
						<div>
							<div className="mb-2">
								<ToolInput
									maxLength={256}
									value={this.props.embed.title || ""}
									bg="!bg-darkGray"
									label="Title"
									onChange={(value) => {
										this.updateData(value, "title");
									}}
								></ToolInput>
							</div>

							<div>
								<ToolTextArea
									maxLength={4096}
									value={this.props.embed.description || ""}
									bg="!bg-darkGray"
									label="Description"
									onChange={(value) => {
										this.updateData(value, "description");
									}}
								></ToolTextArea>

								{this.renderError("descriptionRequired")}
							</div>

							<div className="grid grid-cols-10 mt-2 gap-x-3">
								<div className="col-span-4 md:col-span-5">
									<ToolInput
										bg="!bg-darkGray"
										label="URL"
										showEmojis={false}
										value={this.props.embed.url || ""}
										onChange={(value) => {
											this.updateData(value, "url");
										}}
									></ToolInput>

									{this.renderError("url")}
								</div>

								<div className="col-span-4 md:col-span-3">
									<ToolInput
										bg="!bg-darkGray"
										label="Color"
										showEmojis={false}
										value={this.props.embed.color || ""}
										onChange={(value) => {
											this.updateData(value, "color");
										}}
									></ToolInput>
								</div>

								<div className="col-span-2 md:col-span-2 z-100">
									<div className="flex relative flex-col" ref={this.setWrapperRef}>
										<label htmlFor="email" className="block text-md font-bold leading-6 text-white">
											Picker
										</label>

										<div
											onClick={() => {
												this.setState({ colorOpen: !this.state.colorOpen });
											}}
											style={{
												background: this.props.embed.color ? this.props.embed.color : "#000000"
											}}
											className={`h-[38px] rounded-sm w-full hover:cursor-pointer`}
										></div>

										{this.state.colorOpen && (
											<div className="absolute z-[1000] top-[100%] right-0 mt-1">
												<ChromePicker
													onChange={(value) => {
														this.updateData(value.hex, "color");
													}}
													color={this.props.embed.color || "#000000"}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>

						<div className="border-t-[1px] border-[#adb5bd] mb-2 mt-3"></div>

						{/* Images */}
						<div className="grid grid-cols-2 gap-x-3">
							<div className="mb-2">
								<ToolInput
									bg="!bg-darkGray"
									label="Image URL"
									showEmojis={false}
									value={this.props.embed.image?.url || ""}
									onChange={(value) => {
										var image = { ...this.props.embed.image };
										if (!image)
											image = {
												url: ""
											};
										image.url = value;
										this.updateData(image, "image");
									}}
								></ToolInput>

								{this.renderError("imageUrl")}
							</div>

							<div>
								<ToolInput
									bg="!bg-darkGray"
									label="Thumbnail URL"
									showEmojis={false}
									value={this.props.embed.thumbnail?.url || ""}
									onChange={(value) => {
										var thumbnail = { ...this.props.embed.thumbnail };
										if (!thumbnail)
											thumbnail = {
												url: ""
											};
										thumbnail.url = value;
										this.updateData(thumbnail, "thumbnail");
									}}
								></ToolInput>

								{this.renderError("thumbnailUrl")}
							</div>
						</div>

						<div className="border-t-[1px] border-[#adb5bd] mb-2 mt-3"></div>

						{/* Footer */}

						<div>
							<div className="mb-2">
								<ToolTextArea
									maxLength={2048}
									value={this.props.embed.footer?.text || ""}
									bg="!bg-darkGray"
									label="Footer"
									onChange={(value) => {
										var footer = { ...this.props.embed.footer };
										if (!footer)
											footer = {
												text: "",
												icon_url: ""
											};
										footer.text = value;
										this.updateData(footer, "footer");
									}}
								></ToolTextArea>
							</div>

							<div className="w-full">
								<div className="w-full mb-2">
									<ToolInput
										bg="!bg-darkGray"
										label="Footer Icon URL"
										showEmojis={false}
										value={this.props.embed.footer?.icon_url || ""}
										onChange={(value) => {
											var footer = { ...this.props.embed.footer };
											if (!footer)
												footer = {
													text: "",
													icon_url: ""
												};
											footer.icon_url = value;
											this.updateData(footer, "footer");
										}}
									></ToolInput>

									{this.renderError("footer")}
								</div>
								<div className="w-full mt-2">
									<div className="grid grid-cols-1  gap-x-3">
										<div>
											<label htmlFor="email" className="block text-md mb-2 font-bold leading-6 text-white">Timestamp</label>
											<DateTimePicker
												onChange={(time) => {
													if (!time) time = "";
													this.updateData(`${time}`, "timestamp");
													// this.updateData('', "timestamp_variable");
												}}
												clearIcon={<FontAwesomeIcon icon={faTimes} className="text-white" />}
												calendarIcon={<FontAwesomeIcon icon={faCalendar} className="text-white" />}
												disableClock={true}
												className={"w-full !text-black  bg-darkGray rounded-md border-0 py-2 px-2"}
												value={this.props.embed.timestamp ? new Date(this.props.embed.timestamp) : ""}
											/>
										</div>
										<div className="mt-2">
											<ToolInput
												bg="!bg-darkGray"
												label="Timestamp Variable"
												showEmojis={false}
												value={this.props.embed.timestamp_variable || ""}
												placeholder="Date or Unix Timestamp"
												onChange={(value) => {
													this.updateData(value, "timestamp_variable");
													// this.updateData('', "timestamp");
												}}
											/>
											{/* Alert only one of these can be used */}
											{this.props.embed.timestamp && this.props.embed.timestamp_variable && <div className="text-red mt-2 font-bold">Only one of these can be used. If a variable is used, the timestamp will be replaced with the variable.</div>}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="w-full mt-2">
							<ToolInput
								bg="!bg-darkGray"
								label="Hide Embed"
								showEmojis={false}
								value={this.props.embed.hide_embed || ""}
								placeholder="true or false"
								onChange={(value) => {
									this.updateData(value, "hide_embed");
									// this.updateData('', "timestamp");
								}}
							/>
							<p className="text-sm text-gray-500 mt-1">Note: If set to 'true', the embed will be ignored and not show.</p>
						</div>

						<div className="border-t-[1px] border-[#adb5bd] mb-2 mt-3"></div>
						{/* Fields */}

						<div>
							<label htmlFor="email" className="block text-md mb-2 font-bold leading-6 text-white">
								Fields <span className="muted-text opacity-50">{this.props.embed.fields.length}/25</span>
							</label>
							{this.renderError("fields")}
							<div className="grid gap-y-4">{this.renderFields()}</div>

							<div className="mt-3">
								<button
									className="btn btn-red mr-2 px-1 py-1 font-bold"
									onClick={() => {
										if (this.props.embed.fields.length >= 25) return;
										var embed = { ...this.props.embed };
										embed.fields.push({
											name: "",
											value: "",
											inline: false
										});
										this.props.onChange(embed);
									}}
								>
									Add Field
								</button>
								<button className="btn btn-gray px-1 py-1 font-bold" onClick={() => {
									var embed = { ...this.props.embed };
									embed.fields = [];
									this.props.onChange(embed);
								}}>Clear Fields</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default DiscordEmbed;
