import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../../../api/server';
import { refreshIfttt } from '../../../actions';

export class RefreshIFTTT extends Component {
    render() {
        return (
            <button className='btn btn-gray' onClick={async () => {
                var response = await server.get("/ifttt/status");

                if (response) {
                    this.props.refreshIfttt(response.data);
                }
            }}>Refresh Link Status</button>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    refreshIfttt
};

export default connect(mapStateToProps, mapDispatchToProps)(RefreshIFTTT);