import React, { Component } from 'react'
import { connect } from 'react-redux'

import server from '../../../api/server'
import ReactGA from 'react-ga';


import history from "../../../history"
import { setQuickStart, setOnlineStatus, setServerIntents, setAuth, getData, fetchBotData, getBots, updateErrorLogs, updateServerPopup } from '../../../actions';


export class OfflineModal extends Component {


    constructor(props) {
        super(props)

        this.state = {
            disabled: false
        }
    }

    modalBackgroundClick = (e) => {
        // console.log("MODAL BACKGROUND CLICK")
        if (e.currentTarget == e.target) {
            this.props.closeModal(true)
        }
    }
    componentDidMount() {
        // console.log("PREMIUM MODAL MOUNTED")
    }


    joinServer = async () => {
        ReactGA.initialize('UA-122665050-1');
        ReactGA.event({
            category: "Server Join",
            label: "Server Modal",
            action: "New Dashboard"
        })
        var response = await server.get("/dashboard/joinserver");
        if (response.data.failed != undefined && response.data.failed == true && response.data.reason == "auth") {
            // OPEN LINK IN NEW TAB
            window.open("https://discord.com/invite/9UPM6S4xyA", '_blank').focus();
        }
        this.props.closeModal()
    }

    start = async () => {
        this.setState({ disabled: true })
        var obj = {
            bot: this.props.bot,
            id: this.props.bot.id
        }

        if (this.props.cocreate == true) {
            obj.cocreate = true;
            var host_user_id = null;
            this.props.bots.forEach(bot => {
                if (bot.id == this.props.bot.id && bot.cocreator == true) {
                    host_user_id = bot.host_user_id
                }
            })
            obj.host_user_id = host_user_id
        }
        const response = await server.post("/dashboard/start", obj)
        if (response.data && response.data.response == true) {
            this.props.setOnlineStatus(true)
        } else if (response.data && response.data.response == false && response.data.reason == "logged") {
            this.props.setAuth()
        }
        var quick_start = { ...this.props.quick_start }
        quick_start["on_off"] = true;
        this.props.setQuickStart(quick_start)
        this.setState({ disabled: false })
        // console.log("START DONE")
        ReactGA.initialize('UA-122665050-1');
        ReactGA.event({
            category: "Offline Modal",
            label: "Online",
            action: "New Dashboard"
        })

        return
    }



    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} onClick={(e) => { this.modalBackgroundClick(e) }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div class="modal-dialog premium-modal-dialog" role="document">
                        <div class="modal-content premium-modal" style={{ backgroundColor: "#f8f8fb" }}>

                            <div class="modal-header m-modal-header" style={{ 'borderBottom': 'none' }}>
                                <button onClick={(e) => { this.props.closeModal(true); }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div class="modal-body command-edit-body premium-modal-body">
                                <div className="premium-modal-content">
                                    <img style={{ height: "50px", width: "50px" }} src={process.env.PUBLIC_URL + "/images/logo-red.png"}></img>
                                    <h2 style={{ textAlign: "center", fontSize: "28px" }}>Your Bot is Offline!</h2>
                                    <p style={{ opacity: "0.7" }}>Click the button below or go to settings to turn your bot back online.</p>
                                    <div>
                                        {/* <button className="btn btn-gray" onClick={() =>{this.props.closeModal()}}>Close</button> */}

                                        <button disabled={this.state.disabled} className="btn btn-red ml-2" onClick={() => { this.start() }}>Go Online</button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    intent: state.data.server_members_intent,
    user: state.data.user,
    premium: state.data.premium,
    page: state.page
    , auth: state.auth,
    data: state.data,
    loading: state.data.loading,
    save_required: state.data.save_required,
    processing: state.data.processing,
    bot: state.data.bot
})

const mapDispatchToProps = {
    updateServerPopup,
    setOnlineStatus,
    setQuickStart,
    setServerIntents, updateServerPopup, setAuth, getData, fetchBotData, getBots, updateErrorLogs
}

export default connect(mapStateToProps, mapDispatchToProps)(OfflineModal)
