import axios from 'axios'


export default axios.create({
  baseURL:process.env.REACT_APP_API_SERVER,
  headers:{
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": true
  },
  withCredentials:true
})
