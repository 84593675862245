import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import DocsLink from './DocsLink';
import VariableTextInput from '../commandbuilder/VariableTextInput';

export class LongInput extends Component {
    render() {
        return (
            <div className={!this.props.noMB ? "mb-15" : ""}>
                {this.props.title && <h3 style={{ marginBottom: "0px" }}>{this.props.title} {this.props.inputRequired ? <span className="text-red">*</span> : null} {this.props.docs ? <DocsLink location={this.props.docs} /> : ""}</h3>}

                {this.props.description && <div className="section-content-header">
                    <p>{this.props.description}</p>
                </div>}
                {this.props.variables != false ? <VariableTextInput label={this.props.label} placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.change} slash_options={this.props.slash_options} />
                    : <div className={`long-input ${this.props.showIcon ? 'long-input-error' : ''}`}>
                        <label>{this.props.label}</label>
                        <input
                            required
                            type={this.props.type || "text"}
                            value={this.props.value}
                            className={`${this.props.inputRequired && this.props.value == "" ? "command-required" : ""}`}
                            maxLength={this.props.maxLength}
                            placeholder={this.props.placeholder || ""}
                            onChange={(e) => {
                                this.props.change(e.target.value);
                            }}
                        >
                        </input>
                        {this.props.icon && this.props.showIcon && <FontAwesomeIcon icon={this.props.icon} fixedWidth style={{ position: "absolute", top: "0", right: "10px", bottom: "0", height: "100%", color: this.props.iconColor }} />}

                    </div>}
                {/* <div className={`long-input ${this.props.showIcon ? 'long-input-error' : ''}`}>
                    <label>{this.props.label}</label>
                    <input
                        required
                        type={this.props.type || "text"}
                        value={this.props.value}
                        className={`${this.props.inputRequired && this.props.value == "" ? "command-required" : ""}`}
                        maxLength={this.props.maxLength}
                        placeholder={this.props.placeholder || ""}
                        onChange={(e) => {
                            this.props.change(e.target.value);
                        }}
                    >
                    </input>
                    {this.props.icon && this.props.showIcon && <FontAwesomeIcon icon={this.props.icon} fixedWidth style={{ position: "absolute", top: "0", right: "10px", bottom: "0", height: "100%", color: this.props.iconColor }} />}

                </div> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LongInput);