import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";

import { setBotModule } from "../../../actions";

import Dashboard from "../Dashboard";

export class Search extends Component {
  commandUpdate = (value, type) => {
    // console.log(value, type);
    var search = { ...this.props.search };
    search[type] = value;
    this.props.setBotModule({
      module: "search",
      module_data: search,
    });
  };

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  keyChange = (value) => {
    var search = { ...this.props.search };

    search.youtube.key = value;
    this.props.setBotModule({
      module: "search",
      module_data: search,
    });
  };

  render() {
    return (
      <Dashboard>
        <ModuleHeader
          tutorial="https://docs.botghost.com/utility/search"
          docs="https://docs.botghost.com/utility/search"
          description="Search the Internet for a variety of things"
          showEnabled={false}
          title="Search"
          icon="search.png"
        ></ModuleHeader>

        <section>
          <SectionHeader title="Commands" pretitle="Search"></SectionHeader>

          <div className="section-content-normal">
            <div class="" style={{ marginBottom: "15px" }}>
              <h3 style={{ marginBottom: "0px" }}>YouTube Data API Key</h3>
              <div class="section-content-header">
                <p>
                  A YouTube Data API key is required to search for videos on
                  YouTube, you can find out how to create a key{" "}
                  <a
                    href="https://www.youtube.com/watch?v=sVEytWDWYwM"
                    target="_blank"
                    className="text-red font-semibold hover:font-bold hover:text-red hover:no-underline hover:cursor-pointer"
                  >
                    here
                  </a>
                </p>
              </div>
            </div>

            <div class="long-input">
              <label>API Key</label>
              <input
                value={this.props.search.youtube.key}
                onChange={(e) => {
                  this.keyChange(e.target.value);
                }}
                type="text"
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6">
              <Command
                name="twitch"
                description="Search your favorite Twitch streamers"
                update={this.commandUpdate}
                options={this.props.search.twitch}
                module="search"
                toggleValue={this.props.search.twitch.enabled}
                slashOnly={true}
                showPrefix={true}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="youtube"
                description="Search for YouTube videos"
                update={this.commandUpdate}
                options={this.props.search.youtube}
                module="search"
                toggleValue={this.props.search.youtube.enabled}
                slashOnly={true}
                showPrefix={true}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="urban"
                description="Search for definitions on Urban Dictionary"
                update={this.commandUpdate}
                options={this.props.search.urban}
                module="search"
                toggleValue={this.props.search.urban.enabled}
                slashOnly={true}
                showPrefix={true}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="imgur"
                description="Search for pictures and memes on imgur"
                update={this.commandUpdate}
                options={this.props.search.imgur}
                module="search"
                toggleValue={this.props.search.imgur.enabled}
                showPrefix={true}
                slashOnly={true}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="giphy"
                description="Search for Gifs on Giphy"
                update={this.commandUpdate}
                options={this.props.search.giphy}
                module="search"
                toggleValue={this.props.search.giphy.enabled}
                showPrefix={true}
                slashOnly={true}
              ></Command>
            </div>
          </div>
        </section>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  search: state.data.bot.commands.search,
  beta: state.data.beta,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
