import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../../../api/server';

import history from "../../../history";

export class BuilderExitModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            code: "",
            error: "",
            disabled: false
        };
    }

    componentDidMount() {
        // navigator.clipboard.readText()
        //     .then(text =>{
        //         console.log(text)
        //     })
        //     .catch((e) =>{
        //         console.log(e)
        //     })
    }

    modalBackgroundClick = (e) => {
        // console.log("MODAL BACKGROUND CLICK");
        if (e.currentTarget == e.target) {
            this.props.closeModal(e);
        }
    };



    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} onClick={(e) => { this.modalBackgroundClick(e); }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div class="modal-dialog premium-modal-dialog" role="document">
                        <div class="modal-content premium-modal" style={{ backgroundColor: "#f8f8fb" }}>


                            <div class="modal-body command-edit-body premium-modal-body">
                                <div className="premium-modal-content" style={{ textAlign: "center" }}>
                                    <h2 className="text-2xl font-bold">Exit {`${this.props.mode == "event" ? "Event" : "Command"}`} Builder</h2>
                                    <p className="text-muted mb-3 font-semibold">Are you sure you want to leave the {`${this.props.mode == "event" ? "event" : "command"}`} builder? You will lose any unsaved changes.</p>
                                    <span style={{ color: "red", fontWeight: "800" }}>{this.state.error}</span>

                                    <div>
                                        {/* <button className="btn btn-gray" type="button" onClick={() => { this.props.closeModal(); }}>Close</button> */}

                                        <button className="btn btn-red ml-2" type="button" onClick={() => {
                                            // console.log(history.goBack());
                                            history.goBack();
                                            // history.push(`/dashboard/${this.props.mode == "event" ? "customevents" : "customcommands"}`);

                                        }}>Leave Builder</button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    mode: state.builder.mode,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(BuilderExitModal);
