import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from '../Dashboard';
import ModuleHeader from '../elements/ModuleHeader';
import SectionHeader from '../elements/SectionHeader';
import WebhookItem from '../elements/WebhookItem';
import server from '../../../api/server';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { setBotModule } from '../../../actions';
import DocsLink from '../elements/DocsLink';



export class Webhooks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            key: "1234",
            showKey: false,
            buttonsDisabled: false,
            showIndex: null

        };
    }


    regenerate = async () => {
        this.setState({ buttonsDisabled: true });
        var response = await server.post("/dashboard/generateAPIKey", {
            bot_id: this.props.bot.id,
            cocreate: this.props.cocreate
        });
        if (response.data.success) {
            this.setState({ key: response.data.key, showKey: true });
        }

        this.setState({ buttonsDisabled: false });
    };

    showKey = async () => {
        this.setState({ buttonsDisabled: true });
        if (this.state.showKey) {
            this.setState({ showKey: false, key: "" });
        } else {
            var response = await server.post("/dashboard/fetchAPIKey", {
                bot_id: this.props.bot.id,
                cocreate: this.props.cocreate
            }
            );
            if (response.data.success) {
                this.setState({ key: response.data.key, showKey: true });
            }

        }

        this.setState({ buttonsDisabled: false });
    };


    renderWebhooks = () => {
        var webhooks = [];

        this.props.webhooks.webhooks.forEach((webhook, index) => {
            webhooks.push(
                <div>
                    <div className="announcement">
                        <div
                            className="announcement-header"
                            onClick={(e) => {
                                this.setState({
                                    showIndex: this.state.showIndex == index ? null : index,
                                });
                            }}
                        >
                            <div className="w-full">
                                <h3 className="mb-0">{webhook.name} </h3>
                                <div className="section-content-header overflow-clip w-full">https://api.botghost.com/webhook/{this.props.bot.id}/{webhook.id}</div>
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                                {this.state.showIndex == index ? (
                                    <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i>
                                ) : (
                                    <i
                                        style={{ fontSize: "20px" }}
                                        class="bi bi-caret-down-fill"
                                    ></i>
                                )}
                            </div>
                        </div>

                        {this.state.showIndex == index ? (
                            <div className="announcement-content section-content-normal">
                                <WebhookItem index={index} webhook={webhook} delete={(index) => {
                                    var webhooks = { ...this.props.webhooks };
                                    webhooks.webhooks.splice(index, 1);
                                    this.props.setBotModule("webhooks", webhooks);
                                }} onChange={(data, index) => {
                                    var webhooks = { ...this.props.webhooks };
                                    webhooks.webhooks[index] = {
                                        id: webhook.id,
                                        name: data.name,
                                    };

                                    this.props.setBotModule("webhooks", webhooks);
                                    this.setState({ showIndex: null });
                                }} />
                            </div>
                        ) : null}
                    </div>

                </div>




            );

        });

        return webhooks;
    };
    render() {
        return (
            <Dashboard>
                <ModuleHeader description={'Trigger custom events through webhooks'}
                    title={"Webhooks"}
                    showDocs={true}
                    docs="https://docs.botghost.com/custom-commands-and-events/webhooks"
                    showEnabled={false}
                    tutorial="https://docs.botghost.com/custom-commands-and-events/webhooks"
                    icon={"webhooks.png"} />



                <section>
                    <SectionHeader title="API Key" pretitle="Webhooks" />


                    <div className="p-[28px] bg-[#2f3136] mt-[25px] rounded-[12px]">
                        <div class="">
                            <h3 style={{ marginBottom: "0px" }} className="font-bold text-white">API Key <DocsLink location="https://docs.botghost.com/custom-commands-and-events/webhooks#api-key" /></h3>

                            <div className="section-content-header mb-2">
                                The event id to pass along to the HTTP request
                            </div>
                            <div className={`long-input ${this.props.showIcon ? 'long-input-error' : ''}`}>

                                <div className="relative">
                                    <input
                                        required
                                        type={this.state.showKey ? 'text' : 'password'}
                                        value={this.state.showKey ? this.state.key : "**************************************************"}
                                        disabled={true}
                                        // maxLength={this.props.settings.maxLength}
                                        // placeholder={this.props.settings.placeholder || ""}
                                        onChange={(e) => {
                                            this.props.change(e.target.value);
                                        }}
                                    >

                                    </input>

                                </div>
                            </div>
                            <div className='flex' >
                                <div className='ml-auto'>
                                    <button disabled={this.props.cocreate || this.state.buttonsDisabled} className="btn-red mt-3 mr-2 rounded" onClick={() => {
                                        this.showKey();
                                    }}>{this.state.showKey ? "Hide" : "Show"}</button>
                                    <button disabled={this.props.cocreate || this.state.buttonsDisabled} className="btn-gray mt-3 rounded" onClick={() => {
                                        this.regenerate();
                                    }}>Regenerate</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </section>

                <section>

                    <SectionHeader title="New Webhook" pretitle="Webhooks" />

                    <div>
                        <div className="section-content-normal">
                            <div class="mb-15">
                                <h3 style={{ marginBottom: "0px", fontSize: "26px" }}>
                                    Add a new webhook <DocsLink location="https://docs.botghost.com/custom-commands-and-events/webhooks#1.-creating-a-webhook" />
                                </h3>
                                <p className="text-white opacity-75 mb-2">
                                    Webhooks allow you to trigger custom events through a simple POST HTTP request. You can use them to integrate other services into your BotGhost bot. You can also post data to your bot. To learn more about webhooks and how to trigger them, check out the <a className="text-red font-bold" href="https://docs.botghost.com/custom-commands-and-events/webhooks" target="_blank">documentation</a>.
                                </p>

                                <p className="text-white opacity-75">Once you have added a webhook, create a <Link className="text-red font-bold" to="/dashboard/customevents" >custom event</Link> to be triggered when this webhook is executed.</p>
                            </div>


                            <WebhookItem index="new" onChange={(webhook, index) => {
                                var webhooks = { ...this.props.webhooks };

                                webhooks.webhooks.push({
                                    id: webhook.id,
                                    name: webhook.name,
                                });

                                this.props.setBotModule({
                                    module: "webhooks",
                                    module_data: webhooks,
                                });
                            }} />
                        </div>



                        {/* {this.props.value.length > 0 ? <section>
                            <SectionHeader title={`${this.props.settings.active_title} ${this.props.settings.slot_limit ? `${this.props.value.length}/${this.renderSlotLimit()}` : ""}`} pretitle={this.props.settings.active_pretitle} />
                            {this.renderSlots()}
                        </section> : null} */}
                    </div>
                </section>


                <section>
                    <SectionHeader title="Active Webhooks" pretitle="Webhooks"></SectionHeader>


                    <div>
                        {this.renderWebhooks()}
                    </div>
                </section>
            </Dashboard >
        );
    }
};

const mapStateToProps = (state) => ({
    bot: state.data.bot,
    cocreate: state.data.co_create,
    webhooks: state.data.bot.commands.webhooks
});

const mapDispatchToProps = {
    setBotModule
};

export default connect(mapStateToProps, mapDispatchToProps)(Webhooks);;;