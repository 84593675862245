import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import history from '../../../history';
import { setQuickStart } from "../../../actions"
import { TASKS } from "../../../variables"
import ReactGA from 'react-ga';
export class QuickStart extends Component {

    constructor(props) {
        super(props)
        this.state = {
            length: 0,
            percent: 20,
            tasks_left: "1"
        }


    }

    componentDidMount() {
        ReactGA.initialize('UA-122665050-1');
        ReactGA.event({
            category: "Quick Start",
            label: `Open`,
            action: "New Dashboard"
        })
        const length = TASKS.length;
        var completed = 0;
        TASKS.forEach(task => {
            if (this.props.quick_start[task.slug] != undefined && this.props.quick_start[task.slug] == true) {
                completed += 1;
            }
        })

        this.setState({
            length: length,
            percent: completed / length * 100,
            tasks_left: length - completed
        })
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.quick_start != this.props.quick_start) {
            const length = TASKS.length;
            var completed = 0;
            TASKS.forEach(task => {
                if (this.props.quick_start[task.slug] != undefined && this.props.quick_start[task.slug] == true) {
                    completed += 1;
                }
            })

            this.setState({
                length: length,
                percent: completed / length * 100,
                tasks_left: length - completed
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.props.close()
        }
    }

    completeTask = (e, slug) => {
        e.stopPropagation()
        var quick_start = { ...this.props.quick_start }
        quick_start[slug] = true;
        // console.log(quick_start)
        this.props.setQuickStart(quick_start)
    }

    close = () => {
        this.props.close()
    }

    taskClick = (e, task) => {
        const action = task.action;
        if (action == "page") {
            history.push("/dashboard/" + task.page)
        } else if (action == "invite") {
            window.open(`https://discord.com/oauth2/authorize?client_id=${this.props.bot.id}&scope=bot&permissions=8`)
            this.completeTask(e, task.slug)
        } else if (action == "doc") {
            window.open(task.page)
        }

        this.close()
    }


    renderTasks = () => {
        var tasks = []
        TASKS.forEach(task => {
            if (this.props.quick_start[task.slug] == undefined || (this.props.quick_start[task.slug] != undefined && this.props.quick_start[task.slug] == false)) {
                tasks.push(
                    <div className="quick-start-card" onClick={(e) => { this.taskClick(e, task) }} >
                        <div className="quick-start-close" onClick={(e) => { this.completeTask(e, task.slug) }}><FontAwesomeIcon icon={faTimes} /></div>
                        <h2>{task.header}</h2>
                        <div>{task.description}</div>
                        <div>
                            <button style={{ float: "right", padding: "3px 15px" }} className="btn btn-red">{task.button_text || "Start"}</button>
                        </div>
                    </div>
                )
            }

        })

        return tasks;
    }

    renderCompleted = () => {
        var completed = []
        TASKS.forEach(task => {
            if (this.props.quick_start[task.slug] != undefined && this.props.quick_start[task.slug] == true) {
                completed.push(
                    <div className="quick-start-completed" onClick={(e) => { this.taskClick(e, task) }}>
                        <div>
                            <FontAwesomeIcon icon={faCheckCircle} color={"green"} />
                            <span>{task.header}</span>
                        </div>
                    </div>
                )
            }

        })

        return completed
    }
    render() {
        return (
            <div className="quick-start custom-scrollbar" style={{ marginTop: `${this.props.bot.premium == true ? "60px" : "90px"}` }}>
                <header>
                    <div style={{ width: "30%", marginRight: "15px" }}>
                        <CircularProgressbar value={this.state.percent} text={this.state.tasks_left || "0"} styles={buildStyles({
                            textSize: "30px",
                            fontWeight: "800",
                            pathColor: "#f45142",
                            textColor: "#f45142"
                        })} />
                    </div>
                    <div>
                        <h3>Quick Start</h3>
                        <div>Learn how to use BotGhost to get the most out of your bot.</div>
                    </div>
                </header>

                <main>
                    <section>
                        <h4>The Basics</h4>
                        <div>
                            {this.renderTasks()}
                        </div>
                    </section>

                    <section>
                        <h4>Completed</h4>
                        <div>
                            {this.renderCompleted()}
                        </div>
                    </section>
                </main>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    quick_start: state.quick_start,
    bot: state.data.bot
})

const mapDispatchToProps = {
    setQuickStart
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickStart)
