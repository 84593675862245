import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';

import Embed from '../../elements/Embed';
import Select from '../../elements/Select';

const STYLES = [
    { value: "PRIMARY", label: "Blue" },
    { value: "SECONDARY", label: "Gray" },
    { value: "SUCCESS", label: "Green" },
    { value: "DANGER", label: "Red" },
    { value: "LINK", label: "Link" },
];



export class SelectMenuOption extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        label: "",
                        menu_type: "basic",
                        minOptions: "1",
                        maxOptions: "1",
                        style: "",
                        emoji_id: "",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        label: "",
                        menu_type: "basic",
                        minOptions: "1",
                        maxOptions: "1",
                        style: "",
                        emoji_id: "",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

            // if ((this.props.data.embed.title != "" && this.props.data.embed.description != "") && this.props.data.validated != true) {
            //     this.updateData("validated", true)
            // } else if (this.props.data.validated == true && (this.props.data.embed.title == "" || this.props.data.embed.description == "")) {
            //     this.updateData("validated", false)

            // }
        }
    }

    nameChange = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "-");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("name", value);
    };

    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };


    // updateType = (type) => {
    //         var buttons = [...this.props.data.buttons];
    //         buttons[index].style = type;
    //         this.props.update("buttons", buttons)
    //     }


    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Select Menu Option</h4>
                        <p>Run attached Actions when a user selects this menu option.</p>
                    </div>


                    <div className="mb-15">
                        <h4 style={{ marginBottom: "0px" }}>Label <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-select-menu#label" /> </h4>
                        <div className="section-content-header mb-15">The label of the menu option. All options and variables can be used.</div>
                        <div className="long-input">
                            <label>Text</label>
                            <input maxLength={100} required type="text" value={this.props.data.label} onChange={(e) => {
                                var value = e.target.value;
                                this.updateData("label", value);

                            }}></input>
                        </div>
                    </div>

                    <div className="mb-15">
                        <h4 style={{ marginBottom: "0px" }}>Description <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-select-menu#description" /> </h4>
                        <div className="section-content-header mb-15">The optional description of this menu option. All options and variables can be used.</div>
                        <div className="long-input">
                            <label>Description</label>
                            <input maxLength={100} required type="text" value={this.props.data.description} onChange={(e) => {
                                var value = e.target.value;
                                this.updateData("description", value);

                            }}></input>
                        </div>
                    </div>


                    <>

                        <div className="mb-15">
                            <h4 style={{ marginBottom: "0px" }}>Emoji Id<DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-buttons#button-text" /> </h4>
                            <div className="section-content-header mb-15">An optional emoji id to set for this option.</div>
                            <div className="long-input">
                                <label>Emoji</label>
                                <input required type="text" value={this.props.data.emoji_id ? this.props.data.emoji_id : ""} onChange={(e) => {
                                    var value = e.target.value;
                                    // value = value.substr(0, 32);
                                    this.updateData("emoji_id", value);

                                }}></input>
                            </div>
                        </div></>



                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectMenuOption);
