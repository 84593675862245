import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setRootData } from '../../../../actions';

import TextArea from '../../elements/TextArea';
import Select from '../../elements/Select';
import RoleAdd from '../../elements/RoleAdd';

const EPHEMERAL_OPTIONS = [
    { value: "true", label: "Hide replies" },
    { value: "false", label: "Show replies to everyone." },
];

const COOLDOWN_OPTIONS = [
    { value: "disabled", label: "No Cooldown" },
    { value: "user", label: "User Cooldown" },
    { value: "server", label: "Server Wide Cooldown" },
];

const COOLDOWN_INTERVAL_OPTIONS = [
    { value: "seconds", label: "Seconds" },
    { value: "minutes", label: "Minutes" },
    { value: "hours", label: "Hours" },
];

export class Command extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        ephemeral: false,
                        permissions: {
                            allowed_roles: [{ name: '@everyone', id: 'everyone' }],
                            banned_roles: [],
                            banned_channels: [],
                            banned_users: [],
                            required_permissions: []
                        },
                        cooldown: {
                            type: "disabled",
                            interval: 1,
                            interval_type: "minutes"
                        }
                    },
                    handleErrors: true,
                    errors: [],
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        ephemeral: false,
                        permissions: {
                            allowed_roles: [{ name: '@everyone', id: 'everyone' }],
                            banned_roles: [],
                            banned_channels: [],
                            banned_users: [],
                            required_permissions: []
                        },
                        cooldown: {
                            type: "disabled",
                            interval: 1,
                            interval_type: "minute"
                        }
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

            if ((this.props.data.response != "") && this.props.data.validated != true) {
                this.updateData("validated", true);
            } else if (this.props.data.validated == true && (this.props.data.response == "")) {
                this.updateData("validated", false);

            }
        }
    }

    nameChange = (value) => {
        // Allow 1 space and if it has a space, split it in two and then re join with the space.
        console.log("NAME CHANGES");
        value = value.split(" ");
        console.log(value, 'SPLIT');
        var command = value[0];
        var subcommand = "";
        command = command.toLowerCase();
        command = command.substring(0, 32);
        command = command.replace(" ", "-");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        command = command.replace(regexForNonAlphaNum, "");
        if (value.length > 1) {
            // All others
            value.shift();
            subcommand = value.join(" ");
            subcommand = subcommand.toLowerCase();
            subcommand = subcommand.substring(0, 32);
            subcommand = subcommand.replace(" ", "-");
            const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
            subcommand = subcommand.replace(regexForNonAlphaNum, "");
            value = command + " " + subcommand;

        } else {
            value = command;
        }


        // const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        // command = command.replace(regexForNonAlphaNum, "");




        // value = value.toLowerCase();
        // value = value.substring(0, 32);
        // // value = value.replace(" ", "-");
        // // value = value.replace(/[^\w]+/gi,"")
        // // value = value.replace(/[^[A-zÀ-Ÿ\d-]+/gi,"")
        // const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        // value = value.replace(regexForNonAlphaNum, "");
        this.updateRootInfo("title", value);
    };

    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    updateSelect = (value) => {
        // console.log(value);
        if (value == "true") {
            this.updateData('ephemeral', true);
        } else {
            this.updateData('ephemeral', false);
        }
    };

    roleAddChange = (array, key) => {
        const permissions = { ...this.props.data.permissions };
        permissions[key] = array;
        this.updateData("permissions", permissions);
        // console.log(permissions)
        // this.props.update(permissions)
    };

    updateRootInfo = (key, value) => {
        // console.log(key, value);
        this.props.setRootData({ key: key, value, value });
    };



    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Command Settings</h4>
                        <p>Drag and drop <span style={{ color: "#358deb" }}>Actions</span> and <span style={{ color: "#8d0eab" }}>Options</span> to add them to your command. Connect each nodes of corrosponding colors to create your command. </p>

                        <p><span style={{ color: "#8d0eab" }}>Options</span> allow you to get input from the user.</p>

                        <p><span style={{ color: "#358deb" }}>Actions</span> are what your bot executes when the command is run.</p>
                    </div>

                    <hr style={{ borderTop: "1px solid #7878786b" }}></hr>

                    <div className="mb-15">
                        <h4>Trigger</h4>
                        <p>The trigger and name of your custom command.</p>
                        <div className="long-input">
                            <label>Trigger</label>
                            <input className={`${this.props.name == "" && this.props.saveFailed ? "command-required" : ""}`} required type="text" value={this.props.name} onChange={(e) => this.nameChange(e.target.value)}></input>
                        </div>
                    </div>

                    <div className="mb-15">
                        <h4>Description</h4>
                        <p>The description of what the command does.</p>
                        <div className="long-input">
                            <label>Description</label>
                            <input maxLength={99} className={`${this.props.description == "" && this.props.saveFailed ? "command-required" : ""}`} required type="text" value={this.props.description} onChange={(e) => this.updateRootInfo("description", e.target.value)}></input>
                        </div>
                    </div>

                    <hr style={{ borderTop: "1px solid #7878786b" }}></hr>


                    <div className="mb-15">
                        <div className="slash-action">
                            <h4>Hide bot's replies from everyone but the executor of the slash command.  <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions"} /></h4>
                            <p>Hides the replies of the bot from everyone except the person who triggered the command. Will not work for targeted responses and DM actions.</p>
                        </div>
                        <div>
                            <Select value={this.props.data.ephemeral == true ? "true" : "false"} options={EPHEMERAL_OPTIONS} onChange={(value) => { this.updateSelect(value); }} />
                        </div>
                    </div>

                    <hr style={{ borderTop: "1px solid #7878786b" }}></hr>


                    <div className="mb-15">
                        <div className="slash-action">
                            <h4>Command Cooldown</h4>
                            <p>Set either a User or Server wide cooldown for this command. This will only apply to the command and not any buttons or select menus.</p>
                        </div>
                        <div className='mb-15'>
                            <Select value={this.props.data.cooldown.type} options={COOLDOWN_OPTIONS} onChange={(value) => {
                                this.updateData("cooldown", { type: value, interval: this.props.data.cooldown.interval, interval_type: this.props.data.cooldown.interval_type });
                            }} />
                        </div>

                        {this.props.data.cooldown.type == "user" || this.props.data.cooldown.type == "server" ?
                            <div className=''>
                                <div className="mb-15">
                                    <div className="slash-action">
                                        <h4>Cooldown Interval</h4>
                                        <p>The interval of this cooldown. Either hours, minutes or seconds.</p>
                                    </div>
                                    <div>
                                        <Select value={this.props.data.cooldown.interval_type} options={COOLDOWN_INTERVAL_OPTIONS} onChange={(value) => {
                                            this.updateData("cooldown", { type: this.props.data.cooldown.type, interval: this.props.data.cooldown.interval, interval_type: value });
                                        }} />
                                    </div>
                                </div>

                                <div className="mb-15">
                                    <div className="slash-action">
                                        <h4>Cooldown Time</h4>
                                        <p>The amount of {this.props.data.cooldown.interval_type}.</p>
                                    </div>
                                    <div className="long-input">
                                        <label>Time</label>
                                        <input required type="number" value={this.props.data.cooldown.interval} onChange={(e) => {
                                            this.updateData("cooldown", { type: this.props.data.cooldown.type, interval: e.target.value, interval_type: this.props.data.cooldown.interval_type });
                                        }}></input>
                                    </div>
                                </div>

                            </div> : null}
                        <hr style={{ borderTop: "1px solid #7878786b" }}></hr>
                    </div>


                    <div>
                        <h4>Permission Options</h4>
                        <p>Set permissions for your command to restrict who can use it.</p>

                        <div className="">
                            <div className="row">

                                <div className="command-edit-section col-lg-12 mb-15">
                                    <div className="mb-15 command-edit-section-header">
                                        <h4>Allowed Roles</h4>
                                        <p>Users with these roles can use the command. Adding @everyone will mean anyone can use the command.</p>
                                    </div>

                                    <RoleAdd
                                        type="role"
                                        items={this.props.data.permissions.allowed_roles}
                                        update={this.roleAddChange}
                                        option="allowed_roles"
                                    ></RoleAdd>
                                </div>

                                <div className="command-edit-section col-lg-12 mb-15">
                                    <div className="mb-15 command-edit-section-header">
                                        <h4>Banned Roles</h4>
                                        <p>Users with these roles can't use the command.</p>
                                    </div>

                                    <RoleAdd
                                        type="role"
                                        items={this.props.data.permissions.banned_roles}
                                        update={this.roleAddChange}
                                        option="banned_roles"
                                    ></RoleAdd>
                                </div>


                                <div className="command-edit-section col-lg-12 mb-15">
                                    <div className="mb-15 command-edit-section-header">
                                        <h4>Required Permissions</h4>
                                        <p>Users require these server permissions to use this command.</p>
                                    </div>

                                    <RoleAdd
                                        type="permissions"
                                        items={this.props.data.permissions.required_permissions}
                                        update={this.roleAddChange}
                                        option="required_permissions"
                                    ></RoleAdd>
                                </div>

                                <div className="command-edit-section col-lg-12 mb-15">
                                    <div className="mb-15 command-edit-section-header">
                                        <h4>Banned Channels</h4>
                                        <p>The command will not work in these channels.</p>
                                    </div>

                                    <RoleAdd
                                        type="channels"
                                        items={this.props.data.permissions.banned_channels}
                                        update={this.roleAddChange}
                                        option="banned_channels"
                                    ></RoleAdd>
                                </div>

                            </div>
                        </div>
                    </div>




                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    beta: state.data.beta
});
const mapDispatchToProps = {
    updateElementData,
    setRootData
};

export default connect(mapStateToProps, mapDispatchToProps)(Command);
