import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';

import TextArea from '../../elements/TextArea';
import Embed from '../../elements/Embed';
import Select from '../../elements/Select';
import RefreshBotData from '../../elements/RefreshBotData';
import EmojiAdder from './EmojiAdder';
import { checkIntegrationChild } from '../eventUtils';
import VariableTextInput from '../VariableTextInput';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { successToast, errorToast, loadingToast } from "../toast.js";

export class TargetedResponse extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            var target = { user_id: "" };
            if (this.props.components['User']) {
                target = { self: true };
            }
            this.props.updateElementData(
                {
                    data: {
                        type: "dm_response",
                        response_options: {
                            response: "",
                            emojis: []
                        },
                        target: target,
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "dm_response",
                        response_options: {
                            response: "",
                            emojis: []
                        },
                        target: {
                            self: true,
                            // variable:"{option_target}",
                            // user_id:"136327647792726016"
                        },
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else if (!("target" in this.props.data)) {
            this.updateData("target", { self: true });
        } else if (!("response_options" in this.props.data)) {
            this.updateData("response_options", { response: "", emojis: [] });
        }
        else {
            // VALIDATE

            if (
                (('response' in this.props.data.response_options && this.props.data.response_options.response != "")
                    || ('embed' in this.props.data.response_options && 'description' in this.props.data.response_options.embed && this.props.data.response_options.embed.description != ""))
            ) {
                if (this.props.data.validated == false && this.validateTarget()) {
                    // console.log("VALIDATE", 1);
                    this.updateData("validated", true);
                } else if (this.props.data.validated == true && !this.validateTarget()) {
                    // console.log("VALIDATE", 2);
                    this.updateData("validated", false);
                }
            } else if (this.props.data.validated == true) {
                // console.log("VALIDATE", 3);
                this.updateData("validated", false);
            }
        }
    }

    validateTarget = () => {
        if ("target" in this.props.data) {
            if ("self" in this.props.data.target) {
                return true;
            } else if ("variable" in this.props.data.target && this.props.data.target.variable != "") {
                return true;
            } else if ("user_id" in this.props.data.target && this.props.data.target.user_id != "") {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    nameChange = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "-");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("name", value);
    };

    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    renderResponseTypes = () => {
        var user = "User who used the command";
        if (this.props.mode == "event" && this.props.components['User']) {
            user = "The user.";
        }
        // const responseTypes = [
        //     { value: "reply", label: "Reply to the command", disabled: this.props.components.Interaction? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
        //     { value: "trigger_channel", label: `Send the message to the ${channel}`, disabled: this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true }
        // ];

        const responseTypes = [
            { label: user, value: "self", disabled: this.props.components.Interaction || this.props.components.User ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
            { label: "User option", value: "variable", disabled: !this.props.components.Interaction },
            { label: "User ID", value: "id" },
        ];
        return responseTypes;

    };

    renderDisableField = () => {
        var disabled = true;
        if (this.props.data.target.reply == true && (this.props.components.Interaction || checkIntegrationChild(this.props.elements, this.props.id))) {
            disabled = false;
        } else if (this.props.data.target.trigger_channel == true && (this.props.components.Channel || checkIntegrationChild(this.props.elements, this.props.id))) {
            disabled = false;
        }
        return disabled;
    };

    changeType = () => {
        var response_options = { ...this.props.data.response_options };
        if ("response" in this.props.data.response_options) {
            response_options = {
                embed: {

                },
                emojis: response_options.emojis || []
            };
        } else {
            response_options = {
                response: "",
                emojis: response_options.emojis || []
            };
        }
        this.updateData("response_options", response_options);

    };

    updateResponseOptions = (key, value) => {
        var response_options = { ...this.props.data.response_options };
        response_options[key] = value;
        this.updateData("response_options", response_options);

    };

    renderOptions = () => {
        var options = [
            // {value:"trigger",label:"The member who triggered the command"}
        ];
        this.props.options.forEach(option => {
            if (option.type == "channel") {
                options.push({ value: { variable: option.name }, label: `Channel Option: ${option.name}` });

            }

        });
        return options;
    };

    selectUpdate = (value) => {
        // console.log("selected ");
        value = JSON.parse(value);
        // console.log(value);
        var target = { ...this.props.data.target };
        target = value;
        this.updateData("target", target);
    };

    targetOnChange = (value) => {
        // {label:"User who used the command",value:"self"},
        // {label:"User variable",value:"variable"},
        // {label:"User ID",value:"id"},
        if (value == "self") {
            var data = { ... this.props.data };
            data.target = {
                self: true,
            };
            this.props.updateElementData(
                {
                    data: data,
                    id: this.props.id
                }
            );

        } else if (value == "variable") {
            var data = { ... this.props.data };
            data.target = {
                variable: "",
            };
            this.props.updateElementData(
                {
                    data: data,
                    id: this.props.id
                }
            );
        } else if (value == "id") {
            var data = { ... this.props.data };
            data.target = {
                user_id: '',
            };
            this.props.updateElementData(
                {
                    data: data,
                    id: this.props.id
                }
            );
        }
    };

    render() {
        if (this.props.data != undefined && 'target' in this.props.data && 'response_options' in this.props.data) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Direct Message a User</h4>
                        <p>Send either a plain text or embed direct message to a member. </p>
                    </div>

                    <div className='mb-15 slash-action'>
                        <h4>User to DM <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/direct-message-a-user#user-to-dm" /></h4>
                        <span>Which user to send a direct message to.</span>
                        <Select value={
                            'self' in this.props.data.target ? 'self' : 'variable' in this.props.data.target ? 'variable' : 'user_id' in this.props.data.target ? 'id' : ''
                        } onChange={(value) => {
                            this.targetOnChange(value);
                            // value = JSON.parse(value);
                            // this.setState({value:value})
                        }} options={this.renderResponseTypes()} ></Select>

                    </div>

                    {
                        'variable' in this.props.data.target ?
                            <div className="mb-15 slash-action">
                                <h4>User Option </h4>
                                <span>The user option to send a direct message to.</span>
                                {/* <div class="long-input mt-15">
                                    <label>Option</label>
                                    <input className={this.props.saveFailed && this.props.data.target.variable == "" ? "command-required" : ""} onChange={(e) => {

                                        var data = { ... this.props.data };
                                        data.target = {
                                            variable: e.target.value,
                                        };
                                        this.props.updateElementData(
                                            {
                                                data: data,
                                                id: this.props.id
                                            }
                                        );

                                    }} type="text" placeholder={"{option_user}"} value={this.props.data.target.variable} />
                                </div> */}

                                <VariableTextInput slash_options={this.props.options} placeholder={"{option_user}"} label="Option" value={this.props.data.target.variable} onChange={(value) => {
                                    var data = { ... this.props.data };
                                    data.target = {
                                        variable: value,
                                    };
                                    this.props.updateElementData(
                                        {
                                            data: data,
                                            id: this.props.id
                                        }
                                    );
                                }
                                } />

                            </div> : null
                    }

                    {
                        'user_id' in this.props.data.target ?
                            <div className="mb-15 slash-action">
                                <h4>User ID </h4>
                                <span>The user id to send a direct message to.</span>
                                {/* <div class="long-input mt-15">
                                    <label>ID</label>
                                    <input className={this.props.saveFailed && this.props.data.target.user_id == "" ? "command-required" : ""} onChange={(e) => {

                                        var data = { ... this.props.data };
                                        data.target = {
                                            user_id: e.target.value,
                                        };
                                        this.props.updateElementData(
                                            {
                                                data: data,
                                                id: this.props.id
                                            }
                                        );

                                    }} type="text" placeholder={"136327647792726016"} value={this.props.data.target.user_id} />
                                </div> */}

                                <VariableTextInput placeholder={"136327647792726016"} label="ID" value={this.props.data.target.user_id} onChange={(value) => {
                                    var data = { ... this.props.data };
                                    data.target = {
                                        user_id: value,
                                    };
                                    this.props.updateElementData(
                                        {
                                            data: data,
                                            id: this.props.id
                                        }
                                    );
                                }
                                } />

                            </div> : null
                    }

                    <div className="justify-space-between mb-15">
                        <h4 style={{ marginBottom: "0px" }}>Message <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/direct-message-a-user#message"}></DocsLink></h4>

                        <div className="justify-space-between">
                            <p style={{ marginInlineEnd: "12px", marginBottom: "0px" }}>{this.props.data.response_options.embed != undefined ? "Embed" : "Plain Text"}</p>
                            <Toggle value={this.props.data.response_options.response != undefined ? false : true} update={(value) => { this.changeType(); }} type="type"></Toggle>
                        </div>
                    </div>
                    {'response' in this.props.data.response_options ? <TextArea extra_classNames={`${this.props.data.response_options.response == "" && this.props.saveFailed ? "command-required" : ""}`} variableEditor={true} slash={true} slash_options={this.props.options} value={this.props.data.response_options.response} onChange={(value) => { this.updateResponseOptions("response", value); }} /> : <Embed key={this.props.id} variableEditor={true} slash={true} options={this.props.options} update={(value) => { this.updateResponseOptions("embed", value); }} value={this.props.data.response_options.embed} />}

                    <div className='mt-15'>
                        <h4 style={{ marginBottom: "0px" }}>Reactions<DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/direct-message-a-user#reactions"}></DocsLink></h4>
                        <div className="section-content-header mb-15">Reactions to add to the message.</div>
                        <EmojiAdder emojis={this.props.data.response_options.emojis || []} update={(newEmojis) => {
                            var data = { ...this.props.data };
                            data.response_options.emojis = newEmojis;
                            this.props.updateElementData(
                                {
                                    data: data,
                                    id: this.props.id
                                }
                            );
                        }} />

                    </div>

                    <hr className="slashcommand-hr"></hr>
                    <div className='mt-15'>
                        <h4 style={{ marginBottom: "0px" }}>Optional Variable</h4>
                        <div className="section-content-header mb-15">An optional variable to return the message id of this reply. You can use this variable in other blocks.</div>
                        <div class="long-input mb-15">
                            <label>Variable</label>
                            <input className={`${this.props.data.variable == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                // if (!isNaN(e.target.value) && e.target.value <= 850) {
                                var value = e.target.value;
                                value = value.toLowerCase();
                                value = value.substring(0, 32);
                                value = value.replace(" ", "_");
                                const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
                                value = value.replace(regexForNonAlphaNum, "");

                                this.updateData("variable", value);
                            }} type="text" value={this.props.data.variable ? this.props.data.variable : ""} />
                        </div>

                        {
                            this.props.data.variable ? <div>
                                <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>



                                <h4>Message Id Variable</h4>
                                <p className="mb-15" style={{
                                    color: "#fff",
                                    opacity: "0.7",
                                    fontWeight: 500
                                }}>You can use the variable <span style={{ color: "#f45142", opacity: "1 !important" }}>{`{${this.props.data.variable}}`}</span><FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.variable}}`); successToast("Copied variable to clipboard"); }} /> in other blocks.</p>

                            </div> : null
                        }
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    beta: state.data.beta,
    components: state.builder.components,
    mode: state.builder.mode,
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetedResponse);
