import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";
import { TextArea } from "../elements/TextArea";
import Select from "../elements/Select";
import CommandAdd from "../elements/CommandAdd";
import RoleAdd from "../elements/RoleAdd";

import Dashboard from "../Dashboard";

import { setBotModule } from "../../../actions";
import { ChromePicker } from "react-color";

import PremiumRedirect from "../elements/PremiumRedirect";
import PremiumSection from "../elements/PremiumSection";
import PremiumBadge from "../elements/PremiumBadge";
import { Premium } from "./Premium";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { ModuleDisabled } from "../elements/ModuleDisabled";

const level_up_options = [
  { label: "No Message", value: "no_message" },
  { label: "Plain Text Announcement in Channel", value: "current_channel" },
];

export class Levels extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorPickerKey: "",
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    if (!("messages" in this.props.levels.options.level_up)) {
      const levels = { ...this.props.levels };
      levels.options.level_up.messages = [];
      this.props.setBotModule({
        module: "levels",
        module_data: levels,
      });
    }
  }

  addLevelsRole = () => {
    const levels_roles = [...this.state.levels_roles];
    levels_roles.push({
      requirement: 5,
      roles: [],
    });

    this.setState({ levels_roles: levels_roles });
  };

  // renderLevelRoles = () =>{
  //     const levels_roles = []
  //     this.state.levels_roles.forEach((economy_role,index) =>{
  //         levels_roles.push(
  //             <div key={index} className="economy-roles mb-15">
  //                 <div class="long-input economy-roles-input">
  //                     <label>Level Requirement</label>
  //                     <input type="text"/>
  //                 </div>

  //                 <div className="economy-roles-container">
  //                     <RoleAdd items={[]}></RoleAdd>
  //                 </div>
  //             </div>
  //         )
  //     })
  //     return levels_roles
  // }

  fieldChange = (value, type) => {
    const levels = { ...this.props.levels };
    if (type == "exp_rate" && value <= 0) {
      value = 1;
    }
    levels.options[type] = value;

    this.props.setBotModule({
      module: "levels",
      module_data: levels,
    });
  };

  selectChange = (value, type) => {
    const levels = { ...this.props.levels };
    levels.options.level_up.type = value;
    this.props.setBotModule({
      module: "levels",
      module_data: levels,
    });
  };

  levelUpMessageChange = (value) => {
    this.setState({ level_up_message: value });
  };

  levelUpMessageSubmit = () => {
    const levels = { ...this.props.levels };
    levels.options.level_up.messages.push(this.state.level_up_message);
    this.props.setBotModule({
      module: "levels",
      module_data: levels,
    });

    this.setState({ level_up_message: "" });
  };

  renderMessages = () => {
    const messages = [];
    var level_up = { ...this.props.levels.options.level_up };
    if (level_up.messages != undefined) {
      level_up.messages.forEach((message) => {
        messages.push(
          <div
            className="command"
            onClick={(e) => {
              this.removeMessage(message);
            }}
          >
            <div className="command-info">
              <h3>{message}</h3>
            </div>
          </div>
        );
      });
      return messages;
    }
  };

  deleteRole = (index) => {
    const levels = { ...this.props.levels };

    levels.options.roles.splice(index, 1);
    this.props.setBotModule({
      module: "levels",
      module_data: levels,
    });
  };

  renderLevelRoles = () => {
    const level_roles = [];
    if (this.props.levels.options.roles == undefined) {
      return;
    }
    this.props.levels.options.roles.forEach((role, index) => {
      var level_role = { ...role };
      if (!("roles" in level_role)) {
        level_role.roles = [{ name: level_role.name }];
      }
      level_roles.push(
        <div key={index} className="economy-roles mb-15 m-mb-0">
          <div class="long-input economy-roles-input m-w-100">
            <label>Level Requirement</label>
            <input
              onChange={(e) => {
                this.levelRequirementChange(e.target.value, index);
              }}
              value={level_role.level}
              type="number"
              min={1}
            />
          </div>
          <div className="m-w-100 bottom-economy">
            <div className="economy-roles-container">
              <RoleAdd
                items={level_role.roles}
                option={index}
                update={this.roleAddChange}
                type="role"
              ></RoleAdd>
            </div>

            <div className="economy-roles-delete center-in-div">
              <FontAwesomeIcon
                icon={faTimesCircle}
                color={"red"}
                size="2x"
                onClick={() => {
                  this.deleteRole(index);
                }}
              />
            </div>
          </div>
        </div>
      );
    });
    return level_roles;
  };

  roleAddChange = (value, index) => {
    const levels = { ...this.props.levels };
    if (value.length != 0) {
      levels.options.roles[index].roles = [value[value.length - 1]];
      levels.options.roles[index].name = value[value.length - 1].name;
      levels.options.roles[index].id = value[value.length - 1].id;
    } else {
      levels.options.roles[index].roles = [];
    }

    this.props.setBotModule({
      module: "levels",
      module_data: levels,
    });
  };

  removeMessage = (message) => {
    const levels = { ...this.props.levels };
    var level_up_messages = levels.options.level_up.messages;
    level_up_messages = level_up_messages.filter((item) => item !== message);

    levels.options.level_up.messages = level_up_messages;

    this.props.setBotModule({
      module: "levels",
      module_data: levels,
    });
  };

  addEXPRole = () => {
    const levels = { ...this.props.levels };
    levels.options.roles.push({
      level: 1,
      name: "",
      id: "",
      roles: [],
    });
    this.props.setBotModule({
      module: "levels",
      module_data: levels,
    });
  };
  levelRequirementChange = (value, index) => {
    const levels = { ...this.props.levels };

    if (value <= 0) {
      value = 1;
    }
    levels.options.roles[index].level = value;

    this.props.setBotModule({
      module: "levels",
      module_data: levels,
    });
  };

  renderLeaderBoards = () => {
    const leaderboards = [];

    this.props.guilds.forEach((guild, index) => {
      const keys = this.state.colorPickerKey.split("-");

      var background_color = "#f45142";
      var leaderboard_color = "#f45142";
      if (this.props.levels.options.styles == undefined) {
        const levels = { ...this.props.levels };
        levels.options.styles = {};
        this.props.setBotModule({
          module: "levels",
          module_data: levels,
        });
      }
      if (
        "styles" in this.props.levels.options &&
        this.props.levels.options.styles[guild.id] != undefined
      ) {
        background_color =
          this.props.levels.options.styles[guild.id].background_color;
        leaderboard_color =
          this.props.levels.options.styles[guild.id].leaderboard_color;
      }

      leaderboards.push(
        <div className="col-lg-12">
          <div className="command-no-hover">
            <div className="command-info">
              <h3>{guild.name}</h3>
            </div>

            {/* <div className="levels-color-picker-container">
                        <div className="levels-color-picker">
                            <span>Background Color</span><div class="embed-color-picker-selected mr-5" style={{backgroundColor:background_color}} onClick = {(e) =>{this.changeColorPickerKey(`background_color-${guild.id}`)}}><i class="bi bi-eyedropper"></i></div>
                            {this.renderColorPicker(`background_color-${guild.id}`)}    
                        </div>                               
                        

                        <div className="levels-color-picker">
                            <span>Leaderboard Color</span><div class="embed-color-picker-selected" style={{backgroundColor:leaderboard_color}} onClick = {(e) =>{this.changeColorPickerKey(`leaderboard_color-${guild.id}`)}}><i class="bi bi-eyedropper"></i></div>
                            {this.renderColorPicker(`leaderboard_color-${guild.id}`)}
                        </div>
                    
                    </div> */}

            <div className="command-options" style={{ marginLeft: "15px" }}>
              <a
                className="btn btn-red"
                href={`https://botghost.com/leaderboard/${guild.id}/${this.props.bot.id}`}
                target="_blank"
              >
                Leaderboard
              </a>
            </div>
          </div>
        </div>
      );
    });

    return leaderboards;
  };

  moduleEnable = (value) => {
    const levels = { ...this.props.levels };
    levels.enabled = value;

    this.props.setBotModule({
      module: "levels",
      module_data: levels,
    });
  };

  renderColorPicker = (colorPickerKey) => {
    const keys = this.state.colorPickerKey.split("-");
    const option = keys[0];
    const guild_id = keys[1];

    var color = "#fff";
    if (this.props.levels.options.styles[guild_id] != undefined) {
      color = this.props.levels.options.styles[guild_id][option];
    }
    if (this.state.colorPickerKey == colorPickerKey) {
      return (
        <div className="color-picker-container" style={{ top: "110px" }}>
          <ChromePicker
            color={color}
            onChangeComplete={this.onColorChange}
          ></ChromePicker>
        </div>
      );
    } else {
      return null;
    }
  };

  changeColorPickerKey = (colorPickerKey) => {
    if (this.state.colorPickerKey != colorPickerKey) {
      this.setState({ colorPickerKey: colorPickerKey });
    } else {
      this.setState({ colorPickerKey: "" });
    }
  };

  onColorChange = (color) => {
    const levels = { ...this.props.levels };
    const styles = levels.options.styles;

    const keys = this.state.colorPickerKey.split("-");
    const option = keys[0];
    const guild_id = keys[1];

    if (styles[guild_id] == undefined) {
      styles[guild_id] = {
        id: guild_id,
        background_color: "#fff",
        leaderboard_color: "#fff",
      };
    }

    styles[guild_id][option] = color.hex;

    levels.styles = styles;
    this.props.setBotModule({
      module: "levels",
      module_data: levels,
    });
  };

  render() {
    return (
      <Dashboard>
        <ModuleHeader
          youtube="https://www.youtube.com/watch?v=u5RFEYQm1w4"
          tutorial="https://docs.botghost.com/fun-modules/levels"
          docs="https://docs.botghost.com/fun-modules/levels"
          change={this.moduleEnable}
          enabledValue={this.props.levels.enabled}
          description="Create a leveling system and hosted leaderboard for your server"
          title="Levels"
          icon="levels.png"
        ></ModuleHeader>

        <ModuleDisabled disabled={!this.props.levels.enabled}>
          <section>
            <SectionHeader title="Exp Rate" pretitle="Levels"></SectionHeader>

            <div className="section-content-normal">
              <div className="mb-15">
                <h3 style={{ marginBottom: "0px" }}>Experience Rate</h3>
                <div class="section-content-header">
                  The rate at which users gain experience, the maximum rate is
                  5x
                </div>
              </div>

              <div class="long-input">
                <label>EXP Rate</label>
                <input
                  onChange={(e) => {
                    this.fieldChange(e.target.value, "exp_rate");
                  }}
                  value={this.props.levels.options.exp_rate}
                  type="number"
                />
              </div>
            </div>
          </section>

          <section>
            <SectionHeader
              title="Level Up Settings"
              pretitle="Levels"
            ></SectionHeader>

            <div className="section-content-normal">
              <div className="mb-15">
                <h3 style={{ marginBottom: "0px" }}>Level Up Announcements</h3>
                <div className="section-content-header">
                  What should your bot do when people level up
                </div>
              </div>

              <div>
                <Select
                  value={this.props.levels.options.level_up.type}
                  onChange={this.selectChange}
                  type="level_up"
                  options={level_up_options}
                ></Select>
              </div>
            </div>
          </section>

          <PremiumSection>
            <SectionHeader
              title="Level Up Messages"
              pretitle="Levels"
            ></SectionHeader>

            <div className="section-content-normal">
              <div className="mb-15">
                <h3 style={{ marginBottom: "0px" }}>
                  Level Up Message <PremiumBadge></PremiumBadge>
                </h3>
                <div className="section-content-header">
                  Add a custom level up message for your bot
                </div>
              </div>

              <div>
                <TextArea
                  disabled={this.props.premium == false ? true : false}
                  value={this.state.level_up_message}
                  onChange={this.levelUpMessageChange}
                />
              </div>

              <div className="commandAdd-buttons mt-2">
                <button
                  disabled={this.props.premium == false ? true : false}
                  onClick={(e) => {
                    this.levelUpMessageSubmit();
                  }}
                  className="btn btn-red"
                >
                  Add
                </button>
              </div>
            </div>

            <div className="section-content-normal">
              <h3 style={{ marginBottom: "0px" }}>
                Active Level Up Messages <PremiumBadge></PremiumBadge>
              </h3>
              <div className="section-content-header">
                Your active level up messages
              </div>

              <div className="row">
                <div className="col-lg-12">{this.renderMessages()}</div>
              </div>
            </div>
          </PremiumSection>

          <PremiumSection>
            <SectionHeader
              title="Exp Role Rewards"
              pretitle="Levels"
            ></SectionHeader>

            <div className="section-content-normal justify-space-between m-section-content align-items-left flex-column">
              <div className="mb-15 economy-roles-header">
                <div>
                  <h3 style={{ marginBottom: "0px", justifyContent: 'flex-start' }}>
                    EXP Role Rewards <PremiumBadge></PremiumBadge>
                  </h3>
                  <div class="section-content-header mb-15">
                    Automatically promote members to new roles when they level
                    up
                  </div>
                </div>

                <div class="" style={{ position: "relative" }}>
                  <button
                    disabled={this.props.premium == false ? true : false}
                    class="btn btn-red"
                    onClick={(e) => {
                      this.addEXPRole();
                    }}
                  >
                    Add EXP Role
                  </button>
                </div>
              </div>

              <div className="economy-roles-container">
                {this.renderLevelRoles()}
              </div>
            </div>
          </PremiumSection>

          <PremiumSection>
            <SectionHeader title="Ads" pretitle="Levels"></SectionHeader>

            <div className="section-content-normal">
              <h3>
                Hide Ads <PremiumBadge></PremiumBadge>
              </h3>
              <div className="section-content-header">
                Hide Ads on your server leaderboards. Ads will be automatically
                hidden on server leaderboards for premium bots
              </div>
            </div>
          </PremiumSection>

          <section>
            <SectionHeader
              title="Leaderboards"
              pretitle="Levels"
              refresh={true}
            ></SectionHeader>

            <div className="row">{this.renderLeaderBoards()}</div>
          </section>

          <section>
            <SectionHeader title="Commands" pretitle="Levels"></SectionHeader>

            <div className="row">
              <div className="col-lg-6">
                <Command
                  name="levels"
                  options={this.props.levels.levels}
                  module="levels"
                  showPrefix={true}
                  slashOnly={true}
                  update={(value, type) => {
                    var levels = { ...this.props.levels };
                    levels[type] = value;
                    // console.log(levels, 'AFTER HERE');
                    this.props.setBotModule({
                      module: "levels",
                      module_data: levels,
                    });
                  }}
                  description="Get the link to your servers leaderboard"
                  showToggle={true}
                  toggleValue={this.props.levels.levels.enabled != false ? true : false}

                ></Command>
              </div>

              <div className="col-lg-6">
                <Command
                  name="level"
                  options={this.props.levels.level}
                  module="levels"
                  showPrefix={true}
                  slashOnly={true}
                  update={(value, type) => {
                    var levels = { ...this.props.levels };
                    levels[type] = value;
                    // console.log(levels, 'AFTER HERE');
                    this.props.setBotModule({
                      module: "levels",
                      module_data: levels,
                    });
                  }}
                  description="Get your current level, rank and level progress"
                  showToggle={true}
                  toggleValue={this.props.levels.level.enabled != false ? true : false}
                ></Command>
              </div>
            </div>
          </section>
        </ModuleDisabled>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  levels: state.data.bot.commands.levels,
  guilds: state.data.bot_data,
  premium: state.data.premium,
  bot: state.data.bot,
  beta: state.data.beta,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Levels);
