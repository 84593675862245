import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from '../Dashboard';
import LongInput from '../elements/LongInput';
import ModuleHeader from '../elements/ModuleHeader';
import SectionHeader from '../elements/SectionHeader';
import { setBotModule } from '../../../actions';
import Command from '../elements/Command';
export class NFTs extends Component {
  render() {
    return (
      <Dashboard>
        <ModuleHeader
          tutorial="https://docs.botghost.com/crypto-and-nfts/nfts"
          // showEnabled={true}
          title="NFTs"
          docs="https://docs.botghost.com/crypto-and-nfts/nfts"
          description="Get NFT collection, item and market information"
          icon="eth.png"
          enabledValue={this.props.opensea.enabled}
          change={(value) => {
            // {
            var opensea = { ...this.props.opensea };
            opensea.enabled = value;

            this.props.setBotModule({
              module: "opensea",
              module_data: opensea,
            });
            // }
          }
          }
        ></ModuleHeader>


        <section>
          <SectionHeader title="OpenSea Commands" pretitle="NFTs" />

          <div className='section-content-normal'>
            <LongInput
              title={"Default Collection"}
              description={"Set a default collection to base all commands off. This will mean that users will not have to submit a collection slug when using these commands."}
              label={"Collection Slug"}
              placeholder={"otherdeed"}
              value={this.props.opensea.collection_slug}
              change={(value) => {
                var opensea = { ...this.props.opensea };
                opensea.collection_slug = value;
                this.props.setBotModule({
                  module: "opensea",
                  module_data: opensea,
                });
              }}
            />

          </div>

          <div className='section-content-normal'>
            <LongInput
              title={"Command Trigger"}
              description={"The command trigger for each subcommand."}
              label={"Trigger"}
              placeholder={""}
              value={this.props.opensea.opensea.trigger}
              change={(value) => {
                var opensea = { ...this.props.opensea };

                opensea.opensea.trigger = value;
                this.props.setBotModule({
                  module: "opensea",
                  module_data: opensea,
                });
              }}
            />

          </div>


          <div>
            <Command
              update={(value) => {
                var opensea = { ...this.props.opensea };
                opensea.item = value;
                this.props.setBotModule({
                  module: "opensea",
                  module_data: opensea,
                });
              }}
              options={this.props.opensea.item}
              module="opensea"
              name={"item"}
              description={
                "Get a collection items info and stats from OpenSea."
              }
              showToggle={true}
              toggleValue={this.props.opensea.item.enabled}
              showPrefix={true}
              slashOnly={true}
              subcommand={this.props.opensea.opensea.trigger != "" ? this.props.opensea.opensea.trigger : "opensea"}

              slash={true}
            ></Command>

            <Command
              update={(value) => {
                var opensea = { ...this.props.opensea };
                opensea.floor = value;
                this.props.setBotModule({
                  module: "opensea",
                  module_data: opensea,
                });
              }}
              options={this.props.opensea.floor}
              module="opensea"
              name={"floor"}
              description={
                "Get the floor price of a collection."
              }
              showToggle={true}
              toggleValue={this.props.opensea.floor.enabled}
              showPrefix={true}
              slashOnly={true}
              subcommand={this.props.opensea.opensea.trigger != "" ? this.props.opensea.opensea.trigger : "opensea"}

              slash={true}
            ></Command>

            <Command
              update={(value) => {
                var opensea = { ...this.props.opensea };
                opensea.collection = value;
                this.props.setBotModule({
                  module: "opensea",
                  module_data: opensea,
                });
              }}
              options={this.props.opensea.collection}
              module="opensea"
              name={"collection"}
              description={
                "Get a collection info and stats from OpenSea"
              }
              showToggle={true}
              toggleValue={this.props.opensea.collection.enabled}
              showPrefix={true}
              slashOnly={true}
              subcommand={this.props.opensea.opensea.trigger != "" ? this.props.opensea.opensea.trigger : "opensea"}

              slash={true}
            ></Command>
          </div>

        </section>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({

  opensea: state.data.bot.commands.opensea
});

const mapDispatchToProps = {
  setBotModule
};

export default connect(mapStateToProps, mapDispatchToProps)(NFTs);
