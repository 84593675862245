import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setBotModule } from "../../../actions";

export class EmbedBuilderJson extends Component {

    constructor(props) {
        super(props);
        this.state = {
            code: ''
        };
    }

    modalBackgroundClick = (e) => {
        // console.log("MODAL BACKGROUND CLICK")
        if (e.currentTarget == e.target) {
            this.props.closeModal(true)
        }
    }

    componentDidMount() {
        // console.log("PREMIUM MODAL MOUNTED")
        this.setState({ code: this.props.code() });
    }

    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} onClick={(e) => { this.modalBackgroundClick(e) }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div class="modal-dialog premium-modal-dialog" role="document">
                        <div class="modal-content premium-modal" style={{ backgroundColor: "#f8f8fb" }}>
                            <div class="modal-body command-edit-body premium-modal-body">
                                <div className="premium-modal-content" style={{ width: "100%" }}>
                                    <pre>
                                        <code>{this.state.code}</code>
                                    </pre>
                                    <button className="btn btn-gray mt-15" onClick={() => { this.props.closeModal() }}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
    embeds: state.data.bot.commands.embeds,
    data: state.data,
    beta: state.data.beta,
})

const mapDispatchToProps = {
    setBotModule,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmbedBuilderJson)
