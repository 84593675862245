import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../Dashboard";
import ModuleHeader from "../elements/ModuleHeader";
import SectionHeader from "../elements/SectionHeader";
import server from "../../../api/server";

export class Twitter_FreePremium extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      error: "",
      username: "",
    };
  }

  serverClaim = async () => {
    this.setState({ disabled: true, error: "" });
    var response = await server.post("/dashboard/twitterpremium", {
      bot_id: this.props.bot.id,
      username: this.state.username,
    });
    if (response && response.data.success == true) {
      window.location.reload();
    } else if (response.data.success == false) {
      if (response.data.reason == "followed_by") {
        this.setState({
          error:
            "You have not followed @Tomtoise on twitter. Please click the Follow on Twitter button below and follow @Tomtoise",
        });
      } else if (response.data.reason == "user") {
        this.setState({
          error: `Can not find a twitter user with the handle @${this.state.username}`,
        });
      } else if (response.data.reason == "claimed") {
        this.setState({ error: "You have already claimed this bonus" });
      }
    }

    this.setState({ disabled: false });
  };

  render() {
    return (
      <Dashboard>
        <ModuleHeader
          showEnabled={false}
          icon="twitter.png"
          title="Follow on Twitter"
          description="Follow the BotGhost Owner on Twitter to claim 3 days free BotGhost Premium"
        ></ModuleHeader>

        <section>
          <SectionHeader
            title="How to claim Free BotGhost Premium"
            pretitle="Free Premium"
          ></SectionHeader>
          <div className="section-content-normal">
            <span style={{ color: "red", fontWeight: "600" }}>
              {this.state.error}
            </span>
            <div className="mb-15">
              <h3 style={{ marginBottom: "0px" }}>Twitter Username</h3>
              <div class="section-content-header">
                <p>
                  Enter your twitter handle below, then follow{" "}
                  <span style={{ color: "red" }}>@Tomtoise</span> on twitter.
                  Once you have followed, click the Claim 3 Days of BotGhost
                  Premium button below
                </p>
              </div>
            </div>

            <div className="long-input mb-15">
              <label>Username</label>
              <input
                type="text"
                value={this.state.username}
                onChange={(e) => {
                  this.setState({ username: e.target.value });
                }}
              ></input>
            </div>

            <div className="m-buttons">
              <a
                style={{ backgroundColor: "#1DA1F2" }}
                href="https://twitter.com/Tomtoise"
                target="_blank"
                className="mr-15 btn btn-gray m-mr-0 m-ml-0"
              >
                Follow on Twitter
              </a>
              <button
                disabled={
                  this.state.username == "" ||
                  this.state.disabled ||
                  this.props.premium == true ||
                  this.props.user.twitter_claimed == true
                }
                onClick={() => {
                  this.serverClaim();
                }}
                style={{ marginLeft: "15px" }}
                className="btn btn-red m-mr-0 m-ml-0"
              >
                Claim 3 Days of BotGhost Premium
              </button>
            </div>
          </div>
        </section>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.data.user,
  bot: state.data.bot,
  premium: state.data.premium,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Twitter_FreePremium);
