import React, { Component } from 'react'
import { connect } from 'react-redux'

export class TextStep extends Component {
  render() {
    return (
      <div className='text-step-inner'>
          <h3>{this.props.title}</h3>
          <p>{this.props.content}</p>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default TextStep