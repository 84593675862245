import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dashboard from '../Dashboard'
import ModuleHeader from '../elements/ModuleHeader'
import SectionHeader from '../elements/SectionHeader'


import { updateErrorLogsChecked } from "../../../actions"
import moment from 'moment'

export class Errors extends Component {

    componentDidMount() {
        this.props.updateErrorLogsChecked(Date.now())
    }


    renderErrors = () => {
        var errors = [];
        var api_errors = this.props.api_errors;
        api_errors = api_errors.reverse()
        if (api_errors.length == 0) {
            errors.push(
                <tr><td>No Errors!</td></tr>
            )
        } else {
            api_errors.forEach(error => {
                if (error.errorMessage == "Missing Access: Please KICK and then reinvite your bot using the Invite link in your BotGhost dashboard to enable slash commands.") {
                    errors.push(
                        <tr>
                            <th scope="row">{moment(error.timeStamp).fromNow()}</th>
                            <td>{error.action}</td>
                            <td>{error.errorMessage} <a href={"https://www.youtube.com/watch?v=cS8I8HjB6uE"} target="_blank" className="btn btn-red hithere">How to fix</a></td>
                            <td></td>
                            <td>{error.guild || "No Guild"}</td>
                        </tr>
                    )
                } else if (error.errorMessage == "Your bot could not start because you have enabled a feature (Announcements/Autorole) that requries Gateway Intents. Enable the SERVER MEMBERS INTENT in your Discord Developer Portal or disable the module requiring privileged intents.") {
                    errors.push(
                        <tr>
                            <th scope="row">{moment(error.timeStamp).fromNow()}</th>
                            <td>{error.action}</td>
                            <td>{error.errorMessage} <a href={"https://www.youtube.com/watch?v=Zd459DyJ0ZU"} target="_blank" className="btn btn-red hithere">How to fix</a></td>
                            <td></td>
                            <td>{error.guild || "No Guild"}</td>
                        </tr>
                    )
                } else {
                    errors.push(
                        <tr>
                            <th scope="row">{moment(error.timeStamp).fromNow()}</th>
                            <td>{error.action}</td>
                            <td>{error.errorMessage}</td>
                            <td>{error.module}</td>
                            <td>{error.guild || "No Guild"}</td>
                        </tr>
                    )
                }

            })
        }



        return errors;
    }
    render() {
        return (
            <Dashboard>
                <ModuleHeader showDocs={false} showEnabled={false} icon="affiliate.png" title="Error Logs" description="Your bots API error logs"></ModuleHeader>
                <section>
                    <SectionHeader title={"Error Logs"} pretitle="Errors" />
                    <div className="scrollwrapper">
                        <table class="table error-logs-table">
                            <thead>
                                <tr>
                                    <th scope="col">Timestamp</th>
                                    <th scope="col">Action</th>
                                    <th scope="col">Error Message</th>
                                    <th scope="col">Module</th>
                                    <th scope="col">Guild Id</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderErrors()}
                            </tbody>
                        </table>
                    </div>
                </section>
            </Dashboard>
        )
    }
}

const mapStateToProps = (state) => ({
    api_errors: state.data.api_errors
})

const mapDispatchToProps = {
    updateErrorLogsChecked
}

export default connect(mapStateToProps, mapDispatchToProps)(Errors)
