import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";

import TextArea from "../../elements/TextArea";
import Embed from "../../elements/Embed";
import Select from "../../elements/Select";
import RefreshBotData from "../../elements/RefreshBotData";
import EmojiAdder from "./EmojiAdder";
import { checkIntegrationChild } from "../eventUtils";
import VariableTextInput from "../VariableTextInput";
export class ChangeNicknameAction extends Component {
	componentDidMount() {
		if (this.props.data == undefined) {
			var target = { id: "" };
			if (this.props.components["User"]) {
				target = { self: true };
			}
			this.props.updateElementData({
				data: {
					type: "change_nickname",
					target: target,
					name: "",
					reason: "",
					success_handles: false,
					validated: false
				},
				id: this.props.id
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data != undefined && this.props.data.success_handles == undefined) {
			this.updateData("success_handles", false);
		}
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "dm_response",
					target: {
						self: true
						// variable:"{option_target}",
						// id:"136327647792726016"
					},
					name: "",
					reason: "",
					validated: false
				},
				id: this.props.id
			});
		} else if (!("target" in this.props.data)) {
			this.updateData("target", { self: true });
		} else {
			// VALIDATE
			if ("name" in this.props.data && this.props.data.name != "") {
				if (this.props.data.validated == false && this.validateTarget()) {
					this.updateData("validated", true);
				} else if (this.props.data.validated == true && !this.validateTarget()) {
					this.updateData("validated", false);
				}
			} else if (this.props.data.validated == true) {
				this.updateData("validated", false);
			}
		}
	}

	validateTarget = () => {
		if ("target" in this.props.data) {
			if ("self" in this.props.data.target) {
				return true;
			} else if ("variable" in this.props.data.target && this.props.data.target.variable != "") {
				return true;
			} else if ("id" in this.props.data.target && this.props.data.target.id != "") {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	};

	nameChange = (value) => {
		value = value.toLowerCase();
		value = value.substring(0, 32);
		value = value.replace(" ", "-");
		const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/gu);
		value = value.replace(regexForNonAlphaNum, "");
		this.updateData("name", value);
	};

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	renderResponseTypes = () => {
		var user = "User who used the command";
		if (this.props.mode == "event" && this.props.components["User"]) {
			user = "The user.";
		}

		const responseTypes = [
			{ label: user, value: "self", disabled: this.props.components.Interaction || this.props.components.User ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "User option", value: "variable", disabled: !this.props.components.Interaction },
			{ label: "User ID", value: "id" }
		];
		return responseTypes;
	};

	renderDisableField = () => {
		var disabled = true;
		if (this.props.data.target.reply == true && (this.props.components.Interaction || checkIntegrationChild(this.props.elements, this.props.id))) {
			disabled = false;
		} else if (this.props.data.target.trigger_channel == true && (this.props.components.Channel || checkIntegrationChild(this.props.elements, this.props.id))) {
			disabled = false;
		}
		return disabled;
	};

	changeType = () => {
		var response_options = { ...this.props.data.response_options };
		if ("response" in this.props.data.response_options) {
			response_options = {
				embed: {},
				emojis: response_options.emojis || []
			};
		} else {
			response_options = {
				response: "",
				emojis: response_options.emojis || []
			};
		}
		this.updateData("response_options", response_options);
	};

	updateResponseOptions = (key, value) => {
		var response_options = { ...this.props.data.response_options };
		response_options[key] = value;
		this.updateData("response_options", response_options);
	};

	renderOptions = () => {
		var options = [
			// {value:"trigger",label:"The member who triggered the command"}
		];
		this.props.options.forEach((option) => {
			if (option.type == "channel") {
				options.push({ value: { variable: option.name }, label: `Channel Option: ${option.name}` });
			}
		});
		return options;
	};

	selectUpdate = (value) => {
		// console.log("selected ");
		value = JSON.parse(value);
		// console.log(value);
		var target = { ...this.props.data.target };
		target = value;
		this.updateData("target", target);
	};

	targetOnChange = (value) => {
		// {label:"User who used the command",value:"self"},
		// {label:"User variable",value:"variable"},
		// {label:"User ID",value:"id"},
		if (value == "self") {
			var data = { ...this.props.data };
			data.target = {
				self: true
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		} else if (value == "variable") {
			var data = { ...this.props.data };
			data.target = {
				variable: ""
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		} else if (value == "id") {
			var data = { ...this.props.data };
			data.target = {
				id: ""
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		}
	};

	render() {
		if (this.props.data != undefined && "target" in this.props.data) {
			return (
				<div>
					<div className="mb-15">
						<h4>Change a Members Nickname</h4>
						<p>Change the nickname of a member in a server.</p>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Member <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/change-a-members-nickname#change-a-members-nickname" />
						</h4>
						<span>Which member to change the nickname of.</span>
						<Select
							value={"self" in this.props.data.target ? "self" : "variable" in this.props.data.target ? "variable" : "id" in this.props.data.target ? "id" : ""}
							onChange={(value) => {
								this.targetOnChange(value);
							}}
							options={this.renderResponseTypes()}
						></Select>
					</div>

					{"variable" in this.props.data.target ? (
						<div className="mb-15 slash-action">
							<h4>User Option </h4>
							<span>The user option to change the nickname of</span>
							<VariableTextInput
								required={true}
								slash_options={this.props.options}
								label="Option"
								placeholder="{option_user}"
								value={this.props.data.target.variable}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										variable: value
									};

									this.props.updateElementData({
										data: data,
										id: this.props.id
									});
								}}
							/>
							{/* <div class="long-input mt-15">
                                    <label>Option</label>
                                    <input className={this.props.saveFailed && this.props.data.target.variable == "" ? "command-required" : ""} onChange={(e) => {

                                        var data = { ... this.props.data };
                                        data.target = {
                                            variable: e.target.value,
                                        };
                                        this.props.updateElementData(
                                            {
                                                data: data,
                                                id: this.props.id
                                            }
                                        );

                                    }} type="text" placeholder={"{option_user}"} value={this.props.data.target.variable} />
                                </div> */}
						</div>
					) : null}

					{"id" in this.props.data.target ? (
						<div className="mb-15 slash-action">
							<h4>User ID </h4>
							<span>The user id to change the nickname of</span>
							<VariableTextInput
								required={true}
								slash_options={this.props.options}
								label="ID"
								placeholder="136327647792726016"
								value={this.props.data.target.id}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										id: value
									};

									this.props.updateElementData({
										data: data,
										id: this.props.id
									});
								}}
							/>

							{/* <div class="long-input mt-15">
                                    <label>ID</label>
                                    <input className={this.props.saveFailed && this.props.data.target.id == "" ? "command-required" : ""} onChange={(e) => {

                                        var data = { ... this.props.data };
                                        data.target = {
                                            id: e.target.value,
                                        };
                                        this.props.updateElementData(
                                            {
                                                data: data,
                                                id: this.props.id
                                            }
                                        );

                                    }} type="text" placeholder={"136327647792726016"} value={this.props.data.target.id} />
                                </div> */}
						</div>
					) : null}

					<div className="mb-15 slash-action">
						<h4>
							New Nickname <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/change-a-members-nickname#new-nickname" />
						</h4>
						<span>The new nickname for the member</span>
						<VariableTextInput
							required={true}
							slash_options={this.props.options}
							label="Nickname"
							placeholder="Nickname"
							value={this.props.data.name}
							onChange={(value) => {
								this.updateData("name", value);
							}}
						/>

						{/* <div class={`long-input mt-15`}>
                            <label>Nickname</label>
                            <input className={`${!this.props.data.name && this.props.saveFailed ? 'command-required' : null}`} onChange={(e) => {

                                this.updateData("name", e.target.value);

                            }} type="text" value={this.props.data.name} />
                        </div> */}
					</div>

					<div className="justify-space-between mb-15">
						<h4 style={{ marginBottom: "0px" }}>
							Reason <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/change-a-members-nickname#reason"}></DocsLink>
						</h4>
					</div>
					<TextArea
						variableEditor={true}
						slash={true}
						slash_options={this.props.options}
						value={this.props.data.reason}
						onChange={(value) => {
							this.updateData("reason", value);
						}}
					/>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	saveFailed: state.builder.saveFailed,
	beta: state.data.beta,
	components: state.builder.components,
	mode: state.builder.mode
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeNicknameAction);
