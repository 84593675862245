import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from './Select';



var api_example = {
    "variables": [{
        "name": "message",
        "variable": "{event_message}",
        "value": "HELLO from webhooks"
    }]
};

export class WebhookItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timepicker: "12:00:00",
            error: "",
            id: "",
            name: "",
            type: "interval",
            time: {
                seconds: 0,
                minutes: 0,
                hours: 0,
                days: 0,
            },
            days: {
                mon: true,
                tue: true,
                wed: true,
                thu: true,
                fri: true,
                sat: true,
                sun: true
            }
        };
    }

    componentDidMount() {
        if (this.props.index == "new") {
            var id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            this.setState({ id: id });
        } else {
            Object.keys(this.props.timed_event).forEach((key) => {
                this.setState({ [key]: this.props.timed_event[key] });
            });
        }
    }

    renderDays = () => {
        var days = [];
        Object.keys(this.state.days).forEach((key) => {

            // Capitalize first letter
            var day = key.charAt(0).toUpperCase() + key.slice(1);
            days.push(<div onClick={() => {


                var days = { ...this.state.days };

                days[key] = !days[key];
                // Check to ensure that all are not disabled
                var disabled = true;
                Object.keys(days).forEach((key2) => {
                    if (days[key2]) {
                        disabled = false;
                    }
                });
                if (disabled) return;
                this.setState({ days: days });
            }} class={`${this.state.days[key] ? "bg-green" : "bg-red"}  ${!this.state.days[key] ? "opacity-50" : "hover:opacity-70"} text-white text-md font-semibold mr-2 px-3 hover:cursor-pointer  py-1 rounded dark:bg-blue-900 dark:text-blue-300`}>{day}</div>);
        });
        return days;
    };

    render() {
        return (
            <div>
                <hr class="slashcommand-hr" />

                <div className="grid grid-cols-1">
                    <div>


                        <div className={`mb-15`}>
                            <div class="">
                                <h3 style={{ marginBottom: "0px" }}>Event Name</h3>
                                <div className="section-content-header mb-2">A descriptive name for the event</div>
                                <div className='long-input'>
                                    <label>Name</label>
                                    <input
                                        required
                                        type={"text"}
                                        value={this.state.name}
                                        // maxLength={this.props.settings.maxLength}
                                        // placeholder={this.props.settings.placeholder || ""}
                                        onChange={(e) => {
                                            this.setState({
                                                name: e.target.value
                                            });
                                        }}
                                    ></input>
                                </div>
                            </div>
                        </div>

                        <div className={`mb-15`}>
                            <div class="">
                                <h3 style={{ marginBottom: "0px" }}>Event Type</h3>

                                <div className="section-content-header mb-2">Whether this event should trigger on a schedule or every X amount of seconds, minutes, hours and days.</div>
                                <Select options={[
                                    { label: "Interval", value: "interval" },
                                    { label: "Scheduled", value: "schedule" }

                                ]} value={this.state.type} onChange={(value) => {
                                    if (value == "interval") {
                                        this.setState({
                                            type: value, time: {
                                                seconds: 0,
                                                minutes: 0,
                                                hours: 0,
                                                days: 0
                                            }
                                        });
                                    } else if (value == "schedule") {
                                        this.setState({
                                            type: value, time: {
                                                schedules: []
                                            }
                                        });
                                    }
                                }}></Select>
                            </div>
                        </div>
                    </div>
                    <hr class="slashcommand-hr" />
                    {
                        this.state.type == "interval" ? <>
                            <div className='mb-15'>
                                <h2 className='text-lg font-lg font-bold text-white'>Interval Timed Event</h2>
                                <p className='text-white  opacity-70'>Run events every X amount of seconds, minutes, hours or days. The minimum interval is 10 seconds.</p>
                            </div>
                            <div className='grid grid-cols-4 gap-x-2'>
                                <div className='mb-15'>
                                    <h3 className='mb-2'>Seconds</h3>
                                    <div className='long-input'>

                                        <input
                                            required
                                            type={"number"}
                                            value={this.state.time.seconds}
                                            minValue={0}
                                            // maxLength={this.props.settings.maxLength}
                                            // placeholder={this.props.settings.placeholder || ""}
                                            onChange={(e) => {
                                                this.setState({
                                                    time: {
                                                        ...this.state.time,
                                                        seconds: e.target.value
                                                    }
                                                });
                                            }}
                                        ></input>
                                    </div>
                                </div>

                                <div className='mb-15'>
                                    <h3 className='mb-2'>Minutes</h3>
                                    <div className='long-input'>
                                        <input
                                            required
                                            type={"number"}
                                            value={this.state.time.minutes}
                                            min={0}
                                            // maxLength={this.props.settings.maxLength}
                                            // placeholder={this.props.settings.placeholder || ""}
                                            onChange={(e) => {
                                                this.setState({
                                                    time: {
                                                        ...this.state.time,
                                                        minutes: e.target.value
                                                    }
                                                });
                                            }}
                                        ></input>
                                    </div>
                                </div>

                                <div className=''>
                                    <h3 className='mb-2'>Hours</h3>
                                    <div className='long-input'>
                                        <input
                                            required
                                            type={"number"}
                                            value={this.state.time.hours}
                                            min={0}
                                            // maxLength={this.props.settings.maxLength}
                                            // placeholder={this.props.settings.placeholder || ""}
                                            onChange={(e) => {
                                                this.setState({
                                                    time: {
                                                        ...this.state.time,
                                                        hours: e.target.value
                                                    }
                                                });
                                            }}
                                        ></input>
                                    </div>
                                </div>

                                <div className=''>
                                    <h3 className='mb-2'>Days</h3>
                                    <div className='long-input'>

                                        <input
                                            required
                                            type={"number"}
                                            value={this.state.time.days}
                                            min={0}
                                            // maxLength={this.props.settings.maxLength}
                                            // placeholder={this.props.settings.placeholder || ""}
                                            onChange={(e) => {
                                                this.setState({
                                                    time: {
                                                        ...this.state.time,
                                                        days: e.target.value
                                                    }
                                                });
                                            }}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                        </> :
                            <>
                                <div className='mb-15'>
                                    <h2 className='text-lg font-lg font-bold text-white'>Scheduled Timed Event</h2>
                                    <p className='text-white  opacity-70'>Run events at specific times during the day.</p>
                                </div>

                                <div className='mb-15'>
                                    <div>
                                        <input type="time" value={this.state.timepicker} className='p-2 px-3 bg-[#36393f] text-white border-[#000000] rounded-sm' step="2" onChange={(e) => {
                                            this.setState({ timepicker: e.target.value });
                                        }}></input>
                                        <button className="btn-red rounded font-bold mx-2" onClick={() => {
                                            var time = this.state.timepicker;
                                            // Check the time already exists
                                            var exists = false;
                                            this.state.time.schedules.forEach((schedule) => {
                                                if (schedule == time) {
                                                    exists = true;
                                                }
                                            }
                                            );
                                            if (!exists) {
                                                var schedules = this.state.time.schedules;
                                                schedules.push(time);
                                                // Order the schedules;
                                                schedules.sort();

                                                this.setState({
                                                    timepicker: "12:00:00",
                                                    time: {
                                                        ...this.state.time,
                                                        schedules: schedules
                                                    }
                                                });
                                            }
                                        }}>Add</button>
                                    </div>

                                    <div className='mt-15'>
                                        {this.state.time.schedules.map((schedule) => {
                                            return <div onClick={() => {
                                                var schedules = this.state.time.schedules;
                                                schedules.splice(schedules.indexOf(schedule), 1);
                                                this.setState({
                                                    time: {
                                                        ...this.state.time,
                                                        schedules: schedules
                                                    }
                                                });
                                            }} className='bg-[#36393f] p-2 px-3 rounded-sm inline-block mr-2 text-white font-bold hover:opacity-70 hover:cursor-pointer'>{schedule}</div>;
                                        })}
                                    </div>


                                </div>
                            </>
                    }

                    <div className=''>
                        <h3>Days of the Week</h3>
                        <div className='flex flex-wrap gap-y-2  justify-center md:justify-start md:gap-y-0'>
                            {this.renderDays()}
                        </div>
                    </div>


                    {
                        this.state.error != "" ? <div className='mt-15'>
                            <span className='text-red font-bold'>{this.state.error}</span>
                        </div> : null
                    }


                </div>

                <hr class="slashcommand-hr" />
                <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "auto" }}>
                        {this.props.index != "new" ? (
                            <button
                                style={{ marginRight: "15px" }}
                                onClick={() => {
                                    this.props.delete(this.props.index);
                                }}
                                className="btn btn-gray mt-15 mr-15"
                                type="button"
                            >
                                Delete
                            </button>
                        ) : null}
                        <button
                            className="btn btn-red mt-15"
                            disabled={this.state.name == ""}
                            onClick={() => {
                                this.setState({ error: "" });
                                var timed_event = this.state;
                                if (timed_event.type == "interval") {
                                    // Ensure that the interval is at least 10 seconds
                                    var seconds = parseInt(timed_event.time.seconds) > 0 ? parseInt(timed_event.time.seconds) : 0;
                                    var minutes = parseInt(timed_event.time.minutes) > 0 ? parseInt(timed_event.time.minutes) : 0;
                                    var hours = parseInt(timed_event.time.hours) > 0 ? parseInt(timed_event.time.hours) : 0;
                                    var days = parseInt(timed_event.time.days) > 0 ? parseInt(timed_event.time.days) : 0;
                                    if (seconds < 10 && minutes == 0 && hours == 0 && days == 0) {
                                        return this.setState({ error: "The minimum interval is 10 seconds." });
                                    }
                                    timed_event.time.seconds = seconds;
                                    timed_event.time.minutes = minutes;
                                    timed_event.time.hours = hours;

                                } else {
                                    // Ensure that there is at least one schedule
                                    if (timed_event.time.schedules.length == 0) {
                                        return this.setState({ error: "You must have at least one schedule." });
                                    }

                                }

                                delete timed_event.error;
                                delete timed_event.timepicker;
                                this.props.onChange(timed_event, this.props.index);
                                if (this.props.index == "new") {
                                    // Reset state back to default
                                    var id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                                    this.setState({
                                        timepicker: "12:00:00",
                                        error: "",
                                        id: id,
                                        name: "",
                                        type: "interval",
                                        time: {
                                            seconds: 0,
                                            minutes: 0,
                                            hours: 0,
                                            days: 0,
                                        },
                                        days: {
                                            mon: true,
                                            tue: true,
                                            wed: true,
                                            thu: true,
                                            fri: true,
                                            sat: true,
                                            sun: true
                                        }
                                    });
                                }
                            }}
                        >
                            {this.props.index == "new" ? "Add Timed Event" : "Edit Timed Event"}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WebhookItem);;