import React, { Component } from 'react'
import { connect } from 'react-redux'
import server from '../../../api/server'

import history from "../../../history"

const STEPS =[
    {header:"Drag and Drop a Block",text:"Drag and drop an Option, Action or Condition into the command builder.",img:"command_drop.gif"},
    {header:"Connect Blocks",text:"Connect Blocks of the same color.",img:"command_connect.gif"},
    {header:"Fill in the Options",text:"Click an Option, Action or Condition and fill in its required Details.",img:"command_options.gif"},
    {header:"Save your command.",text:"Click the Save button to save your progress. Your command will be automatically updated and available for your bot to use!",img:"command_save.gif"},


]

export class CustomCommandHelper extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             step:0,
             disabled:false
        }
    }

    componentDidMount() {
        // navigator.clipboard.readText()
        //     .then(text =>{
        //         console.log(text)
        //     })
        //     .catch((e) =>{
        //         console.log(e)
        //     })
    }
    
    modalBackgroundClick = (e) =>{
        console.log("MODAL BACKGROUND CLICK")
        if(e.currentTarget == e.target){
            this.props.closeModal(e)
        }
    }

    import = async () =>{
        this.setState({disabled:true,error:""})
        const response = await server.post("/dashboard/importcommand",{id:this.state.code});

        if(response.data == false){
            // ERROR
            this.setState({disabled:false,error:"Could not find a command with that share code."})
        }else{
            this.props.importCommand(response.data.command)
        }
    }

    

    render() {
        return (
            <div>
            <div class={`modal fade show modal-background`} onClick={(e) =>{this.modalBackgroundClick(e)}} style={{display:"block"}} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
            <div class="modal-dialog premium-modal-dialog" style={{width:"700px",maxWidth:"700px"}} role="document">
                <div class="modal-content premium-modal" style={{backgroundColor:"#f8f8fb"}}>


                <div class="modal-body command-edit-body premium-modal-body">
                    <div className="premium-modal-content">
                        <h2>{STEPS[this.state.step].header}</h2>
                        <p style={{textAlign:"center"}}>{STEPS[this.state.step].text}</p>
                        <img src={process.env.PUBLIC_URL + `/images/gifs/${STEPS[this.state.step].img}`}></img>
                        <div style={{display:"flex",width:"100%",marginTop:"15px"}}>
                            <button disabled={this.state.step == 0 ? true:false} className="btn btn-gray" onClick={() =>{this.setState({step:this.state.step-1})}}>Back</button>
                            {this.state.step == 3 ?
                                <button  style={{ marginLeft: "auto" }} className="btn btn-red" onClick={() => { this.props.closeModal()}}>Get Started</button>
                                : <button disabled={this.state.step == 3 ? true : false} style={{ marginLeft: "auto" }} className="btn btn-red" onClick={() => { this.setState({ step: this.state.step + 1 }) }}>Next</button>
                            }
                        </div>
                    </div>



                </div>


                </div>
            </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomCommandHelper)
