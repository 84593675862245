import React, { Component } from "react";
import { connect } from 'react-redux';
import PremiumModal from "./PremiumModal";

import { setQuickStart } from "../../../actions";
import { DocsLink } from "./DocsLink";
import ReactGA from 'react-ga';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faExclamation, faQuestionCircle, faVideo } from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';


class ModuleHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  modalCheck = () => {
    if (this.props.premiumRequired == true && this.props.premium == false) {
      this.setState({ showModal: true });
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  change = () => {
    this.props.change(!this.props.enabledValue);
    var quick_start = { ...this.props.quick_start };
    quick_start["enable_module"] = true;
    this.props.setQuickStart(quick_start);
  };

  tutorialClick = () => {
    // console.log(this.props);
    ReactGA.initialize('UA-122665050-1');
    ReactGA.event({
      category: "Documentation Click",
      label: this.props.tutorial,
      action: "New Dashboard"
    });
  };

  renderButtons = () => {
    var buttons = [];
    if (this.props.customModule) {
      buttons.push(<button className="btn btn-blue" onClick={() => { this.props.resetModule(); }}>Reset to Default</button>);
    }

    if (this.props.updateAvailable) {
      buttons.push(<button className="btn btn-blue" onClick={() => { this.props.updateModule(); }}>Update Module</button>);
    }

    if (this.props.tutorial != undefined) {
      buttons.push(<a onClick={() => { this.tutorialClick(); }} className="btn btn-blue" href={this.props.tutorial} target="_blank"><FontAwesomeIcon icon={faQuestionCircle} /> Docs</a>
      );

    }

    if (this.props.youtube != undefined) {
      buttons.push(<a style={{ backgroundColor: "#FF0000 !important" }} className="btn btn-blue btn-youtube" href={this.props.youtube} target="_blank"><FontAwesomeIcon icon={faYoutube} /> Tutorial</a>
      );
    }

    if (this.props.showEnabled != false) {
      buttons.push(<button disabled={this.props.premiumRequired == true && this.props.premium == false && this.props.enabledValue == false ? true : false} className={`btn ${this.props.enabledValue == false ? "hithere" : null} ${this.props.enabledValue ? "btn-disabled" : null}`} onClick={(e) => { this.change(); }}>{this.props.enabledValue ? "Disable" : "Enable"}</button>
      );
    }



    return buttons;
  };



  render() {
    return (
      <div>
        <div onClick={() => { this.modalCheck(); }} className={`module-header ${this.props.premium == false && this.props.premiumRequired == true ? "premium-section" : null}`}>
          <div className="module-header-main">
            <img
              className={`${this.props.custom ? "rounded-full" : ""}`}
              src={this.props.custom ? this.props.img : process.env.PUBLIC_URL + `/images/icons/${this.props.icon}`}
            ></img>
            <div>
              <div className="module-header-text"><h1>{this.props.title}</h1>{this.props.updateAvailable == true ? <span className="bg-[#f45142] text-white text-xs font-bold mr-2 px-2.5 py-1 rounded !opacity-100">Update Available</span> : null} {this.props.beta == true ? <span className="bg-[blue] text-white text-xs font-bold mr-2 px-2.5 py-1 rounded !opacity-100">Beta</span> : null}</div>
              <div className="module-header-description">{this.props.description}</div>
            </div>
          </div>
          <div className="module-header-buttons m-w-100">
            <div className="module-header-action m-w-100 button-group-header">
              {this.renderButtons()}
            </div>
          </div>
        </div>
        <div>
          {this.state.showModal ? <PremiumModal closeModal={this.closeModal}></PremiumModal> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  premium: state.data.premium
});

const mapDispatchToProps = {
  setQuickStart
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleHeader);
