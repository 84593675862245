import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";
import TextArea from "../elements/TextArea";
import Embed from "../elements/Embed";
import Select from "../elements/Select";
import { Helmet } from "react-helmet";

import Dashboard from "../Dashboard";
import CustomCommandEditor from "../elements/CustomCommandEditor";

import { setBotModule, setBotSettings } from "../../../actions";

export class CustomCommands extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditor: false,
      type: "role",
    };
  }

  close = () => {
    this.setState({ showEditor: false });
  };

  commandSave = (value, index) => {
    const commands = [...this.props.commands];
    // console.log(value.type + "here2");
    var object = {};
    var type = value.type;
    if (type == undefined) {
      // console.log("YES TYPE UNDEFINED");
      if ("embed" in value) {
        type = "embed";
      } else if ("roles" in value) {
        type = "role";
      } else if ("random_messages" in value) {
        type = "random";
      } else {
        type = "basic";
      }
    }

    // console.log(this.state.type + "here4");
    if (type == "basic") {
      object = {
        trigger: value.trigger,
        info: value.info,
        dm: value.dm,
        response: value.response,
      };
    } else if (type == "embed") {
      object = {
        trigger: value.trigger,
        info: value.info,
        dm: value.dm,
        embed: value.embed,
      };
      object.embed.enabled = true;
    } else if (type == "role") {
      object = {
        trigger: value.trigger,
        info: value.info,
        dm: value.dm,
        response: value.response,
        roles: value.roles,
      };
    } else if (type == "random") {
      object = {
        trigger: value.trigger,
        info: value.info,
        dm: value.dm,
        random: value.random_messages,
      };
    }

    if (index != undefined) {
      commands[index] = object;
    } else {
      commands.push(object);
    }
    // console.log("FINISH");
    // console.log(object);
    this.props.setBotModule({
      module: "autoresponder",
      module_data: commands,
    });
  };

  renderCommands = () => {
    const commands = [];

    this.props.commands.forEach((command, index) => {
      var type = "";
      if ("embed" in command) {
        type = "embed";
      } else if ("roles" in command) {
        type = "role";
      } else if ("random" in command) {
        type = "random";
      } else {
        type = "basic";
      }

      command.type = type;
      command.index = index;
      commands.push(
        <div className="col-xl-6">
          <Command
            name={command.trigger}
            description={command.info}
            options={command}
            custom_command={true}
            custom_command_save={this.commandSave}
            module="autoresponder"
            showToggle={false}
            showPrefix={true}
            type={type}
          ></Command>
        </div>
      );
    });

    return commands;
  };

  commandDelete = (index) => {
    // console.log(index);
  };

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  changePrefix = (value) => {
    this.props.setBotSettings({ value: value, key: "prefix" });
  };

  render() {
    return (
      <>

        <Dashboard>
          {this.state.showEditor ? (
            <CustomCommandEditor
              close={this.close}
              delete={this.commandDelete}
              save={this.commandSave}
              type={this.state.type}
            ></CustomCommandEditor>
          ) : null}
          <ModuleHeader
            tutorial="https://www.youtube.com/watch?v=ZTJHpvk5xA4"
            showEnabled={false}
            title="Text Commands"
            docs="hhttps://docs.botghost.com/custom-commands/command-builder"
            description="Create custom text commands to personalize your bot"
            icon="customcommands.png"
          ></ModuleHeader>

          <section>
            <SectionHeader
              title="Text Commands Prefix"
              pretitle="Custom Commands"
              docs={true}
              docs_link={
                "https://docs.botghost.com/modules/custom-commands#creating-a-custom-command"
              }
            ></SectionHeader>

            <div className="section-content-normal">
              <div class="mb-15">
                <h3 style={{ marginBottom: "0px" }}>Bot Prefix </h3>
                <div className="section-content-header">
                  The prefix your bot should respond to
                </div>
              </div>

              <div class="long-input">
                <label>Prefix</label>
                <input
                  value={this.props.data.bot.prefix}
                  onChange={(e) => {
                    this.changePrefix(e.target.value);
                  }}
                  type="text"
                ></input>
              </div>
            </div>
          </section>
          <section>
            <SectionHeader
              title="Create a Command"
              pretitle="Custom Commands"
              docs={true}
              docs_link={
                "https://docs.botghost.com/modules/custom-commands#creating-a-custom-command"
              }
            ></SectionHeader>

            <div className="row">
              <div className="col-lg-4">
                <div
                  className="section-content-normal cursor-pointer custom-command"
                  onClick={(e) => {
                    this.setState({ showEditor: true, type: "basic" });
                  }}
                >
                  <div>
                    <h3 style={{ marginBottom: "0px" }}>Basic Text Command</h3>
                    <div className="section-content-header">
                      A basic command that responds either with a Plain Text
                      response
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div
                  className="section-content-normal cursor-pointer custom-command"
                  onClick={(e) => {
                    this.setState({ showEditor: true, type: "embed" });
                  }}
                >
                  <div>
                    <h3 style={{ marginBottom: "0px" }}>Embed Command</h3>
                    <div className="section-content-header">
                      Creates an advanced embed message, allows you to create an
                      embed with a title, link, image, color and description
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div
                  className="section-content-normal cursor-pointer custom-command"
                  onClick={(e) => {
                    this.setState({ showEditor: true, type: "random" });
                  }}
                >
                  <div>
                    <h3 style={{ marginBottom: "0px" }}>
                      Random Response Command
                    </h3>
                    <div className="section-content-header">
                      Create a list of random responses, a response will be
                      randomly chosen and sent to the member, can use both embeds
                      and plain text responses
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div
                  className="section-content-normal cursor-pointer custom-command"
                  onClick={(e) => {
                    this.setState({ showEditor: true, type: "role" });
                  }}
                >
                  <div>
                    <h3 style={{ marginBottom: "0px" }}>Role Command</h3>
                    <div className="section-content-header">
                      Add a role or list of roles to a member when this command is
                      called
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <SectionHeader
              title="Commands"
              pretitle="Custom Commands"
              docs={true}
              docs_link="https://docs.botghost.com/modules/custom-commands#editing-a-command"
            ></SectionHeader>

            <div className="row">{this.renderCommands()}</div>
          </section>
        </Dashboard>
      </>

    );
  }
}

const mapStateToProps = (state) => ({
  commands: state.data.bot.commands.autoresponder,
  data: state.data,
});

const mapDispatchToProps = {
  setBotModule,
  setBotSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomCommands);
