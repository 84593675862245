import React, { Component } from 'react'
import { connect } from 'react-redux'
import RoleAdd from '../RoleAdd'


import TextArea from "../TextArea"
import Select from "../Select"
import DocsLink from '../DocsLink'

export class Role_Action extends Component {
    constructor(props) {
        super(props)

        this.state = {
            target_options: []
        }
    }


    componentDidMount() {
        if (!("roles" in this.props.data)) {
            this.props.update("roles", [])
        }
        var target_options = [...this.state.target_options]
        this.props.options.forEach(option => {
            target_options.push({ value: option.name, label: `User Option: ${option.name}` })
        })
        this.setState({ target_options: target_options })
    }


    componentDidUpdate(prevProps, prevState) {
        if (!("target" in this.props.data)) {
            this.props.update("target", "trigger")
        }

        // if(this.prevProps.options.length != this.props.options.length){
        //     var target_options = [...this.state.target_options]
        //     this.props.options.forEach(option =>{
        //         target_options.push({value:option.name,label:`User Option: ${option.name}`})
        //     })
        //     this.setState({target_options:target_options})
        // }
    }

    renderOptions = () => {
        var options = [
            { value: "trigger", label: "The member who triggered the command" }
        ]
        this.props.options.forEach(option => {
            if (option.type == "user") {
                options.push({ value: option.name, label: `User Option: ${option.name}` })

            }

        })
        return options;
    }


    update = (value) => {
        // console.log(value)
        this.props.update("roles", value)
    }

    render() {
        if (this.props.data.roles != undefined && this.props.data.target != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h3 style={{ marginBottom: "0px" }}>Roles <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions/add-remove-roles"} /></h3>
                        <div className="section-content-header mb-15"></div>
                        <RoleAdd slash={true} slash_options={this.props.options} items={this.props.data.roles} update={this.update} />
                    </div>
                    <div className="mb-15">
                        <h3 style={{ marginBottom: "0px" }}>Target</h3>
                        <div className="section-content-header mb-15">Who to apply the action to.</div>
                        <Select options={this.renderOptions()} value={this.props.data.target} onChange={(value) => { this.props.update("target", value) }}></Select>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Role_Action)
