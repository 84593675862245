import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { Draggable } from "react-draggable";
import ReactGA from "react-ga";
import { addEdge, getIncomers } from 'react-flow-renderer';
import { setElements, setSelected } from '../../../actions';
import splitElements from './splitElements';
export class CommandBuilderObject extends Component {

  onDragStart = (event) => {
    event.dataTransfer.setData('options', JSON.stringify({
      type: this.props.title,
      category: this.props.type,
      description: this.props.description,
      icon: this.props.icon,
      data: this.props.data,
      condition_type: this.props.condition_type

    }));
    event.dataTransfer.effectAllowed = 'move';
  };

  componentDidMount() {
    //   $(".command-builder-object").draggable();
  }

  onClick = (event) => {
    if (this.props.disabled) return;
    try {
      this.props.setSelected(null);
      //   event.preventDefault();
      //   const reactFlowBounds = this.reactFlowWrapper.current.getBoundingClientRect();

      //   const position = this.state.reactFlowInstance.project({
      //     x: event.clientX - reactFlowBounds.left,
      //     y: event.clientY - reactFlowBounds.top,
      //   });
      var position = {
        x: 130,
        y: 300
      };

      // var root = this.props.elements.find(element => element.type == "root");
      // if(options.category == "option"){
      //     var incomers = getIncomers(root,splitElements(this.props.elements).nodes,splitElements(this.props.elements).edges);

      // }


      var options = {
        type: this.props.title,
        category: this.props.type,
        description: this.props.description,
        icon: this.props.icon,
        data: this.props.data,
        condition_type: this.props.condition_type
      };
      var elements = [...this.props.elements];
      var length = elements.length + 1;

      var data = {
        node_options: {
          title: options.type,
          description: options.description,
          icon: options.icon,
        },
        data: options.data
      };
      var id = `${options.category}_${length}_${s4()}_${s4()}`;
      const newNode = {
        id: id,
        type: options.category,
        data: data,
        position: position
      };

      ReactGA.initialize('UA-122665050-1');
      ReactGA.event({
        category: "Command Block",
        label: options.type,
        action: "New Dashboard"
      });

      if (options.category == "option") {
        var elements = addEdge({
          source: id,
          target: "root",
          type: "step",
          animated: false,
          arrowHeadType: "arrowclosed"

        }, elements);

      }
      else if (options.category == "condition") {
        var else_id = `$else_${length}_${s4()}_${s4()}`;
        elements.push(
          {
            id: else_id,
            type: 'conditionChild',
            data: {
              node_options: {
                type: options.condition_type,
              },
              data: {
                type: "else",
                actions: []
              }

            },
            // target:"3",
            draggable: true,
            position: { x: position.x + 200, y: position.y + 150 },
          },
        );

        elements = addEdge({
          source: id,
          target: else_id,
          type: "step",
          animated: false,
          arrowHeadType: "arrowclosed"

        }, elements);
      }

      elements.push(newNode);

      this.props.setSelected(newNode.id);
      this.props.setElements(elements);

      //   setElements((es) => es.concat(newNode));
    } catch (e) {

    }
  };

  render() {
    return (

      <div onClick={() => { this.onClick(); }} className={`command-builder-object ${this.props.disabled ? "builder-disabled" : null}`} draggable={this.props.disabled ? false : true} onDragStart={(e) => this.onDragStart(e)}>

        <div className="inner" id={`${this.props.id ? this.props.id : ""}`}>
          <div className="object-grip">
            <FontAwesomeIcon icon={faGripVertical} />
          </div>


          <div className={`object-icon object-icon-${this.props.color}`}>
            <FontAwesomeIcon icon={this.props.icon} />
          </div>

          <div className="object-info">
            <span className="object-title">{this.props.title}</span>
            <span className="object-description">{this.props.description}</span>
          </div>
        </div>
      </div>




    );
  }
}

const mapStateToProps = (state) => ({
  elements: state.builder.elements
});

const mapDispatchToProps = {
  setElements,
  setSelected

};

let s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

export default connect(mapStateToProps, mapDispatchToProps)(CommandBuilderObject);
