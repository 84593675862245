import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";
import { TextArea } from "../elements/TextArea";
import { Select } from "../elements/Select";
import RedditEditor from "../elements/RedditEditor";

import { setBotModule } from "../../../actions";

import Dashboard from "../Dashboard";
import PremiumRedirect from "../elements/PremiumRedirect";
import PremiumSection from "../elements/PremiumSection";

export class Reddit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEdit: null,
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  commandAdd = (value, index) => {
    const reddit = { ...this.props.reddit };
    if (index == undefined) {
      reddit.subreddits.push(value);
    } else {
      reddit.subreddits[index] = value;
    }

    this.props.setBotModule({
      module: "reddit",
      module_data: reddit,
    });

    this.setState({ showEdit: null });
  };

  moduleEnable = (value) => {
    const reddit = { ...this.props.reddit };
    reddit.enabled = value;

    this.props.setBotModule({
      module: "reddit",
      module_data: reddit,
    });
  };

  delete = (index) => {
    const reddit = { ...this.props.reddit };

    reddit.subreddits.splice(index, 1);

    this.props.setBotModule({
      module: "reddit",
      module_data: reddit,
    });

    this.setState({ showEdit: null });
  };

  renderRedditChannels = () => {
    var channels = [];
    this.props.reddit.subreddits.forEach((channel, index) => {
      channels.push(
        <div className="announcement">
          <div
            className="announcement-header"
            onClick={(e) => {
              this.setState({ showEdit: index });
            }}
          >
            <div>
              <h3 className="mb-0">/r/{channel.subreddit}</h3>
              <div className="section-content-header">
                {`Posting an announcement in ${channel.channel}`}{" "}
              </div>
            </div>
            <div style={{ marginLeft: "auto" }}>
              {this.state.showEdit == index ? (
                <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i>
              ) : (
                <i
                  style={{ fontSize: "20px" }}
                  class="bi bi-caret-down-fill"
                ></i>
              )}
            </div>
          </div>

          <div>
            {this.state.showEdit == index ? (
              <RedditEditor
                saveText="Save"
                save={this.commandAdd}
                index={index}
                value={channel}
                showDelete={true}
                delete={this.delete}
              />
            ) : null}
          </div>
        </div>
      );
    });
    return channels;
  };

  render() {
    return (
      <Dashboard>
        <PremiumRedirect></PremiumRedirect>
        <ModuleHeader
          tutorial="https://docs.botghost.com/social-integrations/reddit"
          premiumRequired={true}
          enabledValue={this.props.reddit.enabled}
          change={this.moduleEnable}
          docs="https://docs.botghost.com/social-integrations/reddit"
          description="Get new post alerts directly in your discord server"
          title="Reddit"
          icon="reddit.png"
        ></ModuleHeader>

        <PremiumSection>
          <SectionHeader
            title="Add Subreddit"
            pretitle="Reddit"
          ></SectionHeader>

          <RedditEditor saveText="Add" save={this.commandAdd}></RedditEditor>
        </PremiumSection>

        <PremiumSection>
          <SectionHeader
            title="Tracked Subreddits"
            pretitle="Reddit"
          ></SectionHeader>

          {this.renderRedditChannels()}
        </PremiumSection>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  reddit: state.data.bot.commands.reddit,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reddit);
