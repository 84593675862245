import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocsLink from '../DocsLink'
import RefreshBotData from '../RefreshBotData'
import Select from '../Select'



import SlashResponseOptions from "./SlashResponseOptions"


export class DM_Action extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }


    componentDidMount() {
        if (!("response_options" in this.props.data)) {
            this.props.update("response_options", {
                response: "",
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
    }

    changeType = () => {
        var response_options = { ...this.props.data.response_options };
        if ("response" in this.props.data.response_options) {
            response_options = {
                embed: {

                }
            }
        } else {
            response_options = {
                response: ""
            }
        }
        this.props.update("response_options", response_options);

    }
    updateResponse = (key, value) => {
        var response_options = { ...this.props.data.response_options };
        response_options[key] = value;
        this.props.update("response_options", response_options);
    }

    renderOptions = () => {
        var options = [
            // {value:"trigger",label:"The member who triggered the command"}
        ]
        this.props.options.forEach(option => {
            if (option.type == "channel") {
                options.push({ value: { variable: option.name }, label: `Channel Option: ${option.name}` })

            }

        })
        return options;
    }

    selectUpdate = (value) => {
        // console.log("selected ")
        value = JSON.parse(value);
        // console.log(value)
        var target = { ...this.props.data.target }
        target = value;
        this.props.update("target", target)
    }

    render() {
        if (this.props.data.response_options != undefined) {
            return (
                <div className="mb-15">
                    <div>

                        <SlashResponseOptions options={this.props.options} data={this.props.data} update={(key, value) => { this.props.update("response_options", value) }} />
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(DM_Action)
