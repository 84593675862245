import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../../history";

import moment from "moment";

export class SaleBanner extends Component {
	componentDidMount() {
		this.interval = setInterval(() => {
			this.forceUpdate();
		}, 1000);
	}
	renderTime = () => {
		// Countdown until 5th Jul 2023 GMT+10
		if (!this.props.current_sale) return "0 seconds";

		var timeLeft = moment(this.props.current_sale.expires).diff(moment(), "seconds");
		if (timeLeft < 0) {
			return "0 seconds";
		}
		var days = Math.floor(timeLeft / 86400);
		timeLeft -= days * 86400;
		var hours = Math.floor(timeLeft / 3600) % 24;
		timeLeft -= hours * 3600;
		var minutes = Math.floor(timeLeft / 60) % 60;
		timeLeft -= minutes * 60;
		var seconds = timeLeft % 60;

		var timeString = "";
		if (days > 0) {
			timeString += days + `d `;
		}
		if (hours > 0) {
			timeString += hours + "h ";
		}
		if (minutes > 0) {
			timeString += minutes + "m ";
		}

		timeString += seconds + "s";

		return timeString;
	};
	render() {
		return (
			<div
				onClick={() => {
					if (this.props.user.tokens > 0) {
						history.push("/dashboard/settings");
					} else {
						history.push("/dashboard/premium");
					}
				}}
				className="w-full fixed top-0 left-0 bg-red h-[30px] text-white flex items-center font-bold hover:cursor-pointer hover:opacity-75 justify-center"
				style={{ zIndex: 9999 }}
			>
				{/* Get {this.props.current_sale.percent}% off Unlimited Premium! Ends in {this.renderTime()} */}
				<span className="animate-pulse">
					{
						this.props.user.tokens > 0 ? 'You have Premium Tokens available. Click this banner to upgrade this bot!' : 'Upgrade to Premium to unlock all features and set a custom status for your bot!'
					}
				</span>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	current_sale: state.data.current_sale,
	user: state.data.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SaleBanner);
