import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../../../api/server';

export class IFTTTLink extends Component {

    linkIFTTT = async () => {
        var request = await server.get("/ifttt/auth/link");

        if (request && request.data) {
            window.location.replace(request.data);
        }
    };
    render() {
        return (
            <div className="flex justify-center items-center h-[100vh]">

                <div className='bg-darkGray px-10 max-w-lg py-5 text-white rounded-md flex flex-col align-center text-center'>
                    <div className='flex justify-center'>
                        <img src={process.env.PUBLIC_URL + "/images/ifttt.png"} className='h-10'></img>
                    </div>
                    <h1 className='font-bold text-3xl mt-3'>Link IFTTT to BotGhost</h1>

                    <p className='text-muted font-bold mt-3'>Link your IFTTT account to your BotGhost account. This will grant IFTTT access to trigger custom events on all your bots, and allow you to trigger IFTTT triggers through actions in your command and events</p>


                    <div className='flex mt-3 justify-center'>
                        <button className="btn btn-gray" onClick={() => {
                            window.location.replace('https://ifttt.com/channels/botghost_discord/authorize?error=access_denied');
                        }}>Deny</button>
                        <button className='btn btn-red ml-3 !bg-[#51c8f3]' onClick={() => {
                            this.linkIFTTT();
                        }}>Authorize</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IFTTTLink);