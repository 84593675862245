import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";
import TextArea from "../elements/TextArea";
import Embed from "../elements/Embed";
import Select from "../elements/Select";
import CommandAdd from "../elements/CommandAdd";
import Announcement from "../elements/Announcement";

import Dashboard from "../Dashboard";

import { setBotModule } from "../../../actions";

export class Announcements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      join_type: false,
      ban_type: false,
      leave_type: false,
      join_enabled: false,
      ban_enabled: false,
      leave_enabled: false,
    };
  }

  toggleChange = (value, type) => {
    this.setState({ [type]: value });
  };

  announcementAdd = (value) => {
    const announcements = { ...this.props.announcements };
    announcements[value.announcement_type].push(value);
    this.props.setBotModule({
      module: "announcements",
      module_data: announcements,
    });
  };

  renderAnnouncements = () => {
    const announcements = [];
    Object.keys(this.props.announcements).forEach((type) => {
      if (Array.isArray(this.props.announcements[type])) {
        this.props.announcements[type].forEach((announcement, index) => {
          if (announcement != null) {
            announcements.push(
              <Announcement
                value={announcement}
                type={type}
                index={index}
              ></Announcement>
            );
          }
        });
      }
    });
    return announcements;
  };

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  render() {
    return (
      <Dashboard>
        <ModuleHeader
          youtube="https://www.youtube.com/watch?v=14GqC-AGWSw"
          tutorial={
            "https://docs.botghost.com/modules/server-management/announcements"
          }
          showEnabled={true}
          enabledValue={this.props.announcements.enabled != false ? true : false}
          docs="https://docs.botghost.com/modules/announcements"
          description="Create automatic announcements for unique events in your server"
          title="Announcements"
          icon="announcements.png"
        ></ModuleHeader>

        {/* JOIN */}
        <section>
          <SectionHeader
            title="New Announcement"
            pretitle="Announcements"
            docs={true}
            docs_link={
              "https://docs.botghost.com/modules/announcements#new-announcement"
            }
          ></SectionHeader>

          <div className="section-content-normal">
            <div>
              <h3>New Announcement</h3>
              <div className="section-content-header">
                Create a new announcement for your bot
              </div>
            </div>

            <CommandAdd
              name="Twitter Handle"
              embed={true}
              name_input={true}
              name="Announcement Name"
              saveText="Add"
              title="Announcement Message"
              save={this.announcementAdd}
              announcement={true}
              variableEditor={true}
              value={{}}
            ></CommandAdd>
          </div>
        </section>

        <section>
          <SectionHeader
            title="Active Announcements"
            pretitle="Announcements"
            docs={true}
            docs_link={
              "https://docs.botghost.com/modules/announcements#editing-your-announcements"
            }
          ></SectionHeader>
          <div className="announcements">
            {this.renderAnnouncements("join")}
          </div>
        </section>
        {/* <section>
                    <SectionHeader title="Ban" pretitle="Announcements" ></SectionHeader>

                    <div className="section-content">
                        <div>
                            <h3>Ban Announcement</h3>
                            <div className="section-content-header">Create an announcement when someone is banned from your server.</div>
                        </div>
                        
                        <div style={{marginLeft:"auto"}}>
                            <Toggle value={this.state.ban_enabled} update={this.toggleChange} type="ban_enabled"></Toggle>
                        </div>
                    </div>
                </section>
                
                {this.state.ban_enabled ? 
                    <div>
                        <section>
                            
                            <CommandAdd
                                name="Twitter Handle"
                                embed = {true}
                                name_input = {false}
                            ></CommandAdd>
    
                        </section>

                    </div>
                    :
                null
                }

            <section>
                    <SectionHeader title="Leave" pretitle="Announcements" ></SectionHeader>

                    <div className="section-content">
                        <div>
                            <h3>Leave Announcement</h3>
                            <div className="section-content-header">Create an announcement when someone leaves your server.</div>
                        </div>
                        
                        <div style={{marginLeft:"auto"}}>
                            <Toggle value={this.state.leave_enabled} update={this.toggleChange} type="leave_enabled"></Toggle>
                        </div>
                    </div>
                </section>
                
                {this.state.leave_enabled ? 
                    <div>
                    <section>
                            
                            <CommandAdd
                                name="Twitter Handle"
                                embed = {true}
                                name_input = {false}
                            ></CommandAdd>
    
                        </section>
                    </div>
                    :
                null
                } */}
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  announcements: state.data.bot.commands.announcements,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
