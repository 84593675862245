import React, { Component } from 'react'
import { connect } from 'react-redux'

import Dashboard from "../Dashboard"
import ModuleHeader from '../elements/ModuleHeader'
import SectionHeader from '../elements/SectionHeader'

import { CopyToClipboard } from 'react-copy-to-clipboard';
import server from '../../../api/server'


export class Affiliate extends Component {

    constructor(props) {
        super(props)

        this.state = {
            copied: false,
            disabled: false
        }
    }

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    upgrade = async () => {
        this.setState({ disabled: true })
        var response = await server.post("/dashboard/referralupgrade", { bot_id: this.props.bot.id });
        if (response && response.data == true) {
            window.location.reload()
        }

        this.setState({ disabled: false })

    }

    renderAvailbity = () => {
        var avail = 0;
        if (this.props.user.referrals != undefined) {
            avail = this.props.user.referrals;
        }

        if (this.props.user.referrals_claimed != undefined) {
            avail = avail - this.props.user.referrals_claimed
        }
        return avail;

    }

    render() {
        return (
            <Dashboard>
                <ModuleHeader showEnabled={false} icon="affiliate.png" title="Referrals" description="Earn free BotGhost Premium by signing your friends and audience up for BotGhost."></ModuleHeader>


                <section>
                    <SectionHeader title="Referral Link" pretitle="Referrals"></SectionHeader>

                    <div className="section-content-normal">
                        <div className="mb-15">
                            <h3 style={{ marginBottom: "0px" }}>Your Link</h3>
                            <div class="section-content-header">
                                <span>Copy the link below and share with your friends, followers or viewers. You will receieve <strong>1 Day of Free Premium</strong> for every person who signs up to BotGhost. </span>
                            </div>
                        </div>

                        <div className='long-input affiliate-input m-w-100'>
                            <div className='w-100'>
                                <label>Your Link</label>
                                <input disabled type="text" value={`https://botghost.com/?invite=${this.props.user.id}`}></input>
                            </div>
                            <div className='center-in-div'>
                                <CopyToClipboard text={`https://botghost.com/?invite=${this.props.user.id}`} onCopy={() => { this.setState({ copied: true }) }}><button className="btn btn-red m-mt-15">{this.state.copied == true ? "Copied!" : "Copy"}</button></CopyToClipboard>
                            </div>
                        </div>
                    </div>


                </section>

                <section>
                    <SectionHeader title="Your Referals" pretitle="Affiliate"></SectionHeader>

                    <div className="section-content-normal">
                        <div className="mb-15">
                            <h3 style={{ marginBottom: "0px" }}>Your Referals</h3>
                            <div class="section-content-header"><span>You have referred <strong style={{ color: "#f45142" }}>{this.props.user.referrals || 0} people</strong> and have claimed <strong style={{ color: "#f45142" }}>{this.props.user.referrals_claimed || 0} days</strong> of free BotGhost premium. You have <strong style={{ color: "#f45142" }}>{this.renderAvailbity()} days</strong> of Premium you can claim.</span></div>

                            <button disabled={this.state.disabled || this.props.premium == true || this.renderAvailbity() <= 0} onClick={() => { this.upgrade() }} className=" mt-15 btn btn-red">Claim {this.renderAvailbity()} days of BotGhost Premium</button>
                        </div>
                    </div>
                </section>
            </Dashboard>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.data.user,
    bot: state.data.bot,
    premium: state.data.premium
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Affiliate)
