import React, { Component } from 'react'
import { connect } from 'react-redux'

import server from '../../../api/server'
import ReactGA from 'react-ga';


import history from "../../../history"
import { setQuickStart, setOnlineStatus, setServerIntents, setAuth, getData, fetchBotData, getBots, updateErrorLogs, updateServerPopup } from '../../../actions';


export class EconomyResetModal extends Component {


    constructor(props) {
        super(props)

        this.state = {
            disabled: false,
            error: ""
        }
    }

    modalBackgroundClick = (e) => {
        // console.log("MODAL BACKGROUND CLICK")
        if (e.currentTarget == e.target) {
            this.props.closeModal(true)
        }
    }
    componentDidMount() {
        // console.log("PREMIUM MODAL MOUNTED")
    }


    resetEconomy = async () => {
        this.setState({ disabled: true, error: "" })
        const response = await server.post("/dashboard/reseteconomy", {
            token: this.props.token,
            id: this.props.id,
        });
        if (response.data && response.data.success) {
            // this.setState({disabled:false})
            this.props.closeModal()
        } else if (response.data && response.data.success == false) {
            this.setState({ disabled: false, error: response.data.message })
        }

    };

    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} onClick={(e) => { this.modalBackgroundClick(e) }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div class="modal-dialog premium-modal-dialog" role="document">
                        <div class="modal-content premium-modal" style={{ backgroundColor: "#f8f8fb" }}>

                            <div class="modal-header m-modal-header" style={{ 'borderBottom': 'none' }}>
                                <button onClick={(e) => { this.props.closeModal(true); }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>


                            <div class="modal-body command-edit-body premium-modal-body">
                                <div className="premium-modal-content">
                                    <img style={{ height: "50px", width: "50px" }} src={process.env.PUBLIC_URL + "/images/logo-red.png"}></img>
                                    <h2 style={{ textAlign: "center", fontSize: "28px" }}>Reset Server Economy</h2>
                                    <p style={{ textAlign: "center", opacity: "0.7" }}>{this.state.error ? this.state.error : "This will completely reset this server's economy and can not be reversed."}</p>
                                    <div>
                                        {/* <button className="btn btn-gray" onClick={() =>{this.props.closeModal()}}>Close</button> */}
                                        <button disabled={this.state.disabled} className="btn btn-red ml-2" onClick={() => { this.resetEconomy() }}>Reset Economy</button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    intent: state.data.server_members_intent,
    user: state.data.user,
    premium: state.data.premium,
    page: state.page
    , auth: state.auth,
    data: state.data,
    loading: state.data.loading,
    save_required: state.data.save_required,
    processing: state.data.processing,
    bot: state.data.bot,
    token: state.data.bot.token,
})

const mapDispatchToProps = {
    updateServerPopup,
    setOnlineStatus,
    setQuickStart,
    setServerIntents, updateServerPopup, setAuth, getData, fetchBotData, getBots, updateErrorLogs
}

export default connect(mapStateToProps, mapDispatchToProps)(EconomyResetModal)
