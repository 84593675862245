import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";
import { TextArea } from "../elements/TextArea";
import { Select } from "../elements/Select";
import { CommandAdd } from "../elements/CommandAdd";
import { RoleAdd } from "../elements/RoleAdd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { setBotModule } from "../../../actions";

import Dashboard from "../Dashboard";
import PremiumRedirect from "../elements/PremiumRedirect";
import PremiumSection from "../elements/PremiumSection";

export class Botlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tag: "",
    };
  }
  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  enableModule = (value) => {
    const botlist = { ...this.props.botlist };
    botlist.enabled = value;
    this.props.setBotModule({
      module: "advertise",
      module_data: botlist,
    });
  };

  updateField = (value, field) => {
    const botlist = { ...this.props.botlist };
    botlist[field] = value;
    this.props.setBotModule({
      module: "advertise",
      module_data: botlist,
    });
  };

  textAreaChange = (value) => {
    this.updateField(value, "long_description");
  };

  addTag = (e) => {
    // console.log("CALLED");
    e.preventDefault();
    const botlist = { ...this.props.botlist };
    var tags = botlist.extra_tags.push(this.state.tag);
    this.props.setBotModule({
      module: "advertise",
      module_data: botlist,
    });

    this.setState({ tag: "" });
  };

  removeTag = (tag) => {
    const botlist = { ...this.props.botlist };
    var tags = botlist.extra_tags;
    tags = tags.filter((item) => item !== tag);

    botlist.extra_tags = tags;
    this.props.setBotModule({
      module: "advertise",
      module_data: botlist,
    });
  };

  renderTags = () => {
    const tags = [];
    this.props.botlist.extra_tags.forEach((tag) => {
      tags.push(
        <li className="role-adder-role">
          <FontAwesomeIcon
            onClick={(e) => {
              this.removeTag(tag);
            }}
            icon={faTimesCircle}
          />
          <span class="">{tag}</span>
        </li>
      );
    });

    return tags;
  };

  render() {
    return (
      <Dashboard>
        <PremiumRedirect></PremiumRedirect>
        <ModuleHeader
          tutorial="https://docs.botghost.com/modules/utility-commands/botlist"
          premiumRequired={false}
          change={this.enableModule}
          enabledValue={this.props.botlist.enabled}
          description="Advertise your bot on BotGhost"
          title="Botlist"
          icon="botlist.png"
        ></ModuleHeader>

        <section>
          <SectionHeader title="Settings" pretitle="Botlist"></SectionHeader>

          <div className="section-content-normal">
            <div className="mb-15">
              <h3 style={{ marginBottom: "0px" }}>Short Description</h3>
              <div class="section-content-header">
                A short catchy description of your bot
              </div>
            </div>

            <div className="long-input">
              <label>Short Description</label>
              <input
                onChange={(e) => {
                  this.updateField(e.target.value, "short_description");
                }}
                value={this.props.botlist.short_description}
                type="text"
              ></input>
            </div>
          </div>

          <div className="section-content-normal">
            <div className="mb-15">
              <h3 style={{ marginBottom: "0px" }}>Long Description</h3>
              <div class="section-content-header">
                A long description of what your bot is, and what it does
              </div>
            </div>

            <TextArea
              onChange={this.textAreaChange}
              value={this.props.botlist.long_description}
            ></TextArea>
          </div>

          <div className="section-content-normal">
            <div className="mb-15">
              <h3 style={{ marginBottom: "0px" }}>Support Server</h3>
              <div class="section-content-header">
                An invite link to your discord support server
              </div>
            </div>

            <div className="long-input">
              <label>Invite Link</label>
              <input
                onChange={(e) => {
                  this.updateField(e.target.value, "support_server");
                }}
                value={this.props.botlist.support_server}
                type="text"
              ></input>
            </div>
          </div>
        </section>

        <section>
          <SectionHeader title="Bot Tags" pretitle="Botlist"></SectionHeader>

          <div className="section-content-normal">
            <div className="mb-15">
              <h3 style={{ marginBottom: "0px" }}>Bot Tags</h3>
              <div class="section-content-header">
                Tags that describe your bot
              </div>
            </div>

            <div className="economy-roles-container">
              <div className="economy-roles mb-15">
                <form
                  onSubmit={(e) => {
                    this.addTag(e);
                  }}
                >
                  <div
                    className="long-input economy-roles-input"
                    style={{ width: "80%" }}
                  >
                    <label>Tag</label>
                    <input
                      value={this.state.tag}
                      onChange={(e) => {
                        this.setState({ tag: e.target.value });
                      }}
                      type="text"
                    ></input>
                  </div>
                </form>

                <div className="economy-roles-container">
                  <div className="role-adder">
                    <ul>{this.renderTags()}</ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <SectionHeader title="Your Page" pretitle="Botlist"></SectionHeader>

          <div className="section-content-normal">
            <div className="mb-15">
              <h3 style={{ marginBottom: "0px" }}>Your Bot's page</h3>
              <div class="section-content-header">
                Your bots page on BotGhost.com
              </div>
            </div>

            <div className="command">
              <div className="command-info">
                <h3>My BotGhost Bot</h3>
                <span>A short description</span>
              </div>

              <div className="command-options">
                <a
                  className="btn btn-red"
                  target="_blank"
                  href={`https://botghost.com/bot/${this.props.id}`}
                >
                  My BotGhost Bot
                </a>
              </div>
            </div>
          </div>
        </section>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  botlist: state.data.bot.commands.advertise,
  id: state.data.bot.id,
  premium: state.data.premium,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Botlist);
