import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";

import { setBotModule } from "../../../actions";

import Dashboard from "../Dashboard";
import PremiumRedirect from "../elements/PremiumRedirect";
import PremiumSection from "../elements/PremiumSection";

export class Weather extends Component {
  commandUpdate = (value) => {
    var utils = { ...this.props.utils };
    utils.weather = value;
    this.props.setBotModule({
      module: "utils",
      module_data: utils,
    });
  };

  enableChange = (value) => {
    var utils = { ...this.props.utils };
    utils.weather.enabled = value;
    this.props.setBotModule({
      module: "utils",
      module_data: utils,
    });
  };

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  render() {
    return (
      <Dashboard>
        <PremiumRedirect></PremiumRedirect>
        <ModuleHeader
          tutorial="https://docs.botghost.com/utility/weather"
          docs="https://docs.botghost.com/utility/weather"
          premiumRequired={true}
          description="Get the weather before heading outside"
          change={this.enableChange}
          enabledValue={this.props.utils.weather.enabled}
          title="Weather"
          icon="weather.png"
        ></ModuleHeader>

        <PremiumSection>
          <SectionHeader title="Commands" pretitle="Translator"></SectionHeader>

          <div className="row">
            <div className="col-xl-6">
              <Command
                name="weather"
                description="Gets the current weather forecast for a city or town"
                update={this.commandUpdate}
                options={this.props.utils.weather}
                module="utils"
                showToggle={false}
                slashOnly={true}
                toggleValue={this.props.utils.weather.enabled}
                showPrefix={true}
              ></Command>
            </div>
          </div>
        </PremiumSection>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  utils: state.data.bot.commands.utils,
  beta: state.data.beta,
});

const mapDispatchToProps = {
  setBotModule,
};
export default connect(mapStateToProps, mapDispatchToProps)(Weather);
