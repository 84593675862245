import { faBan } from '@fortawesome/free-solid-svg-icons'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CommandAdd from './CommandAdd'

import { setBotModule } from "../../../actions"
import ReactionRoleEditor from './ReactionRoleEditor'



export class ReactionRole extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showEdit: false
        }
    }

    componentDidMount() {
        // console.log(this.props)
    }

    reactonRoleSave = (state, index) => {
        // console.log(state,index)
        var reaction_roles = { ...this.props.reaction_roles };
        var roles = [];
        state.activeRoles.forEach(activeRole => {
            roles.push({
                name: activeRole.roles[0].name,
                role_id: activeRole.roles[0].id,
                emoji: activeRole.emoji_name
            })
        })


        reaction_roles.roles[index] = {
            name: state.name,
            guild_id: state.guild_id,
            channel_id: state.channel.id,
            embed: state.embed,
            react_type: state.react_role_config,
            roles: roles,
            config: state
        }

        this.props.setBotModule({
            module: "reaction_roles",
            module_data: reaction_roles
        })

        this.setState({ showEdit: false })

    }

    delete = (index) => {
        var reaction_roles = { ...this.props.reaction_roles };

        reaction_roles.roles.splice(index, 1);

        this.props.setBotModule({
            module: "reaction_roles",
            module_data: reaction_roles
        })
        this.setState({ showEdit: false })
    }


    render() {
        return (
            <div className="announcement">
                <div className="announcement-header" onClick={(e) => { this.setState({ showEdit: !this.state.showEdit }) }}>
                    <div>
                        <h3 className="mb-0">{this.props.value.name || "Reaction Role"}</h3>
                        <div className="section-content-header">{`Channel ID:${this.props.value.channel_id} || Guild ID: ${this.props.value.guild_id}`}</div>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                        {this.state.showEdit ? <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i> : <i style={{ fontSize: "20px" }} class="bi bi-caret-down-fill"></i>}
                    </div>
                </div>


                {this.state.showEdit ?
                    <div>
                        <ReactionRoleEditor showDelete={true} delete={this.delete} index={this.props.index} edit={true} save={this.reactonRoleSave} saveText="Save" config={this.props.value.config}></ReactionRoleEditor>
                    </div>
                    :
                    null}

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reaction_roles: state.data.bot.commands.reaction_roles,
})

const mapDispatchToProps = {
    setBotModule
}

export default connect(mapStateToProps, mapDispatchToProps)(ReactionRole)
