import React, { Component } from "react";
import { connect } from "react-redux";
// import 'emoji-mart/css/emoji-mart.css';
import Picker from "@emoji-mart/react";
import VariableEditor from "./VariableEditor";
import ReactGA from "react-ga";
import { renderCustomEmojiCategories, setBotData } from "../../../actions/index.js";
import data from "@emoji-mart/data";
import server from "../../../api/server";

export class TextArea extends Component {
	constructor(props) {
		super(props);
		this.emojiRef = React.createRef();
		this.state = {
			emoji_show: false,
			length: 0,
			showVariableEditor: false,
			showSlashCommandVariables: true,
			position: 0
		};
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);

		this.setState({ length: 0 });
		if (!this.props.bot_data || this.props.bot_data.length == 0) {
			this.getBotData();
		}
	}

	getBotData = async () => {
		const response = await server
			.post("/dashboard/botData", {
				id: this.props.bot.id,
				token: this.props.bot.token
			})
			.catch((e) => {
				this.setState({ loading: false });
			});

		if (response.data && response.data.success == true) {
			const bot_data = response.data.bot_data;
			this.props.setBotData(bot_data);
			this.setState({ loading: false });
		}
	};

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	handleClickOutside = (event) => {
		if (this.emojiRef && this.emojiRef.current && !this.emojiRef.current.contains(event.target)) {
			this.setState({ emoji_show: false });
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.value != undefined && this.state.length != this.props.value.length) {
			this.setState({ length: this.props.value.length });
		}
	}

	onEmojiSelect = (emoji) => {
		if (!emoji.native) {
			var animatedCheck = emoji.src.includes(".gif");
			var emoji = `<${animatedCheck ? "a" : ""}:${emoji.name}:${emoji.id}>`;
			this.props.onChange(this.props.value + emoji);
			this.setState({
				emoji_show: false
			});
		} else {
			this.props.onChange(this.props.value + emoji.native);
			this.setState({
				emoji_show: false
			});
		}
	};

	renderEmojiContainer = () => {
		var customCategories = renderCustomEmojiCategories(this.props.bot_data);

		var categoryIcons = [];
		if (customCategories.length > 0) {
			customCategories.forEach((category) => {
				categoryIcons[category.id] = {
					src: "https://www.svgrepo.com/show/353655/discord-icon.svg"
				};
			});
		}

		if (this.state.emoji_show == true) {
			return (
				<div
					ref={
						{
							// "absolute top-[13px] right-0 z-50"
						}
					}
					className="absolute z-[2001] top-10 -left-8 right-0 md:left-0 md:right-0"
					// style={{ top: "15px", left: "0px", right: "0px", overflow: "hidden", width: "350px" }}
				>
					<Picker noCountryFlags={false} emojiVersion={14} data={data} custom={customCategories} categoryIcons={categoryIcons} onEmojiSelect={this.onEmojiSelect} title="Pick your emoji..."></Picker>
				</div>
			);
		} else {
			return;
		}
	};

	changeHandler = (e) => {
		// console.log("ON CHANGTE", e.target.selectionStart);
		this.props.onChange(e.target.value);
	};

	variableEditorClick = (variable, slash) => {
		// console.log("Var editor click");
		var value = this.props.value;
		if (value != undefined) {
			if (slash != true) {
				// value = value.slice(0,this.state.position) + " "+variable.variable + value.slice(this.state.position);
				if (!value) value = value + variable.variable;
				else value = value + " " + variable.variable;
			} else {
				// value = value.slice(0,this.state.position) + " "+`{option_${variable.name}}` + value.slice(this.state.position);
				if (!value) value = value + `{option_${variable.name}}`;
				else value = value + " " + `{option_${variable.name}}`;
			}
			this.props.onChange(value);
			this.setState({ showVariableEditor: false });
			ReactGA.initialize("UA-122665050-1");
			ReactGA.event({
				category: "Variable",
				label: variable.variable,
				action: "click"
			});
		}
	};

	render() {
		return (
			<div className="textarea-container">
				{this.state.showVariableEditor == true && this.props.variableEditor == true ? <VariableEditor slashcommand={this.props.slash || false} slashcommandVariables={this.props.slash_options} announcement={this.props.announcement} click={this.variableEditorClick} /> : null}
				<textarea
					maxLength={2000}
					required={this.props.required || false}
					disabled={this.props.disabled == true ? true : false}
					onChange={(e) => {
						this.changeHandler(e);
					}}
					placeholder={this.props.placeholder || ""}
					value={this.props.value}
					lines={this.props.lines}
					className={`resize-textarea text-area full-width ${this.props.extra_classNames != undefined ? this.props.extra_classNames : ""}`}
				></textarea>
				{this.renderEmojiContainer()}
				<div className="textarea-counter" style={{ color: this.state.length >= 2000 ? "#f45142" : "" }}>
					{`${this.state.length}/2000`}
				</div>
				{this.props.variableEditor == true ? (
					<div className={`emoji-container mr-4 ${this.state.showVariableEditor == false ? "hithere" : null}`}>
						<i
							class={`bi bi-clipboard ${this.state.showVariableEditor == true ? "icon-red" : null}`}
							style={{ background: "none" }}
							onClick={(e) => {
								this.setState({ showVariableEditor: !this.state.showVariableEditor });
							}}
						></i>
					</div>
				) : null}

				<div className="emoji-container">
					<i
						class="bi bi-emoji-smile"
						onClick={(e) => {
							this.setState({ emoji_show: !this.state.emoji_show });
						}}
					></i>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	bot_data: state.data.bot_data
});

const mapDispatchToProps = {
	setBotData
};

export default connect(mapStateToProps, mapDispatchToProps)(TextArea);
