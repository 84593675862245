import React, { Component } from "react";
import { connect } from "react-redux";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";
import RefreshBotData from "../../elements/RefreshBotData";
import Select from "../../elements/Select";
import RoleAdd from "../../elements/RoleAdd";
import DocsLink from "../../elements/DocsLink";
import { checkIntegrationChild } from "../eventUtils";
import VariableTextInput from "../VariableTextInput";

export class PurgeMessagesAction extends Component {
	componentDidMount() {
		if (!this.props.data) {
			var target = { id: "" };
			if (this.props.components["Channel"]) {
				target = { self: true };
			}
			this.props.updateElementData({
				data: {
					type: "purge_messages",
					amount: 5,
					target: target,
					success_handles: false
				},
				id: this.props.id
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data != undefined && this.props.data.success_handles == undefined) {
			this.updateData("success_handles", false);
		}
		if (!this.props.data) {
			this.props.updateElementData({
				data: {
					type: "purge_messages",
					amount: 5,
					target: {
						self: true
					}
				},
				id: this.props.id
			});
		} else {
			if (this.props.data.target && (("self" in this.props.data.target && this.props.data.target.self) || ("id" in this.props.data.target && this.props.data.target.id) || ("variable" in this.props.data.target && this.props.data.target.variable))) {
				if (!this.props.data.validated) {
					this.updateData("validated", true);
				}
			} else {
				if (this.props.data.validated) {
					this.updateData("validated", false);
				}
			}
		}
	}

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({ data: data, id: this.props.id });
	};

	selectUpdate = (value) => {
		value = JSON.parse(value);
		var target = { ...this.props.data.target };
		target = value;
		this.updateData("target", target);
	};

	renderRoleOptions = () => {
		var options = [];
		options.push({ value: JSON.stringify({ id: "" }), label: "" });
		this.props.options.forEach((option) => {
			if (option.type == "role") {
				options.push({ value: JSON.stringify({ variable: option.name }), label: `Role Option: ${option.name}` });
			}
		});
		if (this.props.bot_data || this.props.bot_data.length > 0) {
			this.props.bot_data.forEach((guild, index) => {
				guild.roles.forEach((role) => {
					options.push({ value: JSON.stringify(role), label: `${role.name} - ${guild.name}` });
				});
			});
		}
		return options;
	};

	renderTargetTypes = () => {
		var channel = "Channel the command was run in";
		if (this.props.mode == "event" && this.props.components["Channel"]) {
			channel = "The channel.";
		}
		const targetTypes = [
			{ label: channel, value: "self", disabled: this.props.components.Interaction || this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "Channel ID or Option", value: "id" }
		];
		return targetTypes;
	};

	targetOnChange = (value) => {
		if (value == "self") {
			var data = { ...this.props.data };
			data.target = {
				self: true
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		} else if (value == "id") {
			var data = { ...this.props.data };
			data.target = {
				id: ""
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		}
	};

	renderPermissions = () => {
		var permissions = [];
		this.props.data.permissions.forEach((permission, index) => {
			permissions.push(
				<div>
					<hr class="slash-hr" style={{ borderTop: "1px solid rgb(173, 181, 189)" }} />
					<div style={{ display: "flex", alignItems: "center" }}>
						<h4 style={{ marginBottom: "0px" }}>Permission Overwrite {index + 1}</h4>
						<button
							onClick={() => {
								var permissions = [...this.props.data.permissions];
								permissions.splice(index, 1);
								this.updateData("permissions", permissions);
							}}
							className="btn btn-red"
							style={{ marginLeft: "auto", padding: "5px", fontSize: "15px", fontWeight: "800" }}
						>
							Delete
						</button>
					</div>
					<div className="mb-15 slash-action">
						<h4>Type</h4>
						<span>The type of this permission overwrite. Either Role or User.</span>
						<div className="mt-15">
							<Select
								value={permission.type}
								options={[
									{ value: "role", label: "Role" },
									{ value: "user", label: "User" }
								]}
								onChange={(value) => {
									var permissions = [...this.props.data.permissions];
									permissions[index].type = value;
									this.updateData("permissions", permissions);
								}}
							/>
						</div>
					</div>

					{permission.type == "role" ? (
						<div className="mb-15 slash-action">
							<h4>
								Role <RefreshBotData />
							</h4>
							<span>The role to apply this permission to.</span>
							<Select
								value={JSON.stringify(permission.id)}
								options={this.renderRoleOptions()}
								onChange={(value) => {
									var permissions = [...this.props.data.permissions];
									permissions[index].id = JSON.parse(value);
									this.updateData("permissions", permissions);
								}}
							/>
						</div>
					) : (
						<div className="mb-15 slash-action">
							<h4>User</h4>
							<span>The user to apply the permissions to. Either a User ID or User Option.</span>
							<VariableTextInput
								slash_options={this.props.options}
								label="User"
								placeholder="User ID"
								value={permission.id.id}
								onChange={(value) => {
									var permissions = [...this.props.data.permissions];
									permissions[index].id.id = value;
									this.updateData("permissions", permissions);
								}}
							/>
							{/* <div class="long-input mt-15">
                            <label>User</label>
                            <input onChange={(e) => {
                                var permissions = [...this.props.data.permissions];
                                permissions[index].id.id = e.target.value;
                                this.updateData("permissions", permissions);
                            }} type="text" value={permission.id.id} />
                        </div> */}
						</div>
					)}

					<div className="mb-15 slash-action">
						<h4>Allow</h4>
						<span>Permissions to allow</span>
						<RoleAdd
							update={(items) => {
								var permissions = [...this.props.data.permissions];
								permissions[index].allow = items;
								this.updateData("permissions", permissions);
							}}
							items={permission.allow}
							type={"permissions"}
						/>
					</div>

					<div className="mb-15 slash-action">
						<h4>Deny</h4>
						<span>Permissions to deny</span>
						<RoleAdd
							update={(items) => {
								var permissions = [...this.props.data.permissions];
								permissions[index].deny = items;
								this.updateData("permissions", permissions);
							}}
							items={permission.deny}
							type={"permissions"}
						/>
					</div>
				</div>
			);
		});
		return permissions;
	};

	render() {
		if (this.props.data) {
			return (
				<div>
					<div className="mb-15">
						<h4>Purge Messages</h4>
						<p>Purge messages in a channel.</p>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Channel <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/purge-messages" />
						</h4>
						<span>Which channel do you want to purge messages in</span>
						<Select
							value={"self" in this.props.data.target ? "self" : "id" in this.props.data.target ? "id" : ""}
							onChange={(value) => {
								this.targetOnChange(value);
							}}
							options={this.renderTargetTypes()}
						></Select>
					</div>

					{"variable" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>
								Channel Option <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/purge-messages" />
							</h4>
							<span>The channel option to edit the channel</span>
							<VariableTextInput
								required={true}
								slash_options={this.props.options}
								label="Option"
								placeholder="{option_channel}"
								value={this.props.data.target.variable}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										variable: value
									};
									this.props.updateElementData({ data: data, id: this.props.id });
								}}
							/>

							{/* <div className="long-input mt-15">
                            <label>Option</label>
                            <input className={this.props.saveFailed && this.props.data.target.variable == "" ? "command-required" : ""} onChange={(e) => {
                                var data = { ...this.props.data };
                                data.target = {
                                    variable: e.target.value
                                };
                                this.props.updateElementData({ data: data, id: this.props.id });
                            }} type="text" placeholder="{option_channel}" value={this.props.data.target.variable} />
                        </div> */}
						</div>
					)}

					{"id" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>
								Channel ID or Option <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/purge-messages" />
							</h4>
							<span>The channel ID or option you want to edit</span>
							<VariableTextInput
								required={true}
								slash_options={this.props.options}
								label="ID"
								placeholder="Channel ID"
								value={this.props.data.target.id}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										id: value
									};
									this.props.updateElementData({ data: data, id: this.props.id });
								}}
							/>
							{/* <div className="long-input mt-15">
                            <label>ID</label>
                            <input className={this.props.saveFailed && this.props.data.target.variable == "" ? "command-required" : ""} onChange={(e) => {
                                var data = { ...this.props.data };
                                data.target = {
                                    id: e.target.value
                                };
                                this.props.updateElementData({ data: data, id: this.props.id });
                            }} type="text" placeholder="911634771019444224" value={this.props.data.target.id} />
                        </div> */}
						</div>
					)}

					<div className="mb-15 slash-action">
						<h4>
							Amount <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/purge-messages" />
						</h4>
						<span>The amount of messages to purge in a channel.</span>
						<VariableTextInput
							required={true}
							slash_options={this.props.options}
							label="Amount"
							placeholder="25"
							value={this.props.data.amount}
							onChange={(value) => {
								this.updateData("amount", value);
							}}
						/>
						{/* <div class="long-input mt-15">
                            <label>Amount</label>
                            <input className={``} onChange={(e) => {
                                this.updateData("amount", e.target.value);
                            }} type="text" value={this.props.data.amount} placeholder="25" />
                        </div> */}
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	bot_data: state.data.bot_data,
	saveFailed: state.builder.saveFailed,
	components: state.builder.components,
	beta: state.data.beta
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(PurgeMessagesAction);
