import React, { Component } from "react";
import { connect } from "react-redux";
import ModuleHeader from "../elements/ModuleHeader";
import SectionHeader from "../elements/SectionHeader";
import Command from "../elements/Command";

import { setBotModule } from "../../../actions";

import Dashboard from "../Dashboard";
import PremiumRedirect from "../elements/PremiumRedirect";
import PremiumSection from "../elements/PremiumSection";
import MusicBotModal from "../elements/MusicBotModal";

export class Music extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tryModal: true,
    };
  }

  moduleEnable = (value) => {
    var music = { ...this.props.music };

    music.enabled = value;
    this.props.setBotModule({
      module: "music",
      module_data: music,
    });
  };

  keyChange = (value) => {
    var music = { ...this.props.music };

    music.key = value;
    this.props.setBotModule({
      module: "music",
      module_data: music,
    });
  };

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  commandUpdate = (value, type) => {
    var music = { ...this.props.music };
    music[type] = value;
    this.props.setBotModule({
      module: "music",
      module_data: music,
    });
  };
  render() {
    return (
      <Dashboard>
        <PremiumRedirect />
        <ModuleHeader
          tutorial="https://docs.botghost.com/fun-modules/music"
          premiumRequired={true}
          enabledValue={this.props.music.enabled}
          change={this.moduleEnable}
          description="Listen to music directly in your discord server"
          title="Music"
          icon="music.png"
        ></ModuleHeader>



        <PremiumSection>
          <SectionHeader title="Commands" pretitle="Music"></SectionHeader>
          <div className="row">
            <div className="col-xl-6">
              <Command
                name="play"
                description="Plays music from YouTube"
                options={this.props.music.play}
                showToggle={false}
                slashOnly={true}
                module="music"
                showPrefix={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.play.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="skip"
                description="Skips the current song


                                "
                options={this.props.music.skip}
                showToggle={false}
                slashOnly={true}
                module="music"
                showPrefix={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.skip.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="stop"
                description="Stops the music bot and clears the queue


                                "
                options={this.props.music.stop}
                showToggle={false}
                slashOnly={true}
                module="music"
                showPrefix={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.stop.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="np"
                description="Displays the currently playing song


                                "
                options={this.props.music.np}
                showToggle={false}
                module="music"
                slashOnly={true}
                showPrefix={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.np.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="queue"
                description="Displays the current song queue to the channel

                                "
                options={this.props.music.queue}
                showToggle={false}
                module="music"
                slashOnly={true}
                showPrefix={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.queue.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="pause"
                description="Pauses the current song

                                "
                options={this.props.music.pause}
                showToggle={false}
                module="music"
                slashOnly={true}
                showPrefix={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.pause.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="resume"
                description="Resumes a paused song

                                "
                options={this.props.music.resume}
                showToggle={false}
                module="music"
                showPrefix={true}
                slashOnly={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.resume.enabled != false ? true : false}
              ></Command>
            </div>

            {/* BETA */}

            <div className="col-xl-6">
              <Command
                name="wipequeue"
                description="Wipes the current song queue

                                "
                options={this.props.music.wipequeue}
                showToggle={false}
                module="music"
                showPrefix={true}
                slashOnly={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.wipequeue.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="connect"
                description="Connect the bot to a voice channel

                                "
                options={this.props.music.connect}
                showToggle={false}
                module="music"
                showPrefix={true}
                slashOnly={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.connect.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="disconnect"
                description="Disconnect the bot from a voice channel

                                "
                options={this.props.music.disconnect}
                showToggle={false}
                module="music"
                showPrefix={true}
                slashOnly={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.disconnect.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="loop"
                description="Enable music looping

                                "
                options={this.props.music.loop}
                showToggle={false}
                module="music"
                showPrefix={true}
                slashOnly={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.loop.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="remove"
                description="Remove a song from the music queue

                                "
                options={this.props.music.remove}
                showToggle={false}
                module="music"
                showPrefix={true}
                slashOnly={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.remove.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="seek"
                description="Seek through the current playing song

                                "
                options={this.props.music.seek}
                showToggle={false}
                module="music"
                showPrefix={true}
                slashOnly={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.seek.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="forward"
                description="Skip forward through the song queue

                                "
                options={this.props.music.forward}
                showToggle={false}
                module="music"
                showPrefix={true}
                slashOnly={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.forward.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="shuffle"
                description="Shuffle the music queue

                                "
                options={this.props.music.shuffle}
                showToggle={false}
                module="music"
                showPrefix={true}
                slashOnly={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.shuffle.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="dmsong"
                description="DMs the current song to the user

                                "
                options={this.props.music.dmsong}
                showToggle={false}
                module="music"
                betaOnly={true}
                showPrefix={true}
                slashOnly={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.dmsong.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="filter"
                description="Applies a filter to the song queue

                                "
                options={this.props.music.filter}
                showToggle={false}
                module="music"
                betaOnly={true}
                showPrefix={true}
                slashOnly={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.filter.enabled != false ? true : false}
              ></Command>
            </div>

            <div className="col-xl-6">
              <Command
                name="volume"
                description="Change the volume

                                "
                options={this.props.music.volume}
                showToggle={false}
                module="music"
                betaOnly={true}
                showPrefix={true}
                slashOnly={true}
                update={this.commandUpdate}
                showToggle={true}
                toggleValue={this.props.music.volume.enabled != false ? true : false}
              ></Command>
            </div>
          </div>
        </PremiumSection>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  music: state.data.bot.commands.music,
  premium: state.data.premium,
  beta: state.data.beta,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Music);
