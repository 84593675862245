import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';

import TextArea from '../../elements/TextArea';
import Embed from '../../elements/Embed';
import Select from '../../elements/Select';
import RefreshBotData from '../../elements/RefreshBotData';
import { EmojiAdder } from './EmojiAdder';

const EPHEMERAL_OPTIONS = [
    { value: "true", label: "Hide error messages" },
    { value: "false", label: "Show error messages to everyone." },
];

export class ErrorHandler extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "ban_action",
                        target: "",
                        reason: "",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "ban_action",
                        target: "",
                        reason: "",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

            if (this.props.data.target != "") {
                if (this.props.data.validated == false) {
                    this.updateData("validated", true);
                }
            } else if (this.props.data.validated == true) {
                this.updateData("validated", false);
            }
        }
    }

    nameChange = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "-");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("name", value);
    };

    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    renderValue = () => {
        var root = this.props.elements.find(element => element.id == 'root');
        var data = { ...root.data.data };

        if (data.error_ephemeral == true || data.error_ephemeral == undefined) {
            return 'true';
        } else {
            return 'false';
        }
    };


    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Error Handler</h4>
                        <p>Handle errors that occur during the {this.props.mode == "event" ? "event" : "command"} execution. Linked actions and condtions will be executed when an error occurs.</p>
                    </div>

                    {this.props.mode != "event" ?
                        <div className="mb-15">
                            <div className="slash-action">
                                <h4>Hide error messages from everyone but the executor of the slash command.  <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions"} /></h4>
                                <p>Hides the error messages of the command from everyone except the person who triggered the command. Will not work for targeted responses and DM actions.</p>
                            </div>
                            <div>
                                <Select value={this.renderValue()} options={EPHEMERAL_OPTIONS} onChange={(value) => {
                                    var root = this.props.elements.find(element => element.id == 'root');
                                    var data = { ...root.data.data };
                                    // var ephemeral = value;

                                    data.error_ephemeral = value == "true" ? true : false;
                                    // console.log(data);
                                    this.props.updateElementData(
                                        {
                                            data: data,
                                            id: "root"
                                        }
                                    );

                                }} />
                            </div>
                        </div> : null}

                    <div className='mb-15 slash-action'>
                        <h4>Variables </h4>
                        <span style={{ marginBottom: "15px" }}>Handle errors when they occur. Use the following variables in actions linked to this error handler. </span>
                        <p style={{
                            color: "#fff",
                            opacity: "0.7",
                            fontWeight: 500,
                            marginTop: "15px"
                        }}><span style={{ color: "#f45142", opacity: "1" }}>{`{error_reason}`}</span> - Returns the reason why the error occurred.</p>

                        <p style={{
                            color: "#fff",
                            opacity: "0.7",
                            fontWeight: 500
                        }}><span style={{ color: "#f45142", opacity: "1" }}>{`{error_message}`}</span> - Returns the error message associated with the action the error occurred from.</p>

                        <p style={{
                            color: "#fff",
                            opacity: "0.7",
                            fontWeight: 500
                        }}><span style={{ color: "#f45142", opacity: "1" }}>{`{error_block}`}</span> - Returns name of the block which the error occurred in.</p>

                    </div>

                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    mode: state.builder.mode
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);
