import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import { updateElementData } from '../../../../actions';
import Select from '../../elements/Select';
import { checkIntegrationChild } from '../eventUtils';
import VariableTextInput from '../VariableTextInput';

export class CurrencyAction extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            var target = "";
            if (this.props.components['Member']) {
                target = "trigger";
            }
            this.props.updateElementData(
                {
                    data: {
                        type: this.props.type,
                        amount: "",
                        target: target,
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: this.props.type,
                        amount: "",
                        target: "trigger",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

            // if ((this.props.data.amount != "" && typeof this.props.data.target === 'object' ? this.props.data.target.id != "" : this.props.data.target != "") && this.props.data.validated != true) {
            //     this.updateData("validated", true);
            // } else if (this.props.data.validated == true && (this.props.data.amount == "" || this.props.data.target == "")) {
            //     this.updateData("validated", false);
            // }

            if (('amount' in this.props.data && this.props.data.amount)) {
                if (this.props.data.validated == false && this.validateTarget()) {
                    this.updateData("validated", true);
                } else if (this.props.data.validated == true && !this.validateTarget()) {
                    this.updateData("validated", false);
                }
            } else if (this.props.data.validated == true) {
                this.updateData("validated", false);
            }
        }
    }

    validateTarget = () => {
        if ("target" in this.props.data) {
            if (typeof this.props.data.target === "object" && this.props.data.target.id != "") {
                // console.log("here");
                return true;
            } else if (typeof this.props.data.target === "string" && this.props.data.target != "") {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    renderUserOptions = () => {
        var member = "The user who triggered the command.";
        if (this.props.mode == "event" && this.props.components['User']) {
            member = "The user who triggered the event.";
        }
        var options = [
            { value: "", label: "" },
            { value: "id", label: "User ID" },
            { value: "trigger", label: member, disabled: this.props.components.Interaction || this.props.components.User ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true }
        ];
        this.props.options.forEach(option => {
            if (option.type == "user") {
                options.push({ value: option.name, label: `User Option: ${option.name}` });
            }
        });
        return options;
    };


    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    selectValue = () => {
        if (typeof this.props.data.target === 'object') {
            return "id";
        }
        return this.props.data.target;
    };

    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>{this.props.type == "add_currency" ? "Add" : "Remove"} Currency</h4>
                        <p>{this.props.type == "add_currency" ? "Adds to a " : "Removes currency from"}  users balance.</p>
                    </div>
                    <div className="mb-15">
                        <h4 style={{ marginBottom: "0px" }}>Target <DocsLink location={`https://docs.botghost.com/custom-commands-and-events/actions/${this.props.type == "add_currency" ? 'add' : "remove"}-currency#target`} /></h4>
                        <div className="section-content-header mb-15">Who to {`${this.props.type == "remove_currency" ? "remove currency from." : "add currency to."}`}</div>
                        <Select options={this.renderUserOptions()} value={this.selectValue()} onChange={(value) => {
                            if (value === "id") {
                                return this.updateData("target", { id: "" });
                            }
                            this.updateData("target", value);
                        }}></Select>
                        {
                            typeof this.props.data.target === 'object' &&
                            <div className="mt-15 slash-action">
                                <h4>User ID </h4>
                                <span>The user id to add currency to</span>
                                {/* <VariableTextInput slash_options={this.props.options} value={this.props.data.response_options.target.user_option} onChange={(value) => {
                                var response_options = { ...this.props.data.response_options };
                                response_options.target.user_option = value;
                                this.updateData("response_options", response_options);
                            }} /> */}

                                <VariableTextInput label="ID" placeholder="136327647792726016" value={this.props.data.target.id} onChange={(value) => {
                                    var target = { ...this.props.data.target };
                                    target.id = value;
                                    this.updateData("target", target);
                                }} />
                                {/* <div class="long-input mt-15">
                                    <label>ID</label>
                                    <input className={this.props.saveFailed && this.props.data.target.id == "" ? "command-required" : ""} onChange={(e) => {

                                        var data = { ... this.props.data };
                                        data.target = {
                                            id: e.target.value,
                                        };
                                        this.props.updateElementData(
                                            {
                                                data: data,
                                                id: this.props.id
                                            }
                                        );

                                    }} type="text" placeholder={"136327647792726016"} value={this.props.data.target.id} />
                                </div> */}

                            </div>
                        }
                    </div>
                    <div className="mb-15 slash-action">
                        <h4>Amount <DocsLink location={`https://docs.botghost.com/custom-commands-and-events/actions/${this.props.type == "add_currency" ? 'add' : "remove"}-currency#amount`} /></h4>
                        <span>{`The amount of currency to ${this.props.type == "add_currency" ? "add" : "remove"}. You can use string and number options.`}</span>
                        <VariableTextInput label="Amount" placeholder="100" value={this.props.data.amount} onChange={(value) => {
                            this.updateData("amount", value);
                        }} />

                        {/* <div class="long-input mt-15">
                            <label>Amount</label>
                            <input className={`${this.props.data.amount == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                this.updateData("amount", e.target.value);
                            }} type="text" value={this.props.data.amount} />
                        </div> */}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    components: state.builder.components,
    mode: state.builder.mode,
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyAction);
