import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData, setHighlightAction } from "../../../../actions";

import TextArea from "../../elements/TextArea";
import Select from "../../elements/Select";
import VariableTextInput from "../VariableTextInput";

export class DeleteMessage extends Component {
	componentDidMount() {
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "delete_message",
					specific: false,
					channel_id: "",
					message_id: "",
					id: "",
					action_id: "",
					validated: false
				},
				id: this.props.id
			});
		} else {
			this.props.setHighlightAction(this.props.data.action_id);
		}
	}

	componentWillUnmount() {
		this.props.setHighlightAction(null);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.id != this.props.id && this.props.data != undefined) {
			this.props.setHighlightAction(this.props.data.action_id);
		}
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "delete_message",
					specific: false,
					channel_id: "",
					message_id: "",
					id: "",
					action_id: "",
					validated: false
				},
				id: this.props.id
			});
		} else {
			// VALIDATE

			if ((!this.props.data.specific && this.props.data.id != "") || (this.props.data.specific && this.props.data.channel_id && this.props.data.message_id)) {
				if (!this.props.data.validated) {
					this.updateData("validated", true);
				}
			} else {
				if (this.props.data.validated) {
					this.updateData("validated", false);
				}
			}
		}
	}

	updateData = (key, value) => {
		// console.log("VALUE", value);
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	renderOptions = () => {
		var options = [
			{
				label: "",
				value: null
			}
		];

		var types = ["plain_text", "advanced_message", "embed", "random_response", "button_response", "targeted_response", "dm_response", "select_menu"];

		this.props.actions.forEach((action) => {
			if ("data" in action && "data" in action.data && action.data.data && types.includes(action.data.data.type)) {
				var label = action.data.node_options.title;
				if ("command_label" in action.data.data && action.data.data.command_label != "") {
					label = action.data.data.command_label;
				}
				options.push({
					value: action.id,
					label: label
				});
			}
		});
		if (this.props.components.Message) {
			options.push({
				value: "root",
				label: "The Message"
			});
		}

		return options;
	};

	selectChange = (value) => {
		if (!value) return;
		this.props.setHighlightAction(value);
		if (value == "root") {
			var action_data = { ...this.props.data };
			action_data.id = "root";
			action_data.action_id = "root";
			return this.props.updateElementData({
				data: action_data,
				id: this.props.id
			});
		}
		var action = this.props.actions.find((action) => action.id == value);

		if (!("editId" in action.data.data)) {
			var data = { ...action.data.data };
			data.editId = `${Date.now()}`;
			this.props.updateElementData({
				data: data,
				id: value
			});
			action.data.data.editId = data.editId;
		}
		var id = action.data.data.editId;

		var action_data = { ...this.props.data };
		action_data.id = id;
		action_data.action_id = value;
		this.props.updateElementData({
			data: action_data,
			id: this.props.id
		});
	};

	render() {
		if (this.props.data != undefined) {
			return (
				<div>
					<div className="mb-15">
						<h4>Delete a Message</h4>
						<p>Delete a message sent by the bot in this command. Select a response action to delete the message sent in that action.</p>
					</div>

					<div className="mb-15 slash-action">
						<h4>Delete by Message ID or another Action</h4>
						<span className="mb-15">Whether or not to delete a specific message by its message id or the message produced by another action.</span>
						<Select
							value={this.props.data.specific}
							onChange={(value) => {
								if (value == "true") {
									value = true;
								} else if (value == "false") {
									value = false;
								}

								if (value == true && this.props.data.channel_id == undefined) {
									this.updateData("channel_id", "");
									this.updateData("message_id", "");

									var data = { ...this.props.data };
									data.channel_id = "";
									data.message_id = "";
									data.action_id = "";
									data.id = "";
									data.specific = value;
									this.props.updateElementData({
										data: data,
										id: this.props.id
									});
								} else {
									var data = { ...this.props.data };
									data.action_id = "";
									data.id = "";
									data.specific = value;
									this.props.updateElementData({
										data: data,
										id: this.props.id
									});
								}
							}}
							options={[
								{ label: "Message Id", value: true },
								{ label: "Another Action", value: false }
							]}
						></Select>
					</div>

					<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>

					{this.props.data.specific == true ? (
						<>
							<div className="mb-15 slash-action">
								<h4>Channel Id</h4>
								<span>The channel the message is in</span>
								{/* <div class="long-input mt-15">
									<label>Channel Id</label>
									<input
										className={``}
										onChange={(e) => {
											this.updateData("channel_id", e.target.value);
										}}
										type="text"
										value={this.props.data.channel_id}
										placeholder="928304793309544468"
									/>
								</div> */}

								<VariableTextInput
									label={"Channel Id"}
									type="text"
									onChange={(value) => {
										this.updateData("channel_id", value);
									}}
									value={this.props.data.channel_id}
									placeholder="928304793309544468"
								/>
							</div>

							<div className="mb-15 slash-action">
								<h4>Message Id</h4>
								<span>The message id of the message to delete.</span>
								{/* <div class="long-input mt-15">
									<label>Message Id</label>
									<input
										className={``}
										onChange={(e) => {
											this.updateData("message_id", e.target.value);
										}}
										type="text"
										value={this.props.data.message_id}
										placeholder="1179107633148481696"
									/>
								</div> */}

								<VariableTextInput
									label={"Message Id"}
									type="text"
									onChange={(value) => {
										this.updateData("message_id", value);
									}}
									value={this.props.data.message_id}
									placeholder="1179107633148481696"
								/>
							</div>
						</>
					) : (
						<>
							<h4 style={{ marginBottom: "0px" }}>
								Response Action<DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/delete-a-message#response-action"}></DocsLink>
							</h4>
							<div className="section-content-header mb-15">Select one of the response actions in this command to delete the message sent by that action.</div>
							<Select saveFailed={this.props.data.action_id == "" && this.props.saveFailed} value={this.props.data.action_id} options={this.renderOptions()} onChange={this.selectChange} />
						</>
					)}
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	saveFailed: state.builder.saveFailed,
	components: state.builder.components,
	beta: state.data.beta
});
const mapDispatchToProps = {
	updateElementData,
	setHighlightAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteMessage);
