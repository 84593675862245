import React, { Component } from 'react'
import ReactDOM from "react-dom"
import { connect } from 'react-redux'
import Select from './Select'
import PlainText_Action from './slashcommandactions/PlainText_Action'
import Embed_Action from "./slashcommandactions/Embed_Action"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Role_Action from './slashcommandactions/Role_Action'
import DocsLink from './DocsLink'
import Random_Response_Action from './slashcommandactions/Random_Response_Action'
import Button_Action from './slashcommandactions/Button_Action'
import Targeted_Response from './slashcommandactions/Targeted_Response'
import DM_Action from './slashcommandactions/DM_Action'
import ReactGA from 'react-ga';
import DiscordMessagePreview from './DiscordMessagePreview'

const ACTION_TYPES = [
    {
        name:"Response Actions",
        options:[
            {value:"plain_text",label:"Bot replies with a plain text response"},
            {value:"embed",label:"Bot replies with an embed response"},
            {value:"random_response",label:"Bot responds with a random response"},
            {value:"targeted_response",label:"Bot sends a message to a specific channel", disabled:false},
            {value:"dm_response",label:"Bot sends a direct message to the member who used the command", disabled:false},
            {value:"plain_text_channel",label:"Bot waits x (amount of time) before next action (COMING SOON)", disabled:true},
        ]
    },
    {
        name:"Button Action",
        options:[
            {value:"button_response",label:"Add buttons and button actions",button:false},

        ]
    },
    {
        name:"Role Actions",
        options:[
            {value:"role_add",label:"Adds one or more roles to a user"},
            {value:"role_remove",label:"Removes one or more roles from a user"}
        ]
    },

    {
        name:"Reaction Actions",
        options:[
            {value:"Command Reaction",label:"Adds one or more reactions to the message that triggered the command. (COMING SOON)",disabled:true},
        ]
    },
    {
        name:"Server Actions",
        options:[
            {value:"Ban a Member",label:"Bans a member from the server (COMING SOON)",disabled:true},
            {value:"Kick a Member",label:"Kicks a member from the server (COMING SOON)",disabled:true},
            {value:"Mute a Member",label:"Mute a Member (COMING SOON)",disabled:true},
            {value:"Warn a aMember",label:"Warns a Member (COMING SOON)",disabled:true},
            {value:"Create a text-channel",label:"Creates a text-channel in the server (COMING SOON)",disabled:true},
            {value:"Create a voice-channel",label:"Creates a voice-channel in the server (COMING SOON)",disabled:true},
            {value:"Join a Voice Channel",label:"Bot joins a voice channel (COMING SOON)",disabled:true}
        ]
    }
]

export class SlashCommandAction extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showContents:true,
            valid:false
        }
    }

    componentDidMount() {
        if(this.props.edit == true){
            this.setState({showContents:false})
        }
        document.addEventListener("click",this.handleClick,true)
    }

    componentWillUnmount (){
        document.removeEventListener("click",this.handleClick,true)
    }

    componentDidUpdate(prevProps, prevState) {
        this.validateAction()
    }

    handleClick = (event) =>{
        const domNode = ReactDOM.findDOMNode(this);
        if(!domNode || !domNode.contains(event.target) && this.state.valid == true){
            this.setState({showContents:false})
        }
    }

    validateAction = () =>{
        var valid = false;
        if(this.props.data.type == "plain_text" && this.props.data.response != ""){
            valid = true;
        }else if(this.props.data.type  == "embed" && this.props.data.embed != undefined && this.props.data.embed.title != "" && this.props.data.embed.description != ""){
            valid = true;
        }else if((this.props.data.type == "role_add" || this.props.data.type == "role_remove") && this.props.data.roles != undefined &&  this.props.data.roles.length != 0 ){
            valid = true;
        }else if(this.props.data.type == "random_response" && this.props.data.responses != undefined &&  this.props.data.responses.length >=1){
            valid=true
            this.props.data.responses.forEach(response =>{
                if("embed" in response && (response.embed.title == "" || response.embed.description == "")){
                    valid = false;
                }else if("response" in response && response.response == ""){
                    valid = false
                }
            })
        }else if(this.props.data.type == "dm_response" && this.props.data.response_options != undefined){
            if(this.props.data.response_options.response != undefined && this.props.data.response_options.response != ""){
                valid = true
            }else if(this.props.data.response_options.embed != undefined && this.props.data.response_options.embed.title != "" && this.props.data.response_options.embed.description != "" ){
                valid = true
            }

        }

        if(this.state.valid != valid){
            this.setState({valid:valid})
        }
    }

    renderTypeLabel = (value) =>{
        var string = ""
        ACTION_TYPES.forEach(type =>{
            type.options.forEach(option =>{
                if(option.value == value){
                    string =  option.label
                }
            })
        })

        if(this.props.button == true){
            string = string + " on button click"
        }
        return string;
    }
    

    update = (key,value) =>{
        this.props.update(key,value,this.props.index)
    }

    renderAction = () =>{
        if(this.props.data.type == "plain_text"){
            return <PlainText_Action preview_interaction={true} name={this.props.name} options={this.props.options} data={this.props.data} update={this.update}></PlainText_Action>
        }else if(this.props.data.type  == "embed"){
            return <Embed_Action preview_interaction={true} options={this.props.options} data={this.props.data} update={this.update}/>
        }else if(this.props.data.type == "role_add" || this.props.data.type == "role_remove"){
            return <Role_Action data={this.props.data} options={this.props.options} update={this.update}></Role_Action>
        }else if(this.props.data.type == "random_response"){
            return <Random_Response_Action options={this.props.options} data={this.props.data} update={this.update}/>
        }else if(this.props.data.type == "button_response"){
            return <Button_Action options={this.props.options} data={this.props.data} update={this.update}/>
        }else if(this.props.data.type == "targeted_response"){
            return <Targeted_Response options={this.props.options} data={this.props.data} update={this.update}/>
        }else if(this.props.data.type == "dm_response"){
            return <DM_Action options={this.props.options} data={this.props.data} update={this.update}/>
        }
    }

    selectChange = (value) =>{
        
        ReactGA.initialize('UA-122665050-1');
        ReactGA.event({
            category:"Custom Command Action",
            label:value,
            action:"New Dashboard"
        })

        this.props.update("type", value, this.props.index)
    }


    
    render() {
        return (

            <div className="announcement slashcommand-option-container">
                <div className="announcement-header" onClick={(e) => { this.state.valid == true ? this.setState({ showContents: !this.state.showContents }) : this.setState({ showContents: true }) }}>
                    <div>
                        <h3 className="mb-0">Action {this.props.index + 1} </h3>
                        <div className="section-content-header">{this.renderTypeLabel(this.props.data.type)} </div>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                        {this.state.showContents == true ? <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i> : <i style={{ fontSize: "20px" }} class="bi bi-caret-down-fill"></i>}
                    </div>
                </div>

                <div>
                    {this.state.showContents == true ?
                        <div className="slashcommand-option">
                            <hr className="slashcommand-hr"></hr>
                            <div className="mb-15">
                                <h4>Action</h4>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="mb-15 slash-action">
                                        <h4>Action Type <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions"} /></h4>
                                        <span>Pick an action from the dropdown below. You can add as many actions to your command as you like.</span>
                                        <div className="mt-15">
                                            <Select button={this.props.button} value={this.props.data.type} onChange={this.selectChange} type="slashcommand_actions" options={ACTION_TYPES}></Select>
                                        </div>
                                    </div>
                                    <hr class="slashcommand-hr" />

                                    <div>
                                        {this.renderAction()}

                                    </div>
                                </div>
                            </div>

                            <div className="mb-15">
                                        <button onClick={(e) => { this.props.delete(this.props.index) }} className="btn btn-red" style={{ backgroundColor: "rgba(255, 107, 134, 0.5)" }}><FontAwesomeIcon icon={faTrash} /> {this.props.button == true ? "Delete Button Action":"Delete Action"}</button>
                                </div>
                            <hr className="slashcommand-hr"></hr>

                        </div>
                        : null
                    }
                </div>



            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(SlashCommandAction)
