import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";

import Embed from "../../elements/Embed";
import RoleAdd from "../../elements/RoleAdd";
import Select from "../../elements/Select";
import { checkIntegrationChild } from "../eventUtils";
import LongText from "../../inputs/LongText";
import LongInput from "../../elements/LongInput";
import VariableTextInput from "../VariableTextInput";

export class VoiceLeave extends Component {
	componentDidMount() {
		if (this.props.data == undefined) {
			var target = { self: true };
			this.props.updateElementData({
				data: {
					validated: false,
					type: "voice_leave",
					guild_id: "",
					success_handles: false
				},
				id: this.props.id
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data != undefined && this.props.data.success_handles == undefined) {
			this.updateData("success_handles", false);
		}
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					validated: false,
					type: "voice_leave",
					guild_id: "",
					validated: true,
					success_handles: false
				},
				id: this.props.id
			});
		} else if (this.props.data && !this.props.data.target) {
			if (this.props.components["Member"]) {
				this.updateData("target", { self: true });
			} else {
				this.updateData("target", { id: "" });
			}
		} else if (this.props.data && typeof this.props.data.target === "string") {
			console.log("UPDATE TARGET");
			if (this.props.components["Member"]) {
				this.updateData("target", { self: true });
			} else {
				this.updateData("target", { id: "" });
			}
		} else {
			// VALIDATE
			if (this.props.data.validated == false) {
				this.updateData("validated", true);
			}
		}
	}

	validateTarget = () => {
		if (typeof this.props.data.target === "string") {
			return true;
		} else if ("id" in this.props.data.target && this.props.data.target.id != "") {
			return true;
		} else if ("self" in this.props.data.target && this.props.data.target.self) {
			return true;
		} else if ("variable" in this.props.data.target && this.props.data.target.variable != "") {
			return true;
		} else {
			return false;
		}
	};

	nameChange = (value) => {
		value = value.toLowerCase();
		value = value.substring(0, 32);
		value = value.replace(" ", "-");
		const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/gu);
		value = value.replace(regexForNonAlphaNum, "");
		this.updateData("name", value);
	};

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	renderRoleOptions = () => {
		var options = [
			// {value:"trigger",label:"The member who triggered the command"}
		];
		this.props.options.forEach((option) => {
			if (option.type == "role") {
				options.push({ type: "role", name: option.name });
			}
		});
		return options;
	};

	renderResponseTypes = () => {
		var user = "The voice channel of the user.";
		if (this.props.mode == "event") {
			user = "The voice channel associated with this event.";
		}

		const responseTypes = [
			{ label: user, value: "self", disabled: this.props.components.Interaction || this.props.components.User ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "Channel option", value: "variable", disabled: !this.props.components.Interaction },
			{ label: "Voice Channel ID", value: "id" }
		];
		return responseTypes;
	};

	renderUserOptions = () => {
		var member = "The member who triggered the command";
		if (this.props.mode == "event" && this.props.components["Channel"]) {
			member = "The user.";
		}
		var options = [{ value: "trigger", label: member, disabled: this.props.components.Interaction || this.props.components.Member ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true }];
		this.props.options.forEach((option) => {
			if (option.type == "user") {
				options.push({ value: option.name, label: `User Option: ${option.name}` });
			}
		});
		if (this.props.mode == "event") {
			options.unshift({ value: "", label: "", disabled: true });
		}
		console.log(options);
		return options;
	};

	targetOnChange = (value) => {
		// {label:"User who used the command",value:"self"},
		// {label:"User variable",value:"variable"},
		// {label:"User ID",value:"id"},
		if (value == "self") {
			var data = { ...this.props.data };
			data.target = {
				self: true
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		} else if (value == "variable") {
			var data = { ...this.props.data };
			data.target = {
				variable: ""
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		} else if (value == "id") {
			var data = { ...this.props.data };
			data.target = {
				id: ""
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		}
	};

	renderOptions = () => {
		var options = [];
		this.props.options.forEach((option) => {
			if (option.type === "channel") {
				options.push({ value: option.name, label: `User Option: ${option.name}` });
			}
		});
		return options;
	};

	render() {
		if (this.props.data != undefined && typeof this.props.data.target === "object") {
			return (
				<div>
					<div className="mb-15">
						<h4>Leave a Voice Channel</h4>
						<p>Force the bot to leave its voice channel.</p>
					</div>
					<div className="mb-15 slash-action">
						<h4>
							Optional Server ID <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#optional-server-id" />
						</h4>
						<span>An optional server Id for the bot to leave. An Id or Variable can be used. Leave this blank to voice leave in the server the {this.props.mode == "event" ? "event" : "command"} was triggered in.</span>

						<VariableTextInput
							label="Server ID"
							placeholder="822426820447567872"
							value={this.props.data.guild_id}
							onChange={(value) => {
								this.updateData("guild_id", value);
							}}
							slash_options={this.props.options}
						/>
						{/* <div class="long-input mt-15">
                            <label>ID or Variable</label>
                            <input onChange={(e) => {
                                this.updateData("guild_id", e.target.value);
                            }} maxLength={250} type="text" value={this.props.data.guild_id} placeholder="822426820447567872" />
                        </div> */}
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	saveFailed: state.builder.saveFailed,
	components: state.builder.components,
	mode: state.builder.mode
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(VoiceLeave);
