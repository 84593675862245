import React, { Component } from "react";
import { connect } from "react-redux";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";
import Select from "../../elements/Select";
import RoleAdd from "../../elements/RoleAdd";
import VariableTextInput from "../VariableTextInput";

export class RoleEditAction extends Component {
	componentDidMount() {
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "edit_role",
					name: "",
					color: "",
					hoist: false,
					mentionable: false,
					permissions: [],
					position: "",
					reason: "",
					validated: false,
					success_handles: false,
					target: { id: "" }
				},
				id: this.props.id
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data != undefined && this.props.data.success_handles == undefined) {
			this.updateData("success_handles", false);
		}
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "edit_role",
					name: "",
					color: "",
					hoist: false,
					mentionable: false,
					permissions: [],
					position: "",
					reason: "",
					validated: false,

					target: { id: "" }
				},
				id: this.props.id
			});
		} else {
			if (this.props.data.target && (("id" in this.props.data.target && this.props.data.target.id) || ("variable" in this.props.data.target && this.props.data.target.variable))) {
				if (!this.props.data.validated) {
					this.updateData("validated", true);
				}
			} else {
				if (this.props.data.validated) {
					this.updateData("validated", false);
				}
			}
		}
	}

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({ data: data, id: this.props.id });
	};

	selectUpdate = (value) => {
		value = JSON.parse(value);
		var target = { ...this.props.data.target };
		target = value;
		this.updateData("target", target);
	};

	renderRoleOptions = () => {
		var options = [];
		options.push({ value: JSON.stringify({ id: "" }), label: "" });
		this.props.options.forEach((option) => {
			if (option.type == "role") {
				options.push({ value: JSON.stringify({ variable: option.name }), label: `Role Option: ${option.name}` });
			}
		});

		if (this.props.bot_data || this.props.bot_data.length > 0) {
			this.props.bot_data.forEach((guild, index) => {
				const guild_options = [];
				guild.roles.forEach((role) => {
					options.push({ value: JSON.stringify(role), label: `${role.name} - ${guild.name}` });
				});
			});
		}
		return options;
	};

	renderTargetTypes = () => {
		const targetTypes = [
			{ label: "Role Option", value: "variable", disabled: !this.props.components.Interaction },
			{ label: "Role ID", value: "id" }
		];
		return targetTypes;
	};

	targetOnChange = (value) => {
		if (value === "variable") {
			var data = { ...this.props.data };
			data.target = {
				variable: ""
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		} else if (value === "id") {
			var data = { ...this.props.data };
			data.target = {
				id: ""
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		}
	};

	render() {
		if (this.props.data) {
			return (
				<div>
					<div className="mb-15">
						<h4>Edit a Role</h4>
						<p>Edit a role in the server. Options and variables can be used for the reason.</p>
					</div>

					<div className="mb-15 slash-action">
						<h4>Role</h4>
						<span>Which role do you want to edit?</span>
						<Select
							value={"variable" in this.props.data.target ? "variable" : "id" in this.props.data.target ? "id" : ""}
							onChange={(value) => {
								this.targetOnChange(value);
							}}
							options={this.renderTargetTypes()}
						></Select>
					</div>

					{"variable" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>Role Option </h4>
							<span>The role option to edit</span>
							<VariableTextInput
								required={true}
								label="Option"
								placeholder="{option_role}"
								value={this.props.data.target.variable}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										variable: value
									};
									this.props.updateElementData({ data: data, id: this.props.id });
								}}
								slash_options={this.props.options}
							/>
							{/* <div className="long-input mt-15">
                            <label>Option</label>
                            <input className={this.props.saveFailed && this.props.data.target.variable === "" ? "command-required" : ""} onChange={(e) => {
                                var data = { ...this.props.data };
                                data.target = {
                                    variable: e.target.value
                                };
                                this.props.updateElementData({ data: data, id: this.props.id });
                            }} type="text" placeholder="{option_role}" value={this.props.data.target.variable} />
                        </div> */}
						</div>
					)}

					{"id" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>Role ID</h4>
							<span>The ID of the role that you want to edit</span>
							<VariableTextInput
								required={true}
								label="ID"
								placeholder="911634771019444224"
								value={this.props.data.target.id}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										id: value
									};
									this.props.updateElementData({ data: data, id: this.props.id });
								}}
								slash_options={this.props.options}
							/>
							{/* <div className="long-input mt-15">
                            <label>ID</label>
                            <input className={this.props.saveFailed && this.props.data.target.variable === "" ? "command-required" : ""} onChange={(e) => {
                                var data = { ...this.props.data };
                                data.target = {
                                    id: e.target.value
                                };
                                this.props.updateElementData({ data: data, id: this.props.id });
                            }} type="text" placeholder="911634771019444224" value={this.props.data.target.id} />
                        </div> */}
						</div>
					)}

					<div className="mb-15 slash-action">
						<h4>Name </h4>
						<span>The new name for the role you want to edit</span>
						<VariableTextInput
							required={true}
							label="Name"
							placeholder="New Role Name"
							value={this.props.data.name}
							onChange={(value) => {
								this.updateData("name", value);
							}}
							slash_options={this.props.options}
						/>

						{/* <div class="long-input mt-15">
                            <label>Name</label>
                            <input className={``} onChange={(e) => {
                                this.updateData("name", e.target.value);
                            }} maxLength={50} type="text" value={this.props.data.name} />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>Reason </h4>
						<span>An optional reason for editing the role.</span>
						<VariableTextInput
							label="Reason"
							placeholder="Reason"
							value={this.props.data.reason}
							onChange={(value) => {
								this.updateData("reason", value);
							}}
							slash_options={this.props.options}
						/>

						{/* <div class="long-input mt-15">
                            <label>Reason</label>
                            <input onChange={(e) => {
                                this.updateData("reason", e.target.value);
                            }} maxLength={250} type="text" value={this.props.data.reason} />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>Position </h4>
						<span>The position of the edited role. Leave blank for default positioning.</span>
						<VariableTextInput
							label="Position"
							placeholder="Position"
							value={this.props.data.position}
							onChange={(value) => {
								this.updateData("position", value);
							}}
							slash_options={this.props.options}
						/>

						{/* <div class="long-input mt-15">
                            <label>Position</label>
                            <input onChange={(e) => {
                                this.updateData("position", e.target.value);
                            }} type="number" value={this.props.data.position} />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>Color</h4>
						<span>The color of the edited role. Members use the color of the highest role they have on the roles list.</span>
						<VariableTextInput
							label="Color"
							placeholder="Color"
							value={this.props.data.color}
							onChange={(value) => {
								this.updateData("color", value);
							}}
							slash_options={this.props.options}
						/>
						{/* <div class="long-input mt-15">
                            <label>Hex</label>
                            <input onChange={(e) => {
                                this.updateData("color", e.target.value);
                            }} maxLength={250} type="text" value={this.props.data.color} />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>Display role members seperately from online members </h4>
						<span>Whether or not to hoist the role.</span>
						<div className="mt-15">
							<Toggle
								value={this.props.data.hoist}
								update={(value) => {
									this.updateData("hoist", value);
								}}
							/>
						</div>
					</div>

					<div className="mb-15 slash-action">
						<h4>Mentionable</h4>
						<span>Allow anyone to @mention this role.</span>
						<div className="mt-15">
							<Toggle
								value={this.props.data.mentionable}
								update={(value) => {
									this.updateData("mentionable", value);
								}}
							/>
						</div>
					</div>

					<hr class="slash-hr" style={{ borderTop: "1px solid rgb(173, 181, 189)" }} />

					<div className="mb-15 slash-action">
						<h4>Permissions </h4>
						<span>Add Permissions for this role. The role will start with no default permissions.</span>
						<RoleAdd
							update={(items) => {
								var permissions = [...this.props.data.permissions];
								permissions = items;
								this.updateData("permissions", permissions);
							}}
							items={this.props.data.permissions}
							type={"permissions"}
						/>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	bot_data: state.data.bot_data,
	saveFailed: state.builder.saveFailed,
	components: state.builder.components,
	beta: state.data.beta
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleEditAction);
