import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import ReactionRoleEditor from "../elements/ReactionRoleEditor";
import Command from "../elements/Command";
import RoleAdd from "../elements/RoleAdd";

import Dashboard from "../Dashboard";

import { setBotModule } from "../../../actions";
import PremiumRedirect from "../elements/PremiumRedirect";
import PremiumSection from "../elements/PremiumSection";
import PremiumBadge from "../elements/PremiumBadge";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import ModuleDisabled from "../elements/ModuleDisabled";
import Shop from "../elements/Shop";
import server from "../../../api/server";
import EconomyResetModal from "../elements/EconomyResetModal";

export class Economy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      economy_roles: [
        {
          points: 100,
          roles: [],
          error: ""
        },
      ],
      showModal: null,
      servers: []
    };
  }

  componentDidMount() {
    this.getServers();
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  getServers = async () => {
    const response = await server.post("/dashboard/servers", {
      token: this.props.token,
    });
    if (response.data != false) {
      this.setState({ servers: response.data });
    }
  };

  renderServers = () => {
    var servers = [];

    this.state.servers.forEach((ser, index) => {
      var showModal = false;
      var resetEconomy = async () => {
        const response = await server.post("/dashboard/reseteconomy", {
          token: this.props.token,
          id: ser.id,
        });
        if (response.data) {

        } else {
          this.setState({
            error: "You must be the owner of the server to reset the economy"
          });
        }

      };

      servers.push(
        <div className="col-lg-6 col-md-12 " style={{ padding: "15px" }}>
          {this.state.showModal == ser.id ? <EconomyResetModal closeModal={() => {
            this.setState({ showModal: null });
          }} id={ser.id} /> : null}
          <div className="server-item">
            <div className="server-info">
              <img
                src={
                  ser.icon != null
                    ? `https://cdn.discordapp.com/icons/${ser.id}/${ser.icon}.webp`
                    : process.env.PUBLIC_URL + "/images/icons/discord.png"
                }
              ></img>
              <div>
                <div className="server-name">
                  <span>{ser.name}</span>
                </div>

                {/* <span>The stats of the server.</span> */}
              </div>
            </div>
            <button
              style={{ marginLeft: "auto" }}
              onClick={() => {
                this.setState({ showModal: ser.id });
                // resetEconomy();
              }}
              className="btn btn-red"
            >
              Reset Economy
            </button>
          </div>
        </div>
      );
    });

    return servers;
  };

  roleAddChange = (value, index) => {
    const economy = { ...this.props.economy };
    if (value.length != 0) {
      economy.roles[index].roles = [value[value.length - 1]];
      economy.roles[index].name = value[value.length - 1].name;
      economy.roles[index].id = value[value.length - 1].id;
    } else {
      economy.roles[index].roles = [];
    }

    this.props.setBotModule({
      module: "economy",
      module_data: economy,
    });
  };

  updatePointRequirement = (value, index) => {
    const economy = { ...this.props.economy };
    if (value <= 0) {
      value = 1;
    }
    economy.roles[index].points = value;

    this.props.setBotModule({
      module: "economy",
      module_data: economy,
    });
  };

  moduleEnable = (value) => {
    const economy = { ...this.props.economy };
    economy.enabled = value;

    this.props.setBotModule({
      module: "economy",
      module_data: economy,
    });
  };

  renderEconomyRoles = () => {
    const economy_roles = [];
    this.props.economy.roles.forEach((role, index) => {
      var economy_role = { ...role };
      if (!("roles" in economy_role)) {
        economy_role.roles = [{ name: economy_role.name }];
      }
      economy_roles.push(
        <div key={index} className="economy-roles mb-15">
          <div class="long-input economy-roles-input">
            <label>Points Required</label>
            <input
              onChange={(e) => {
                this.updatePointRequirement(e.target.value, index);
              }}
              value={economy_role.points}
              type="number"
              min={1}
            />
          </div>

          <div className="economy-roles-container">
            <RoleAdd
              items={economy_role.roles}
              option={index}
              update={this.roleAddChange}
              type="role"
            ></RoleAdd>
          </div>

          <div className="economy-roles-delete">
            <FontAwesomeIcon
              icon={faTimesCircle}
              color={"red"}
              size="2x"
              onClick={() => {
                this.deleteRole(index);
              }}
            />
          </div>
        </div>
      );
    });
    return economy_roles;
  };

  deleteRole = (index) => {
    const economy = { ...this.props.economy };

    economy.roles.splice(index, 1);
    this.props.setBotModule({
      module: "economy",
      module_data: economy,
    });
  };

  commandUpdate = (value, type) => {
    var economy = { ...this.props.economy };
    economy[type] = value;
    this.props.setBotModule({
      module: "economy",
      module_data: economy,
    });
  };

  addEconomyRole = () => {
    const economy = { ...this.props.economy };
    economy.roles.push({
      points: 0,
      name: "",
      id: "",
      roles: [],
    });
    this.props.setBotModule({
      module: "economy",
      module_data: economy,
    });
  };

  textChange = (value, field) => {
    var economy = { ...this.props.economy };

    if (field === "points_per_chat" || field === "daily_points") {
      value = parseInt(value);
    }
    economy[field] = value;
    this.props.setBotModule({
      module: "economy",
      module_data: economy,
    });
  };

  render() {
    return (
      <Dashboard>
        <PremiumRedirect></PremiumRedirect>
        <ModuleHeader
          tutorial="https://docs.botghost.com/fun-modules/economy"
          docs="https://docs.botghost.com/fun-modules/economy"
          premiumRequired={false}
          change={this.moduleEnable}
          enabledValue={this.props.economy.enabled}
          description="Set up a fully fledged server economy"
          title="Economy"
          icon="economy.png"
        ></ModuleHeader>
        <ModuleDisabled disabled={!this.props.economy.enabled}>
          <section>
            <SectionHeader title="Settings" pretitle="Economy"></SectionHeader>

            <div className="section-content-normal">
              <div className="mb-15">
                <h3 style={{ marginBottom: "0px" }}>Currency Name</h3>
                <div class="section-content-header">
                  The name of your economy's currency
                </div>
              </div>

              <div class="long-input">
                <label>Currency Name</label>
                <input
                  onChange={(e) => {
                    this.textChange(e.target.value, "currency_name");
                  }}
                  value={this.props.economy.currency_name}
                  type="text"
                />
              </div>
            </div>

            <div className="section-content-normal">
              <div className="mb-15">
                <h3 style={{ marginBottom: "0px" }}>Currency Icon or Prefix</h3>
                <div class="section-content-header">
                  The icon or prefix of your economy, could be a symbol ($) or a
                  discord emote (:dollar:)
                </div>
              </div>

              <div class="long-input">
                <label>Icon/Prefix</label>
                <input
                  onChange={(e) => {
                    this.textChange(e.target.value, "currency_prefix");
                  }}
                  value={this.props.economy.currency_prefix}
                  type="text"
                />
              </div>
            </div>

            <div className="section-content-normal">
              <div className="mb-15">
                <h3 style={{ marginBottom: "0px" }}>Points Per Message</h3>
                <div class="section-content-header">
                  The amount of points or currency allocated to a member for
                  each message
                </div>
              </div>

              <div class="long-input">
                <label>Points</label>
                <input
                  onChange={(e) => {
                    this.textChange(e.target.value, "points_per_chat");
                  }}
                  value={this.props.economy.points_per_chat}
                  type="number"
                />
              </div>
            </div>

            <div className="section-content-normal">
              <div className="mb-15">
                <h3 style={{ marginBottom: "0px" }}>Daily Bonus</h3>
                <div class="section-content-header">
                  Allow users to claim a daily bonus
                </div>
              </div>

              <div class="long-input">
                <label>Bonus</label>
                <input
                  onChange={(e) => {
                    this.textChange(e.target.value, "daily_points");
                  }}
                  value={this.props.economy.daily_points}
                  type="number"
                />
              </div>
            </div>

            <div className="section-content-normal">
              <div className="mb-15">
                <h3 style={{ marginBottom: "0px" }}>
                  Currency Cooldown Time (minutes)
                </h3>
                <div class="section-content-header">
                  The amount of time between allocating points for each message
                  a member posts
                </div>
              </div>

              <div class="long-input">
                <label>Minutes</label>
                <input
                  onChange={(e) => {
                    this.textChange(e.target.value, "points_timeout");
                  }}
                  value={this.props.economy.points_timeout}
                  type="number"
                />
              </div>
            </div>

            <div className="section-content-normal">
              <div class="justify-space-between">
                <div>
                  <h3 style={{ marginBottom: "0px" }}>
                    Economy Roles <PremiumBadge />
                  </h3>
                  <div class="section-content-header mb-15">
                    Automatically promote and demote members based on their
                    currency balance
                  </div>
                </div>

                <div class="" style={{ position: "relative" }}>
                  <button
                    disabled={this.props.premium == false ? true : false}
                    class="btn btn-red"
                    onClick={(e) => {
                      this.addEconomyRole();
                    }}
                  >
                    Add Economy Role
                  </button>
                </div>
              </div>

              <div className="economy-roles-container">
                {this.renderEconomyRoles()}
              </div>
            </div>
          </section>

          <section>
            <SectionHeader title="Shop" pretitle="Economy" />

            <Shop />
          </section>

          <section>
            <SectionHeader title="Commands" pretitle="Economy"></SectionHeader>

            <div className="row">
              <div className="col-lg-6">
                <Command
                  name="give"
                  description="Gives money to a user from your balance"
                  options={this.props.economy.give}
                  module="economy"
                  showPrefix={true}
                  slash={true}
                  slashOnly={true}
                  update={this.commandUpdate}
                  showToggle={true}
                  toggleValue={this.props.economy.give.enabled != false ? true : false}
                ></Command>
              </div>

              <div className="col-lg-6">
                <Command
                  name="add-money"
                  description="An admin only command that adds currency to another users balance"
                  options={this.props.economy["add-money"]}
                  module="economy"
                  showPrefix={true}
                  slash={true}
                  slashOnly={true}
                  update={this.commandUpdate}
                  showToggle={true}
                  toggleValue={this.props.economy['add-money'].enabled != false ? true : false}
                ></Command>
              </div>

              <div className="col-lg-6">
                <Command
                  name="remove-money"
                  options={this.props.economy["remove-money"]}
                  module="economy"
                  showPrefix={true}
                  slash={this.props.beta}
                  slashOnly={true}
                  description="An admin only command that removes currency from another users balance"
                  update={this.commandUpdate}
                  showToggle={true}
                  toggleValue={this.props.economy['remove-money'].enabled != false ? true : false}
                ></Command>
              </div>

              <div className="col-lg-6">
                <Command
                  name="check-balance"
                  options={this.props.economy["check-balance"]}
                  module="economy"
                  showToggle={true}
                  showPrefix={true}
                  slash={this.props.beta}
                  slashOnly={true}
                  description="Displays the currency balance of a user"
                  update={this.commandUpdate}
                  toggleValue={this.props.economy['check-balance'].enabled != false ? true : false}
                ></Command>
              </div>

              <div className="col-lg-6">
                <Command
                  name="daily"
                  options={this.props.economy.daily}
                  module="economy"
                  showToggle={true}
                  showPrefix={true}
                  slash={this.props.beta}
                  slashOnly={true}
                  description="Claims your daily bonus."
                  update={this.commandUpdate}
                  toggleValue={this.props.economy.daily.enabled != false ? true : false}
                ></Command>
              </div>

              <div className="col-lg-6">
                <Command
                  name="leaderboard"
                  options={this.props.economy.leaderboard}
                  module="economy"
                  showToggle={true}
                  showPrefix={true}
                  slash={this.props.beta}
                  slashOnly={true}
                  description="Displays the server leader board"
                  update={this.commandUpdate}
                  toggleValue={this.props.economy.leaderboard.enabled != false ? true : false}
                ></Command>
              </div>

              <div className="col-lg-6">
                <Command
                  name="econadmin"
                  options={this.props.economy.econadmin}
                  module="economy"
                  showPrefix={true}
                  slash={this.props.beta}
                  slashOnly={true}
                  extraDescriptions={[
                    // {key:"description_main",name:"Main Command"},
                    { key: "description_additem", name: "Add Item Subcommand" },
                    {
                      key: "description_removeitem",
                      name: "Remove Item Subcommand",
                    },
                  ]}
                  description="Admin commands for the Economy shop"
                  update={this.commandUpdate}
                  showToggle={true}
                  toggleValue={this.props.economy.econadmin.enabled != false ? true : false}
                ></Command>
              </div>

              <div className="col-lg-6">
                <Command
                  name="giveitem"
                  options={this.props.economy.giveitem}
                  module="economy"
                  showPrefix={true}
                  slash={this.props.beta}
                  slashOnly={true}
                  description="Give an item from your inventory to someone else"
                  update={this.commandUpdate}
                  showToggle={true}
                  toggleValue={this.props.economy.giveitem.enabled != false ? true : false}
                ></Command>
              </div>

              <div className="col-lg-6">
                <Command
                  name="use"
                  options={this.props.economy.use}
                  module="economy"
                  showPrefix={true}
                  slash={this.props.beta}
                  slashOnly={true}
                  description="Use an item from your inventory"
                  update={this.commandUpdate}
                  showToggle={true}
                  toggleValue={this.props.economy.use.enabled != false ? true : false}
                ></Command>
              </div>

              <div className="col-lg-6">
                <Command
                  name="buy"
                  options={this.props.economy.buy}
                  module="economy"
                  showPrefix={true}
                  slash={this.props.beta}
                  slashOnly={true}
                  description="Buy an item from the shop"
                  update={this.commandUpdate}
                  showToggle={true}
                  toggleValue={this.props.economy.buy.enabled != false ? true : false}
                ></Command>
              </div>

              <div className="col-lg-6">
                <Command
                  name="viewshop"
                  defaultTrigger="shop"
                  options={this.props.economy.viewshop}
                  module="economy"
                  showPrefix={true}
                  slash={this.props.beta}
                  slashOnly={true}
                  extraDescriptions={[
                    // {key:"description_main",name:"Main Command"},
                    { key: "description_item", name: "Item Subcommand" },
                    { key: "description_view", name: "View Subcommand" },
                  ]}
                  description="View the shop or an item from the shop"
                  update={this.commandUpdate}
                  showToggle={true}
                  toggleValue={this.props.economy.viewshop.enabled != false ? true : false}
                ></Command>
              </div>

              <div className="col-lg-6">
                <Command
                  name="inventory"
                  options={this.props.economy.inventory}
                  module="economy"
                  showPrefix={true}
                  slash={this.props.beta}
                  slashOnly={true}
                  description="View your inventory or an item in your inventory"
                  update={this.commandUpdate}
                  showToggle={true}
                  toggleValue={this.props.economy.inventory.enabled != false ? true : false}
                ></Command>
              </div>

              {/* 
                        <div className="col-lg-6">
                            <Command name="slotmachine"
                                options={this.props.economy.slotmachine}
                                module="economy"
                                showToggle={false}
                                showPrefix={true} 
                                slash={true}
                            description="Test your luck against the slot machine."></Command>
                        </div> */}
            </div>
          </section>

          <section>
            <SectionHeader title="Servers" pretitle="Fun"></SectionHeader>

            <div className="server-list">
              <span style={{ color: "#f45142" }}>{this.state.error}</span>
              <div className="row">{this.renderServers()}</div>
            </div>
          </section>
        </ModuleDisabled>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  economy: state.data.bot.commands.economy,
  premium: state.data.premium,
  beta: state.data.beta,
  token: state.data.bot.token,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Economy);
