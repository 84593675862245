import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';

import TextArea from '../../elements/TextArea';
import VariableTextInput from '../VariableTextInput';


export class ErrorLogAction extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "send_error_message",
                        module: "",
                        action: "",
                        reason: "",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "send_error_message",
                        module: "",
                        action: "",
                        reason: "",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

            if ((this.props.data.module != "") && this.props.data.validated != true) {
                this.updateData("validated", true);
            } else if (this.props.data.validated == true && (this.props.data.module == "")) {
                this.updateData("validated", false);

            }
        }
    }

    updateData = (key, value) => {

        // console.log("VALUE", value);
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };


    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Send an error log message</h4>
                        <p>Trigger an error message. This message will either be handled by the error handler or sent to the bot's error logs.</p>
                    </div>

                    <h4 style={{ marginBottom: "0px" }}>Module</h4>
                    <div className="section-content-header mb-15">The module field of the error message</div>
                    {/* <VariableTextInput slash_options={this.props.options} value={this.props.data.response_options.target.user_option} onChange={(value) => {
                                var response_options = { ...this.props.data.response_options };
                                response_options.target.user_option = value;
                                this.updateData("response_options", response_options);
                            }} /> */}

                    <VariableTextInput required={true} slash_options={this.props.options} label="Module" placeholder="Module" value={this.props.data.module} onChange={(value) => {
                        this.updateData("module", value);
                    }} />

                    {/* <div class="long-input mb-15">
                        <label>Module</label>
                        <input className={`${this.props.data.module == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                            // if (!isNaN(e.target.value) && e.target.value <= 850) {
                            this.updateData("module", e.target.value);
                            // }
                        }} type="text" value={this.props.data.module} />
                    </div> */}

                    <h4 className='mt-15' style={{ marginBottom: "0px" }}>Action</h4>
                    <div className="section-content-header mb-15">A description of the action the bot failed to complete</div>

                    <VariableTextInput required={true} slash_options={this.props.options} label="Action" placeholder="Action" value={this.props.data.action} onChange={(value) => {
                        this.updateData("action", value);
                    }} />
                    {/* <div class="long-input mb-15">
                        <label>Action</label>
                        <input className={`${this.props.data.action == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                            // if (!isNaN(e.target.value) && e.target.value <= 850) {
                            this.updateData("action", e.target.value);
                            // }
                        }} type="text" value={this.props.data.action} />
                    </div> */}

                    <h4 className='mt-15' style={{ marginBottom: "0px" }}>Reason</h4>
                    <div className="section-content-header mb-15">A descriptive reason why the action failed</div>
                    <VariableTextInput required={true} slash_options={this.props.options} label="Reason" placeholder="Reason" value={this.props.data.reason} onChange={(value) => {
                        this.updateData("reason", value);
                    }} />
                    {/* <div class="long-input mb-15">
                        <label>Reason</label>
                        <input className={`${this.props.data.reason == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                            // if (!isNaN(e.target.value) && e.target.value <= 850) {
                            this.updateData("reason", e.target.value);
                            // }
                        }} type="text" value={this.props.data.reason} />
                    </div> */}


                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorLogAction);
