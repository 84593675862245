import React, { Component } from "react";
import { connect } from "react-redux";
import server from "../../../api/server";
import history from "../../../history";
import { setAuth } from "../../../actions";
import { Helmet } from "react-helmet";

export class CollabInvite extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
			bot: {
				img: "https://cdn.discordapp.com/avatars/1029849529299779634/0ec6014d14fd47bcdf8f0293f2497992.png?size=1024",
				name: "Tomtoise bot",
				id: "12324125232232"
			},
			invite: {}
		};
	}

	componentDidMount() {
		if (this.props.auth == false) {

			this.props.setAuth();
		} else {
			this.fetchInvite();
		}

	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.auth == true && this.state.loaded == false) {
			this.fetchInvite();
		}
	}
	fetchInvite = async () => {
		var response = await server.get("/collab/invite/" + this.props.match.params.id).catch((e) => {
			// Redirect to Dashboard
			history.push("/dashboard");
		});

		if (response.status === 200) {
			this.setState({
				loaded: true,
				bot: response.data.bot,
				invite: response.data.invite
			});
		}
	};

	acceptInvite = async () => {
		// this.setState({ loaded: false });

		var response = await server
			.post("/collab/invite/accept", {
				invite: this.state.invite.invite,
				bot_id: this.state.bot.id
			})
			.catch((e) => {
				// Redirect to Dashboard
				history.push("/dashboard");
			});

		if (response.status === 200) {
			this.setState({ loaded: true });
			history.push("/dashboard");
		}
	};
	render() {
		if (this.state.loaded) {
			return (
				<>
					<Helmet>
						<meta name="viewport" content="width=device-width, initial-scale=1.0" />
					</Helmet>
					<div className="h-screen w-screen px-2 md:!px-[25%] py-10 flex justify-center items-center">
						<div className="bg-gray px-3 py-5 gap-y-3  w-full rounded-md text-white">
							<div className="text-center text-white font-bold text-xl">
								<h1 className="text-xl">Collaboration Invite</h1>
								<p className="text-muted text-sm">You have been invited to collaborate on {this.state.bot.name}.</p>
							</div>

							<div className="grid grid-cols-1 mt-3 md:mt-0 px-5 justify-center">
								<div className="group block flex-shrink-0">
									<div className="flex flex-col justify-center items-center">
										<div>
											<img
												onError={({ currentTarget }) => {
													currentTarget.onerror = null; // prevents looping
													currentTarget.src = "https://botghost-cdn.s3.us-east-2.amazonaws.com/unknown_pfp.png";
												}}
												className="inline-block h-24 w-24 rounded-full" src={this.state.bot.img ? this.state.bot.img : "https://botghost-cdn.s3.us-east-2.amazonaws.com/unknown_pfp.png"} alt=""
											/>
										</div>
										<div className="ml-0 md:ml-3 text-center">
											<p className="text-xl font-bold text-white">{this.state.bot.name}</p>
											<p className="text-sm font-medium text-muted">{this.state.bot.id}</p>
										</div>
									</div>
								</div>

								<div className="flex justify-center items-center">
									<button
										className="btn btn-red ml-0 mt-3 w-[150px] md:ml-auto"
										onClick={() => {
											this.acceptInvite();
										}}
									>
										Accept
									</button>
									{/* <button className="btn btn-green bg-green text-white">Accept</button> */}
								</div>
							</div>
						</div>
					</div>
				</>
			);
		} else {
			return (
				<div className="h-screen w-screen flex justify-center items-center">
					{/* Tailwind loader */}

					<div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-red"></div>
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	loading: state.data.loading,
});

const mapDispatchToProps = {
	setAuth
};

export default connect(mapStateToProps, mapDispatchToProps)(CollabInvite);
