import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const OPTION_TYPES = [
    { value: "string", label: "Text" },
    { value: "int", label: "Number" },
    { value: "boolean", label: "Choice" },
    { value: "user", label: "User" },
    { value: "channel", label: "Channel" },
    { value: "role", label: "Role" },
    { value: "attachment", label: "Attachment" },
];



export class Option extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: OPTION_TYPES.find(ele => ele.label == this.props.node_options.title).value,
                        name: "",
                        description: "",
                        required: false,
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: OPTION_TYPES.find(ele => ele.label == this.props.node_options.title).value,
                        name: "",
                        description: "",
                        required: false,
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

            if ((this.props.data.name != "" && this.props.data.description != "") && this.checkExistingOption() == true && this.validateChoices() == true) {
                if (this.props.data.validated == false) {
                    this.updateData("validated", true);
                }
            } else if (this.props.data.validated == true) {
                this.updateData("validated", false);

            }
        }
    }

    validateChoices = () => {
        var passed = true;
        if (this.props.data.choices != undefined) {
            this.props.data.choices.forEach(choice => {
                if (choice == "") {
                    passed = false;
                }
            });
        }
        return passed;
    };

    nameChange = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "-");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("name", value);
    };

    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    checkExistingOption = () => {
        var passed = true;
        var options = this.props.elements.filter(element => element.type == "option");
        // console.log(options, "options");
        options.forEach(option => {
            if (option.id != this.props.id && option.data.data.name == this.props.data.name && this.props.name != "") {
                passed = false;
            }
        });
        // console.log(passed, "PASSED");
        return passed;
    };

    renderChoices = () => {
        if (this.props.data.choices == undefined) {
            return null;
        }

        var returnChoices = [];


        this.props.data.choices.forEach((choice, index) => {

            const deleteChoice = () => {
                var choices = [...this.props.data.choices];
                choices.splice(index, 1);
                this.updateData("choices", choices);
            };

            const editChoice = (value) => {
                var choices = [...this.props.data.choices];
                value = value.substring(0, 99);
                choices[index] = value;
                this.updateData("choices", choices);

            };
            returnChoices.push(
                <div className="mb-15" style={{ display: "flex", alignItems: "center" }}>
                    <div className="long-input" style={{ width: "100%" }}>
                        <label>{`Choice ${index + 1}`}</label>
                        <input value={choice} required={true} type={this.props.title == "Text" ? "text" : "number"} onChange={(e) => { editChoice(e.target.value); }}></input>
                    </div>
                    <FontAwesomeIcon onClick={() => { deleteChoice(); }} className="delete-icon" icon={faTimesCircle} />
                </div>
            );
        });
        return returnChoices;

    };

    addChoice = () => {
        var choices = [];
        // console.log(this.props.data.choices, "CHOICES");
        if (this.props.data.choices != undefined) {
            choices = [...this.props.data.choices];
        }
        if (choices.length <= 24) {
            choices.push("");
            this.updateData("choices", choices);
        }

    };


    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>{this.props.title} Option</h4>
                        <p>{this.props.description}. Use the variable <span style={{ color: "rgb(141, 14, 171)" }}>{`{option_${this.props.data.name}}`}</span><FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{option_${this.props.data.name}}`); toast.success("Copied variable to clipboard"); }} /> in your responses to reference this option.</p>
                    </div>
                    <div className="mb-15">
                        <h4>Option Name <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/options/text-option"}></DocsLink></h4>
                        <p>A descriptive name of the option</p>
                        <div className="long-input">
                            <label>Name</label>
                            <input className={`${this.props.data.name == "" && this.props.saveFailed ? "command-required" : ""}`} value={this.props.data.name} required={true} type="text" onChange={(e) => { this.nameChange(e.target.value); }}></input>
                        </div>

                        <p style={{ color: "red" }}>{this.checkExistingOption() == false ? "An option with this name already exists. Please change the name of one of the options." : null}</p>
                    </div>

                    <div className="mb-15">
                        <h4>Option Description <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/options/text-option"}></DocsLink></h4>
                        <p>A short description of what the option is</p>
                        <div className="long-input">
                            <label>Description</label>
                            <input maxLength={100} className={`${this.props.data.description == "" && this.props.saveFailed ? "command-required" : ""}`} value={this.props.data.description} required={true} type="text" onChange={(e) => { this.updateData("description", e.target.value); }}></input>
                        </div>
                    </div>

                    <div className="mb-15">
                        <h4>Set Required <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/options/text-option"}></DocsLink></h4>
                        <p>Whether or not a response is required</p>
                        <div className="mt-15">
                            <Toggle value={this.props.data.required} required={true} update={(value) => { this.updateData("required", value); }}></Toggle>
                        </div>
                    </div>

                    {(this.props.title == "Text" || this.props.title == "Number") ?
                        <div>
                            <hr style={{ borderTop: "1px solid #7878786b" }}></hr>

                            <div className="mb-15">
                                <h4>Add Choices {this.props.data.choices != undefined && this.props.data.choices.length > 0 ? this.props.data.choices.length : "0"}/25</h4>
                                <p>Set choices for your user to select from. If you specify choices for an option, they'll be the only valid values users can pick from! </p>
                            </div>

                            {this.renderChoices()}
                            <button onClick={() => { this.addChoice(); }} className="btn btn-red command-builder-save" style={{ width: "100%" }}>Add Choice</button>

                        </div>
                        : null}

                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    beta: state.data.beta,
    saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(Option);
