import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData, setHighlightAction, setElements } from "../../../../actions";

import TextArea from "../../elements/TextArea";
import { EmojiAdder } from "./EmojiAdder";
import Select from "../../elements/Select";
import { addEdge, getIncomers, getOutgoers } from "react-flow-renderer";
import splitElements from "../splitElements";
import { checkIntegrationChild } from "../eventUtils";
import { CUSTOM_EVENTS } from "../../../../variables";
import { renderResponseActionOptions } from "../builderUtils";
import MessageBuilder from "../../elements/MessageBuilder";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle, faClipboard } from "@fortawesome/free-solid-svg-icons";
import VariableTextInput from "../VariableTextInput";
import { successToast } from "../toast";
import DiscordEmbed from "../../elements/DiscordEmbed";

const responseTypes = [
	{ value: "reply", label: "Reply to the command", disabled: true },
	{ value: "trigger_channel", label: "Send the message to the channel the command was used in." }
];

class AdvancedReply extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openBuilder: false,
			messageValidated: false,
			embedValidated: true,
			embedErrorIndex: null,
			errorMessages: [],
			lastValidatedData: null
		};
	}

	componentDidMount() {
		if (this.props.data == undefined) {
			// this.addElements();
			var target = {};
			if (this.props.mode == "event" && this.props.components["Channel"]) {
				target = { trigger_channel: true };
			} else if (this.props.components["Interaction"]) {
				target = { reply: true };
			}

			this.props.updateElementData({
				data: {
					type: "advanced_message",
					message: {
						content: "",
						embeds: []
					},
					messageType: "basic",
					components: [],
					target: target,
					validated: false
				},
				id: this.props.id
			});
		} else if (this.props.data.target == undefined) {
			var target = {};
			if (this.props.mode == "event" && this.props.components["Channel"]) {
				target = { trigger_channel: true };
			} else if (this.props.components["Interaction"]) {
				target = { reply: true };
			}

			var target = { ...this.props.data.target };
			target = target;
			this.updateData("target", target);
		}

		// Highlight corrospodning action
		if (this.props.data && this.props.data.action_id && this.props.data.target && this.props.data.target.editId) {
			this.props.setHighlightAction(this.props.data.action_id);
		}

		this.validateMessage();
	}

	componentDidUpdate(prevProps) {
		if (this.props.data !== prevProps.data) {
			this.validateMessage();
		}
	}

	validateMessage = () => {
		console.log("Validate Message");
		if (!this.props.data) {
			return;
		}

		const isContentValid = this.props.data.message.content.trim() !== '';
		const hasEmbeds = this.props.data.message.embeds && this.props.data.message.embeds.length > 0;
		const areAllEmbedsValid = hasEmbeds && this.validateEmbeds(this.props.data.message.embeds);
		const hasComponentChildren = this.checkForComponentChildren();

		console.log(hasEmbeds, "HAS EMBEDS");
		console.log(areAllEmbedsValid, "ARE ALL EMBEDS VALID");
		console.log(hasComponentChildren, "HAS COMPONENT CHILDREN");

		// Message is valid if it has content, or all embeds are valid, or it has component children
		var isMessageValid = isContentValid || (hasEmbeds ? areAllEmbedsValid : false) || hasComponentChildren;
		if (hasEmbeds && !areAllEmbedsValid) {
			isMessageValid = false;
		}
		console.log(isMessageValid, 'IS MESSAGE VALID');
		const isTargetValid = this.validateTarget(this.props.data.target);

		let errorMessages = [];
		if (!isMessageValid) {
			errorMessages.push('Your message must have either content, valid embeds, or components.');
		}
		if (hasEmbeds && !areAllEmbedsValid) {
			const embedErrors = this.getEmbedErrors(this.props.data.message.embeds);
			errorMessages = [...errorMessages, ...embedErrors];
		}

		const isOverallValid = isMessageValid && isTargetValid;

		// Only update state if there's a change to avoid unnecessary re-renders
		if (this.state.messageValidated !== isMessageValid ||
			this.state.errorMessages !== errorMessages ||
			this.props.data.validated !== isOverallValid) {

			this.setState({
				messageValidated: isMessageValid,
				errorMessages,
				lastValidatedData: this.props.data
			}, () => {
				// Only update props if the overall validity has changed
				if (isOverallValid !== this.props.data.validated) {
					this.props.updateElementData({
						data: { ...this.props.data, validated: isOverallValid },
						id: this.props.id
					});
				}
			});
		}
	};

	validateEmbeds = (embeds) => {
		// All embeds must be valid
		return embeds.every(embed => this.validateEmbed(embed));
	};

	validateEmbed = (embed) => {
		// Check if at least one of description, title, or author name is present
		if (!embed.description && !embed.title && !embed?.author?.name) {
			return false;
		}

		// Validate fields
		if (embed.fields) {
			const fieldsValid = embed.fields.every(field => field.name && field.value);
			if (!fieldsValid) return false;
		}

		// Validate author
		if ((embed.author?.url || embed.author?.icon_url) && !embed.author?.name) {
			return false;
		}

		// Validate footer
		if (embed.footer?.icon_url && !embed.footer?.text) {
			return false;
		}

		// Add any additional embed validations here

		return true;
	};

	getEmbedErrors = (embeds) => {
		let errors = [];
		embeds.forEach((embed, index) => {
			if (!this.validateEmbed(embed)) {
				if (!embed.description && !embed.title && !embed?.author?.name) {
					errors.push(`Embed ${index + 1}: At least one of Description, Title, or Author name is required.`);
				}
				if (embed.fields && !embed.fields.every(field => field.name && field.value)) {
					errors.push(`Embed ${index + 1}: Each field requires a name and value.`);
				}
				if ((embed.author?.url || embed.author?.icon_url) && !embed.author?.name) {
					errors.push(`Embed ${index + 1}: Author name is required when author URL or icon is set.`);
				}
				if (embed.footer?.icon_url && !embed.footer?.text) {
					errors.push(`Embed ${index + 1}: Footer text is required when footer icon is set.`);
				}
			}
		});
		return errors;
	};

	validateTarget = (target) => {
		if ('id' in target) {
			return target.id.trim() !== '';
		} else if ('channel_option' in target) {
			return target.channel_option.trim() !== '';
		} else if ('dm_option' in target) {
			return target.dm_option.trim() !== '';
		} else if ('dm_user_id' in target) {
			return target.dm_user_id.trim() !== '';
		} else if ('reply_message' in target) {
			return target.channel_id.trim() !== '' && target.message_id.trim() !== '';
		} else if ('editId' in target) {
			return target.editId.trim() !== '';
		} else if ('reply' in target || 'trigger_channel' in target || 'dm_command' in target) {
			return true; // These options don't require additional validation
		} else if ('editViaId' in target) {
			return target.channel_id.trim() !== '' && target.message_id.trim() !== '';
		}
		return false; // Invalid target type
	};

	checkForComponentChildren = () => {
		var elements = [...this.props.elements];
		var element = this.props.elements.find((element) => element.id == this.props.selected);

		var children = getOutgoers(element, splitElements(elements).nodes, splitElements(elements).edges);
		return children.some(child => child.type === 'advMessageButton' || child.type === 'advMessageSelectMenu');
	};

	nameChange = (value) => {
		value = value.toLowerCase();
		value = value.substring(0, 32);
		value = value.replace(" ", "-");
		const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/gu);
		value = value.replace(regexForNonAlphaNum, "");
		this.updateData("name", value);
	};

	updateData = (key, value) => {
		// console.log("VALUE", value);
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	renderResponseInputs = () => {
		if ("id" in this.props.data.target) {
			return (
				<>
					<div>
						<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>
						<h4 style={{ marginBottom: "0px" }}>Channel Id</h4>
						<p className="mb-15 block">The id of a text channel.</p>
						<VariableTextInput
							label={"Channel Id"}
							placeholder="911634771019444224"
							slash_options={this.props.options}
							value={this.props.data.target.id}
							onChange={(value) => {
								this.updateData("target", { id: value });
							}}
						/>
					</div>
				</>
			);
		} else if ("channel_option" in this.props.data.target) {
			return (
				<>
					<div>
						<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>
						<h4 style={{ marginBottom: "0px" }}>Channel Option</h4>
						<p className="mb-15 block">
							The <span className="!text-[#8d0eab] font-bold">channel option</span> of a text channel.
						</p>
						<VariableTextInput
							label={"Channel Option"}
							options={this.props.options}
							value={this.props.data.target.channel_option}
							placeholder={"{option_channel}"}
							onChange={(value) => {
								this.updateData("target", { channel_option: value });
							}}
						/>
					</div>
				</>
			);
		} else if ("dm_option" in this.props.data.target) {
			return (
				<>
					<div>
						<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>
						<h4 style={{ marginBottom: "0px" }}>DM User Option</h4>
						<p className="mb-15 block">
							The <span className="!text-[#8d0eab] font-bold">user option</span> of a server member.
						</p>
						<VariableTextInput
							label={"DM Option"}
							options={this.props.options}
							value={this.props.data.target.dm_option}
							placeholder={"{option_user}"}
							onChange={(value) => {
								this.updateData("target", { dm_option: value });
							}}
						/>
					</div>
				</>
			);
		} else if ("dm_user_id" in this.props.data.target) {
			return (
				<>
					<div>
						<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>
						<h4 style={{ marginBottom: "0px" }}>DM User Id</h4>
						<p className="mb-15 block">The id of a server member.</p>
						<VariableTextInput
							label={"DM User Id"}
							placeholder="911634771019444224"
							slash_options={this.props.options}
							value={this.props.data.target.dm_user_id}
							onChange={(value) => {
								this.updateData("target", { dm_user_id: value });
							}}
						/>
					</div>
				</>
			);
		} else if ("editId" in this.props.data.target) {
			return (
				<>
					<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>

					<div>
						<h4 style={{ marginBottom: "0px" }}>
							Response Action<DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/plain-text-reply#edit-a-message-sent-by-another-action"}></DocsLink>
						</h4>
						<div className="section-content-header mb-15">Select one of the response actions in this command to delete the message sent by that action.</div>
						<Select saveFailed={this.props.data.action_id == "" && this.props.saveFailed} value={this.props.data.action_id} options={renderResponseActionOptions(this.props.actions, this.props.selected)} onChange={this.selectChange} />
					</div>

					<div className="mt-15">
						<h4 style={{ marginBottom: "0px" }}>Keep Components</h4>
						<div className="section-content-header mb-15">Keep the buttons or select menu options of the original message.</div>
						<Select
							saveFailed={this.props.data.action_id == "" && this.props.saveFailed}
							value={this.props.data.target.keep_components == true ? true : false}
							options={[
								{
									value: true,
									label: "Yes"
								},
								{
									value: false,
									label: "No"
								}
							]}
							onChange={(val) => {
								if (val == "true") {
									val = true;
								} else {
									val = false;
								}

								var data = { ...this.props.data };
								data.target.keep_components = val;
								this.props.updateElementData({
									data: data,
									id: this.props.id
								});
							}}
						/>
					</div>
					<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>
				</>
			);
		} else if ("reply_message" in this.props.data.target) {
			return (
				<>
					<div>
						<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>
						<h4 style={{ marginBottom: "0px" }}>Channel Id</h4>
						<p className="mb-15 block">The text channel the message is in</p>
						<VariableTextInput
							label={"Channel Id"}
							placeholder="911634771019444224"
							slash_options={this.props.options}
							value={this.props.data.target.channel_id}
							onChange={(value) => {
								var target = { ...this.props.data.target };
								target.channel_id = value;
								this.updateData("target", target);
							}}
						/>

						<h4 className="mt-2" style={{ marginBottom: "0px" }}>Message Id</h4>
						<p className="mb-15 block">The id of the message to reply to</p>
						<VariableTextInput
							label={"Message Id"}
							placeholder="911634771019444224"
							slash_options={this.props.options}
							value={this.props.data.target.message_id}
							onChange={(value) => {
								var target = { ...this.props.data.target };
								target.message_id = value;
								this.updateData("target", target);
							}}
						/>
					</div>
				</>
			);
		} else if ("editViaId" in this.props.data.target) {
			// Need a Channel Id input && a Message Id input

			return (
				<>
					<div>
						<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>
						<h4 style={{ marginBottom: "0px" }}>Channel Id</h4>
						<p className="mb-15 block">The text channel the message is in</p>
						<VariableTextInput
							label={"Channel Id"}
							placeholder="911634771019444224"
							slash_options={this.props.options}
							value={this.props.data.target.channel_id}
							onChange={(value) => {
								var target = { ...this.props.data.target };
								target.channel_id = value;
								this.updateData("target", target);
							}}
						/>

						<h4 className="mt-2" style={{ marginBottom: "0px" }}>
							Message Id
						</h4>
						<p className="mb-15 block">The id of the message to edit</p>
						<VariableTextInput
							label={"Message Id"}
							placeholder="911634771019444224"
							slash_options={this.props.options}
							value={this.props.data.target.message_id}
							onChange={(value) => {
								var target = { ...this.props.data.target };
								target.message_id = value;
								this.updateData("target", target);
							}}
						/>

						<div className="mt-15">
							<h4 style={{ marginBottom: "0px" }}>Keep Components</h4>
							<div className="section-content-header mb-15">Keep the buttons or select menu options of the original message.</div>
							<Select
								saveFailed={this.props.data.action_id == "" && this.props.saveFailed}
								value={this.props.data.target.keep_components == true ? true : false}
								options={[
									{
										value: true,
										label: "Yes"
									},
									{
										value: false,
										label: "No"
									}
								]}
								onChange={(val) => {
									if (val == "true") {
										val = true;
									} else {
										val = false;
									}

									var data = { ...this.props.data };
									data.target.keep_components = val;
									this.props.updateElementData({
										data: data,
										id: this.props.id
									});
								}}
							/>
						</div>
					</div>
				</>
			);
		}
	};

	renderResponseTypes = () => {
		var channel = "channel the command was used in.";
		if (this.props.mode == "event" && this.props.components["Channel"]) {
			channel = "channel.";
		}

		var response_cats = {
			reply: {
				options: [],
				label: "Reply"
			},
			specific: {
				options: [],
				label: "Specific"
			},
			dm: {
				options: [],
				label: "Direct Message"
			},
			edit: {
				options: [],
				label: "Edit"
			}
		};

		const responseTypes = [
			// { value: "reply", label: "Reply to the command", disabled: this.props.components.Interaction ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			// { value: "trigger_channel", label: `Send the message to the ${channel}`, disabled: this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
		];
		if (this.props.components.Interaction) {
			response_cats.reply.options.push({ value: "reply", label: "Reply to the command" });
		}

		// Reply to a specific message;
		response_cats.reply.options.push({ value: "reply_message", label: "Reply to a specific message" });

		if (this.props.components.Channel && this.props.mode == "event") {
			response_cats.reply.options.push({ value: "trigger_channel", label: `Send the message to the ${channel}` });
		} else if (this.props.components.Interaction) {
			response_cats.reply.options.push({ value: "trigger_channel", label: `Send the message to the channel the command was used in` });
		}

		// Send to Channel ID
		response_cats.specific.options.push({ value: "id", label: `Send the message to a specific channel` });

		// Channel Option
		if (this.props.components.Interaction) {
			response_cats.specific.options.push({ value: "channel_option", label: `Send the message to a channel option` });
		}

		// Edit another action
		response_cats.edit.options.push({ value: "editId", label: "Edit a message sent by another action" });

		// Edit via ID
		response_cats.edit.options.push({ value: "editViaId", label: "Edit a specific message" });

		// DM command User
		if (this.props.components.Interaction) {
			response_cats.dm.options.push({ value: "dm_command", label: "Direct message the user who used the command" });
			response_cats.dm.options.push({ value: "dm_option", label: "Direct message a user option" });
		}

		// DM User

		response_cats.dm.options.push({ value: "dm_user_id", label: "Direct message a specific user" });

		if (this.props.mode == "event") {
			responseTypes.unshift({ value: "", label: "", disabled: true });
		}

		var option_groups = [];
		for (var key in response_cats) {
			if (response_cats[key].options.length > 0) {
				option_groups.push(
					<optgroup className="text-muted" label={response_cats[key].label}>
						{response_cats[key].options.map((option) => {
							return (
								<option className="text-white" value={option.value} disabled={option.disabled}>
									{option.label}
								</option>
							);
						})}
					</optgroup>
				);
			}
		}

		return (
			<select
				value={this.renderSelectValue()}
				onChange={(e) => {
					var target = {};
					if (e.target.value == "id") {
						target = { id: "" };
					} else if (e.target.value == "channel_option") {
						target = { channel_option: "" };
					} else if (e.target.value == "editId") {
						target = { editId: "" };
					} else if (e.target.value == "editViaId") {
						target = { editViaId: true, channel_id: "", message_id: "" };
					} else if (e.target.value == "dm_command") {
						target = { dm_command: true };
					} else if (e.target.value == "dm_option") {
						target = { dm_option: "" };
					} else if (e.target.value == "dm_user_id") {
						target = { dm_user_id: "" };
					} else if (e.target.value == "reply") {
						target = { reply: true };
					} else if (e.target.value == "trigger_channel") {
						target = { trigger_channel: true };
					} else if (e.target.value == "reply_message") {
						target = { reply_message: true, channel_id: "", message_id: "" };
					} else {
						target = {};
					}

					this.updateData("target", target);
				}}
			>
				{option_groups}
			</select>
		);
		// return <select>
		//     {response_cats.reply.options.length > 0 ? <optgroup label={response_cats.reply.label}>
		//         {response_cats.reply.options.map(option => {
		//             return <option value={option.value} disabled={option.disabled}>{option.label}</option>;
		//         }
		//         )}
		//     </optgroup> : null}
		// </select>;
	};

	renderSelectValue = () => {
		console.log(this.props.data.target);
		if (this.props.data.target.reply) {
			return "reply";
		} else if ("id" in this.props.data.target) {
			return "id";
		} else if ("channel_option" in this.props.data.target) {
			return "channel_option";
		} else if ("editId" in this.props.data.target) {
			return "editId";
		} else if ("editViaId" in this.props.data.target) {
			return "editViaId";
		} else if ("dm_command" in this.props.data.target) {
			return "dm_command";
		} else if ("dm_option" in this.props.data.target) {
			return "dm_option";
		} else if ("dm_user_id" in this.props.data.target) {
			return "dm_user_id";
		} else if (this.props.data.target.trigger_channel) {
			return "trigger_channel";
		} else if ("reply_message" in this.props.data.target) {
			return "reply_message";
		} else {
			return "";
		}
	};

	renderTextAreaDisabled = () => {
		var disabled = true;
		if (!this.props.data.target || (this.props.data.target.reply == true && (this.props.components.Interaction || checkIntegrationChild(this.props.elements, this.props.id)))) {
			console.log("DISABLE 1");
			disabled = false;
		} else if (this.props.data.target.trigger_channel == true && (this.props.components.Channel || checkIntegrationChild(this.props.elements, this.props.id))) {
			console.log("DISABLE 2");
			disabled = false;
		} else if (this.props.data.target.editId) {
			disabled = false;
		}
		console.log("DISABLE 3", disabled, this.props.data.target, this.props.components["Interaction"]);
		return disabled;
	};

	selectChange = (value) => {
		if (!value) return;
		this.props.setHighlightAction(value);
		var action = this.props.actions.find((action) => action.id == value);

		if (!("editId" in action.data.data)) {
			var data = { ...action.data.data };
			data.editId = `${Date.now()}_${Math.floor(Math.random() * 100000)}`;
			console.log("DATA", data);
			this.props.updateElementData({
				data: data,
				id: value
			});
			action.data.data.editId = data.editId;
		}
		var id = action.data.data.editId;

		var action_data = { ...this.props.data };
		action_data.target.editId = id;
		action_data.action_id = value;
		this.props.updateElementData({
			data: action_data,
			id: this.props.id
		});
	};

	render() {
		if (this.props.data != undefined) {
			return (
				<>
					<Transition.Root show={this.state.openBuilder} as={Fragment}>
						<Dialog
							as="div"
							className="relative z-10"
							onClose={() => {
								this.setState({ openBuilder: false });
							}}
						>
							<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
								<div className="fixed inset-0 bg-[#0000008a] transition-opacity" />
							</Transition.Child>

							<div className="fixed w-screen inset-0 z-10 overflow-y-auto">
								<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
									<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-0 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
										<Dialog.Panel className="w-screen min-h-[90vh] relative transform  rounded-lg bg-darkGray px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8  sm:p-6">
											<MessageBuilder
												closeModal={() => {
													this.setState({ openBuilder: false });
												}}


												updateMessage={(message) => {
													console.log("UPDATE MESSAGE");
													this.updateData("message", message);
													this.validateMessage(message);
												}}
												message={this.props.data.message}
											/>
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</Dialog>
					</Transition.Root>

					<div>
						<div className="mb-15">
							<h4>Send or Edit a Message</h4>
							<p>Send or Edit a message with optional buttons and select menus.</p>
						</div>
						<h4 style={{ marginBottom: "0px" }}>Message Builder {!this.state.messageValidated ? <FontAwesomeIcon icon={faExclamationCircle} color="#f45142" /> : <FontAwesomeIcon icon={faCheckCircle} color="green" />}</h4>
						<div className="section-content-header mb-15">Add Messages to this response. If you add more than one message, a message will be chosen at random to send.</div>
						<button
							className="btn btn-red"
							onClick={() => {
								this.setState({ openBuilder: true });
							}}
						>
							Message Builder
						</button>

						{/* Display error messages */}
						{this.state.errorMessages.length > 0 && (
							<div className="text-red font-semibold mt-2">
								{this.state.errorMessages.map((error, index) => (
									<div key={index}>{error}</div>
								))}
							</div>
						)}

						{this.props.data.target ? (
							<div>
								<div className="mt-15 mb-15 slash-action">
									<h4 style={{ marginBottom: "0px" }}>
										Response type <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/plain-text-reply#response-type"}></DocsLink>{" "}
									</h4>
									<div className="section-content-header mb-15">Where your bot should send this message.</div>
									{this.renderResponseTypes()}
									{/* <Select onChange={(value) => {
                                    var target = { ...this.props.data.target };
                                    if (value == "reply") {
                                        this.props.setHighlightAction(null);
                                        target = {
                                            reply: true
                                        };
                                    } else if (value == "trigger_channel") {
                                        this.props.setHighlightAction(null);
                                        target = {
                                            trigger_channel: true
                                        };
                                    } else if (value == "editId") {
                                        target = {
                                            editId: ""
                                        };
                                        if (this.props.data.action_id) {
                                            target.editId = this.props.data.action_id;
                                            this.props.setHighlightAction(this.props.data.action_id);
                                        }

                                    }
                                    this.updateData("target", target);

                                }
                                } value={"reply" in this.props.data.target ? "reply" : "trigger_channel" in this.props.data.target ? "trigger_channel" : 'editId' in this.props.data.target ? "editId" : ""} options={this.renderResponseTypes()} /> */}
								</div>
							</div>
						) : null}

						{this.props.data.target ? <>{this.renderResponseInputs()}</> : null}

						<hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>
						<div className='mt-15'>
							<h4 style={{ marginBottom: "0px" }}>Optional Variable</h4>
							<div className="section-content-header mb-15">An optional variable to return the message id of this reply. You can use this variable in other blocks.</div>
							<div className="long-input mb-15">
								<label>Variable</label>
								<input
									className={`${this.props.data.variable == "" && this.props.saveFailed ? "command-required" : ""}`}
									onChange={(e) => {
										var value = e.target.value;
										value = value.toLowerCase();
										value = value.substring(0, 32);
										value = value.replace(" ", "_");
										const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
										value = value.replace(regexForNonAlphaNum, "");

										this.updateData("variable", value);
									}}
									type="text"
									value={this.props.data.variable ? this.props.data.variable : ""}
								/>
							</div>



							{this.props.data.variable && (
								<div>
									<hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>

									<h4>Message Id Variable</h4>
									<p className="mb-15" style={{
										color: "#fff",
										opacity: "0.7",
										fontWeight: 500
									}}>
										You can use the variable <span style={{ color: "#f45142", opacity: "1 !important" }}>{`{${this.props.data.variable}}`}</span>
										<FontAwesomeIcon
											icon={faClipboard}
											fixedWidth
											className='text-sm hover:text-red hover:cursor-pointer'
											onClick={() => {
												navigator.clipboard.writeText(`{${this.props.data.variable}}`);
												successToast("Copied variable to clipboard");
											}}
										/> in other blocks.
									</p>
								</div>
							)}
						</div>
					</div>
				</>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	saveFailed: state.builder.saveFailed,
	components: state.builder.components,
	mode: state.builder.mode,
	beta: state.data.beta
});
const mapDispatchToProps = {
	updateElementData,
	setHighlightAction,
	setElements
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedReply);

let s4 = () => {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
};
