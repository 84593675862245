import { isNode } from "react-flow-renderer";
import dagre from 'dagre';
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
export default async function getLayoutedElements(elements, direction = 'TB') {
  const nodeWidth = 400
  const nodeHeight = 90;
  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el) => {

    if (isNode(el)) {
      var docuElement = document.getElementById(el.id);
      // console.log(docuElement,docuElement.clientHeight)
      if (el.id != 'root') {
        dagreGraph.setNode(el.id, { width: docuElement.clientWidth, height: 79 });
      } else {
        dagreGraph.setNode(el.id, { width: 400, height: 121 });

      }
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = 'top';
      el.sourcePosition = 'bottom';
      var docuElement = document.getElementById(el.id);
      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      if (el.id != "root") {
        el.position = {
          x: nodeWithPosition.x - docuElement.clientWidth / 2 + Math.random() / 1000,
          y: nodeWithPosition.y - 79 / 2,
        };
      } else {
        el.position = {
          x: nodeWithPosition.x - 400 / 2 + Math.random() / 1000,
          y: nodeWithPosition.y - 121 / 2,
        };
      }

    }

    return el;
  });
};