import React, { Component } from "react";
import { connect } from "react-redux";
import server from "../../../api/server";
import Dashboard from "../Dashboard";
import ModuleHeader from "../elements/ModuleHeader";
import SectionHeader from "../elements/SectionHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faRecycle } from "@fortawesome/free-solid-svg-icons";

export class Servers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      servers: [],
    };
  }

  componentDidMount() {
    this.getServers();
  }

  getServers = async () => {
    const response = await server.post("/dashboard/servers", {
      token: this.props.token,
      bot_id: this.props.bot.id,
    });
    if (response.data != false) {
      this.setState({ servers: response.data });
    }
  };

  renderServers = () => {
    var servers = [];

    this.state.servers.forEach((ser, index) => {
      var disabled = false;
      var leaveServer = async () => {
        disabled = true;
        const response = await server.post("/dashboard/leaveserver", {
          token: this.props.token,
          bot_id: this.props.bot.id,
          id: ser.id,
        });
        var new_servers = [...this.state.servers];
        new_servers.splice(index, 1);
        this.setState({ servers: new_servers });
      };

      servers.push(
        <div className="col-lg-6 col-md-12 " style={{ padding: "15px" }}>
          <div className="server-item flex-col">
            <div className="server-info w-100">
              <img
                src={
                  ser.icon != null
                    ? `https://cdn.discordapp.com/icons/${ser.id}/${ser.icon}.webp`
                    : process.env.PUBLIC_URL + "/images/icons/discord.png"
                }
              ></img>
              <div className="server-name truncate">
                <span>{ser.name}</span>
                {/* <span>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA</span> */}
              </div>

            </div>
            <button
              disabled={disabled}
              style={{ marginLeft: "auto" }}
              onClick={() => {
                leaveServer();
              }}
              className="btn btn-red m-mt-15"
            >
              Leave Server
            </button>
          </div>
        </div>
      );
    });

    return servers;
  };
  render() {
    return (
      <Dashboard>
        <ModuleHeader
          showDocs={false}
          showEnabled={false}
          icon="discord.png"
          title="Servers"
          description="The servers your bot is currently active in"
        ></ModuleHeader>

        <section>
          <SectionHeader
            title={`Active Servers (${this.state.servers.length})`}
            pretitle="Servers"
          />

          {this.state.servers.length > 0 ? (
            <div className="server-list">
              <div className="row">{this.renderServers()}</div>
            </div>
          ) : (
            <div className="no-servers">
              <p>
                Please invite your bot to a server using the{" "}
                <FontAwesomeIcon icon={faLink} /> Invite Link and then click
                refresh
              </p>
              <button
                className="btn btn-gray"
                onClick={() => {
                  this.getServers();
                }}
              >
                Refresh Server List
              </button>
            </div>
          )}
        </section>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.data.bot.token,
  bot: state.data.bot,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Servers);
