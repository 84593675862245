import update from 'immutability-helper';

export default (state = {},action) => {
    switch(action.type){
      case 'SET_QUICK_START':
        return update(state,{
          $set:action.payload
        })
      default:
        return state
    }
  }
  