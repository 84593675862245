import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";
import { TextArea } from "../elements/TextArea";
import { Select } from "../elements/Select";
import { CommandAdd } from "../elements/CommandAdd";
import { RoleAdd } from "../elements/RoleAdd";

import Dashboard from "../Dashboard";

import { setBotModule, setAuth } from "../../../actions";

import server from "../../../api/server";
import PremiumRedirect from "../elements/PremiumRedirect";
import PremiumSection from "../elements/PremiumSection";

export class CoCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            disableButton: false,
        };
    }

    moduleEnable = (value) => {
        const cocreate = { ...this.props.cocreate };
        cocreate.enabled = value;

        this.props.setBotModule({
            module: "cocreate",
            module_data: cocreate,
        });
    };

    generateInvite = async () => {
        this.setState({ disableButton: true });
        const response = await server.post("/dashboard/generateinvite", {
            id: this.props.id,
        });
        if (response.data) {
            var cocreate = { ...this.props.cocreate };
            cocreate.invite = response.data.invite_id.result;
            this.props.setBotModule({
                module: "cocreate",
                module_data: cocreate,
            });
            this.setState({ disableButton: false });
        } else {
            if (response.data.reason && response.data.reason == "logged") {
                this.props.setAuth();
            }
        }
    };

    removeCoCreator = async (id) => {
        const response = await server.post("/dashboard/cocreator/delete", {
            cocreator_id: id,
            id: this.props.bot.id,
        });
        var cocreate = { ...this.props.cocreate };
        var cocreators = cocreate.cocreators;
        cocreate.cocreators = cocreators;
        cocreators = cocreators.filter((item) => item.id !== id);
        cocreate.cocreators = cocreators;

        this.props.setBotModule({
            module: "cocreate",
            module_data: cocreate,
        });
    };

    renderCoCreators = () => {
        const cocreators = [];

        this.props.cocreate.cocreators.forEach((cocreator) => {
            cocreators.push(
                <div
                    className="command"
                    onClick={(e) => {
                        this.removeCoCreator(cocreator.id);
                    }}
                >
                    <div class="command-info">
                        <h3>{cocreator.username}</h3>
                        <span>{cocreator.id}</span>
                    </div>
                </div>
            );
        });

        return cocreators;
    };

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    render() {
        return (
            <Dashboard>
                <PremiumRedirect></PremiumRedirect>
                <ModuleHeader
                    // youtube="https://www.youtube.com/watch?v=S5lqq27aWG4"
                    // tutorial="https://docs.botghost.com/utility/co-create"
                    // docs="https://docs.botghost.com/utility/co-create"
                    // premiumRequired={true}
                    showEnabled={false}
                    // enabledValue={this.props.cocreate.enabled}
                    change={this.moduleEnable}
                    description="Invite other people to edit and work on your bot"
                    title="Co Create"
                    icon="co-create.png"
                ></ModuleHeader>

                <PremiumSection>
                    <SectionHeader
                        title="Invite Link"
                        pretitle="CoCreate"
                    ></SectionHeader>

                    <div className="section-content-normal">
                        <div className="mb-15">
                            <h3 style={{ marginBottom: "0px" }}>Invite Link</h3>
                            <div class="section-content-header">
                                Your Co-create invite link. Give this link to users to invite
                                them to edit your bot
                            </div>
                        </div>

                        <div className="command m-mt-0 m-p-0 m-section-content">
                            <div className="command-info m-breakword m-w-100">
                                <h3 onClick={() => {
                                    navigator.clipboard.writeText(`https://dashboard.botghost.com/invite/${this.props.cocreate.invite}`).then(() => {
                                        // todo - add toast
                                    });
                                }}>{`https://dashboard.botghost.com/invite/${this.props.cocreate.invite}`}</h3>
                            </div>

                            <div className="command-options m-w-100 m-mt-15">
                                <button
                                    disabled={this.state.disableButton}
                                    onClick={(e) => {
                                        this.generateInvite();
                                    }}
                                    className="btn btn-red"
                                >
                                    Regenerate Link
                                </button>
                            </div>
                        </div>
                    </div>
                </PremiumSection>

                <PremiumSection>
                    <SectionHeader
                        title="Your Co-Creators"
                        pretitle="CoCreate"
                    ></SectionHeader>

                    <div className="row">
                        <div className="col-lg-6">{this.renderCoCreators()}</div>
                    </div>
                </PremiumSection>
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    cocreate: state.data.bot.commands.cocreate,
    id: state.data.bot.id,
    premium: state.data.premium,
    bot: state.data.bot,
});

const mapDispatchToProps = {
    setBotModule,
    setAuth,

};

export default connect(mapStateToProps, mapDispatchToProps)(CoCreate);
