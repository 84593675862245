import React, { Component } from 'react'
import { connect } from 'react-redux'


import SectionHeader from "../elements/SectionHeader"
import ModuleHeader from "../elements/ModuleHeader"
import Toggle from "../elements/Toggle"
import Command from "../elements/Command"

import {setBotModule} from "../../../actions"

import Dashboard from "../Dashboard"
import  PremiumRedirect  from '../elements/PremiumRedirect'
import  PremiumSection  from '../elements/PremiumSection'

export class ApexLegends extends Component {

    commandUpdate = (value)=>{
        var games = {...this.props.games}
        games.apex = value
        this.props.setBotModule({
            module:"games",
            module_data:games
        })
    }
    
    enableChange = (value) =>{
        var games = {...this.props.games}
        games.apex.enabled = value
        this.props.setBotModule({
            module:"games",
            module_data:games
        })
    }

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    render() {
        return (
            <Dashboard>
                <PremiumRedirect></PremiumRedirect>
                <ModuleHeader youtube ="https://www.youtube.com/watch?v=xdbCHE6oOCQ" tutorial="https://docs.botghost.com/modules/game-integrations/apex-legends" premiumRequired={true}  description="Share your latest stats directly from Apex Tracker" change={this.enableChange} enabledValue={this.props.games.apex.enabled} title="Apex Legends" icon="apex.png"></ModuleHeader>
                
                <PremiumSection>
                    <SectionHeader title="Commands" pretitle="Apex Legends" ></SectionHeader>

                    <div className="row">
                        <div className="col-xl-6">
                            <Command
                             name="apex" 
                             description="Receives Apex Legends Stats for a specific user from a specific platform (pc, xb1 or psn)."
                             update={this.commandUpdate}
                             options={this.props.games.apex}
                             module="games"
                             showToggle={false}
                             toggleValue={this.props.games.apex.enabled}
                             showPrefix={true}
                             slashOnly={true}
                             
                             
                             ></Command>
                        </div>
                    </div>
                </PremiumSection>
            </Dashboard>
        )
    }
}

const mapStateToProps = (state) => ({
    games:state.data.bot.commands.games,
    beta:state.data.beta
})

const mapDispatchToProps = {
    setBotModule
}

export default connect(mapStateToProps, mapDispatchToProps)(ApexLegends)
