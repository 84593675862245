import React, { Component } from 'react'
import { connect } from 'react-redux'

import TextArea from "../TextArea"
import Toggle from "../Toggle"
import Embed_Action from './Embed_Action'
import PlainText_Action from './PlainText_Action'
import DocsLink from '../DocsLink'

export class SlashResponseOptions extends Component {

    updateResponse = (key,value) =>{
        var response_options = {...this.props.data.response_options};
        response_options[key] = value;
        this.props.update("response_options",response_options);
    }
    changeType = () =>{
        var response_options = {...this.props.data.response_options};
        if("response" in this.props.data.response_options){
            response_options = {
                embed:{

                }
            }
        }else{
            response_options = {
                response:""
            }
        }
        this.props.update("response_options",response_options);
        
    }
    render() {
        return (
            <div className="mb-15">

                <div style={{ width: "100%", marginRight: "20px" }}>

                    <div className="justify-space-between">
                        <h3 style={{ marginBottom: "0px" }}>Message <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions/plain-text-response"}></DocsLink></h3>

                        <div className="section-content-title justify-space-between">
                            <span style={{ marginInlineEnd: "12px", marginBottom: "0px" }}>{this.props.data.response_options.embed != undefined ? "Embed" : "Plain Text"}</span>
                            <Toggle value={this.props.data.response_options.response != undefined ? false : true} update={(value) => { this.changeType() }} type="type"></Toggle>
                        </div>
                    </div>

                    {'response' in this.props.data.response_options ? <PlainText_Action options={this.props.options} data={this.props.data.response_options} update={this.updateResponse} /> : <Embed_Action options={this.props.options} data={this.props.data.response_options} update={this.updateResponse} />}
                    <hr className="slashcommand-hr"></hr>

                    {/* {this.state.role_message_type ? <Embed value={this.state.embed} update={this.embedUpdate}></Embed> : <TextArea variableEditor={true} value={this.state.response} onChange={this.responseOnChange}></TextArea>} */}
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SlashResponseOptions)
