import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../Dashboard";
import ModuleHeader from "../elements/ModuleHeader";
import SectionHeader from "../elements/SectionHeader";
import SlashCommand from "../elements/SlashCommand";
import SlashCommandEditor from "../elements/SlashCommandEditor";
import GroupEditor from "./SlashCommandsGrouping";

export class SlashCommands extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: "",
      showModal: false
    };
  }
  renderCommands = () => {
    const { slash, groups } = this.props;
    if (!slash || !slash.commands) return null;

    const filter = this.state.filter.toLowerCase().split(" ").join("-");

    // Group commands
    const groupedCommands = {};
    const ungroupedCommands = [];
    slash.commands.forEach((command, index) => {
      if (command.event || !command.name || !command.description) return;

      const name = command.name.toLowerCase();
      const description = command.description.toLowerCase();

      if (filter === "" || name.includes(filter) || description.includes(filter)) {
        const groupId = command.groupId;
        const group = groups.find(g => g.id === groupId);
        
        if (groupId && group) {
          if (!groupedCommands[groupId]) groupedCommands[groupId] = [];
          groupedCommands[groupId].push(
            <SlashCommand key={index} command={command} index={index} />
          );
        } else {
          ungroupedCommands.push(
            <SlashCommand key={index} command={command} index={index} />
          );
        }
      }
    });

    // Render grouped commands
    const renderedGroups = Object.entries(groupedCommands).map(([groupId, commands]) => {
      const group = groups.find(g => g.id === groupId);
      return (
        <section key={groupId}>
          <SectionHeader
            title={group.name}
            pretitle={group.description}
          />
          <div>{commands}</div>
        </section>
      );
    });

    // Render ungrouped commands at the bottom
    if (ungroupedCommands.length > 0) {
      renderedGroups.push(
        <section key="ungrouped">
          <SectionHeader
            title="Ungrouped"
            pretitle="Ungrouped"
          />
          <div>{ungroupedCommands}</div>
        </section>
      );
    }

    return renderedGroups;
  };

  countActiveCommands = () => {
    var count = 0;
    this.props.slash.commands.forEach((command) => {
      if (command.enabled == undefined || command.enabled == true) {
        count += 1;
      }
    });
    return count;
  };
  render() {
    return (
      <Dashboard>
        <GroupEditor showModal={this.state.showModal} close={() => { this.setState({ showModal: false }); }}></GroupEditor>
        <ModuleHeader
          tutorial="https://docs.botghost.com/custom-commands-and-events/command-and-event-builder"
          showEnabled={false}
          title="Custom Commands"
          description="Create custom slash commands"
          icon="slash.png"
          docs={"https://docs.botghost.com/custom-commands-and-events/command-and-event-builder"}
        ></ModuleHeader>

        <section>
          <SectionHeader
            title="New Custom Command "
            pretitle="Custom Commands"
          />

          <SlashCommandEditor></SlashCommandEditor>
        </section>



        <section>
          {/* <SectionHeader
            title={`Active Commands ${this.props.premium == false
              ? ` (${this.countActiveCommands()}/5)`
              : ""
              }`}
            pretitle="Slash Commands"
          ></SectionHeader> */}
          <div className='flex flex-col md:flex-row gap-5 md:gap-0'>
            <div>
              <p class="section-pretitle">Slash Commands</p>
              <h2 class="section-title">Active Commands {this.props.premium == false
                ? ` (${this.countActiveCommands()}/8)`
                : ""
              }</h2>
            </div>

            <div
              className='ml-auto flex items-center w-full md:w-auto'
            >
              {/* Grouping Button */}
              <button className="btn btn-primary h-10 whitespace-nowrap mr-2" onClick={() => { this.setState({ showModal: true }); }}>Command Group Editor</button>
              <div className="long-input" style={{
                width: "100%"
              }}>
                <input style={{
                  backgroundColor: "#2f3136"
                }} type="text" placeholder="Search" value={this.state.filter} onChange={(e) => {
                  this.setState({ filter: e.target.value });
                }}></input>
              </div>
            </div>

          </div>
          <div>{this.renderCommands()}</div>
        </section>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  slash: state.data.bot.commands.slash,
  premium: state.data.premium,
  groups: state.data.bot.commands.commandGroups || [],
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SlashCommands);