import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactDOM from "react-dom"
import Select from './Select'
import Toggle from './Toggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DocsLink from './DocsLink'


const OPTION_TYPES = [
    {value:"string",label:"Text"},
    {value:"int",label:"Number"},
    {value:"boolean",label:"Choice"},
    {value:"user",label:"User"},
    {value:"channel",label:"Channel"},
    {value:"role",label:"Role"},


]

export class SlashCommandOption extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             type:"string",
             name:"",
             description:"",
             required:false,
             validated:true,
             showContents:true
        }
    }
    componentDidMount() {
        if(this.props.edit == true){
            this.setState({showContents:false})
        }
        document.addEventListener("click",this.handleClick,true)
    }

    componentWillUnmount (){
        document.removeEventListener("click",this.handleClick,true)
    }

    handleClick = (event) =>{
        const domNode = ReactDOM.findDOMNode(this);
        if(!domNode || !domNode.contains(event.target)){
            this.validateOption(true)
        }
    }

    nameChange = (value) =>{
        value = value.toLowerCase();
        value = value.substring(0,32)
        value = value.replace(" ","-")
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum,"")
        this.props.update("name",value,this.props.index)
    } 
 
    validateOption = (close) =>{
        if((this.props.data.name != "" && this.props.data.description != "") || this.state.showContents == false){
            if(close == true){
                this.setState({showContents:false})
            }else{
                this.setState({showContents:!this.state.showContents})
            }
        }
    }

    renderTypeLabel = (value) =>{
        var string = ""
        OPTION_TYPES.forEach(option =>{
            if(option.value == value){
                string = option.label;
            }
        })

        return string;
    }
    
    render() {
        return (

            <div className={`announcement slashcommand-option-container ${this.state.validated == false ? "slashcommand-unvalidated":null}`}>
            <div className="announcement-header" onClick={(e) =>{this.validateOption()}}>
                <div>
                    <h3 className="mb-0">Option {this.props.index + 1}</h3>
                    <div className="section-content-header">{this.renderTypeLabel(this.props.data.type)} Option</div>
                </div>
                <div style={{marginLeft:"auto"}}>
                    
                    {this.state.showContents == true ? <i style={{fontSize:"20px"}} class="bi bi-caret-up-fill"></i>: <i style={{fontSize:"20px"}} class="bi bi-caret-down-fill"></i>}
                </div>
            </div>

            <div>
                {this.state.showContents == true ?
            <div className="slashcommand-option slash-action">
            <hr className="slashcommand-hr"></hr>
            <div className="mb-15">
                    <h4>Option</h4>
                </div>


                <div className="mb-15">
                    <h4>Option Type <DocsLink location={"https://docs.botghost.com/modules/slash-commands/options-1#option-type"}></DocsLink></h4>
                    <span>The type of data to get from the user.</span>
                    <div className="mt-15">
                        <Select required={true} value={this.props.data.type} onChange={(value) => { this.props.update("type",value,this.props.index) }} options={OPTION_TYPES}></Select>
                    </div>
                </div>
                <div className="mb-15">
                    <h4>Option Name <DocsLink location={"https://docs.botghost.com/modules/slash-commands/options-1#option-name"}></DocsLink></h4>
                    <span>A descriptive name of the option</span>
                    <div className="long-input">
                        <label>Name</label>
                        <input required={true} type="text" value={this.props.data.name} onChange={(e) => { this.nameChange(e.target.value) }}></input>
                    </div>
                </div>

                <div className="mb-15">
                    <h4>Option Description <DocsLink location={"https://docs.botghost.com/modules/slash-commands/options-1#option-description"}></DocsLink></h4>
                    <span>A short description of what the option is</span>
                    <div className="long-input">
                        <label>Description</label>
                        <input required={true}  type="text" value={this.props.data.description.substring(0,100)} onChange={(e) => { this.props.update("description",e.target.value,this.props.index) }}></input>
                    </div>
                </div>

                <div className="mb-15">
                    <h4>Set Required <DocsLink location={"https://docs.botghost.com/modules/slash-commands/options-1#set-required"}></DocsLink></h4>
                    <span>Whether or not a response is required</span>
                    <div className="mt-15">
                        <Toggle required={true} update={(value) => { this.props.update("required",value,this.props.index) }} value={this.props.data.required}></Toggle>
                    </div>
                </div>

                <div className="mb-15">
                    <button onClick={(e) =>{this.props.delete(this.props.index)}} className="btn btn-red" style={{backgroundColor:"rgba(255, 107, 134, 0.5)"}}><FontAwesomeIcon icon={faTrash}/> Delete Option</button>
                </div>


            </div>
                :null
                }
            </div>


        
    </div>

        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(SlashCommandOption)
