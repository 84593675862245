import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import { updateElementData } from "../../../../actions";
import TextArea from "../../elements/TextArea";
import Select from "../../elements/Select";
import { checkIntegrationChild } from "../eventUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import VariableTextInput from "../VariableTextInput";
import BuilderVarEditor from "../BuilderVarEditor";

export class EquationAction extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dataType: "text",
			user: false,
			showVariableEditor: false,
			channel: false
		};
	}

	componentDidMount() {
		if (this.props.data == undefined) {
			var target = { user_id: "" };
			if (this.props.components["User"]) {
				target = { self: true };
			}
			this.props.updateElementData({
				data: {
					type: "math_var",
					reference: "",
					key: "",
					round: true,
					equation: "",
					target: target,
					validated: false
				},
				id: this.props.id
			});
		} else {
			var reference = this.props.data.reference;
			var variables_array = this.props.custom_variables.variables;
			var variable_settings = variables_array.find((v) => v.reference == reference);
			if (!variable_settings) return;
			var type = variable_settings.type;
			var user = false;
			var channel = false;
			if (type == "user") {
				type = variable_settings.dataType;
				user = true;
			} else if (type == "channel") {
				type = variable_settings.dataType;
				channel = true;
			}

			this.setState({ user: user, dataType: type, channel: channel });
		}
	}

	targetOnChange = (value) => {
		if (value == this.state.user) {
			return;
		}
		// {label:"User who used the command",value:"self"},
		// {label:"User variable",value:"variable"},
		// {label:"User ID",value:"id"},
		if (value == "self") {
			var data = { ...this.props.data };
			data.target = {
				self: true
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		} else if (value == "variable") {
			var data = { ...this.props.data };
			data.target = {
				variable: ""
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		} else if (value == "id") {
			var data = { ...this.props.data };
			data.target = {
				user_id: ""
			};
			this.props.updateElementData({
				data: data,
				id: this.props.id
			});
		}

		this.setState({ user: value });
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "math_var",
					reference: "",
					equation: "",
					target: {
						self: true
						// variable:"{option_target}",
						// user_id:"12345"
					},
					round: true,
					validated: false
				},
				id: this.props.id
			});
		} else {
			// VALIDATE
			if (this.props.id != prevProps.id && this.props.data && this.props.data.reference) {
				var reference = this.props.data.reference;
				var variables_array = this.props.custom_variables.variables;
				var variable_settings = variables_array.find((v) => v.reference == reference);
				if (!variable_settings) return;

				var type = variable_settings.type;
				var user = false;
				var channel = false;
				if (type == "user") {
					type = variable_settings.dataType;
					user = true;
				} else if (type == "channel") {
					type = variable_settings.dataType;
					channel = true;
				}
				this.setState({ user: user, channel: channel, dataType: type });
			}
			if (this.props.data.equation != "" && this.props.data.reference != "") {
				if (this.props.data.validated == false) {
					this.updateData("validated", true);
				}
			} else if (this.props.data.validated == true) {
				this.updateData("validated", false);
			}
		}
	}

	nameChange = (value) => {
		value = value.toLowerCase();
		value = value.substring(0, 32);
		value = value.replace(" ", "-");
		const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/gu);
		value = value.replace(regexForNonAlphaNum, "");
		this.updateData("name", value);
	};

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	renderResponseTypes = () => {
		var user = "User who used the command";
		if (this.props.mode == "event" && this.props.components["User"]) {
			user = "The user.";
		}
		// const responseTypes = [
		//     { value: "reply", label: "Reply to the command", disabled: this.props.components.Interaction? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
		//     { value: "trigger_channel", label: `Send the message to the ${channel}`, disabled: this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true }
		// ];

		const responseTypes = [
			{ label: user, value: "self", disabled: this.props.components.Interaction || this.props.components.User ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "User option", value: "variable", disabled: this.props.components.Interaction ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "User ID", value: "id" }
		];

		return responseTypes;
	};

	renderChannelTypes = () => {
		var channel = "Channel the command was used in";
		if (this.props.mode == "event" && this.props.components["Channel"]) {
			channel = "The channel.";
		}
		// const responseTypes = [
		//     { value: "reply", label: "Reply to the command", disabled: this.props.components.Interaction? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
		//     { value: "trigger_channel", label: `Send the message to the ${channel}`, disabled: this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true }
		// ];

		const responseTypes = [
			{ label: channel, value: "self", disabled: this.props.components.Interaction || this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "Specific channel or variable", value: "id" }
		];

		return responseTypes;
	};

	renderDisableField = () => {
		var disabled = true;
		if (this.props.data.target.reply == true && (this.props.components.Interaction || checkIntegrationChild(this.props.elements, this.props.id))) {
			disabled = false;
		} else if (this.props.data.target.trigger_channel == true && (this.props.components.Channel || checkIntegrationChild(this.props.elements, this.props.id))) {
			disabled = false;
		}
		return disabled;
	};

	setVariable = (value) => {
		this.updateData("reference", value);
		var variables_array = this.props.custom_variables.variables;
		var variable_settings = variables_array.find((v) => v.reference == value);
		if (!variable_settings) return;
		var type = variable_settings.type;
		var user = false;
		var channel = false;
		if (type == "user") {
			type = variable_settings.dataType;
			user = true;
		} else if (type == "channel") {
			type = variable_settings.dataType;
			channel = true;
		}
		this.setState({ dataType: type, user: user, channel: channel });
	};

	render() {
		if (this.props.data != undefined) {
			return (
				<div>
					<BuilderVarEditor
						close={(newVar) => {
							this.setState({ showVariableEditor: false });
							if (newVar) {
								this.setVariable(newVar);
							}
						}}
						showModal={this.state.showVariableEditor}
					/>
					<div className="mb-15 flex flex-row itesm-center">
						<div className="max-w-[68%]">
							<h4 className="!text-md">Equation on Variable</h4>
							<p className="!text-sm">Run an equation using the value of a custom variable. The custom variable will be set to the result of the equation.</p>
						</div>

						<div className="ml-auto">
							<button
								className="btn btn-red px-2 text-sm"
								onClick={() => {
									this.setState({ showVariableEditor: true });
								}}
							>
								New Variable
							</button>
						</div>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Custom Variable <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/run-equation-on-variable#custom-variable" />
						</h4>
						<span>The Custom Variable to run the equation on. </span>
						<Select
							type="custom_variables"
							custom_arrays={false}
							value={this.props.data.reference}
							onChange={(value) => {
								this.setVariable(value);
							}}
						></Select>
					</div>

					<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>

					{this.state.user ? (
						<>
							<div className="mb-15 slash-action">
								<h4>User Target </h4>
								<span>Which user to set the custom variable of.</span>
								<Select
									value={"self" in this.props.data.target ? "self" : "variable" in this.props.data.target ? "variable" : "user_id" in this.props.data.target ? "id" : ""}
									onChange={(value) => {
										this.targetOnChange(value);
										// value = JSON.parse(value);
										// this.setState({value:value})
									}}
									options={this.renderResponseTypes()}
								></Select>
							</div>

							{"variable" in this.props.data.target ? (
								<div className="mb-15 slash-action">
									<h4>User Option </h4>
									<span>The user option to set the custom variable of.</span>

									<VariableTextInput
										required={true}
										slash_options={this.props.options}
										label="Option"
										placeholder="{option_user}"
										value={this.props.data.target.variable}
										onChange={(value) => {
											var data = { ...this.props.data };
											data.target = {
												variable: value
											};

											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										}}
									/>
									{/* <div class="long-input mt-15">
                                            <label>Option</label>
                                            <input onChange={(e) => {

                                                var data = { ... this.props.data };
                                                data.target = {
                                                    variable: e.target.value,
                                                };
                                                this.props.updateElementData(
                                                    {
                                                        data: data,
                                                        id: this.props.id
                                                    }
                                                );

                                            }} type="text" placeholder={"{option_user}"} value={this.props.data.target.variable} />
                                        </div> */}
								</div>
							) : null}

							{"user_id" in this.props.data.target ? (
								<div className="mb-15 slash-action">
									<h4>User ID </h4>
									<span>The user id to set the custom variable of.</span>
									<VariableTextInput
										required={true}
										slash_options={this.props.options}
										label="ID"
										placeholder="136327647792726016"
										value={this.props.data.target.user_id}
										onChange={(value) => {
											var data = { ...this.props.data };

											data.target = {
												user_id: value
											};

											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										}}
									/>
									{/* <div class="long-input mt-15">
                                            <label>ID</label>
                                            <input onChange={(e) => {

                                                var data = { ... this.props.data };
                                                data.target = {
                                                    user_id: e.target.value,
                                                };
                                                this.props.updateElementData(
                                                    {
                                                        data: data,
                                                        id: this.props.id
                                                    }
                                                );

                                            }} type="text" placeholder={"136327647792726016"} value={this.props.data.target.user_id} />
                                        </div> */}
								</div>
							) : null}

							<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>
						</>
					) : null}

					{this.state.channel ? (
						<>
							<div className="mb-15 slash-action">
								<h4>Channel Target </h4>
								<span>Which channel to set the custom variable of.</span>
								<Select
									value={"self" in this.props.data.target ? "self" : "id"}
									onChange={(value) => {
										if (value == this.state.channel) {
											return;
										}
										// {label:"User who used the command",value:"self"},
										// {label:"User variable",value:"variable"},
										// {label:"User ID",value:"id"},
										if (value == "self") {
											var data = { ...this.props.data };
											data.target = {
												self: true
											};
											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										} else if (value == "id") {
											var data = { ...this.props.data };
											data.target = {
												id: ""
											};
											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										}

										this.setState({ channel: value });

										// value = JSON.parse(value);
										// this.setState({value:value})
									}}
									options={this.renderChannelTypes()}
								></Select>
							</div>

							{"id" in this.props.data.target ? (
								<div className="mb-15 slash-action">
									<h4>Channel ID or Variable </h4>
									<span>The id or variable of a channel to set the custom variable of.</span>

									<VariableTextInput
										required={true}
										slash_options={this.props.options}
										label="ID or Variable"
										placeholder="136327647792726016"
										value={this.props.data.target.id}
										onChange={(value) => {
											var data = { ...this.props.data };
											data.target = {
												id: value
											};
											this.props.updateElementData({
												data: data,
												id: this.props.id
											});
										}}
									/>
									{/* <div class="long-input mt-15">
                                        <label>ID or Variable</label>
                                        <input onChange={(e) => {

                                            var data = { ... this.props.data };
                                            data.target = {
                                                id: e.target.value,
                                            };
                                            this.props.updateElementData(
                                                {
                                                    data: data,
                                                    id: this.props.id
                                                }
                                            );

                                        }} type="text" placeholder={"136327647792726016"} value={this.props.data.target.id} />
                                    </div> */}
								</div>
							) : null}

							<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>
						</>
					) : null}

					{this.state.dataType == "object" ? (
						<>
							{/* Key Input */}
							<div className="mb-15 slash-action">
								<h4>
									Key <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/set-variable#key" />
								</h4>
								<span>The key of this custom variable to run the equation on.</span>

								<VariableTextInput
									required={true}
									slash_options={this.props.options}
									label="Key"
									placeholder="key"
									value={this.props.data.key}
									onChange={(value) => {
										// value = value.substring(0, 100);
										// // value = value.replace(" ","-")
										// value = value.split(" ").join("_");
										// const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_]+/gu);
										// value = value.replace(regexForNonAlphaNum, "");
										// // console.log(value, 'VALUE NOW ');

										this.updateData("key", value);
									}}
								/>
							</div>
							<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>
						</>
					) : null}

					{/* Text Value */}
					<div className="mb-15 slash-action">
						<h4>
							Round Result <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/run-equation-on-variable#round-result" />
						</h4>
						<span>Round the result to the nearest whole number.</span>
						<Select
							options={[
								{ label: "Yes", value: true },
								{ label: "No", value: false }
							]}
							value={this.props.data.round}
							onChange={(value) => {
								if (value == "true") {
									value = true;
								} else {
									value = false;
								}
								this.updateData("round", value);
							}}
						></Select>
					</div>
					<div className="mb-15 slash-action">
						<h4>
							Equation <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/run-equation-on-variable#equation" />
						</h4>
						<span>
							The equation to run. The <span>var</span> keyword will be replaced with your custom variable. The equation will respect order of operations.
						</span>

						<TextArea
							placeholder="var + 5"
							value={this.props.data.equation}
							onChange={(value) => {
								this.updateData("equation", value);
							}}
						/>
					</div>

					<div className="slash-action">
						<h4>Examples</h4>

						<p>var + 5</p>
						<p>var * 2</p>
						<p>var / 2</p>
						<p>var - 5</p>
						<p>var * (5+2)</p>
						<p>var + {`{random[1,10]}`}</p>
					</div>

					<div className="slash-action">
						<span>
							You can use this variable by using <span style={{ color: "#f45142", opacity: 1, fontWeight: "800" }}>{`{BGVAR_${this.props.data.reference}}`}</span>
							<FontAwesomeIcon
								icon={faClipboard}
								fixedWidth
								className="text-[#9d9b9b] text-sm hover:text-red hover:cursor-pointer"
								onClick={() => {
									navigator.clipboard.writeText(`{BGVAR_${this.props.data.reference}}`);
									toast.success("Copied variable to clipboard");
								}}
							/>
							in other actions.
						</span>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	saveFailed: state.builder.saveFailed,
	custom_variables: state.data.bot.commands.variables,
	components: state.builder.components,
	mode: state.builder.mode,
	beta: state.data.beta
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(EquationAction);
