import React, { Component } from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga";

var price_points = {
	Lifetime: [
		// { min: 1, max: 1, price: 119.99 },
		// { min: 2, max: 5, price: 89.99 },
		// { min: 6, max: 1000, price: 59.99 },

		{ min: 1, max: 1, price: 99.99 },
		{ min: 2, max: 5, price: 74.99 },
		{ min: 6, max: 1000, price: 49.99 }
	],
	Yearly: [
		{ min: 1, max: 1, price: 79.99 },
		{ min: 2, max: 5, price: 59.99 },
		{ min: 6, max: 1000, price: 39.99 }
	],
	Monthly: [
		{ min: 1, max: 1, price: 14.95 },
		{ min: 2, max: 5, price: 11.21 },
		{ min: 6, max: 1000, price: 7.48 }
	]
};
var STEPS = [1, 5, 10, 25, 50, 100];

var steps = {
	Lifetime: {
		1: 99.99,
		5: 129.99,
		10: 149.99,
		25: 199.99,
		50: 249.99,
		100: 299.99
	},
	Yearly: {
		1: 59.99,
		5: 99.99,
		10: 129.99,
		25: 149.99,
		50: 199.99,
		100: 249.99
	},
	Monthly: {
		1: 14.95,
		5: 39.99,
		10: 59.99,
		25: 79.99,
		50: 99.99,
		100: 149.99
	}
};

var prices_Unlimited = {
	Lifetime: {
		single: 79.99,
		unlimited: 99.99
	},
	Yearly: {
		single: 49.99,
		unlimited: 79.99
	},
	Monthly: {
		single: 7.99,
		unlimited: 17.99
	}
};

var subscription_types = {
	Lifetime: {
		single: {
			USD: "botghost-lifetime-3_v2",
			EURO: "botghost-lifetime-euro_v2"
		},
		unlimited: {
			USD: "botghost-lifetime-unlimited_v2",
			EURO: "botghost-lifetime-unlimited-euro_v2"
		}
	},
	Yearly: {
		single: {
			USD: "botghost-yearly-tiered_v2",
			EURO: "botghost-yearly-tiered-euro_v2"
		},
		unlimited: {
			USD: "botghost-yearly-tiered-unlimited_v2",
			EURO: "botghost-yearly-tiered-unlimited-euro_v2"
		}
	},
	Monthly: {
		single: {
			USD: "botghost-monthly-tiered_v2",
			EURO: "botghost-monthly-tiered-euro_v2"
		},
		unlimited: {
			USD: "botghost-monthly-tiered-unlimited_v3",
			EURO: "botghost-monthly-tiered-unlimited_v3-euro"
		}
	}
};
export class PremiumPlan extends Component {
	constructor(props) {
		super(props);

		this.state = {
			quantity: 1
		};
	}

	componentDidMount() {
		// console.log(this.props);
	}

	componentDidUpdate(prevProps, prevState) {
		// console.log(this.props);
	}

	calculatePrice = (billing) => {
		var price = 0;

		price = prices_Unlimited[this.props.name][this.props.type];

		var amount = parseFloat(price).toFixed(2);
		if (this.props.sale) {
			amount = (amount * this.props.sale_amount).toFixed(2);
		}

		return amount;
	};

	renderBilling = () => {
		var text = "";
		if (this.props.name == "Lifetime") {
			text = `${this.props.symbol}${this.calculatePrice()} paid once. Lifetime access`;
		} else if (this.props.name == "Yearly") {
			text = `${this.props.symbol}${this.calculatePrice(true)} billed yearly`;
		} else {
			text = `${this.props.symbol}${this.calculatePrice()} per month`;
		}

		return text + ". 7-day money back guarantee.";
	};
	planClick = () => {
		var plan = this.props.plan;
		var ga_price = this.props.ga_price;
		if (this.props.type == "unlimited") {
			ga_price = this.props.ga_ultimate_price;
		}

		// console.log(plan);
		this.props.select(this.props.name, plan, ga_price);
	};

	displayChange = () => {
		if ("transactions" in this.props.user) {
			var pass = false;
			this.props.user.transactions.forEach((transaction) => {
				if (transaction.active == true) {
					pass = true;
				}
			});
			return pass;
		} else {
			return false;
		}
	};

	calculateSaving = () => {
		if (this.props.name == "Lifetime") {
			return "Most Popular";
		} else if (this.props.name == "Yearly") {
			var savings = 1 - prices_Unlimited["Yearly"][this.props.type] / (prices_Unlimited["Monthly"][this.props.type] * 12);
			savings = Math.round(savings * 100);
			return `Save ${savings}%`;
		} else if (this.props.name == "Monthly") {
			return "Pay Monthly";
		}
	};

	renderPrice = () => {
		if (this.props.sale) {
			var amount = parseFloat(this.props.price).toFixed(2);
			var sale_amount = (amount * this.props.sale_amount).toFixed(2);
			return (
				<>
					<span className="line-through text-grayText">
						{this.props.symbol}
						{this.props.price}
					</span>{" "}
					<span>
						{this.props.symbol}
						{sale_amount}
					</span>
				</>
			);
		} else {
			return (
				<span>
					{this.props.symbol}
					{this.props.price}
				</span>
			);
		}
	};

	render() {
		return (
			<div className="col-xl-4 col-md-6">
				<div
					className={`${this.props.most_popular ? "lg:!min-h-[500px]" : ""} ${this.props.type == "single" ? "premium-plan" : "premium-plan-ultimate"} ${this.props.selected ? "premium-plan-selected" : null}`}
					onClick={(e) => {
						this.planClick();
					}}
				>
					{this.props.most_popular == true ? (
						<div className="premium-header-tag" style={{ backgroundColor: this.props.type == "unlimited" ? "#ffb632" : "#f45142" }}>
							<span>Lifetime Access</span>
						</div>
					) : null}
					<div className="premium-plan-info">
						<span className="premium-plan-name">
							{this.props.name} {this.props.type == "unlimited" ? "Unlimited Bots" : "Single Bot"}
						</span>
						<span className="premium-plan-price flex flex-col items-center 2xl:flex-row 2xl:gap-x-2">{this.renderPrice()}</span>
						<span className="premium-plan-description">{this.props.description}</span>
						<span className="premium-plan-tag mt-2">{this.calculateSaving()}</span>
					</div>

					{this.props.type == "unlimited" ? (
						<div style={{ height: "100%" }}>
							<div className="premium-ultimate-text">Create Unlimited Bots!</div>
						</div>
					) : null}
					<div className="premium-plan-buttons">
						<div className="premium-plan-buttons-container">
							{/* <button className="btn btn-gray" onClick={(e) =>{this.props.checkout(this.props.normal_plan,this.props.ga_price)}}>Single Bot</button> */}
							<button
								className={`btn btn-red ${this.props.type != "single" ? "ultimate-button" : null}`}
								onClick={(e) => {
									this.props.checkout(subscription_types[this.props.name][this.props.type][this.props.symbol == "$" ? "USD" : "EURO"], this.calculatePrice(), false);
								}}
								style={{ float: "right" }}
							>
								Upgrade {this.props.type == "unlimited" ? "Unlimited Bots" : "1 Bot"}
							</button>
							{this.displayChange() ? (
								<button
									className={`btn btn-gray`}
									onClick={(e) => {
										this.props.change();
									}}
									style={{ float: "right" }}
								>
									Change
								</button>
							) : null}
						</div>

						<div className="premium-plan-billing mt-2">
							<span>{this.renderBilling()}</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.data.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumPlan);
