import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from '../Dashboard';
import SectionHeader from '../elements/SectionHeader';
import GiftItem from '../elements/GiftItem';
import ReactGA from "react-ga";
import server from "../../../api/server";
import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import {
    setAuth,
    upgradeSubscription,
    updateProcessing,
} from "../../../actions";
import axios from 'axios';
import history from '../../../history';

const FAQS = [
    {
        question: "What is BotGhost Gifting?",
        answer: "BotGhost gifting allows you to send a code to someone else that will allow them to claim a premium subscription for one or an unlimited amount of bots."
    },
    {
        question: "How do I gift someone?",
        answer: "To gift someone, simply select one of the 3 gift options below and check out. After you have checked out, you will be given a code that you can send to the person you want to gift. They may then redeem the gift by following the instructions provided in the section below."
    },
    {
        question: "When is a gift activated?",
        answer: "The gift will become active as soon as the intended recipient claims the gift through the input below."
    },
    {
        question: "Can I get a refund?",
        answer: "In the event that you have successfully gifted someone and the recipient has already claimed the gift, it is not possible to obtain a refund. However, if you have gifted someone and they have not yet claimed the gift, you may be eligible to recieve a refund within seven days of the original purchase. It is not possible to refund a claimed gift. You can request a refund by sending an email to billing@botghost.com."
    },

];

export class Gifting extends Component {

    constructor(props) {
        super(props);

        this.state = {
            chargebeeReady: false,
            instance: null,
            currency: "USD",
            premium_code: "",
            premium_error: null,
            premium_code_disabled: false,
        };
    }
    setCurrency = async () => {
        var response = axios.get("https://ipapi.co/json")
            .then(results => {
                if ('data' in results && 'currency' in results.data && results.data.currency == "EUR") {
                    this.setState({ currency: "EURO" });
                }
            })
            .catch(e => {
                // console.log(e, "aRHUASUHAHUSDHASDU");
            });
    };
    componentDidMount() {

        const el = document.createElement('script');
        el.onload = () => {
            window.Chargebee.init({
                "site": "botghost",
            });
            window.Chargebee.registerAgain();
            this.setState({ chargebeeReady: true });
        };
        this.setCurrency();
        el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
        document.body.appendChild(el);
        if (this.props.user.id == undefined) {
            history.push("/dashboard/modules");
        }
    }

    createInstance = () => {
        if (this.state.chargebeeReady && this.state.instance == null) {
            var cbInstance = window.Chargebee.getInstance();
            // var cbPortal = cbInstance.createChargebeePortal();
            // cbPortal = this.setPortalCallbacks(cbPortal);
            var cart = cbInstance.getCart();
            var customer = { cf_user_id: this.props.user.id };
            cart.setCustomer(customer);
            // console.log(cart, "CART");
            cart = this.setCallBacks(cart);
            this.setState({ instance: cbInstance, cart: cart, });
        }
    };

    setCallBacks = (cart) => {
        cart.callbacks.close = async () => {
            this.props.updateProcessing(false);
            var response = await server.post("/dashboard/abandondedcart", { email: this.props.user.email });


        };

        cart.callbacks.success = (pageId) => {
            this.props.updateProcessing(true);
            this.checkoutSuccess(pageId);
        };

        cart.callbacks.step = (step) => {
            ReactGA.initialize("UA-122665050-1");
            ReactGA.event({
                category: "Checkout Step",
                label: step,
                action: "New Dashboard",
            });
        };
        return cart;
    };

    checkout = (plan, price, quantity) => {
        this.props.updateProcessing(true);
        let product = this.state.instance.initializeProduct(plan);
        // var price = this.state.type == false ? norm_price : ult_price;


        if (quantity) {
            product.setPlanQuantity(quantity);
        }
        this.state.cart.replaceProduct(product);
        this.state.cart.proceedToCheckout();
        this.setState({ ga_plan: plan, ga_price: price });
        ReactGA.initialize("UA-122665050-1");
        ReactGA.event({
            category: "Checkout",
            label: this.state.version,
            action: "click",
        });
    };


    renderGifts = () => {
        var gifts = [];
        if (!this.props.user.gifts) {
            return [];
        }

        this.props.user.gifts.forEach(gift => {
            var type;
            if (gift.refunded == true) {
                return;
            }
            // if (plan_id == "botghost_lifetime_gift") {
            //     type = "lifetime_unlimited";
            // } else if (plan_id == "botghost_365day_gift") {
            //     type = "yearly_single";
            // } else if (plan_id == "botghost_30day_gift") {
            //     type = "monthly_single";
            // }
            if (gift.type == "lifetime_unlimited") {
                type = "Unlimited Lifetime Premium";
            } else if (gift.type == "yearly_single") {
                type = "365 Days Premium";
            } else if (gift.type == "monthly_single") {
                type = "30 Days Premium";
            }
            gifts.push(
                <div className="command">
                    <div className="command-info">
                        <h3>{type}</h3>
                        <span className={`${gift.claimed ? "!text-red" : "!text-green"} !font-semibold`}>{gift.claimed ? "Claimed" : "Unclaimed"}</span>
                    </div>

                    <div className="command-options justify-center">
                        <div className="command-option">
                            <button className="btn btn-red" onClick={() => {
                                navigator.clipboard.writeText(gift.id);
                            }}>Copy Code</button>
                        </div>
                    </div>
                </div>
            );
        });

        return gifts;
    };

    checkoutSuccess = async (hostedPageId) => {
        // ReactGA.initialize("UA-122665050-1");

        // ReactGA.plugin.require("ecommerce");
        // ReactGA.plugin.execute("ecommerce", "addItem", {
        //   id: this.props.bot.id,
        //   name: this.state.ga_plan,
        //   sku: "--", // seems to be required
        //   price: this.state.ga_price,
        //   category: "Subscriptions",
        //   quantity: "1",
        // });
        // ReactGA.plugin.execute("ecommerce", "addTransaction", {
        //   id: this.props.bot.id, // the same as for addItem to connect them
        //   revenue: this.state.ga_price, // obviously it's price * quantity
        // });
        // ReactGA.plugin.execute("ecommerce", "send");
        // ReactGA.plugin.execute("ecommerce", "clear");

        // ReactGA.event({
        //   category: "Sale",
        //   label: `${this.state.ga_plan}`,
        //   action: "click",
        // });

        // ReactGA.plugin.execute("ecommerce", "send");
        this.setState({ loading: true });
        // const response =await  server.post("/dashboard/chargebee",{page_id:hostedPageId,bot:this.props.bot,user:this.props.user});
        setTimeout(() => {
            window.location.replace("/dashboard/gifting");
        }, 10000);

    };


    handlePremiumCodeClaim = async () => {
        // premium_code: "",
        // premium_code_disabled: false,
        // premium_error: ""
        // this.setState({ premium_error: "", premium_code_disabled: true });

        const response = await server.post("/dashboard/claimgiftcode", {
            code: this.state.premium_code,
            bot_id: this.props.bot.id
        });

        if (response && response.data) {
            if (response.data.success) {
                // Refresh browser
                if (response.data.message == 'lifetime') {
                    // Go to settings page
                    window.location.replace("/dashboard/settings");
                } else {
                    window.location.replace("/dashboard");
                }
            } else {
                this.setState({ premium_error: response.data.message, premium_code_disabled: false });
            }
        }
    };


    render() {
        return (
            <Dashboard>
                {this.createInstance()}
                {this.state.chargebeeReady ? <>                <section className='mt-[200px]'>
                    {/* <SectionHeader title="Gifting" pretitle="Gifting" /> */}
                    <div className='flex justify-center mb-5'>
                        <img src={process.env.PUBLIC_URL + "/images/icons/gift.png"} className='h-[75px]'></img>

                    </div>
                    <div className='grid grid-cols-1 xl:grid-cols-2 gap-x-[150px] gap-y-5 px-5'>

                        <div>

                            {/* <small className='text-red mb-3 font-semibold text-sm uppercase'>Give someone the gift of BotGhost Premium</small> */}
                            <div className="text-center lg:!text-left">
                                <h2 className='text-5xl mb-3 text-white font-bold'>Gift BotGhost Premium</h2>
                                <p className='text-lg font-semibold text-white opacity-75'>Gift someone BotGhost premium. Grant someone access to all premium features for one or an unlimited amount of bots. </p>


                            </div>
                            <div className='mt-10 space-y-6 divide-y divide-[#adafca]'>
                                {FAQS.map((faq) => (
                                    <Disclosure as="div" key={1} className="pt-6">
                                        {({ open }) => (
                                            <>
                                                <dt>
                                                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-white opacity-90">
                                                        <span className="text-lg font-semibold leading-7">{faq.question}</span>
                                                        <span className="ml-6 flex h-7 items-center text-red">
                                                            {open ? (
                                                                <MinusSmallIcon className="h-6 w-6 tex-tred" aria-hidden="true" />
                                                            ) : (
                                                                <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                            )}
                                                        </span>
                                                    </Disclosure.Button>
                                                </dt>
                                                <Disclosure.Panel as="dd" className="mt-2 pr-12 opacity-75">
                                                    <p className="text-base leading-7 text-white">{faq.answer}</p>
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                ))}

                            </div>

                        </div>

                        <div className='gap-y-5'>
                            <GiftItem currency={this.state.currency} onClick={() => {
                                var plan = 'botghost_lifetime_gift_v2';
                                if (this.state.currency == "EURO") {
                                    plan = 'botghost_lifetime_gift_euro_v2';
                                }
                                this.checkout(plan, '199.99',);
                            }} icon={1} description="Gift lifetime premium for an unlimited amount of bots." name="Unlimited Lifetime Premium" price={'99.99'} />
                            <GiftItem currency={this.state.currency} onClick={() => {
                                var plan = 'botghost_365day_gift_v2';
                                if (this.state.currency == "EURO") {
                                    plan = 'botghost_365day_gift_euro_v2';
                                }
                                this.checkout(plan, '79.99',);
                            }} icon={2} description="Gift 365 days of premium for a single bot." name="365 Days Premium" price="49.99" />
                            <GiftItem currency={this.state.currency} onClick={() => {
                                var plan = 'botghost_30day_gift_v2';
                                if (this.state.currency == "EURO") {
                                    plan = 'botghost_30day_gift_euro_v2';
                                }
                                this.checkout(plan, '14.95',);
                            }} icon={3} name="30 Days Premium" description="Gift 30 days of premium for a single bot." price="7.99" />


                        </div>

                    </div>
                </section></> : null}

                <section>
                    <SectionHeader
                        title="Claim Gift Code"
                        pretitle="Gifting"
                    ></SectionHeader>
                    <div className="section-content-normal" id="settings-bot-name">
                        <div>
                            <div class="mb-15 ">
                                <h3 style={{ marginBottom: "0px" }}>
                                    Claim a Gift Code
                                </h3>
                                <div className="section-content-header">Claim a premium code on this bot or account.</div>
                                <span className="text-red">{this.state.premium_error}</span>
                            </div>

                            <div class="long-input">
                                <label>Code</label>
                                <input
                                    value={this.state.premium_code}
                                    onChange={(e) => {
                                        this.setState({ premium_code: e.target.value, premium_error: null });
                                        //                   this.handleChange("name", e);
                                        // this.setState({ name: e.target.value, name_error: null });
                                    }}
                                    placeholder="GIFT_6fc07bc4-39be-49ae-917f-0a579354b4a5"
                                    type="text"
                                ></input>
                            </div>
                        </div>

                        <div>
                            <button onClick={() => {
                                this.handlePremiumCodeClaim();
                            }} className="btn btn-red mt-2" disabled={this.state.premium_code == "" || this.state.premium_code_disabled}>Claim</button>
                        </div>

                    </div>
                </section>

                <section>
                    <SectionHeader title="Gifts" pretitle="Gifting" />


                    <div>
                        {this.renderGifts()}
                    </div>

                </section>
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
    user: state.data.user,
    auth: state.auth
});

const mapDispatchToProps = {
    updateProcessing
};

export default connect(mapStateToProps, mapDispatchToProps)(Gifting);