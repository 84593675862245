import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from '../Dashboard';
import ModuleHeader from '../elements/ModuleHeader';
import SectionHeader from '../elements/SectionHeader';
import Select from '../elements/Select';
import CustomVariable from './CustomVariable';
import { setBotSettings, setBotModule, setUsage } from "../../../actions";
import VariablePage from './VariablePage';
import server from '../../../api/server';
import GroupEditor from './SlashCommandsGrouping';

const VARIABLE_TYPES = [
    { label: "Text", value: "text" },
    { label: "Number", value: "number" },
    { label: "User", value: "user" },
];

const SCOPES = [
    { label: "Global", value: "global" },
    { label: "Server Specific", value: "server" },
];

// Add this new component
const VariableBadge = ({ type, dataType }) => {
    const badgeStyle = {
        padding: '4px 8px',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: 'bold',
        textTransform: 'lowercase',
        color: 'white',
        marginRight: '4px',
    };

    const getBadgeColor = (type) => {
        switch (type) {
            case 'text':
                return '#4a90e2'; // Blue
            case 'number':
                return '#50e3c2'; // Teal
            case 'user':
                return '#8b6244'; // Brown
            case 'channel':
                return '#f5a623'; // Orange
            case 'array':
                return '#bd10e0'; // Purple
            case 'object':
                return '#4a4a4a'; // Dark gray
            default:
                return '#9b9b9b'; // Light gray
        }
    };

    return (
        <div style={{ display: 'flex' }}>
            <span style={{...badgeStyle, backgroundColor: getBadgeColor(type)}}>
                {type}
            </span>
            {(type === 'user' || type === 'channel') && dataType && type !== dataType && (
                <span style={{...badgeStyle, backgroundColor: getBadgeColor(dataType)}}>
                    {dataType}
                </span>
            )}
        </div>
    );
};

export class DataStorage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            variable: {
                name: "",
                reference: "",
                type: "text",
                dataType: "text",
                defaultValue: "",
                global: false
            },
            variablePage: null,
            variableIndex: null,
            loading: false,
            filter: "",
            showModal: false,
        };
    }


    nameChange = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32);
        // value = value.replace(" ","-")
        value = value.split(" ").join("_");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        // console.log(value, 'VALUE NOW ');

        return value;
    };

    renderVariableList = () => {
        const { variables, groups } = this.props;
        if (!variables || !variables.variables) return null;

        const filter = this.state.filter.toLowerCase().split(" ").join("-");

        // Group variables
        const groupedVariables = {};
        const ungroupedVariables = [];
        variables.variables.forEach((variable, index) => {
            const name = variable.name.toLowerCase();
            const reference = variable.reference.toLowerCase();

            if (filter === "" || name.includes(filter) || reference.includes(filter)) {
                const groupId = variable.groupId;
                const group = groups.find(g => g.id === groupId);
                
                const variableElement = (
                    <div className='command' key={index} onClick={(e) => {
                        if (e.target.tagName.toLowerCase() !== 'select') {
                            this.setState({
                                variable: variable,
                                variableIndex: index,
                                variablePage: true
                            });
                        }
                    }}>
                        <div className='command-info'>
                            <h3>{variable.name}</h3>
                            <span>{`{BGVAR_${variable.reference}}`}</span>
                        </div>
                        <div className="ml-auto flex items-center">
                            <VariableBadge type={variable.type} dataType={variable.dataType} />
                            <select
                                className="bg-[#40444b] !p-4 !pl-4 text-white opacity-70 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ml-2"
                                value={variable.groupId || ''}
                                onChange={(e) => {
                                    e.stopPropagation(); // Prevent event from bubbling up
                                    const newGroupId = e.target.value;
                                    const updatedVariables = { ...variables };
                                    updatedVariables.variables[index] = {
                                        ...variable,
                                        groupId: newGroupId
                                    };
                                    this.props.setBotModule(
                                        {
                                            module: "variables",
                                            module_data: updatedVariables
                                        }
                                    );
                                }}
                            >
                                <option value="">No Group</option>
                                {groups.map((group) => (
                                    <option key={group.id} value={group.id}>
                                        {group.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                );

                if (groupId && group) {
                    if (!groupedVariables[groupId]) groupedVariables[groupId] = [];
                    groupedVariables[groupId].push(variableElement);
                } else {
                    ungroupedVariables.push(variableElement);
                }
            }
        });

        // Render grouped variables
        const renderedGroups = Object.entries(groupedVariables).map(([groupId, variables]) => {
            const group = groups.find(g => g.id === groupId);
            return (
                <section key={groupId}>
                    <SectionHeader
                        title={group.name}
                        pretitle={group.description}
                    />
                    <div>{variables}</div>
                </section>
            );
        });

        // Render ungrouped variables at the bottom
        if (ungroupedVariables.length > 0) {
            renderedGroups.push(
                <section key="ungrouped">
                    <SectionHeader
                        title="Ungrouped"
                        pretitle="Ungrouped Variables"
                    />
                    <div>{ungroupedVariables}</div>
                </section>
            );
        }

        return renderedGroups;
    };

    renderPercent = (type) => {
        var user = this.props.user;
        if (!("current_usage" in user)) {
            return 0;
        }
        var amount = 0;
        if (type == 'storage') {
            Object.keys(user.storage_by_variable).forEach((key) => {
                amount += user.storage_by_variable[key];
            });
        } else {
            amount = user.current_usage;
            // Object.keys(user.usage_by_variable).forEach((key) => {
            //     amount += user.usage_by_variable[key];
            // });
        }
        var percent = (amount / 5000) * 100;
        return {
            percent: percent.toFixed(0),
            amount: amount
        };
    };

    renderUsageTable = () => {
        var rows = [];
        var user = this.props.user;
        if (!("current_usage" in user)) {
            return;
        }
        var total = 0;
        Object.keys(user.storage_by_variable).forEach(key => {
            var usage = user.usage_by_variable[key] ? user.usage_by_variable[key] : 0;
            total = total + usage;
            rows.push(
                <tr>
                    <td>{`{BGVAR_${key}}`}</td>
                    {/* <td className='text-right'>{storage}</td> */}
                    {/* <td className='text-right'>{usage}</td> */}
                    <td className='text-right'>{usage}</td>
                </tr>
            );
        });

        rows.push(
            <tr>
                <td><b>Total</b></td>
                <td className='text-right'><b>{total}</b></td>
            </tr>
        );

        return rows;
    };

    refreshUsage = async () => {
        this.setState({ loading: true });
        var response = await server.get("/datastorage/usage", {
            bot_id: this.props.bot_id,
            user_id: this.props.user_id
        });
        if (response.data && response.data.success) {
            this.props.setUsage(response.data.usage);
        }
        this.setState({ loading: false });
    };
    checkPremium = () => {
        var user = this.props.user;
        if ('transactions' in user) {
            var flag = false;
            user.transactions.forEach(transaction => {
                if (transaction.active) flag = true;
            });
            return flag;
        } else {
            return false;
        }
    };
    render() {
        return (
            <Dashboard>
                <GroupEditor showModal={this.state.showModal} close={() => { this.setState({ showModal: false }); }}></GroupEditor>
                <ModuleHeader
                    tutorial="https://docs.botghost.com/custom-commands/data-storage"
                    showEnabled={false}
                    title="Data Storage"
                    docs="https://docs.botghost.com/custom-commands/data-storage"
                    description="Create custom variables to store data for your bot"
                    icon="datastorage.png"
                // enabledValue={this.props.opensea_alerts.enabled}
                // change={(value) => {
                //     {
                //         var opensea_alerts = { ...this.props.opensea_alerts };
                //         opensea_alerts.enabled = value;

                //         this.props.setBotModule({
                //             module: "opensea_alerts",
                //             module_data: opensea_alerts,
                //         });
                //     }
                // }}
                ></ModuleHeader>

                {this.state.variablePage == null ?
                    <>
                        {!this.props.premium ? <section>
                            <div style={{
                                display: 'flex'
                            }}>
                                <div><SectionHeader title={`Data Storage Usage`} pretitle="Data Storage" /></div>
                                <div style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}><button style={{ height: "45px" }} className='btn btn-red' onClick={() => {
                                    // this.getData()
                                    this.refreshUsage();
                                }} disabled={this.state.loading}>Refresh</button></div>
                            </div>
                            {!this.props.premium ? <>
                                <div class="progress mt-2" style={{ height: "40px" }}>
                                    {/* <div class="progress-bar bg-info" role="progressbar" style={{ width: `${this.renderPercent("storage").percent}%` }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">Storage ({`${this.renderPercent("storage").percent}`}%)</div> */}
                                    <div class="progress-bar bg-red" role="progressbar" style={{ width: `${this.renderPercent("usage").percent}%` }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">Free Usage ({this.renderPercent("usage").percent}%)</div>
                                    {/* <div class="progress-bar bg-info" role="progressbar" style="width: 20%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div> */}
                                </div>
                                <div className='storage-stats font-semibold mt-2'>

                                    {this.renderPercent("usage").amount}/5000 ({parseInt(this.renderPercent("usage").percent)}%)
                                </div></> : null}

                            <div>
                                <table class="table error-logs-table">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50%" }}>Variable</th>
                                            {/* <th scope="col text-right" className='text-right'>Items Stored</th> */}
                                            {/* <th scope="col text-right" className='text-right'>Item Calls</th> */}
                                            <th scope="col text-right" className='text-right'>Total Usage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderUsageTable()}
                                        {/* {this.renderErrors()} */}
                                    </tbody>
                                </table>
                            </div>
                        </section> : null}

                        <section>
                            <SectionHeader title="New Variable" pretitle="Data Storage" />
                            <div className="section-content-normal">
                                <div class="mb-15">
                                    <h3 style={{ marginBottom: "0px", fontSize: "26px" }}>
                                        New Custom Variable
                                    </h3>
                                    <div className="section-content-header">
                                        Create a new Custom Variable. These can be used in your custom commands and events to store data.
                                    </div>
                                </div>
                                <span style={{ color: "red", fontWeight: "600" }}>
                                    {this.state.error}
                                </span>

                                <hr class="slashcommand-hr" />
                                <div>
                                    <CustomVariable variable={this.state.variable} index="new" editVariable={(variable, index) => {
                                        if (index == "new") {
                                            var variables = { ...this.props.variables };

                                            var variable_settings = variables.variables.find(v => v.reference == variable.reference);
                                            if (variable_settings) {
                                                return;
                                            }


                                            variables.variables.push(variable);
                                            this.props.setBotModule({
                                                module: "variables",
                                                module_data: variables
                                            });
                                            var newVariable = {
                                                name: "",
                                                reference: "",
                                                type: "text",
                                                dataType: "text",
                                                defaultValue: "",
                                                global: false
                                            };
                                            this.setState({
                                                variable: newVariable
                                            });
                                        }
                                    }}></CustomVariable>
                                </div>
                            </div>
                        </section>


                        <section>
                            <div style={{ display: "flex" }}>
                                <div>
                                    <p class="section-pretitle">DATA STORAGE</p>
                                    <h2 class="section-title">Custom Variables</h2>
                                </div>

                                <div style={{
                                    marginLeft: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                }}>
                                    {/* Grouping Button */}
                                    <button className="btn btn-primary h-10 whitespace-nowrap mr-2" onClick={() => { this.setState({ showModal: true }); }}>Variable Group Editor</button>

                                    <div className="long-input" style={{
                                        width: "100%"
                                    }}>
                                        <input style={{
                                            backgroundColor: "#2f3136"
                                        }} type="text" placeholder="Search" value={this.state.filter} onChange={(e) => {
                                            this.setState({ filter: e.target.value });
                                        }}></input>
                                    </div>
                                </div>

                            </div>
                            {this.renderVariableList()}
                        </section>
                    </> : <>
                        <VariablePage back={() => {
                            this.setState({
                                variablePage: null,
                                variableIndex: null,
                                variable: {
                                    name: "",
                                    reference: "",
                                    type: "text",
                                    dataType: "text",
                                    defaultValue: "",
                                    global: false
                                }
                            });
                        }} variable={this.state.variable} index={this.state.variableIndex} />
                    </>}


            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    variables: state.data.bot.commands.variables,
    user: state.data.user,
    premium: state.data.premium,
    groups: state.data.bot.commands.commandGroups || [],
});

const mapDispatchToProps = {
    setBotModule,
    setUsage
};

export default connect(mapStateToProps, mapDispatchToProps)(DataStorage);