import React, { Component } from 'react'
import { connect } from 'react-redux'

export class RippleLoader extends Component {
  render() {
    return (
        <div className='loader-container'>
            <div className="lds-ripple"><div></div><div></div></div>
        </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RippleLoader)