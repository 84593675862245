import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from "../../../history";

export class PremiumModal extends Component {
    modalBackgroundClick = (e) => {
        // console.log("MODAL BACKGROUND CLICK");
        if (e.currentTarget == e.target) {
            this.props.closeModal(e);
        }
    };
    componentDidMount() {
        // console.log("PREMIUM MODAL MOUNTED");
    }



    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} onClick={(e) => { this.modalBackgroundClick(e); }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div class="modal-dialog premium-modal-dialog" role="document">
                        <div class="modal-content premium-modal" style={{ backgroundColor: "#f8f8fb" }}>

                            <div class="modal-header m-modal-header" style={{ 'borderBottom': 'none' }}>
                                <button onClick={(e) => { this.props.closeModal(true); }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div class="modal-body command-edit-body premium-modal-body">
                                <div className="premium-modal-content">
                                    <img className='mb-3' style={{ width: "50px", height: "50px" }} src={process.env.PUBLIC_URL + "/images/logo-red.png"}></img>
                                    <h2 className='text-3xl font-bold'>Premium Subscription required!</h2>
                                    <p className='opacity-70'>A premium subscription is required to use this module or feature.</p>
                                    <div className='mt-15'>
                                        <button className="btn btn-gray" onClick={() => { this.props.closeModal(); }}>Close</button>

                                        <button className="btn btn-red ml-2" onClick={() => { history.push("/dashboard/premium"); }}>Upgrade</button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumModal);
