import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';
import RefreshBotData from '../../elements/RefreshBotData';
import Select from '../../elements/Select';
import RoleAdd from '../../elements/RoleAdd';
import DocsLink from '../../elements/DocsLink';
import { checkIntegrationChild } from '../eventUtils';

export class DeleteEmoji extends Component {
    componentDidMount() {
        if (!this.props.data) {
            var target = { id: "" };
            if (this.props.components['Channel']) {
                target = { self: true };
            }
            this.props.updateElementData(
                {
                    data: {
                        type: "emoji_delete",
                        id: "",
                        validated: true
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.data) {
            this.props.updateElementData(
                {
                    data: {
                        type: "emoji_delete",
                        id: "",
                        validated: true
                    },
                    id: this.props.id
                }
            );
        } else {
            if (("id" in this.props.data && this.props.data.id != "")) {
                if (!this.props.data.validated) {
                    this.updateData("validated", true);
                }
            } else {
                if (this.props.data.validated) {
                    this.updateData("validated", false);
                }
            }
        }
    }

    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData({ data: data, id: this.props.id });
    };

    selectUpdate = (value) => {
        value = JSON.parse(value);
        var target = { ...this.props.data.target };
        target = value;
        this.updateData("target", target);
    };

    renderRoleOptions = () => {
        var options = [];
        options.push({ value: JSON.stringify({ id: "" }), label: "" });
        this.props.options.forEach(option => {
            if (option.type == "role") {
                options.push({ value: JSON.stringify({ variable: option.name }), label: `Role Option: ${option.name}` });
            }
        });
        if (this.props.bot_data || this.props.bot_data.length > 0) {
            this.props.bot_data.forEach((guild, index) => {
                guild.roles.forEach(role => {
                    options.push({ value: JSON.stringify(role), label: `${role.name} - ${guild.name}` });
                });
            });
        }
        return options;
    };

    renderTargetTypes = () => {
        var channel = "Channel the command was run in";
        if (this.props.mode == "event" && this.props.components['Channel']) {
            channel = "The channel.";
        }
        const targetTypes = [
            { label: channel, value: "self", disabled: this.props.components.Interaction || this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
            { label: "Channel option", value: "variable", disabled: !this.props.components.Interaction },
            { label: "Channel ID", value: "id" }
        ];
        return targetTypes;
    };

    targetOnChange = (value) => {
        if (value == "self") {
            var data = { ...this.props.data };
            data.target = {
                self: true
            };
            this.props.updateElementData({ data: data, id: this.props.id });
        } else if (value == "variable") {
            var data = { ...this.props.data };
            data.target = {
                variable: "",
            };
            this.props.updateElementData({ data: data, id: this.props.id });
        } else if (value == "id") {
            var data = { ...this.props.data };
            data.target = {
                id: "",
            };
            this.props.updateElementData({ data: data, id: this.props.id });
        }
    };



    render() {
        if (this.props.data) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Delete Emoji</h4>
                        <p>Delete an emoji in a server.</p>
                    </div>


                    <div className="mb-15 slash-action">
                        <h4>Emoji ID <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#name" /></h4>
                        <span>The id of the emoji to delete.</span>
                        <div class="long-input mt-15">
                            <label>ID</label>
                            <input className={`${this.props.data.id == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                this.updateData("id", e.target.value);
                            }} type="text" value={this.props.data.id} placeholder="" />
                        </div>
                    </div>



                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>

                    <div className="mb-15 slash-action">
                        <h4>Optional Server ID <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#optional-server-id" /></h4>
                        <span>An optional server Id to delete the emoji in. An Id or Variable can be used. Leave this blank to delete the emoji in the server the {this.props.mode == "event" ? "event" : "command"} was triggered in.</span>
                        <div class="long-input mt-15">
                            <label>ID or Variable</label>
                            <input onChange={(e) => {
                                this.updateData("guild_id", e.target.value);
                            }} maxLength={250} type="text" value={this.props.data.guild_id} placeholder="822426820447567872" />
                        </div>
                    </div>


                </div >
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    bot_data: state.data.bot_data,
    saveFailed: state.builder.saveFailed,
    components: state.builder.components,
    beta: state.data.beta
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteEmoji);
