import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';

import TextArea from '../../elements/TextArea';
import Embed from '../../elements/Embed';
import Select from '../../elements/Select';
import RefreshBotData from '../../elements/RefreshBotData';
import { EmojiAdder } from './EmojiAdder';
import VariableTextInput from '../VariableTextInput';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { successToast, errorToast, loadingToast } from "../toast.js";

export class TargetedResponse extends Component {

    constructor(props) {
        super(props);

        this.state = {
            channel_type: "specific"
        };
    }

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "targeted_response",
                        response_options: {
                            response: "",
                            emojis: []
                        },
                        target: { id: "" },
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else if (this.props.data && this.props.data.target) {
            if (this.props.data.target.id_variable) {
                this.setState({ channel_type: "id" });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "targeted_response",
                        response_options: {
                            response: "",
                            emojis: []
                        },
                        target: { id: "" },
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else if (prevProps.selected != this.props.selected && this.props.data && this.props.data.target) {
            //     { value: "specific", label: "Specific Channel" },
            //     { value: "id", label: "Channel ID or Variable" },
            // ]} onChange={(value) => {
            //     this.setState({ channel_type: value });

            if (this.props.data.target.id_variable) {
                this.setState({ channel_type: "id" });
            } else {
                this.setState({ channel_type: "specific" });
            }
        }
        else {
            // VALIDATE

            if (
                (('response' in this.props.data.response_options && this.props.data.response_options.response != "") || ('embed' in this.props.data.response_options && this.props.data.response_options.embed.description != ""))
                && (('id' in this.props.data.target && this.props.data.target.id != "") || ('variable' in this.props.data.target && this.props.data.target.variable != ""))
            ) {
                if (this.props.data.validated == false) {
                    this.updateData("validated", true);
                }
            } else if (this.props.data.validated == true) {
                this.updateData("validated", false);
            }
        }
    }

    nameChange = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "-");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("name", value);
    };

    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    changeType = () => {
        var response_options = { ...this.props.data.response_options };
        if ("response" in this.props.data.response_options) {
            response_options = {
                embed: {
                    title: "",
                    description: "",
                },
                emojis: response_options.emojis || []
            };
        } else {
            response_options = {
                response: "",
                emojis: response_options.emojis || []
            };
        }
        this.updateData("response_options", response_options);

    };

    updateResponseOptions = (key, value) => {
        var response_options = { ...this.props.data.response_options };
        response_options[key] = value;
        this.updateData("response_options", response_options);

    };

    renderOptions = () => {
        var options = [
            // {value:"trigger",label:"The member who triggered the command"}
        ];
        this.props.options.forEach(option => {
            if (option.type == "channel") {
                options.push({ value: { variable: option.name }, label: `Channel Option: ${option.name}` });

            }

        });
        return options;
    };

    selectUpdate = (value) => {
        console.log("selected ");
        value = JSON.parse(value);
        var target = { ...this.props.data.target };
        target = value;
        this.updateData("target", target);
    };

    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Send a message to a Channel</h4>
                        <p>Send either a plain text or embed message to a specific text channel. You can choose between a specific channel or a channel option. </p>
                    </div>

                    <div className="justify-space-between mb-15">
                        <h4 style={{ marginBottom: "0px" }}>Message <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-to-a-channel#message-type"}></DocsLink></h4>

                        <div className="justify-space-between">
                            <p style={{ marginInlineEnd: "12px", marginBottom: "0px" }}>{this.props.data.response_options.embed != undefined ? "Embed" : "Plain Text"}</p>
                            <Toggle value={this.props.data.response_options.response != undefined ? false : true} update={(value) => { this.changeType(); }} type="type"></Toggle>
                        </div>
                    </div>
                    {'response' in this.props.data.response_options ? <TextArea variableEditor={true} slash={true} slash_options={this.props.options} extra_classNames={`${this.props.data.response_options.response == "" && this.props.saveFailed ? "command-required" : ""}`} value={this.props.data.response_options.response} onChange={(value) => { this.updateResponseOptions("response", value); }} /> : <Embed key={this.props.id} variableEditor={true} slash={true} options={this.props.options} commandSaveFailed={this.props.saveFailed} update={(value) => { this.updateResponseOptions("embed", value); }} value={this.props.data.response_options.embed} />}

                    <div className='mb-15'>
                        <h4 style={{ marginBottom: "0px" }}>Reactions<DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-to-a-channel#reactions"}></DocsLink></h4>
                        <div className="section-content-header mb-15">Reactions to add to the message.</div>
                        <EmojiAdder emojis={this.props.data.response_options.emojis || []} update={(newEmojis) => {
                            var data = { ...this.props.data };
                            data.response_options.emojis = newEmojis;
                            this.props.updateElementData(
                                {
                                    data: data,
                                    id: this.props.id
                                }
                            );
                        }} />

                    </div>
                    <hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />

                    <div className="mb-15 slash-action">
                        <h4>Channel Type <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-to-a-channel#channel-type" /></h4>
                        <span>The channel to post the message in.</span>
                        <div className="mt-15">
                            <Select value={this.state.channel_type} options={[
                                { value: "specific", label: "Specific Channel" },
                                { value: "id", label: "Channel ID or Variable" },
                            ]} onChange={(value) => {
                                this.setState({ channel_type: value });
                            }}></Select>
                        </div>
                    </div>

                    {this.state.channel_type == 'specific' || !this.state.channel_type ? <div className="mb-15 slash-action">
                        <h4>Specific Channel <RefreshBotData /><DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-to-a-channel#specific-channel"} /></h4>
                        <span>Select a specific channel to post the message in.</span>
                        <div className="mt-15">
                            <Select saveFailed={this.props.saveFailed} slash_options={this.renderOptions()} value={JSON.stringify(this.props.data.target)} onChange={this.selectUpdate} type="channel" ></Select>
                        </div>
                    </div> :
                        <div className="mb-15 slash-action">
                            <h4>Channel ID or Variable <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-to-a-channel#channel-id-or-variable"></DocsLink></h4>
                            <span>Enter a Channel ID or Variable. Custom variables can be used.</span>
                            <VariableTextInput
                                slash_options={this.props.options}
                                value={this.props.data.target.id}
                                onChange={(value) => {
                                    var target = { ...this.props.data.target };
                                    target.id = value;
                                    target.id_variable = true;

                                    this.updateData("target", target);
                                }}
                                label="ID or Variable"
                            />
                            {/* <div className="long-input mt-15">
                                <label>ID or Variable</label>
                                <input required type="text" value={this.props.data.target.id} onChange={(e) => {
                                    var value = e.target.value;
                                    var target = { ...this.props.data.target };
                                    target.id = value;
                                    target.id_variable = true;

                                    this.updateData("target", target);

                                }}></input>
                            </div> */}
                        </div>}

                    <hr className="slashcommand-hr"></hr>
                    <div className='mt-15'>
                        <h4 style={{ marginBottom: "0px" }}>Optional Variable</h4>
                        <div className="section-content-header mb-15">An optional variable to return the message id of this reply. You can use this variable in other blocks.</div>
                        <div class="long-input mb-15">
                            <label>Variable</label>
                            <input className={`${this.props.data.variable == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                // if (!isNaN(e.target.value) && e.target.value <= 850) {
                                var value = e.target.value;
                                value = value.toLowerCase();
                                value = value.substring(0, 32);
                                value = value.replace(" ", "_");
                                const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
                                value = value.replace(regexForNonAlphaNum, "");

                                this.updateData("variable", value);
                            }} type="text" value={this.props.data.variable ? this.props.data.variable : ""} />
                        </div>

                        {
                            this.props.data.variable ? <div>
                                <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>



                                <h4>Message Id Variable</h4>
                                <p className="mb-15" style={{
                                    color: "#fff",
                                    opacity: "0.7",
                                    fontWeight: 500
                                }}>You can use the variable <span style={{ color: "#f45142", opacity: "1 !important" }}>{`{${this.props.data.variable}}`}</span><FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.variable}}`); successToast("Copied variable to clipboard"); }} /> in other blocks.</p>

                            </div> : null
                        }
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    beta: state.data.beta
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetedResponse);
