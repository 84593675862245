import React, { Component } from 'react'
import { connect } from 'react-redux'
import LongInput from './LongInput'

import { ToastContainer, toast } from 'react-toastify';

export class EmbedBuilderSave extends Component {

    constructor(props) {
        super(props);
        this.state = {
            embedSelect: "",
            newName: ""
        };
    }

    modalBackgroundClick = (e) => {
        if (e.currentTarget == e.target) {
            this.props.closeModal(true)
        }
    }

    componentDidMount() {
        // console.log("PREMIUM MODAL MOUNTED")
    }

    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} onClick={(e) => { this.modalBackgroundClick(e) }} style={{ display: "block" }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div class="modal-dialog premium-modal-dialog" role="document">
                        <ToastContainer />
                        <div class="modal-content premium-modal" style={{ backgroundColor: "#f8f8fb" }}>
                            <div class="modal-body command-edit-body premium-modal-body">
                                <div className="premium-modal-content space-y-2">
                                    <h2 style={{ textAlign: "center", fontSize: "28px" }}>Save Embed</h2>
                                    {!this.props.loadedEmbed && <div className='w-full'>
                                        <h4 className='text-center mb-2'>Give your embed a name</h4>
                                        <LongInput
                                            placeholder={"Awesome Embed"}
                                            value={this.state.newName}
                                            change={(value) => {
                                                this.setState({ newName: value })
                                            }}
                                            style={{ width: "80%" }}
                                            noMB={true}
                                        /></div>}
                                    {this.props.loadedEmbed && <p style={{ textAlign: "center" }}>Since you loaded an embed, you will update the loaded embed.</p>}
                                    <div className='flex flex-col md:flex-row gap-2 w-full justify-center'>
                                        <button className="btn btn-gray " onClick={() => { this.props.closeModal() }}>Cancel</button>

                                        <button className="btn btn-red" onClick={() => {
                                            if (this.state.newName.length > 0 || this.props.loadedEmbed) {
                                                this.props.saveEmbed(this.state.newName)
                                                this.props.closeModal();
                                            } else {
                                                toast.error("Include an embed name", {
                                                    position: "top-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                });
                                            }
                                        }}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(EmbedBuilderSave)
