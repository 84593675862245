import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";

import TextArea from "../../elements/TextArea";
import Embed from "../../elements/Embed";
import Select from "../../elements/Select";
import RefreshBotData from "../../elements/RefreshBotData";
import { EmojiAdder } from "./EmojiAdder";
import { faYoutube, faYoutubeSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VariableTextInput from "../VariableTextInput";
export class KickMemberAction extends Component {
	componentDidMount() {
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "kick_action",
					target: "",
					reason: "",
					validated: false,
					success_handles: false
				},
				id: this.props.id
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data != undefined && this.props.data.success_handles == undefined) {
			this.updateData("success_handles", false);
		}
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "kick_action",
					target: "",
					reason: "",
					validated: false
				},
				id: this.props.id
			});
		} else {
			// VALIDATE

			if (this.props.data.target != "") {
				if (this.props.data.validated == false) {
					this.updateData("validated", true);
				}
			} else if (this.props.data.validated == true) {
				this.updateData("validated", false);
			}
		}
	}

	nameChange = (value) => {
		value = value.toLowerCase();
		value = value.substring(0, 32);
		value = value.replace(" ", "-");
		const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/gu);
		value = value.replace(regexForNonAlphaNum, "");
		this.updateData("name", value);
	};

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	render() {
		if (this.props.data != undefined) {
			return (
				<div>
					<div className="mb-15">
						<h4>Kick a Member</h4>
						<p>Kick a member from the guild.</p>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							User ID <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/kick-a-member#user-id" />
						</h4>
						<span>The user to kick. Either a User ID, User Option, or {`{user_id}`}</span>

						<VariableTextInput
							slash_options={this.props.options}
							required={true}
							label="User"
							placeholder="User ID"
							value={this.props.data.target}
							onChange={(value) => {
								this.updateData("target", value);
							}}
						/>
						{/* <div class="long-input mt-15">
                            <label>User</label>
                            <input className={`${this.props.data.target == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {

                                this.updateData("target", e.target.value);

                            }} type="text" value={this.props.data.target} />
                        </div> */}
					</div>

					<div className="justify-space-between mb-15">
						<h4 style={{ marginBottom: "0px" }}>
							Reason <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/kick-a-member#reason"}></DocsLink>
						</h4>
					</div>
					<TextArea
						variableEditor={true}
						slash={true}
						slash_options={this.props.options}
						value={this.props.data.reason}
						onChange={(value) => {
							this.updateData("reason", value);
						}}
					/>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(KickMemberAction);
