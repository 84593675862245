import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";
import { TextArea } from "../elements/TextArea";
import { Select } from "../elements/Select";
import TwitchEditor from "../elements/TwitchEditor";

import Dashboard from "../Dashboard";
import { setBotModule } from "../../../actions";
import PremiumRedirect from "../elements/PremiumRedirect";

import PremiumSection from "../elements/PremiumSection";
import { copy } from 'copy-anything';
export class Twitch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEdit: null,
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  commandAdd = (value, index) => {

    const twitch = copy(this.props.twitch);

    if (index == undefined) {
      Array.isArray(twitch) ? twitch.push(value) : twitch.streams.push(value);
    } else {
      if (Array.isArray(twitch)) {
        twitch[index] = value;
      } else {
        twitch.streams[index] = value;
      }
    }

    this.props.setBotModule({
      module: "twitch",
      module_data: twitch,
    });

    this.setState({ showEdit: null });
  };

  renderTwitchChannels = () => {
    var channels = [];
    let streams = [];

    if (Array.isArray(this.props.twitch)) {
      streams = this.props.twitch;
    } else {
      streams = this.props.twitch.streams;
    }

    streams.forEach((channel, index) => {
      channels.push(
        <div className="announcement" key={index}>
          <div
            className="announcement-header"
            onClick={(e) => {
              this.setState({ showEdit: index });
            }}
          >
            <div>
              <h3 className="mb-0">{channel.username}</h3>
              <div className="section-content-header">
                {`Posting an announcement in ${channel.channel}`}{" "}
              </div>
            </div>
            <div style={{ marginLeft: "auto" }}>
              {this.state.showEdit == index ? (
                <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i>
              ) : (
                <i
                  style={{ fontSize: "20px" }}
                  class="bi bi-caret-down-fill"
                ></i>
              )}
            </div>
          </div>

          <div>
            {this.state.showEdit == index ? (
              <TwitchEditor
                saveText="Save"
                save={this.commandAdd}
                index={index}
                value={channel}
                showDelete={true}
                delete={this.delete}
              />
            ) : null}
          </div>
        </div>
      );
    });

    return channels;
  };

  delete = (index) => {
    const twitch = copy(this.props.twitch);

    if (Array.isArray(twitch)) {
      twitch.splice(index, 1);
    } else {
      twitch.streams.splice(index, 1);
    }

    this.props.setBotModule({
      module: "twitch",
      module_data: twitch,
    });

    this.setState({ showEdit: null });
  };

  render() {

    return (
      <Dashboard>
        <PremiumRedirect />
        <ModuleHeader
          tutorial="https://docs.botghost.com/social-integrations/twitch.tv"
          docs="https://docs.botghost.com/social-integrations/twitch.tv"
          description="Setup stream notifications so your members never miss when you go online"
          title="Twitch"
          icon="twitch.png"
          showEnabled={Array.isArray(this.props.twitch) ? false : true}
          enabledValue={this.props.twitch?.enabled}
          change={(value) => {

            if (Array.isArray(this.props.twitch)) {
              const twitch = {
                enabled: value,
                streams: this.props.twitch
              }

              this.props.setBotModule({
                module: "twitch",
                module_data: twitch,
              });

              return;

            }

            var twitch = { ...this.props.twitch };
            twitch.enabled = value;

            this.props.setBotModule({
              module: "twitch",
              module_data: twitch,
            });
          }}
        ></ModuleHeader>

        <PremiumSection>
          <SectionHeader
            title="Add Twitch Channel"
            pretitle="Twitch"
          ></SectionHeader>

          <TwitchEditor saveText="Add" save={this.commandAdd}></TwitchEditor>
        </PremiumSection>

        <PremiumSection>
          <SectionHeader
            title="Tracked Twitch Accounts"
            pretitle="Twitch"
          ></SectionHeader>

          {this.renderTwitchChannels()}
        </PremiumSection>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  twitch: state.data.bot.commands.twitch,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Twitch);
