import React, { Component } from "react";
import { connect } from "react-redux";

import Toggle from "../elements/Toggle";

import Select from "../elements/Select";
import { TextArea } from "../elements/TextArea";
import { Embed } from "../elements/Embed";
import RoleAdd from "../elements/RoleAdd";

// import "emoji-mart/css/emoji-mart.css";
import Picker from '@emoji-mart/react';
import { act } from "react-dom/test-utils";
import RefreshBotData from "./RefreshBotData";

const configuration_options = [
  { value: "binding", label: "Permanent" },
  { value: "unique", label: "Unique" },
  { value: "multi", label: "Multi" },
  { value: "reversed", label: "Reversed" },
];

export class ReactionRoleEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message_type: true,
      showEmojiMart: false,
      channel: null,
      guild_id: null,
      name: "",
      text: null,
      embed: {},
      activeRoles: [],
      error: "",
      react_role_config: "binding",
    };
  }

  componentDidMount() {
    if (this.props.config != undefined) {
      const config = { ...this.props.config };
      config.error = "";
      this.setState(config);
    }
    // if(this.props.value != undefined && this.props.value != {}){
    //     const state = {...this.state};
    //     Object.keys(this.props.value).forEach(key =>{
    //         state[key] = this.props.value[key]
    //     })
    // }
  }

  componentDidUpdate(prevProps, prevState) { }

  toggleChange = (value, type) => {
    this.setState({ [type]: value });
  };

  renderEmojiMart = () => {
    if (this.state.showEmojiMart == true) {
      return (
        <div className="react-role-emoji-container">
          <Picker
            onEmojiSelect={this.onEmojiSelect}
            title="Pick your emoji..."
          ></Picker>
        </div>
      );
    }
  };

  onChannelSelect = (channel) => {
    const channelObj = JSON.parse(channel);
    const guild_id = channelObj.guild;
    delete channel.guild;
    this.setState({ channel: JSON.parse(channel), guild_id: guild_id });
  };

  onEmojiSelect = (emoji) => {
    const activeRoles = [...this.state.activeRoles];
    activeRoles.push({
      emoji_name: emoji.native,
      roles: [],
    });
    this.setState({ activeRoles: activeRoles, showEmojiMart: false });
  };

  reactRoleChange = (roles, key) => {
    const activeRoles = [...this.state.activeRoles];
    const role = roles[roles.length - 1];
    const activeRole = activeRoles[key];
    activeRole.roles = [role];

    // console.log(activeRoles)
    this.setState({ activeRoles: activeRoles });
  };

  renderActiveReactRoles = () => {
    const reactroles = [];
    this.state.activeRoles.forEach((role, index) => {
      reactroles.push(
        <div className="react-role mt-15">
          <span className="react-role-emoji">{role.emoji_name}</span>
          <div className="react-role-container">
            <RoleAdd
              items={role.roles}
              update={this.reactRoleChange}
              option={index}
              type="roles"
            ></RoleAdd>
          </div>
        </div>
      );
    });
    return reactroles;
  };

  embedUpdate = (embed) => {
    this.setState({ embed: embed });
  };

  textAreaChange = (value) => {
    this.setState({ text: value });
  };

  embedCheck = () => {
    // console.log(this.state.embed);
    if (
      !("description" in this.state.embed) ||
      this.state.embed.description == ""
    ) {
      this.setState({ error: "Please set content for your Embed" });
      return false;
    } else if (!("title" in this.state.embed) || this.state.embed.title == "") {
      this.setState({ error: "Please set a title for your Embed" });
      return false;
    } else if (this.state) {
    }
    return true;
  };

  roleCheck = () => {
    var flag = true;
    this.state.activeRoles.forEach((activeRole) => {
      if (activeRole.roles.length == 0) {
        flag = false;
      }
    });
    return flag;
  };

  reactRoleConfigChange = (value) => {
    // console.log(value);
    this.setState({ react_role_config: value });
  };

  reset = () => {
    this.setState({
      message_type: true,
      showEmojiMart: false,
      channel: null,
      guild_id: null,
      name: "",
      text: null,
      embed: {},
      activeRoles: [],
      error: "",
      react_role_config: null,
    });
  };
  save = () => {
    if (this.state.name == "") {
      this.setState({ error: "Please set a name for your reaction role" });
    } else if (this.state.channel == null) {
      this.setState({ error: "Please set a channel for your reaction role" });
    } else if (
      this.state.message_type == false &&
      (this.state.text == "" || this.state.text == null)
    ) {
      this.setState({ error: "Please set a message for the reaction role" });
    } else if (this.state.message_type == true && !this.embedCheck()) {
    } else if (this.state.react_role_config == null) {
      this.setState({ error: "Please set a Reaction Role Configuration" });
    } else if (this.state.activeRoles.length == 0) {
      this.setState({ error: "Please add atleast one react role" });
    } else if (!this.roleCheck()) {
      this.setState({
        error: "Please ensure that every react role has atleast one role",
      });
    } else {
      this.props.save(this.state, this.props.index);
      if (this.props.edit != true) {
        this.reset();
      }
    }
  };

  render() {
    return (
      <div className="section-content-normal">
        {this.state.error != "" ? (
          <div className="errors">
            <div class="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          </div>
        ) : null}

        <div className="mb-15">
          <h3 style={{ marginBottom: "0px" }}>Name</h3>
          <div className="section-content-header mb-15">
            A descriptive name for your Reaction Role
          </div>

          <div className="long-input">
            <label>Name</label>
            <input
              disabled={this.props.premium == false}
              type="text"
              value={this.state.name}
              onChange={(e) => {
                this.setState({ name: e.target.value });
              }}
            ></input>
          </div>
        </div>
        <div className="mb-15">
          <h3 style={{ marginBottom: "0px" }}>
            Channel <RefreshBotData />
          </h3>
          <div className="section-content-header mb-15">
            Channel to post new reaction role message
          </div>
          <Select
            disabled={this.props.premium == false ? true : false}
            options={[]}
            requireGuild={true}
            value={JSON.stringify(this.state.channel)}
            type="channel"
            onChange={this.onChannelSelect}
          ></Select>
        </div>

        <div className="mb-15">
          <div className="justify-space-between">
            <h3>Message</h3>
            {/* <div className="section-content-header">Create an announcement when someone joins your server.</div> */}

            <div className="section-content-title justify-space-between">
              <span style={{ marginInlineEnd: "12px" }}>
                {this.state.message_type ? "Embed" : "Plain Text"}
              </span>
              {/* <Toggle value={this.state.message_type} update={this.toggleChange} type="message_type"></Toggle> */}
            </div>
          </div>

          {this.state.message_type == false ? (
            <div>
              <TextArea
                disabled={this.props.premium == false ? true : false}
                value={this.state.text}
                onChange={this.textAreaChange}
                lines="5"
              ></TextArea>
            </div>
          ) : (
            <div>
              <Embed
                disabled={this.props.premium == false ? true : false}
                update={this.embedUpdate}
                value={this.state.embed}
              ></Embed>
            </div>
          )}
        </div>

        <div className="mb-15">
          <div className="justify-space-between">
            <div>
              <h3 style={{ marginBottom: "0px" }}>Reaction Roles</h3>
              <div className="section-content-header mb-15">
                Add a reaction and then choose one or more roles to add/remove
              </div>
            </div>

            <div className="" style={{ position: "relative" }}>
              <button
                disabled={this.props.premium == false ? true : false}
                className="btn btn-red"
                onClick={(e) => {
                  this.setState({ showEmojiMart: !this.state.showEmojiMart });
                }}
              >
                Add Reaction
              </button>
              {this.renderEmojiMart()}
            </div>
          </div>

          <div>{this.renderActiveReactRoles()}</div>
        </div>

        <div className="mb-15">
          <div className="">
            <h3 style={{ marginBottom: "0px" }}>Reaction Role Configuration</h3>
            <div className="section-content-header mb-15">
              The configuration of the reaction role
            </div>
          </div>
          <Select
            disabled={this.props.premium == false ? true : false}
            value={this.state.react_role_config}
            onChange={this.reactRoleConfigChange}
            options={configuration_options}
          ></Select>
        </div>

        <div class="commandAdd-buttons">
          <button
            disabled={this.props.premium == false ? true : false}
            class="btn btn-gray"
            onClick={this.reset}
          >
            Reset
          </button>
          {this.props.showDelete ? (
            <button
              disabled={this.props.premium == false ? true : false}
              class="btn btn-gray"
              onClick={() => {
                this.props.delete(this.props.index);
              }}
            >
              Delete
            </button>
          ) : null}
          <button
            disabled={this.props.premium == false ? true : false}
            class="btn btn-red"
            onClick={this.save}
          >
            {this.props.saveText || "Add"}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bot_data: state.data.bot_data,
  premium: state.data.premium,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReactionRoleEditor);
