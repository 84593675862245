import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";
import Select from "../../elements/Select";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { ThreadAutoArchiveDuration } from "discord-api-types/v10";
import { checkIntegrationChild } from "../eventUtils";
import VariableTextInput from "../VariableTextInput";

const THREAD_TYPES = [
	{ label: "Public Thread", value: "GUILD_PUBLIC_THREAD" },
	{ label: "Private Thread", value: "GUILD_PRIVATE_THREAD" },
	{ label: "Announcement Thread", value: "GUILD_NEWS_THREAD" }
];

const AUTO_ARCHIVE_DURATION = [
	{ label: "One Hour", value: ThreadAutoArchiveDuration.OneHour },
	{ label: "One Day", value: ThreadAutoArchiveDuration.OneDay },
	{ label: "Three Days", value: ThreadAutoArchiveDuration.ThreeDays },
	{ label: "One Week", value: ThreadAutoArchiveDuration.OneWeek }
];

export class ThreadCreateAction extends Component {
	DEFAULT_THREAD_OBJECT = {
		type: "thread_create",
		name: "",
		autoArchiveDuration: ThreadAutoArchiveDuration.ThreeDays,
		reason: "",
		rateLimitPerUser: 0,
		startMessage: "",
		threadType: "GUILD_PUBLIC_THREAD",
		invitable: false,
		validated: false,
		success_handles: false,
		subType: this.props.type
	};

	componentDidMount() {
		if (this.props.data === undefined) {
			var target = { id: "" };
			if (this.props.components["Channel"]) {
				target = { self: true };
			}
			this.props.updateElementData({
				data: { ...this.DEFAULT_THREAD_OBJECT, target: target, variable: `created_thread_${s4()}` },
				id: this.props.id
			});
		}
	}

	componentDidUpdate() {
		if (this.props.data != undefined && this.props.data.success_handles == undefined) {
			this.updateData("success_handles", false);
		}
		if (this.props.data === undefined) {
			var target = { id: "" };
			if (this.props.components["Channel"]) {
				target = { self: true };
			}
			this.props.updateElementData({
				data: { ...this.DEFAULT_THREAD_OBJECT, target: target, variable: `created_thread_${s4()}` },
				id: this.props.id
			});
		} else {
			if (!this.props.data.variable) {
				this.updateData("variable", `created_thread_${s4()}`);
			}

			// console.log("here", this.props.data);
			// validate
			if (this.props.data.name !== "") {
				if (("variable" in this.props.data.target && this.props.data.target.variable) || ("id" in this.props.data.target && this.props.data.target.id) || "self" in this.props.data.target) {
					if (this.props.data.validated === false) {
						this.updateData("validated", true);
					}
				} else {
					if (this.props.data.validated === true) {
						this.updateData("validated", false);
					}
				}
			} else {
				if (this.props.data.validated === true) {
					this.updateData("validated", false);
				}
			}
		}
	}

	type = this.props.type === "thread" ? "thread" : "forum post";

	renderURL = (section) => {
		return `https://docs.botghost.com/custom-commands-and-events/actions/create-a-thread${section ? `#${section}` : ""}`;
	};

	renderOptions = () => {
		var options = [{ value: "", label: "" }];
		this.props.options.forEach((option) => {
			if (option.type === "channel") options.push({ value: `{option_${option.name}}`, label: option.name });
		});

		if (options.length === 1) {
			options = [{ value: ``, label: "Please add a channel option", disabled: true }];
		}

		return options;
	};

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	renderTargetTypes = () => {
		var channel = "Channel the command was run in";
		if (this.props.mode == "event" && this.props.components["Channel"]) {
			channel = "The channel.";
		}
		const targetTypes = [
			{ label: channel, value: "self", disabled: this.props.components.Interaction || this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ label: "Channel ID", value: "id" },
			{ label: "Channel option", value: "variable", disabled: !this.props.components.Interaction }
		];

		return targetTypes;
	};

	targetOnChange = (value) => {
		if (value == "self") {
			var data = { ...this.props.data };
			data.target = {
				self: true
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		} else if (value == "variable") {
			var data = { ...this.props.data };
			data.target = {
				variable: ""
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		} else if (value == "id") {
			var data = { ...this.props.data };
			data.target = {
				id: ""
			};
			this.props.updateElementData({ data: data, id: this.props.id });
		}
	};

	render() {
		if (this.props.data != undefined) {
			return (
				<>
					<div className="mb-15">
						<h4>
							Create a {this.props.type === "thread" ? "Thread" : "Forum Post"} <DocsLink location={this.renderURL("")} />
						</h4>
						<p>Create a {this.type} in the channel that you specified.</p>
						<p>
							You can use the{" "}
							<strong style={{ color: "#f45142" }}>
								{`{${this.props.data.variable}}`}{" "}
								<FontAwesomeIcon
									icon={faClipboard}
									fixedWidth
									className="text-[#9d9b9b] text-sm hover:text-red hover:cursor-pointer"
									onClick={() => {
										navigator.clipboard.writeText(`{${this.props.data.variable}}`);
										toast.success("Copied variable to clipboard");
									}}
								/>
							</strong>{" "}
							variable in other actions and conditions.
						</p>
					</div>

					{/* Targetting */}
					<div className="mb-15 slash-action">
						<h4>
							Channel <DocsLink location={this.renderURL("channel")} />
						</h4>
						<span>Which channel do you want to create the {this.type} in.</span>
						<Select
							value={"self" in this.props.data.target ? "self" : "variable" in this.props.data.target ? "variable" : "id" in this.props.data.target ? "id" : ""}
							onChange={(value) => {
								this.targetOnChange(value);
							}}
							options={this.renderTargetTypes()}
						></Select>
					</div>

					{"variable" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>
								Channel Option <DocsLink location={this.renderURL("channel-option")} />
							</h4>
							<span>The channel option to create the {this.type} in.</span>
							<Select
								saveFailed={(this.props.saveFailed && this.props.data.target.variable == "") || this.renderOptions().length === 1}
								value={this.props.data.target.variable}
								onChange={(value) => {
									var data = { ...this.props.data };
									data.target = {
										variable: value
									};
									this.props.updateElementData({ data: data, id: this.props.id });
								}}
								options={this.renderOptions()}
							/>
						</div>
					)}

					{"id" in this.props.data.target && (
						<div className="mb-15 slash-action">
							<h4>
								Channel ID <DocsLink location={this.renderURL("channel-id")} />
							</h4>
							<span>The channel ID you want to create the {this.type} in (you can use variables).</span>
							<VariableTextInput
								required={true}
								slash_options={this.props.options}
								label="ID"
								placeholder="911634771019444224"
								value={this.props.data.target.id}
								onChange={(value) => {
									var target = { ...this.props.data.target };
									target.id = value;
									this.updateData("target", target);
								}}
							/>

							{/* <div className="long-input mt-15">
                            <label>ID</label>
                            <input className={this.props.saveFailed && this.props.data.target.variable == "" ? "command-required" : ""} onChange={(e) => {
                                var data = { ...this.props.data };
                                data.target = {
                                    id: e.target.value
                                };
                                this.props.updateElementData({ data: data, id: this.props.id });
                            }} type="text" placeholder="911634771019444224" value={this.props.data.target.id} />
                        </div> */}
						</div>
					)}

					{("id" in this.props.data.target || "variable" in this.props.data.target) && (
						<div className="mb-15 slash-action">
							<h4>
								Start Message ID <DocsLink location={this.renderURL("channel-id")} />
							</h4>
							<span>The message ID you want to attach the {this.type} too.</span>
							<VariableTextInput
								required={true}
								slash_options={this.props.options}
								label="Start Message ID"
								placeholder="1196538488233935039"
								value={this.props.data.startMessage}
								onChange={(value) => {
									this.updateData("startMessage", value);
								}}
							/>
							{/* <div class="long-input mt-15">
                            <label>Start Message ID</label>
                            <input className={`${this.props.data.startMessage == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                this.updateData("startMessage", e.target.value);
                            }} maxLength={50} type="text" value={this.props.data.startMessage} placeholder='1196538488233935039' />
                        </div> */}
						</div>
					)}
					{/* End of Targetting */}

					<div className="mb-15 slash-action">
						<h4>
							Name <DocsLink location={this.renderURL("name")} />
						</h4>
						<span>The name of the new {this.type}.</span>
						<VariableTextInput
							required={true}
							slash_options={this.props.options}
							label="Name"
							placeholder="BotGhost is Cool"
							value={this.props.data.name}
							onChange={(value) => {
								this.updateData("name", value);
							}}
						/>
						{/* <div class="long-input mt-15">
                            <label>Name</label>
                            <input className={`${this.props.data.name == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                this.updateData("name", e.target.value);
                            }} maxLength={50} type="text" value={this.props.data.name} placeholder='BotGhost is Cool' />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Type <DocsLink location={this.renderURL("type")} />
						</h4>
						<span>The type of the new {this.type}.</span>
						<div className="mt-15">
							<Select
								value={this.props.data.threadType}
								options={THREAD_TYPES}
								onChange={(value) => {
									this.updateData("threadType", value);
								}}
							/>
						</div>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Auto Archive <DocsLink location={this.renderURL("auto-archive")} />
						</h4>
						<span>The auto archive duration for the {this.type}.</span>
						<div className="mt-15">
							<Select
								value={this.props.data.autoArchiveDuration}
								options={AUTO_ARCHIVE_DURATION}
								onChange={(value) => {
									this.updateData("autoArchiveDuration", value);
								}}
							/>
						</div>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Reason <DocsLink location={this.renderURL("reason")} />
						</h4>
						<span>The reason you are creating a {this.type}.</span>
						<VariableTextInput
							required={true}
							slash_options={this.props.options}
							label="Reason"
							placeholder="I wanted a thread"
							value={this.props.data.reason}
							onChange={(value) => {
								this.updateData("reason", value);
							}}
						/>
						{/* <div class="long-input mt-15">
                            <label>Reason</label>
                            <input className={`${this.props.data.reason == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                this.updateData("reason", e.target.value);
                            }} maxLength={50} type="text" value={this.props.data.reason} placeholder='I wanted a thread' />
                        </div> */}
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Slowmode <DocsLink location={this.renderURL("slowmode")} />
						</h4>
						<span>An optional rate limit (in seconds) that you want to set the created {this.type} to.</span>
						<VariableTextInput
							required={true}
							slash_options={this.props.options}
							label="Slowmode"
							placeholder="3"
							value={this.props.data.rateLimitPerUser}
							onChange={(value) => {
								this.updateData("rateLimitPerUser", value);
							}}
						/>
						{/* <div class="long-input mt-15">
                            <label>Slowmode</label>
                            <input onChange={(e) => {
                                this.updateData("rateLimitPerUser", e.target.value);
                            }} type="number" min={0} max={21600} value={this.props.data.rateLimitPerUser} placeholder={3} />
                        </div> */}
					</div>

					{this.props.data.threadType === "GUILD_PRIVATE_THREAD" && (
						<div className="mb-15 slash-action">
							<h4>
								Moderators Invite <DocsLink location={this.renderURL("moderators-invite")} />
							</h4>
							<span>Whether non-moderators can invite other users to the {this.type}</span>
							<Toggle
								value={this.props.data.invitable}
								update={(value) => {
									this.updateData("invitable", value);
								}}
							/>
						</div>
					)}
				</>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	bot_data: state.data.bot_data,
	saveFailed: state.builder.saveFailed,
	beta: state.data.beta,
	components: state.builder.components
});
const mapDispatchToProps = {
	updateElementData
};

let s4 = () => {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
};

export default connect(mapStateToProps, mapDispatchToProps)(ThreadCreateAction);
