import React, { Component } from "react";
import { connect } from "react-redux";

import ReactFlow, {
	Background,
	ReactFlowProvider,
	addEdge,
	// removeElements,
	applyNodeChanges,
	Handle,
	isEdge,
	ControlButton,
	isNode,
	// useZoomPanHelper,
	useReactFlow,
	getBezierPath,
	getMarkerEnd,
	getEdgeCenter,
	Controls,
	getIncomers,
	setEdges,
	getOutgoers,
	getConnectedEdges
} from "react-flow-renderer";
import dagre from "dagre";

import ReactGA from "react-ga";
import { isBrowser } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCopy, faExclamation, faGripVertical, faHammer, faHandPointer, faLink, faPlusCircle, faQuestion, faRandom, faSave, faSlash, faSort, faTimes, faTrash, faTrashAlt, faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";


import { faCode, faCodeBranch, faEnvelope, faEye, faHashtag, faMouse, faMousePointer, faPaperPlane, faReply, faReplyAll, faSortNumericDown, faTextHeight, faUser, faUserMinus, faUserPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";

import { setElements, setSelected, updateNode, updateElementData } from "../../../actions";
import ZoomPanHelper from "./ZoomPanHelper";
import splitElements from "./splitElements";
import { checkAdvancedMessageChild, checkErrorChild, checkErrorSuccessHandles, checkSplitChild, layoutElements } from "./eventUtils";
import { CUSTOM_EVENTS } from "../../../variables";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import KeyEventsHandler from "./KeyEventsHandler";

const ICONS = [faCode, faCodeBranch, faEnvelope, faEye, faHashtag, faMouse, faMousePointer, faPaperPlane, faReply, faReplyAll, faSortNumericDown, faTextHeight, faUser, faUserMinus, faUserPlus, faUsers];

function withMyHook(Component) {
	return function WrappedComponent(props) {
		const { zoomTo } = useReactFlow();
		return <Component {...props} zoomTo={zoomTo} />;
	};
}

export class CommandBuilderCanvas extends Component {
	constructor(props) {
		super(props);

		this.state = {
			reactFlowInstance: null,
			showSaveTemplateModal: false,
			selected: null,
			handleId: null,
			nodeHandleID: null,
			selectedElements: {
				nodes: [],
				edges: []
			},
			handleType: null,
			elements: [
				{
					id: "root",
					type: "root",
					data: {
						title: "Number",
						description: "text"
					},
					// target:"3",
					draggable: false,
					position: { x: 250, y: 250 }
				}
			],
			collapsedNodes: new Set()
		};

		this.reactFlowWrapper = React.createRef();
		this.setCenter = useReactFlow;
	}

	componentDidMount() {
		document.addEventListener("keydown", this.onKeyPress, false);
	}
	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKeyPress, false);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.elements.length != this.props.elements.length) {
			var elements = [...this.props.elements];
			elements.forEach((element) => {
				if (element.data?.data?.type == "advanced_message") {
					var children = getOutgoers(element, splitElements(elements).nodes, splitElements(elements).edges);
					var components_length = 0;
					children.forEach((child) => {
						if (child.type == "advMessageSelectMenu" || child.type == "advMessageButton") {
							components_length++;
						}
					});

					if (components_length == 0 && element.data?.data?.messageType == "advanced") {
						var data = { ...element.data.data };
						data.messageType = "basic";
						this.props.updateElementData({
							data: data,
							id: element.id
						});
					} else if (components_length > 0 && element.data?.data?.messageType == "basic") {
						var data = { ...element.data.data };
						data.messageType = "advanced";
						console.log(data, "DATA TYPE HERE");
						this.props.updateElementData({
							data: data,
							id: element.id
						});
						// this.props.updateElementData(element.id, data);
					}

					// children.forEach(child => {
					//   if (child.type == 'advMessageButton' || child.type == 'advMessageSelectMenu') {
					//     state = 2;
					//   }
				}
			});
			// var element = elements.find(element => element.id == id);
			// var children = getOutgoers(element, splitElements(elements).nodes, splitElements(elements).edges);
			// // Check if any of the children are buttons or select menus
			// children.forEach(child => {
			//   if (child.type == 'advMessageButton' || child.type == 'advMessageSelectMenu') {
			//     state = 2;
			//   }
			// });
			//
		}
	}

	deleteMulti = () => {
		var nodes = this.state.selectedElements.nodes;
		var edges = splitElements(this.props.elements).edges;
		nodes.forEach((node) => {
			var element = this.props.elements.find((element) => element.id == node.id);
			if (!element || element.id == "root" || element.id.includes("else") || element.id == "error_handler") {
				return;
			}
			var elementsDel = [element];
			if (node.id.includes("condition")) {
				var elementChildren = getOutgoers(element, splitElements(this.props.elements).nodes, edges);
				// console.log(elementChildren, 'CONDITION CHILDREN'); elementsDel = elementsDel.concat(elementChildren);
			}

			var connectedEdges = getConnectedEdges(elementsDel, splitElements(this.props.elements).edges);

			// HERE
			elementsDel.forEach((eleDel) => {
				var elements = this.deleteElements(eleDel.id);
				this.props.setElements(elements);
			});

			connectedEdges.forEach((eleDel) => {
				var elements = this.deleteElements(eleDel.id);
				this.props.setElements(elements);
			});

			this.props.setSelected(null);
		});
	};

	CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, data, arrowHeadType, markerEndId }) => {
		const edgePath = getBezierPath({
			sourceX,
			sourceY,
			sourcePosition,
			targetX,
			targetY,
			targetPosition
		});
		const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
		const [edgeCenterX, edgeCenterY] = getEdgeCenter({
			sourceX,
			sourceY,
			targetX,
			targetY
		});

		const foreignObjectSize = 40;

		return (
			<>
				<path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
				<foreignObject width={foreignObjectSize} height={foreignObjectSize} x={edgeCenterX - foreignObjectSize / 2} y={edgeCenterY - foreignObjectSize / 2} className="edgebutton-foreignobject" requiredExtensions="http://www.w3.org/1999/xhtml">
					<div className="edgebutton-container">
						<button className={`edgebutton ${this.props.module_id != null && !this.props.premium ? "cursor-not-allowed" : ""}`} onClick={(event) => this.onEdgeClick(event, id)} disabled={this.props.module_id != null && !this.props.premium}>
							×
						</button>
					</div>
				</foreignObject>
			</>
		);
	};

	onEdgeClick = (event, id) => {
		var elements = this.deleteElements(id);
		this.props.setElements(elements);
	};

	deleteElements = (id) => {
		var elements = [...this.props.elements];
		if (this.props.module_id != null && !this.props.premium) {
			return elements;
		}
		var index = this.props.elements.findIndex((element) => element.id == id);

		elements.splice(index, 1);

		//check if duplicated
		return elements;
	};

	OptionNode = ({ id, data }) => (
		// <>
		//     <div onClick={(e) =>{this.props.setSelected(id)}} className={`custom-node option-node ${this.props.selected == id ? "selected":null}`}>
		//       <span className="title">{data.title} Option</span>
		//       <span className="description">{data.description}</span>
		//   </div>
		//   <Handle type="source" position="bottom" isValidConnection = {(connection) => connection.target === "root"} />
		// </>

		<>
			<div
				id={id}
				onTouchStart={(e) => {
					this.props.setSelected(id);
				}}
				onClick={(e) => {
					this.props.setSelected(id);
				}}
				style={{ position: "relative" }}
				className={`action-node option-node ${this.props.selected == id ? "selected" : null}`}
			>
				<div className="inner" style={{ display: "flex" }}>
					{data.data == undefined || data.data.validated == false ? (
						<div className="node-invalid">
							<FontAwesomeIcon icon={faExclamation} />
						</div>
					) : null}

					<div className="object-icon object-icon-option">
						<FontAwesomeIcon icon={data.node_options.icon} />
					</div>

					<div className="object-info object-info-option">
						<span className="object-title">{data.data != undefined && data.data.name != "" ? data.data.name : data.node_options.title}</span>
						<span className="object-description">{data.data != undefined && data.data.description != "" ? data.data.description : data.node_options.description}</span>
					</div>
				</div>
			</div>
			<Handle type="source" position="bottom" style={{ backgroundColor: "#8d0eab", border: "none", height: "10px", width: "10px" }} />
		</>
	);

	renderEventDescription = (type) => {
		var description = CUSTOM_EVENTS.find((e) => e.value == type);
		if (description != undefined) {
			return description.label;
		} else {
			return null;
		}
		// return description;
	};

	RootNode = ({ id, data }) => (
		<>
			{this.props.mode != "event" ? (
				<Handle
					className={` `}
					style={{ backgroundColor: "#8d0eab", border: "none", height: "20px", width: "20px", top: "-8px" }}
					type="target"
					position="top"
					isValidConnection={(connection) => {
						return false;
					}}
				/>
			) : null}
			{/* <Handle style={{ backgroundColor: "#f44336", border: "none", height: "20px", width: "20px", right: "-8px" }} id="error" type="source" position="right" isValidConnection={(connection) => {

        return false;
      }} /> */}
			<div
				id={id}
				onTouchStart={(e) => {
					this.props.setSelected(id);
				}}
				onClick={(e) => {
					this.props.setSelected(id);
				}}
				className={`action-node root-action-node ${this.props.highlightAction == "root" ? "highlight-action" : null} ${this.props.selected == id ? "selected" : null}`}
				style={{ width: "600px" }}
			>
				<div id="root-inner" className="inner" style={{ display: "flex" }}>
					<div className="object-icon object-icon-root">
						<FontAwesomeIcon icon={faHammer} />
					</div>

					{this.props.mode == "event" ? (
						<div className="object-info object-info-action">
							<span className="object-title" style={{ fontSize: "30px", lineHeight: "30px" }}>
								{this.renderEventDescription(data.data.type)}
							</span>
							<span className="object-description" style={{ fontSize: "20px", lineHeight: "20px" }}>
								Drag and drop different <span style={{ color: "rgb(53, 141, 235)" }}>actions</span> and <span style={{ color: "#28a745" }}>conditions</span> to add them to your custom event. Connect the corresponding colors to create your custom event.
							</span>
						</div>
					) : (
						<div className="object-info object-info-action">
							<span className="object-title" style={{ fontSize: "30px", lineHeight: "30px" }}>
								/{data.title}
							</span>
							<span className="object-description" style={{ fontSize: "20px", lineHeight: "20px" }}>
								Drag and drop different <span style={{ color: "rgb(141, 14, 171)" }}>options</span>, <span style={{ color: "rgb(53, 141, 235)" }}>actions</span> and <span style={{ color: "#28a745" }}>conditions</span> to add them to your command. Connect the corresponding colors to create your command.
							</span>
						</div>
					)}
				</div>
			</div>
			<Handle className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} id="action" type="source" style={{ backgroundColor: "#358deb", border: "none", bottom: this.renderHandlePositions(true, "source", id), height: `${this.renderHandleSizes(true, "source", id)}`, width: `${this.renderHandleSizes(true, "source", id)}` }} position="bottom" />
		</>
	);

	ButtonNode = ({ id, data }) => (
		<>
			<Handle isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", border: "none", height: "10px", width: "10px" }} id={"button"} type="target" position="top" />
			<div
				id={id}
				onTouchStart={(e) => {
					this.props.setSelected(id);
				}}
				onClick={(e) => {
					this.props.setSelected(id);
				}}
				className={`button-node discord-button-${data.data.style.toLowerCase()} ${this.props.selected == id ? "selected" : null}`}
			>
				<div className="inner">
					<span>{data.data.label || "Button"}</span>
				</div>
			</div>
			{data.data.style != "LINK" ? <Handle isValidConnection={this.actionConnection} id="action" className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} style={{ backgroundColor: "#358deb", bottom: this.renderHandlePositions(true, "source", id), height: `${this.renderHandleSizes(true, "source", id)}`, width: `${this.renderHandleSizes(true, "source", id)}`, border: "none" }} type="source" position="bottom" /> : null}
		</>
	);

	renderLoopData = (id, type) => {
		if (type == "loop_actions") {
			var element = this.props.elements.find((element) => element.id == id);
			if (!element) return null;
			var edges = splitElements(this.props.elements).edges;

			var parentLoop = getIncomers(element, splitElements(this.props.elements).nodes, edges);
			var times = 0;
			if (parentLoop[0] && parentLoop[0].data) {
				times = parentLoop[0].data.data.count;
			}
			return (
				<>
					<span className="select-menu-item-label" style={{ fontWeight: "600" }}>
						Loop {times} times
					</span>
					<span className="select-menu-description">Connected Actions & Conditions will be executed {times} times.</span>
				</>
			);
		} else {
			return (
				<>
					<span className="select-menu-item-label" style={{ fontWeight: "600" }}>
						After loop
					</span>
					<span className="select-menu-description">Run when the loop has finished</span>
				</>
			);
		}
	};

	renderAdvMessageData = (id, type) => {
		if (type == "advanced_message_components") {
			return (
				<div className="flex flex-col justify-center">
					<span className="select-menu-item-label" style={{ fontWeight: "600" }}>
						Message Components
					</span>
					<span className="select-menu-description">Click the buttons below to add Message Components</span>
				</div>
			);
		} else {
			return (
				<div className="flex flex-col justify-center">
					<span className="select-menu-item-label" style={{ fontWeight: "600" }}>
						{" "}
						After Message Actions
					</span>
					<span className="select-menu-description">Actions to run after the message has been sent</span>
				</div>
			);
		}
	};

	renderAdvancedMessageSelectMenuType = (data) => {
		if (data?.data?.menu_type == "basic") {
			return "Basic Select Menu";
		} else if (data?.data?.menu_type == "role") {
			return "Role Select Menu";
		} else if (data?.data?.menu_type == "channel") {
			return "Channel Select Menu";
		} else if (data?.data?.menu_type == "user") {
			return "User Select Menu";
		} else if (data?.data?.menu_type == "mentionable") {
			return "Mentionable Select Menu";
		}
	};

	renderAdvancedButtonStyle = (data) => {
		// { value: "PRIMARY", label: "Blue" },
		// { value: "SECONDARY", label: "Gray" },
		// { value: "SUCCESS", label: "Green" },
		// { value: "DANGER", label: "Red" },
		// { value: "LINK", label: "Link" }
		if (!data?.data?.style) return "bg-blue";

		if (data?.data?.style == "PRIMARY") return "bg-blue";
		if (data?.data?.style == "SECONDARY") return "bg-[#4f545c]";
		if (data?.data?.style == "SUCCESS") return "bg-green";
		if (data?.data?.style == "DANGER") return "bg-red";
		if (data?.data?.style == "LINK") return "bg-[#4f545c]";
	};
	AdvancedMessageButton = ({ id, data }) => (
		<>
			<Handle isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", border: "none", height: "10px", width: "10px" }} id={"selectmenu"} type="target" position="top" />
			<div
				id={id}
				onTouchStart={(e) => {
					this.props.setSelected(id);
				}}
				onClick={(e) => {
					this.props.setSelected(id);
				}}
				className={`action-node p-[10px] option-node ${this.props.highlightAction == id ? "highlight-action" : null} ${this.props.selected == id ? "selected" : null}`}
			>
				<div className="inner" style={{ display: "flex" }}>
					{data.data == undefined || data.data.validated == false ? (
						<div className="node-invalid">
							<FontAwesomeIcon icon={faExclamation} />
						</div>
					) : null}

					{data.data == undefined || data.data.linked == false ? (
						<div className="node-invalid" style={{ right: `${data.data != undefined && data.data.validated == false ? "10px" : ""}` }}>
							<FontAwesomeIcon icon={faLink} />
						</div>
					) : null}
					<div className={`object-icon object-icon-action ${this.renderAdvancedButtonStyle(data)}`}>
						<FontAwesomeIcon icon={faHandPointer} />
					</div>

					<div className="object-info object-info-action">
						<span className="object-title text-sm"><span className="mr-1">{data?.data?.emoji_id ? data?.data?.emoji_id : ""}</span>{data?.data?.label ? data?.data?.label : !data?.data?.emoji_id ? "Button" : ""}</span>
						<span className="object-description text-xs">Button</span>
					</div>
				</div>
			</div>
			{data?.data?.style !== "LINK" && (
				<Handle
					isValidConnection={this.actionConnection}
					className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`}
					id="action"
					style={{
						backgroundColor: "#358deb",
						bottom: this.renderHandlePositions(true, "source", id),
						height: `${this.renderHandleSizes(true, "source", id)}`,
						width: `${this.renderHandleSizes(true, "source", id)}`,
						border: "none"
					}}
					type="source"
					position="bottom"
				/>
			)}
		</>
	);

	AdvancedMessageSelectMenu = ({ id, data }) => (
		<>
			<Handle isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", border: "none", height: "10px", width: "10px" }} id={"selectmenu"} type="target" position="top" />
			<div
				id={id}
				onTouchStart={(e) => {
					this.props.setSelected(id);
				}}
				onClick={(e) => {
					this.props.setSelected(id);
				}}
				className={`action-node p-[10px] option-node ${this.props.highlightAction == id ? "highlight-action" : null} ${this.props.selected == id ? "selected" : null}`}
			>
				<div className="inner" style={{ display: "flex" }}>
					{data.data == undefined || data.data.validated == false ? (
						<div className="node-invalid">
							<FontAwesomeIcon icon={faExclamation} />
						</div>
					) : null}

					{data.data == undefined || data.data.linked == false ? (
						<div className="node-invalid" style={{ right: `${data.data != undefined && data.data.validated == false ? "10px" : ""}` }}>
							<FontAwesomeIcon icon={faLink} />
						</div>
					) : null}
					<div className="object-icon object-icon-action bg-darkGray">
						<FontAwesomeIcon icon={faBars} />
					</div>

					<div className="object-info object-info-action">
						<span className="object-title text-sm">{this.renderAdvancedMessageSelectMenuType(data)}</span>
						<span className="object-description text-xs">Select Menu</span>
					</div>
				</div>
			</div>
			<Handle isValidConnection={this.actionConnection} className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} id="action" style={{ backgroundColor: "#358deb", bottom: this.renderHandlePositions(true, "source", id), height: `${this.renderHandleSizes(true, "source", id)}`, width: `${this.renderHandleSizes(true, "source", id)}`, border: "none" }} type="source" position="bottom" />
		</>
	);

	AdvancedMessageChildNode = ({ id, data }) => (
		<div className="min-w-[400px]">
			<Handle isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", border: "none", height: "10px", width: "10px" }} id={"selectmenu"} type="target" position="top" />
			<div
				id={id}
				onTouchStart={(e) => {
					this.props.setSelected(id);
				}}
				onClick={(e) => { }}
				className={`menu-option-node ${this.props.selected == id ? "selected" : null}`}
			>
				<div className="inner">
					<div className="select-menu-item-info">
						{/* ADVANCED MESSAGE */}
						{this.renderAdvMessageData(id, data.type)}
					</div>
				</div>
			</div>
			{data.type == "advanced_message_components" ? (
				<>
					<button
						className="absolute border-white border-opacity-50 btn btn-red !bg-[#2f3136] flex flex-row justify-center !p-[3px] top-[60px]"
						onClick={() => {
							this.addToMessage("button", id);
						}}
					>
						<PlusCircleIcon className="h-4 w-4 mr-1" /> Button
					</button>
					<Handle
						isValidConnection={this.actionConnection}
						style={{
							backgroundColor: "#545b6b",
							bottom: this.renderHandlePositions(false, "source"),
							height: `${this.renderHandleSizes(false, "source")}`,
							width: `${this.renderHandleSizes(false, "source")}`,
							border: "none"
						}}
						id="message_components"
						type="source"
						position="bottom"
					/>
					<button
						onClick={() => {
							this.addToMessage("menu", id);
						}}
						className="absolute focus:text-white focus:bg-[#2f3136] hover:!text-white hover:opacity-100 border-white border-opacity-50 btn btn-red !bg-[#2f3136] flex flex-row justify-center !p-[3px] top-[60px] right-[8px] focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
					>
						<PlusCircleIcon className="h-4 w-4 mr-1" /> Menu
					</button>
				</>
			) : (
				<>
					<Handle isValidConnection={this.actionConnection} className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} id="action" style={{ backgroundColor: "#358deb", bottom: this.renderHandlePositions(true, "source", id), height: `${this.renderHandleSizes(true, "source", id)}`, width: `${this.renderHandleSizes(true, "source", id)}`, border: "none" }} type="source" position="bottom" />
				</>
			)}
		</div>
	);

	LoopChildNode = ({ id, data }) => (
		<>
			<Handle isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", border: "none", height: "10px", width: "10px" }} id={"selectmenu"} type="target" position="top" />
			<div
				id={id}
				onTouchStart={(e) => {
					this.props.setSelected(id);
				}}
				onClick={(e) => { }}
				className={`menu-option-node ${this.props.selected == id ? "selected" : null}`}
			>
				<div className="inner">
					<div className="select-menu-item-info">{this.renderLoopData(id, data.type)}</div>
				</div>
			</div>
			<Handle isValidConnection={this.actionConnection} className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} id="action" style={{ backgroundColor: "#358deb", bottom: this.renderHandlePositions(true, "source", id), height: `${this.renderHandleSizes(true, "source", id)}`, width: `${this.renderHandleSizes(true, "source", id)}`, border: "none" }} type="source" position="bottom" />
		</>
	);

	SelectMenuNode = ({ id, data }) => (
		<>
			<Handle isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", border: "none", height: "10px", width: "10px" }} id={"selectmenu"} type="target" position="top" />
			<div
				id={id}
				onTouchStart={(e) => {
					this.props.setSelected(id);
				}}
				onClick={(e) => {
					this.props.setSelected(id);
				}}
				className={`menu-option-node ${this.props.selected == id ? "selected" : null}`}
			>
				<div className="inner">
					<div className="select-menu-item-info">
						<span className="select-menu-item-label" style={{ fontWeight: "600" }}>
							SELECT MENU
						</span>
						<span className="select-menu-description">SELECT MENU</span>
					</div>
				</div>
			</div>
			<Handle isValidConnection={this.actionConnection} className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} id="action" style={{ backgroundColor: "#358deb", bottom: this.renderHandlePositions(true, "source", id), height: `${this.renderHandleSizes(true, "source", id)}`, width: `${this.renderHandleSizes(true, "source", id)}`, border: "none" }} type="source" position="bottom" />
		</>
	);

	SelectMenuOptionNode = ({ id, data }) => (
		<>
			<Handle isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", border: "none", height: "10px", width: "10px" }} id={"selectmenu"} type="target" position="top" />
			<div
				id={id}
				onTouchStart={(e) => {
					this.props.setSelected(id);
				}}
				onClick={(e) => {
					this.props.setSelected(id);
				}}
				className={`menu-option-node ${this.props.selected == id ? "selected" : null}`}
			>
				<div className="inner">
					<div className="select-menu-item-info">
						<span className="select-menu-item-label" style={{ fontWeight: "600" }}>
							{data.data.label}
						</span>
						<span className="select-menu-description">{data.data.description}</span>
					</div>
				</div>
			</div>
			<Handle isValidConnection={this.actionConnection} className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} id="action" style={{ backgroundColor: "#358deb", bottom: this.renderHandlePositions(true, "source", id), height: `${this.renderHandleSizes(true, "source", id)}`, width: `${this.renderHandleSizes(true, "source", id)}`, border: "none" }} type="source" position="bottom" />
		</>
	);

	renderChanceType = (type) => {
		if (type == "less_than") {
			return "is less than";
		} else if (type == "less_than_equal_to") {
			return "is less than or equal to";
		} else if (type == "greater_than") {
			return "is greater than";
		} else if (type == "greater_than_equal_to") {
			return "is greater than or equal to";
		} else if (type == "equal_to") {
			return "is equal to";
		} else if (type == "not") {
			return "is not equal to";
		} else if (type == "contains") {
			return "contains";
		} else if (type == "not_contains") {
			return "does not contain";
		} else if (type == "starts_with") {
			return "starts with";
		} else if (type == "ends_with") {
			return "ends with";
		} else if (type == "not_starts_with") {
			return "does not start with";
		} else if (type == "not_ends_with") {
			return "does not end with";
		} else if (type == "array_contains") {
			return "collection contains";
		} else if (type == "array_not_contain") {
			return "collection does not contain";
		}
		// if
		// { label: "Less than", value: "less_than" },
		// { label: "Less than or equal to", value: "less_than_equal_to" },
		// { label: "Greater than", value: "greater_than" },
		// { label: "Greater than or equal to", value: "greater_than_equal_to" },
		// { label: "Equal to", value: "equal_to" },
		// { label: "Not equal to", value: "not" },
		// { label: "Contains", value: "contains" },
		// { label: "Does not contain", value: "not_contains" },
		// { label: "Starts with", value: "starts_with" },
		// { label: "Ends with", value: "ends_with" },
		// { label: "Does not start with", value: "not_starts_with" },
		// { label: "Does not end with", value: "not_ends_with" },
	};

	itemConditionChild = (possesses, amount) => {
		return `Owns ${amount} or ${possesses ? "more" : "less"}`;
	};

	renderHandleSizes = (action, type, id) => {
		var size = 15;
		if (isBrowser) {
			size = 15;
		} else {
			size = 25;
		}
		if (action == true && this.state.handleId == "action" && this.state.handleType != type && id != this.state.nodeHandleID) {
			if (isBrowser) {
				size = 30;
			} else {
				size = 50;
			}
		}

		return `${size}px`;
	};

	renderHandlePositions = (action, type, id) => {
		var size = -6;
		if (isBrowser) {
			size = -6;
		} else {
			size = -10;
		}
		if (action == true && this.state.handleId == "action" && this.state.handleType != type && id != this.state.nodeHandleID) {
			if (isBrowser) {
				size = -12;
			} else {
				size = -20;
			}
		}

		return size;
	};

	renderVariableConditionName = (id) => {
		var name = "";
		var element = this.props.elements.find((element) => element.id == id);
		if (!element) return name;
		var edges = splitElements(this.props.elements).edges;
		var parentCondition = getIncomers(element, splitElements(this.props.elements).nodes, edges);
		if (parentCondition[0] && parentCondition[0].data) {
			name = `{BGVAR_${parentCondition[0].data.data.reference}}`;
		}

		return name;
	};

	renderComparisonConditionBase = (id) => {
		var name = "";
		var element = this.props.elements.find((element) => element.id == id);
		if (!element) return name;
		var edges = splitElements(this.props.elements).edges;
		var parentCondition = getIncomers(element, splitElements(this.props.elements).nodes, edges);
		if (parentCondition && parentCondition[0] && parentCondition[0].data) {
			name = parentCondition[0].data.data.base_value;
		}

		return name;
	};

	renderConditionChildOptions = (id, data) => {
		if (data.node_options.type == "option_condition" || data.node_options.type == "option") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">{data.data.option}</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : "name" in data.data && data.data.name != "" ? data.data.name : data.data.value}</span>
				</div>
			);
		} else if (data.node_options.type == "chance") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">{data.data.type == "else" ? "Else" : "Chance"}</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.value}%`}</span>
				</div>
			);
		} else if (data.node_options.type == "channel" || data.node_options.type == "role") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">{data.data.type == "else" ? "Else" : data.data.id}</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.name}`}</span>
				</div>
			);
		} else if (data.node_options.type == "user") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">User</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.id}`}</span>
				</div>
			);
		} else if (data.node_options.type == "currency") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">
						{" "}
						<span className="object-description">{data.data.type == "else" ? "Else" : this.renderChanceType(data.data.type)}</span>
					</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `$${data.data.amount}`}</span>
				</div>
			);
		} else if (data.node_options.type == "item") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">
						{" "}
						<span className="object-description">{data.data.type == "else" ? "Else" : this.itemConditionChild(data.data.possesses, data.data.amount)}</span>
					</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.item}`}</span>
				</div>
			);
		} else if (data.node_options.type == "custom_variable") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">
						{" "}
						<span className="object-description">{data.data.type == "else" ? "Else" : `${this.renderVariableConditionName(id)} ${this.renderChanceType(data.data.type)}`}</span>
					</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.value}`}</span>
				</div>
			);
		} else if (data.node_options.type == "comparison") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">
						{" "}
						<span className="object-description">{data.data.type == "else" ? "Else" : `${this.renderComparisonConditionBase(id)} ${this.renderChanceType(data.data.type)}`}</span>
					</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.comparison_value}`}</span>
				</div>
			);
		} else if (data.node_options.type == "permissions") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">
						{" "}
						<span className="object-description">{data.data.type == "else" ? "Else" : `${data.data.type == "has_all" ? "Has all of these permissions" : "Has at least one of these permissions"}`}</span>
					</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.permissions.join(", ")}`}</span>
				</div>
			);
		} else if (data.node_options.type == "premium") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">
						{" "}
						<span className="object-description">{data.data.type == "else" ? "Else" : "Premium"}</span>
					</span>
					<span className="object-title">{data.data.type == "else" ? "Not Premium" : "Premium"}</span>
				</div>
			);
		}
	};

	ConditionChildNode = ({ id, data }) => (
		<>
			<Handle style={{ backgroundColor: "#28a745", border: "none", height: "10px", width: "10px" }} type="target" position="top" isValidConnection={(connection) => connection.target === "root"} />
			<div
				id={id}
				onTouchStart={(e) => {
					this.props.setSelected(id);
				}}
				onClick={(e) => {
					this.props.setSelected(id);
				}}
				className={`action-node ${this.props.selected == id ? "selected" : null}`}
			>
				<div className="inner" style={{ display: "flex" }}>
					<div className={`object-icon object-icon-${data.data.type == "else" ? "else" : "condition"}`}>
						<FontAwesomeIcon icon={faQuestion} />
					</div>

					{this.renderConditionChildOptions(id, data)}
				</div>
			</div>
			<Handle isValidConnection={this.actionConnection} className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} id="action" type="source" style={{ backgroundColor: "#358deb", bottom: this.renderHandlePositions(false), height: `${this.renderHandleSizes()}`, width: `${this.renderHandleSizes()}`, border: "none" }} position="bottom" />
		</>
	);

	renderBlockLabels = (data) => {
		if (data && data.data && "command_label" in data.data && data.data.command_label) {
			return (
				<>
					<span className="object-title">{data.data.command_label}</span>
					<span className="object-description">{data.node_options.title}</span>
				</>
			);
		} else {
			return (
				<>
					<span className="object-title">{data.node_options.title}</span>
					<span className="object-description">{data.node_options.description}</span>
				</>
			);
		}
	};

	addToMessage = (type, id) => {
		var elements = [...this.props.elements];
		var element = this.props.elements.find((element) => element.id == id);

		var children = getOutgoers(element, splitElements(elements).nodes, splitElements(elements).edges);
		console.log(element);
		if (element.data?.data?.messageType == "basic") {
			// Change to advanced;
			var data = { ...element.data.data };
			console.log(data, "DATA HERE ");
			data.messageType = "advanced";
			console.log(data, "DATA HERE 1");
			this.props.updateElementData({
				data: {
					...element.data.data,
					messageType: "advanced"
				},
				id: id
			});
		}

		var existingButtonCount = 0;
		var existingMenuCount = 0;
		var existingComponentsCount = 0;

		children.forEach((child) => {
			if (child.type === "advMessageButton") {
				existingButtonCount++;
			} else if (child.type === "advMessageSelectMenu") {
				existingMenuCount++;

			}
			existingComponentsCount++;
		});


		if (type == "button" && existingButtonCount >= 25) {
			toast.error("Maximum of 25 buttons reached.");
			return;
		}

		if (type == "menu" && (existingMenuCount >= 5)) {
			toast.error("Maximum of 5 select menus or 5 rows reached.");
			return;
		}

		if (type == "button") {
			var new_id = `adv_button${elements.length + 1}_${Math.random().toString(36).substr(2, 9)}`;
			var existingMenuOptions = [];
			if (existingMenuOptions.length >= 25) {
				return;
			}
			// var lastChild = existingMenuOptions[existingMenuOptions.length - 1];

			var width = 0;
			var position = { x: element.position.x, y: element.position.y + 100 };
			// if (lastChild) {
			//     var docuElement = document.getElementById(lastChild.id);
			//     width = docuElement.clientWidth;
			//     position = { x: lastChild.position.x + width + 20, y: lastChild.position.y };
			// }

			elements.push({
				id: new_id,
				type: "advMessageButton",
				data: {
					node_options: {},
					data: {
						type: "button",
						label: `Button ${existingButtonCount + 1}`,
						style: "PRIMARY",
						emoji_id: "",
						orderComponent: false,
						weight: "",
						row: "",
						validated: true
					}
				},
				// target:"3",
				draggable: true,
				position: { x: element.position.x - (existingComponentsCount == 0 ? 0 : existingComponentsCount * 100), y: element.position.y + 150 }
			});
			var newEdges = addEdge(
				{
					source: element.id,
					target: new_id,
					type: "step",
					sourceHandle: "components_source",
					animated: false,
					arrowHeadType: "arrowclosed"
				},
				splitElements(elements).edges
			);
			newEdges.forEach((newEdge) => {
				var index = elements.findIndex((element) => element.id == newEdge.id);
				if (index == -1) {
					elements.push(newEdge);
				}
				// elements[index] = newNode;
			});
			this.props.setElements(elements);
			this.props.setSelected(element.id);
		} else {
			var new_id = `adv_select_menu${elements.length + 1}_${Math.random().toString(36).substr(2, 9)}`;
			var existingMenuOptions = [];
			if (existingMenuOptions.length >= 25) {
				return;
			}
			// var lastChild = existingMenuOptions[existingMenuOptions.length - 1];

			var width = 0;
			var position = { x: element.position.x, y: element.position.y + 100 };
			// if (lastChild) {
			//     var docuElement = document.getElementById(lastChild.id);
			//     width = docuElement.clientWidth;
			//     position = { x: lastChild.position.x + width + 20, y: lastChild.position.y };
			// }

			elements.push({
				id: new_id,
				type: "advMessageSelectMenu",
				data: {
					node_options: {},
					data: {
						type: "adv_select_menu",
						menu_type: "basic",
						multiselect: false,
						minOptions: "1",
						maxOptions: "1",
						basic_options: [],
						type_options: {},
						options: [],
						validated: false,
						customVariableName: "",
					}
				},
				// target:"3",
				draggable: true,
				position: { x: element.position.x - (existingComponentsCount == 0 ? 0 : existingComponentsCount * 100), y: element.position.y + 150 }
			});
			var newEdges = addEdge(
				{
					source: element.id,
					target: new_id,
					type: "step",
					animated: false,
					sourceHandle: "components_source",
					arrowHeadType: "arrowclosed"
				},
				splitElements(elements).edges
			);
			newEdges.forEach((newEdge) => {
				var index = elements.findIndex((element) => element.id == newEdge.id);
				if (index == -1) {
					elements.push(newEdge);
				}
				// elements[index] = newNode;
			});
			this.props.setElements(elements);
			this.props.setSelected(element.id);
		}
	};

	renderAdvMessageHandles = (id, data) => {
		// If Doesn't have any components, Have 1 action handle

		var elements = [...this.props.elements];
		var element = elements.find((element) => element.id == id);

		if (!element || element.data?.data?.messageType == "basic") {
			return <Handle className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} id="action" isValidConnection={this.actionConnection} style={{ backgroundColor: "#358deb", bottom: this.renderHandlePositions(true, "source", id), height: `${this.renderHandleSizes(true, "source", id)}`, width: `${this.renderHandleSizes(true, "source", id)}`, border: "none" }} type="source" position="bottom" />;
		} else {
			return (
				<div className="gap-x-2">
					<Handle className={`ml-[-100px]`} isValidConnection={this.actionConnection} style={{ backgroundColor: "#358deb", bottom: this.renderHandlePositions(true, "source", id), height: `${this.renderHandleSizes(true, "source", id)}`, width: `${this.renderHandleSizes(true, "source", id)}`, border: "none" }} id="action" type="source" position="bottom" />

					<Handle className={`ml-[100px]`} isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", bottom: this.renderHandlePositions(false, "source"), height: `${this.renderHandleSizes(false, "source")}`, width: `${this.renderHandleSizes(false, "source")}`, border: "none" }} id="components_source" type="source" position="bottom" />
				</div>
			);
		}
	};
	renderActionHandles = (id, data) => {
		if (!data) return;
		// {data.node_options.title == "Send a Message with attached Buttons" || data.node_options.title == "Send a Message with a Select Menu" ?

		//   data.node_options.title == "Send a Message with attached Buttons" ?
		//     <Handle className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", bottom: this.renderHandlePositions(false, "source"), height: `${this.renderHandleSizes(false,)}`, width: `${this.renderHandleSizes(false, "source")}`, border: "none", }} id="button" type="source" position="bottom" /> :
		//     <Handle className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", bottom: this.renderHandlePositions(false, "source"), height: `${this.renderHandleSizes(false, "source")}`, width: `${this.renderHandleSizes(false, "source")}`, border: "none", }} id="selectmenu" type="source" position="bottom" />

		//   :

		//   data.node_options.title == "Run a Loop" ?
		//     <div className='gap-x-2'>
		//       <Handle className={`ml-[-50px]`} isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", bottom: this.renderHandlePositions(false, "source"), height: `${this.renderHandleSizes(false, "source")}`, width: `${this.renderHandleSizes(false, "source")}`, border: "none", }} id="loop_after" type="source" position="bottom" />
		//       <Handle className={`ml-[50px]`} isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", bottom: this.renderHandlePositions(false, "source"), height: `${this.renderHandleSizes(false, "source")}`, width: `${this.renderHandleSizes(false, "source")}`, border: "none", }} id="loop_actions" type="source" position="bottom" />

		//     </div>
		//     :
		//     <Handle className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} id="action" isValidConnection={this.actionConnection} style={{ backgroundColor: "#358deb", bottom: this.renderHandlePositions(true, "source", id), height: `${this.renderHandleSizes(true, "source", id)}`, width: `${this.renderHandleSizes(true, "source", id)}`, border: "none", }} type="source" position="bottom" />

		// }
		if (data.data?.success_handles) {
			return (
				<div className="gap-x-2">
					<Handle className={`ml-[-70px] border-1 `} isValidConnection={this.actionConnection} style={{ backgroundColor: "#358deb", border: "3px solid lime", bottom: this.renderHandlePositions(true, "source"), height: `${this.renderHandleSizes(true, "source")}`, width: `${this.renderHandleSizes(true, "source")}` }} id="success_actions" type="source" position="bottom" />
					<Handle className={`ml-[70px]`} isValidConnection={this.actionConnection} style={{ backgroundColor: "#358deb", border: "3px solid red", bottom: this.renderHandlePositions(true, "source"), height: `${this.renderHandleSizes(true, "source")}`, width: `${this.renderHandleSizes(true, "source")}` }} id="error_actions" type="source" position="bottom" />
				</div>
			);
		}

		if (data.node_options.title == "Send a Message with attached Buttons" || data.node_options.title == "Send a Message with a Select Menu") {
			return data.node_options.title == "Send a Message with attached Buttons" ? (
				<Handle className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", bottom: this.renderHandlePositions(false, "source"), height: `${this.renderHandleSizes(false)}`, width: `${this.renderHandleSizes(false, "source")}`, border: "none" }} id="button" type="source" position="bottom" />
			) : (
				<Handle className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", bottom: this.renderHandlePositions(false, "source"), height: `${this.renderHandleSizes(false, "source")}`, width: `${this.renderHandleSizes(false, "source")}`, border: "none" }} id="selectmenu" type="source" position="bottom" />
			);
		} else if (data.node_options.title == "Run a Loop") {
			return (
				<div className="gap-x-2">
					<Handle className={`ml-[-50px]`} isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", bottom: this.renderHandlePositions(false, "source"), height: `${this.renderHandleSizes(false, "source")}`, width: `${this.renderHandleSizes(false, "source")}`, border: "none" }} id="loop_after" type="source" position="bottom" />
					<Handle className={`ml-[50px]`} isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", bottom: this.renderHandlePositions(false, "source"), height: `${this.renderHandleSizes(false, "source")}`, width: `${this.renderHandleSizes(false, "source")}`, border: "none" }} id="loop_actions" type="source" position="bottom" />
				</div>
			);
		} else {
			return <Handle className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} id="action" isValidConnection={this.actionConnection} style={{ backgroundColor: "#358deb", bottom: this.renderHandlePositions(true, "source", id), height: `${this.renderHandleSizes(true, "source", id)}`, width: `${this.renderHandleSizes(true, "source", id)}`, border: "none" }} type="source" position="bottom" />;
		}
	};
	ActionNode = ({ id, data }) => (
		// <>
		//   <Handle id={"action"} type="target" position="top" isValidConnection = {this.actionConnection} />
		//     <div onClick={(e) =>{this.props.setSelected(id)}} className={`custom-node action-node ${this.props.selected == id ? "selected":null}`}>
		//       <span className="title">{data.title}</span>
		//       <span className="description">{data.description}</span>
		//   </div>
		//   <Handle type="source" position="bottom"  />
		// </>

		<>
			{data.node_options.title == "Send a Message" || data.node_options.title == "Send or Edit a Message" ? (
				<div className="min-w-[430px]">
					<Handle className={`${this.state.nodeHandleID == id && this.state.handleType == "target" ? "node-selected" : null} ${this.state.handleId == "action" ? "compatible-node" : null}`} style={{ backgroundColor: "#358deb", border: "none", top: this.renderHandlePositions(true, "target", id), height: `${this.renderHandleSizes(true, "target", id)}`, width: `${this.renderHandleSizes(true, "target", id)}` }} id={"action"} type="target" position="top" isValidConnection={this.actionConnection} />

					<div
						id={id}
						onTouchStart={(e) => {
							this.props.setSelected(id);
						}}
						onClick={(e) => {
							this.props.setSelected(id);
						}}
						className={`action-node option-node ${this.props.highlightAction == id ? "highlight-action" : null} ${this.props.selected == id ? "selected" : null}`}
					>
						<div className="inner" style={{ display: "flex" }}>
							{data.data == undefined || data.data.validated == false ? (
								<div className="node-invalid">
									<FontAwesomeIcon icon={faExclamation} />
								</div>
							) : null}

							{data.data == undefined || data.data.linked == false ? (
								<div className="node-invalid" style={{ right: `${data.data != undefined && data.data.validated == false ? "10px" : ""}` }}>
									<FontAwesomeIcon icon={faLink} />
								</div>
							) : null}
							<div className="object-icon object-icon-action">
								<FontAwesomeIcon icon={data.node_options.icon} />
							</div>

							<div className="object-info object-info-action">{this.renderBlockLabels(data)}</div>
						</div>
						{/* 
            <div className='mt-2'>

              <button onClick={() => {
                this.addToMessage("menu", id);
              }} className='btn float-right bg-[#358deb] text-white'><FontAwesomeIcon icon={faPlusCircle} /> Menu</button>
              <button onClick={() => {
                this.addToMessage("button", id);
              }} className='btn bg-[#358deb] text-white'><FontAwesomeIcon icon={faPlusCircle} /> Button</button>
            </div> */}
					</div>
					{/* <Handle  style={{backgroundColor:"#358deb",height:"10px",width:"10px",border:"none",}} type="source" position="bottom"  /> */}
					<>
						<button
							onTouchEnd={(e) => {
								this.addToMessage("button", id);
							}}
							className="z-50 absolute text-white border border-rounded-sm hover:!border-blue rounded-sm border-white border-1 !bg-[#2f3136] flex flex-row justify-center !p-[3px] top-[65px]"
							onClick={() => {
								this.addToMessage("button", id);
							}}
						>
							<PlusCircleIcon className="h-4 w-4 mr-1" /> Button
						</button>
						<button
							onTouchEnd={(e) => {
								this.addToMessage("menu", id);
							}}
							onClick={() => {
								console.log("ON CLICK");
								this.addToMessage("menu", id);
							}}
							className="absolute text-white border border-rounded-sm hover:!border-blue rounded-sm border-white border-1 focus:text-white focus:bg-[#2f3136] hover:!text-white hover:opacity-100 !bg-[#2f3136] flex flex-row justify-center !p-[3px] top-[65px] right-0 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
						>
							<PlusCircleIcon className="h-4 w-4 mr-1" /> Menu
						</button>
					</>
					{this.renderAdvMessageHandles(id, data)}
				</div>
			) : (
				<>
					{/* Break here */}
					<Handle className={`${this.state.nodeHandleID == id && this.state.handleType == "target" ? "node-selected" : null} ${this.state.handleId == "action" ? "compatible-node" : null}`} style={{ backgroundColor: "#358deb", border: "none", top: this.renderHandlePositions(true, "target", id), height: `${this.renderHandleSizes(true, "target", id)}`, width: `${this.renderHandleSizes(true, "target", id)}` }} id={"action"} type="target" position="top" isValidConnection={this.actionConnection} />

					<div
						id={id}
						onTouchStart={(e) => {
							this.props.setSelected(id);
						}}
						onClick={(e) => {
							this.props.setSelected(id);
						}}
						className={`action-node option-node ${this.props.highlightAction == id ? "highlight-action" : null} ${this.props.selected == id ? "selected" : null}`}
					>
						<div className="inner" style={{ display: "flex" }}>
							{data.data == undefined || data.data.validated == false ? (
								<div className="node-invalid">
									<FontAwesomeIcon icon={faExclamation} />
								</div>
							) : null}

							{data.data == undefined || data.data.linked == false ? (
								<div className="node-invalid" style={{ right: `${data.data != undefined && data.data.validated == false ? "10px" : ""}` }}>
									<FontAwesomeIcon icon={faLink} />
								</div>
							) : null}
							<div className="object-icon object-icon-action">
								<FontAwesomeIcon icon={data.node_options.icon} />
							</div>

							<div className="object-info object-info-action">{this.renderBlockLabels(data)}</div>
						</div>
					</div>
					{/* <Handle  style={{backgroundColor:"#358deb",height:"10px",width:"10px",border:"none",}} type="source" position="bottom"  /> */}

					{this.renderActionHandles(id, data)}
				</>
			)}
		</>
	);

	ErrorNode = ({ id, data }) => (
		// <>
		//   <Handle id={"action"} type="target" position="top" isValidConnection = {this.actionConnection} />
		//     <div onClick={(e) =>{this.props.setSelected(id)}} className={`custom-node action-node ${this.props.selected == id ? "selected":null}`}>
		//       <span className="title">{data.title}</span>
		//       <span className="description">{data.description}</span>
		//   </div>
		//   <Handle type="source" position="bottom"  />
		// </>

		<>
			{/* <Handle style={{ backgroundColor: "#f44336", border: "none", height: "20px", width: "20px", left: "-8px" }} id="error" type="target" position="left" isValidConnection={(connection) => {

        return false;
      }} /> */}
			<div
				id={id}
				onTouchStart={(e) => {
					this.props.setSelected(id);
				}}
				onClick={(e) => {
					this.props.setSelected(id);
				}}
				className={`action-node option-node ${this.props.highlightAction == id ? "highlight-action" : null} ${this.props.selected == id ? "selected" : null}`}
			>
				<div className="inner" style={{ display: "flex" }}>
					{data.data == undefined || data.data.validated == false ? (
						<div className="node-invalid">
							<FontAwesomeIcon icon={faExclamation} />
						</div>
					) : null}

					{data.data == undefined || data.data.linked == false ? (
						<div className="node-invalid" style={{ right: `${data.data != undefined && data.data.validated == false ? "10px" : ""}` }}>
							<FontAwesomeIcon icon={faLink} />
						</div>
					) : null}
					<div className="object-icon object-icon-error">
						<FontAwesomeIcon icon={data.node_options.icon} />
					</div>

					<div className="object-info object-info-action">
						<span className="object-title">{data.node_options.title}</span>
						<span className="object-description">{data.node_options.description}</span>
					</div>
				</div>
			</div>
			{/* <Handle  style={{backgroundColor:"#358deb",height:"10px",width:"10px",border:"none",}} type="source" position="bottom"  /> */}

			{data.node_options.title == "Send a Message with attached Buttons" || data.node_options.title == "Send a Message with a Select Menu" ? (
				data.node_options.title == "Send a Message with attached Buttons" ? (
					<Handle className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", bottom: this.renderHandlePositions(true), height: `${this.renderHandleSizes()}`, width: `${this.renderHandleSizes()}`, border: "none" }} id="button" type="source" position="bottom" />
				) : (
					<Handle className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} isValidConnection={this.actionConnection} style={{ backgroundColor: "#545b6b", bottom: this.renderHandlePositions(true), height: `${this.renderHandleSizes()}`, width: `${this.renderHandleSizes()}`, border: "none" }} id="selectmenu" type="source" position="bottom" />
				)
			) : (
				<Handle className={`${this.state.nodeHandleID == id && this.state.handleType == "source" ? "node-selected" : null}`} id="action" isValidConnection={this.actionConnection} style={{ backgroundColor: "#358deb", bottom: this.renderHandlePositions(true, "source", id), height: `${this.renderHandleSizes(true, "source", id)}`, width: `${this.renderHandleSizes(true, "source", id)}`, border: "none" }} type="source" position="bottom" />
			)}
		</>
	);
	ConditionNode = ({ id, data }) => (
		<>
			<Handle className={`${this.state.nodeHandleID == id && this.state.handleType == "target" ? "node-selected" : null} ${this.state.handleId == "action" ? "compatible-node" : null}`} style={{ backgroundColor: "#358deb", border: "none", top: this.renderHandlePositions(true, "target", id), height: `${this.renderHandleSizes(true, "target", id)}`, width: `${this.renderHandleSizes(true, "target", id)}` }} id={"action"} type="target" position="top" isValidConnection={this.actionConnection} />

			<div
				onTouchStart={(e) => {
					this.props.setSelected(id);
				}}
				id={id}
				onClick={(e) => {
					this.props.setSelected(id);
				}}
				className={`action-node option-node ${this.props.selected == id ? "selected" : null}`}
			>
				<div className="inner" style={{ display: "flex" }}>
					{data.data == undefined || data.data.validated == false ? (
						<div className="node-invalid">
							<FontAwesomeIcon icon={faExclamation} />
						</div>
					) : null}

					{data.data == undefined || data.data.linked == false ? (
						<div className="node-invalid" style={{ right: `${data.data != undefined && data.data.validated == false ? "10px" : ""}` }}>
							<FontAwesomeIcon icon={faLink} />
						</div>
					) : null}
					<div className="object-icon object-icon-condition">
						<FontAwesomeIcon icon={data.node_options.icon} />
					</div>

					<div className="object-info object-info-action">{this.renderBlockLabels(data)}</div>
				</div>
			</div>

			<Handle id="action" isValidConnection={this.actionConnection} style={{ backgroundColor: "#28a745", height: "10px", width: "10px", border: "none" }} type="source" position="bottom" />
		</>
	);

	nodeTypes = {
		option: this.OptionNode,
		root: this.RootNode,
		action: this.ActionNode,
		button: this.ButtonNode,
		condition: this.ConditionNode,
		conditionChild: this.ConditionChildNode,
		selectMenu: this.SelectMenuNode,
		selectMenuOption: this.SelectMenuOptionNode,
		loopChild: this.LoopChildNode,
		advMessageChild: this.AdvancedMessageChildNode,
		error: this.ErrorNode,
		advMessageSelectMenu: this.AdvancedMessageSelectMenu,
		advMessageButton: this.AdvancedMessageButton,
		loopChild: this.LoopChildNode,
		error: this.ErrorNode
	};

	edgeTypes = {
		custom: this.CustomEdge
	};

	actionConnection = (connection) => {
		if (connection.sourceHandle === connection.targetHandle) {
			return true;
		} else if ((connection.sourceHandle == "success_actions" || connection.sourceHandle == "error_actions") && connection.targetHandle == "action") {
			return true;
		} else {
			return false;
		}
		// if(connection.targetHandle == "action" && connection.sourceHandle == "action"){
		//   return true;
		// } else if(connection.targetHandle == "button" && connection.sourceHandle == "button"){
		// return true;
		// }else{
		//   return false;
		// }
	};

	onConnect = (params) => {
		if (params.source == params.target) {
			return false;
		}

		if (this.props.module_id != null && !this.props.premium) {
			return false;
		}

		// action_11_d8ad_c8a9
		var elements = [...this.props.elements];
		var check = true;

		elements.forEach((element) => {
			if (isEdge(element) && element.target) {
				checkAdvancedMessageChild(elements, params.source, params.target);
			}
			if (isEdge(element) && ((element.target == params.target && params.target != "root" && !checkSplitChild(this.props.elements, params.target)) || element.source == params.source || !checkErrorChild(this.props.elements, params.source, params.target))) {
				console.log("CHECK HERE FAIL 0");
				check = false;
			} else if (isEdge(element) && element.target == params.source && element.source == params.target) {
				console.log("CHECK HERE FAIL 1");
				check = false;
			}
		});

		var sourceElement = elements.find((element) => element.id == params.source);
		var targetElement = elements.find((element) => element.id == params.target);

		if (sourceElement.data?.data?.type == "advanced_message") {
			check = checkAdvancedMessageChild(elements, params.source, params.target);
			console.log(check, "adv check here");
		}
		// check = checkErrorSuccessHandles(elements, params.source, params.target);

		if (sourceElement.data?.data?.success_handles) {
			check = checkErrorSuccessHandles(elements, params.source, params.sourceHandle);
			console.log(check, "CHECK ERROR SUCCESS HANDLES");
		}

		// Check if its a modal action
		if (targetElement.data.data.type == "modal") {
			var edges = splitElements(elements).edges;
			var targetParents = [sourceElement];
			var modalCheckPassed = true;
			while (targetParents.length > 0) {
				// If its root, button, or select menu break the while loop

				if ((targetParents[0].data.data.type == "root" && this.props.mode == "command") ||
					targetParents[0].type == "button" ||
					targetParents[0].type == "selectMenuOption" ||
					targetParents[0].type == "advMessageButton" ||
					targetParents[0].type == "advMessageSelectMenu") {
					break;
				}

				if (targetParents[0].type == "error") {
					modalCheckPassed = false;
					break;
				} else if (targetParents[0].type == "root" && this.props.mode == "event") {
					modalCheckPassed = false;
					break;
				}

				//
				if (!targetParents[0].data || !targetParents[0].data.data) {
					if (targetParents[0].type == "error") {
						modalCheckPassed = false;
					}
				} else if (targetParents[0].data.data.type == "plain_text" && targetParents[0].data.data.target.reply) {
					modalCheckPassed = false;
				} else if (targetParents[0].data.data.type == "embed" && targetParents[0].data.data.target.reply) {
					modalCheckPassed = false;
				} else if (targetParents[0].data.data.type == "random_response" && targetParents[0].data.data.target.reply) {
					modalCheckPassed = false;
				} else if (targetParents[0].data.data.type == "random_response" && targetParents[0].data.data.target.reply) {
					modalCheckPassed = false;
				} else if (targetParents[0].data.data && targetParents[0].data.data.type == "modal") {
					modalCheckPassed = false;
				}
				if (modalCheckPassed == false) {
					break;
				}
				targetParents = getIncomers(targetParents[0], splitElements(elements).nodes, edges);
			}

			// Check if its the child

			if (!modalCheckPassed) {
				toast.error("Forms must be the first response after an interaction. An interaction occurs after a command is used, a button is clicked or a select menu option is picked", {
					duration: 5000,
					style: {
						borderRadius: "10px",
						background: "#333",
						color: "#fff"
					}
				});
				return;
			}
		}

		// Check if the element is already a child of the target.
		// If it is, do not allow the connection

		var edges = splitElements(elements).edges;
		var parents = getIncomers(sourceElement, splitElements(elements).nodes, edges);
		var parentsArray = [];
		while (parents.length > 0) {
			parentsArray.push(parents[0]);
			parents = getIncomers(parents[0], splitElements(elements).nodes, edges);
		}
		parentsArray.forEach((parent) => {
			if (parent.id == params.target) {
				console.log("CHECK HERE FAIL 2");
				check = false;
			}
		});
		console.log(check, "CHECK HERE");
		// console.log(checkSplitChild(this.props.elements, params.target), 'CHECK SPLIT CHILD');

		// Check if incoming is split from a button
		// Only the top handle can have multiple connections
		// Bottom can not

		if (check == true) {
			params.animated = false;
			params.arrowHeadType = "arrowclosed";
			// params.type = "step"
			params.type = "custom";
			var edges = splitElements(this.props.elements).edges;
			var newEdges = addEdge(params, edges);
			newEdges.forEach((newEdge) => {
				var index = elements.findIndex((element) => element.id == newEdge.id);
				if (index == -1) {
					elements.push(newEdge);
				}
				// elements[index] = newNode;
			});
			this.props.setElements(elements);
		}
	};

	onDragOver = (event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = "move";
	};

	onLoad = (reactFlowInstance) => {
		this.setState({ reactFlowInstance: reactFlowInstance });
		var elements = this.getLayoutedElements(this.props.elements);
		var root = elements.find((element) => element.id == "root");

		this.props.setElements(elements);
		if (elements.length == 1) {
			setTimeout(() => {
				reactFlowInstance.fitView({ padding: 2.5 });
				// this.props.zoomTo(1)
				// reactFlowInstance.setZoom(1)
			}, 0);
		} else {
			setTimeout(() => {
				reactFlowInstance.fitView({ padding: 0.5 });
				// this.props.zoomTo(1)
				// reactFlowInstance.setZoom(1)
			}, 0);
		}
		// this.props.setCenter(0,0,0.8)
		// this.props.setZoom(1)
		// const { setCenter } = useZoomPanHelper();
		// setCenter(root.position.x,root.position.y,0.5)
	};

	onDrop = (event) => {
		try {
			event.preventDefault();
			const reactFlowBounds = this.reactFlowWrapper.current.getBoundingClientRect();

			const position = this.state.reactFlowInstance.project({
				x: event.clientX - reactFlowBounds.left,
				y: event.clientY - reactFlowBounds.top
			});
			var options = JSON.parse(event.dataTransfer.getData("options"));
			if (options?.type == "block_template") {
				var newElements = options?.elements;
				var elements = [...this.props.elements];
				// console.log(elements, options, "ELEMENTS HERE");
				var random_id = s4();
				newElements.forEach((element) => {
					if (isEdge(element)) {
						element.id = `${element.id}_${random_id}`;
						element.source = `${element.source}_${random_id}`;
						element.target = `${element.target}_${random_id}`;
					} else {
						element.id = `${element.id}_${random_id}`;
					}

					if (element.selected) {
						element.selected = false;
					}
				});

				newElements[0].position = position;
				console.log(newElements);
				var layouted_new_elements = layoutElements(newElements, position);

				// console.log(layouted_new_elements, "NEW ELEMENTS");

				elements = [...elements, ...layouted_new_elements];
				console.log(elements, "ELEMENTS HERE");
				this.props.setElements(elements);

				// setTimeout(() => {
				// this.props.setSelected(layouted_new_elements[0].id);
				// }, 1000);
				return;
			}

			var options = JSON.parse(event.dataTransfer.getData("options"));
			var elements = [...this.props.elements];
			var length = elements.length + 1;

			var data = {
				node_options: {
					title: options.type,
					description: options.description,
					icon: options.icon
				},
				data: options.data
			};
			var id = `${options.category}_${length}_${s4()}_${s4()}`;
			const newNode = {
				id: id,
				type: options.category,
				data: data,
				position
			};

			ReactGA.initialize("UA-122665050-1");
			ReactGA.event({
				category: "Command Block",
				label: options.type,
				action: "New Dashboard"
			});

			if (options.category == "option") {
				var elements = addEdge(
					{
						source: id,
						target: "root",
						type: "step",
						animated: false,
						arrowHeadType: "arrowclosed"
					},
					elements
				);
			} else if (options.category == "condition") {
				var else_id = `$else_${length}_${s4()}_${s4()}`;
				elements.push({
					id: else_id,
					type: "conditionChild",
					data: {
						node_options: {
							type: options.condition_type
						},
						data: {
							type: "else",
							actions: []
						}
					},
					// target:"3",
					draggable: true,
					position: { x: position.x + 200, y: position.y + 150 }
				});

				elements = addEdge(
					{
						source: id,
						target: else_id,
						type: "step",
						animated: false,
						arrowHeadType: "arrowclosed"
					},
					elements
				);
			}

			elements.push(newNode);

			this.props.setSelected(newNode.id);
			this.props.setElements(elements);

			// setElements((es) => es.concat(newNode));
		} catch (e) { }
	};

	updatePosition = (e, node) => {
		this.props.updateNode(node);
	};

	sortElements = () => {
		var elements = this.getLayoutedElements(this.props.elements);
		this.props.setElements(elements);
	};

	getLayoutedElements = (ele, direction = "TB") => {
		const nodeWidth = 400;
		const nodeHeight = 90;
		const dagreGraph = new dagre.graphlib.Graph();
		dagreGraph.setDefaultEdgeLabel(() => ({}));
		dagreGraph.setGraph({ rankdir: direction });
		var elements = [...ele];
		var optionsCheck = elements.filter((element) => element.type == "option");
		if (optionsCheck.length > 0) {
			elements.push({
				source: "option_7_c44a_0728",
				target: "error_handler",
				type: "step",
				animated: true,
				arrowHeadType: "arrowclosed",
				id: "error_handler_placeholder"
			});
		}
		elements.forEach((el) => {
			if (isNode(el)) {
				var docuElement = document.getElementById(el.id);
				if (el.type == "selectMenuOption" || el.type == "button") {
					dagreGraph.setNode(el.id, { width: docuElement.clientWidth, height: 52 });
				} else if (el.id != "root") {
					dagreGraph.setNode(el.id, { width: docuElement.clientWidth, height: 79 });
				} else {
					dagreGraph.setNode(el.id, { width: 600, height: 121 });
				}
			} else {
				dagreGraph.setEdge(el.source, el.target);
			}
		});

		dagre.layout(dagreGraph);

		var error_handler = elements.find((element) => element.id == "error_handler");

		// Add a Temp node to the graph to make sure the error handler is always at the bottom

		elements = elements.map((el) => {
			if (isNode(el)) {
				const nodeWithPosition = dagreGraph.node(el.id);
				el.targetPosition = "top";
				el.sourcePosition = "bottom";
				var docuElement = document.getElementById(el.id);
				// unfortunately we need this little hack to pass a slightly different position
				// to notify react flow about the change. Moreover we are shifting the dagre node position
				// (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
				if (el.id != "root") {
					el.position = {
						x: nodeWithPosition.x - docuElement.clientWidth / 2 + Math.random() / 1000,
						y: nodeWithPosition.y - 79 / 2
					};
				} else if (el.id == "root") {
					el.position = {
						x: nodeWithPosition.x - 600 / 2 + Math.random() / 1000,
						y: nodeWithPosition.y - 165 / 2
					};
				}

				// if (el.id == "error_handler") {
				//   console.log("error_handler", el,);
				//   el.position = {
				//     x: el.position.x + Math.random() / 1000,
				//     y: 149 + Math.random() / 1000
				//   };
				// } else if (el.id == "root") {
				//   console.log("error_handler root", el,);
				// }
			}

			return el;
		});

		// remove placeholder edge
		elements = elements.filter((element) => element.id != "error_handler_placeholder");
		return elements;
	};

	onConnectStart = (event, info) => {
		this.setState({ handleId: info.handleId, nodeHandleID: info.nodeId, handleType: info.handleType });
	};

	onConnectEnd = (event) => {
		this.setState({ handleId: null, nodeHandleID: null, handleType: null });
	};

	splitNodes = () => {
		var nodes = [];
		this.props.elements.forEach((element) => {
			if (isNode(element)) {
				nodes.push(element);
			}
		});
		return nodes;
	};

	splitEdges = () => {
		var edges = [];
		this.props.elements.forEach((element) => {
			if (isEdge(element)) {
				edges.push(element);
			}
		});
		return edges;
	};

	render() {
		return (
			<KeyEventsHandler
				triggerSave={this.props.triggerSave}
				showSaveTemplateModal={this.state.showSaveTemplateModal}
				selectedElements={this.state.selectedElements}
				closeSaveTemplateModal={() => {
					this.setState({ showSaveTemplateModal: false });
				}}
				reactFlowInstance={this.state.reactFlowInstance}
				reactFlowWrapper={this.reactFlowWrapper}
			>
				{/* <Toaster position="top-right" reverseOrder={false} /> */}
				<div
					className={`command-builder-canvas${this.props.hidden == true ? "-hidden" : ""}`}
					onClick={(e) => {
						if (e.target.className == "react-flow__pane react-flow__container") {
							this.props.setSelected(null);
						}
					}}
					ref={this.reactFlowWrapper}
				>
					<ReactFlow
						onDragOver={this.onDragOver}
						onConnect={this.onConnect}
						onDrop={this.onDrop}
						onInit={this.onLoad}
						onConnectStart={this.onConnectStart}
						onConnectEnd={this.onConnectEnd}
						defaultZoom={1}
						// snapToGrid={true}
						onNodeDragStop={(event, node) => {
							// console.log(event, node, "EVENT NODE");
							this.updatePosition(event, node);
						}}
						// snapGrid={[10, 10]}
						nodeTypes={this.nodeTypes}
						edgeTypes={this.edgeTypes}
						maxZoom={20}
						className="touchdevice-flow"
						selectionKeyCode={["Shift"]}
						onSelectionChange={(selection) => {
							if (selection.nodes.length > 1) {
								this.props.setSelected(null);
							}
							this.setState({
								selectedElements: selection
							});
							// this.props.setSelected(null);
						}}
						onSelectionDragStart={(event) => {
							// console.log("DRAG  START");
						}}
						onSelectionDragStop={(e) => {
							// console.log("DRAG STOP");
						}}
						onPaneClick={() => {
							// console.log("PANE CLICK");
						}}
						onNodesChange={(changes) => {

							var newNodes = applyNodeChanges(changes, splitElements(this.props.elements).nodes);
							var elements = [...this.props.elements];
							newNodes.forEach((newNode) => {
								var index = this.props.elements.findIndex((element) => element.id == newNode.id);
								elements[index] = newNode;
								delete newNode.handleBounds;
							});

							this.props.setElements(elements);
						}}
						onEdgesChange={(changes) => { }}
						nodes={splitElements(this.props.elements).nodes}
						edges={splitElements(this.props.elements).edges}
						elements={this.props.elements}
					>
						<Background variant="dots" gap={15} size={0.5} />
						<Controls showZoom={false} showFitView={true} showInteractive={false}>
							<ControlButton
								onClick={() => {
									this.sortElements();
								}}
							>
								<FontAwesomeIcon icon={faRandom} />
							</ControlButton>
						</Controls>

						{this.state.selectedElements.nodes.length > 1 ? (
							<div className="multi-select-duplicate-container">
								<button
									className="btn btn-gray mr-3"
									onClick={(e) => {
										if (window.getSelection) {
											window.getSelection().removeAllRanges();
										} else if (document.selection) {
											document.selection.empty();
										}
										document.querySelector(".react-flow__pane").click();
									}}
								>
									Deselect
								</button>
								<button
									className="btn btn-red mr-3"
									onClick={(e) => {
										this.setState({ showSaveTemplateModal: true });
									}}
								>
									<FontAwesomeIcon icon={faSave} /> Save as Template
								</button>
								<button
									className="btn btn-gray"
									onClick={(e) => {
										var selection = { ...this.state.selectedElements };
										var newElements = [];
										var newEdges = [];
										var nodes = [...selection.nodes];
										var edges = [...selection.edges];
										for (var i = 0; i < nodes.length; i++) {
											var node = nodes[i];
											// nodes.forEach(node => {

											if (node.id == "root" || node.id == "error_handler") {
												continue;
											}

											if (node.type == "button" || node.type == "conditionChild" || node.type == "selectMenuOption") {
												// Get the node parent and add it to.
												var allEdges = splitElements(this.props.elements).edges;
												var parent = getIncomers(node, splitElements(this.props.elements).nodes, allEdges);
												if (parent) {
													var parentIndex = nodes.findIndex((n) => n.id == parent[0].id);
													if (parentIndex == -1) {
														nodes.push(parent[0]);
													}
												}
											} else if (node.type == "condition") {
												var allEdges = splitElements(this.props.elements).edges;
												var elementChildren = getOutgoers(node, splitElements(this.props.elements).nodes, allEdges);
												if (elementChildren) {
													var elseChild = elementChildren.find((c) => c.data.data.type == "else");
													if (elseChild) {
														var elseChildIndex = nodes.findIndex((n) => n.id == elseChild.id);
														if (elseChildIndex == -1) {
															var newEdge = {
																id: `edge_${s4()}_${s4()}_${s4()}`,
																source: node.id,
																target: elseChild.id,
																type: "smoothstep",
																animated: false,
																arrowHeadType: "arrowclosed",
																style: { stroke: "#fff" }
															};
															nodes.push(elseChild);
															edges.push(newEdge);
														}
													}
												}
											} else if (node.type == "loopChild") {
												var allEdges = splitElements(this.props.elements).edges;
												var parent = getIncomers(node, splitElements(this.props.elements).nodes, allEdges);
												if (parent) {
													var parentIndex = nodes.findIndex((n) => n.id == parent[0].id);
													if (parentIndex == -1) {
														nodes.push(parent[0]);
													}
												}
											} else if (node.type == "action" && node.data.data.type == "loop") {
												// Clone children
												var allEdges = splitElements(this.props.elements).edges;
												var elementChildren = getOutgoers(node, splitElements(this.props.elements).nodes, allEdges);
												if (elementChildren) {
													elementChildren.forEach((child) => {
														var childIndex = nodes.findIndex((n) => n.id == child.id);
														if (childIndex == -1) {
															nodes.push(child);
														}
													});
												}
											}
											var newNode = copy(node);
											var id_type = "action";
											if (node.type == "condition") {
												id_type = "condition";
											} else if (node.type == "button") {
												id_type = "button";
											} else if (node.type == "selectMenuOption") {
												id_type = "select_menu_option";
											} else if ("data" in node && "data" in node.data && "type" in node.data.data && node.data.data.type == "else") {
												id_type = "else";
											} else if (node.type == "conditionChild") {
												id_type = "conChild";
											}

											var id = `${id_type}_${new Date().getTime()}_${s4()}_${s4()}`;
											newNode.oldId = newNode.id;
											newNode.id = id;
											newNode.position.x += 500;

											newElements.push(newNode);
											if (newNode.type == "option") {
												var newEdge = {
													id: `edge_${s4()}_${s4()}_${s4()}`,
													source: newNode.id,
													target: "root",
													type: "smoothstep",
													animated: false,
													arrowHeadType: "arrowclosed",
													style: { stroke: "#fff" }
												};
												newElements.push(newEdge);
											}
											// });
										}
										var elements = [...this.props.elements, ...newElements];

										edges.forEach((edge, index) => {
											if (edge.source == "root") {
												// edges.splice(index, 1);
											} else {
												var source = newElements.find((element) => element.oldId == edge.source);
												var target = newElements.find((element) => element.oldId == edge.target);

												if (source && target) {
													var newEdge = copy(edge);
													// delete newEdge.id;
													newEdge.id = `edge_${new Date().getTime()}_${s4()}_${s4()}`;
													newEdge.source = source.id;
													newEdge.target = target.id;

													elements.push(newEdge);
												}
											}
										});

										// elements = [...elements, ...newElements, ...edges];

										this.props.setElements(elements);
										// this.props.setSelected(null);
										// Deselect the mutli select
										// this.setState({ selectedElements: { nodes: [], edges: [] } });
										// Click the react flow pane to deselect the nodes
										setTimeout(() => {

											if (window.getSelection) {
												window.getSelection().removeAllRanges();
											} else if (document.selection) {
												document.selection.empty();
											}
											document.querySelector(".react-flow__pane").click();

										}, 100);
									}}
									disabled={this.props.module_id != null && !this.props.premium}
								>
									<FontAwesomeIcon icon={faCopy} /> Duplicate Blocks
								</button>
								{/* <button className="btn btn-gray command-builder-save" onClick={(e) => {
                console.log("DELETE MUTLi");
                this.deleteMulti();
              }}><FontAwesomeIcon icon={faTrash} /> Delete Blocks</button> */}
							</div>
						) : null}
					</ReactFlow>
					;
				</div>
			</KeyEventsHandler>
		);
	}
}

const mapStateToProps = (state) => ({
	elements: state.builder.elements,
	selected: state.builder.selected,
	index: state.builder.index,
	hidden: state.builder.hidden,
	highlightAction: state.builder.highlightAction,
	mode: state.builder.mode,
	module_id: state.builder.module_id,
	premium: state.data.premium
});

const mapDispatchToProps = {
	setElements,
	setSelected,
	updateNode,
	updateElementData
};
let s4 = () => {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
};
function copy(obj) {
	// Get object type
	let type = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

	/**
	 * Create an immutable copy of an object
	 * @return {Object}
	 */
	function cloneObj() {
		let clone = {};
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				clone[key] = copy(obj[key]);
			}
		}
		return clone;
	}

	/**
	 * Create an immutable copy of an array
	 * @return {Array}
	 */
	function cloneArr() {
		return obj.map(function (item) {
			return copy(item);
		});
	}

	// Return a clone based on the object type
	if (type === "object") return cloneObj();
	if (type === "array") return cloneArr();
	return obj;
}

export default withMyHook(connect(mapStateToProps, mapDispatchToProps)(CommandBuilderCanvas));
