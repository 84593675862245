import React, { Component } from 'react';
import { connect } from 'react-redux';
import SlashCommandEditor from './SlashCommandEditor';
import Toggle from './Toggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore } from '@fortawesome/free-solid-svg-icons';
import { CUSTOM_EVENTS } from '../../../variables';
import { setBotModule } from '../../../actions';

export class CustomEventEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showEditor: false
        };
    }

    commandClick = () => {
        console.log();
    };

    renderEditor = () => {
        if (this.state.showEditor == true) {
        } else {

            return null;
        }
    };

    renderDescription = () => {
        var description = "";
        var eventOptions = CUSTOM_EVENTS.find(x => x.value == this.props.event.type);
        if (eventOptions) {
            description = eventOptions.label;
        }
        return description;
    };
    render() {
        return (
            <div className="announcement" style={{ opacity: this.props.event.enabled == false ? "0.5" : "1" }}>
                <div className="announcement-header m-section-content" onClick={(e) => {
                    // Check if the click target is the select element
                    if (e.target.tagName.toLowerCase() !== 'select') {
                        this.setState({ showEditor: !this.state.showEditor });
                    }
                }}>
                    <div className='command-block'>
                        <div className='m-section-content'>
                            <div>
                                <h3 className="mb-0">{this.props.event.name || this.props.event.type}</h3>
                                <div className="section-content-header">{this.renderDescription()} </div>
                            </div>
                        </div>
                        <div className='m-section-content-row'>
                            <div className="mr-4">
                                <select
                                    className="bg-[#40444b] !p-4 !pl-4 text-white opacity-70 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    value={this.props.event.groupId || ''}
                                    onChange={(e) => {
                                        e.stopPropagation(); // Prevent event from bubbling up
                                        const newGroupId = e.target.value;
                                        const data = { ...this.props.custom_events };
                                        const events = [...data.events];
                                        events[this.props.index] = {
                                            ...this.props.event,
                                            groupId: newGroupId
                                        };
                                        data.events = events;
                                        this.props.setBotModule(
                                            {
                                                module: "custom_events",
                                                module_data: data
                                            }
                                        );
                                    }}
                                >
                                    <option value="">No Group</option>
                                    {this.props.groups.map((group) => (
                                        <option key={group.id} value={group.id}>
                                            {group.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                {this.state.showEditor == true ? <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i> : <i style={{ fontSize: "20px" }} class="bi bi-caret-down-fill"></i>}
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    {this.state.showEditor == true ?
                        <SlashCommandEditor
                            event={this.props.event}
                            index={this.props.index}
                            edit={true}
                            mode={"event"}
                            close={() => { this.setState({ showEditor: false }); }}
                        />
                        : null
                    }
                </div>



            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    groups: state.data.bot.commands.commandGroups || [],
    custom_events: state.data.bot.commands.custom_events,
});

const mapDispatchToProps = {
    setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomEventEditor);