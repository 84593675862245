import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toggle from '../elements/Toggle';
import InputTitle from './InputTitle';

export class ModuleToggle extends Component {

    renderDescription = () => {
        if (this.props.settings.html) {
            return <div dangerouslySetInnerHTML={{ __html: this.props.settings.description }}></div>;
        } else {
            return <p>{this.props.settings.description}</p>;
        }
    };

    render() {
        return (
            <div className={`${this.props.settings.premium && !this.props.premium ? "opacity-75 pointer-events-none" : ""} ${!this.props.slot ? "section-content" : "flex mb-15 items-center"}`}>
                <div>
                    <InputTitle settings={this.props.settings} />
                    <div className="section-content-header">
                        {this.renderDescription()}
                    </div>
                </div>

                <div style={{ marginLeft: "auto" }}>
                    <Toggle value={this.props.value} update={(value) => {
                        this.props.change(value);
                    }} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    premium: state.data.premium
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleToggle);