import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Embed } from './Embed';
import RefreshBotData from './RefreshBotData';
import Select from './Select';
import TextArea from './TextArea';
import Toggle from './Toggle';

const timed_messages_intervals = [
    { label: "1 minute", value: { type: "interval", amount: "1", interval: "minute" } },
    { label: "5 minutes", value: { type: "interval", amount: "5", interval: "minute" } },
    { label: "10 minutes", value: { type: "interval", amount: "10", interval: "minute" } },
    { label: "15 minutes", value: { type: "interval", amount: "15", interval: "minute" } },
    { label: "20 minutes", value: { type: "interval", amount: "20", interval: "minute" } },
    { label: "30 minutes", value: { type: "interval", amount: "30", interval: "minute" } },
    { label: "40 minutes", value: { type: "interval", amount: "40", interval: "minute" } },
    { label: "50 minutes", value: { type: "interval", amount: "50", interval: "minute" } },
    { label: "60 minutes", value: { type: "interval", amount: "60", interval: "minute" } },
    { label: "2 hours", value: { type: "interval", amount: "2", interval: "hour" } },
    { label: "3 hours", value: { type: "interval", amount: "3", interval: "hour" } },
    { label: "4 hours", value: { type: "interval", amount: "4", interval: "hour" } },
    { label: "5 hours", value: { type: "interval", amount: "5", interval: "hour" } },
    { label: "6 hours", value: { type: "interval", amount: "6", interval: "hour" } },
    { label: "12 hours", value: { type: "interval", amount: "12", interval: "hour" } },
];

const announcement_types = [
    { value: "join", label: "Join" },
    { value: "ban", label: "Ban" },
    { value: "leave", label: "Leave" }
];

export class CommandAdd extends Component {

    constructor(props) {
        super(props);

        this.state = {
            type: false,
            channel: null,
            name_input: "",
            text: "",
            embed: {
                title: "",
                description: "",
                color: "",
                footer: "",
                thumbnail: "",
                link: "",
            },
            error: "",
            interval: { type: "interval", amount: "1", interval: "minute" },
            announcement_type: "join"

        };
    }



    componentDidMount() {
        if (this.props.value != undefined && this.props.value != {}) {
            const state = { ...this.state };
            Object.keys(this.props.value).forEach(key => {
                state[key] = this.props.value[key];
            });

            if (this.props.type && this.props.type == 'text') {
                state.type = false;
            }
            if (state.embed == undefined) {
                delete state.embed;
            }

            if (state.text == undefined) {
                state.text = "";
            }

            this.setState(state);
        }
    }

    toggleChange = (value) => {
        this.setState({ type: value });
    };

    channelSelectChange = (value) => {
        const channelObject = JSON.parse(value);
        this.setState({ channel: channelObject });
    };

    intervalChange = (value) => {
        const interval = JSON.parse(value);
        // console.log(interval);
        this.setState({ interval: interval });
    };

    textAreaChange = (value) => {
        if (this.state.text.length < 2000) {
            this.setState({ text: value });
        } else {
            this.setState({ text: value.substring(0, 2000) });
        }
    };

    embedChange = (value) => {
        this.setState({ embed: value });
    };

    embedCheck = () => {
        // console.log(this.state.embed);
        if (!("description" in this.state.embed) || this.state.embed.description == "") {
            this.setState({ error: "Please set content for your Embed." });
            return false;
        }
        return true;
    };

    onAnnouncementChange = (value) => {
        this.setState({ announcement_type: value });
    };

    save = () => {
        if (this.state.name_input == null || this.state.name_input == "") {
            this.setState({ error: `Please set a ${this.props.name}` });
        } else if (this.state.channel == null) {
            this.setState({ error: "Please select a channel to post in." });
        } else if (this.state.type == false && this.state.text == "") {
            this.setState({ error: "Please set a message for the announcement" });
        } else if (this.state.type == true && !(this.embedCheck())) {

        } else {
            const object = {
                channel: this.state.channel,
                name: this.state.name_input,
                name_input: this.state.name_input,
                text: this.state.text
            };
            if (this.state.type == false) {
                object.type = "text";
                object.content = this.state.text;
            } else {
                object.type = "embed";
                object.embed = this.state.embed;
            }
            if (this.props.announcement) {
                object.announcement_type = this.state.announcement_type;
            }

            if (this.props.interval == true) {
                object.interval = this.state.interval;
            }
            this.props.save(object);

            if (this.props.reset != false) {
                this.reset();
            }
        }
    };

    reset = () => {
        this.setState({
            type: false,
            channel: null,
            name_input: "",
            text: "",
            embed: {
            },
            error: "",
            announcement_type: "join"
        });
    };

    premiumDisable = () => {
        if (this.props.premiumRequired == true && this.props.premium == false) {
            return true;
        } else {
            return false;
        }
    };


    render() {
        return (
            <div className="section-content-normal">
                {this.state.error != "" ?
                    <div className="errors">
                        <div class="alert alert-danger" role="alert">
                            {this.state.error}
                        </div>
                    </div> :
                    null}
                {this.props.name_input != false ?
                    <div className="long-input mb-15">
                        <label>{this.props.name}</label>
                        <input disabled={this.premiumDisable()} value={this.state.name_input} maxLength={50} onChange={(e) => { this.setState({ name_input: e.target.value }); }} type="text"></input>
                    </div>
                    : null
                }

                <div className="flex-row m-section-content">
                    <div style={{ width: "100%", marginRight: "20px" }}>
                        <div className="justify-space-between">
                            <h3>{this.props.title || "Message"}</h3>
                            <div className="section-content-title justify-space-between">
                                <span style={{ marginInlineEnd: "12px" }}>{this.state.type ? "Embed" : "Plain Text"}</span>
                                <Toggle value={this.state.type} update={this.toggleChange} type="type"></Toggle>
                            </div>
                        </div>

                        {this.state.type ? <Embed disabled={this.premiumDisable()} value={this.state.embed} update={this.embedChange}></Embed> : <TextArea customVariables={this.props.customVariable ? true : false} variableEditor={this.props.variableEditor} announcement={this.props.announcement} disabled={this.premiumDisable()} value={this.state.text} onChange={this.textAreaChange}></TextArea>}
                    </div>

                    <div style={{ width: "30%" }} className="m-w-100">
                        <div className="mb-15">
                            <h3>Post in Channel <RefreshBotData ></RefreshBotData></h3>
                            <Select disabled={this.premiumDisable()} type="channel" onChange={this.channelSelectChange} value={JSON.stringify(this.state.channel)} options={[]}></Select>
                        </div>

                        {this.props.interval == true ?
                            <div>
                                <h3>Interval</h3>
                                <Select disabled={this.premiumDisable()} type="interval" value={JSON.stringify(this.state.interval)} onChange={this.intervalChange} options={timed_messages_intervals}></Select>
                            </div>
                            : null}

                        {this.props.announcement == true ?
                            <div>
                                <h3>Announcement Type</h3>
                                <Select disabled={this.premiumDisable()} value={this.state.announcement_type} options={announcement_types} onChange={this.onAnnouncementChange}></Select>
                            </div>
                            : null}

                    </div>
                </div>


                <div className="commandAdd-buttons">
                    {this.props.delete == true ? <button className="btn btn-gray" onClick={(e) => { this.props.onDelete(); }}>Delete</button> : null}
                    {this.props.reset != false ? <button disabled={this.premiumDisable()} className="btn btn-gray" onClick={(e) => { this.reset(); }}>Reset</button> : null}
                    <button disabled={this.premiumDisable()} className="btn btn-red" onClick={(e) => { this.save(); }}>{this.props.saveText || "Save"}</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bot_data: state.data.bot_data,
    premium: state.data.premium
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CommandAdd);
