import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";
import { TextArea } from "../elements/TextArea";
import { Select } from "../elements/Select";
import TwitterEditor from "../elements/TwitterEditor";

import Dashboard from "../Dashboard";

import { setBotModule } from "../../../actions";
import PremiumRedirect from "../elements/PremiumRedirect";
import PremiumSection from "../elements/PremiumSection";

export class Twitter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEdit: null,
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  commandAdd = (value, index) => {
    const twitter = { ...this.props.twitter };
    if (index == undefined) {
      twitter.tracking.push(value);
    } else {
      twitter.tracking[index] = value;
    }

    this.props.setBotModule({
      module: "twitter",
      module_data: twitter,
    });

    this.setState({ showEdit: null });
  };

  renderTwitterChannels = () => {
    var channels = [];
    this.props.twitter.tracking.forEach((channel, index) => {
      channels.push(
        <div className="announcement">
          <div
            className="announcement-header"
            onClick={(e) => {
              this.setState({ showEdit: index });
            }}
          >
            <div>
              <h3 className="mb-0">@{channel.username}</h3>
              <div className="section-content-header">
                {`Posting an announcement in ${channel.channel}`}{" "}
              </div>
            </div>
            <div style={{ marginLeft: "auto" }}>
              {this.state.showEdit == index ? (
                <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i>
              ) : (
                <i
                  style={{ fontSize: "20px" }}
                  class="bi bi-caret-down-fill"
                ></i>
              )}
            </div>
          </div>

          <div>
            {this.state.showEdit == index ? (
              <TwitterEditor
                saveText="Save"
                save={this.commandAdd}
                index={index}
                value={channel}
                showDelete={true}
                delete={this.delete}
              />
            ) : null}
          </div>
        </div>
      );
    });
    return channels;
  };

  delete = (index) => {
    const twitter = { ...this.props.twitter };

    twitter.tracking.splice(index, 1);

    this.props.setBotModule({
      module: "twitter",
      module_data: twitter,
    });
    this.setState({ showEdit: null });
  };

  moduleEnable = (value) => {
    const twitter = { ...this.props.twitter };
    twitter.enabled = value;

    this.props.setBotModule({
      module: "twitter",
      module_data: twitter,
    });
  };

  render() {
    return (
      <Dashboard>
        <PremiumRedirect></PremiumRedirect>
        <ModuleHeader
          tutorial="https://docs.botghost.com/social-integrations/twitter"
          enabledValue={this.props.twitter.enabled}
          change={this.moduleEnable}
          premiumRequired={true}
          docs="hhttps://docs.botghost.com/social-integrations/twitter"
          description="Have new tweets automatically posted in your server"
          title="Twitter"
          icon="twitter.png"
        ></ModuleHeader>

        <PremiumSection>
          <SectionHeader
            title="New Twitter Target"
            pretitle="Twitter"
          ></SectionHeader>

          <TwitterEditor saveText="Add" save={this.commandAdd}></TwitterEditor>
        </PremiumSection>

        <PremiumSection>
          <SectionHeader
            title="Tracked Twitter Handles"
            pretitle="Twitter"
          ></SectionHeader>

          {this.renderTwitterChannels()}
        </PremiumSection>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  twitter: state.data.bot.commands.twitter,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Twitter);
