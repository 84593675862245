import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setBotModule } from '../../../actions';
import Dashboard from '../Dashboard';
import Command from '../elements/Command';
import LongInput from '../elements/LongInput';
import ModuleHeader from '../elements/ModuleHeader';
import RefreshBotData from '../elements/RefreshBotData';
import RoleAdd from '../elements/RoleAdd';
import SectionHeader from '../elements/SectionHeader';
import Select from '../elements/Select';
import Toggle from '../elements/Toggle';
import ModuleDisabled from '../elements/ModuleDisabled';

export class Tickets extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newCatName: "",
            newCatRoles: [],
            newCat: null,
            newCatMessage: ""
        };
    }

    renderCategories = () => {
        var cats = [];
        this.props.tickets.options.categories.forEach((cat, index) => {
            cats.push(
                <div className='section-content m-section-content'>
                    <div>
                        <h3>{cat.name}</h3>
                        <div className='section-content-header'>Category Id: {cat.category_id}</div>
                    </div>

                    <div style={{ marginLeft: "auto" }} className="m-w-100 m-mt-100">
                        <button className="btn btn-red m-w-100" onClick={() => {
                            var tickets = { ...this.props.tickets };
                            tickets.options.categories.splice(index, 1);
                            this.props.setBotModule({
                                module: "tickets",
                                module_data: tickets,
                            });
                        }}>Delete Category</button>
                    </div>
                </div>
            );
        });
        return cats;
    };
    render() {
        return (
            <Dashboard>
                <ModuleHeader
                    tutorial="https://docs.botghost.com/server-management/tickets"
                    // showEnabled={true}
                    title="Tickets"
                    docs="https://docs.botghost.com/"
                    description="Manage support tickets through your server"
                    icon="tickets.png"
                    enabledValue={this.props.tickets.enabled}
                    change={(value) => {
                        // {
                        var tickets = { ...this.props.tickets };
                        tickets.enabled = value;

                        this.props.setBotModule({
                            module: "tickets",
                            module_data: tickets,
                        });
                        // }

                    // Disable Custom Module if tickets are enabled
                    if (value) {
                        this.checkRedundantModules();
                    }

                    function checkRedundantModules() {
                        const ticketsModuleId = "4393a6b8-0282-47fe-a7a9-a58584943e82";
                        if (this.props.moduleSettings && this.props.moduleSettings[ticketsModuleId]) {
                            var settings = { ...this.props.moduleSettings };
                            settings[ticketsModuleId].enabled = false;
                            this.props.setBotModule({
                                module: "moduleSettings",
                                module_data: settings,
                            });
                        }
                    }
                    }
                    }
                ></ModuleHeader>


                <ModuleDisabled disabled={!this.props.tickets.enabled}>

                    <section>
                        <SectionHeader title="General Options" pretitle="Tickets" />

                        <div className="section-content">
                            <div>
                                <h3>Ping Roles</h3>
                                <div className="section-content-header">
                                    Whether or not to ping roles upon ticket creation.
                                </div>
                            </div>

                            <div style={{ marginLeft: "auto" }}>
                                <Toggle value={this.props.tickets.options.pingRoles} update={(value) => {
                                    var tickets = { ...this.props.tickets };
                                    tickets.options.pingRoles = value;
                                    this.props.setBotModule({
                                        module: "tickets",
                                        module_data: tickets,
                                    });
                                }} />
                            </div>
                        </div>

                        <div className="section-content-normal">
                            <div>
                                <h3 className='mb-0'>Ticket access roles</h3>
                                <div className="section-content-header">Roles that should have access to view tickets.</div>
                            </div>

                            <div className="mt-15">
                                <RoleAdd items={this.props.tickets.options.roles} update={(roles) => {
                                    var tickets = { ...this.props.tickets };
                                    tickets.options.roles = roles;
                                    this.props.setBotModule({
                                        module: "tickets",
                                        module_data: tickets,
                                    });
                                }} />
                            </div>
                        </div>

                        <div className="section-content-normal">
                            <div>
                                <h3 className='mb-0'>Blacklisted Roles</h3>
                                <div className="section-content-header">Ban roles from creating tickets</div>
                            </div>

                            <div className="mt-15">
                                <RoleAdd items={this.props.tickets.options.blacklistRoles} update={(roles) => {
                                    var tickets = { ...this.props.tickets };
                                    tickets.options.blacklistRoles = roles;
                                    this.props.setBotModule({
                                        module: "tickets",
                                        module_data: tickets,
                                    });
                                }} />
                            </div>
                        </div>

                        <div className="section-content">
                            <div>
                                <h3>Ticket Transcripts</h3>
                                <div className="section-content-header">
                                    Create a downloadable transcript when a ticket is closed.
                                </div>
                            </div>

                            <div style={{ marginLeft: "auto" }}>
                                <Toggle value={this.props.tickets.options.transcript} update={(value) => {
                                    var tickets = { ...this.props.tickets };
                                    tickets.options.transcript = value;
                                    this.props.setBotModule({
                                        module: "tickets",
                                        module_data: tickets,
                                    });
                                }} />
                            </div>
                        </div>




                    </section>

                    <section>
                        <SectionHeader title="Category Settings" pretitle="Tickets" />


                        <div className="section-content-normal">
                            <div>
                                <h3 className='mb-0'><RefreshBotData /> Default Ticket Category</h3>
                                <div className="section-content-header">The default channel category to post tickets to. If no other categories are added and selected, tickets will be posted here.</div>
                            </div>

                            <div className="mt-15">
                                <Select
                                    type="categories"
                                    value={JSON.stringify(
                                        this.props.tickets.options.category != undefined ?
                                            {
                                                id: this.props.tickets.options.category.id,
                                                name: this.props.tickets.options.category.name,
                                                type: 4
                                            } : {}
                                    )}
                                    onChange={(value) => {
                                        var category = JSON.parse(value);

                                        var cat = {
                                            category_id: category.id,
                                            id: category.id,
                                            name: category.name,
                                        };
                                        var tickets = { ...this.props.tickets };
                                        tickets.options.category = cat;
                                        this.props.setBotModule({
                                            module: "tickets",
                                            module_data: tickets,
                                        });
                                    }}
                                ></Select>
                            </div>
                        </div>

                        <div className='section-content-normal'>

                            <LongInput
                                title={"Default Ticket Open Message"}
                                description={"The default message that is sent when someone opens a ticket."}
                                label={"Message"}
                                placeholder={"Thank you for opening a ticket!"}
                                value={this.props.tickets.options.created_message}
                                change={(value) => {
                                    var tickets = { ...this.props.tickets };
                                    tickets.options.created_message = value;
                                    this.props.setBotModule({
                                        module: "tickets",
                                        module_data: tickets,
                                    });
                                }}
                            />

                        </div>


                        <div className="section-content-normal">
                            <div>
                                <h3 className='mb-0'>Add Extra Ticket Categories</h3>
                                <div className="section-content-header">Add extra ticket categories for users to select from.</div>
                            </div>

                            <form onSubmit={(e) => {
                                e.preventDefault();
                                var newCat = {
                                    name: this.state.newCatName,
                                    roles: this.state.newCatRoles,
                                    category_id: this.state.newCat.id,
                                    created_message: this.state.newCatMessage,
                                };

                                var tickets = { ...this.props.tickets };
                                tickets.options.categories.push(newCat);
                                this.props.setBotModule({
                                    module: "tickets",
                                    module_data: tickets,
                                });
                                this.setState({
                                    newCatName: "",
                                    newCatRoles: [],
                                    newCat: null,
                                    newCatMessage: ""
                                });

                            }}>
                                <div className="mt-15">
                                    <LongInput
                                        title={"Category Name"}
                                        description={"The name of the new ticket category. This will be displayed to users when they create a ticket."}
                                        label={"Name"}
                                        placeholder={"Sales"}
                                        value={this.state.newCatName}
                                        change={(value) => {
                                            this.setState({ newCatName: value });
                                        }}
                                    />
                                </div>



                                <div>
                                    <h3 style={{ marginBottom: "0px" }}><RefreshBotData /> Server Category</h3>
                                    <div className="section-content-header">The Discord server category to post the tickets to when a user selects this category.</div>


                                    <div className="mt-15">
                                        <Select
                                            required={true}
                                            type="categories"
                                            value={JSON.stringify(
                                                this.state.newCat
                                            )}
                                            onChange={(value) => {
                                                var category = JSON.parse(value);
                                                this.setState({ newCat: category });
                                            }}
                                        ></Select>
                                    </div>

                                </div>


                                <div className='mt-15'>
                                    <h3 style={{ marginBottom: "0px" }}><RefreshBotData /> Category Roles</h3>
                                    <div className="section-content-header">Roles that should have access to tickets in this category.</div>


                                    <div className="mt-15">
                                        <RoleAdd items={this.state.newCatRoles} update={(values) => {
                                            this.setState({ newCatRoles: values });
                                        }} />
                                    </div>

                                </div>

                                <div className='mt-15'>
                                    <LongInput
                                        title={"Category Open Message"}
                                        description={"This categories message that is sent when someone opens a ticket."}
                                        label={"Message"}
                                        placeholder={"Thank you for opening a ticket!"}
                                        value={this.state.newCatMessage}
                                        change={(value) => {
                                            this.setState({ newCatMessage: value });
                                        }}
                                    />
                                </div>

                                <div className='mt-15'>
                                    <button className='btn btn-red m-w-100' disabled={this.state.newCat == null}>Add Ticket Category</button>
                                </div>
                            </form>


                        </div>

                        <div>
                            {this.renderCategories()}
                        </div>


                    </section>

                    <section>
                        <SectionHeader title="Ticket Logging" pretitle="Tickets" />

                        <div className="section-content">
                            <div>
                                <h3>Log Ticket Events</h3>
                                <div className="section-content-header">
                                    Whether or not to log ticket events to a ticket logging channel
                                </div>
                            </div>

                            <div style={{ marginLeft: "auto" }}>
                                <Toggle value={this.props.tickets.options.logEvents} update={(value) => {
                                    var tickets = { ...this.props.tickets };
                                    tickets.options.logEvents = value;
                                    this.props.setBotModule({
                                        module: "tickets",
                                        module_data: tickets,
                                    });
                                }} />
                            </div>
                        </div>

                        <div className="section-content-normal">
                            <div>
                                <h3 className='mb-0'><RefreshBotData /> Ticket Logging Channel</h3>
                                <div className="section-content-header">The channel to log ticket events to</div>
                            </div>

                            <div className="mt-15">
                                <Select
                                    type="channel"
                                    value={JSON.stringify(
                                        this.props.tickets.options.logChannel
                                    )}
                                    onChange={(value) => {
                                        var channel = JSON.parse(value);
                                        var tickets = { ...this.props.tickets };
                                        tickets.options.logChannel = channel;
                                        this.props.setBotModule({
                                            module: "tickets",
                                            module_data: tickets,
                                        });
                                    }}
                                ></Select>
                            </div>
                        </div>
                    </section>
                    <section>

                        <SectionHeader title="Modal Settings" pretitle="Tickets" />
                        <div className='section-content-normal'>

                            <LongInput
                                title={"Modal Title"}
                                description={"The title of the modal that users interact with through a ticket panel."}
                                label={"Title"}
                                maxLength={45}
                                placeholder={"What is your question?"}
                                value={this.props.tickets.options.modal.title}
                                change={(value) => {
                                    var tickets = { ...this.props.tickets };
                                    tickets.options.modal.title = value;
                                    this.props.setBotModule({
                                        module: "tickets",
                                        module_data: tickets,
                                    });
                                }}
                            />

                        </div>


                        <div className='section-content-normal'>

                            <LongInput
                                title={"Modal Description"}
                                description={"The description of the modal that users interact with through a ticket panel."}
                                label={"Description"}
                                maxLength={45}
                                placeholder={"What is your question?"}
                                value={this.props.tickets.options.modal.description}
                                change={(value) => {
                                    var tickets = { ...this.props.tickets };
                                    tickets.options.modal.description = value;
                                    this.props.setBotModule({
                                        module: "tickets",
                                        module_data: tickets,
                                    });
                                }}
                            />

                        </div>
                    </section>

                    {/* Commands */}
                    <section>
                        <SectionHeader title="General Commands" pretitle="Tickets" />
                        <div className='section-content-normal'>
                            <LongInput
                                title={"General commands group trigger"}
                                description={"The group trigger for general ticket commands."}
                                label={"Trigger"}
                                placeholder={"tickets"}
                                value={this.props.tickets.trigger}
                                change={(value) => {
                                    var tickets = { ...this.props.tickets };
                                    tickets.trigger = value;
                                    this.props.setBotModule({
                                        module: "tickets",
                                        module_data: tickets,
                                    });
                                }}
                            />

                        </div>

                        <Command
                            update={(value) => {
                                var tickets = { ...this.props.tickets };
                                tickets.create = value;
                                this.props.setBotModule({
                                    module: "tickets",
                                    module_data: tickets,
                                });

                            }}
                            options={this.props.tickets.create}
                            module="tickets"
                            name={"create"}
                            description={
                                "Create a new ticket"
                            }
                            showToggle={true}
                            toggleValue={this.props.tickets.create.enabled}
                            showPrefix={true}
                            slashOnly={true}
                            subcommand={this.props.tickets.trigger != "" ? this.props.tickets.trigger : "tickets"}

                            slash={true}
                        ></Command>

                        <Command
                            update={(value) => {
                                var tickets = { ...this.props.tickets };
                                tickets.close = value;
                                this.props.setBotModule({
                                    module: "tickets",
                                    module_data: tickets,
                                });

                            }}
                            options={this.props.tickets.close}
                            module="tickets"
                            name={"close"}
                            description={
                                "Close a ticket"
                            }
                            showToggle={true}
                            toggleValue={this.props.tickets.close.enabled}
                            showPrefix={true}
                            slashOnly={true}
                            subcommand={this.props.tickets.trigger != "" ? this.props.tickets.trigger : "tickets"}

                            slash={true}
                        ></Command>

                        <Command
                            update={(value) => {
                                var tickets = { ...this.props.tickets };
                                tickets.addUser = value;
                                this.props.setBotModule({
                                    module: "tickets",
                                    module_data: tickets,
                                });

                            }}
                            options={this.props.tickets.addUser}
                            module="tickets"
                            name={"addUser"}
                            description={
                                "Add a user to an open ticket"
                            }
                            showToggle={true}
                            toggleValue={this.props.tickets.addUser.enabled}
                            showPrefix={true}
                            defaultTrigger={'add-user'}
                            slashOnly={true}
                            subcommand={this.props.tickets.trigger != "" ? this.props.tickets.trigger : "tickets"}

                            slash={true}
                        ></Command>

                        <Command
                            update={(value) => {
                                var tickets = { ...this.props.tickets };
                                tickets.removeUser = value;
                                this.props.setBotModule({
                                    module: "tickets",
                                    module_data: tickets,
                                });

                            }}
                            options={this.props.tickets.removeUser}
                            module="tickets"
                            name={"removeUser"}
                            description={
                                "Remove a user to an open ticket"
                            }
                            showToggle={true}
                            toggleValue={this.props.tickets.removeUser.enabled}
                            showPrefix={true}
                            slashOnly={true}
                            subcommand={this.props.tickets.trigger != "" ? this.props.tickets.trigger : "tickets"}
                            defaultTrigger={"remove-user"}
                            slash={true}
                        ></Command>




                    </section>

                    <section>
                        <SectionHeader title="Admin Commands" pretitle="Tickets" />

                        <div className='section-content-normal'>
                            <LongInput
                                title={"Admin commands group trigger"}
                                description={"The group trigger for admin commands."}
                                label={"Trigger"}
                                placeholder={"tickets-admin"}
                                value={this.props.tickets.adminTrigger}
                                change={(value) => {
                                    var tickets = { ...this.props.tickets };
                                    tickets.adminTrigger = value;
                                    this.props.setBotModule({
                                        module: "tickets",
                                        module_data: tickets,
                                    });
                                }}
                            />

                        </div>
                        <Command
                            update={(value) => {
                                var tickets = { ...this.props.tickets };
                                tickets.createPanel = value;
                                this.props.setBotModule({
                                    module: "tickets",
                                    module_data: tickets,
                                });

                            }}
                            options={this.props.tickets.createPanel}
                            module="tickets"
                            name={"createPanel"}
                            description={
                                "Create a ticket panel in a channel. Admin only"
                            }
                            showToggle={true}
                            toggleValue={this.props.tickets.createPanel.enabled}
                            showPrefix={true}
                            slashOnly={true}
                            subcommand={this.props.tickets.adminTrigger != "" ? this.props.tickets.adminTrigger : "tickets-admin"}
                            defaultTrigger={"create-panel"}
                            slash={true}
                        ></Command>

                        <Command
                            update={(value) => {
                                var tickets = { ...this.props.tickets };
                                tickets.addBlacklist = value;
                                this.props.setBotModule({
                                    module: "tickets",
                                    module_data: tickets,
                                });

                            }}
                            options={this.props.tickets.addBlacklist}
                            module="tickets"
                            name={"addBlacklist"}
                            description={
                                "Add a user to the ticket blacklist. Admin only"
                            }
                            showToggle={true}
                            toggleValue={this.props.tickets.addBlacklist.enabled}
                            showPrefix={true}
                            slashOnly={true}
                            subcommand={this.props.tickets.adminTrigger != "" ? this.props.tickets.adminTrigger : "tickets-admin"}
                            defaultTrigger={"blacklist-add"}
                            slash={true}
                        ></Command>

                        <Command
                            update={(value) => {
                                var tickets = { ...this.props.tickets };
                                tickets.removeBlacklist = value;
                                this.props.setBotModule({
                                    module: "tickets",
                                    module_data: tickets,
                                });

                            }}
                            options={this.props.tickets.removeBlacklist}
                            module="tickets"
                            name={"removeBlacklist"}
                            description={
                                "Remove a user to the ticket blacklist. Admin only"
                            }
                            showToggle={true}
                            toggleValue={this.props.tickets.removeBlacklist.enabled}
                            showPrefix={true}
                            slashOnly={true}
                            subcommand={this.props.tickets.adminTrigger != "" ? this.props.tickets.adminTrigger : "tickets-admin"}
                            defaultTrigger={"blacklist-remove"}
                            slash={true}
                        ></Command>
                    </section>
                </ModuleDisabled>
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    tickets: state.data.bot.commands.tickets,
    moduleSettings: state.data.bot.commands.moduleSettings,
});

const mapDispatchToProps = {
    setBotModule
};

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
