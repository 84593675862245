import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setRootData, setBuilderMode, setWebhookId } from '../../../../actions';
import RefreshIFTTT from '../../elements/RefreshIFTTT';
import TextArea from '../../elements/TextArea';
import Select from '../../elements/Select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import RoleAdd from '../../elements/RoleAdd';

import { CUSTOM_EVENTS, CUSTOM_EVENT_CATEGORIES } from '../../../../variables';

const EPHEMERAL_OPTIONS = [
    { value: "true", label: "Hide replies" },
    { value: "false", label: "Show replies to everyone." },
];

const COOLDOWN_OPTIONS = [
    { value: "disabled", label: "No Cooldown" },
    { value: "user", label: "User Cooldown" },
    { value: "server", label: "Server Wide Cooldown" },
];

const COOLDOWN_INTERVAL_OPTIONS = [
    { value: "seconds", label: "Seconds" },
    { value: "minutes", label: "Minutes" },
    { value: "hours", label: "Hours" },
];

export class CustomEvent extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        name: "Channel Create Event",
                        type: "channelCreate",
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        name: "Channel Create Event",
                        type: "channelCreate",
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

            if ((this.props.data.response != "") && this.props.data.validated != true) {
                this.updateData("validated", true);
            } else if (this.props.data.validated == true && (this.props.data.response == "")) {
                this.updateData("validated", false);

            }
        }
    }

    nameChange = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "-");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("name", value);
    };

    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    updateSelect = (value) => {
        // console.log(value);
        if (value == "true") {
            this.updateData('ephemeral', true);
        } else {
            this.updateData('ephemeral', false);
        }
    };

    roleAddChange = (array, key) => {
        const permissions = { ...this.props.data.permissions };
        permissions[key] = array;
        this.updateData("permissions", permissions);
        // console.log(permissions)
        // this.props.update(permissions)

    };

    updateRootInfo = (key, value) => {
        // console.log(key, value);
        this.props.setRootData({ key: key, value, value });

    };

    nameChange = (value) => {



        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "-");
        // value = value.replace(/[^\w]+/gi,"")
        // value = value.replace(/[^[A-zÀ-Ÿ\d-]+/gi,"")
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateRootInfo("title", value);
    };

    renderEventTypes = () => {
        var events = [];

        CUSTOM_EVENTS.forEach(event => {
            events.push({ value: event, label: event });
        });

        return events;
    };

    renderSelect = () => {
        var eventCategories = [...CUSTOM_EVENT_CATEGORIES];
        eventCategories.forEach(cat => {
            cat.children = [];
        });

        var options = [...CUSTOM_EVENTS];
        // console.log(eventCategories);
        options.forEach(event => {
            if (event.beta && !this.props.beta) return;
            var eventCategory = eventCategories.find(category => category.value == event.event_category);
            if (eventCategory) {
                eventCategory.children.push(<option value={event.value}>{event.label}</option>);
            }
        });

        if (this.props.webhooks?.webhooks?.length > 0) {
            eventCategories.push({
                label: "Webhooks",
                children: this.props.webhooks.webhooks.map(webhook => {
                    return (<option value={`WEBHOOK_${webhook.id}`}>{webhook.name} Webhook</option>);
                })
            });
        }

        if (this.props.timed_events?.events?.length > 0) {
            eventCategories.push({
                label: "Timed Events",
                children: this.props.timed_events.events.map(timed_Event => {
                    return (<option value={`TIMED_EVENT_${timed_Event.id}`}>{timed_Event.name} Timed Event</option>);
                })
            });
        }


        var value = this.props.data.type;
        if (value == "webhookExecuted") {
            value = `WEBHOOK_${this.props.data.webhookId}`;
        } else if (value == "timedEvent") {
            value = `TIMED_EVENT_${this.props.data.timer_id}`;
        }
        return (<select value={value} onChange={(e) => {
            if (e.target.value.startsWith("WEBHOOK_")) {
                var data = { ...this.props.data };
                data.type = "webhookExecuted";
                data.webhookId = e.target.value.replace("WEBHOOK_", "");
                this.props.updateElementData(
                    {
                        data: data,
                        id: 'root'
                    }
                );
                this.props.setBuilderMode({
                    mode: "event",
                    components: {
                        Guild: true,
                        Channel: false,
                        User: false,
                        Member: false,
                        Message: false,
                        variables: [
                        ]
                    },
                });
                return;
            } else if (e.target.value.startsWith("TIMED_EVENT_")) {
                var data = { ...this.props.data };
                data.type = "timedEvent";
                data.timer_id = e.target.value.replace("TIMED_EVENT_", "");
                this.props.updateElementData(
                    {
                        data: data,
                        id: 'root'
                    }
                );
                this.props.setBuilderMode({
                    mode: "event",
                    components: {
                        Guild: true,
                        Channel: false,
                        User: false,
                        Member: false,
                        Message: false,
                        variables: [
                        ]
                    },
                });
                return;
            }

            this.updateData('type', e.target.value);
            var event_options = CUSTOM_EVENTS.find(event => event.value == e.target.value);

            this.props.setBuilderMode({
                mode: "event",
                components: event_options.components
            });
        }}>
            {eventCategories.map(category => {
                return (<optgroup className='font-bold' label={category.label}>
                    {category.children}
                </optgroup>);
            })}
        </select>);
    };

    checkIfServerRequired = () => {
        var event_options = CUSTOM_EVENTS.find(event => event.value == this.props.data.type);
        if (event_options.server_required) {
            return true;
        }
        return false;
    };




    render() {
        if (this.props.data != undefined) {
            if (!this.props.ifttt_linked && this.props.data.type == "IFTTTActionEvent") {

                return (
                    <div>
                        <div className="mb-15">
                            <h4>Custom Event</h4>
                            <p>Drag and drop <span style={{ color: "#358deb" }}>Actions</span> and <span style={{ color: "#28a745" }}>Conditions</span> to add them to your event. Connect each nodes of corrosponding colors to create your custom event. </p>

                            <p><span style={{ color: "#358deb" }}>Actions</span> are what your bot executes when the custom event is triggered.</p>

                        </div>


                        <div className="mb-15">
                            <h4>Event Type</h4>
                            <p>The type of this custom event.</p>



                            {/* <Select options={CUSTOM_EVENTS} value={this.props.data.type} onChange={(value) => {
                                this.updateData('type', value);
                                var event_options = CUSTOM_EVENTS.find(event => event.value == value);
    
                                this.props.setBuilderMode({
                                    mode: "event",
                                    components: event_options.components
                                });
                            }}></Select> */}

                            {this.renderSelect()}


                        </div>

                        <hr style={{ borderTop: "1px solid #7878786b" }}></hr>


                        <div class="text-center">
                            <h3 style={{ marginBottom: "0px" }} className="font-bold text-white text-xl">IFTTT BotGhost Link {!this.props.ifttt_linked ? <FontAwesomeIcon icon={faExclamationCircle} color="#f45142" /> : <FontAwesomeIcon icon={faCheckCircle} color="green" />}</h3>

                            <div className="section-content-header mb-2">
                                Click the button below to link your IFTTT account to your BotGhost account. Once you have linked your account, you will be able to create IFTTT Applets that trigger custom events in your bot, and you will be able to trigger IFTTT Applets from your bot.
                            </div>
                            <div className='flex justify-center' >
                                <div className=''>
                                    <RefreshIFTTT />
                                    <a href={process.env.REACT_APP_IFTTT_LINK} target="_blank" className='btn btn-red ml-3 !bg-[#51c8f3]'>Link IFTTT</a>
                                </div>
                            </div>
                        </div>


                    </div>
                );

            } else {
                return (
                    <div>
                        <div className="mb-15">
                            <h4>Custom Event</h4>
                            <p>Drag and drop <span style={{ color: "#358deb" }}>Actions</span> and <span style={{ color: "#28a745" }}>Conditions</span> to add them to your event. Connect each nodes of corrosponding colors to create your custom event. </p>

                            <p><span style={{ color: "#358deb" }}>Actions</span> are what your bot executes when the custom event is triggered.</p>

                        </div>

                        <hr style={{ borderTop: "1px solid #7878786b" }}></hr>

                        <div className="mb-15">
                            <h4>Event Type</h4>
                            <p>The type of this custom event.</p>



                            {/* <Select options={CUSTOM_EVENTS} value={this.props.data.type} onChange={(value) => {
                                this.updateData('type', value);
                                var event_options = CUSTOM_EVENTS.find(event => event.value == value);
    
                                this.props.setBuilderMode({
                                    mode: "event",
                                    components: event_options.components
                                });
                            }}></Select> */}

                            {this.renderSelect()}


                        </div>

                        <div>
                            <div className="mb-15">
                                <h4>Event Nickname</h4>
                                <p>A nickname for this custom event. This will not be displayed to users.</p>
                                <div className="long-input">
                                    <label>Nickname</label>
                                    <input maxLength={99} className={`${this.props.description == "" && this.props.saveFailed ? "command-required" : ""}`} required type="text" value={this.props.data.name} onChange={(e) => {
                                        this.updateData('name', e.target.value);
                                    }}></input>
                                </div>
                            </div>
                        </div>



                        {this.checkIfServerRequired() ?
                            <>
                                <hr style={{ borderTop: "1px solid #7878786b" }}></hr>
                                <div>
                                    <div className="mb-15">
                                        <h4>Discord Server ID</h4>
                                        <p>The base discord server for this event. This event requires a discord server for it to operate.</p>
                                        <div className="long-input">
                                            <label>Server ID</label>
                                            <input maxLength={99} className={`${(this.props.guild_id == "" || !this.props.guild_id) && this.props.saveFailed ? "command-required" : ""}`} required type="text" value={this.props.data.guild_id} onChange={(e) => {
                                                this.updateData('guild_id', e.target.value);
                                            }}></input>
                                        </div>
                                    </div>
                                </div>
                            </> : null}

                        {
                            this.props.data.type == "IFTTTActionEvent" ?
                                <>
                                    <hr style={{ borderTop: "1px solid #7878786b" }}></hr>
                                    <div>
                                        <div className="mb-15">
                                            <h4>IFTTT Event ID <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/ifttt#trigger-a-custom-event-ifttt-actions"}></DocsLink></h4>
                                            <p>The ID you have set in your IFTTT Applet. Any IFTTT Action with this ID will trigger this custom event. </p>
                                            <div className="long-input">
                                                <label>Event ID</label>
                                                <input maxLength={99} className={`${(this.props.iftttId == "" || !this.props.iftttId) && this.props.saveFailed ? "command-required" : ""}`} required type="text" value={this.props.data.iftttId} onChange={(e) => {
                                                    this.updateData('iftttId', e.target.value);
                                                }}></input>
                                            </div>
                                        </div>
                                    </div>
                                </> : null
                        }



                    </div >
                );
            }

        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    beta: state.data.beta,
    webhooks: state.data.bot.commands.webhooks,
    timed_events: state.data.bot.commands.timed_events,
    ifttt_linked: state.data.user.ifttt_linked
});
const mapDispatchToProps = {
    updateElementData,
    setRootData,
    setBuilderMode,
    setWebhookId
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomEvent);
