import {combineReducers} from 'redux'
import authReducer from './authReducer'
import dataReducer from './dataReducer'
import pageReducer from './pageReducer'
import notificationReducer from './notificationReducer'
import quickStartReducer from "./quickStartReducer"
import builderReducer from "./builderReducer"


export default combineReducers({
    page:pageReducer,
    auth:authReducer,
    data:dataReducer,
    notifications:notificationReducer,
    quick_start:quickStartReducer,
    builder:builderReducer
//     id:idReducer,
//     data:dataReducer,
//     auth:authReducer
})
