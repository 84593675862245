import React, { Component } from 'react'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGripVertical, faHandPointer, faMousePointer } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export class CommandBuilderTemplate extends Component {



      componentDidMount() {
        //   $(".command-builder-object").draggable();
      }

    render() {
        return (

                <div onClick={() =>{this.props.importCommand(this.props.data,this.props.title)}} className="command-builder-object command-template-hover " >

                    <div className="inner">
                    {/* <div className="object-grip">
                            <FontAwesomeIcon icon={faHandPointer} />
                        </div> */}
                        <div className={`object-icon object-icon-template`}>
                            <FontAwesomeIcon icon={this.props.icon}/>
                        </div>

                        <div className="object-info">
                            <span className="object-title">{this.props.title}</span>
                            <span className="object-description">{this.props.description}</span>
                        </div>
                    </div>
                </div>
 

        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(CommandBuilderTemplate)
