import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../../../api/server';

import history from "../../../history";

export class CommandImporter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            code: "",
            error: "",
            disabled: false
        };
    }

    componentDidMount() {
        // navigator.clipboard.readText()
        //     .then(text =>{
        //         console.log(text)
        //     })
        //     .catch((e) =>{
        //         console.log(e)
        //     })
    }

    modalBackgroundClick = (e) => {
        // console.log("MODAL BACKGROUND CLICK");
        if (e.currentTarget == e.target) {
            this.props.closeModal(e);
        }
    };

    import = async () => {
        this.setState({ disabled: true, error: "" });
        const response = await server.post("/dashboard/importcommand", { id: this.state.code });
        // console.log(response, 'response');
        if (response.data == false || response.data.command == undefined) {
            // ERROR
            this.setState({ disabled: false, error: "Could not find a command with that share code." });
        } else {
            if (response.data.type && response.data.type != this.props.mode) {
                this.setState({ disabled: false, error: "This is not a " + this.props.mode + " share code." });

            } else {
                this.props.importCommand(response.data.command, response.data.type);

            }
            // this.props.importCommand(response.data.command, response.data.type);
        }
    };



    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} onClick={(e) => { this.modalBackgroundClick(e); }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div class="modal-dialog premium-modal-dialog" role="document">
                        <div class="modal-content premium-modal" style={{ backgroundColor: "#f8f8fb" }}>


                            <div class="modal-body command-edit-body premium-modal-body">
                                <div className="premium-modal-content">
                                    <h2 className="text-2xl font-bold">Import {this.props.mode == "event" ? "an Event" : "a Command"}</h2>
                                    <p className="text-muted mb-3 font-semibold">Import {this.props.mode == "event" ? "an event" : "a command"} using a {this.props.mode == "event" ? "event" : "command"} share code.</p>
                                    <span style={{ color: "red", fontWeight: "800" }}>{this.state.error}</span>
                                    <div className="long-input mb-15" style={{ width: "100%" }}>
                                        <label>Share Code</label>
                                        <input placeholder={`${this.props.mode == "event" ? "EVENT" : "CMD"}_IASDAD-2399ASDAS-293293ASD-ASD`} required type="text" value={this.state.code} onChange={(e) => { this.setState({ code: e.target.value.trim() }); }}></input>
                                    </div>

                                    <div>
                                        <button className="btn btn-gray" type="button" onClick={() => { this.props.closeModal(); }}>Close</button>

                                        <button className="btn btn-red ml-2" type="button" disabled={this.state.code == "" || this.state.disabled} onClick={() => { this.import(); }}>Import</button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    mode: state.builder.mode

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CommandImporter);
