import React, { Component } from 'react';
import { connect } from 'react-redux';

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import PremiumBadge from '../elements/PremiumBadge';
import ReactGA from 'react-ga';
import { updateBeta, setBotSettings, upgradeBot, setOnlineStatus, changeIMG, changeName, setAuth, setQuickStart } from "../../../actions";
import { Steps, Hints } from 'intro.js-react';
import 'intro.js/introjs.css';

import server from "../../../api/server";

import Dashboard from "../Dashboard";
import DeleteBotModal from '../elements/DeleteBotModal';
import PremiumModal from '../elements/PremiumModal';
import IntentsFailedModal from '../elements/IntentsFailedModal';
import Select from '../elements/Select';
import { LANGUAGE_OPTIONS } from '../../../variables';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfinity } from '@fortawesome/free-solid-svg-icons';
import TextStep from '../elements/TextStep';
import RevokeTokenModal from '../elements/RevokeTokenModal';
import history from '../../../history';


var INTENT_OPTIONS = [
  { value: "ALWAYS", label: "Always Enable" },
  { value: "NEVER", label: "Never Enable" },
  { value: "AUTO", label: "Auto" }
];

export class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepsEnabled: true,
      online: false,
      upgradeDisabled: false,
      disabled: false,
      timeoutID: null,
      saved: null,
      name: null,
      name_error: null,
      img_error: null,
      showExpired: false,
      showIntentsFail: false,
      restarting: false,
      showRevokeToken: false,
      premium_code: "",
      premium_code_disabled: false,
      premium_error: ""
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.setState({ name: this.props.data.bot.name });
    this.setState({ name_error: null });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.bot.name != this.props.data.bot.name) {
      this.setState({ name: this.props.data.bot.name });
    }
    clearTimeout(prevState.timeoutID);
  }

  toggleChange = (value, type) => {
    this.props.setBotSettings({ value: value, key: type });
  };

  renderSubscriptionType = () => {
    if (this.props.premium) {
      return "Premium";
    } else {
      return "Free";
    }
    // const sub_type = this.props.data.bot.subscription.sub_type;
    // if (sub_type == "trial") {
    //   return "Basic";
    // } else if (sub_type == "monthly") {
    //   return "Monthly";
    // } else if (sub_type == "yearly") {
    //   return "Yearly";
    // } else if (sub_type == "lifetime") {
    //   return "Lifetime";
    // } else if (sub_type == "freepremium") {
    //   if (this.props.data.bot.subscription.gift) {
    //     return "Premium Gift";
    //   } else {
    //     return "Free Premium";
    //   }
    // }
  };

  upgradeBot = async () => {
    this.setState({ upgradeDisabled: true });
    const response = await server.post("/dashboard/upgrade", {
      bot: this.props.bot,
    });

    if (response.data && response.data.upgraded != false) {
      this.props.upgradeBot(response.data);
    } else if (
      response.data &&
      response.data.upgraded == false &&
      response.data.reason == "logged"
    ) {
      this.props.setAuth();
    }
    this.setState({ upgradeDisabled: false });
  };

  start = async () => {
    this.setState({ disabled: true });
    var obj = {
      bot: this.props.bot,
      id: this.props.bot.id,
    };

    obj.collab = this.props.collab ? true : false;

    // if (this.props.cocreate == true) {
    //   obj.cocreate = true;
    //   var host_user_id = null;
    //   this.props.bots.forEach((bot) => {
    //     if (bot.id == this.props.bot.id && bot.cocreator == true) {
    //       host_user_id = bot.host_user_id;
    //     }
    //   });
    //   obj.host_user_id = host_user_id;
    // }
    const response = await server.post("/dashboard/start", obj);
    if (response.data && response.data.response == true) {
      this.props.setOnlineStatus(true);
    } else if (
      response.data &&
      response.data.response == false &&
      response.data.reason == "logged"
    ) {
      this.props.setAuth();
    } else if (response.data &&
      response.data.response == false &&
      response.data.reason == "premium") {
      window.location.reload();
    }
    var quick_start = { ...this.props.quick_start };
    quick_start["on_off"] = true;
    this.props.setQuickStart(quick_start);
    this.setState({ disabled: false });
    // console.log("START DONE");
    return;
  };

  stop = async (restart) => {
    // if(this.props.bot.expired == true && this.props.premium == false){
    //     this.setState({showExpired:true})
    //     console.log("EXPIRED!!!")
    //     return;
    // }
    this.setState({ disabled: true });
    var obj = {
      id: this.props.bot.id,
    };

    obj.collab = this.props.collab ? true : false;

    // if (this.props.cocreate == true) {
    //   obj.cocreate = true;
    //   var host_user_id = null;
    //   this.props.bots.forEach((bot) => {
    //     if (bot.id == this.props.bot.id && bot.cocreator == true) {
    //       host_user_id = bot.host_user_id;
    //     }
    //   });
    //   obj.host_user_id = host_user_id;
    // }
    const response = await server.post("/dashboard/stop", obj);
    if (response.data && response.data.response == true && restart != true) {
      this.props.setOnlineStatus(false);
    } else if (
      response.data &&
      response.data.response == false &&
      response.data.reason == "logged"
    ) {
      this.props.setAuth();
    }
    this.setState({ disabled: false });
    // console.log("STOP DONE");
    return;
  };

  handleChange = async (type, e) => {
    const changeSetting = async (type) => {
      if (type == "name") {
        const response = await server
          .post("/dashboard/name", {
            name: this.state.name,
            token: this.props.data.bot.token,
            id: this.props.data.bot.id,
          })
          .catch((e) => { });

        // console.log(response);
        if (response != undefined && response.data == true) {
          ReactGA.initialize("UA-122665050-1");
          ReactGA.event({
            category: "Change Setting",
            label: `Name`,
            action: "New Dashboard",
          });
          this.setState({ saved: "name" });
          this.props.changeName(this.state.name);

          var quick_start = { ...this.props.quick_start };
          quick_start["bot_name"] = true;
          this.props.setQuickStart(quick_start);
        } else {
          if (response != undefined && response.data == "logged") {
            this.props.setAuth();
          } else if (
            response != undefined &&
            response.data != false &&
            "username" in response.data
          ) {
            this.setState({ name_error: response.data.username[0] });
          } else if (response != undefined && response.data == false) {
            this.setState({
              name_error:
                "You have been rate-limited. Please wait a few minutes before trying again.",
            });
          }
          this.setState({ name: this.props.data.bot.name });
        }
      } else {
      }
    };

    if (type == "name") {
      this.setState({ name: e.target.value, name_error: null });
    }
    //     const timeoutID = setTimeout(function () {
    //       changeSetting("name");
    //     }, 1000);
    //     this.setState({ timeoutID: timeoutID });
    changeSetting("name");
  };

  onFileChange = async (e) => {
    if (e.target.files[0] == undefined || e.target.files[0].size >= 10240000) {
      this.setState({
        img_error: "Your file is too large. Max image size is 10MB",
      });
    } else {
      this.setState({ img_error: "" });
      const formData = new FormData();
      formData.append("avatar", e.target.files[0]);
      const obj = {
        token: this.props.data.bot.token,
        id: this.props.data.bot.id,
      };
      const json = JSON.stringify(obj);
      const blob = new Blob([json], {
        type: "application/json",
      });

      formData.append("data", blob);
      const response = await server
        .post("/dashboard/avatar", formData)
        .catch((e) => { });

      if (response != undefined && response.data.updated == true) {
        ReactGA.initialize("UA-122665050-1");
        ReactGA.event({
          category: "Change Setting",
          label: `Avatar`,
          action: "New Dashboard",
        });
        this.setState({ saved: "avatar" });
        this.props.changeIMG(response.data.data);

        var quick_start = { ...this.props.quick_start };
        quick_start["bot_avatar"] = true;
        this.props.setQuickStart(quick_start);
      } else {
        if (response == undefined) {
          this.setState({
            img_error: "Server Error: Please retry in 30 seconds",
          });
        } else if (response.data == "logged") {
          this.props.setAuth();
        } else if (
          response.data.data != undefined &&
          response.data != false &&
          "avatar" in response.data.data
        ) {
          this.setState({ img_error: response.data.data.avatar[0] });
        }
      }
    }
  };

  logOut = async () => { };

  closeDelete = () => {
    this.setState({ showDelete: false });
  };

  changePrefix = (value) => {
    this.props.setBotSettings({ value: value, key: "prefix" });
    var quick_start = { ...this.props.quick_start };
    quick_start["prefix"] = true;
    this.props.setQuickStart(quick_start);
  };

  updateBeta = async (value) => {
    this.props.updateBeta(value);

    await this.stop(true);
    var obj = {
      beta: value,
      bot_id: this.props.bot.id
    };
    obj.collab = this.props.collab ? true : false;
    // if (this.props.cocreate == true) {
    //   obj.cocreate = true;
    //   var host_user_id = null;
    //   this.props.bots.forEach((bot) => {
    //     if (bot.id == this.props.bot.id && bot.cocreator == true) {
    //       host_user_id = bot.host_user_id;
    //     }
    //   });
    //   obj.host_user_id = host_user_id;
    // }
    const response = await server
      .post("/dashboard/beta", obj)
      .catch((e) => { });

    await this.start();
  };

  restartBot = async () => {
    this.setState({ restarting: true });
    if (this.props.bot.online) {
      ReactGA.initialize("UA-122665050-1");
      ReactGA.event({
        category: "Online Restart",
        label: this.props.bot.id,
        action: "New Dashboard",
      });
    }
    await this.stop(true);
    await this.start();
    this.setState({ restarting: false });
  };

  renderLangOptions = () => {
    var options = [];
    Object.keys(LANGUAGE_OPTIONS).forEach((key) => {
      options.push({ value: key, label: LANGUAGE_OPTIONS[key] });
    });
    return options;
  };

  handlePremiumCodeClaim = async () => {
    // premium_code: "",
    // premium_code_disabled: false,
    // premium_error: ""
    this.setState({ premium_error: "", premium_code_disabled: true });
    if (this.state.premium_code.startsWith("GIFT")) {
      history.push("/dashboard/gifting");
    }
    const response = await server.post("/dashboard/claimpremiumcode", {
      code: this.state.premium_code,
      bot_id: this.props.bot.id
    });

    if (response && response.data) {
      if (response.data.success) {
        // Refresh browser
        window.location.reload();
      } else {
        this.setState({ premium_error: response.data.message, premium_code_disabled: false });
      }
    }
  };

  render() {
    return (
      <Dashboard>
        <ModuleHeader
          showDocs={false}
          showEnabled={false}
          tutorial="https://www.youtube.com/watch?v=H9Emm0AOY7g"
          title="Settings"
          description="Your Bots Settings and Presence"
          icon="wrench.png"
        ></ModuleHeader>
        {this.state.showIntentsFail == true ? (
          <IntentsFailedModal
            closeModal={() => {
              this.setState({ showIntentsFail: false });
            }}
          />
        ) : null}
        {this.state.showDelete == true ? (
          <DeleteBotModal closeModal={this.closeDelete} />
        ) : null}

        {this.state.showRevokeToken == true ? (
          <RevokeTokenModal closeModal={() => {
            this.setState({ showRevokeToken: false });
          }} />
        ) : null}
        <section>
          <SectionHeader
            title="Bot Settings"
            pretitle="Settings"
          ></SectionHeader>
          {this.props.data.user.tokens >= 1 &&
            this.props.data.premium == false &&
            this.props.collab == false ? (
            <div className="section-content token-section animate-pulse">
              <div>
                <h3>Premium Tokens Available: {this.props.data.user.tokens >= 100 ? <FontAwesomeIcon icon={faInfinity} /> : this.props.data.user.tokens}</h3>
                <div className="section-content-header">
                  Your premium tokens, purchase more to upgrade more bots.
                </div>
              </div>

              <div style={{ marginLeft: "auto" }}>
                <button
                  className="btn btn-gray "
                  disabled={this.state.upgradeDisabled}
                  onClick={(e) => {
                    this.upgradeBot();
                  }}
                >
                  Upgrade Bot
                </button>
              </div>
            </div>
          ) : null}
          <div className="section-content-normal" id="settings-bot-name">
            <div class="mb-15">
              <h3 style={{ marginBottom: "0px" }}>
                Bot Name <i class="bi bi-info-circle"></i>{" "}
                {this.state.saved == "name" ? (
                  <span style={{ color: "green" }}>Saved!</span>
                ) : null}
              </h3>
              <div className="section-content-header">Your bot's username</div>
              <span style={{ color: "red" }}>{this.state.name_error}</span>
            </div>

            <div class="long-input">
              <label>Username</label>
              <input
                value={this.state.name}
                onChange={(e) => {
                  //                   this.handleChange("name", e);
                  this.setState({ name: e.target.value, name_error: null });
                }}
                onBlur={(e) => {
                  this.handleChange("name", e);
                }}
                type="text"
              ></input>
            </div>
          </div>

          <div className="section-content-normal">
            <div class="mb-15">
              <h3 style={{ marginBottom: "0px" }}>
                Bot Avatar <i class="bi bi-info-circle"></i>{" "}
                {this.state.saved == "avatar" ? (
                  <span style={{ color: "green" }}>Saved!</span>
                ) : null}
              </h3>
              <div className="section-content-header">
                Your bot's profile picture
              </div>
              <span style={{ color: "red" }}>{this.state.img_error}</span>
            </div>

            <div class="long-input">
              <input type="file" onChange={this.onFileChange}></input>
            </div>
          </div>

          {/* <div className="section-content-normal">
                        <div class="mb-15">
                            <h3 style={{marginBottom:"0px"}}>Bot Prefix </h3>
                            <div className="section-content-header">The prefix your bot should respond to.</div>
                        </div>

                        <div class="long-input">
                            <label>Prefix</label>
                            <input value={this.props.data.bot.prefix} onChange={(e) =>{this.changePrefix(e.target.value)}} type="text"></input>
                        </div>
                        
                    </div> */}

          <div className="section-content m-section-content" id="settings-bot-status-message">
            <div>
              <h3 style={{ marginBottom: "0px" }}>Bot Status Message</h3>
              <div
                className="section-content-header"
                style={{ display: "block" }}
              >
                Change the{" "}
                <span style={{ color: "#51b46f", fontWeight: "800" }}>
                  Made with BotGhost.com
                </span>{" "}
                to your own custom status message
              </div>
            </div>

            <div className="m-ml-0 m-w-100">
              <Link className="btn btn-blue" to="/dashboard/module/42056fd5-1c1d-4f89-8db7-794fe732424c/status">
                Set Status Message
              </Link>
            </div>
          </div>
        </section>
        <section>
          <SectionHeader
            title="Bot Options"
            pretitle="Settings"
          ></SectionHeader>

          {!this.props.co_create ? <div className="section-content-normal">
            <div class="mb-15">
              <h3 style={{ marginBottom: "0px" }}>
                Bot Token <i class="bi bi-info-circle"></i>
              </h3>
              <div className='section-content-header'>
                <p className="">
                  Your bot's token from {" "}
                  <a
                    href="https://discord.com/developers/applications"
                    target="_blank"
                  >
                    {" "}
                    Discord Developer Portal
                  </a>
                </p>
              </div>

            </div>

            <div class="long-input">
              <label>Bot Token</label>
              <input
                value={this.props.data.bot.token}
                onChange={(e) => {
                  this.props.setBotSettings({
                    value: e.target.value,
                    key: "token",
                  });
                }}
                type="password"
                autocomplete="off"
              ></input>
            </div>
          </div> : null}

          <div className="section-content" id="settings-bot-status">
            <div id="bot-status">
              <h3>Bot Status</h3>
              <div className="section-content-header">
                {this.props.data.bot.online ? (
                  <span style={{ fontWeight: "800", color: "#63f58c" }}>
                    Online
                  </span>
                ) : (
                  <span style={{ color: "#f45142", fontWeight: 800 }}>
                    Offline
                  </span>
                )}
              </div>
            </div>

            <div style={{ marginLeft: "auto" }}>
              {this.props.bot.online ? (
                <button
                  disabled={this.state.disabled}
                  className="btn btn-red"
                  onClick={(e) => {
                    this.stop();
                  }}
                >
                  Stop
                </button>
              ) : (
                <button
                  disabled={this.state.disabled}
                  onClick={(e) => {
                    this.start();
                  }}
                  className="btn btn-red"
                >
                  Start
                </button>
              )}
            </div>
          </div>

          <div className="section-content">
            <div>
              <h3>Restart Bot</h3>
              <div className="section-content-header">
                <span>Quickly restart your bot.</span>
              </div>
            </div>

            <div style={{ marginLeft: "auto" }}>
              <button
                disabled={this.state.restarting === true}
                className="btn btn-blue"
                onClick={() => {
                  this.restartBot();
                }}
              >
                {this.state.restarting === true
                  ? "Restarting..."
                  : "Restart Bot"}
              </button>
            </div>
          </div>

          {this.props.premium == false || (this.props.premium == true && this.props.user.tokens >= 1000) ? (
            <div className="section-content">
              <div>
                <h3>Remove Bot</h3>
                <div className="section-content-header">
                  Permanently remove this bot from BotGhost
                </div>
              </div>

              <div style={{ marginLeft: "auto" }}>
                <button
                  className="btn btn-red"
                  onClick={(e) => {
                    this.setState({ showDelete: true });
                  }}
                >
                  Delete Bot
                </button>
              </div>
            </div>
          ) : null}

          {this.props.premium == true || this.props.beta == true && !this.props.cocreate ? (
            <div className="section-content">
              <div>
                <h3>Enable Beta Features</h3>
                <div className="section-content-header">
                  Enable beta features for your bot, note: Some features may not
                  be properly tested and may not function as intended
                </div>
              </div>

              <div style={{ marginLeft: "auto" }}>
                <Toggle value={this.props.beta} update={this.updateBeta} />
              </div>
            </div>
          ) : null}

          {/* {
                        this.props.beta == true ?
                        <div className='section-content-normal'>
                        <div class="mb-15">
                            <h3 style={{marginBottom:"0px"}}>Language</h3>
                            <div className="section-content-header">Your bots language. Responses are translated from english using a translation AI. As a result, inaccurate translations will not be changed.</div>
                        </div>

                        <div >
                            <Select value={this.props.bot.lang} options={this.renderLangOptions()} onChange={(value) =>{
                                this.props.setBotSettings({value:value,key:"lang"})
                            }}/>
                        </div>

                    </div>:null
                    } */}
        </section>

        <section>
          <SectionHeader
            title="Account Options"
            pretitle="Settings"
          ></SectionHeader>
          <div className="section-content m-section-content" id="settings-bot-logout">
            <div>
              <h3>Log Out</h3>
              <div className="section-content-header">
                Log out of your discord account
              </div>
            </div>

            <div style={{ marginLeft: "auto" }} className="m-w-100 m-ml-0 m-mt-15">
              <a
                className="btn btn-red"
                href={process.env.REACT_APP_API_SERVER + "/logout"}
              >
                Log Out
              </a>
            </div>
          </div>
        </section>
        <section>
          <SectionHeader
            title="Privileged Intent Options"
            pretitle="Settings"
          ></SectionHeader>

          <div className="section-content-normal" id="settings-bot-name">
            <div>
              <div class="mb-15">
                <h3 style={{ marginBottom: "0px" }}>
                  Presence Intent (GUILD_PRESENCES)
                </h3>
                <div className="section-content-header">Whether to always enable, never enable or automatically allow BotGhost to determine if the GUILD_PRESENCES intent should be enabled on your bot.</div>

              </div>

              <Select onChange={(value) => {
                this.toggleChange(value, "presence_intent");
              }} value={
                this.props.bot.presence_intent ? this.props.bot.presence_intent : "AUTO"
              } options={INTENT_OPTIONS} />

            </div>



          </div>

          <div className="section-content-normal" id="settings-bot-name">
            <div>
              <div class="mb-15">
                <h3 style={{ marginBottom: "0px" }}>
                  Server Members Intent (GUILD_MEMBERS)
                </h3>
                <div className="section-content-header">Whether to always enable, never enable or automatically allow BotGhost to determine if the GUILD_MEMBERS intent should be enabled on your bot.</div>

              </div>

              <Select onChange={(value) => {
                this.toggleChange(value, "server_members_intent");
              }} value={
                this.props.bot.server_members_intent ? this.props.bot.server_members_intent : "AUTO"
              } options={INTENT_OPTIONS} />

            </div>



          </div>

          <div className="section-content-normal" id="settings-bot-name">
            <div>
              <div class="mb-15">
                <h3 style={{ marginBottom: "0px" }}>
                  Message Content Intent (MESSAGE_CONTENT)
                </h3>
                <div className="section-content-header">Whether to always enable, never enable or automatically allow BotGhost to determine if the MESSAGE_CONTENT intent should be enabled on your bot.</div>

              </div>

              <Select onChange={(value) => {
                this.toggleChange(value, "message_intent");
              }} value={
                this.props.bot.message_intent ? this.props.bot.message_intent : "AUTO"
              } options={INTENT_OPTIONS} />

            </div>



          </div>
        </section>
        <section>
          <SectionHeader
            title="Bot Subscription Options"
            pretitle="Settings"
          ></SectionHeader>



          {/* {
            this.props.premium && this.props.bot.subscription && (this.props.bot.subscription.sub_type == 'monthly' || this.props.bot.subscription.sub_type == 'yearly' || this.props.bot.subscription.sub_type == "lifetime") ? <>
              <div className="section-content">
                <div>
                  <h3>Revoke Premium Token</h3>
                  <div className="section-content-header">
                    Remove the premium token from this bot. This will downgrade this bot and allow you to use its premium token on another bot.
                  </div>
                </div>

                <div style={{ marginLeft: "auto" }}>
                  <button
                    className="btn btn-red"
                    onClick={() => {
                      this.setState({ showRevokeToken: true });
                    }}
                  >
                    Revoke Token
                  </button>
                </div>
              </div></> : null
          } */}

          <div className="section-content">
            <div>
              <h3>Bot Premium Status</h3>
              <div className="section-content-header">
                {this.renderSubscriptionType()}
              </div>
            </div>

            <div style={{ marginLeft: "auto" }}>
              {this.props.premium == true && this.props.data.bot.subscription.sub_type != "freepremium" ? (
                <a
                  className="btn btn-red"
                  href="https://botghost.chargebeeportal.com/"
                  target="_blank"
                >
                  Manage Subscription
                </a>
              ) : null}
            </div>
          </div>
        </section>

        <section>
          <SectionHeader
            title="Claim Premium Code"
            pretitle="Settings"
          ></SectionHeader>
          <div className="section-content-normal" id="settings-bot-name">
            <div>
              <div class="mb-15">
                <h3 style={{ marginBottom: "0px" }}>
                  Claim a Premium Code
                </h3>
                <div className="section-content-header">Claim a premium code on this bot.</div>
                <span className="text-red">{this.state.premium_error}</span>
              </div>

              <div class="long-input">
                <label>Code</label>
                <input
                  value={this.state.premium_code}
                  onChange={(e) => {
                    this.setState({ premium_code: e.target.value, premium_error: null });
                    //                   this.handleChange("name", e);
                    // this.setState({ name: e.target.value, name_error: null });
                  }}
                  placeholder="6fc07bc4-39be-49ae-917f-0a579354b4a5"
                  type="text"
                ></input>
              </div>
            </div>

            <div>
              <button onClick={() => {
                this.handlePremiumCodeClaim();
              }} className="btn btn-red mt-2" disabled={this.state.premium_code == "" || this.state.premium_code_disabled}>Claim</button>
            </div>

          </div>
        </section>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  bot: state.data.bot,
  cocreate: state.data.co_create,
  bots: state.data.bots,
  premium: state.data.premium,
  quick_start: state.quick_start,
  beta: state.data.beta,
  user: state.data.user,
  server_members_intent: state.data.server_members_intent,
  collab: state.data.collab,
});

export default connect(mapStateToProps, {
  updateBeta,
  changeName,
  setAuth,
  setQuickStart,
  changeIMG,
  setBotSettings,
  upgradeBot,
  setOnlineStatus,
})(Settings);
