import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../../../api/server';

export class RevokeTokenModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            buttonDisabled: false
        };
    }

    modalBackgroundClick = (e) => {
        if (e.currentTarget == e.target) {
            this.props.closeModal(e);
        }
    };

    revoke = async () => {
        this.setState({ buttonDisabled: true });
        const response = await server.post("/dashboard/revoketoken", {
            bot_id: this.props.bot.id,
        });

        if (response && response.data.success == true) {
            window.location.reload();

        } else {
            this.setState({ buttonDisabled: false });
        }
        // if (response.data == true) {
        //     window.location.replace("/dashboard");
        // } else {
        //     this.setState({ deleteDisabled: false });
        //     this.props.closeModal();
        // 
    };

    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} onClick={(e) => { this.modalBackgroundClick(e); }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">

                        <div class="modal-content premium-modal" >
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Revoke Premium Token</h5>
                                <button onClick={(e) => { this.props.closeModal(e); }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div class="modal-body command-edit-body" style={{ color: "#fff", opacity: "0.7", overflowY: "hidden" }}>
                                <p>Are you sure you wish to remove the premium token from this bot. This will downgrade this bot and remove its premium token so it can be used on another bot.</p>
                            </div>

                            <div class="modal-footer">
                                <button onClick={(e) => { this.props.closeModal(); }} type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button disabled={this.state.buttonDisabled} type="button" class="btn btn-danger" onClick={(e) => { this.revoke(); }}>Revoke Premium Token</button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(RevokeTokenModal);
