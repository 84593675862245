import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../../../api/server';

import history from "../../../history";
import Select from './Select';
import { CUSTOM_EVENTS, CUSTOM_EVENT_CATEGORIES } from '../../../variables';
import { updateElementData, setBuilderMode, setSelected, setWebhookId } from '../../../actions';



export class CustomCommandHelper extends Component {

    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            disabled: false
        };
    }

    componentDidMount() {
        // navigator.clipboard.readText()
        //     .then(text =>{
        //         console.log(text)
        //     })
        //     .catch((e) =>{
        //         console.log(e)
        //     })
    }

    modalBackgroundClick = (e) => {
        console.log("MODAL BACKGROUND CLICK");
        if (e.currentTarget == e.target) {
            this.props.closeModal(e);
        }
    };

    import = async () => {
        this.setState({ disabled: true, error: "" });
        const response = await server.post("/dashboard/importcommand", { id: this.state.code });

        if (response.data == false) {
            // ERROR
            this.setState({ disabled: false, error: "Could not find a command with that share code." });
        } else {
            this.props.importCommand(response.data.command);
        }
    };

    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: 'root'
            }
        );
    };

    returnOptions = () => {
        var options = [...CUSTOM_EVENTS];
        options.unshift({ value: "", label: "" });
        return options;
    };

    renderSelect = () => {
        var eventCategories = [...CUSTOM_EVENT_CATEGORIES];
        eventCategories.forEach(cat => {
            cat.children = [];
        });
        var options = [...CUSTOM_EVENTS];

        options.forEach(event => {
            if (event.beta && !this.props.beta || (event.hidden == true)) return;
            var eventCategory = eventCategories.find(category => category.value == event.event_category);
            if (eventCategory) {
                eventCategory.children.push(<option value={event.value}>{event.label}</option>);
            }
        });





        if (this.props.webhooks?.webhooks?.length > 0) {
            eventCategories.push({
                label: "Webhooks",
                children: this.props.webhooks.webhooks.map(webhook => {
                    return (<option value={`WEBHOOK_${webhook.id}`}>{webhook.name} Webhook</option>);
                })
            });
        }

        if (this.props.timed_events?.events?.length > 0) {
            eventCategories.push({
                label: "Timed Events",
                children: this.props.timed_events.events.map(timed_Event => {
                    return (<option value={`TIMED_EVENT_${timed_Event.id}`}>{timed_Event.name} Timed Event</option>);
                })
            });
        }









        return (<select onChange={(e) => {
            console.log(e.target.value);
            if (e.target.value.startsWith("WEBHOOK_")) {

                var data = { ...this.props.data };
                data.type = "webhookExecuted";
                data.webhookId = e.target.value.replace("WEBHOOK_", "");
                this.props.updateElementData(
                    {
                        data: data,
                        id: 'root'
                    }
                );

                this.props.setBuilderMode({
                    mode: "event",
                    components: {
                        Guild: true,
                        Channel: false,
                        User: false,
                        Member: false,
                        Message: false,
                        variables: [
                        ]
                    },
                });
                this.props.setSelected('root');
                return;
            } else if (e.target.value.startsWith("TIMED_EVENT_")) {
                var data = { ...this.props.data };
                data.type = "timedEvent";
                data.timer_id = e.target.value.replace("TIMED_EVENT_", "");
                data.guild_id = "{DEFAULT_SERVER}";
                this.props.updateElementData(
                    {
                        data: data,
                        id: 'root'
                    }
                );

                this.props.setBuilderMode({
                    mode: "event",
                    components: {
                        Guild: true,
                        Channel: false,
                        User: false,
                        Member: false,
                        Message: false,
                        variables: [
                        ]
                    },
                });
                this.props.setSelected('root');
                return;
            }
            this.updateData('type', e.target.value);
            var event_options = CUSTOM_EVENTS.find(event => event.value == e.target.value);

            this.props.setBuilderMode({
                mode: "event",
                components: event_options.components
            });
            this.props.setSelected('root');
        }}>
            <option value></option>
            {eventCategories.map(category => {
                return (<optgroup className='font-bold' label={category.label}>
                    {category.children}
                </optgroup>);
            })}
        </select>);
    };

    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div class="modal-dialog premium-modal-dialog" style={{ width: "700px", maxWidth: "700px" }} role="document">
                        <div class="modal-content premium-modal" style={{ backgroundColor: "#f8f8fb" }}>


                            <div class="modal-body command-edit-body premium-modal-body">
                                <div className="premium-modal-content">
                                    <h2 className="text-2xl font-bold">Select a Custom Event</h2>
                                    <p style={{ textAlign: "center" }} className='mb-15'>Select a custom event to run actions and conditions when triggered. You can change this event at anytime by clicking on the root block in the center of the builder.</p>
                                    <div>
                                        {/* 
                                        <Select options={this.returnOptions()} value={this.props.data.type} onChange={(value) => {
                                            this.updateData('type', value);
                                            var event_options = CUSTOM_EVENTS.find(event => event.value == value);

                                            this.props.setBuilderMode({
                                                mode: "event",
                                                components: event_options.components
                                            });
                                            this.props.setSelected('root');
                                        }}></Select> */}

                                        {this.renderSelect()}
                                        {/* <select>
                                            <option value></option>
                                            <optgroup label="User Events">
                                                <option value="guildMemberAdd">When a new user joins the server</option>

                                            </optgroup>
                                        </select> */}
                                    </div>
                                </div>



                            </div>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    beta: state.data.beta,
    webhooks: state.data.bot.commands.webhooks,
    timed_events: state.data.bot.commands.timed_events,
    user: state.data.user
});

const mapDispatchToProps = {
    updateElementData,
    setBuilderMode,
    setSelected,
    setWebhookId

};

export default connect(mapStateToProps, mapDispatchToProps)(CustomCommandHelper);
