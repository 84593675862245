import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'

export class PremiumTimer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            remaining: "7 Days"
        }
    }

    componentDidMount() {
        if (this.props.expires) {
            var cutoff = moment(this.props.expires)
            // console.log(moment(cutoff).fromNow(true))
            this.setState({ remaining: moment(cutoff).fromNow(true) })
        } else {
            var cutoff = moment(this.props.created_at).add("7", "days");
            // console.log(moment(cutoff).fromNow(true))
            this.setState({ remaining: moment(cutoff).fromNow(true) })
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.created_at != prevProps.created_at) || (this.props.expires != prevProps.expires)) {
            if (this.props.expires) {
                var cutoff = moment(this.props.expires)
                this.setState({ remaining: moment(cutoff).fromNow(true) })
            } else {
                var cutoff = moment(this.props.created_at).add("7", "days");

                this.setState({ remaining: moment(cutoff).fromNow(true) })
            }
        }
    }


    render() {
        if (this.props.expired == true && this.props.premium == false) {
            return (
                <div className="premium-banner-container">
                    <div className="premium-timer-text">
                        {"FREE HOSTING EXPIRED"}
                    </div>
                    <div className="premium-timer-progress">
                        {/* {this.renderText()} */}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="premium-banner-container">
                    <div className="premium-timer-text">
                        {this.state.remaining + " OF FREE HOSTING REMAINING"}
                    </div>
                    <div className="premium-timer-progress">
                        {/* {this.renderText()} */}
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    created_at: state.data.created_at,
    expired: state.data.bot.expired,
    premium: state.data.premium,
    expires: state.data.expires
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PremiumTimer)
