import React, { Component } from 'react'
import { connect } from 'react-redux'

import history from "../../../history"

export class PremiumRedirect extends Component {

    redirect = () =>{

    }


    render() {
        return (
            <div>
                {this.redirect()}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    premium:state.data.bot.premium
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(PremiumRedirect)
