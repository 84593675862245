import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";

import TextArea from "../../elements/TextArea";
import RefreshBotData from "../../elements/RefreshBotData";
import Select from "../../elements/Select";
import VariableTextInput from "../VariableTextInput";

export class RoleDeleteAction extends Component {
	constructor(props) {
		super(props);

		this.state = {
			role_type: "specific"
		};
	}
	componentDidMount() {
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "delete_role",
					target: {
						id: ""
					},
					success_handles: false,
					validated: false
				},
				id: this.props.id
			});
		} else if (this.props.data && this.props.data.target) {
			if (this.props.data.target.id_variable) {
				this.setState({ role_type: "id" });
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data != undefined && this.props.data.success_handles == undefined) {
			this.updateData("success_handles", false);
		}
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "delete_role",
					target: {
						id: ""
					},
					validated: false
				},
				id: this.props.id
			});
		} else {
			// VALIDATE

			if (("id" in this.props.data.target && this.props.data.target.id != "") || ("variable" in this.props.data.target && this.props.data.target.variable != "")) {
				if (this.props.data.validated == false) {
					this.updateData("validated", true);
				}
			} else if (this.props.data.validated == true) {
				this.updateData("validated", false);
			}
		}
	}

	updateData = (key, value) => {
		// console.log("VALUE", value);
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	renderOptions = () => {
		var options = [
			// {value:"trigger",label:"The member who triggered the command"}
		];
		this.props.options.forEach((option) => {
			if (option.type == "role") {
				options.push({ value: { variable: option.name }, label: `Role Option: ${option.name}` });
			}
		});
		return options;
	};

	selectUpdate = (value) => {
		value = JSON.parse(value);
		var target = { ...this.props.data.target };
		target = value;
		this.updateData("target", target);
	};

	render() {
		if (this.props.data != undefined) {
			return (
				<div>
					<div className="mb-15">
						<h4>Delete a Role</h4>
						<p>Delete a Role in the server. Role options can be used.</p>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Role Type <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/delete-a-role#role-type" />
						</h4>
						<span>The role to delete.</span>
						<div className="mt-15">
							<Select
								value={this.state.role_type}
								options={[
									{ value: "specific", label: "Specific Role" },
									{ value: "id", label: "Role ID or Variable" }
								]}
								onChange={(value) => {
									this.setState({ role_type: value });
								}}
							></Select>
						</div>
					</div>

					{this.state.role_type == "specific" || !this.state.role_type ? (
						<div className="mb-15 slash-action">
							<h4>
								Specific Role <RefreshBotData />
								<DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/delete-a-role#specific-role"} />
							</h4>
							<span>Select a specific role to delete.</span>
							<div className="mt-15">
								<Select saveFailed={this.props.saveFailed} slash_options={this.renderOptions()} value={JSON.stringify(this.props.data.target)} onChange={this.selectUpdate} type="role"></Select>
							</div>
						</div>
					) : (
						<div className="mb-15 slash-action">
							<h4>
								Role ID or Variable <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/delete-a-role#role-id-or-variable"></DocsLink>
							</h4>
							<span>Enter a Role ID or Variable to delete. Custom variables can be used.</span>
							<VariableTextInput
								required={true}
								label="Role ID or Variable"
								placeholder="Role ID or Variable"
								value={this.props.data.target.id}
								onChange={(value) => {
									var target = { ...this.props.data.target };
									target.id = value;
									target.id_variable = true;

									this.updateData("target", target);
								}}
								slash_options={this.props.options}
							/>

							{/* <div className="long-input mt-15">
                                <label>ID or Variable</label>
                                <input required type="text" value={this.props.data.target.id} onChange={(e) => {
                                    var value = e.target.value;
                                    var target = { ...this.props.data.target };
                                    target.id = value;
                                    target.id_variable = true;

                                    this.updateData("target", target);

                                }}></input>
                            </div> */}
						</div>
					)}
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	saveFailed: state.builder.saveFailed,
	beta: state.data.beta
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleDeleteAction);
