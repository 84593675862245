import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../../../history";
import Dashboard from "../Dashboard";
import ModuleHeader from "../elements/ModuleHeader";
import SectionHeader from "../elements/SectionHeader";

export class FreeHosting extends Component {
  render() {
    return (
      <Dashboard>
        <ModuleHeader
          showEnabled={false}
          icon="botghostpremium.png"
          title="Free Premium"
          description="Earn free premium for your Discord Bot"
        ></ModuleHeader>

        <section>
          <SectionHeader
            title="Free Premium Options"
            pretitle="Free Premium"
          ></SectionHeader>

          <div className="row">
            {/* <div className="col-xl-3 col-lg-6">
              <div
                className="module-item"
                onClick={(e) => {
                  history.push("/dashboard/referrals");
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/affiliate.png"}
                />
                <h3>Referrals</h3>
                <p>Refer your friends, viewers and users to BotGhost</p>
              </div>
            </div> */}

            <div className="col-xl-3 col-lg-6">
              <div
                className="module-item"
                onClick={(e) => {
                  history.push("/dashboard/serverpremium");
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/discord.png"}
                />
                <h3>Discord Server</h3>
                <p>Join the BotGhost Official Discord Server</p>
              </div>
            </div>

            {/* <div className="col-xl-3 col-lg-6">
              <div
                className="module-item"
                onClick={(e) => {
                  history.push("/dashboard/twitterpremium");
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/twitter.png"}
                />
                <h3>Twitter</h3>
                <p>Follow the BotGhost Owner on Twitter</p>
              </div>
            </div> */}
          </div>
        </section>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FreeHosting);
