import { faAddressBook, faHandScissors, faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import server from '../../../api/server'
import ReactGA from 'react-ga';
import history from "../../../history"
import buildExistingCommand from '../commandbuilder/buildExistingCommand';
import { CommandBuilderTemplate } from '../commandbuilder/CommandBuilderTemplate'
import { setCommandSaveFailed,setElements,setSelected,setBotModule,saveBot,setBotSettings,setBuilderIndex } from '../../../actions';
import {TEMPLATES} from "../../../variables"


export class CustomCommandHelper extends Component {

    constructor(props) {
        super(props)

        this.state = {
            step: 0,
            disabled: false
        }
    }

    componentDidMount() {
        // navigator.clipboard.readText()
        //     .then(text =>{
        //         console.log(text)
        //     })
        //     .catch((e) =>{
        //         console.log(e)
        //     })
    }

    modalBackgroundClick = (e) => {
        if (e.currentTarget == e.target) {
            this.props.closeModal(e)
        }
    }

    import = async () => {
        this.setState({ disabled: true, error: "" })
        const response = await server.post("/dashboard/importcommand", { id: this.state.code });

        if (response.data == false) {
            // ERROR
            this.setState({ disabled: false, error: "Could not find a command with that share code." })
        } else {
            this.props.importCommand(response.data.command)
        }
    }

    renderTemplates = () => {
        var templates = [];
        TEMPLATES.forEach(template =>{
            templates.push(
                <CommandBuilderTemplate 
                icon={template.icon}
                title={template.name}
                description={template.description}
                data={template.data}
                importCommand={this.importCommand}
                />
            )
        })
        return templates;
    }

    importCommand = async (data) =>{
        var elements =await  buildExistingCommand(data);
        this.props.setBuilderIndex(null);
        this.props.setElements(elements)
        // this.props.setSelected('root')
        setTimeout(() => {
            this.props.closeModal()
        }, 1000);
        ReactGA.initialize('UA-122665050-1');
        ReactGA.event({
            category: "Command Template",
            label: "Template Used",
            action: "New Command Builder"
        })


    }



    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} onClick={(e) => { this.modalBackgroundClick(e) }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div class="modal-dialog premium-modal-dialog" style={{ width: "1000px", maxWidth: "1000px" }} role="document">
                        <div class="modal-content premium-modal" style={{ backgroundColor: "#f8f8fb" }}>


                            <div class="modal-body command-edit-body command-template-modal-body">

                                    <div style={{display:"flex",justifyContent:"center"}}>
                                        <h2 style={{fontWeight:"600"}}>Command Templates</h2>
                                    </div>


                                    <div className='command-templates'>
                                        {this.renderTemplates()}
                                    </div>


                                </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    setBuilderIndex,
    setElements,
    setSelected
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomCommandHelper)
