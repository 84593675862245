import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from "react-ga";

var price_points = {
    'Lifetime': [
        // { min: 1, max: 1, price: 119.99 },
        // { min: 2, max: 5, price: 89.99 },
        // { min: 6, max: 1000, price: 59.99 },

        { min: 1, max: 1, price: 99.99 },
        { min: 2, max: 5, price: 74.99 },
        { min: 6, max: 1000, price: 49.99 },
    ],
    'Yearly': [
        { min: 1, max: 1, price: 79.99 },
        { min: 2, max: 5, price: 59.99 },
        { min: 6, max: 1000, price: 39.99 },
    ],
    'Monthly': [
        { min: 1, max: 1, price: 14.95 },
        { min: 2, max: 5, price: 11.21 },
        { min: 6, max: 1000, price: 7.48 },
    ],
};
var STEPS = [1, 5, 10, 25, 50, 100];

var steps = {
    Lifetime: {
        1: 99.99,
        5: 129.99,
        10: 149.99,
        25: 199.99,
        50: 249.99,
        100: 299.99,
    },
    Yearly: {
        1: 59.99,
        5: 99.99,
        10: 129.99,
        25: 149.99,
        50: 199.99,
        100: 249.99,
    },
    Monthly: {
        1: 14.95,
        5: 39.99,
        10: 59.99,
        25: 79.99,
        50: 99.99,
        100: 149.99,
    }
};
export class PremiumPlan extends Component {

    constructor(props) {
        super(props);

        this.state = {
            quantity: 1
        };
    }

    componentDidMount() {
        // console.log(this.props);
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props);
    }


    calculatePrice = (billing) => {
        var price = 0;
        var quantity = this.props.quantity;

        price = steps[this.props.name][quantity];


        return price;


    };

    renderBilling = () => {
        var text = "";
        if (this.props.name == 'Lifetime') {
            text = `${this.props.symbol}${this.calculatePrice()} paid once. Lifetime access`;
        } else if (this.props.name == 'Yearly') {
            text = `${this.props.symbol}${this.calculatePrice(true)} billed yearly`;
        } else {
            text = `${this.props.symbol}${this.calculatePrice()} per month`;
        }

        return text + ". 7-day money back guarantee.";
    };
    planClick = () => {
        var plan = this.props.plan;
        var ga_price = this.props.ga_price;
        if (this.props.type == "unlimited") {
            ga_price = this.props.ga_ultimate_price;
        }

        // console.log(plan);
        this.props.select(this.props.name, plan, ga_price);
    };

    displayChange = () => {
        if ('transactions' in this.props.user) {
            var pass = false;
            this.props.user.transactions.forEach(transaction => {
                if (transaction.active == true) {
                    pass = true;
                }
            });
            return pass;
        } else {
            return false;
        }
    };

    calculateSaving = () => {
        var price = this.calculatePrice();
        var maxPrice = steps[this.props.name][1] * this.props.quantity;

        var savings = (maxPrice - price).toFixed(0);
        var saving_percent = ((savings / maxPrice) * 100).toFixed(0);
        if (savings > 0) {
            return `Save ${saving_percent}%`;
        } else {
            return this.props.tag;
        }
    };

    render() {
        return (
            <div className="col-xl-4 col-md-6">

                <div className={`${this.props.most_popular ? "most-popular" : null} ${this.props.type == "single" ? "premium-plan" : "premium-plan-ultimate"} ${this.props.selected ? "premium-plan-selected" : null}`} onClick={(e) => { this.planClick(); }}>
                    {this.props.most_popular == true ? <div className="premium-header-tag">
                        <span>Most Popular</span>
                    </div> : null}
                    <div className="premium-plan-info">
                        <span className="premium-plan-name">{this.props.quantity} x {this.props.name} Bot{this.props.quantity == 1 ? "" : "s"}</span>
                        <span className="premium-plan-price">{this.props.symbol}{this.calculatePrice()}</span>
                        <span className="premium-plan-description">{this.props.description}</span>
                        <span className="premium-plan-tag mt-2">{this.calculateSaving()}</span>

                    </div>

                    {this.props.type == "unlimited" ?
                        <div style={{ height: "100%" }}>
                            <div className="premium-ultimate-text">Unlimited Premium Bots!</div>
                        </div> : null}
                    <div className="premium-plan-buttons">

                        <div className="premium-plan-buttons-container">
                            {/* <button className="btn btn-gray" onClick={(e) =>{this.props.checkout(this.props.normal_plan,this.props.ga_price)}}>Single Bot</button> */}
                            <button className={`btn btn-red ${this.props.type != "single" ? "ultimate-button" : null}`} onClick={(e) => { this.props.checkout(this.props.plan, this.calculatePrice(), this.props.quantity); }} style={{ float: "right" }}>Upgrade {this.props.quantity} Bot{this.props.quantity == 1 ? "" : "s"}</button>
                            {this.displayChange() ? <button className={`btn btn-gray`} onClick={(e) => { this.props.change(); }} style={{ float: "right" }}>Change</button> : null}
                        </div>

                        <div className="premium-plan-billing mt-2">
                            <span>{this.renderBilling()}</span>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.data.user
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumPlan);
