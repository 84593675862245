import React, { Component } from "react";
import { connect } from "react-redux";

import { Embed } from "./Embed";
import RefreshBotData from "./RefreshBotData";
import Select from "./Select";
import TextArea from "./TextArea";
import Toggle from "./Toggle";

export class YouTubeEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: false,
      subreddit: "",
      timeout: null,
      channel_object: null,
      text: "",
      channel: "",
      embed: {},
      error: "",
    };
  }

  componentDidMount() {
    if (this.props.value != undefined && this.props.value != {}) {
      this.setState(this.props.value);
    }
  }

  channelSelectChange = (channel) => {
    var channelObject = JSON.parse(channel);
    this.setState({
      channel_object: channelObject,
      channel: channelObject.name,
    });
    // console.log(channelObject);
  };

  textAreaChange = (value) => {
    this.setState({ text: value });
  };

  embedChange = (embed) => {
    this.setState({ embed });
  };

  toggleChange = (value) => {
    this.setState({
      type: value,
    });
  };

  embedCheck = () => {
    if (
      !("description" in this.state.embed) ||
      this.state.embed.description == ""
    ) {
      this.setState({ error: "Please set content for your Embed" });
      return false;
    } else if (!("title" in this.state.embed) || this.state.embed.title == "") {
      this.setState({ error: "Please set a title for your Embed" });
      return false;
    }
    return true;
  };

  save = () => {
    if (this.state.subreddit == null || this.state.subreddit == "") {
      this.setState({ error: `Please set a subreddit to follow.` });
    } else if (this.state.timeout == null || this.state.timeout == 0) {
      this.setState({ error: `Please set an interval.` });
    } else if (this.state.channel == "") {
      this.setState({ error: "Please select a channel to post in" });
    } else {
      this.props.save(this.state, this.props.index);
      this.reset();
    }
  };

  reset = () => {
    this.setState({
      type: false,
      subreddit: "",
      timeout: null,
      channel_object: null,
      text: "",
      channel: "",
      embed: {},
      error: "",
    });
  };

  render() {
    return (
      <div className="section-content-normal m-mt-0">
        {this.state.error != "" ? (
          <div className="errors">
            <div class="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          </div>
        ) : null}
        <div className="mb-15">
          <h3 style={{ marginBottom: "0px" }}>Subreddit</h3>
          <div className="section-content-header">
            The subreddit you wish to receive posts from.
          </div>
        </div>

        <div className="long-input mb-15">
          <label>Subreddit</label>
          <input
            disabled={this.props.premium == false ? true : false}
            value={this.state.subreddit}
            onChange={(e) => {
              this.setState({ subreddit: e.target.value });
            }}
            type="text"
          ></input>
        </div>

        <div className="mb-15">
          <h3 style={{ marginBottom: "0px" }}>Interval</h3>
          <div className="section-content-header">
            Timeout between new posts.
          </div>
        </div>

        <div className="long-input mb-15">
          <label>Interval</label>
          <input
            disabled={this.props.premium == false ? true : false}
            value={this.state.timeout}
            onChange={(e) => {
              this.setState({ timeout: e.target.value });
            }}
            min={1}
            type="number"
          ></input>
        </div>

        <div className="flex-row m-w-100 m-mr-0" style={{ width: "30%" }}>
          <div className="mb-15 m-w-100">
            <h3 style={{ marginBottom: "0px" }}>
              Post in Channel <RefreshBotData />
            </h3>
            <div
              className="section-content-header"
              style={{ marginBottom: "15px" }}
            >
              The text-channel to post notications in.
            </div>
            <Select
              disabled={this.props.premium == false ? true : false}
              type="channel"
              onChange={this.channelSelectChange}
              value={JSON.stringify(this.state.channel_object)}
              options={[]}
            ></Select>
          </div>
        </div>
        <div className="commandAdd-buttons">
          <button
            disabled={this.props.premium == false ? true : false}
            className="btn btn-gray"
            onClick={(e) => {
              this.reset();
            }}
          >
            Reset
          </button>
          {this.props.showDelete ? (
            <button
              className="btn btn-gray"
              onClick={(e) => {
                this.props.delete(this.props.index);
              }}
            >
              Delete
            </button>
          ) : null}

          <button
            disabled={this.props.premium == false ? true : false}
            className="btn btn-red"
            onClick={(e) => {
              this.save();
            }}
          >
            {this.props.saveText || "Save"}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  premium: state.data.premium,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(YouTubeEditor);
