import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setHighlightAction } from '../../../../actions';

import TextArea from '../../elements/TextArea';
import Select from '../../elements/Select';


export class PinMessage extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "pin_message",
                        id: "",
                        action_id: "",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            this.props.setHighlightAction(this.props.data.action_id);
        }
    }

    componentWillUnmount() {
        this.props.setHighlightAction(null);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id != this.props.id && this.props.data != undefined) {
            this.props.setHighlightAction(this.props.data.action_id);
        }
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "pin_message",
                        id: "",
                        action_id: "",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

            if ((this.props.data.id != "") && this.props.data.validated != true) {
                this.updateData("validated", true);
            } else if (this.props.data.validated == true && this.props.data.id == "") {
                this.updateData("validated", false);

            }
        }
    }

    updateData = (key, value) => {

        // console.log("VALUE", value);
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    renderOptions = () => {
        var options = [{
            label: "",
            value: null
        }];

        var types = [
            'plain_text',
            "embed",
            'random_response',
            'button_response',
            'targeted_response',
            'dm_response',
            'select_menu',
            "advanced_message"
        ];

        this.props.actions.forEach(action => {
            if ('data' in action && 'data' in action.data && action.data.data && types.includes(action.data.data.type)) {
                var label = action.data.node_options.title;
                if ("command_label" in action.data.data && action.data.data.command_label != "") {
                    label = action.data.data.command_label;
                }
                options.push({
                    value: action.id,
                    label: label
                });
            }
        });
        if (this.props.components.Message) {
            options.push({
                value: "root",
                label: "The Message"
            });
        }


        return options;
    };

    selectChange = (value) => {
        if (!value) return;
        this.props.setHighlightAction(value);
        if (value == "root") {
            var action_data = { ...this.props.data };
            action_data.id = 'root';
            action_data.action_id = 'root';
            return this.props.updateElementData(
                {
                    data: action_data,
                    id: this.props.id
                }
            );
        }
        var action = this.props.actions.find(action => action.id == value);

        if (!("editId" in action.data.data)) {
            var data = { ...action.data.data };
            data.editId = `${Date.now()}`;
            this.props.updateElementData(
                {
                    data: data,
                    id: value
                }
            );
            action.data.data.editId = data.editId;
        }
        var id = action.data.data.editId;

        var action_data = { ...this.props.data };
        action_data.id = id;
        action_data.action_id = value;
        this.props.updateElementData(
            {
                data: action_data,
                id: this.props.id
            }
        );

    };


    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Pin a Message</h4>
                        <p>Pin a message sent by the bot in this command. Select a response action to pin the message sent in that action.</p>
                    </div>

                    <h4 style={{ marginBottom: "0px" }}>Response Action<DocsLink location={"https://youtu.be/6aAYImRvszM"}></DocsLink></h4>
                    <div className="section-content-header mb-15">Select one of the response actions in this command to pin the message sent by that action.</div>
                    <Select saveFailed={this.props.data.action_id == "" && this.props.saveFailed} value={this.props.data.action_id} options={this.renderOptions()} onChange={this.selectChange} />


                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    components: state.builder.components
});
const mapDispatchToProps = {
    updateElementData,
    setHighlightAction
};

export default connect(mapStateToProps, mapDispatchToProps)(PinMessage);
