import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateServerPopup } from '../../../actions'
import server from '../../../api/server'
import ReactGA from 'react-ga';


import history from "../../../history"

export class MailingListModal extends Component {
    modalBackgroundClick = (e) => {
        // console.log("MODAL BACKGROUND CLICK")
        if (e.currentTarget == e.target) {
            this.props.closeModal(true)
        }
    }

    componentDidMount() {
        // console.log("PREMIUM MODAL MOUNTED")
    }


    joinServer = async () => {
        ReactGA.initialize('UA-122665050-1');
        ReactGA.event({
            category: "Mail Join",
            label: "Mail Modal",
            action: "New Dashboard"
        })
        var response = await server.post("/dashboard/mailinglist");

        this.props.closeModal()
    }


    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} onClick={(e) => { this.modalBackgroundClick(e) }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div class="modal-dialog premium-modal-dialog" role="document">
                        <div class="modal-content premium-modal" style={{ backgroundColor: "#f8f8fb" }}>

                            <div class="modal-header m-modal-header" style={{ 'borderBottom': 'none' }}>
                                <button onClick={(e) => { this.props.closeModal(true); }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>


                            <div class="modal-body command-edit-body premium-modal-body">
                                <div className="premium-modal-content">
                                    <img style={{ height: "50px", width: "50px" }} src={process.env.PUBLIC_URL + "/images/logo-red.png"}></img>
                                    <h2 style={{ textAlign: "center", fontSize: "28px" }}>Join the BotGhost Mailing List</h2>
                                    <p style={{ textAlign: "center" }}>Join the BotGhost mailing list to be notified of updates, sales, and more.</p>
                                    <div>
                                        {/* <button className="btn btn-gray" onClick={() =>{this.props.closeModal()}}>Close</button> */}

                                        <button className="btn btn-red ml-2" onClick={() => { this.joinServer() }}>Join Mailing List</button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    updateServerPopup
}

export default connect(mapStateToProps, mapDispatchToProps)(MailingListModal)
