import React, { Component } from 'react'
import { connect } from 'react-redux'

export class DiscordMessagePreview extends Component {

    componentDidMount() {
        // console.log(this.props.response)
    }

    renderButtons = () => {
        var buttons = []
        if (this.props.buttons != undefined && this.props.buttons.length > 0) {
            this.props.buttons.forEach((button, index) => {
                var className = ""
                buttons.push(
                    <button type="button" class={`discord-button discord-button-${button.style.toLowerCase()}`}>{button.label || `Button ${index + 1}`}</button>
                )
            })
            return (
                <div className="discord-buttons">
                    {buttons}
                </div>
            )
        } else {
            return null
        }
    }

    render() {
        return (
            <div className="discord-preview-container">
                <div className="discord-preview">
                    <div className="discord-messages">
                        <div className="discord-message">
                            {this.props.interaction == true ?
                                <div className="discord-interaction">
                                    <img class="discord-interaction-author-avatar" src={process.env.PUBLIC_URL + "/images/logo-red.png"} alt="" />
                                    <span class="discord-author-info discord-interaction-author-info">
                                        <span class="discord-author-username">{this.props.user.name}</span>
                                    </span>

                                    <span class="discord-interaction-command">
                                        used
                                        <span class="discord-interaction-command-name"> /{this.props.interaction_name}</span>
                                    </span>
                                </div> : null}


                            <div className="discord-message-content">
                                <div class="discord-author-avatar">
                                    <img src="https://cdn.discordapp.com/embed/avatars/2.png" alt="" />

                                </div>

                                <div class="discord-message-body">
                                    <div>
                                        <span class="discord-author-info">
                                            <span class="discord-author-username">{this.props.bot.name}</span>
                                            <span class="discord-author-bot-tag"> Bot </span>
                                        </span>
                                        <span class="discord-message-timestamp">09/15/2021</span>
                                    </div>
                                    {this.props.type == "embed" ?
                                        <div className="discord-embed">
                                            <div class="discord-embed-left-border" style={{ backgroundColor: this.props.embed.color }}></div>

                                            <div className="discord-embed-container">
                                                <div className="discord-embed-content">
                                                    <div>
                                                        {/* AUTHOR */}
                                                        <div class="discord-embed-author">
                                                            {/* <img class="discord-embed-author-icon" src="https://i.imgur.com/AfFp7pu.png" alt=""/> */}
                                                            <a rel="noopener noreferrer">{this.props.embed.author}</a>
                                                        </div>

                                                        <div class="discord-embed-title">
                                                            <a href={this.props.embed.url} target="_blank" rel="noopener noreferrer">{this.props.embed.title}</a>
                                                        </div>

                                                        <div class="discord-embed-description"> {this.props.embed.description} </div>


                                                        {this.props.embed.image ? <img class="discord-embed-image" src={this.props.embed.image} alt=""></img> : null}
                                                    </div>
                                                    {this.props.embed.thumbnail ? <img class="discord-embed-thumbnail" src={this.props.embed.thumbnail} alt="" /> : null}
                                                </div>

                                                <div class="discord-embed-footer">
                                                    {/* <img class="discord-embed-footer-icon" src="https://i.imgur.com/AfFp7pu.png" alt=""> */}
                                                    <span>
                                                        <span>{this.props.embed.footer}</span>

                                                        {/* <span class="discord-embed-footer-separator"> • </span> */}
                                                        {/* <span>01/01/2018</span> */}
                                                    </span>
                                                </div>
                                            </div>

                                        </div> : <span>{this.props.response}</span>}

                                    {this.renderButtons()}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
    user: state.data.user
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(DiscordMessagePreview)
