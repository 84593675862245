import React, { Component } from 'react'
import { connect } from 'react-redux'
import CommandAdd from './CommandAdd'

import { setBotModule } from "../../../actions"

export class TimedMessage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showDropDown: false
        }
    }



    updateMessage = (value) => {
        this.props.update(value, this.props.index);
    }

    delete = () => {
        const messenger = { ...this.props.messenger };
        // console.log(this.props.index)
        messenger.messages.splice(this.props.index, 1)
        this.props.setBotModule({
            module: "messenger",
            module_data: messenger
        })

    }

    render() {
        return (
            <div class="announcement">
                <div class="announcement-header" onClick={(e) => { this.setState({ showDropDown: !this.state.showDropDown }) }}>
                    <div>
                        <h3 class="mb-0">{this.props.value.description}</h3>
                        <div class="section-content-header">#{this.props.value.channels[0] != undefined ? this.props.value.channels[0].name : null}</div>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                        <i className={`bi bi-caret-${this.state.showDropDown ? "up" : "down"}-fill`} style={{ fontSize: "20px" }}></i>
                    </div>
                </div>
                {
                    this.state.showDropDown ?
                        <div className="announcement-content m-p-0">
                            <CommandAdd
                                type={this.props.value.type}
                                embed={true}
                                name_input={true}
                                name="Message Description"
                                saveText="Save"
                                title="Message Content"
                                save={this.updateMessage}
                                interval={true}
                                value={this.props.value.value}
                                reset={false}
                                delete={true}
                                onDelete={this.delete}
                            ></CommandAdd>
                        </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    messenger: state.data.bot.commands.messenger
})

const mapDispatchToProps = {
    setBotModule
}

export default connect(mapStateToProps, mapDispatchToProps)(TimedMessage)
