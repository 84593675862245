import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PremiumModal } from './PremiumModal';

export class PremiumSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        };
    }

    closeModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        if (this.props.premiumRequired == false) {
            return (
                <div>
                    <section>
                        <div>
                            {this.props.children}
                        </div>

                    </section>

                    <div>
                        {this.state.showModal ? <PremiumModal closeModal={this.closeModal}></PremiumModal> : null}
                    </div>
                </div>
            );
        }
        return (
            <div>
                <section onClick={() => { this.props.premium == false ? this.setState({ showModal: true }) : this.setState({ showModal: false }); }} className={`${this.props.premium == false ? "premium-section" : null}`}>
                    {/* <section> */}
                    <div className={`${this.props.premium == false && "module-disabled"}`}>
                        {this.props.children}
                    </div>

                </section>

                <div>
                    {this.state.showModal ? <PremiumModal closeModal={this.closeModal}></PremiumModal> : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    premium: state.data.premium
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumSection);
