import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setBotModule } from '../../../actions'
import Dashboard from '../Dashboard'
import ModuleHeader from '../elements/ModuleHeader'
import OpenSeaAlert from '../elements/OpenSeaAlert'
import SectionHeader from '../elements/SectionHeader'
import Autoresponse from './Autoresponse'

export class OpenSea extends Component {

  constructor(props) {
    super(props)

    this.state = {
      type: "item_sold",
      slug: "",
      showIndex: null
      //  first
    }
  }

  renderAlerts = () => {
    var alerts = []

    this.props.opensea_alerts.alerts.forEach((alert, index) => {

      const deleteAlert = () => {
        var opensea_alerts = { ...this.props.opensea_alerts };
        opensea_alerts.alerts.splice(index, 1);
        this.props.setBotModule({
          module: "opensea_alerts",
          module_data: opensea_alerts,
        });
        this.setState({ showIndex: null });
      };

      var close = () => {
        this.setState({ showIndex: null });
      }
      alerts.push(
        <div className="announcement">
          <div
            className="announcement-header"
            onClick={(e) => {
              this.setState({
                showIndex: this.state.showIndex == index ? null : index,
              });
            }}
          >
            <div>
              <h3 className="mb-0">{alert.slug}</h3>
              <div className="section-content-header">{`https://opensea.io/collection/${alert.slug}`}</div>
            </div>
            <div style={{ marginLeft: "auto" }}>
              {this.state.showIndex == index ? (
                <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i>
              ) : (
                <i
                  style={{ fontSize: "20px" }}
                  class="bi bi-caret-down-fill"
                ></i>
              )}
            </div>
          </div>

          {this.state.showIndex == index ? (
            <div className="announcement-content section-content-normal">
              <OpenSeaAlert
                delete={deleteAlert}
                alert={alert}
                index={index}
                close={close}

              />
            </div>
          ) : null}
        </div>
      );
    })
    return alerts;
  }

  render() {
    return (
      <Dashboard>
        <ModuleHeader
          tutorial="https://docs.botghost.com/crypto-and-nfts/opensea-alerts"
          showEnabled={true}
          title="OpenSea Alerts"
          docs="https://docs.botghost.com/crypto-and-nfts/opensea-alerts"
          description="Recieve item sales and listings alerts"
          icon="opensea.png"
          enabledValue={this.props.opensea_alerts.enabled}
          change={(value) => {
            {
              var opensea_alerts = { ...this.props.opensea_alerts };
              opensea_alerts.enabled = value;

              this.props.setBotModule({
                module: "opensea_alerts",
                module_data: opensea_alerts,
              });
            }
          }}
        ></ModuleHeader>




        <section>


          <section>
            <SectionHeader title="New Alert" pretitle="OpenSea Alerts" />

            <div className="section-content-normal">
              <div class="mb-15">
                <h3 style={{ marginBottom: "0px", fontSize: "26px" }}>
                  Add OpenSea Alert
                </h3>
                <div className="section-content-header">
                  <p className='m-w-100 m-breakword'>Add a new OpenSea Alert to your bot. You can find the slug of the collection in the OpenSea URL. For example https://opensea.io/collection/<span style={{ color: "#ff1500", fontWeight: "800" }}>boredapeyachtclub</span>.</p>
                </div>
              </div>
              <span style={{ color: "#ff1500", fontWeight: "800" }}>
                {this.state.error}
              </span>

              <hr class="slashcommand-hr" />
              <OpenSeaAlert index={'new'} />

            </div>
          </section>
        </section>


        <section>
          <SectionHeader title="Active Alerts" pretitle="OpenSea Alerts" />

          {this.renderAlerts()}
        </section>
      </Dashboard>
    )
  }
}

const mapStateToProps = (state) => ({
  opensea_alerts: state.data.bot.commands.opensea_alerts
})

const mapDispatchToProps = { setBotModule }

export default connect(mapStateToProps, mapDispatchToProps)(OpenSea)
