import React, { Component } from 'react'
import { connect } from 'react-redux'

import server from '../../../api/server'
import ReactGA from 'react-ga';


import history from "../../../history"
import { setQuickStart, setOnlineStatus, setServerIntents, setAuth, getData, fetchBotData, getBots, updateErrorLogs, updateServerPopup } from '../../../actions';


export class MusicBotModal extends Component {


    constructor(props) {
        super(props)

        this.state = {
            disabled: false
        }
    }

    modalBackgroundClick = (e) => {
        // console.log("MODAL BACKGROUND CLICK")
        if (e.currentTarget == e.target) {
            this.props.closeModal(true)
        }
    }
    componentDidMount() {
        // console.log("PREMIUM MODAL MOUNTED")
    }



    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} onClick={(e) => { this.modalBackgroundClick(e) }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div class="modal-dialog premium-modal-dialog" role="document">
                        <div class="modal-content premium-modal" style={{ backgroundColor: "#f8f8fb" }}>

                            <div class="modal-header m-modal-header" style={{ 'borderBottom': 'none' }}>
                                <button onClick={(e) => { this.props.closeModal(true); }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>


                            <div class="modal-body command-edit-body premium-modal-body">
                                <div className="premium-modal-content">
                                    <img style={{ height: "50px", width: "50px" }} src={process.env.PUBLIC_URL + "/images/icons/music.png"}></img>
                                    <h2 style={{ textAlign: "center", fontSize: "28px" }}>Try BotGhost Music?</h2>
                                    <p style={{ opacity: "0.7" }}>Click the button below to try this modules features in your server.</p>
                                    <div>
                                        {/* <button className="btn btn-gray" onClick={() =>{this.props.closeModal()}}>Close</button> */}

                                        <a disabled={this.state.disabled} href={`https://discord.com/oauth2/authorize?client_id=958664564470345748&scope=applications.commands%20bot&permissions=8`} target="_blank" className="btn btn-red ml-2" onClick={() => {
                                            ReactGA.initialize('UA-122665050-1');
                                            ReactGA.event({
                                                category: "Music Bot",
                                                label: "Online",
                                                action: "Try Bots"
                                            })
                                            this.props.closeModal()
                                        }}>Try BotGhost Music</a>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    intent: state.data.server_members_intent,
    user: state.data.user,
    premium: state.data.premium,
    page: state.page
    , auth: state.auth,
    data: state.data,
    loading: state.data.loading,
    save_required: state.data.save_required,
    processing: state.data.processing,
    bot: state.data.bot
})

const mapDispatchToProps = {
    updateServerPopup,
    setOnlineStatus,
    setQuickStart,
    setServerIntents, updateServerPopup, setAuth, getData, fetchBotData, getBots, updateErrorLogs
}

export default connect(mapStateToProps, mapDispatchToProps)(MusicBotModal)
