
import { isNode,isEdge } from "react-flow-renderer"



export default function splitElements(elements){
    var nodes = []
    var edges = []
    elements.forEach(element =>{
        if(isNode(element)){
          nodes.push(element)
        }else if(isEdge(element)){
            edges.push(element)
        }
      })

      return {
          nodes:nodes,
          edges:edges
      }
}