import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";
import { TextArea } from "../elements/TextArea";
import { Select } from "../elements/Select";

import TimedMessage from "../elements/TimedMessage";
import CommandAdd from "../elements/CommandAdd";

import Dashboard from "../Dashboard";

import { setBotModule } from "../../../actions";
import PremiumRedirect from "../elements/PremiumRedirect";
import PremiumSection from "../elements/PremiumSection";
import PremiumModal from "../elements/PremiumModal";
import { ModuleDisabled } from "../elements/ModuleDisabled";

export class TimedMessages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPremiumModal: false,
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  moduleEnable = (value) => {
    const messenger = { ...this.props.messenger };
    messenger.enabled = value;

    this.props.setBotModule({
      module: "messenger",
      module_data: messenger,
    });
  };

  addMessage = (value) => {
    var object = {};
    if (value.type == "text") {
      object = {
        id: Math.random().toString(36).substr(2, 9),
        type: "text",
        content: value.content,
        channels: [value.channel],
        time: {
          type: "interval",
          amount: value.interval.amount,
          interval: value.interval.interval,
        },
        description: value.name,
        value: value,
      };
    } else {
      object = {
        id: Math.random().toString(36).substr(2, 9),
        type: "embed",
        embed: value.embed,
        channels: [value.channel],
        time: {
          type: "interval",
          amount: value.interval.amount,
          interval: value.interval.interval,
        },
        description: value.name,
        value: value,
      };
    }
    const messenger = { ...this.props.messenger };
    if (
      this.props.premium == true ||
      this.props.messenger.messages.length < 5
    ) {
      messenger.messages.push(object);
      this.props.setBotModule({
        module: "messenger",
        module_data: messenger,
      });
    } else {
      this.setState({ showPremiumModal: true });
    }
  };

  updateMessage = (value, index) => {
    var messenger = { ...this.props.messenger };
    if (value.interval == undefined) {
      value.interval = { type: "interval", amount: "1", interval: "minute" };
    }
    var object = {};
    if (value.type == "text") {
      object = {
        id: Math.random().toString(36).substr(2, 9),
        type: "text",
        content: value.content,
        channels: [value.channel],
        time: {
          type: "interval",
          amount: value.interval.amount,
          interval: value.interval.interval,
        },
        description: value.name,
        value: value,
      };
    } else {
      object = {
        id: Math.random().toString(36).substr(2, 9),
        type: "embed",
        embed: value.embed,
        channels: [value.channel],
        time: {
          type: "interval",
          amount: value.interval.amount,
          interval: value.interval.interval,
        },
        description: value.name,
        value: value,
      };
    }

    messenger.messages[index] = object;

    this.props.setBotModule({
      module: "messenger",
      module_data: messenger,
    });
  };

  renderTimedMessages = () => {
    const messages = [];

    this.props.messenger.messages.forEach((message, index) => {
      messages.push(
        <TimedMessage
          value={message}
          index={index}
          update={this.updateMessage}
        ></TimedMessage>
      );
    });
    return messages;
  };

  render() {
    return (
      <Dashboard>
        {this.state.showPremiumModal == true ? (
          <PremiumModal
            closeModal={(e) => {
              this.setState({ showPremiumModal: false });
            }}
          />
        ) : null}

        <ModuleHeader
          youtube="https://www.youtube.com/watch?v=pz29Lyyebcw"
          tutorial="https://docs.botghost.com/server-management/timed-messages"
          premiumRequired={false}
          enabledValue={this.props.messenger.enabled}
          change={this.moduleEnable}
          title="Timed Messages"
          docs="https://docs.botghost.com/server-management/timed-messages"
          description="Automatically send timed messages to your server"
          icon="messenger.png"
        ></ModuleHeader>

        <ModuleDisabled disabled={!this.props.messenger.enabled}>
          <section>
            <SectionHeader
              title="Add Timed Message"
              pretitle="Timed Messages"
            ></SectionHeader>
            <div></div>
            <CommandAdd
              embed={true}
              name_input={true}
              name="Message Description"
              saveText="Add"
              title="Message Content"
              save={this.addMessage}
              interval={true}
              value={{}}
              premiumRequired={false}
            ></CommandAdd>
          </section>

          <section>
            <SectionHeader
              title={`Messages ${this.props.premium == false
                  ? `${this.props.messenger.messages.length}/5`
                  : ""
                }`}
              pretitle="Timed Messages"
            ></SectionHeader>
            <div>{this.renderTimedMessages()}</div>
          </section>
        </ModuleDisabled>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  messenger: state.data.bot.commands.messenger,
  premium: state.data.premium,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimedMessages);
