import React, { Component } from "react";
import { connect } from "react-redux";
import MarketItem from "../elements/MarketItem";

import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BookmarkIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { MARKET_CATEGORIES } from "../../../variables";
import server from "../../../api/server";
import LZString from "lz-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faSave } from "@fortawesome/free-solid-svg-icons";
import { setBlockTemplates } from "../../../actions";

export class BlockTemplates extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchTerm: "",
			delete_id: null,
			showDeleteModal: false,
			disableDeleteButton: false
		};
	}

	renderItems() {
		var items = [];

		this.props.block_templates.forEach((item, index) => {
			// if search is blank or title or description is in search term,
			if (this.state.searchTerm == "" || item.name.toLowerCase().includes(this.state.searchTerm.toLowerCase()) || item.description.toLowerCase().includes(this.state.searchTerm.toLowerCase())) {
				items.push(
					<div
						onClick={() => {
							// this.onClick();
							this.setState({ delete_id: item.id, showDeleteModal: true });
							console.log("clicked");
						}}
						className={`command-builder-object hover:cursor-pointer ${this.props.disabled ? "builder-disabled" : null}`}
						draggable={this.props.disabled ? false : true}
						onDragStart={(e) => this.onDragStart(e, item)}
					>
						<div className="inner" id={`${this.props.id ? this.props.id : ""}`}>
							<div className="object-grip">
								<FontAwesomeIcon icon={faGripVertical} />
							</div>

							<div className={`object-icon object-icon-red bg-red text-white`}>
								<FontAwesomeIcon icon={faSave} />
							</div>

							<div className="object-info">
								<span className="object-title">{item.name}</span>
								<span className="object-description">{item.description}</span>
							</div>
						</div>
					</div>
				);
			}

		});

		return items;
	}

	onDragStart = (event, data) => {
		var elements = [];

		var elements_string = LZString.decompressFromBase64(data.elements_string);

		elements = JSON.parse(elements_string);

		event.dataTransfer.setData(
			"options",
			JSON.stringify({
				elements: elements,
				type: "block_template"
			})
		);
		event.dataTransfer.effectAllowed = "move";
	};
	render() {
		return (
			<>
				<Transition.Root show={this.state.showDeleteModal} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={() => {
							this.setState({ showDeleteModal: false });
						}}
					>
						<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
							<div className="fixed inset-0 bg-[#0000008a] transition-opacity" />
						</Transition.Child>

						<div className="fixed inset-0 z-10 overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
									<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-lightGray px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
										<div className="sm:flex sm:items-start">
											<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red sm:mx-0 sm:h-10 sm:w-10">
												<ExclamationTriangleIcon className="h-6 w-6 text-white" aria-hidden="true" />
											</div>
											<div className="text-left ml-2">
												<Dialog.Title as="h3" className="text-xl text-white font-bold leading-6">
													Delete this template?
												</Dialog.Title>
												<div className="mt-2">
													<p className="text-muted mb-3 font-semibold">Are you sure you want to delete this template? This action cannot be undone.</p>
												</div>
											</div>
										</div>
										<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
											<button
												type="button"
												className="inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
												disabled={this.state.disableDeleteButton}
												onClick={async () => {
													this.setState({
														disableDeleteButton: true
													});

													var response = await server.post("/dashboard/block_templates/delete", {
														id: this.state.delete_id
													});

													if (response && response?.data?.success) {
														this.setState({ showDeleteModal: false, disableDeleteButton: false, delete_id: null });
														this.props.setBlockTemplates(response.data.templates);
													}
												}}
											>
												Delete Template
											</button>
											<button type="button" className=" inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm" onClick={() => this.setState({ showDeleteModal: false })}>
												Cancel
											</button>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
				<div className="command-builder-objects pt-2 ml-[-20px]">
					<nav className="flex  mb-3 px-2 content-center justify-center">
						<div className="long-input w-full">
							<input
								onChange={(e) => {
									this.setState({ searchTerm: e.target.value });
								}}
								value={this.state.searchTerm}
								type="text"
								placeholder="Search"
							></input>
						</div>
					</nav>

					<div className="ml-[20px]">
						{this.props.block_templates.length == 0 ? (
							<div className="flex flex-col items-center text-center pt-5 pr-[20px]">
								<h2 className="text-white text-lg font-bold">You don't have any block templates</h2>
								<span className="object-description">
									You can save new block templates by selecting multiple blocks by holding <strong className="!text-red !opacity-100">Shift</strong> and then clicking the <strong className="!text-red !opacity-100">Save Template</strong> button.
								</span>
							</div>
						) : (
							this.renderItems()
						)}
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	block_templates: state.data.user?.block_templates ? state.data.user.block_templates : []
});

const mapDispatchToProps = {
	setBlockTemplates: setBlockTemplates
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockTemplates);
