import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../Dashboard";
import ModuleHeader from "../elements/ModuleHeader";
import SectionHeader from "../elements/SectionHeader";
import WebhookItem from "../elements/WebhookItem";
import server from "../../../api/server";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { setBotModule } from "../../../actions";
import DocsLink from "../elements/DocsLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import RefreshIFTTT from "../elements/RefreshIFTTT";
import IFTTTItem from "../elements/IFTTTItem";

export class IFTTT extends Component {
	constructor(props) {
		super(props);

		this.state = {
			key: "1234",
			showKey: false,
			buttonsDisabled: false,
			showIndex: null,
			services: [],
			search: ""
		};
	}

	componentDidMount = async () => {
		var response = await server.get("/dashboard/ifttt/services");
		if (response.data) {
			this.setState({ services: response.data });
		}
	};

	regenerate = async () => {
		this.setState({ buttonsDisabled: true });
		var response = await server.post("/dashboard/generateAPIKey", {
			bot_id: this.props.bot.id,
			cocreate: this.props.cocreate
		});
		if (response.data.success) {
			this.setState({ key: response.data.key, showKey: true });
		}

		this.setState({ buttonsDisabled: false });
	};

	showKey = async () => {
		this.setState({ buttonsDisabled: true });
		if (this.state.showKey) {
			this.setState({ showKey: false, key: "" });
		} else {
			var response = await server.post("/dashboard/fetchAPIKey", {
				bot_id: this.props.bot.id,
				cocreate: this.props.cocreate
			});
			if (response.data.success) {
				this.setState({ key: response.data.key, showKey: true });
			}
		}

		this.setState({ buttonsDisabled: false });
	};

	renderWebhooks = () => {
		var webhooks = [];

		this.props.webhooks.webhooks.forEach((webhook, index) => {
			webhooks.push(
				<div>
					<div className="announcement">
						<div
							className="announcement-header"
							onClick={(e) => {
								this.setState({
									showIndex: this.state.showIndex == index ? null : index
								});
							}}
						>
							<div className="w-full">
								<h3 className="mb-0">{webhook.name} </h3>
								<div className="section-content-header overflow-clip w-full">
									https://api.botghost.com/webhook/{this.props.bot.id}/{webhook.id}
								</div>
							</div>
							<div style={{ marginLeft: "auto" }}>{this.state.showIndex == index ? <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i> : <i style={{ fontSize: "20px" }} class="bi bi-caret-down-fill"></i>}</div>
						</div>

						{this.state.showIndex == index ? (
							<div className="announcement-content section-content-normal">
								<WebhookItem
									index={index}
									webhook={webhook}
									delete={(index) => {
										var webhooks = { ...this.props.webhooks };
										webhooks.webhooks.splice(index, 1);
										this.props.setBotModule("webhooks", webhooks);
									}}
									onChange={(data, index) => {
										var webhooks = { ...this.props.webhooks };
										webhooks.webhooks[index] = {
											id: webhook.id,
											name: data.name
										};

										this.props.setBotModule("webhooks", webhooks);
										this.setState({ showIndex: null });
									}}
								/>
							</div>
						) : null}
					</div>
				</div>
			);
		});

		return webhooks;
	};

	renderServices = () => {
		var services = [];

		var filteredServices = this.state.services.filter((service) => {
			return service.name.toLowerCase().includes(this.state.search.toLowerCase());
		});

		filteredServices.forEach((service) => {
			services.push(
				<div
					onClick={(e) => {
						// Open in new tab
						window.open(`https://ifttt.com/connect/botghost/${service.slug}`, "_blank").focus();
					}}
					style={{
						background: `#${service.color}`
					}}
					className="hover:cursor-pointer hover:opacity-80 flex justify-center align-center flex-col h-[150px] text-center"
				>
					<img className="h-[40px] mb-4" src={service.icon}></img>
					<h3 className="text-white text-xl font-bold">{service.name}</h3>
				</div>
			);
		});

		return services;
	};

	render() {
		return (
			<Dashboard>
				<ModuleHeader description={"Connect your bot to thousands of apps and services"} title={"IFTTT"} showDocs={true} docs="https://docs.botghost.com/custom-commands-and-events/ifttt" showEnabled={false} tutorial="https://docs.botghost.com/custom-commands-and-events/ifttt" icon={"IFTTT.webp"} />

				<section>
					<SectionHeader title="Services" pretitle="IFTTT" />
					{/* Search bar */}
					<div className="mt-15">
						<div
							className="long-input"
							style={{
								width: "100%"
							}}
						>
							<input
								style={{
									backgroundColor: "#2f3136"
								}}
								className="!h-[55px] !py-2"
								type="text"
								placeholder="Search IFTTT Services"
								value={this.state.search}
								onChange={(e) => {
									this.setState({ search: e.target.value });
								}}
							></input>
						</div>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-6 gap-y-6 mt-6">{this.renderServices()}</div>
				</section>
			</Dashboard>
		);
	}
}

const mapStateToProps = (state) => ({
	bot: state.data.bot,
	cocreate: state.data.co_create,
	webhooks: state.data.bot.commands.webhooks,
	ifttt_linked: state.data.user.ifttt_linked
});

const mapDispatchToProps = {
	setBotModule
};

export default connect(mapStateToProps, mapDispatchToProps)(IFTTT);
