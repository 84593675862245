import React, { Component } from "react";
import { connect } from "react-redux";

import ReactFlow, { ReactFlowProvider, getIncomers, isNode } from "react-flow-renderer";
import CommandBuilderNav from "./CommandBuilderNav";
import CommandBuilderAside from "./CommandBuilderAside";
import CommandBuilderCanvas from "./CommandBuilderCanvas";
import CommandBuilderProperties from "./CommandBuilderProperties";

import { updateElementData, setBotData, resetBuilder } from "../../../actions";
import history from "../../../history";

import ReactGA from "react-ga";
import splitElements from "./splitElements";
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
import Cookies from "universal-cookie";
import { Helmet } from "react-helmet";
import server from "../../../api/server";

const steps = [
	{
		element: "#text-option",
		intro: (
			<div className="command-builder-tooltip-inner">
				<h2>Drag and Drop a Block</h2>
				<p>Drag and drop an Option, Action or Condition into the command builder.</p>
				<img src={process.env.PUBLIC_URL + `/images/gifs/command_drop.gif`}></img>
			</div>
		),
		position: "right",
		tooltipClass: "command-builder-tooltip",
		highlightClass: "myHighlightClass"
	},
	{
		element: "#root-inner",
		intro: (
			<div className="command-builder-tooltip-inner">
				<h2>Connect Blocks</h2>

				<p>Connect Blocks of the same color.</p>
				<img src={process.env.PUBLIC_URL + `/images/gifs/command_connect.gif`}></img>
			</div>
		),
		position: "left",
		tooltipClass: "command-builder-tooltip",
		highlightClass: "myHighlightClass"
	},

	{
		element: "#command-builder-properties",
		intro: (
			<div className="command-builder-tooltip-inner">
				<h2>Fill in the Options</h2>

				<p>Click an Option, Action or Condition and fill in its required Details.</p>
				<img src={process.env.PUBLIC_URL + `/images/gifs/command_options.gif`}></img>
			</div>
		),
		position: "left",
		tooltipClass: "command-builder-tooltip",
		highlightClass: "myHighlightClass"
	},

	{
		element: "#command-builder-save",
		intro: (
			<div className="command-builder-tooltip-inner">
				<h2>Save your command</h2>

				<p>Save your command for your changes to take effect.</p>
				<img src={process.env.PUBLIC_URL + `/images/gifs/command_save.gif`}></img>
			</div>
		),
		position: "left",
		tooltipClass: "command-builder-tooltip",
		highlightClass: "myHighlightClass"
	}

	// {
	//   element: '.selector2',
	//   intro: 'test 2',
	// },
	// {
	//   element: '.selector3',
	//   intro: 'test 3',
	// },
];

export class CommandBuilder extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: "sd",
			open: true,
			saveRequired: false,
			stepsEnabled: false,
			elements: [
				{
					id: "root",
					type: "root",
					data: {
						title: "Number",
						description: "text"
					},
					// target:"3",
					draggable: false,
					position: { x: 250, y: 250 }
				}
			]
		};
	}

	componentDidMount() {
		ReactGA.initialize("UA-122665050-1");
		// console.log(window.location.pathname)
		ReactGA.pageview(window.location.pathname);
		if (this.props.elements.length == 0) {
			history.push("/dashboard/customcommands");
		}
		// console.log("COMMAND BUILDER MOUNTED");
		const cookies = new Cookies();
		// console.lo
		if (cookies.get("commandBuilder") == null) {
			cookies.set("commandBuilder", "true");
			this.setState({ stepsEnabled: true });
		}
		this.loadBotData();
	}

	loadBotData = async () => {
		// console.log("LOADING BOT DATA");
		const response = await server
			.post("/dashboard/botData", {
				id: this.props.bot.id,
				token: this.props.bot.token
			})
			.catch((e) => {});

		if (response.data && response.data.success == true) {
			const bot_data = response.data.bot_data;
			this.props.setBotData(bot_data);
		}
	};

	componentWillUnmount() {
		// console.log("COMMAND BUILDER UNMOUNTED");
		this.props.resetBuilder();
	}

	componentDidUpdate(prevProps, prevState) {
		this.props.elements.forEach((element, index) => {
			if (isNode(element) && element.type != "root" && (element.type == "action" || element.type == "condition")) {
				var edges = splitElements(this.props.elements).edges;
				var incomers = getIncomers(element, splitElements(this.props.elements).nodes, edges);
				if (element.data && element.data.data != undefined) {
					var data = { ...element.data.data };
					if (incomers.length === 0 && (element.data.data.linked == undefined || element.data.data.linked == true)) {
						data.linked = false;

						this.props.updateElementData({
							data: data,
							id: element.id
						});
					} else if (incomers.length >= 1 && (element.data.data.linked == undefined || element.data.data.linked == false)) {
						data.linked = true;

						this.props.updateElementData({
							data: data,
							id: element.id
						});
					}
				}
			}
		});
	}

	render() {
		if (this.props.elements.length > 0) {
			return (
				<div className="command-builder" style={{ height: "100vh" }}>
					<Helmet>
						<meta name="viewport" content="initial-scale=0" />
					</Helmet>
					{/* <Steps
						enabled={this.state.stepsEnabled}
						steps={steps}
						initialStep={0}
						showStepNumbers={true}
						onExit={() => {
							this.setState({ stepsEnabled: false });
						}}
					/> */}
					<ReactFlowProvider>
						<CommandBuilderNav
							saveRequired={this.state.saveRequired}
							triggerSave={() => {
								if (this.state.saveRequired) {
									this.setState({ saveRequired: false });
								}
							}}
						/>
						<CommandBuilderAside />
						<CommandBuilderProperties />
						<CommandBuilderCanvas
							triggerSave={() => {
								// Trigger a save in the CommandBuilderNav
								this.setState({ saveRequired: true });
							}}
							// elements={this.state.elements}
							// selected={this.state.selected}
							// setElements = {(elements) =>{this.setState({elements})}}
							// setSelected={(node) =>{this.setState({selected:node})}}
						/>
					</ReactFlowProvider>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	elements: state.builder.elements,
	bot: state.data.bot
});

const mapDispatchToProps = {
	updateElementData,
	resetBuilder,
	setBotData
};

export default connect(mapStateToProps, mapDispatchToProps)(CommandBuilder);
