import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';
import RefreshBotData from '../../elements/RefreshBotData';
import Select from '../../elements/Select';
import RoleAdd from '../../elements/RoleAdd';
import DocsLink from '../../elements/DocsLink';
import { checkIntegrationChild } from '../eventUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import VariableTextInput from '../VariableTextInput';

const operations = [
    {
        type: "length",
        requirements: [],
        title: "Get Length",
        description: "Returns the length of the text"
    },
    {
        type: "charAt",
        requirements: [{
            name: "index",
            title: "Index",
            description: "The index of the character to retrieve"
        }],
        title: "Character At",
        description: "Returns the character at the specified index"
    },
    {
        type: "slice",
        requirements: [{
            name: "start",
            title: "Start Index",
            description: "The index to start extraction from"
        },
        {
            name: "end",
            title: "End Index",
            description: "The index to end extraction at"
        }],
        title: "Slice",
        description: "Extracts a section of the text between the start and end indices"
    },
    {
        type: "substring",
        requirements: [{
            name: "start",
            title: "Start Index",
            description: "The starting index of the substring"
        },
        {
            name: "end",
            title: "End Index",
            description: "The ending index of the substring"
        }],
        title: "Substring",
        description: "Extracts the characters between two specified indices"
    },
    {
        type: "toUpperCase",
        requirements: [],
        title: "To Uppercase",
        description: "Converts the text to uppercase"
    },
    {
        type: "toLowerCase",
        requirements: [],
        title: "To Lowercase",
        description: "Converts the text to lowercase"
    },
    {
        type: "trim",
        requirements: [],
        title: "Trim",
        description: "Removes whitespace from both ends of the text"
    },
    {
        type: "replace",
        requirements: [{
            name: "search",
            title: "Search",
            description: "The substring to be replaced"
        },
        {
            name: "replace",
            title: "Replacement",
            description: "The substring to replace with"
        }],
        title: "Replace",
        description: "Replaces a substring with another substring"
    },
    {
        type: "replaceAll",
        requirements: [{
            name: "search",
            title: "Search",
            description: "The substring to be replaced"
        },
        {
            name: "replace",
            title: "Replacement",
            description: "The substring to replace with"
        }],
        title: "Replace All",
        description: "Replaces all occurrences of a substring with another substring"
    },
    {
        type: "startsWith",
        requirements: [{
            name: "search",
            title: "Search",
            description: "The substring to check for at the start"
        }],
        title: "Starts With",
        description: "Checks if the text starts with a specified substring"
    },
    {
        type: "endsWith",
        requirements: [{
            name: "search",
            title: "Search",
            description: "The substring to check for at the end"
        }],
        title: "Ends With",
        description: "Checks if the text ends with a specified substring"
    },
    {
        type: "includes",
        requirements: [{
            name: "search",
            title: "Search",
            description: "The substring to search for"
        }],
        title: "Includes",
        description: "Checks if the text contains a specified substring"
    },
    {
        type: "indexOf",
        requirements: [{
            name: "search",
            title: "Search",
            description: "The substring to search for"
        }],
        title: "Index Of",
        description: "Finds the index of the first occurrence of a substring"
    },
    {
        type: "lastIndexOf",
        requirements: [{
            name: "search",
            title: "Search",
            description: "The substring to search for"
        }],
        title: "Last Index Of",
        description: "Finds the index of the last occurrence of a substring"
    },
    {
        type: "split",
        requirements: [{
            name: "search",
            title: "Search",
            description: "The delimiter used to split the text"
        }],
        title: "Split",
        description: "Splits the text into an array of substrings based on a delimiter"
    },
    {
        type: "match",
        requirements: [{
            name: "search",
            title: "Search",
            description: "The pattern to search for"
        }],
        title: "Match",
        description: "Searches for a pattern and returns the matches as an array"
    },
    {
        type: "search",
        requirements: [{
            name: "search",
            title: "Search",
            description: "The pattern to search for"
        }],
        title: "Search",
        description: "Searches for a pattern and returns the index of the first match"
    },
    {
        type: "repeat",
        requirements: [{
            name: "count",
            title: "Count",
            description: "The number of times to repeat the text"
        }],
        title: "Repeat",
        description: "Repeats the text a specified number of times"
    },
    {
        type: "json_stringify",
        requirements: [],
        title: "JSON Stringify",
        description: "Runs the JSON.stringify function on the text"
    },
    {
        type: "json_stringify_no_enclosing",
        requirements: [],
        title: "JSON Stringify (No Enclosing Quotes)",
        description: "Runs the JSON.stringify function on the text without enclosing quotes"
    }
];
export class StringManipulation extends Component {
    componentDidMount() {
        if (!this.props.data) {
            this.props.updateElementData(
                {
                    data: {
                        type: "string_manipulation",
                        string: "",
                        variable: "",
                        operation: {
                            type: "length"
                        },
                        validated: true
                    },
                    id: this.props.id
                }
            );
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (!this.props.data) {
            this.props.updateElementData(
                {
                    data: {
                        type: "string_manipulation",
                        string: "",
                        variable: "",
                        operation: {
                            type: "length"
                        },
                        validated: true
                    },
                    id: this.props.id
                }
            );
        } else {
            if (("string" in this.props.data && this.props.data.string != "") && ("variable" in this.props.data && this.props.data.variable != "") && this.checkRequirements()) {
                if (!this.props.data.validated) {
                    this.updateData("validated", true);
                }
            } else {
                if (this.props.data.validated) {
                    this.updateData("validated", false);
                }
            }
        }
    }

    checkRequirements = () => {
        if (this.props.data.operation.type != "") {
            var operation = operations.find((operation) => operation.type == this.props.data.operation.type);
            if (operation) {
                var requirements = operation.requirements;
                var valid = true;
                requirements.forEach((requirement) => {
                    if (requirement.name in this.props.data.operation) {
                        if (this.props.data.operation[requirement.name] == "") {
                            valid = false;
                        }
                    } else {
                        valid = false;
                    }
                });
                return valid;
            }
        }
        return false;
    };

    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData({ data: data, id: this.props.id });
    };

    selectUpdate = (value) => {
        value = JSON.parse(value);
        var target = { ...this.props.data.target };
        target = value;
        this.updateData("target", target);
    };

    renderRoleOptions = () => {
        var options = [];
        options.push({ value: JSON.stringify({ id: "" }), label: "" });
        this.props.options.forEach(option => {
            if (option.type == "role") {
                options.push({ value: JSON.stringify({ variable: option.name }), label: `Role Option: ${option.name}` });
            }
        });
        if (this.props.bot_data || this.props.bot_data.length > 0) {
            this.props.bot_data.forEach((guild, index) => {
                guild.roles.forEach(role => {
                    options.push({ value: JSON.stringify(role), label: `${role.name} - ${guild.name}` });
                });
            });
        }
        return options;
    };

    renderTargetTypes = () => {
        var channel = "Channel the command was run in";
        if (this.props.mode == "event" && this.props.components['Channel']) {
            channel = "The channel.";
        }
        const targetTypes = [
            { label: channel, value: "self", disabled: this.props.components.Interaction || this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
            { label: "Channel option", value: "variable", disabled: !this.props.components.Interaction },
            { label: "Channel ID", value: "id" }
        ];
        return targetTypes;
    };

    targetOnChange = (value) => {
        if (value == "self") {
            var data = { ...this.props.data };
            data.target = {
                self: true
            };
            this.props.updateElementData({ data: data, id: this.props.id });
        } else if (value == "variable") {
            var data = { ...this.props.data };
            data.target = {
                variable: "",
            };
            this.props.updateElementData({ data: data, id: this.props.id });
        } else if (value == "id") {
            var data = { ...this.props.data };
            data.target = {
                id: "",
            };
            this.props.updateElementData({ data: data, id: this.props.id });
        }
    };



    render() {
        if (this.props.data) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Manipulate some text</h4>
                        <p>Manipulate and run functions on provided text</p>
                    </div>


                    <div className="mb-15 slash-action">
                        <h4>Text <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/other-actions/manipulate-some-text" /></h4>
                        <span>The text to manipulate or run a function on.</span>
                        <VariableTextInput required={true} slash_options={this.props.options} label="Text" placeholder="Text" value={this.props.data.string} onChange={(value) => {
                            this.updateData("string", value);
                        }} />
                        {/* <div class="long-input mt-15">
                            <label>Text</label>
                            <input className={`${this.props.data.string == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {


                                this.updateData("string", e.target.value);
                            }} type="text" value={this.props.data.string} placeholder="" />
                        </div> */}
                    </div>

                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>


                    <div className="mb-15 slash-action">
                        <h4>Text Operation <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/other-actions/manipulate-some-text" /></h4>
                        <span>What to do with the provided text.</span>
                        <Select value={this.props.data.operation.type} options={
                            operations.map((operation) => {
                                return { label: operation.title, value: operation.type };
                            })
                        } onChange={(value) => {
                            var operation = { ...this.props.data.operation };
                            operation.type = {};
                            operation.type = value;
                            // Find the requirements for the operation and set them to empty
                            operations.find((operation) => operation.type == value).requirements.forEach((requirement) => {
                                operation[requirement.name] = "";
                            });


                            this.updateData("operation", operation);
                        }} />

                    </div>

                    {/* Operation title and description*/}

                    <div>
                        {this.props.data.operation.type != "" ? <>
                            <h4>{operations.find((operation) => operation.type == this.props.data.operation.type).title}</h4>
                            <p>{operations.find((operation) => operation.type == this.props.data.operation.type).description}</p>
                        </> : null}
                    </div>

                    {this.props.data.operation.type != "" ? <>

                        {operations.find((operation) => operation.type == this.props.data.operation.type).requirements.map((requirement) => {
                            return <div className="mb-15 slash-action">
                                <h4>{requirement.title} <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/other-actions/manipulate-some-text" /></h4>
                                <span>{requirement.description}</span>
                                {/* Add command-required if the value is empty */}
                                <VariableTextInput required={true} slash_options={this.props.options} label={requirement.title} placeholder={requirement.title} value={this.props.data.operation[requirement.name]} onChange={(value) => {
                                    var operation = { ...this.props.data.operation };
                                    operation[requirement.name] = value;
                                    this.updateData("operation", operation);
                                }} />
                                {/* <div class="long-input
                                mt-15">
                                    <label>{requirement.title}</label>
                                    <input onChange={(e) => {
                                        var operation = { ...this.props.data.operation };
                                        operation[requirement.name] = e.target.value;
                                        this.updateData("operation", operation);
                                    }} className={`${this.props.data.operation[requirement.name] == "" && this.props.saveFailed ? "command-required" : ""
                                        }`} type="text" value={this.props.data.operation[requirement.name]} placeholder="" />
                                </div> */}
                            </div>;
                        })}
                    </> : null}

                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>
                    <div className="mb-15 slash-action">
                        <h4>Variable<DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/other-actions/manipulate-some-text" /></h4>
                        <span>A variable name to use to assign to the result of the manipulation. </span>
                        <VariableTextInput required={true} slash_options={this.props.options} label="Variable" placeholder="Variable" value={this.props.data.variable} onChange={(value) => {
                            this.updateData("variable", value);
                        }} />
                        {/* <div class="long-input mt-15">
                            <label>Variable</label>
                            <input className={`${this.props.data.variable == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                var value = e.target.value;
                                value = value.toLowerCase();
                                value = value.substring(0, 32);
                                value = value.replace(" ", "_");
                                // value = value.replace(/[^\w]+/gi,"")
                                // value = value.replace(/[^[A-zÀ-Ÿ\d-]+/gi,"")
                                const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
                                value = value.replace(regexForNonAlphaNum, "");

                                this.updateData("variable", value);

                            }} type="text" value={this.props.data.variable} placeholder="" />
                        </div> */}
                    </div>

                    {this.props.data.variable != "" ? <>
                        <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>



                        <h4>Result Variable</h4>
                        <p className="mb-15" style={{
                            color: "#fff",
                            opacity: "0.7",
                            fontWeight: 500
                        }}>You can use the <span style={{ color: "#f45142", opacity: "1 !important" }}>{`{${this.props.data.variable}}`}</span> variable to access the result of the text operation.</p>

                        <p style={{
                            color: "#fff",
                            opacity: "0.7",
                            fontWeight: 500
                        }}><span style={{ color: "#f45142" }}>{`{${this.props.data.variable}}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.variable}}`); toast.success("Copied variable to clipboard"); }} /> - Returns the result of the text operation.</p>


                    </> : null
                    }




                </div >
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    bot_data: state.data.bot_data,
    saveFailed: state.builder.saveFailed,
    components: state.builder.components,
    beta: state.data.beta
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(StringManipulation);
