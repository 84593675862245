import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faInfinity,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const ROWS = [

  {
    feature: "Replace the 'Made with BotGhost.com' status with your own",
    free: false,
    premium: true,
  },
  {
    feature: "Priority hosting & early testing through the beta servers",
    free: false,
    premium: true,
  },
  {
    feature: "24/7 hosting",
    free: true,
    premium: true,
  },
  { feature: "Number of Custom Slash Commands", free: 8, premium: "Unlimited" },
  { feature: "Number of Custom Events", free: 3, premium: "Unlimited" },
  { feature: "Import commands from the Marketplace", free: true, premium: true },
  { feature: "Use default Moderation Commands", free: true, premium: true },
  { feature: "Moderation Logging", free: true, premium: true },
  { feature: "Create custom join/leave/ban Message", free: true, premium: true },
  { feature: "Bad Word", free: true, premium: true },
  { feature: "Links Automod", free: true, premium: true },
  { feature: "Mass Caps Automod", free: true, premium: true },
  { feature: "Mass Emoji Automod", free: true, premium: true },
  { feature: "Server Invites Automod", free: true, premium: true },
  { feature: "Mass Mention Automod", free: true, premium: true },
  { feature: "Automod Settings", free: true, premium: true },
  { feature: "Create Tickets through the Ticket Module", free: true, premium: true },
  { feature: "Tickets Panel", free: true, premium: true },
  { feature: "Custom Ticket Catagories", free: true, premium: true },
  { feature: "Send messages through the Embed Builder", free: true, premium: true },
  { feature: "Load saved embeds in Embed Builder", free: true, premium: true },
  { feature: "Set custom Autoresponders", free: 3, premium: "Unlimited" },
  { feature: "Membership screening through Auto Role", free: true, premium: true },
  { feature: "Advanced Logging with logged 42 events", free: false, premium: true },
  { feature: "Change default embed colors with the Usage Module", free: false, premium: true },
  { feature: "Set custom Timed Messages", free: 5, premium: "Unlimited" },
  { feature: "Enable 6 Statistics Channels", free: false, premium: true },
  { feature: "Create Giveaways through the Giveaway Module", free: false, premium: true },
  { feature: "Setup a Reaction Roles panel", free: true, premium: true },
  { feature: "Use the Cyrpto, NFTs & OpenSea Alerts modules", free: true, premium: true },
  { feature: "YouTube Upload Notifications", free: false, premium: true },
  { feature: "Twitch Live Notifications", free: false, premium: true },
  // { feature: "Twitter new Tweet Notifications", free: false, premium: true },
  { feature: "Reddit new post Notifications", free: false, premium: true },
  { feature: "Create Memes using ImgFlip", free: true, premium: true },
  { feature: "Setup a Leveling System", free: true, premium: true },
  { feature: "Change the leveling EXP Rate", free: true, premium: true },
  { feature: "Setup (custom) Level Up Message", free: true, premium: true },
  { feature: "Ad free level leaderboard", free: false, premium: true },
  { feature: "Setup a server economy", free: true, premium: true },
  { feature: "Customize your Economy", free: true, premium: true },
  { feature: "Economy Roles", free: false, premium: true },
  { feature: "Add custom Economy Store items", free: false, premium: true },
  // { feature: "Play Music through your bot", free: false, premium: true },
  { feature: "Play Trivia", free: false, premium: true },
  { feature: "Search Twitch", free: true, premium: true },
  { feature: "Search YouTube", free: true, premium: true },
  { feature: "Search Urban Dictionary", free: true, premium: true },
  { feature: "Search Imgur", free: true, premium: true },
  { feature: "Get the Weather", free: false, premium: true },
  { feature: "Use the Translator", free: false, premium: true },
  {
    feature: "Build your bot with a friend through Co-create",
    free: false,
    premium: true,
  },

];

export class PremiumTable extends Component {
  renderRows = () => {
    var returnRows = [];

    const renderContent = (content) => {
      if (content == false) {
        return <FontAwesomeIcon color={"red"} icon={faTimes} />;
      } else if (content == true) {
        return <FontAwesomeIcon color={"green"} icon={faCheck} />;
      } else {
        return content;
      }
    };

    ROWS.forEach((row) => {
      returnRows.push(
        <div className=" premium-table-row">
          <div className="premium-table-item premium-table-item-plan">
            {row.feature}
          </div>

          <div className="premium-table-item">{renderContent(row.free)}</div>

          <div className="premium-table-item">{renderContent(row.premium)}</div>
        </div>
      );
    });

    return returnRows;
  };
  render() {
    return (
      <div className="premium-table">
        <div className="premium-table-header">
          <div className="premium-table-item premium-table-item-plan">
            Feature
          </div>

          <div className="premium-table-item">Free</div>

          <div className="premium-table-item">Premium</div>
        </div>

        <div className="premium-table-rows">{this.renderRows()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumTable);
