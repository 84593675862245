import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { AllModules } from "../../variables/index";
import ModuleItem from "./elements/ModuleItem";
import SectionHeader from "./elements/SectionHeader";

import { updatePage } from "../../actions";
import Dashboard from './Dashboard';
import server from '../../api/server';
import { Helmet } from "react-helmet";


const tabs = [
    { name: 'Applied', href: '#', current: false },
    { name: 'Phone Screening', href: '#', current: false },
    { name: 'Interview', href: '#', current: true },
    { name: 'Offer', href: '#', current: false },
    { name: 'Hired', href: '#', current: false },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export class NewModules extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                </Helmet>

                <Dashboard>

                    <div className="relative border-b-[1px] border-[#9ca3af] sm:pb-0">
                        <div className="md:flex md:items-center md:justify-between">
                            <h3 className="text-white font-semibold text-2xl leading-6 text-gray-900">Modules</h3>
                            {/* <div className="mt-3 flex md:absolute md:right-0 md:top-3 md:mt-0">
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    Share
                                </button>
                                <button
                                    type="button"
                                    className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Create
                                </button>
                            </div> */}
                        </div>
                        <div className="mt-4">
                            <div className="sm:hidden">
                                <label htmlFor="current-tab" className="sr-only">
                                    Select a tab
                                </label>
                                <select
                                    id="current-tab"
                                    name="current-tab"
                                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                    defaultValue={tabs.find((tab) => tab.current).name}
                                >
                                    {tabs.map((tab) => (
                                        <option key={tab.name}>{tab.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="hidden sm:block">
                                <nav className="-mb-px flex space-x-8">
                                    <div
                                        key={1}
                                        href="#"
                                        className={`border-red hover:cursor-pointer text-white text-base whitespace-nowrap border-b-2 px-2 pb-3 text-sm font-medium`}
                                    >
                                        All Modules
                                    </div>
                                    {/* {tabs.map((tab) => (
                                        <a
                                            key={tab.name}
                                            href={tab.href}
                                            className={classNames(
                                                tab.current
                                                    ? 'border-indigo-500 text-indigo-600'
                                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                                            )}
                                            aria-current={tab.current ? 'page' : undefined}
                                        >
                                            {tab.name}
                                        </a>
                                    ))} */}
                                </nav>
                            </div>
                        </div>
                    </div>

                </Dashboard>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    premium: state.data.premium,
    bot: state.data.bot,
    beta: state.data.beta,
    user: state.data.user,
    modules: state.data.modules
});

export default connect(mapStateToProps, { updatePage })(NewModules);