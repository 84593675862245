import React, { Component } from 'react'
import { connect } from 'react-redux'

export class ModuleDisabled extends Component {

    componentDidMount() {
        // console.log(this.props)
    }

    render() {
        return (
            <div className={`${this.props.disabled == true && "module-disabled"}`}>
                {this.props.children}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleDisabled)
