import React, { Component } from 'react';
import { connect } from 'react-redux';

export class Loader extends Component {
    render() {
        return (
            <div className="loader-full">
                <div className="loader-contents">
                    <div><img src={process.env.PUBLIC_URL + "/images/logo-red.png"}></img>
                        {this.props.processing ? <div className="mt-3 payment-processing" style={{ color: "#fff" }}>Processing Payment...</div> : null}
                    </div>
                    {/* <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    processing: state.data.processing
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
