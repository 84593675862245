import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

export class Select extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: false
        };
    }

    componentDidMount() {
        // console.log(this.props.value);
    }

    renderOptions = () => {
        const options = [];
        if (this.props.type == "channel") {
            if (this.props.bot_data == undefined || this.props.bot_data.length == 0) {
                options.push(<optgroup label="Refresh Server Data. Ensure your bot is in a server and click the refresh button above."></optgroup>);
            } else {

                this.props.bot_data.forEach((guild, index) => {
                    if (index == 0) {
                        options.push(<option value={JSON.stringify({ id: "" })}></option>);
                    }
                    const guild_options = [];
                    guild.channels.forEach(channel => {
                        if (this.props.requireGuild == true) {
                            channel.guild = guild.id;
                        }
                        if (this.props.channelFilter == undefined && channel.type == 0 || (this.props.channelFilter && this.props.channelFilter.length > 0 && this.props.channelFilter.includes(channel.type))) {
                            guild_options.push(<option value={JSON.stringify(channel)}>#{channel.name}</option>);
                        }
                    });


                    options.push(
                        <optgroup label={guild.name}>
                            {guild_options}
                        </optgroup>
                    );
                });
            }
        } else if (this.props.type == "role") {
            if (this.props.bot_data == undefined || this.props.bot_data.length == 0) {
                options.push(<optgroup label="Refresh Server Data. Ensure your bot is in a server and click the refresh button above."></optgroup>);
            } else {
                this.props.bot_data.forEach((guild, index) => {
                    if (index == 0) {
                        options.push(<option value={JSON.stringify({ id: "" })}></option>);
                    }
                    const guild_options = [];
                    guild.roles.forEach(role => {
                        if (this.props.requireGuild == true) {
                            role.guild = guild.id;
                        }
                        guild_options.push(<option value={JSON.stringify(role)}>{role.name}</option>);
                    });

                    options.push(
                        <optgroup label={guild.name}>
                            {guild_options}
                        </optgroup>
                    );
                });
            }
        } else if (this.props.type == "categories") {
            if (this.props.bot_data == undefined || this.props.bot_data.length == 0) {
                options.push(<optgroup label="Refresh Server Data. Ensure your bot is in a server and click the refresh button above."></optgroup>);
            } else {
                this.props.bot_data.forEach((guild, index) => {
                    if (index == 0) {
                        options.push(<option value={JSON.stringify({ id: "" })}></option>);
                    }
                    const guild_options = [];
                    guild.channels.forEach(channel => {
                        if (channel.type !== 4) return;
                        if (this.props.requireGuild == true) {
                            channel.guild = guild.id;
                        }
                        guild_options.push(<option value={JSON.stringify(channel)}>{channel.name}</option>);
                    });

                    options.push(
                        <optgroup label={guild.name}>
                            {guild_options}
                        </optgroup>
                    );
                });
            }
        }
        else if (this.props.type == "interval") {
            this.props.options.forEach(option => {
                options.push(<option value={JSON.stringify(option.value)}>{option.label}</option>);
            });
        } else if (this.props.type == "slashcommand_actions") {
            this.props.options.forEach(optionGroup => {
                var suboptions = [];
                optionGroup.options.forEach(option => {
                    if (option.premium != true && (option.beta != true || this.props.beta == true) && (this.props.button != true || option.button != false)) {

                        suboptions.push(
                            <option disabled={option.disabled} value={option.value}>{option.label}{this.props.button == true ? " on button click" : null}</option>
                        );
                    } else if (option.premium == true) {
                        suboptions.push(
                            <option disabled={this.props.premium == false ? true : false} value={option.value}>{option.label}{this.props.button == true ? " on button click" : null}</option>
                        );
                    }
                });
                options.push(
                    <optgroup label={optionGroup.name}>
                        {suboptions}
                    </optgroup>
                );
            });
        }
        else if (this.props.type == "embeds") {
            if (this.props.bot_data == undefined || this.props.bot_data.length == 0) {
                options.push(<optgroup label="Refresh Server Data. Ensure your bot is in a server and click the refresh button above."></optgroup>);
            } else {
                if (this.props.bot_data[0].embeds.length === 0) {
                    options.push(<optgroup label="You have no embeds saved."></optgroup>);
                } else {
                    options.push(<option value={JSON.stringify({ id: "" })}></option>);
                    this.props.bot_data[0].embeds.forEach((embed, index) => {
                        options.push(<option value={embed.id} key={embed.id}>{embed.name}</option>);
                    });
                }
            }
        }
        else if (this.props.type == "custom_variables") {
            options.push(<option value={""} key={`variable0`}></option>);

            this.props.custom_variables.variables.forEach((variable, index) => {
                if ((variable.dataType == "array" || variable.type == "array") && this.props.custom_arrays == false) {
                    return;
                }
                options.push(<option value={variable.reference} key={`variable-${index}`}>{variable.name}</option>);

            });
            // if(this.props.bot_data == undefined || this.props.bot_data.length == 0){
            //     options.push(<optgroup label="Refresh Server Data. Ensure your bot is in a server and click the refresh button above."></optgroup>)
            // }else{
            //     if(this.props.bot_data[0].embeds.length === 0) {
            //         options.push(<optgroup label="You have no embeds saved."></optgroup>)
            //     } else {
            //         options.push(<option value={JSON.stringify({id:""})}></option>)
            //         this.props.bot_data[0].embeds.forEach((embed, index) => {
            //             options.push(<option value={embed.id} key={embed.id}>{embed.name}</option>)
            //         })
            //     }
            // }
        } else if (this.props.type == "guilds") {
            if (this.props.bot_data == undefined || this.props.bot_data.length == 0) {
                options.push(<optgroup label="Refresh Server Data. Ensure your bot is in a server and click the refresh button above."></optgroup>);
            } else {
                this.props.bot_data.forEach((guild, index) => {
                    if (index == 0) {
                        options.push(<option value={JSON.stringify({ id: "" })}></option>);
                    }

                    options.push(
                        <option value={guild.id}>
                            {guild.name}
                        </option>
                    );
                });
            }
        }
        else {
            this.props.options.forEach(option => {
                options.push(<option value={option.value} disabled={option.disabled ? true : false}>{option.label}</option>);
            });
        }


        if (this.props.slash_options != undefined && this.props.slash_options.length > 0) {

            var suboptions = [<option value={JSON.stringify({ id: "" })}></option>];
            this.props.slash_options.forEach(option => {

                suboptions.push(
                    <option value={JSON.stringify(option.value)}>
                        {option.label}
                    </option>
                );
            });
            options.unshift(
                <optgroup label={"Command Options"}>
                    {suboptions}
                </optgroup>
            );
        }
        if (this.state.options == false && options.length > 0) {
            this.setState({ options: true });
        }
        return options;
    };

    render() {
        return (
            <div className='relative'>
                {((this.props.bot_data == undefined || this.props.bot_data.length == 0) && !('options' in this.props) && this.props.type !== "custom_variables") && <div className='absolute left-5 top-1/2 transform -translate-y-1/2 pointer-events-none'>
                    <p className='text-gray-300 italics'>Click the refresh button (<FontAwesomeIcon icon={faSyncAlt} color={"#fff"} className='text-xs' />) above </p>
                </div>}
                {('options' in this.props || (!(this.props.bot_data == undefined || this.props.bot_data.length == 0))) && <div className='absolute right-5 top-1/2 transform -translate-y-1/2 pointer-events-none'>
                    <FontAwesomeIcon icon={faChevronDown} className='text-white' />
                </div>}
                <select className={`${this.props.saveFailed == true ? "command-required" : ""} hover:cursor-pointer`} required={true} disabled={this.props.disabled == true ? true : false} value={this.props.value} onChange={(e) => { this.props.onChange(e.target.value); }}>
                    {this.renderOptions()}
                </select>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bot_data: state.data.bot_data,
    beta: state.data.beta,
    premium: state.data.premium,
    custom_variables: state.data.bot.commands.variables
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Select);
