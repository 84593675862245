import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setElements } from '../../../../actions';
import getLayoutedElements from "../getLayoutElements";
import Embed from '../../elements/Embed';
import TextArea from '../../elements/TextArea';
import Select from '../../elements/Select';
import RefreshBotData from '../../elements/RefreshBotData';
import { addEdge, getOutgoers } from 'react-flow-renderer';
import { EmojiAdder } from './EmojiAdder';
import splitElements from '../splitElements';
import RoleAdd from '../../elements/RoleAdd';

const responseTypes = [
    { value: "reply", label: "Reply to the command" },
    { value: "targeted", label: "Send the message to a specific text-channel" }
];


export class PermissionsConditionChild extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ""
        };
    }

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "condition",
                        choice_type: "role",
                        not: false,
                        option: "",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        // if(this.props.data == undefined){
        //     this.props.updateElementData(
        //         {                
        //             data:{
        //                 type:"button_response",
        //                 response_options:{
        //                     response:"",
        //                     target: { reply: true },
        //                     emojis:[]
        //                 },
        //                 validated:false
        //             },
        //             id:this.props.id
        //         }
        //     )
        // }else{
        //     // VALIDATE
        //     if((
        //         ('response' in this.props.data.response_options && this.props.data.response_options.response != "") ||
        //         ("embed" in this.props.data.response_options && "description" in this.props.data.response_options.embed &&  this.props.data.response_options.embed.description != "")) &&
        //         (this.props.data.response_options.target.reply != undefined || ("variable" in this.props.data.response_options.target && this.props.data.response_options.target.variable != "") || ('id' in this.props.data.response_options.target && this.props.data.response_options.target.id != "")
        //     )){
        //         if(this.props.data.validated == false){
        //             this.updateData("validated",true)
        //         }

        //     }else if(this.props.data.validated == true){
        //         this.updateData("validated",false)

        //     }
        // }
    }


    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };


    renderOptions = () => {
        var options = [
            // {value:"trigger",label:"The member who triggered the command"}
        ];
        this.props.options.forEach(option => {
            options.push({ value: option.name, label: option.name });
        });
        return options;
    };

    selectUpdate = (value) => {
        value = JSON.parse(value);
        var response_options = { ...this.props.data.response_options };
        response_options.target = value;
        this.updateData("response_options", response_options);
    };


    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    {this.props.data.type != "else" ?
                        <div className="mb-15">
                            <h4>Custom Variable Condition</h4>
                            <p>Run actions based on the value of a custom variable.</p>
                        </div> :
                        <div className="mb-15">
                            <h4>Else Conditon</h4>
                            <p>Run connected actions when the option equals no other condition value. </p>
                        </div>}



                    {this.props.data.type != "else" ?
                        <div className="mb-15">

                            <div className='mb-15 slash-action'>
                                <h4>Permissions <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/permission-condition#permissions" /></h4>
                                <span>The permissions to base this condition on.</span>


                                <div class="long-input mt-15">
                                    <RoleAdd type="permissions" update={(values) => {
                                        this.updateData("permissions", values);
                                    }} items={this.props.data.permissions} />

                                </div>
                            </div>

                            <div className='mb-15 slash-action'>
                                <h4>Condition Type <DocsLink location="https://docs.botghost.com/custom-commands-and-events/conditions/permission-condition#condition-type" /> </h4>
                                <span>The condition type. Whether to check for all or atleast one permission.</span>
                                <Select value={this.props.data.type} onChange={(value) => {
                                    this.updateData("type", value);
                                    // value = JSON.parse(value);
                                    // this.setState({value:value})
                                }} options={[
                                    { label: "Has atleast one of these permissions", value: "has_one" },
                                    { label: "Has all of these permissions", value: "has_all" },

                                ]} ></Select>

                            </div>


                        </div> : null}



                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
    updateElementData,
    setElements
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsConditionChild);
