import React, { Component } from 'react';
import { connect } from 'react-redux';

export class GiftItem extends Component {
    render() {
        return (
            <div onClick={() => {
                this.props.onClick();
            }} className='p-3 xl:!p-[30px] bg-[#2f3136] text-white rounded-sm mb-3 hover:cursor-pointer hover:opacity-75'>
                <div className='flex flex-col-reverse md:flex-row align-center'>
                    <div className='flex align-center'>
                        <img src={process.env.PUBLIC_URL + `/images/icons/${this.props.icon}.png`} className='h-[50px] mr-2'></img>
                        <div>
                            <h3 className='font-bold text-md xl:text-2xl'>{this.props.name}</h3>
                            <p className='opacity-75 text-sm xl:text-base'>{this.props.description}</p>
                        </div>
                    </div>

                    <div className='text-center text-red md:ml-auto'>
                        <p className='font-bold text-3xl'>{this.props.currency == "USD" ? "$" : "€"}{this.props.price}</p>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GiftItem);