import toast from "react-hot-toast";
import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";

export const successToast = (title, message, command) => {
	toast.custom((t) => (
		<div
			onClick={() => {
				console.log("ON CLICK");
				toast.remove(t.id);
			}}
		>
			{/* Global notification live region, render this permanently at the end of the document */}
			<div aria-live="assertive" className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
				<div className=" hover:cursor-pointer flex w-full flex-col items-center space-y-4 sm:items-end">
					{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
					<Transition show={true} as={Fragment} enter="transform ease-out duration-300 transition" enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enterTo="translate-y-0 opacity-100 sm:translate-x-0" leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-[#333] shadow-lg ring-1 ring-black ring-opacity-5">
							<div className="p-4">
								<div className="flex items-start">
									<div className="flex-shrink-0">
										<CheckCircleIcon className="h-6 w-6 text-green" aria-hidden="true" />
									</div>
									<div className="ml-3 w-0 flex-1 pt-0.5">
										<p className="text-md font-medium text-white">{title}</p>
										<p className="mt-1 text-sm text-white opacity-70">{message}</p>
										{command ? (
											<p className="mt-3 text-md text-white">
												Refresh your client by clicking <strong className="font-bold">CTRL + R</strong> in Discord for your changes to take effect.
											</p>
										) : null}
									</div>
									{/* <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => {
                                                toast.dismiss(t.id);
                                                //   
                                            }}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div> */}
								</div>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		</div>
	));
	// id && toast.dismiss(id);
	// toast.success(message, {
	//     duration: 5000,
	//     style: {
	//         borderRadius: "10px",
	//         background: "#333",
	//         color: "#fff"
	//     }
	// });
};

export const copyToast = (text) => {

	toast.success(text ? text : "Block copied", {
		duration: 1000,
		style: {
			borderRadius: "10px",
			background: "#333",
			color: "#fff"
		}
	});

};


export const errorToast = (message, id) => {
	console.log("ERROR TOAST");
	id && toast.dismiss(id);
	// Onclick dismiss?

	toast.error(message, {
		duration: 3000,
		style: {
			borderRadius: "10px",
			background: "#333",
			color: "#fff"
		}
	});
};

export const loadingToast = (message) => {
	return toast.loading(message || "Waiting...", {
		style: {
			borderRadius: "10px",
			background: "#333",
			color: "#fff"
		}
	});
};

export const copiedToast = (message) => {
	return toast.success(message || "Copied!", {
		duration: 3000,
		style: {
			borderRadius: "10px",
			background: "#333",
			color: "#fff",
			marginTop: "30px"
		}
	});
};
