import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setHighlightAction } from '../../../../actions';

import TextArea from '../../elements/TextArea';
import { EmojiAdder } from './EmojiAdder';
import Select from '../../elements/Select';
import { getIncomers, getOutgoers } from 'react-flow-renderer';
import splitElements from '../splitElements';
import { checkIntegrationChild } from '../eventUtils';
import { CUSTOM_EVENTS } from '../../../../variables';
import { renderResponseActionOptions } from '../builderUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { successToast, errorToast, loadingToast } from "../toast.js";

const responseTypes = [
    { value: "reply", label: "Reply to the command", disabled: true },
    { value: "trigger_channel", label: "Send the message to the channel the command was used in." }
];


export class PlainTextReply extends Component {

    componentDidMount() {

        if (this.props.data == undefined) {

            var target = {};
            if (this.props.mode == "event" && this.props.components['Channel']) {
                target = { trigger_channel: true };
            } else if (this.props.components['Interaction']) {
                target = { reply: true };
            }
            // console.log("DATA UNDEFINED");
            this.props.updateElementData(
                {
                    data: {
                        type: "plain_text",
                        response: "",
                        // variable: "",
                        emojis: [],
                        target: target,
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else if (this.props.data.target == undefined) {
            var target = {};
            if (this.props.mode == "event" && this.props.components['Channel']) {
                target = { trigger_channel: true };
            } else if (this.props.components['Interaction']) {
                target = { reply: true };
            }

            var target = { ...this.props.data.target };
            target = target;
            this.updateData("target", target);

        }

        // Highlight corrospodning action
        if (this.props.data && this.props.data.action_id && this.props.data.target && this.props.data.target.editId) {
            this.props.setHighlightAction(this.props.data.action_id);
        }


    }

    componentWillUnmount() {
        this.props.setHighlightAction(null);
    }


    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            // console.log("DATA UNDEFINED");
            this.props.updateElementData(
                {
                    data: {
                        type: "plain_text",
                        response: "",
                        emojis: [],
                        // variable: "",
                        target: { reply: true },
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else if (this.props.data.target == undefined) {
            var target = {};
            if (this.props.mode == "event" && this.props.components['Channel']) {
                target = { trigger_channel: true };
            } else if (this.props.components['Interaction']) {
                target = { reply: true };
            }

            var dataTarget = { ...this.props.data.target };
            dataTarget = target;
            this.updateData("target", dataTarget);

        } else {
            // VALIDATE

            if ((this.props.data.response != "") && this.props.data.validated != true) {
                this.updateData("validated", true);
            } else if (this.props.data.validated == true && (this.props.data.response == "")) {
                this.updateData("validated", false);

            }
        }
    }

    nameChange = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "-");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("name", value);
    };

    updateData = (key, value) => {

        // console.log("VALUE", value);
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    renderResponseTypes = () => {
        var channel = "channel the command was used in.";
        if (this.props.mode == "event" && this.props.components['Channel']) {
            channel = "channel.";
        }


        const responseTypes = [
            { value: "reply", label: "Reply to the command", disabled: this.props.components.Interaction ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
            { value: "trigger_channel", label: `Send the message to the ${channel}`, disabled: this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },

        ];

        responseTypes.push({ value: "editId", label: "Edit a message sent by another action" });

        if (this.props.mode == "event") {
            responseTypes.unshift({ value: "", label: "", disabled: true });
        }
        return responseTypes;
    };

    renderTextAreaDisabled = () => {
        var disabled = true;
        if (!this.props.data.target || (this.props.data.target.reply == true && (this.props.components.Interaction || checkIntegrationChild(this.props.elements, this.props.id)))) {
            // console.log("DISABLE 1");
            disabled = false;
        } else if (this.props.data.target.trigger_channel == true && (this.props.components.Channel || checkIntegrationChild(this.props.elements, this.props.id))) {
            // console.log("DISABLE 2");
            disabled = false;
        } else if (this.props.data.target.editId) {

            disabled = false;
        }
        // console.log("DISABLE 3", disabled, this.props.data.target, this.props.components['Interaction']);
        return disabled;
    };

    selectChange = (value) => {
        if (!value) return;
        this.props.setHighlightAction(value);
        var action = this.props.actions.find(action => action.id == value);

        if (!("editId" in action.data.data)) {
            var data = { ...action.data.data };
            data.editId = `${Date.now()}_${Math.floor(Math.random() * 100000)}`;
            // console.log("DATA", data);
            this.props.updateElementData(
                {
                    data: data,
                    id: value
                }
            );
            action.data.data.editId = data.editId;
        }
        var id = action.data.data.editId;

        var action_data = { ...this.props.data };
        action_data.target.editId = id;
        action_data.action_id = value;
        this.props.updateElementData(
            {
                data: action_data,
                id: this.props.id
            }
        );

    };

    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Plain Text Reply</h4>
                        <p>Bot responds with a simple plain text reply.</p>
                    </div>
                    <h4 style={{ marginBottom: "0px" }}>Content<DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/plain-text-reply#content"}></DocsLink></h4>
                    <div className="section-content-header mb-15">The message the bot should reply with.</div>
                    <TextArea disabled={this.renderTextAreaDisabled()} extra_classNames={`${this.props.data.response == "" && this.props.saveFailed ? "command-required" : ""}`} variableEditor={true} slash={true} slash_options={this.props.options} required={true} value={this.props.data.response} onChange={(value) => { this.updateData("response", value); }} />


                    {this.props.data.target ? <div>
                        <div className="mt-15 mb-15 slash-action">
                            <h4 style={{ marginBottom: "0px" }}>Response type <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/plain-text-reply#response-type"}></DocsLink> </h4>
                            <div className="section-content-header mb-15">Where your bot should send this message.</div>
                            <Select onChange={(value) => {
                                var target = { ...this.props.data.target };
                                if (value == "reply") {
                                    this.props.setHighlightAction(null);
                                    target = {
                                        reply: true
                                    };
                                } else if (value == "trigger_channel") {
                                    this.props.setHighlightAction(null);
                                    target = {
                                        trigger_channel: true
                                    };
                                } else if (value == "editId") {
                                    target = {
                                        editId: ""
                                    };
                                    if (this.props.data.action_id) {
                                        target.editId = this.props.data.action_id;
                                        this.props.setHighlightAction(this.props.data.action_id);
                                    }

                                }
                                this.updateData("target", target);

                            }
                            } value={"reply" in this.props.data.target ? "reply" : "trigger_channel" in this.props.data.target ? "trigger_channel" : 'editId' in this.props.data.target ? "editId" : ""} options={this.renderResponseTypes()} />
                        </div>
                    </div> : null}

                    {this.props.data.target && 'editId' in this.props.data.target ? <>
                        <div>
                            <h4 style={{ marginBottom: "0px" }}>Response Action<DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/plain-text-reply#edit-a-message-sent-by-another-action"}></DocsLink></h4>
                            <div className="section-content-header mb-15">Select one of the response actions in this command to edit the message sent by that action.</div>
                            <Select saveFailed={this.props.data.action_id == "" && this.props.saveFailed} value={this.props.data.action_id} options={renderResponseActionOptions(this.props.actions, this.props.selected)} onChange={this.selectChange} />
                        </div>

                        <div className='mt-15'>
                            <h4 style={{ marginBottom: "0px" }}>Keep Components</h4>
                            <div className="section-content-header mb-15">Keep the buttons or select menu options of the original message.</div>
                            <Select saveFailed={this.props.data.action_id == "" && this.props.saveFailed} value={this.props.data.target.keep_components == true ? true : false} options={[
                                {
                                    value: true,
                                    label: "Yes"
                                },
                                {
                                    value: false,
                                    label: "No"
                                }
                            ]} onChange={(val) => {
                                if (val == "true") {
                                    val = true;
                                } else {
                                    val = false;
                                }

                                var data = { ...this.props.data };
                                data.target.keep_components = val;
                                this.props.updateElementData(
                                    {
                                        data: data,
                                        id: this.props.id
                                    }
                                );
                            }} />
                        </div>
                    </> : null}
                    <div className='mt-15'>
                        <h4 style={{ marginBottom: "0px" }}>Reactions<DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/plain-text-reply#reactions"}></DocsLink></h4>
                        <div className="section-content-header mb-15">Reactions to add to the message.</div>
                        <EmojiAdder emojis={this.props.data.emojis || []} update={(newEmojis) => {
                            var data = { ...this.props.data };
                            data.emojis = newEmojis;
                            this.props.updateElementData(
                                {
                                    data: data,
                                    id: this.props.id
                                }
                            );
                        }} />

                    </div>

                    <div className='mt-15'>
                        <h4 style={{ marginBottom: "0px" }}>Optional Variable</h4>
                        <div className="section-content-header mb-15">An optional variable to return the message id of this reply. You can use this variable in other blocks.</div>
                        <div class="long-input mb-15">
                            <label>Variable</label>
                            <input className={`${this.props.data.variable == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                // if (!isNaN(e.target.value) && e.target.value <= 850) {
                                var value = e.target.value;
                                value = value.toLowerCase();
                                value = value.substring(0, 32);
                                value = value.replace(" ", "_");
                                const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
                                value = value.replace(regexForNonAlphaNum, "");

                                this.updateData("variable", value);
                            }} type="text" value={this.props.data.variable ? this.props.data.variable : ""} />
                        </div>

                        {
                            this.props.data.variable ? <div>
                                <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>



                                <h4>Message Id Variable</h4>
                                <p className="mb-15" style={{
                                    color: "#fff",
                                    opacity: "0.7",
                                    fontWeight: 500
                                }}>You can use the variable <span style={{ color: "#f45142", opacity: "1 !important" }}>{`{${this.props.data.variable}}`}</span><FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.variable}}`); successToast("Copied variable to clipboard"); }} /> in other blocks.</p>

                            </div> : null
                        }
                    </div>
                </div>


            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    components: state.builder.components,
    mode: state.builder.mode,
    beta: state.data.beta
});
const mapDispatchToProps = {
    updateElementData,
    setHighlightAction
};

export default connect(mapStateToProps, mapDispatchToProps)(PlainTextReply);
