import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";
import Select from "../elements/Select";
import AutoModModule from "../elements/AutoModModule";

import RefreshBotData from "../elements/RefreshBotData";

import Dashboard from "../Dashboard";

import { setBotModule } from "../../../actions";
import { ModuleDisabled } from "../elements/ModuleDisabled";

export class Automod extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ignore_bots: false,
      ignore_admins: false,
      dm_reason: false,
      log_actions: false,
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  toggleChange = (value, type) => {
    var automod = { ...this.props.automod };
    automod.automod.options[type] = value;
    this.props.setBotModule({
      module: "automod",
      module_data: automod,
    });
  };

  loggingChange = (value) => {
    var automod = { ...this.props.automod };
    automod.automod.logging.enabled = value;
    this.props.setBotModule({
      module: "automod",
      module_data: automod,
    });
  };

  autoModUpdate = (state, type) => {
    var automod = { ...this.props.automod };
    if (type == "blacklist") {
      automod.automod.options.whitelist.words = state.whitelistArray;
    }

    automod.automod.options[type] = state.value;
    this.props.setBotModule({
      module: "automod",
      module_data: automod,
    });
  };

  loggingChanelChange = (value) => {
    const channel = JSON.parse(value);
    const automod = { ...this.props.automod };

    automod.automod.logging.channel = channel;
    this.props.setBotModule({
      module: "automod",
      module_data: automod,
    });
  };

  commandUpdate = (value, type) => {
    // console.log("Updated", value, type);
    const automod = { ...this.props.automod };
    automod[type] = value;
    this.props.setBotModule({
      module: "automod",
      module_data: automod,
    });
  };

  enableModule = (value) => {
    const automod = { ...this.props.automod };

    automod.automod.enabled = value;
    this.props.setBotModule({
      module: "automod",
      module_data: automod,
    });
  };

  render() {
    return (
      <Dashboard>
        <ModuleHeader
          tutorial="https://docs.botghost.com/server-management/automod"
          title="Auto Moderator"
          docs="https://docs.botghost.com/modules/auto-moderator"
          description="Automatically moderate your servers text channels. No humans required"
          icon="automoderator.png"
          enabledValue={this.props.automod.automod.enabled}
          change={this.enableModule}
        ></ModuleHeader>
        <ModuleDisabled disabled={!this.props.automod.automod.enabled}>
          <section>
            <SectionHeader
              title="Module Settings"
              pretitle="Automoderator"
              docs={true}
              docs_link={"https://docs.botghost.com/modules/auto-moderator"}
            ></SectionHeader>

            <div className="row">
              <AutoModModule
                docs_link="https://docs.botghost.com/modules/auto-moderator#bad-words"
                empty={this.props.automod.automod.options.empty}
                value={this.props.automod.automod.options.blacklist}
                update={this.autoModUpdate}
                whitelistArray={
                  this.props.automod.automod.options.whitelist.words
                }
                type="blacklist"
                name="Bad Words"
              ></AutoModModule>
              <AutoModModule
                docs_link="https://docs.botghost.com/modules/auto-moderator#links"
                value={this.props.automod.automod.options.links}
                update={this.autoModUpdate}
                type="links"
                name="Links"
              ></AutoModModule>
              <AutoModModule
                docs_link="https://docs.botghost.com/modules/auto-moderator#mass-caps"
                value={this.props.automod.automod.options.caps}
                update={this.autoModUpdate}
                type="caps"
                name="Mass Caps"
              ></AutoModModule>
              <AutoModModule
                docs_link="https://docs.botghost.com/modules/auto-moderator#mass-emoji"
                value={this.props.automod.automod.options.emoji}
                update={this.autoModUpdate}
                type="emoji"
                name="Mass Emoji"
              ></AutoModModule>
              <AutoModModule
                docs_link="https://docs.botghost.com/modules/auto-moderator#server-invites"
                value={this.props.automod.automod.options.server_invites}
                update={this.autoModUpdate}
                type="server_invites"
                name="Server Invites"
              ></AutoModModule>
              <AutoModModule
                docs_link="https://docs.botghost.com/modules/auto-moderator#mass-mentions"
                value={this.props.automod.automod.options.mentions}
                update={this.autoModUpdate}
                type="mentions"
                name="Mass Mentions"
              ></AutoModModule>
            </div>
          </section>

          <section>
            <SectionHeader
              title="Basic Options"
              pretitle="Automoderator"
              docs={true}
              docs_link="https://docs.botghost.com/modules/auto-moderator#generic-auto-moderator-options"
            ></SectionHeader>

            <div className="section-content">
              <div>
                <h3>Ignore Bots</h3>
                <div className="section-content-header">
                  The Auto-moderator will take no action against other bots.
                </div>
              </div>

              <div style={{ marginLeft: "auto" }}>
                <Toggle
                  value={this.props.automod.automod.options.ignore_bots}
                  update={this.toggleChange}
                  type="ignore_bots"
                ></Toggle>
              </div>
            </div>

            <div className="section-content">
              <div>
                <h3>Ignore Admins</h3>
                <div className="section-content-header">
                  The Auto-moderator will take no action against members with
                  the ADMINISTRATOR permission.
                </div>
              </div>

              <div style={{ marginLeft: "auto" }}>
                <Toggle
                  value={this.props.automod.automod.options.ignore_admins}
                  update={this.toggleChange}
                  type="ignore_admins"
                ></Toggle>
              </div>
            </div>

            <div className="section-content">
              <div>
                <h3>DM Reason</h3>
                <div className="section-content-header">
                  Direct message the reason to the member
                </div>
              </div>

              <div style={{ marginLeft: "auto" }}>
                <Toggle
                  value={this.props.automod.automod.options.dm}
                  update={this.toggleChange}
                  type="dm"
                ></Toggle>
              </div>
            </div>

            <div className="section-content">
              <div>
                <h3>Log all Auto-Moderator Actions</h3>
                <div className="section-content-header">
                  Record all actions the auto-moderator takes in a text channel
                </div>
              </div>

              <div style={{ marginLeft: "auto" }}>
                <Toggle
                  value={this.props.automod.automod.logging.enabled}
                  update={this.loggingChange}
                  type="log_actions"
                ></Toggle>
              </div>
            </div>

            <div className="section-content-normal">
              <div>
                <h3 className="mb-0">
                  Auto-moderator logging channel <RefreshBotData />
                </h3>
                <div className="section-content-header">
                  The name of the text channel that the auto-moderator should
                  log to
                </div>
              </div>

              <div className="mt-15">
                <Select
                  type="channel"
                  value={JSON.stringify(
                    this.props.automod.automod.logging.channel
                  )}
                  onChange={this.loggingChanelChange}
                ></Select>
              </div>
            </div>
          </section>

          <section>
            <SectionHeader
              title="Commands"
              pretitle="Automoderator"
              docs={true}
              docs_link={
                "https://docs.botghost.com/modules/auto-moderator#commands"
              }
            ></SectionHeader>

            <div className="row">
              <div className="col-xl-6">
                <Command
                  name="white-list"
                  description="Adds a word to the servers whitelist."
                  showToggle={false}
                  showPrefix={false}
                  slashOnly={true}
                  update={this.commandUpdate}
                  options={this.props.automod["white-list"]}
                  module="automod"
                  // slashOnly={this.props.beta || false}
                  toggleValue={this.props.automod["white-list"].enabled != false ? true : false}
                ></Command>
              </div>

              <div className="col-xl-6">
                <Command
                  name="black-list"
                  description="Adds a word to the servers blacklist."
                  showToggle={false}
                  showPrefix={false}
                  slashOnly={true}
                  update={this.commandUpdate}
                  options={this.props.automod["black-list"]}
                  module="automod"
                  // slashOnly={this.props.beta || false}
                  toggleValue={this.props.automod["black-list"].enabled != false ? true : false}
                ></Command>
              </div>
            </div>
          </section>
        </ModuleDisabled>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  automod: state.data.bot.commands.automod,
  beta: state.data.beta,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Automod);
