import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { faDownload, faExclamationTriangle, faEye } from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';
import buildExistingCommand from '../commandbuilder/buildExistingCommand';
import { setElements, setBuilderMarketID, setBotModule, setSelected } from "../../../actions";
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import server from '../../../api/server';
import { layoutElements } from '../commandbuilder/eventUtils';

const formatNumber = (value) => {
    var newValue = value;
    if (value >= 1000) {
        var suffixes = ["", "k", "m", "b", "t"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat((suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
            if (dotLessShortValue.length <= 2) {
                break;
            }
        }
        if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
};



export class MarketItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openModal: false
        };
    }

    handleImport = async () => {

        // console.log("Market ID", this.props.item.market_id);
        var blocks = [];
        if (this.props.mode == "command") {
            blocks = await buildExistingCommand(this.props.item.command, 'command', false);
            if ("errors" in this.props.item.command) {
                // console.log(this.props.item.command.errors, 'ERRORS');
                var errorElements = await buildExistingCommand({
                    options: [],
                    actions: this.props.item.command.errors
                }, 'command', true);
                blocks = blocks.concat(errorElements);
            } else {
                var errorElements = await buildExistingCommand({
                    options: [],
                    actions: [{

                        nodeID: "action_3_6d1a_9853",
                        embed: {
                            color: "#FF0000",
                            description: "{error_reason}",
                            title: ":x: {error_message}",
                            footer: "{error_block}"
                        },
                        emojis: [],
                        target: { reply: true },
                        type: "embed"
                    }
                    ]
                }, 'command', true);
                blocks = blocks.concat(errorElements);
            }


        } else {
            blocks = await buildExistingCommand(this.props.item.command, 'event', false);
            if ("errors" in this.props.item.command) {
                var errorElements = await buildExistingCommand({
                    options: [],
                    actions: this.props.item.command.errors
                }, 'event', true);
                blocks = blocks.concat(errorElements);
            } else {
                blocks.push({
                    id: "error_handler",
                    type: "error",
                    position: {
                        x: 850,
                        y: 0
                    },
                    draggable: false,
                    data: {
                        node_options: {
                            title: "Error Handler",
                            description: "Handle errors that occur during the event execution",
                            icon: faExclamationTriangle
                        },
                        data: {}
                    }
                });
            }

        }

        var custom_variables = this.props.item.customVariables;
        if (custom_variables && custom_variables.length > 0) {
            var existing_variables = [...this.props.bot.commands.variables.variables];
            // console.log("existing_variables BEFORE", existing_variables);
            custom_variables.forEach((variable) => {
                var variable_settings = existing_variables.find((v) => v.reference == variable.reference);
                if (!variable_settings && variable) {
                    existing_variables.push(variable);
                }
            });
            var variablesObject = { ...this.props.bot.commands.variables };
            variablesObject.variables = existing_variables;
            this.props.setBotModule({
                module: "variables",
                module_data: variablesObject
            });

        }
        // console.log(blocks);
        this.props.setElements(blocks);
        blocks = [...this.props.elements];
        var layout = layoutElements(blocks);
        this.props.setElements(layout);
        this.props.setSelected(null);
        this.props.setBuilderMarketID(this.props.item.market_id);

    };

    render() {
        return (
            <>
                <Transition.Root show={this.state.openModal} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={() => {
                        this.setState({ openModal: false });
                    }}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-[#0000008a] transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-lightGray px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red sm:mx-0 sm:h-10 sm:w-10">
                                                <ExclamationTriangleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </div>
                                            <div className="text-left ml-2">
                                                <Dialog.Title as="h3" className="text-xl text-white font-bold leading-6">
                                                    Import {this.props.mode == "event" ? "Event" : "Command"}
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-muted mb-3 font-semibold">
                                                        Are you sure you wish to import this {this.props.mode}? This will overwrite your current {this.props.mode}. Any custom variables associated with this {this.props.mode} will also be imported.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">

                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={() => {
                                                    this.handleImport();
                                                    this.setState({ openModal: false });
                                                }}
                                            >
                                                Import
                                            </button>
                                            <button
                                                type="button"
                                                className=" inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                                onClick={() => this.setState({ openModal: false })}
                                            >
                                                Cancel
                                            </button>

                                            <a
                                                type="button"
                                                target="_blank"
                                                href={`https://botghost.com/market/${this.props.mode}/${this.props.item.market_id}`}
                                                className="mr-auto inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm hover:no-underline hover:opacity-80"

                                            >
                                                View {this.props.mode == "event" ? "Event" : "Command"} Page
                                            </a>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
                <div className='p-2 hover:cursor-pointer hover:bg-darkGray' onClick={() => {
                    // this.handleImport();
                    this.setState({ openModal: true });
                }}>
                    <div className='flex relative px-2'>
                        <img
                            className="w-[40px] h-[40px] rounded-full mr-2"
                            src={this.props.item.userInfo.img != null ? `https://cdn.discordapp.com/avatars/${this.props.item.userInfo.id}/${this.props.item.userInfo.img}.webp` : process.env.PUBLIC_URL + "/img/icons/discord.jpg"}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = process.env.PUBLIC_URL + "/images/discord.jpg";
                            }}
                            alt="User Avatar"
                        ></img>

                        <div className='text-white ml-1'>
                            <p className='font-bold'>{this.props.item.marketInfo.name}</p>
                            <p className='text-sm text-muted mt-0 leading-none'>By {this.props.item.userInfo.name}</p>
                            <p className="text-sm mt-1 text-grayText">{this.props.item.marketInfo.tagline}</p>
                        </div>

                        <div className="absolute top-0 right-0 text-white text-sm">
                            {/* <FontAwesomeIcon icon={faDownload} /> */}
                            <FontAwesomeIcon icon={faDownload} /> {formatNumber(this.props.item.imports)}
                        </div>
                    </div>
                </div>
                <div className="w-full border-t border-muted opacity-20" />
            </>

        );
    }
}

const mapStateToProps = (state) => ({
    mode: state.builder.mode,
    bot: state.data.bot,
    elements: state.builder.elements
});

const mapDispatchToProps = {
    setElements,
    setBuilderMarketID,
    setBotModule,
    setSelected
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketItem);