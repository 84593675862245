import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';
import VariableTextInput from '../VariableTextInput';

import Embed from '../../elements/Embed';
import Select from '../../elements/Select';
import { faChevronDown, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { copyToast } from '../toast';
import RoleAdd from '../../elements/RoleAdd';

const STYLES = [
    { value: "PRIMARY", label: "Blue" },
    { value: "SECONDARY", label: "Gray" },
    { value: "SUCCESS", label: "Green" },
    { value: "DANGER", label: "Red" },
    { value: "LINK", label: "Link" },
];



export class AdvSelectMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openOption: null
        };
    }

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "adv_select_menu",
                        menu_type: "basic",
                        multiselect: false,
                        minOptions: "1",
                        maxOptions: "1",
                        basic_options: [],
                        type_options: {},
                        options: [],

                        validated: false,
                        customVariableName: "",
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "adv_select_menu",
                        menu_type: "basic",
                        multiselect: false,
                        minOptions: "1",
                        maxOptions: "1",

                        type_options: {},
                        options: [],
                        basic_options: [],

                        validated: false,
                        customVariableName: "",
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE
            let isValid = true;
            const data = this.props.data;

            // // Common validation for all menu types
            // if (!data.placeholder) {
            //     isValid = false;
            // }

            // Specific validation for each menu type
            switch (data.menu_type) {
                case "basic":
                    if (!data.basic_options || data.basic_options.length === 0) {
                        isValid = false;
                    } else {
                        // Check if all required fields are filled for each option
                        isValid = data.basic_options.every(option => option.label);
                    }
                    break;
                case "channel":
                    // Validate channel-specific fields

                    break;
                case "user":
                    // No specific validation for user type
                    break;
                case "role":
                    // No specific validation for role type
                    break;
                case "mentionable":
                    // No specific validation for mentionable type
                    break;
                default:
                    isValid = false;
            }

            // Validate multiselect options if enabled
            if (data.multiselect) {
                if (!data.minOptions || data.minOptions.trim() === '' ||
                    !data.maxOptions || data.maxOptions.trim() === '') {
                    isValid = false;
                }
            }



            // Update validation status
            if (isValid !== data.validated) {
                this.updateData("validated", isValid);
            }
        }
    }

    nameChange = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "-");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("name", value);
    };

    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };


    // updateType = (type) => {
    //         var buttons = [...this.props.data.buttons];
    //         buttons[index].style = type;
    //         this.props.update("buttons", buttons)
    //     }

    renderStringOptions = () => {
        var options = [];

        // Text inputs for Label, Description, value, and Optional Emoji Id;
        // Collapsible accordion for each option
        // Users can click an option to expand its options;'


        for (var i = 0; i < this.props.data.options.length; i++) {
            var option = this.props.data.options[i];
            if (this.state.openOption == i) {

            } else {
                options.push(
                    <div className="mb-15">
                        <div className="d-flex justify-content-between">
                            <h4 className="mb-2">Option {i + 1}</h4>
                            <button onClick={() => {
                                if (this.state.openOption == i) {
                                    this.setState({ openOption: null });
                                } else {
                                    this.setState({ openOption: i });
                                }
                            }} className="btn btn-red">Edit</button>
                        </div>
                    </div>


                );
            }
        }


        return options;
    };


    renderChannelTypes = () => {
        if (this.props.data.menu_type == 'basic') {
            const optionsCount = this.props.data.basic_options ? this.props.data.basic_options.length : 0;
            return (
                <>
                    <div className="mb-15">
                        <h4>Options ({optionsCount}/25)</h4>
                        <p>Add options for users to select from the menu. (Max 25 options)</p>
                    </div>
                    {this.props.data.basic_options && this.props.data.basic_options.map((option, index) => (
                        <div key={index} className="mb-15 option-container">
                            <details className="option-details bg-darkGray">
                                <summary className="option-summary flex items-center justify-between p-3 bg-gray-800 rounded-md cursor-pointer">
                                    <span className="font-medium text-white">Option {index + 1}: {option.label ? option.label : `Option ${index + 1}`}</span>
                                    <FontAwesomeIcon icon={faChevronDown} className="text-white" />
                                </summary>
                                <div className="option-content mt-3 p-4 bg-gray-700 rounded-md">
                                    <div className="mb-4">
                                        <h4 className="text-white mb-2">Label</h4>
                                        <div className="section-content-header mb-2">The text displayed for this option in the menu.</div>

                                        <VariableTextInput
                                            label="Label"
                                            value={option.label}
                                            onChange={(value) => {
                                                const newOptions = [...this.props.data.basic_options];
                                                newOptions[index].label = value;
                                                this.updateData("basic_options", newOptions);
                                            }}
                                            placeholder="Option label"
                                            required={true}
                                            slash_options={this.props.slash_options}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <h4 className="text-white mb-2">Description</h4>
                                        <div className="section-content-header mb-2">Additional information about this option.</div>
                                        <VariableTextInput
                                            label="Description"
                                            value={option.description}
                                            onChange={(value) => {
                                                const newOptions = [...this.props.data.basic_options];
                                                newOptions[index].description = value;
                                                this.updateData("basic_options", newOptions);
                                            }}
                                            placeholder="Option description"
                                            required={false}
                                            slash_options={this.props.slash_options}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <h4 className="text-white mb-2">Value (Optional)</h4>
                                        <div className="section-content-header mb-2">
                                            The value associated with this option. If left empty, the option's label will be used as its value.
                                        </div>
                                        <VariableTextInput
                                            label="Value"
                                            value={option.value || ""}
                                            onChange={(value) => {
                                                const newOptions = [...this.props.data.basic_options];
                                                newOptions[index].value = value;
                                                this.updateData("basic_options", newOptions);
                                            }}
                                            placeholder="Option value"
                                            required={false}
                                            slash_options={this.props.slash_options}
                                        />
                                        {!option.value && (
                                            <p className="text-sm text-gray-400 mt-1">
                                                Note: If left empty, the option's label will be used as its value.
                                            </p>
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <h4 className="text-white mb-2">Emoji ID</h4>
                                        <div className="section-content-header mb-2">Optional emoji to display next to the option.</div>
                                        <VariableTextInput
                                            label="Emoji ID (Optional)"
                                            value={option.emoji_id || ""}
                                            onChange={(value) => {
                                                const newOptions = [...this.props.data.basic_options];
                                                newOptions[index].emoji_id = value;
                                                this.updateData("basic_options", newOptions);
                                            }}
                                            placeholder="Emoji ID"
                                            required={false}
                                            slash_options={this.props.slash_options}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <h4 className="text-white mb-2">Hide Option</h4>
                                        <div className="section-content-header mb-2">
                                            When set to true, this option will be hidden and not added to the menu.
                                        </div>
                                        <VariableTextInput
                                            label="Hide Option"
                                            value={option.hidden || ""}
                                            onChange={(value) => {
                                                const newOptions = [...this.props.data.basic_options];
                                                newOptions[index].hidden = value;
                                                this.updateData("basic_options", newOptions);
                                            }}
                                            placeholder="true or false"
                                            required={false}
                                            slash_options={this.props.slash_options}
                                        />
                                        <p className="text-sm text-gray-400 mt-1">
                                            Enter 'true' to hide this option, or 'false' to show it.
                                        </p>
                                    </div>
                                    <button
                                        onClick={() => {
                                            const newOptions = this.props.data.basic_options.filter((_, i) => i !== index);
                                            this.updateData("basic_options", newOptions);
                                        }}
                                        className="bg-red hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                                    >
                                        Remove Option
                                    </button>
                                </div>
                            </details>
                        </div>
                    ))}
                    {optionsCount < 25 && (
                        <button
                            className='btn btn-red'
                            onClick={() => {
                                const optionNumber = (this.props.data.basic_options || []).length + 1;
                                const newOptions = [...(this.props.data.basic_options || []), {
                                    label: `Option ${optionNumber}`,
                                    description: `Option ${optionNumber}`,
                                    emoji_id: "",
                                    value: ``
                                }];
                                this.updateData("basic_options", newOptions);
                            }}
                        >
                            Add Option
                        </button>
                    )}
                    {(this.props.data.basic_options || []).length === 0 && (
                        <div className="error-message mt-3 mb-3" style={{ color: 'red', fontWeight: 'bold' }}>
                            Error: At least one option must be added to the select menu.
                        </div>
                    )}
                    {/* Add horizontal rule here */}
                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd", marginTop: "20px", marginBottom: "20px" }}></hr>
                </>

            );
        } else if (this.props.data.menu_type == 'channel') {
            return (
                <>
                    <div>
                        <h4 className="mb-2">Default Selected Channels</h4>
                        <p>Select the channels that will be pre-selected in the menu.</p>
                        <RoleAdd
                            items={this.props.data.default_channels || []}
                            update={(channels) => {
                                this.updateData("default_channels", channels);
                            }}
                            bot_data={this.props.bot_data}
                            type="channels"
                            slash_options={this.props.slash_options}
                        />
                    </div>
                    <div className="mb-15 mt-3">
                        <h4 style={{ marginBottom: "0px" }}>Optional Additional Channels</h4>
                        <div className="section-content-header mb-15">Add additional channel IDs or variables that resolve to channel IDs. Separate each with commas.</div>
                        <VariableTextInput
                            label="Channels"
                            value={this.props.data.additional_channels || ""}
                            onChange={(value) => {
                                this.updateData("additional_channels", value);
                            }}
                            required={false}
                            placeholder="Enter channel IDs or variables, separated by commas"
                            slash_options={this.props.slash_options}
                        />
                    </div>

                    <div className="mb-15 mt-3">
                        <h4 className="mb-2">Optional Channel Types</h4>
                        <p>Select the types of channels that will be available in the menu.</p>
                        <RoleAdd
                            items={this.props.data.channel_types || []}
                            update={(channelTypes) => {
                                this.updateData("channel_types", channelTypes);
                            }}
                            bot_data={this.props.bot_data}
                            type="channelTypes"
                            slash_options={this.props.slash_options}
                        />
                    </div>

                    <div className="mb-15 mt-3">
                        <h4 style={{ marginBottom: "0px" }}>Optional Additional Channel Types</h4>
                        <div className="section-content-header block mb-15">
                            Add additional channel type integers or variables here. Separate each with commas.
                            For a list of channel type integers, see the <a className='text-red font-bold' href="https://discord.com/developers/docs/resources/channel#channel-object-channel-types" target="_blank" rel="noopener noreferrer">Discord API documentation</a>.
                        </div>
                        <VariableTextInput
                            label="Channel Types"
                            required={false}
                            value={this.props.data.additional_channel_types || ""}
                            onChange={(value) => {
                                this.updateData("additional_channel_types", value);
                            }}
                            placeholder="Enter additional channel type integers or variables"
                            slash_options={this.props.slash_options}
                        />
                    </div>
                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>
                </>
            );
        } else if (this.props.data.menu_type == 'user') {
            return (
                <>

                    <div className="mb-15 mt-3">
                        <h4 style={{ marginBottom: "0px" }}>Optional Default Selected Users</h4>
                        <p>Enter user IDs or variables that resolve to user IDs. Separate each with a comma.</p>
                        <VariableTextInput
                            label="Users"
                            required={false}
                            value={this.props.data.additional_users || ""}
                            onChange={(value) => {
                                this.updateData("additional_users", value);
                            }}
                            placeholder="Enter user IDs or variables, separated by commas"
                            slash_options={this.props.slash_options}
                        />
                    </div>
                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>
                </>
            );
        } else if (this.props.data.menu_type == 'role') {
            return (
                <>
                    <div>
                        <h4 className="mb-2">Optional Default Selected Roles</h4>
                        <p>Select the roles that will be pre-selected in the menu.</p>
                        <RoleAdd
                            items={this.props.data.default_roles || []}
                            update={(roles) => {
                                this.updateData("default_roles", roles);
                            }}
                            bot_data={this.props.bot_data}
                            type="roles"
                            slash_options={this.props.slash_options}
                        />
                    </div>
                    <div className="mb-15 mt-3">
                        <h4 style={{ marginBottom: "0px" }}>Optional Additional Roles</h4>
                        <div className="section-content-header mb-15">Add additional role IDs or variables that resolve to role IDs. Separate each with commas.</div>
                        <VariableTextInput
                            label="Roles"
                            required={false}
                            value={this.props.data.additional_roles || ""}
                            onChange={(value) => {
                                this.updateData("additional_roles", value);
                            }}
                            placeholder="Enter role IDs or variables, separated by commas"
                            slash_options={this.props.slash_options}
                        />
                    </div>
                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>
                </>
            );
        }
        else if (this.props.data.menu_type == 'mentionable') {
            return (
                <>
                    <div>
                        <h4 className="mb-2">Optional Default Selected Roles</h4>
                        <p>Select the roles that will be pre-selected in the menu.</p>
                        <RoleAdd
                            items={this.props.data.default_roles || []}
                            update={(roles) => {
                                this.updateData("default_roles", roles);
                            }}
                            bot_data={this.props.bot_data}
                            type="roles"
                            slash_options={this.props.slash_options}
                        />
                    </div>
                    <div className="mb-15 mt-3">
                        <h4 style={{ marginBottom: "0px" }}>Additional Roles</h4>
                        <div className="section-content-header mb-15">Add additional role IDs or variables that resolve to role IDs. Separate each with commas.</div>
                        <VariableTextInput
                            label="Roles"
                            required={false}
                            value={this.props.data.additional_roles || ""}
                            onChange={(value) => {
                                this.updateData("additional_roles", value);
                            }}
                            placeholder="Enter role IDs or variables, separated by commas"
                            slash_options={this.props.slash_options}
                        />
                    </div>
                    <div className="mb-15 mt-3">
                        <h4 style={{ marginBottom: "0px" }}>Optional Default Selected Users</h4>
                        <p>Enter user IDs or variables that resolve to user IDs. Separate each with a comma.</p>
                        <VariableTextInput
                            label="Users"
                            required={false}
                            value={this.props.data.default_users || ""}
                            onChange={(value) => {
                                this.updateData("default_users", value);
                            }}
                            placeholder="Enter user IDs or variables, separated by commas"
                            slash_options={this.props.slash_options}
                        />
                    </div>

                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>
                </>
            );
        }
    };

    renderComponentOrdering = () => {
        if (this.props.data.orderComponent) {
            return (
                <>
                    <div>
                        <div>
                            <h4 className="mb-2">Row</h4>
                            <p>Which row to place this select menu on. </p>
                        </div>
                        <VariableTextInput
                            label={"Row"}
                            placeholder="1"
                            slash_options={this.props.slash_options}
                            required={false}
                            value={this.props.data.row || ""}
                            onChange={(value) => {
                                this.updateData("row", value);
                            }}
                        />
                    </div>

                    <div className="mt-3">
                        <div>
                            <h4 className="mb-2">Position Weight</h4>
                            <p>Which position to place this select menu on the row. Components with a higher weight will be placed first. </p>
                        </div>
                        <VariableTextInput
                            label={"Weight"}
                            placeholder="1"
                            slash_options={this.props.slash_options}
                            value={this.props.data.weight || ""}
                            required={false}
                            onChange={(value) => {
                                this.updateData("weight", value);
                            }}
                        />
                        <p className="text-sm text-gray-500 mt-1">Note: A weight of -1 will cause the menu to be ignored.</p>
                    </div>
                </>
            );
        }
    };

    renderMultiselectOptions = () => {
        // Inputs for Multiselect

        if (this.props.data.multiselect) {
            return (
                <>
                    <div>
                        <div>
                            <h4 className="mb-2">Minimum Options</h4>
                            <p>The minimum amount of options the user must select </p>
                        </div>
                        <VariableTextInput
                            label={"Min"}
                            placeholder="1"
                            slash_options={this.props.options}
                            value={this.props.data.minOptions}
                            onChange={(value) => {
                                this.updateData("minOptions", value);
                            }}
                        />
                    </div>

                    <div className="mt-3">
                        <div>
                            <h4 className="mb-2">Maximum Options</h4>
                            <p>The maximum amount of options the user can select </p>
                        </div>
                        <VariableTextInput
                            label={"Max"}
                            placeholder="1"
                            slash_options={this.props.options}
                            value={this.props.data.maxOptions}
                            onChange={(value) => {
                                this.updateData("maxOptions", value);
                            }}
                        />
                    </div>
                </>
            );
        }

        else {
            return;
        }
    };

    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Select Menu</h4>
                        <p>Run attached Actions when a user selects one or more options from this menu.</p>
                    </div>

                    {/* Accessing Selected Options */}
                    <h4>Accessing Selected Options <DocsLink location="https://docs.botghost.com/custom-commands/actions/send-a-message-with-select-menu#using-responses" /></h4>
                    <p className="mb-15" style={{
                        color: "#fff",
                        opacity: "0.7",
                        fontWeight: 500
                    }}>You can use the following variable(s) to access the selected option(s) in your actions:</p>

                    {this.props.data.customVariableName ? (
                        <>
                            <p style={{
                                color: "#fff",
                                opacity: "0.7",
                                fontWeight: 500
                            }}>
                                <span style={{ color: "#f45142" }}>{`{${this.props.data.customVariableName}}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.customVariableName}}`); copyToast("Copied Variable"); }} /> - Returns the {this.props.data.multiselect ? "array of selected options" : "selected option"}.
                            </p>
                            <p style={{
                                color: "#fff",
                                opacity: "0.7",
                                fontWeight: 500
                            }}>
                                <span style={{ color: "#f45142" }}>{this.props.data.multiselect ? `{selected_options}` : `{selected_option}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(this.props.data.multiselect ? `{selected_options}` : `{selected_option}`); copyToast("Copied Variable"); }} /> - Also returns the {this.props.data.multiselect ? "array of selected options" : "selected option"}.
                            </p>

                            {this.props.data.multiselect && (
                                <>
                                    <p style={{
                                        color: "#fff",
                                        opacity: "0.7",
                                        fontWeight: 500
                                    }}>
                                        <span style={{ color: "#f45142" }}>{`{${this.props.data.customVariableName}.length}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.customVariableName}.length}`); copyToast("Copied Variable"); }} /> - Returns the number of selected options.
                                    </p>

                                    <p style={{
                                        color: "#fff",
                                        opacity: "0.7",
                                        fontWeight: 500
                                    }}>
                                        <span style={{ color: "#f45142" }}>{`{selected_options.length}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{selected_options.length}`); copyToast("Copied Variable"); }} /> - Returns the number of selected options.
                                    </p>
                                </>
                            )}

                        </>
                    ) : (
                        this.props.data.multiselect ? (
                            <>
                                <p style={{
                                    color: "#fff",
                                    opacity: "0.7",
                                    fontWeight: 500
                                }}>
                                    <span style={{ color: "#f45142" }}>{`{selected_options}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{selected_options}`); copyToast("Copied Variable"); }} /> - Returns an array of selected options.
                                </p>
                                <p style={{
                                    color: "#fff",
                                    opacity: "0.7",
                                    fontWeight: 500
                                }}>
                                    <span style={{ color: "#f45142" }}>{`{selected_options.length}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{selected_options.length}`); copyToast("Copied Variable"); }} /> - Returns the number of selected options.
                                </p>
                            </>
                        ) : (
                            <p style={{
                                color: "#fff",
                                opacity: "0.7",
                                fontWeight: 500
                            }}>
                                <span style={{ color: "#f45142" }}>{`{selected_option}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{selected_option}`); copyToast("Copied Variable"); }} /> - Returns the selected option.
                            </p>
                        )
                    )}

                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>

                    {/* Placeholder */}
                    <div className="mb-15">
                        <h4 style={{ marginBottom: "0px" }}>Placeholder</h4>
                        <div className="section-content-header mb-15">The placeholder text displayed when no option is selected.</div>
                        <VariableTextInput
                            label="Placeholder"
                            value={this.props.data.placeholder || ""}
                            onChange={(value) => {
                                this.updateData("placeholder", value);
                            }}
                            required={false}
                            placeholder="Select an option..."
                            slash_options={this.props.slash_options}
                        />
                    </div>

                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>

                    {/* Select Type */}
                    <div className="mb-15">
                        <h4 style={{ marginBottom: "0px" }}>Menu Type </h4>
                        <div className="section-content-header mb-15">The label of the menu option. All options and variables can be used.</div>
                        {/* Basic, Role, Channel, User */}
                        <select onChange={(e) => {
                            var value = e.target.value;
                            this.updateData("menu_type", value);
                        }} value={this.props.data.menu_type ? this.props.data.menu_type : "basic"}>
                            <option value="basic">Basic (Text)</option>
                            <option value="role">Role</option>
                            <option value="channel">Channel</option>
                            <option value="user">User</option>
                            <option value="mentionable">Mentionable</option>
                        </select>
                    </div>

                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>

                    {/* Menu Type Options */}
                    {this.renderChannelTypes()}

                    {/* Multiselect Options */}
                    <div className="mb-15">
                        <h4 style={{ marginBottom: "0px" }}>Enable Multiselect</h4>
                        <div className="section-content-header mb-15">Allow users to select more than one option.</div>
                        <select onChange={(e) => {
                            var value = e.target.value === "true";
                            this.updateData("multiselect", value);
                        }} value={this.props.data.multiselect ? "true" : "false"}>
                            <option value="false">Single Select</option>
                            <option value="true">Multi Select</option>
                        </select>
                    </div>

                    {this.props.data.multiselect && (
                        <>
                            <div className="mb-15">
                                <h4 style={{ marginBottom: "0px" }}>Minimum Options</h4>
                                <div className="section-content-header mb-15">Set the minimum number of options that can be selected.</div>
                                <VariableTextInput
                                    value={this.props.data.minOptions}
                                    placeholder="1"
                                    onChange={(value) => this.updateData("minOptions", value)}
                                />
                            </div>

                            <div className="mb-15">
                                <h4 style={{ marginBottom: "0px" }}>Maximum Options</h4>
                                <div className="section-content-header mb-15">Set the maximum number of options that can be selected.</div>
                                <VariableTextInput
                                    value={this.props.data.maxOptions}
                                    placeholder="1"
                                    onChange={(value) => this.updateData("maxOptions", value)}
                                />
                            </div>
                        </>
                    )}

                    <hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr mt-15" />

                    {/* Custom Variable Name */}
                    <div className="mb-15">
                        <h4 style={{ marginBottom: "0px" }}>Custom Variable Name (Optional)</h4>
                        <div className="section-content-header mb-15">
                            Set a custom variable name to access the selected option(s). If left empty, you can use the default {this.props.data.multiselect ? '{selected_options}' : '{selected_option}'} variable.
                        </div>
                        <div className="long-input mb-15" style={{ width: "100%" }}>
                            <label>Custom Variable Name</label>
                            <input
                                value={this.props.data.customVariableName || ""}
                                placeholder="E.g., my_select_menu"
                                type="text"
                                maxLength={50}
                                onChange={(e) => {
                                    this.updateData("customVariableName", e.target.value.replace(/[^a-zA-Z0-9_\s]/g, '').replace(/\s/g, '_'));
                                }}
                            />
                        </div>
                    </div>

                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd", marginTop: "20px", marginBottom: "20px" }}></hr>

                    {/* Disable Select Menu by Default */}
                    <div className="mb-15">
                        <h4 style={{ marginBottom: "0px" }}>Disable Select Menu by Default</h4>
                        <div className="section-content-header mb-15">Set to 'true' to disable this select menu by default.</div>
                        <VariableTextInput
                            label="Disabled"
                            value={this.props.data.disabled || ""}
                            onChange={(value) => {
                                this.updateData("disabled", value);
                            }}
                            placeholder="true or false"
                            required={false}
                            slash_options={this.props.slash_options}
                        />
                        <p className="text-sm text-gray-400 mt-1">
                            Enter 'true' to disable this select menu by default, or 'false' to keep it enabled.
                        </p>
                    </div>

                    {/* Show Select Menu Replies */}
                    <div className="mb-15 slash-action">
                        <h4>
                            Show Select Menu Replies <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-buttons#button-replies"} />
                        </h4>
                        <span>Choose whether to show or hide the select menu replies.</span>
                        <div className="mt-15">
                            <Select
                                value={this.props.data.showReplies || "show"}
                                onChange={(value) => {
                                    this.updateData("showReplies", value);
                                }}
                                type=""
                                options={[
                                    { value: "show", label: "Show Replies" },
                                    { value: "hide", label: "Hide Replies" }
                                ]}
                            ></Select>
                        </div>
                    </div>

                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>

                    {/* Component Ordering */}
                    <div className="flex flex-row items-center gap-x-3 mb-15">
                        <div>
                            <h4 className="mb-2">Enable Component Ordering</h4>
                            <p>Order this select menu amongst other message components</p>
                        </div>
                        <Toggle
                            value={this.props.data.orderComponent}
                            update={(value) => {
                                this.updateData("orderComponent", value);
                            }}
                        />
                    </div>

                    {this.renderComponentOrdering()}

                </div>
            );
        } else {
            return null;
        }
    }
};

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvSelectMenu);