import React, { Component } from 'react'
import { connect } from 'react-redux'

export class DiscordSlashPreview extends Component {

    renderOptions = () =>{
        var options = []
        var required_options = []
        if(this.props.options != undefined && this.props.options.length >0){
            this.props.options.forEach(option =>{
                if(option.required == true){
                    required_options.push(<div class="option-tag option-required">{option.name}</div>)
                }else{
                    options.push(<div class="option-tag">{option.name}</div>
                    )
                }
            })

            return(
                <div className="command-code">
                    {required_options}
                    {options.length > 0 ? <span class="tag-divider"></span>:null}
                    {options.length > 0 ? <h5>OPTIONAL</h5>:null}
                    {options}
                </div>
            )
        }else{
            return null
        }
    }
    render() {
        return (
            <div className="discord-slash-preview">
                <div className="discord-screen">

                    <div className="command-options-preview">
                        <div className="command-title">
                            <h5>Commands Matching</h5>
                            {/* <b className="slash-icon">/</b> */}
                            <h4 className="discord-command-name">/{this.props.name}</h4>
                        </div>

                        <div>

                            <div className='command-row'>
                                <div className="preview-avatar">
                                    <img src="https://polybit-apps.s3.amazonaws.com/stdlib/users/discord/profile/image.png?1621007833204"/>
                                </div>

                                <div>
                                    <div class="command-code">
                                        /{this.props.name}
                                        {this.renderOptions()}
                                        </div>
                                    <div class="preview-command-description">{this.props.description}</div>
                                </div>

                                <div class="command-bot-name">{this.props.bot.name}</div>

                            </div>

                        </div>
                    </div>

                    <div class="text-input"> 
                            <div class="plus-button"> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            </div> 
                            <span data-command-preview-name="">/{this.props.name}</span>
                            {/* <span class="placeholder">Message #general</span>  */}
                        </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    bot:state.data.bot
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscordSlashPreview)
