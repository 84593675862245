import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "../elements/Select";
import Embed from "../elements/Embed";
import TextArea from "../elements/TextArea";
import RoleAdd from "../elements/RoleAdd";

import { Steps, Hints } from "intro.js-react";
import TextStep from "../elements/TextStep";

const TYPES = [
  { label: "Message STARTS WITH the trigger", value: "starts_with" },
  { label: "Message is EXACTLY the trigger", value: "exact" },
  { label: "Message INCLUDES the trigger", value: "includes" },
];

const REPLY_TYPES = [
  { label: "Plain Text Reply", value: "text" },
  { label: "Embed Reply", value: "embed" },
];



export class Autoresponse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stepsEnabled: true,
      trigger: "",
      message_type: false,
      replyType: "text",
      type: "starts_with",

      text: "",
      embed: {},
      permissions: {
        allowed_roles: [],
        banned_roles: [],
        banned_channels: [],
        banned_users: [],
        required_permissions: [],
      },
    };
  }

  componentDidMount() {
    if (this.props.responder != undefined) {
      Object.keys(this.props.responder).forEach((key) => {
        this.setState({ [key]: this.props.responder[key] });
      });
      if ("embeds" in this.props.responder) {
        this.setState({ embed: this.props.responder.embeds[0] });
      }
      if ("text" in this.props.responder) {
        this.setState({ text: this.props.responder.text[0] });
      }
    }
  }

  addResponse = (e) => {
    e.preventDefault();
    if (this.props.index == "new") {
      this.setState({
        trigger: "",
        message_type: false,
        replyType: "text",
        type: "starts_with",
        text: "",
        embed: {},
        permissions: {
          allowed_roles: [],
          banned_roles: [],
          banned_channels: [],
          banned_users: [],
          required_permissions: [],
        },
      });
    }
    this.props.updateResponse(this.state, this.props.index);
  };

  roleAddChange = (array, key) => {
    const permissions = { ...this.state.permissions };
    permissions[key] = array;
    this.setState({ permissions: permissions });
    // console.log(permissions)
    // this.props.update(permissions)
  };

  render() {
    return (
      <div>
        {/* <Steps
                                enabled={this.state.stepsEnabled}
                                steps={steps}
                                initialStep={0}
                                showStepNumbers={true}
                                onExit={() =>{
                                    this.setState({ stepsEnabled: false });
                                }}
                            /> */}
        <form
          className="mb-15"
          onSubmit={(e) => {
            this.addResponse(e);
          }}
        >
          <div class="mb-15" id="autoresponse-trigger">
            <h3 style={{ marginBottom: "0px" }}>Trigger </h3>
            <div className="section-content-header">
              The word or phrase that your bot should respond to
            </div>
          </div>

          <div className="long-input mb-15">
            <label>Trigger</label>
            <input
              required
              type="text"
              value={this.state.trigger}
              onChange={(e) => this.setState({ trigger: e.target.value })}
            ></input>
          </div>

          <div class="mb-15">
            <h3 style={{ marginBottom: "0px" }}>Trigger Type </h3>
            <div className="section-content-header">
              When your bot should respond if a member types in the trigger
            </div>
          </div>

          <Select
            options={TYPES}
            value={this.state.type}
            onChange={(value) => {
              this.setState({ type: value });
            }}
          />

          <div class=" mt-15 mb-15">
            <h3 style={{ marginBottom: "0px" }}>Reply Type </h3>
            <div className="section-content-header">
              Wether to reply with a plain text or Embed message
            </div>
          </div>

          <Select
            options={REPLY_TYPES}
            value={this.state.replyType}
            onChange={(value) => {
              this.setState({ replyType: value });
            }}
          />

          <div class=" mt-15 mb-15">
            <h3 style={{ marginBottom: "0px" }}>Reply Content </h3>
            <div className="section-content-header">
              The content your bot will respond to when the autoresponse is
              triggered
            </div>
          </div>

          {this.state.replyType == "embed" ? (
            <Embed
              required={true}
              value={this.state.embed}
              update={(value) => {
                this.setState({ embed: value });
              }}
            />
          ) : (
            <TextArea
              required={true}
              value={this.state.text}
              onChange={(text) => {
                this.setState({ text: text });
              }}
            />
          )}


          <div>

            <div className="">
              <div className="mt-15 mb-15">
                <h3 style={{ marginBottom: "0px" }}>Permission Options</h3>
                <div className="section-content-header">
                  Set permissions to restrict who the autoresponder will
                  respond to
                </div>
              </div>
              <div className="row">
                <div className="command-edit-section col-lg-6 mb-15">
                  <div className="command-edit-section-header">
                    <h3 style={{ marginBottom: "0px" }}>Required Roles</h3>
                    <div className="section-content-header">
                      The autoresponder will only respond to people with these
                      roles. Leave blank or add @everyone to include everyone
                    </div>
                  </div>

                  <RoleAdd
                    type="role"
                    items={this.state.permissions.allowed_roles}
                    update={this.roleAddChange}
                    option="allowed_roles"
                  ></RoleAdd>
                </div>

                <div className="command-edit-section col-lg-6 mb-15">
                  <div className="command-edit-section-header">
                    <h3 style={{ marginBottom: "0px" }}>Ignored Roles</h3>
                    <div className="section-content-header">
                      Users with these roles will be ignored by the auto
                      responder
                    </div>
                  </div>

                  <RoleAdd
                    type="role"
                    items={this.state.permissions.banned_roles}
                    update={this.roleAddChange}
                    option="banned_roles"
                  ></RoleAdd>
                </div>

                <div className="command-edit-section col-lg-6 mb-15">
                  <div className="command-edit-section-header">
                    <h3 style={{ marginBottom: "0px" }}>
                      Required Permissions
                    </h3>
                    <div className="section-content-header">
                      The autoresponder will only respond to people that have
                      these permissions. Leave blank to allow all permissions
                    </div>
                  </div>

                  <RoleAdd
                    type="permissions"
                    items={this.state.permissions.required_permissions}
                    update={this.roleAddChange}
                    option="required_permissions"
                  ></RoleAdd>
                </div>

                <div className="command-edit-section col-lg-6 mb-15">
                  <div className=" command-edit-section-header">
                    <h3 style={{ marginBottom: "0px" }}>Ignored Channels</h3>
                    <div className="section-content-header">
                      The autoresponder will ignore messages posted in this
                      channels
                    </div>
                  </div>

                  <RoleAdd
                    type="channels"
                    items={this.state.permissions.banned_channels}
                    update={this.roleAddChange}
                    option="banned_channels"
                  ></RoleAdd>
                </div>
              </div>
            </div>
          </div>

          <hr class="slashcommand-hr" />
          <div style={{ display: "flex" }}>
            <div style={{ marginLeft: "auto" }}>
              {this.props.index != "new" ? (
                <button
                  style={{ marginRight: "15px" }}
                  onClick={() => {
                    this.props.delete(this.props.index);
                  }}
                  className="btn btn-gray mt-15 mr-15"
                  type="button"
                >
                  Delete
                </button>
              ) : null}
              <button className="btn btn-red mt-15" type="submit">
                {this.props.index == "new" ? "Add" : "Edit"} Autoresponder
                Message
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  beta: state.data.beta,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Autoresponse);
