import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';
import RefreshBotData from '../../elements/RefreshBotData';
import Select from '../../elements/Select';
import RoleAdd from '../../elements/RoleAdd';
import DocsLink from '../../elements/DocsLink';
import { checkIntegrationChild } from '../eventUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
export class CreateEmoji extends Component {
    componentDidMount() {
        if (!this.props.data) {
            this.props.updateElementData(
                {
                    data: {
                        type: "emoji_create",
                        name: "",
                        image: "",
                        reason: "",
                        variable: "",
                        validated: true
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.data) {
            this.props.updateElementData(
                {
                    data: {
                        type: "emoji_create",
                        name: "",
                        image: "",
                        reason: "",
                        variable: "",
                        validated: true
                    },
                    id: this.props.id
                }
            );
        } else {
            if (("name" in this.props.data && this.props.data.name != "") && ("image" in this.props.data && this.props.data.image != "") && ("variable" in this.props.data && this.props.data.variable != "")) {
                if (!this.props.data.validated) {
                    this.updateData("validated", true);
                }
            } else {
                if (this.props.data.validated) {
                    this.updateData("validated", false);
                }
            }
        }
    }

    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData({ data: data, id: this.props.id });
    };

    selectUpdate = (value) => {
        value = JSON.parse(value);
        var target = { ...this.props.data.target };
        target = value;
        this.updateData("target", target);
    };

    renderRoleOptions = () => {
        var options = [];
        options.push({ value: JSON.stringify({ id: "" }), label: "" });
        this.props.options.forEach(option => {
            if (option.type == "role") {
                options.push({ value: JSON.stringify({ variable: option.name }), label: `Role Option: ${option.name}` });
            }
        });
        if (this.props.bot_data || this.props.bot_data.length > 0) {
            this.props.bot_data.forEach((guild, index) => {
                guild.roles.forEach(role => {
                    options.push({ value: JSON.stringify(role), label: `${role.name} - ${guild.name}` });
                });
            });
        }
        return options;
    };

    renderTargetTypes = () => {
        var channel = "Channel the command was run in";
        if (this.props.mode == "event" && this.props.components['Channel']) {
            channel = "The channel.";
        }
        const targetTypes = [
            { label: channel, value: "self", disabled: this.props.components.Interaction || this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
            { label: "Channel option", value: "variable", disabled: !this.props.components.Interaction },
            { label: "Channel ID", value: "id" }
        ];
        return targetTypes;
    };

    targetOnChange = (value) => {
        if (value == "self") {
            var data = { ...this.props.data };
            data.target = {
                self: true
            };
            this.props.updateElementData({ data: data, id: this.props.id });
        } else if (value == "variable") {
            var data = { ...this.props.data };
            data.target = {
                variable: "",
            };
            this.props.updateElementData({ data: data, id: this.props.id });
        } else if (value == "id") {
            var data = { ...this.props.data };
            data.target = {
                id: "",
            };
            this.props.updateElementData({ data: data, id: this.props.id });
        }
    };



    render() {
        if (this.props.data) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Create Emoji</h4>
                        <p>Create an emoji in a server.</p>
                    </div>


                    <div className="mb-15 slash-action">
                        <h4>Name <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#name" /></h4>
                        <span>The name for the newly created emoji. Can not have spaces.</span>
                        <div class="long-input mt-15">
                            <label>Name</label>
                            <input className={`${this.props.data.name == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {


                                this.updateData("name", e.target.value);
                            }} type="text" value={this.props.data.name} placeholder="" />
                        </div>
                    </div>

                    <div className="mb-15 slash-action">
                        <h4>Image URL <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#name" /></h4>
                        <span>An image URL for the emoji.</span>
                        <div class="long-input mt-15">
                            <label>URL</label>
                            <input className={`${this.props.data.image == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                this.updateData("image", e.target.value);
                            }} type="text" value={this.props.data.image} placeholder="" />
                        </div>
                    </div>



                    <div className="mb-15 slash-action">
                        <h4>Reason <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#name" /></h4>
                        <span>An optional reason for why this emoji is being created</span>
                        <div class="long-input mt-15">
                            <label>Reason</label>
                            <input className={``} onChange={(e) => {
                                this.updateData("reason", e.target.value);
                            }} type="text" value={this.props.data.reason} placeholder="" />
                        </div>
                    </div>

                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>
                    <div className="mb-15 slash-action">
                        <h4>Variable<DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#name" /></h4>
                        <span>A variable name to use to assign to the new emoji. Using the below variables will allow you to use this emoji in other actions and conditions.</span>
                        <div class="long-input mt-15">
                            <label>Variable</label>
                            <input className={`${this.props.data.variable == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                var value = e.target.value;
                                value = value.toLowerCase();
                                value = value.substring(0, 32);
                                value = value.replace(" ", "_");
                                // value = value.replace(/[^\w]+/gi,"")
                                // value = value.replace(/[^[A-zÀ-Ÿ\d-]+/gi,"")
                                const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
                                value = value.replace(regexForNonAlphaNum, "");

                                this.updateData("variable", value);

                            }} type="text" value={this.props.data.variable} placeholder="" />
                        </div>
                    </div>

                    {this.props.data.variable != "" ? <>
                        <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>



                        <h4>Emoji Variables</h4>
                        <p className="mb-15" style={{
                            color: "#fff",
                            opacity: "0.7",
                            fontWeight: 500
                        }}>You can use the <span style={{ color: "#f45142", opacity: "1 !important" }}>{`{${this.props.data.variable}}`}</span> variable with dot notation to access data about the created emoji. </p>

                        <p style={{
                            color: "#fff",
                            opacity: "0.7",
                            fontWeight: 500
                        }}><span style={{ color: "#f45142" }}>{`{${this.props.data.variable}.id}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.variable}.id}`); toast.success("Copied variable to clipboard"); }} /> - Returns the id of the emoji.</p>

                        <p style={{
                            color: "#fff",
                            opacity: "0.7",
                            fontWeight: 500
                        }}><span style={{ color: "#f45142" }}>{`{${this.props.data.variable}.name}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.variable}.name}`); toast.success("Copied variable to clipboard"); }} /> - Returns the name of the emoji</p>

                        <p style={{
                            color: "#fff",
                            opacity: "0.7",
                            fontWeight: 500
                        }}><span style={{ color: "#f45142" }}>{`{${this.props.data.variable}.emoji}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.variable}.emoji}`); toast.success("Copied variable to clipboard"); }} /> - Returns the formatted emoji.</p>

                        <p style={{
                            color: "#fff",
                            opacity: "0.7",
                            fontWeight: 500
                        }}><span style={{ color: "#f45142" }}>{`{${this.props.data.variable}.animated}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.variable}.animated}`); toast.success("Copied variable to clipboard"); }} /> - Returns whether the emoji is animated or not.</p>

                    </> : null}


                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>

                    <div className="mb-15 slash-action">
                        <h4>Optional Server ID <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/edit-a-channel#optional-server-id" /></h4>
                        <span>An optional server Id to create the emoji in. An Id or Variable can be used. Leave this blank to create the emoji in the server the {this.props.mode == "event" ? "event" : "command"} was triggered in.</span>
                        <div class="long-input mt-15">
                            <label>ID or Variable</label>
                            <input onChange={(e) => {
                                this.updateData("guild_id", e.target.value);
                            }} maxLength={250} type="text" value={this.props.data.guild_id} placeholder="822426820447567872" />
                        </div>
                    </div>


                </div >
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    bot_data: state.data.bot_data,
    saveFailed: state.builder.saveFailed,
    components: state.builder.components,
    beta: state.data.beta
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmoji);
