import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setElements, setHighlightAction } from '../../../../actions';
import { addEdge, getOutgoers } from 'react-flow-renderer';
import { EmojiAdder } from './EmojiAdder';
import splitElements from '../splitElements';
import TextArea from '../../elements/TextArea';
import VariableTextInput from '../VariableTextInput';


export class LoopAction extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            this.addElements();
            this.props.updateElementData(
                {
                    data: {
                        type: "loop",
                        count: 5,
                        timeout: 100,
                        actions: [],
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "loop",
                        count: 5,
                        timeout: 100,
                        actions: [],
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

            if ((this.props.data.count != "") && this.props.data.validated != true) {
                this.updateData("validated", true);
            } else if (this.props.data.validated == true && (this.props.data.count == "")) {
                this.updateData("validated", false);

            }
        }
    }

    updateData = (key, value) => {

        // console.log("VALUE", value);
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    nameChange = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "_");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("reference", value);
    };

    addElements = () => {
        var elements = [...this.props.elements];
        var element = this.props.elements.find(element => element.id == this.props.selected);

        // Loop Actions
        var id = `loop_actions${elements.length + 1}_${s4()}_${s4()}`;
        elements.push(
            {
                id: id,
                type: 'loopChild',
                data: {
                    type: "loop_actions"
                },
                // target:"3",
                draggable: true,
                position: { x: element.position.x + 300, y: element.position.y + 100 },
            },
        );
        var newEdges = addEdge({
            source: element.id,
            target: id,
            type: "step",
            sourceHandle: "loop_actions",
            animated: false,
            arrowHeadType: "arrowclosed"

        }, splitElements(elements).edges);

        newEdges.forEach(newEdge => {
            var index = elements.findIndex(element => element.id == newEdge.id);
            if (index == -1) {
                elements.push(newEdge);
            }
        });

        // After Loop Actions
        var id = `loop_after${elements.length + 1}_${s4()}_${s4()}`;
        elements.push(
            {
                id: id,
                type: 'loopChild',
                data: {
                    type: "loop_after"
                },
                // target:"3",
                draggable: true,
                position: { x: element.position.x - 150, y: element.position.y + 100 },
            },
        );
        var newEdges = addEdge({
            source: element.id,
            target: id,
            type: "step",
            sourceHandle: "loop_after",
            animated: false,
            arrowHeadType: "arrowclosed"

        }, splitElements(elements).edges);

        newEdges.forEach(newEdge => {
            var index = elements.findIndex(element => element.id == newEdge.id);
            if (index == -1) {
                elements.push(newEdge);
            }
        });

        this.props.setElements(elements);

    };


    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Run a Loop</h4>
                        <p>Loop through actions and conditions a set amount of times.</p>
                        <p>Actions connected to the <strong className='text-red'>{`Loop ${this.props.data.count} times`}</strong> block will be run {this.props.data.count} times.</p>
                        <p> Once the loop has finished, actions connected the the <strong className='text-red'>After loop</strong> block will be run.</p>
                    </div>

                    <h4 style={{ marginBottom: "0px" }}>Loop Count</h4>
                    <p className="mb-2 block">The amount of times to loop through connected actions and conditions. </p>
                    <p className=' mb-15'>Use the <strong className='text-red'>{`{lengthOfCollection(ARRAY)}`}</strong> variable and the <strong className='text-red'>{`{loop_index}`}</strong> variable to loop through the contents of a collection.</p>

                    <VariableTextInput label="Count" placeholder="5" value={this.props.data.count} onChange={(value) => {

                        this.updateData("count", value);
                    }} required={true} />
                    {/* <div class="long-input mb-15">
                        <label>Count</label>
                        <input className={`${this.props.data.count == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                            this.updateData("count", e.target.value);
                        }} type="text" value={this.props.data.count} />
                    </div> */}

                    <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>
                    <h4 style={{ marginBottom: "0px" }}>Timeout (ms)</h4>
                    <p className="mb-15 block">The amount of time to wait between loops. Minimum 10ms and maximum 10 minutes.</p>

                    <VariableTextInput label="Timeout (ms)" placeholder="100" value={this.props.data.timeout} onChange={(value) => {

                        this.updateData("timeout", value);
                    }} required={true} />

                    {/* <div class="long-input mb-15">
                        <label>Timeout (ms)</label>
                        <input className={`${this.props.data.timeout == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                            this.updateData("timeout", e.target.value);
                        }} type="text" value={this.props.data.timeout} />
                    </div> */}



                    <div>
                        <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>



                        <h4>Loop Variables</h4>
                        <p className='mb-2'>There are two unique. variables that can be used inside of loops.</p>
                        <p><strong className='text-red'>{`{loop_count}`}</strong> - Returns the current loop count. Starts at 1.</p>
                        <p><strong className='text-red'>{`{loop_index}`}</strong> - Returns the current loop index. Starts at 0 and useful for positions in collections. </p>
                    </div>



                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
    updateElementData,
    setElements,
    setHighlightAction
};

export default connect(mapStateToProps, mapDispatchToProps)(LoopAction);

let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
};