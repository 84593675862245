import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";

import Embed from "../../elements/Embed";
import Select from "../../elements/Select";
import VariableTextInput from "../VariableTextInput";

const STYLES = [
	{ value: "PRIMARY", label: "Blue" },
	{ value: "SECONDARY", label: "Gray" },
	{ value: "SUCCESS", label: "Green" },
	{ value: "DANGER", label: "Red" },
	{ value: "LINK", label: "Link" }
];

export class EmbedReply extends Component {
	componentDidMount() {
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "button",
					label: "",
					style: "",
					emoji_id: "",
					validated: false
				},
				id: this.props.id
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "button",
					label: "",
					emoji_id: "",
					style: "",
					validated: false
				},
				id: this.props.id
			});
		} else {
			// VALIDATE
			// if ((this.props.data.embed.title != "" && this.props.data.embed.description != "") && this.props.data.validated != true) {
			//     this.updateData("validated", true)
			// } else if (this.props.data.validated == true && (this.props.data.embed.title == "" || this.props.data.embed.description == "")) {
			//     this.updateData("validated", false)
			// }
		}
	}

	nameChange = (value) => {
		value = value.toLowerCase();
		value = value.substring(0, 32);
		value = value.replace(" ", "-");
		const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/gu);
		value = value.replace(regexForNonAlphaNum, "");
		this.updateData("name", value);
	};

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	// updateType = (type) => {
	//         var buttons = [...this.props.data.buttons];
	//         buttons[index].style = type;
	//         this.props.update("buttons", buttons)
	//     }

	render() {
		if (this.props.data != undefined) {
			return (
				<div>
					<div className="mb-15">
						<h4>Button</h4>
						<p>Run attached Actions when a user clicks this button.</p>
					</div>

					<div className="mb-15">
						<h4 style={{ marginBottom: "0px" }}>
							Button Text <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-buttons#button-text" />{" "}
						</h4>
						<div className="section-content-header mb-15">The text of this button. All options and variables can be used.</div>
						<VariableTextInput
							value={this.props.data.label}
							slash_options={this.props.options}
							label="Text"
							placeholder="Button"
							onChange={(value) => {
								this.updateData("label", value);
							}}
						/>
					</div>

					<>
						<div className="mb-15">
							<h4 style={{ marginBottom: "0px" }}>
								Emoji Id
								<DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-buttons#button-text" />{" "}
							</h4>
							<div className="section-content-header mb-15">An optional emoji id to set for this button.</div>
							<div className="long-input">
								<label>Emoji</label>
								<input
									required
									type="text"
									value={this.props.data.emoji_id ? this.props.data.emoji_id : ""}
									onChange={(e) => {
										var value = e.target.value;
										// value = value.substr(0, 32);
										this.updateData("emoji_id", value);
									}}
								></input>
							</div>
						</div>
					</>

					<div className="mb-15 slash-action">
						<h4>
							Button Style <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-buttons#button-style"} />
						</h4>
						<span>The style and color of this button.</span>
						<div className="mt-15">
							<Select
								value={this.props.data.style}
								onChange={(value) => {
									this.updateData("style", value);
								}}
								type=""
								options={STYLES}
							></Select>
						</div>
					</div>

					{this.props.data.style == "LINK" ? (
						<div className="mb-15">
							<h4 style={{ marginBottom: "0px" }}>Button URL </h4>
							<div className="section-content-header mb-15">The URL that you will go to when you click the button. It needs to be a valid URL.</div>
							<div className="long-input">
								<label>URL</label>
								<input
									required
									type="text"
									value={this.props.data.URL ? this.props.data.URL : ""}
									onChange={(e) => {
										var value = e.target.value;
										this.updateData("URL", value);
									}}
								></input>
							</div>
						</div>
					) : null}

					{this.props.data.style != "LINK" ? (
						<div className="mb-15 slash-action">
							<div className="mb-15">
								<h4 style={{ marginBottom: "0px" }}>
									Disable button after use <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-buttons#disable-button-after-use" />{" "}
								</h4>
								<div className="section-content-header mb-15">Disable the button after use.</div>
								<div className="mt-15">
									<Select
										value={this.props.data.disableOnUse == true ? "true" : "false"}
										onChange={(value) => {
											var v = value == "true" ? true : false;
											this.updateData("disableOnUse", v);
										}}
										type=""
										options={[
											// { value: "", label: "" },
											{ value: "true", label: "Yes" },
											{ value: "false", label: "No" }
										]}
									></Select>
								</div>
							</div>
						</div>
					) : null}

					{this.props.data.style != "LINK" ? (
						<div className="mb-15 slash-action">
							<div className="mb-15">
								<h4 style={{ marginBottom: "0px" }}>
									Enable cooldown for this button <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-buttons#enable-cooldown-for-this-button" />{" "}
								</h4>
								<div className="section-content-header mb-15">Enable a user or global cooldown for this button.</div>
								<div className="mt-15">
									<Select
										value={this.props.data.cooldownEnabled == true ? "true" : "false"}
										onChange={(value) => {
											var v = value == "true" ? true : false;
											this.updateData("cooldownEnabled", v);
										}}
										type=""
										options={[
											{ value: "", label: "" },
											{ value: "true", label: "Enabled" },
											{ value: "false", label: "Disabled" }
										]}
									></Select>
								</div>
							</div>
						</div>
					) : null}

					{this.props.data.style != "LINK" ? (
						<>
							{this.props.data.disableOnUse == true ? (
								<>
									<hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />

									<div className="mb-15 slash-action">
										<h4>
											Clicks Required <DocsLink location={"https://docs.botghost.com/custom-commands/actions-options-conditions"} />
										</h4>
										<span>
											Disable the button after being clicked by this many <strong className="text-red font-bold !opacity-100">different users.</strong>
										</span>
										{/* <div className="long-input mt-15">
											<label>Clicks</label>
											<input
												required
												type="number"
												value={this.props.data.disableAfter}
												onChange={(e) => {
													var value = e.target.value;
													this.updateData("disableAfter", value);
												}}
											></input>
										</div> */}

										<VariableTextInput
											slash_options={this.props.options}
											label="Clicks"
											placeholder="3"
											value={this.props.data.disableAfter}
											onChange={(value) => {
												this.updateData("disableAfter", value);
											}}
										/>
									</div>

									<div className="mb-15 slash-action">
										<h4>
											Disabled Text <DocsLink location={"https://docs.botghost.com/custom-commands/actions-options-conditions"} />
										</h4>
										<span>The text of the button after it has been disabled.</span>
										{/* <div className="long-input mt-15">
											<label>Text</label>
											<input
												required
												type="text"
												value={this.props.data.disableText}
												onChange={(e) => {
													var value = e.target.value;
													// value = value.substr(0, 32);
													this.updateData("disableText", value);
												}}
											></input>
										</div> */}

										<VariableTextInput
											slash_options={this.props.options}
											label="Text"
											placeholder="Disabled"
											value={this.props.data.disableText}
											onChange={(value) => {
												this.updateData("disableText", value);
											}}
										/>
									</div>

									<div className="mb-15 slash-action">
										<h4>
											Disabled Style <DocsLink location={"https://docs.botghost.com/custom-commands/actions-options-conditions"} />
										</h4>
										<span>The style of the button after it has been disabled.</span>
										<div className="mt-15">
											<Select
												value={this.props.data.disableStyle}
												onChange={(value) => {
													this.updateData("disableStyle", value);
												}}
												type=""
												options={[
													{ value: "PRIMARY", label: "Blue" },
													{ value: "SECONDARY", label: "Gray" },
													{ value: "SUCCESS", label: "Green" },
													{ value: "DANGER", label: "Red" }
												]}
											></Select>
										</div>
									</div>
								</>
							) : null}
						</>
					) : null}

					{this.props.data.cooldownEnabled == true && this.props.data.style != "LINK" ? (
						<>
							<hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />

							<div className="mb-15 slash-action">
								<h4>
									Cooldown Type <DocsLink location={"https://docs.botghost.com/custom-commands/actions-options-conditions"} />
								</h4>
								<span>Whether this button's cooldown should be a global or user specific cooldown.</span>
								<div className="mt-15">
									<Select
										value={this.props.data.cooldownType}
										onChange={(value) => {
											this.updateData("cooldownType", value);
										}}
										type=""
										options={[
											{ value: "", label: "" },
											{ value: "global", label: "Global" },
											{ value: "user", label: "User" }
										]}
									></Select>
								</div>
							</div>

							<div className="mb-15 slash-action">
								<h4>
									Cooldown Duration (Seconds) <DocsLink location={"https://docs.botghost.com/custom-commands/actions-options-conditions"} />
								</h4>
								<span>The cooldown duration of this button in seconds.</span>
								<div className="long-input mt-15">
									<label>Seconds</label>
									<input
										required
										type="number"
										value={this.props.data.cooldownDuration}
										onChange={(e) => {
											this.updateData("cooldownDuration", e.target.value);
										}}
									></input>
								</div>
							</div>
						</>
					) : null}
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	beta: state.data.beta
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(EmbedReply);
