import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData, setHighlightAction } from '../../../../actions';

import TextArea from '../../elements/TextArea';
import Select from '../../elements/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faSyncAlt, faClipboard } from '@fortawesome/free-solid-svg-icons';
import VariableEditor from '../../elements/VariableEditor';
import VariableTextInput from '../VariableTextInput';

export class CrosspostMessageAction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emoji_show: false,
            length: 0,
            showVariableEditor: false,
            showSlashCommandVariables: true,
            position: 0
        };
    }

    componentDidMount() {
        if (this.props.data === undefined) {
            this.props.updateElementData({
                data: {
                    type: "crosspost_message",
                    channel_id: "",
                    message_id: "",
                    validated: false,
                    action_id: "",
                    id: ""
                },
                id: this.props.id
            });
        } else {
            this.props.setHighlightAction(this.props.data.action_id);
        }
    }

    componentWillUnmount() {
        this.props.setHighlightAction(null);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id != this.props.id && this.props.data != undefined) {
            this.props.setHighlightAction(this.props.data.action_id);
        }

        if (this.props.data === undefined) {
            this.props.updateElementData({
                data: {
                    type: "crosspost_message",
                    channel_id: "",
                    message_id: "",
                    validated: false,
                    action_id: "",
                    id: ""
                },
                id: this.props.id
            });
        } else {
            if (((this.props.data.id != "") || (this.props.data.channel_id && this.props.data.message_id))) {
                if (!this.props.data.validated) {
                    this.updateData("validated", true);
                }
            } else {
                if (this.props.data.validated) {
                    this.updateData("validated", false);
                }
            }
        }
    }

    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    checkRoot = () => {
        const find = this.props.elements.find(i => i.type === 'root');
        if (find && find.data.data.type === 'messageCreate') {
            return true;
        }
        return false;
    };

    render() {
        if (this.props.data !== undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Publish a Message</h4>
                        <p>Publish a message sent in an announcements/news channel</p>
                        {this.checkRoot() && <button onClick={async (e) => {
                            var data = { ...this.props.data };
                            data.channel_id = "{event_message_channel_id}";
                            data.message_id = "{event_message_id}";
                            this.props.updateElementData(
                                {
                                    data: data,
                                    id: this.props.id
                                }
                            );
                        }} className='btn btn-red'>Populate with event Channel and Message</button>}
                    </div>
                    <div className="mb-15 slash-action">
                        <h4>Channel ID</h4>
                        <p>The channel the message is in</p>

                        <VariableTextInput slash_options={this.props.options} label="Channel ID" placeholder="928304793309544468" value={this.props.data.channel_id} onChange={(value) => {
                            this.updateData("channel_id", value);
                        }} />

                        {/* <div class="long-input mt-15">
                            <label>Channel ID</label>
                            <input className={``} onChange={(e) => {
                                this.updateData("channel_id", e.target.value);
                            }} type="text" value={this.props.data.channel_id} placeholder="928304793309544468" />
                        </div> */}
                    </div>

                    {this.props.data.channel_id && <div className="mb-15 slash-action">
                        <h4>Message ID</h4>
                        <span>The message id of the message to announce.</span>
                        <VariableTextInput slash_options={this.props.options} label="Message ID" placeholder="1179107633148481696" value={this.props.data.message_id} onChange={(value) => {
                            this.updateData("message_id", value);
                        }} />
                        {/* <div class="long-input mt-15">
                            <label>Message Id</label>
                            <input className={``} onChange={(e) => {
                                this.updateData("message_id", e.target.value);
                            }} type="text" value={this.props.data.message_id} placeholder="1179107633148481696" />
                        </div> */}
                    </div>}
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    components: state.builder.components,
    beta: state.data.beta,
    mode: state.builder.mode,
    slash: state.builder.slash
});
const mapDispatchToProps = {
    updateElementData,
    setHighlightAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CrosspostMessageAction);