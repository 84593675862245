import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import server from "../../../api/server";
import Dashboard from "../Dashboard";
import ModuleHeader from "../elements/ModuleHeader";
import SectionHeader from "../elements/SectionHeader";
import { TextArea } from "../elements/TextArea";

const options = {
  account: [
    {
      icon: "fa-lock",
      text: "Account Locked",
      helper: {
        title: "Create your a new Discord account",
        text: "If your account was locked or banned by Discord we can not help you. Once you have created your new account, open a Change Bot Ownership ticket to move over your bots.",
      },
    },
    {
      icon: "fa-lock-open",
      text: "Can't Log in",
      helper: {
        title: "Please ensure that Discord is not down",
        text: "Please ensure that Discord.com is not down and that you are logging in with the correct discord account. Make sure you are using the correct email and password combination.",
      },
    },
    {
      icon: "fa-lock",
      text: "Change Bot Ownership",
      form_contents: {
        placeholder:
          "Please include the name of the bot you wish to transfer and the discord email address of the account you wish to transfer it to.",
      },
    },
    {
      icon: "fa-users",
      text: "Delete Account",
      form_contents: {
        placeholder:
          "Please provide the discord email address of the account you are wanting to delete. Note: This will delete your entire account and not a single bot.",
      },
    },
    {
      text: "Other",
      form_contents: {
        placeholder:
          "Please provide as much detail about your question as possible.",
      },
    },
  ],
  payment: [
    {
      icon: "fa-money-bill-wave",
      text: "Refund",
    },
    {
      icon: "fa-window-close",
      text: "Cancel Subscription",
      helper: {
        title: "Please cancel your subscription in your dashboard",
        text: "Your premium subscription can be cancelled by going into Settings in your dashboard and clicking Manage Subscription",
      },
    },
    {
      icon: "fa-arrows-alt-h",
      text: "Move Subscription",
      form_contents: {
        placeholder:
          "Please provide the email address of the discord account you wish to transfer your subscription to. Please also provide the email address you used when signing up for premium.",
      },
    },
    {
      icon: "fa-heart-broken",
      text: "I did not receive my tokens",
      form_contents: {
        placeholder:
          "Please provide the email address you used when signing up for premium.",
      },
    },
    {
      text: "Other",
      server: true,
    },
  ],
  bot: [
    {
      icon: "fa-cloud",
      text: "My bot is offline",
      server: true,
    },
    {
      icon: "fa-envelope",
      text: "Duplicated Responses",
      server: true,
    },
    {
      icon: "fa-bug",
      text: "Code-Grant Error",
      helper: {
        title: "Ensure that Requires OAUTH2 Code Grant is unchecked",
        text: "Ensure that Requires OAUTH2 Code Grant is unchecked in the area you generated your bot's token. Read more here https://docs.botghost.com/getting-started/getting-a-token#adding-a-profile-picture-and-name. ",
      },
    },
    {
      icon: "fa-bug",
      text: "I'd like to report a bug",
      server: true,
    },
    {
      text: "Other",
      server: true,
    },
  ],
  suggestions: [
    {
      icon: "fa-bullhorn",
      text: "Feature Suggestion",
      server: true,
    },
  ],
};

export class Support extends Component {
  constructor(props) {
    super(props);

    this.state = {
      option: null,
      option_object: null,
      email: "",
      content: "",
      disabled: false,
      completed: false,
      subscriptions: [],
      subscription_disabled: false,
      subscription_failed: "",
    };
  }

  getData = async () => {
    const response = await server.get("/support/info").catch((e) => { });
    if (response != undefined && response.data != false) {
      this.setState({ subscriptions: response.data.subscriptions });
    }
  };

  componentDidMount() {
    this.getData();
  }

  formSubmit = async (e) => {
    e.preventDefault();
    this.setState({ disabled: true });
    const response = await server
      .post("/support", {
        email: this.state.email,
        content: this.state.content,
        option: this.state.option_object.text,
      })
      .catch((e) => { });
    this.setState({ disabled: false, completed: true });
    window.location.replace("/dashboard");
  };

  renderOptions = () => {
    if (this.state.option != null) {
      var returnarray = [];
      options[this.state.option].forEach((option) => {
        returnarray.push(
          <div className="col-xl-3 col-lg-6">
            <div
              className="module-item"
              onClick={(e) => {
                this.setState({ option_object: option });
              }}
            >
              <FontAwesomeIcon />
              <h3>{option.text}</h3>
              <p>Issues with your BotGhost or Discord account</p>
            </div>
          </div>
        );
      });
      return <div className="row">{returnarray}</div>;
    } else {
      return null;
    }
  };

  textChange = (value) => {
    this.setState({ content: value });
  };

  renderSupport = () => {
    // console.log(this.state);
    if (this.state.option_object.server == true) {
      return (
        <div>
          <SectionHeader
            title="Support Server"
            pretitle="Support"
          ></SectionHeader>
          <div className="section-content-normal">
            <div>
              <h3>
                Please join the BotGhost support server by clicking the button
                below
              </h3>
              <div className="section-content-support">
                <a
                  href="https://discord.com/invite/9UPM6S4xyA"
                  target="_blank"
                  className="btn btn-red"
                >
                  Support Server
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.option_object.form_contents != undefined) {
      return (
        <div>
          <SectionHeader
            title="Support Server"
            pretitle="Support"
          ></SectionHeader>
          <div className="section-content-normal">
            <div>
              <h3>
                Please join the BotGhost support server by clicking the button
                below
              </h3>
              <div className="section-content-support">
                <a
                  href="https://discord.com/invite/9UPM6S4xyA"
                  target="_blank"
                  className="btn btn-red"
                >
                  Support Server
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.option_object.text != "Refund") {
      // HELPER
      return (
        <div>
          <SectionHeader title="Answer" pretitle="Support"></SectionHeader>
          <div className="section-content-normal">
            <div>
              <h3>{this.state.option_object.helper.title}</h3>
              <div className="section-content-support">
                {this.state.option_object.helper.text}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.option_object.text == "Refund") {
      var subscriptions = [];
      if (this.state.subscriptions.length <= 3) {
        this.state.subscriptions.forEach((subscription) => {
          subscriptions.push(
            <div className="section-content-normal">
              <div>
                <h3>
                  Subscription Id: {subscription.subscription_id} Type:{" "}
                  {subscription.type}
                </h3>
                <div>
                  {subscription.active == true ? (
                    <button
                      disabled={this.state.subscription_disabled}
                      onClick={(e) => {
                        this.refund(subscription);
                      }}
                      className="btn btn-red"
                    >
                      Refund and Cancel
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          );
        });
        return (
          <div>
            <SectionHeader title="Refund" pretitle="Support"></SectionHeader>
            <div style={{ color: "red", fontWeight: "800" }}>
              {this.state.subscription_failed}
            </div>
            {subscriptions}
          </div>
        );
      } else {
        return (
          <div>
            <SectionHeader
              title="Support Server"
              pretitle="Support"
            ></SectionHeader>
            <div className="section-content-normal">
              <div>
                <h3>
                  Please join the BotGhost support server by clicking the button
                  below
                </h3>
                <div className="section-content-support">
                  <a
                    href="https://discord.com/invite/9UPM6S4xyA"
                    target="_blank"
                    className="btn btn-red"
                  >
                    Support Server
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  refund = async (subscription) => {
    this.setState({ subscription_disabled: true });
    const response = await server
      .post("/support/refund", subscription)
      .catch((e) => { });
    if (response == undefined || response.data.completed == false) {
      if (response == undefined) {
        var reason = "Server Error: Please retry in 30 seconds";
        this.setState({
          subscription_failed:
            "The available refund period of this subscription has expired",
          subscription_disabled: false,
        });
      } else {
        var reason = response.data.error.error_code;
        if (reason == "invalid_state_for_cancel") {
          this.setState({
            subscription_disabled: false,
            subscription_failed:
              "This subscription has already been canceled and refunded",
          });
        } else if (reason == "refund_period_expired") {
          this.setState({
            subscription_failed:
              "The available refund period of this subscription has expired",
            subscription_disabled: false,
          });
          // $scope.refund_error = ""
        }
      }
    } else {
      window.location.replace("/dashboard");
      this.setState({ completed: true });
    }
  };
  render() {
    return (
      <Dashboard>
        <ModuleHeader
          showEnabled={false}
          description="Send a ticket to receive support"
          title="Support"
          icon="support.png"
        />

        <section>
          <SectionHeader
            title="Pick a Category"
            pretitle="Support"
          ></SectionHeader>

          <div className="row">
            <div className="col-xl-3 col-lg-6">
              <div
                className="module-item"
                onClick={(e) => {
                  this.setState({ option: "account" });
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/account.png"}
                />
                <h3>Account</h3>
                <p>Issues with your BotGhost or Discord account</p>
              </div>
            </div>

            <div className="col-xl-3 col-lg-6">
              <div
                className="module-item"
                onClick={(e) => {
                  this.setState({ option: "payment" });
                }}
              >
                <img src={process.env.PUBLIC_URL + "/images/icons/money.png"} />
                <h3>Payments</h3>
                <p>Problems with your subscription or payments</p>
              </div>
            </div>

            <div className="col-xl-3 col-lg-6">
              <div
                className="module-item"
                onClick={(e) => {
                  this.setState({ option: "bot" });
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/operation.png"}
                />
                <h3>Bot Issues</h3>
                <p>Problems with your bot and how it operates</p>
              </div>
            </div>

            <div className="col-xl-3 col-lg-6">
              <div
                className="module-item"
                onClick={(e) => {
                  this.setState({ option: "suggestions" });
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/wrench.png"}
                />
                <h3>Feature Suggestions</h3>
                <p>
                  Suggest features you would like to see added in the future
                </p>
              </div>
            </div>
          </div>
        </section>

        {this.state.option != null ? (
          <section>
            <SectionHeader
              title="Select an option from below"
              pretitle="Support"
            ></SectionHeader>
            {this.renderOptions()}
          </section>
        ) : null}

        {this.state.option_object != null ? (
          <seciton>{this.renderSupport()}</seciton>
        ) : null}
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Support);
