import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactGA from 'react-ga';
import { updatePage, setFavModules } from "../../../actions";
import server from '../../../api/server';

import history from "../../../history";

class ModuleItem extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    setPage = (page) => {

        var slug = page.toLowerCase();
        slug = slug.split(" ").join("");
        if (this.props.custom) {
            history.push(`/dashboard/module/${this.props.id}/${slug}`);
        } else {
            history.push("/dashboard/" + slug);
        }


        // this.props.updatePage(slug)
    };

    checkFav = () => {
        if (this.props.user.favModules.includes(this.props.title) || (this.props.custom && this.props.user.favModules.includes(this.props.id))) {
            return true;
        } else {
            return false;
        }
    };

    renderBadges = () => {
        // Return only 1 badge
        if (this.props.new == true) {
            return <span className="module-badge" style={{ backgroundColor: "#f45142" }}>New</span>;
        } else if (this.props.updated == true) {
            return <span className="module-badge" style={{ backgroundColor: "#f45142" }}>Updated</span>;
        } else if (this.props.premium && this.props.bot.premium == false) {
            return <span className="module-badge">Premium</span>;
        } else {
            return null;
        }
    };

    render() {
        if (this.props.link != undefined) {
            return (
                <a href={this.props.link} target="_blank" style={{ textDecoration: "none" }}>
                    <div className={`module-item  ${this.props.premium && this.props.bot.premium == false ? "module-premium" : ""}`}>
                        <div className='top-[10px] right-[10px] text-[20px] absolute text-gray hover:opacity-100 hover:text-[#FFD700]'>
                            <FontAwesomeIcon icon={faStar} className="h-5" />
                        </div>
                        {/* <div onClick={(e) =>{this.setPage(this.props.title)}} className={`module-item`}> */}
                        {this.renderBadges()}
                        <img src={this.props.custom ? this.props.icon : process.env.PUBLIC_URL + `/images/icons/${this.props.icon}`} className={`${this.props.custom ? "rounded-full" : ""}`}></img>
                        <h3 style={{ fontSize: "22px" }}>{this.props.title}</h3>
                        <p>{this.props.description}</p>
                    </div>
                </a>

            );
        } else {
            return (
                <div onClick={(e) => { this.setPage(this.props.title); }} className={`module-item ${this.props.premium && this.props.bot.premium == false ? "module-premium" : ""}`}>
                    {/* <div onClick={(e) =>{this.setPage(this.props.title)}} className={`module-item`}> */}
                    {!this.props.featured ? <div className={`top-[10px] right-[10px] text-[20px] absolute p-2 ${this.checkFav() == true ? "text-[#FFD700] hover:text-[gray] hover:duration-300" : "text-[gray]"} hover:text-[#FFD700] hover:duration-300`} onClick={(e) => {
                        // Disable clicks from above
                        e.stopPropagation();
                        var favModules = [...this.props.user.favModules];
                        ReactGA.initialize('UA-122665050-1');
                        if (this.props.custom) {
                            if (favModules.includes(this.props.id)) {
                                favModules.splice(favModules.indexOf(this.props.id), 1);
                            } else {
                                favModules.push(this.props.id);
                                ReactGA.event({
                                    category: "Module Favorite",
                                    label: this.props.title,
                                    action: "Favorite"
                                });
                            }

                        } else {
                            if (favModules.includes(this.props.title)) {
                                favModules.splice(favModules.indexOf(this.props.title), 1);
                            } else {
                                favModules.push(this.props.title);
                                ReactGA.event({
                                    category: "Module Favorite",
                                    label: this.props.title,
                                    action: "Favorite"
                                });
                            }
                        }

                        server.post("/dashboard/updateFavModules", {
                            favModules: favModules
                        }).catch(e => {

                        });


                        this.props.setFavModules(favModules);



                    }}>
                        <FontAwesomeIcon icon={faStar} className="h-5" />
                    </div> : null}


                    {this.renderBadges()}
                    <img src={this.props.custom ? this.props.icon : process.env.PUBLIC_URL + `/images/icons/${this.props.icon}`} className={`${this.props.custom ? "rounded-full" : ""}`}></img>
                    <h3 style={{ fontSize: "22px" }}>{this.props.title}</h3>
                    <p>{this.props.description}</p>
                </div>
            );
        }

    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
    user: state.data.user,
});

export default connect(mapStateToProps, { updatePage, setFavModules })(ModuleItem);