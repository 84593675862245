import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import { setAuth } from "../actions/";
import Dashboard from "./dashboard/Dashboard";
import { Loader } from "./Loader";

import Modules from "./dashboard/Modules";

import Moderation from "./dashboard/modules/Moderation";
import Announcements from "./dashboard/modules/Announcements";
import CustomCommands from "./dashboard/modules/CustomCommands";
// import MemeCreator from "./dashboard/modules/MemeCreator";
import Fortnite from "./dashboard/modules/Fortnite";
import ApexLegends from "./dashboard/modules/ApexLegends";
import Settings from "./dashboard/modules/Settings";
import Translator from "./dashboard/modules/Translator";
import Weather from "./dashboard/modules/Weather";
import Search from "./dashboard/modules/Search";
import Trivia from "./dashboard/modules/Trivia";
import AutoMod from "./dashboard/modules/AutoMod";
import AutoRole from "./dashboard/modules/AutoRole";
import Twitch from "./dashboard/modules/Twitch";
import YouTube from "./dashboard/modules/YouTube";
import Twitter from "./dashboard/modules/Twitter";
import Reddit from "./dashboard/modules/Reddit";
import Music from "./dashboard/modules/Music";
import Usage from "./dashboard/modules/Usage";
import TimedMessages from "./dashboard/modules/TimedMessages";
import ReactionRoles from "./dashboard/modules/ReactionRoles";
import Economy from "./dashboard/modules/Economy";
import Levels from "./dashboard/modules/Levels";
import CoCreate from "./dashboard/modules/CoCreate";
import Botlist from "./dashboard/modules/Botlist";
import Premium from "./dashboard/modules/Premium";
import Status from "./dashboard/modules/Status";
import NewBot from "./dashboard/modules/NewBot";
import Invite from "./dashboard/modules/Invite";
import StatsChannels from "./dashboard/modules/StatsChannels";
import Giveaways from "./dashboard/modules/Giveaways";
import ErrorBoundary from "./dashboard/elements/ErrorBoundary";
import ReactGA from "react-ga";
import Affiliate from "./dashboard/modules/Affiliate";
import Support from "./dashboard/modules/Support";
import Errors from "./dashboard/modules/Errors";
import FreeHosting from "./dashboard/modules/FreeHosting";
import Server_FreeHosting from "./dashboard/modules/Server_FreeHosting";
import SlashCommands from "./dashboard/modules/SlashCommands";
import Servers from "./dashboard/modules/Servers";
import CommandBuilder from "./dashboard/commandbuilder/CommandBuilder";
import AutoResponder from "./dashboard/modules/AutoResponder";
import Twitter_FreePremium from "./dashboard/modules/Twitter_FreePremium";
import Logging from "./dashboard/modules/Logging";
import Crypto from "./dashboard/modules/Crypto";
import OpenSea from "./dashboard/modules/OpenSea";
import NFTs from "./dashboard/modules/NFTs";
import Tickets from "./dashboard/modules/Tickets";
import BuilderEmbed from "./dashboard/modules/EmbedBuilder";
import DataStorage from "./dashboard/modules/DataStorage";
import CustomEvents from "./dashboard/modules/CustomEvents";
import ActiveDevBadge from "./dashboard/modules/ActiveDevBadge";
import history from "../history";
import CustomModule from "./dashboard/modules/CustomModule";
import NewModules from "./dashboard/NewModules";
import Gifting from "./dashboard/modules/Gifting";
import Webhooks from "./dashboard/modules/Webhooks";
import TimedEvents from "./dashboard/modules/TimedEvents";
import IFTTTLink from "./dashboard/modules/IFTTTLink";
import IFTTT from "./dashboard/modules/IFTTT";
import CoCreateUpdate from "./dashboard/modules/CoCreateUpdate";
import Collaborate from "./dashboard/modules/Collaborate";
import CollabInvite from "./dashboard/modules/CollabInvite";
import server from "../api/server";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			whitelist: false
		};
	}

	componentDidMount() {
		this.props.setAuth();
		ReactGA.initialize("UA-122665050-1", {
			gaOptions: {
				cookieDomain: "auto"
			}
		});

		if (process.env.REACT_APP_WHITELIST_MODE === "true") {
			// this.checkWhiteList();
		}

		console.log(process.env.REACT_APP_WHITELIST_MODE, "WHITELIST MODE");
		// ReactGA.set({ referrer: 'https://botghost.com' })
	}

	checkWhiteList = async () => {
		var response = await server.get("/dev/whitelist");
		if (!response.data.whitelist) {
			this.setState({ whitelist: true });
		}
	};

	render() {
		if (this.state.whitelist) {
			return (
				<div className="p-5">
					<h3 className="text-lg text-white">You are not whitelisted to view this content. This is for authorized BotGhost testers only. Please stop snooping.</h3>
				</div>
			);
		}
		return (
			<ErrorBoundary>
				<Switch>
					<Route exact path="/dashboard/new" component={NewBot}></Route>
					<Route exact path="/dashboard/modules" component={Modules}></Route>
					<Route exact path="/dashboard/moderation" component={Moderation}></Route>
					<Route exact path="/dashboard/announcements" component={Announcements}></Route>
					<Route exact path="/dashboard/automod" component={AutoMod}></Route>
					<Route exact path="/dashboard/customcommands" component={SlashCommands}></Route>
					<Route exact path="/dashboard/textcommands" component={CustomCommands}></Route>
					<Route exact path="/dashboard/auto-role" component={AutoRole}></Route>
					<Route exact path="/dashboard/usage" component={Usage}></Route>
					<Route exact path="/dashboard/giveaways" component={Giveaways}></Route>
					<Route exact path="/dashboard/timedmessages" component={TimedMessages}></Route>
					<Route exact path="/dashboard/reactionroles" component={ReactionRoles}></Route>
					<Route exact path="/dashboard/youtube" component={YouTube}></Route>
					<Route exact path="/dashboard/twitter" component={Twitter}></Route>
					{/* <Route exact path="/dashboard/reddit" component={Reddit}></Route> */}
					<Route exact path="/dashboard/twitch" component={Twitch}></Route>
					{/* <Route exact path="/dashboard/memecreator" component={MemeCreator}></Route> */}
					<Route exact path="/dashboard/levels" component={Levels}></Route>
					<Route
						exact
						path="/dashboard/music"
						component={() => {
							window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
							return null;
						}}
					></Route>
					<Route exact path="/dashboard/economy" component={Economy}></Route>
					<Route exact path="/dashboard/trivia" component={Trivia}></Route>
					<Route exact path="/dashboard/search" component={Search}></Route>
					<Route exact path="/dashboard/weather" component={Weather}></Route>
					<Route exact path="/dashboard/autoresponder" component={AutoResponder}></Route>
					<Route exact path="/dashboard/translator" component={Translator}></Route>
					<Route exact path="/dashboard/fortnite" component={Fortnite}></Route>
					<Route exact path="/dashboard/apexlegends" component={ApexLegends}></Route>
					<Route exact path="/dashboard/settings" component={Settings}></Route>
					<Route exact path="/dashboard/premium" component={Premium}></Route>
					{/* <Route exact path="/dashboard/cocreate" component={CoCreateUpdate}></Route> */}
					<Route exact path="/dashboard/botlist" component={Botlist}></Route>
					<Route exact path="/dashboard/serverpremium" component={Server_FreeHosting}></Route>
					<Route exact path="/dashboard/twitterpremium" component={Twitter_FreePremium}></Route>
					<Route exact path="/dashboard/tickets" component={Tickets}></Route>
					<Route exact path="/dashboard/freepremium" component={Server_FreeHosting}></Route>
					<Route exact path="/dashboard/referrals" component={Affiliate}></Route>
					<Route exact path="/dashboard/errors" component={Errors}></Route>
					<Route exact path="/dashboard/statisticschannels" component={StatsChannels}></Route>
					<Route exact path="/dashboard/crypto" component={Crypto}></Route>
					<Route exact path="/dashboard/openseaalerts" component={OpenSea}></Route>
					<Route exact path="/dashboard/nfts" component={NFTs}></Route>
					<Route exact path="/dashboard/webhooks" component={Webhooks}></Route>
					<Route exact path="/dashboard/timedevents" component={TimedEvents}></Route>
					<Route exact path="/dashboard/status" component={Status}></Route>
					<Route exact path="/invite/:id" component={Invite}></Route>
					<Route exact path="/collab/:id" component={CollabInvite}></Route>
					<Route exact path="/dashboard/support" component={Support}></Route>
					<Route exact path="/dashboard/servers" component={Servers}></Route>
					<Route exact path="/dashboard/slashcommands" component={SlashCommands}></Route>
					<Route exact path="/dashboard/builder" component={CommandBuilder}></Route>
					<Route exact path="/dashboard/logging" component={Logging}></Route>
					<Route exact path="/dashboard/embedbuilder" component={BuilderEmbed}></Route>
					<Route exact path="/dashboard/datastorage" component={DataStorage}></Route>
					<Route exact path="/dashboard/customevents" component={CustomEvents}></Route>
					<Route exact path="/dashboard/activedeveloperbadge" component={ActiveDevBadge}></Route>
					<Route exact path={`/dashboard/module/:id/:name`} component={CustomModule}></Route>
					<Route exact path={`/dashboard/gifting`} component={Gifting}></Route>
					<Route exact path={`/dashboard/ifttt/link`} component={IFTTTLink}></Route>
					<Route exact path={`/dashboard/ifttt/`} component={IFTTT}></Route>
					<Route exact path={`/dashboard/collab`} component={Collaborate}></Route>
					<Route exact path="/dashboard" component={Modules}></Route>

					<Route path="/" component={Modules}></Route>
				</Switch>
			</ErrorBoundary>
		);
	}
}

const mapStateToProps = (state) => {
	return { auth: state.auth, user: state.data.user, bot: state.data.bot };
};

export default connect(mapStateToProps, { setAuth })(App);
