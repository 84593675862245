import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ShopItem } from './ShopItem';
import ShopItemModal from './ShopItemModal';
import PremiumModal from './PremiumModal';

import ReactGA from 'react-ga';

export class Shop extends Component {

  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      index: null,
      showPremiumModal: false
    }
  }

  renderItems = () => {
    var items = []
    if ('shop' in this.props.economy) {
      this.props.economy.shop.items.forEach((item, index) => {
        items.push(
          <ShopItem click={() => {
            this.setState({
              index: index,
              showModal: true
            });

            document.body.style.overflowY = 'hidden';

          }} name={item.name} img={item.img} />
        )
      })
    }
    return items;
  }

  render() {
    return (
      <div className="shop">
        {this.state.showModal === true ? <ShopItemModal index={this.state.index} closeModal={() => {
          this.setState({ showModal: false });
          document.body.style.overflowY = 'visible';
        }} /> : null}

        {this.state.showPremiumModal === true ? <PremiumModal

          closeModal={() => {
            this.setState({ showPremiumModal: false });
            document.body.style.overflowY = 'visible';
          }}
        /> : null}
        <div className="shop-items-container">
          {this.renderItems()}
          <button className="new-item" onClick={() => {
            if (this.props.premium === true) {
              this.setState({ showModal: true, index: null });
              document.body.style.overflowY = 'hidden';
            } else {
              this.setState({ showPremiumModal: true });
            }

            ReactGA.initialize('UA-122665050-1');
            ReactGA.event({
              category: "Economy Item",
              label: this.props.premium,
              action: "New Dashboard"
            })

          }}>New Item</button>



        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  economy: state.data.bot.commands.economy,
  premium: state.data.premium
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
