import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';

import TextArea from '../../elements/TextArea';
import { EmojiAdder } from './EmojiAdder';
import Select from '../../elements/Select';
import { checkIntegrationChild } from '../eventUtils';

export class ItemAction extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            var target = "";
            if (this.props.components['Member']) {
                target = "trigger";
            }

            this.props.updateElementData(
                {
                    data: {
                        type: this.props.type,
                        item: "",
                        target: target,
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: this.props.type,
                        item: "",
                        target: "",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

            if ((this.props.data.item != "" && this.props.data.target != "") && this.props.data.validated != true) {
                this.updateData("validated", true);
            } else if (this.props.data.validated == true && (this.props.data.item == "" || this.props.data.target == "")) {
                this.updateData("validated", false);

            }
        }
    }

    renderUserOptions = () => {
        var member = "The user who triggered the command.";
        if (this.props.mode == "event" && this.props.components['User']) {
            member = "The user.";
        }

        var options = [
            { value: "", label: "" },
            { value: "trigger", label: member, disabled: this.props.components.Interaction || this.props.components.User ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true }
        ];
        this.props.options.forEach(option => {
            if (option.type == "user") {
                options.push({ value: option.name, label: `User Option: ${option.name}` });


            }

        });
        // console.log(options);
        return options;

    };
    renderItemOptions = () => {
        var options = [
            { value: "", label: "" }
        ];

        this.props.options.forEach(option => {
            if (option.type == "string") {
                options.push({ value: `{option_${option.name}}`, label: `String Option: ${option.name}` });
            }
        });

        this.props.shop.items.forEach(item => {
            options.push({ value: item.name, label: item.name });
        });

        return options;
    };

    updateData = (key, value) => {

        // console.log("VALUE", value);
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };


    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>{this.props.type == "add_item" ? "Add" : "Remove"} Item</h4>
                        <p>{this.props.type == "add_item" ? "Adds an item to a users inventory." : "Removes an item from a users inventory."}</p>
                    </div>
                    <div className="mb-15 slash-action">
                        <h4>Item <DocsLink location={`https://docs.botghost.com/custom-commands-and-events/actions/${this.props.type == "add_item" ? "add" : "remove"}-item#item`} /></h4>
                        <span className='mb-15'>{`The item to ${this.props.type == "add_item" ? "add to" : "remove from"} a users inventory.`}</span>

                        <Select options={this.renderItemOptions()} value={this.props.data.item} onChange={(value) => { this.updateData("item", value); }}></Select>

                    </div>

                    <div className="mb-15">
                        <h4 style={{ marginBottom: "0px" }}>Target <DocsLink location={`https://docs.botghost.com/custom-commands-and-events/actions/${this.props.type == "add_item" ? "add" : "remove"}-item#target`} /></h4>
                        <div className="section-content-header mb-15">Who to {`${this.props.type == "remove_item" ? "remove an item from." : "add an item to."}`}</div>
                        <Select options={this.renderUserOptions()} value={this.props.data.target} onChange={(value) => { this.updateData("target", value); }}></Select>
                    </div>

                </div>


            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    shop: state.data.bot.commands.economy.shop,
    components: state.builder.components,
    mode: state.builder.mode,
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemAction);
