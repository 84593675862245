import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../Dashboard";
import ModuleHeader from "../elements/ModuleHeader";
import SectionHeader from "../elements/SectionHeader";

import { setBotSettings, setBotModule } from "../../../actions";
import { Select } from "../elements/Select";
import { Embed } from "../elements/Embed";
import { TextArea } from "../elements/TextArea";
import Toggle from "../elements/Toggle";
import { text } from "@fortawesome/fontawesome-svg-core";
import Autoresponse from "./Autoresponse";
import Command from "../elements/Command";
import { PremiumBanner } from "../elements/PremiumBanner";
import { PremiumModal } from "../elements/PremiumModal";

export class AutoResponder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cooldown: 30,
      error: "",
      showPremiumBanner: false,
    };
  }

  setCooldown = (value) => {
    var responder = { ...this.props.responder };
    var cooldown = this.state.cooldown;
    if (cooldown <= 10) {
      cooldown = 10;
      this.setState({ cooldown: 10 });
    }

    responder.cooldown = parseInt(cooldown);
    this.props.setBotModule({
      module: "responder",
      module_data: responder,
    });
  };

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.setState({ cooldown: this.props.responder.cooldown });
  }

  changeMessageType = (value) => {
    this.setState({ message_type: value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.responder.cooldown != this.props.responder.cooldown &&
      this.state.cooldown == prevState.cooldown
    ) {
      this.setState({ cooldown: this.props.responder.cooldown });
    }
  }

  // addResponse =(e) =>{
  //     e.preventDefault()

  //     var newResponse = {
  //         type:this.state.type,
  //         trigger:this.state.trigger,
  //         replyType:this.state.replyType,
  //     }
  // if(this.state.replyType == "embed"){
  //     newResponse.embeds = [this.state.embed];
  // }else{
  //     newResponse.text = [this.state.text]
  // }

  //     console.log(newResponse)

  // }

  updateResponse = (response, index) => {
    this.setState({ error: "" });
    if (index == "new") {
      var newResponse = {
        type: response.type,
        trigger: response.trigger,
        replyType: response.replyType,
        permissions: response.permissions,
      };

      if (response.replyType == "embed") {
        newResponse.embeds = [response.embed];
      } else {
        newResponse.text = [response.text];
      }

      if (
        this.props.premium != true &&
        this.props.responder.messages.length > 2
      ) {
        this.setState({ showPremiumBanner: true });
        return;
        // DONT ALLOW
      }

      // CHECK IF ALREADY EXISTS;

      var responder = { ...this.props.responder };
      var exists = false;
      responder.messages.forEach((message) => {
        if (message.trigger == newResponse.trigger) {
          exists = true;
        }
      });
      if (exists == true) {
        this.setState({
          error: "An Autoresponder with this trigger already exists!",
        });
        return;
      }
      responder.messages.push(newResponse);
      // console.log(responder);
      this.props.setBotModule({
        module: "responder",
        module_data: responder,
      });
    } else {
      var newResponse = {
        type: response.type,
        trigger: response.trigger,
        replyType: response.replyType,
        permissions: response.permissions,
      };

      if (response.replyType == "embed") {
        newResponse.embeds = [response.embed];
      } else {
        newResponse.text = [response.text];
      }

      var responder = { ...this.props.responder };
      responder.messages[index] = newResponse;
      this.props.setBotModule({
        module: "responder",
        module_data: responder,
      });
      // console.log(responder);
      this.setState({ showIndex: null });
    }
  };

  renderAutoResponses = () => {
    var responses = [];
    this.props.responder.messages.forEach((responder, index) => {
      const deleteResponder = () => {
        var responder = { ...this.props.responder };
        responder.messages.splice(index, 1);
        this.props.setBotModule({
          module: "responder",
          module_data: responder,
        });
        this.setState({ showIndex: null });
      };
      responses.push(
        <div className="announcement">
          <div
            className="announcement-header"
            onClick={(e) => {
              this.setState({
                showIndex: this.state.showIndex == index ? null : index,
              });
            }}
          >
            <div>
              <h3 className="mb-0">{responder.trigger}</h3>
              <div className="section-content-header">{`Responds to ${responder.trigger
                } with ${"embeds" in responder ? `an embed message.` : `a text message.`
                }`}</div>
            </div>
            <div style={{ marginLeft: "auto" }}>
              {this.state.showIndex == index ? (
                <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i>
              ) : (
                <i
                  style={{ fontSize: "20px" }}
                  class="bi bi-caret-down-fill"
                ></i>
              )}
            </div>
          </div>

          {this.state.showIndex == index ? (
            <div className="announcement-content section-content-normal">
              <Autoresponse
                delete={deleteResponder}
                responder={responder}
                index={index}
                updateResponse={this.updateResponse}
              />
            </div>
          ) : null}
        </div>
      );
    });

    return responses;
  };

  enableModule = (value) => {
    const responder = { ...this.props.responder };

    responder.enabled = value;
    this.props.setBotModule({
      module: "responder",
      module_data: responder,
    });
  };

  commandUpdate = (value) => {
    var responder = { ...this.props.responder };
    responder.autoresponder = value;
    this.props.setBotModule({
      module: "responder",
      module_data: responder,
    });
  };

  render() {
    if (this.props.responder != undefined) {
      return (
        <Dashboard>
          <ModuleHeader
            youtube="https://www.youtube.com/watch?v=2auT7E8D0qw"
            tutorial="https://docs.botghost.com/server-management/autoresponder"
            docs="https://docs.botghost.com/server-management/autoresponder"
            title="Autoresponder"
            description="Automatically respond to trigger words and phrases"
            icon="autoresponder.png"
            enabledValue={this.props.responder.enabled}
            change={this.enableModule}
          ></ModuleHeader>

          {this.state.showPremiumBanner == true ? (
            <PremiumModal
              closeModal={() => {
                this.setState({ showPremiumBanner: false });
              }}
            />
          ) : null}
          <section>
            <SectionHeader title="Cooldown" pretitle="Autoresponder" />
            <div className="section-content-normal">
              <div class="mb-15">
                <h3 style={{ marginBottom: "0px" }}>Channel Cooldown </h3>
                <div className="section-content-header">
                  How long the bot should wait before it responds to another
                  trigger in seconds. Cooldown is channel based
                </div>
              </div>

              <div class="long-input">
                <label>Cooldown</label>
                <input
                  value={this.state.cooldown}
                  placeholder="10"
                  min="10"
                  type="number"
                  onChange={(e) => {
                    this.setState({ cooldown: e.target.value });
                  }}
                ></input>
              </div>

              <button
                className="btn btn-red mt-15"
                onClick={() => {
                  this.setCooldown();
                }}
              >
                Set Cooldown
              </button>
            </div>
          </section>

          <section>
            <SectionHeader title="Add Response" pretitle="Autoresponder" />

            <div className="section-content-normal">
              <div class="mb-15">
                <h3 style={{ marginBottom: "0px", fontSize: "26px" }}>
                  Add Autoresponder Message
                </h3>
                <div className="section-content-header">
                  Add an Autoresponder message to your bot. Your bot will
                  automatically reply if the trigger conditions are met, without
                  any prefix
                </div>
              </div>
              <span style={{ color: "red", fontWeight: "600" }}>
                {this.state.error}
              </span>

              <hr class="slashcommand-hr" />
              <Autoresponse
                index={"new"}
                updateResponse={this.updateResponse}
              />
            </div>
          </section>

          <section>
            <SectionHeader
              title={`Active Autoresponses ${this.props.premium != true
                ? `${this.props.responder.messages.length}/3`
                : ""
                }`}
              pretitle="Autoresponder"
            />
            {this.renderAutoResponses()}
          </section>

          <section>
            <SectionHeader title="Commands" pretitle="Autoresponder" />
            <Command
              update={this.commandUpdate}
              options={this.props.responder.autoresponder}
              module="responder"
              name={"autoresponder"}
              description={
                "Edit the autoresponder module from within your server"
              }
              showToggle={true}
              toggleValue={this.props.responder.autoresponder.enabled}
              showPrefix={true}
              slashOnly={true}
              slash={true}
              extraDescriptions={[
                // {key:"description_main",name:"Main Command"},
                { key: "desciption_add", name: "Add Responder Subcommand" },
                {
                  key: "desciption_delete",
                  name: "Remove Responder Subcommand",
                },
                { key: "desciption_list", name: "List Responders Subcommand" },
                { key: "description_edit", name: "Edit Responder Subcommand" },
              ]}
            ></Command>
          </section>
        </Dashboard>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  responder: state.data.bot.commands.responder,
  premium: state.data.premium,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoResponder);
