import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../elements/DocsLink';
import RefreshBotData from '../elements/RefreshBotData';

export class InputTitle extends Component {
    render() {
        return (
            <div className='flex items-center'>
                <h3 style={{ marginBottom: "0px" }}>{this.props.settings.title} {this.props.refresh ? <RefreshBotData /> : null}</h3>
                {
                    this.props.settings.help ? <DocsLink location={this.props.settings.help} /> : null
                }
                {
                    this.props.settings.premium && !this.props.premium ?
                        <div className='ml-2 premium-badge'>
                            <span className="">Premium</span>
                        </div> : null
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    premium: state.data.premium
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InputTitle);