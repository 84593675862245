import React, { Component } from 'react';
import { connect } from 'react-redux';

import { saveBot, setBotSettings, revertChanges, cleanUpActionTrees } from "../../../actions";
import server from "../../../api/server";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import history from '../../../history';
import PremiumModal from './PremiumModal';



export class SaveRequired extends Component {
    constructor(props) {
        super(props);

        this.state = {
            disabled: false,
        };
    }

    save = async () => {
        this.setState({ disabled: true });

        var cleanedBot = cleanUpActionTrees(this.props.bot);

        var obj = {
            bot: cleanedBot
        };

        if (this.props.collab) {
            obj.collab = true;
        } else {
            obj.collab = false;
        }
        // if (this.props.cocreate == true) {
        //     obj.cocreate = true;
        //     var host_user_id = null;
        //     this.props.bots.forEach(bot => {
        //         if (bot.id == this.props.bot.id && bot.cocreator == true) {
        //             host_user_id = bot.host_user_id;
        //         }
        //     });
        //     obj.host_user_id = host_user_id;
        // } else {
        //     obj.cocreate = false;
        // }
        const response = await server.post("/dashboard/save", obj).catch(e => {

        });

        if (response == undefined) {
            this.setState({ disabled: false });
            reason = "Network Error: Please try again in a few seconds.";
            toast.dark(`Save Failed: ${reason}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (response.data.updated == true) {
            this.props.saveBot({ ...this.props.bot });

        } else {
            var reason = "";
            if (response.data.message) {
                reason = response.data.message;
            }
            else if (response.data.reason == "validation") {
                reason = "Your bot's token could not be validated. Please double check you have pasted your bots Token and not its id.";
                const token = this.props.pre_save.token;
                history.push("/dashboard/settings");
                this.props.setBotSettings({ value: token, key: "token" });
            } else if (response.data.reason == "token") {
                reason = "You did not provide your bots token. Please read the Getting Started section under Dashboard to get your Bot's Token.";
                const token = this.props.pre_save.token;
                history.push("/dashboard/settings");
                this.props.setBotSettings({ value: token, key: "token" });
            } else if (response.data.reason == "exists") {
                reason = "A bot with this token already exists on your account.";
                const token = this.props.pre_save.token;
                history.push("/dashboard/settings");
                this.props.setBotSettings({ value: token, key: "token" });
            } else if (response.data.reason == "cocreator_disabled") {
                reason = "The author of this bot has disabled cocreator.";
            } else if (response.data.reason == "other_account") {
                reason = "A bot with this token already exists on another account.";
            }
            toast.dark(`Save Failed: ${reason}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        this.setState({ disabled: false });
    };
    render() {
        return (
            <div className="save-container">
                <ToastContainer />
                <div className="save">
                    <span>Please save your changes!</span>
                    <div className="save-buttons m-buttons">
                        {/* <button disabled={this.state.disabled} className="btn mr-2" onClick={(e) => { this.props.revertChanges(this.props.pre_save); }}>Cancel</button> */}
                        <button disabled={this.state.disabled} className="btn" onClick={(e) => { this.save(); }}>{this.state.disabled ? "Saving..." : "Save Changes"}</button>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
    pre_save: state.data.pre_save,
    cocreate: state.data.co_create,
    bots: state.data.bots,
    premium: state.data.premium,
    collab: state.data.collab
});

const mapDispatchToProps = {
    saveBot,
    setBotSettings,
    revertChanges
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveRequired);
