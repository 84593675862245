import React, { Component } from 'react';
import { connect } from 'react-redux';
import MarketItem from '../elements/MarketItem';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { MARKET_CATEGORIES } from '../../../variables';
import server from '../../../api/server';
export class MarketCommands extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: "Most Popular",
            category: "all",
            searchTerm: "",
            commands: [],
            searching: false,
            subCategory: "All",
            searchResults: []
        };
    }
    renderCategoryMenu = () => {
        var menus = [<Menu.Item>
            <span
                href="#"
                className=
                'text-gray-700 block px-4 py-2 text-sm hover:text-red'
                onClick={() => {
                    this.setState({ category: "all", subCategory: "All" });
                }}
            >
                All
            </span>
        </Menu.Item>];

        MARKET_CATEGORIES.forEach(category => {
            menus.push(
                <Menu.Item>
                    <span
                        href="#"
                        className=
                        'text-gray-700 block px-4 py-2 text-sm hover:text-red'
                        onClick={() => {
                            this.setState({ category: category.value, subCategory: category.label });
                        }}
                    >
                        {category.label}
                    </span>
                </Menu.Item>
            );
        });

        return menus;
    };

    componentDidMount() {
        this.getCategory("all");

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.category !== prevState.category) {
            this.getCategory(this.state.category);
            // this.props.getCommands(this.state.menu);
        }
    }

    getCategory = async (category) => {
        this.setState({ searching: true, searchTerm: "" });
        const response = await server.get("/market/category/" + category);
        if (response && response.data) {
            // console.log(response.data, 'RESPONSE DATA    ');
            var commands = [];
            if (this.props.mode == "command") {
                commands = [...response.data.data.top.commands];
            } else {
                commands = [...response.data.data.top.events];
            }
            var items = [];
            commands.forEach(item => {
                if (item.type == this.props.mode || !item.type && this.props.mode == 'command') {
                    items.push(item);
                }
            });
            this.setState({ commands: commands, searching: false });
        }
    };

    renderItems = () => {
        var items = [];

        var commands = this.state.searchResults.length > 0 && this.state.searchTerm != "" ? this.state.searchResults : this.state.commands;
        commands.forEach(item => {
            items.push(
                <MarketItem item={item} />
            );
        });

        return items;
    };

    search = async () => {
        this.setState({ searchResults: [], searching: true });
        var response = await server.post("/market/search", {
            search: this.state.searchTerm,
            command: true
        });

        if (response.data && response.data.success == true) {
            var results = [...response.data.results];
            // console.log(results, 'RESULTS');
            var commands = [];
            results.forEach(result => {
                var item = result.item;
                if (item.type == this.props.mode || !item.type && this.props.mode == 'command') {
                    commands.push(item);
                }
            });
            this.setState({ searchResults: commands, searching: false });
            // this.setState({ commands: response.data.results, searching: false });
        }
    };

    handleSearch(e) {
        const value = e.target.value;
        this.setState({ searchTerm: value, showDropDown: true });
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.search();
        }, 1000);
    }

    render() {


        return (
            <div className="command-builder-objects pt-2 ml-[-20px]">
                <nav className='flex  mb-3 px-2 content-center justify-center'>

                    <div className='long-input w-full'>
                        <input onChange={(e) => this.handleSearch(e)} value={this.state.searchTerm} type="text" placeholder="Search"></input>
                    </div>



                    <div className='block  flex px-2'>
                        <Menu as="div" className=" relative inline-block text-left">
                            <div>
                                <Menu.Button className="inline-flex w-full justify-center rounded-md border text-white bg-lightGray px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                    {this.state.subCategory}
                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-lightGray text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                        {this.renderCategoryMenu()}

                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </nav>
                <div className=''>
                    {this.state.commands.length == 0 || this.state.searching ?
                        <div className="loader-container">
                            <div className="lds-ripple">
                                <div></div>
                                <div></div>
                            </div>
                        </div> : this.renderItems()}

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    mode: state.builder.mode
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MarketCommands);