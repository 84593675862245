import React, { Component } from 'react'
import { connect } from 'react-redux'

export class Accordion extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             collapased:true
        }
    }
    
    render() {
        return (
            <div className="card mt-15">
                <div className="card-header" onClick={(e) =>{this.setState({collapased:!this.state.collapased})}}>
                    <h2>{this.props.title}</h2>
                </div>

                {this.state.collapased == false ?
                <div className="collpase">
                <div className="card-body">
                    {this.props.description}              
                </div>
            </div>:null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Accordion)
