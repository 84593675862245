import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";

import Dashboard from "../Dashboard";

import { setBotModule } from "../../../actions";
import { ModuleDisabled } from "../elements/ModuleDisabled";

export class MemeCreator extends Component {
  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  commandUpdate = (value, type) => { };

  moduleEnable = (value) => {
    var meme_creator = { ...this.props.meme_creator };
    meme_creator.enabled = value;
    this.props.setBotModule({
      module: "meme_creator",
      module_data: meme_creator,
    });
  };

  render() {
    return (
      <Dashboard>
        <ModuleHeader
          tutorial="https://docs.botghost.com/fun-modules/meme-creator"
          docs="https://docs.botghost.com/fun-modules/meme-creator"
          enabledValue={this.props.meme_creator.enabled}
          change={this.moduleEnable}
          description="Create memes using templates from ImgFlip"
          title="Meme Creator"
          icon="memecreator.png"
        ></ModuleHeader>
        <ModuleDisabled disabled={!this.props.meme_creator.enabled}>
          <section>
            <SectionHeader
              title="Commands"
              pretitle="Meme Creator"
            ></SectionHeader>

            <div className="row">
              <div className="col-xl-6">
                <Command
                  update={this.commandUpdate}
                  options={this.props.meme_creator.memes}
                  showToggle={false}
                  defaultTrigger="get_memes"
                  slashOnly={true}
                  module="meme_creator"
                  showPrefix={true}
                  name="memes"
                  description="Returns a list of templates from ImgFlip and shows the template ID and captions required
"
                ></Command>
              </div>

              <div className="col-xl-6">
                <Command
                  update={this.commandUpdate}
                  options={this.props.meme_creator.create_meme}
                  showToggle={false}
                  module="meme_creator"
                  showPrefix={true}
                  name="create_meme"
                  slashOnly={true}
                  description="Creates a meme from a supplied template ID and caption list"
                ></Command>
              </div>
            </div>
          </section>
        </ModuleDisabled>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  meme_creator: state.data.bot.commands.meme_creator,
  beta: state.data.beta,
});

const mapDispatchToProps = {
  setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(MemeCreator);
