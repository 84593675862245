import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactGA from 'react-ga';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faVideo } from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

export class VideoLink extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {

    }

    docsClick = () => {
        ReactGA.initialize('UA-122665050-1');
        ReactGA.event({
            category: "Documentation Click",
            label: this.props.location,
            action: "New Dashboard"
        });
    };

    render() {
        return (
            <a className="text-red ml-1 hover:text-red hover:no-underline hover:opacity-75" onClick={() => { this.docsClick(); }} href={this.props.location} target="_blank"> <FontAwesomeIcon icon={faYoutube} /></a>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(VideoLink);
