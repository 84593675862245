import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocsLink from '../DocsLink'
import Select from '../Select'



import TextArea from "../TextArea"
import Toggle from "../Toggle"
import Embed_Action from './Embed_Action'
import PlainText_Action from './PlainText_Action'

import SlashCommandAction from '../SlashCommandAction'
import RefreshBotData from '../RefreshBotData'


const STYLES = [
    { value: "PRIMARY", label: "Blue" },
    { value: "SECONDARY", label: "Gray" },
    { value: "SUCCESS", label: "Green" },
    { value: "DANGER", label: "Red" },
    { value: "LINK", label: "Link" },
]

const responseTypes = [
    { value: "reply", label: "Reply to the command" },
    { value: "targeted", label: "Send the message to a specific text-channel" }
]
export class Button_Action extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }


    componentDidMount() {
        if (!("response_options" in this.props.data)) {
            this.props.update("response_options", {
                response: "",
                target: { reply: true }
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!("buttons" in this.props.data)) {
            this.props.update("buttons", [{
                label: "",
                style: "PRIMARY",
                actions: []
            },])
        }
    }

    updateResponse = (key, value) => {
        var response_options = { ...this.props.data.response_options };
        response_options[key] = value;
        this.props.update("response_options", response_options);
    }

    changeType = () => {
        var response_options = { ...this.props.data.response_options };
        if ("response" in this.props.data.response_options) {
            response_options = {
                embed: {

                },
                target: this.props.data.response_options.target
            }
        } else {
            response_options = {
                response: "",
                target: this.props.data.response_options.target
            }
        }
        this.props.update("response_options", response_options);

    }

    renderOptions = () => {
        var options = [
            // {value:"trigger",label:"The member who triggered the command"}
        ]
        this.props.options.forEach(option => {
            if (option.type == "channel") {
                options.push({ value: { variable: option.name }, label: `Channel Option: ${option.name}` })

            }

        })
        return options;
    }

    renderButtons = () => {
        var buttons = [];
        this.props.data.buttons.forEach((button, index) => {

            const renderBackgroundColor = () => {
                if (button.style == "PRIMARY") {
                    return "#5865f2"
                } else if (button.style == "SECONDARY" || button.style == "LINK") {
                    return "#4f545c"
                } else if (button.style == "SUCCESS") {
                    return "#3ba55c"
                } else if (button.style == "DANGER") {
                    return "#ed4245"
                } else {
                    return "#4f545c"
                }
            }

            const updateType = (type) => {
                var buttons = [...this.props.data.buttons];
                buttons[index].style = type;
                this.props.update("buttons", buttons)
            }

            const changeLabel = (value) => {
                var buttons = [...this.props.data.buttons];
                buttons[index].label = value;
                this.props.update("buttons", buttons)
            }

            const changeLink = (value) => {
                var buttons = [...this.props.data.buttons];
                buttons[index].URL = value;
                this.props.update("buttons", buttons)
            }

            const addAction = () => {
                var buttons = [...this.props.data.buttons];
                buttons[index].actions.push({
                    type: "plain_text",
                    response: ""
                })
                this.props.update("buttons", buttons)
            }

            const updateAction = (key, value, actionIndex) => {
                var buttons = [...this.props.data.buttons];
                var actions = [...buttons[index].actions]
                var action = { ...actions[actionIndex] }
                action[key] = value;
                actions[actionIndex] = action;
                buttons[index].actions = actions;

                // var actions = [...this.state.actions];
                // var action = { ...this.state.actions[index] }
                // action[key] = value;
                // actions[index] = action
                // this.setState({ actions: actions })
                this.props.update("buttons", buttons)

            }

            const deleteAction = (delIndex) => {
                // console.log("deleteaction")
                var buttons = [...this.props.data.buttons];
                buttons[index].actions.splice(delIndex, 1)
                this.props.update("buttons", buttons)
            }

            const renderActions = () => {
                var actions = []
                button.actions.forEach((action, index) => {
                    actions.push(
                        <SlashCommandAction
                            data={action}
                            index={index}
                            button={true}
                            edit={this.props.edit}
                            update={updateAction}
                            delete={deleteAction}
                            options={this.props.options}
                            name={this.props.name}

                        />
                    )
                })
                return actions;

            }

            const toggleButtonOpen = () => {

            }

            const deleteButton = () => {
                var buttons = [...this.props.data.buttons];
                buttons.splice(index, 1)
                this.props.update("buttons", buttons)
            }

            buttons.push(


                <div className="announcement slashcommand-option-container" style={{ backgroundColor: renderBackgroundColor() }}>
                    <div className="announcement-header" onClick={(e) => { this.state.openButton != index ? this.setState({ openButton: index }) : this.setState({ openButton: null }) }}>
                        <div>
                            <h3 className="mb-0">{button.label || `Button ${index + 1}`}</h3>
                            {/* <div className="section-content-header">Button Desc</div> */}
                        </div>
                        <div style={{ marginLeft: "auto" }}>
                            {this.state.openButton == index ? <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i> : <i style={{ fontSize: "20px" }} class="bi bi-caret-down-fill"></i>}
                        </div>
                    </div>

                    <div>
                        {this.state.openButton == index ?
                            <div className="slashcommand-option">



                                <div className="mb-15 slash-action">
                                    <h4>Button Text <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions"} /></h4>
                                    <span>The text of this button.</span>
                                    <div className="long-input">
                                        <label>Text</label>
                                        <input required type="text" value={button.label} onChange={(e) => { changeLabel(e.target.value) }}></input>
                                    </div>
                                </div>

                                <div className="mb-15 slash-action">

                                    <h4>Button Style <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions"} /></h4>
                                    <span>The style and color of this button.</span>
                                    <div className="mt-15">
                                        <Select value={button.style} onChange={updateType} type="" options={STYLES}></Select>
                                    </div>
                                </div>

                                {button.style == "LINK" ?
                                    <div className="mb-15 slash-action">
                                        <h4>Button Link <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions"} /></h4>
                                        <span>The link of this button</span>
                                        <div className="long-input">
                                            <label>Text</label>
                                            <input required type="text" value={button.URL} onChange={(e) => { changeLink(e.target.value) }}></input>
                                        </div>
                                    </div>
                                    : null}

                                <hr class="slashcommand-hr" />

                                <div>
                                    <div className="mb-15">

                                        <div className="justify-space-between">
                                            <div>
                                                <h3 style={{ fontSize: "27px" }}>Actions <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions"} /></h3>
                                                <div className="section-content-header mb-15">Add actions to to be executed when someone clicks your button.</div>
                                            </div>
                                            <button className="btn btn-red btn-800 hithere" type="button" onClick={(e) => { addAction() }}>Add Button Action</button>

                                        </div>
                                        <hr class="slashcommand-hr" />
                                        <div className="slashcommand-options">
                                            {renderActions()}
                                        </div>
                                        <button disabled={this.props.data.buttons.length == 1} className="btn btn-red btn-800" style={{ backgroundColor: "rgba(255, 107, 134, 0.5)" }} type="button" onClick={(e) => { deleteButton() }}>Delete Button</button>


                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>



                </div>
            )
        })
        return buttons;
    }

    addButton = () => {
        var buttons = [...this.props.data.buttons];
        buttons.push({
            label: "",
            style: "PRIMARY",
            actions: []
        })

        this.props.update("buttons", buttons)
    }

    changeReponseType = (value) => {
        // console.log(value)
        var response_options = { ...this.props.data.response_options };
        if (value == "reply") {
            response_options.target = {
                reply: true
            }
        } else {
            response_options.target = {
                id: ""
            }
        }
        this.props.update("response_options", response_options)
    }

    selectUpdate = (value) => {
        // console.log("selected ")
        value = JSON.parse(value);
        // console.log(value)
        var response_options = { ...this.props.data.response_options }
        response_options.target = value;
        this.props.update("response_options", response_options)
    }

    render() {
        if (this.props.data.response_options != undefined && this.props.data.buttons != undefined) {
            return (
                <div>


                    <div className="mb-15">
                        <h3 style={{ marginBottom: "0px" }}>Message </h3>
                        <div className="section-content-header mb-15">The message to attach the buttons to.</div>

                        <div style={{ width: "100%", marginRight: "20px" }}>

                            <div className="justify-space-between">
                                <h3 style={{ marginBottom: "0px" }}>Message <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions/plain-text-response"}></DocsLink></h3>

                                <div className="section-content-title justify-space-between">
                                    <span style={{ marginInlineEnd: "12px", marginBottom: "0px" }}>{this.props.data.response_options.embed != undefined ? "Embed" : "Plain Text"}</span>
                                    <Toggle value={this.props.data.response_options.response != undefined ? false : true} update={(value) => { this.changeType() }} type="type"></Toggle>
                                </div>
                            </div>

                            {'response' in this.props.data.response_options ? <PlainText_Action buttons={this.props.data.buttons} options={this.props.options} data={this.props.data.response_options} update={this.updateResponse} /> : <Embed_Action buttons={this.props.data.buttons} options={this.props.options} data={this.props.data.response_options} update={this.updateResponse} />}
                            <hr className="slashcommand-hr"></hr>

                            {/* {this.state.role_message_type ? <Embed value={this.state.embed} update={this.embedUpdate}></Embed> : <TextArea variableEditor={true} value={this.state.response} onChange={this.responseOnChange}></TextArea>} */}
                        </div>
                    </div>

                    <div className="mb-15 slash-action">
                        <h3 style={{ marginBottom: "0px" }}>Response type </h3>
                        <div className="section-content-header mb-15">How your bot should send your button message.</div>
                        <Select onChange={(value) => { this.changeReponseType(value) }} value={"reply" in this.props.data.response_options.target ? "reply" : "targeted"} options={responseTypes} />
                    </div>

                    {"reply" in this.props.data.response_options.target ?
                        null :
                        <div className="mb-15 slash-action">
                            <h4>Channel <RefreshBotData /><DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions"} /></h4>
                            <span>The channel to post the message in.</span>
                            <div className="mt-15">
                                <Select slash_options={this.renderOptions()} value={JSON.stringify(this.props.data.target)} onChange={this.selectUpdate} type="channel" ></Select>
                            </div>
                        </div>}
                    <hr className="slashcommand-hr"></hr>

                    <div className="mb-15">
                        <div className="justify-space-between">
                            <div>
                                <h3 style={{ marginBottom: "0px" }}>Buttons </h3>
                                <div className="section-content-header mb-15">Attach Buttons to your message</div>

                            </div>
                            <button disabled={this.props.data.buttons.length >= 5} className="btn btn-red" type="button" onClick={(e) => { this.addButton() }}>Add Button</button>

                        </div>
                        <div>
                            {this.renderButtons()}
                        </div>
                        {/* <div>
                            <button disabled={this.props.data.buttons.length >=5} className="btn btn-red" type="button" onClick={(e) =>{this.addButton()}}>Add Button</button>
                        </div> */}
                        <hr className="slashcommand-hr"></hr>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Button_Action)
