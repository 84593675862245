import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setBotModule } from "../../../actions";

export class EmbedBuilderDelete extends Component {

    constructor(props) {
        super(props);
        this.state = {
            embedSelect: "",
            newName: ""
        };
    }

    modalBackgroundClick = (e) => {
        // console.log("MODAL BACKGROUND CLICK")
        if (e.currentTarget == e.target) {
            this.props.closeModal(true)
        }
    }

    componentDidMount() {
        // console.log("PREMIUM MODAL MOUNTED")
    }

    renderEmbeds = () => {
        // console.log(this.props.embeds);
        return this.props.embeds.map((embeds, index) => {
            return (<>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "80%", borderBottom: "1px solid #161618", padding: "5px" }}>
                    <p style={{ margin: 0, padding: 0 }}>{embeds.name}</p>
                    <button className='btn btn-red' onClick={() => {
                        this.props.deleteEmbed(embeds.id);
                        this.props.closeModal();
                    }}>Delete</button>
                </div>

            </>
            )
        })
    }

    render() {
        return (
            <div>
                <div class={`modal fade show modal-background`} onClick={(e) => { this.modalBackgroundClick(e) }} style={{ display: "block" }} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div class="modal-dialog premium-modal-dialog" role="document">
                        <div class="modal-content premium-modal" style={{ backgroundColor: "#f8f8fb" }}>
                            <div class="modal-body command-edit-body premium-modal-body">
                                <div className="premium-modal-content" style={{ width: "100%" }}>
                                    <h2 style={{ textAlign: "center", fontSize: "28px" }}>Delete Embeds</h2>
                                    {this.renderEmbeds()}
                                    <button className="btn btn-gray mt-15" onClick={() => { this.props.closeModal() }}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
    embeds: state.data.bot.commands.embeds,
    data: state.data,
    beta: state.data.beta,
})

const mapDispatchToProps = {
    setBotModule,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmbedBuilderDelete)
