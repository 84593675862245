import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import RefreshIFTTT from '../../elements/RefreshIFTTT';
import { updateElementData } from '../../../../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import TextArea from '../../elements/TextArea';
import VariableTextInput from '../VariableTextInput';


export class IFTTTAction extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data:
                    {
                        type: "ifttt_action",
                        trigger_id: "",
                        variables: {
                            variable1: "",
                            variable2: "",
                            variable3: "",
                            variable4: "",
                            variable5: "",
                            variable6: "",
                            variable7: "",
                            variable8: "",
                            variable9: "",
                            variable10: "",
                        },
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "ifttt_action",
                        trigger_id: "",
                        variables: {
                            variable1: "",
                            variable2: "",
                            variable3: "",
                            variable4: "",
                            variable5: "",
                            variable6: "",
                            variable7: "",
                            variable8: "",
                            variable9: "",
                            variable10: "",

                        },
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

            if ((this.props.data.trigger_id != "") && this.props.data.validated != true) {
                this.updateData("validated", true);
            } else if (this.props.data.validated == true && !this.props.data.trigger_id) {
                this.updateData("validated", false);

            }
        }
    }

    updateData = (key, value) => {

        // console.log("VALUE", value);
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    renderVariables = () => {
        var vars = [];


        Object.keys(this.props.data.variables).forEach((key, i) => {
            vars.push(
                <>
                    <h4 style={{ marginBottom: "0px", marginTop: "15px" }}>Variable {i + 1}<DocsLink location={"https://docs.botghost.com/custom-commands-and-events/ifttt#action-block-triggered-ifttt-triggers"}></DocsLink></h4>
                    <div className="section-content-header mb-15">An optional value for Variable {i + 1}. You can use this value as an ingredient in IFTTT Actions.</div>
                    <VariableTextInput slash_options={this.props.options} label={`Variable ${i + 1}`} placeholder={`Variable ${i + 1}`} value={this.props.data.variables[key]} onChange={(value) => {
                        var variables = { ...this.props.data.variables };
                        variables[key] = value;
                        this.updateData("variables", variables);
                    }} />
                    {/* <div class="long-input mb-15">
                        <label>Variable {i + 1}</label>
                        <input onChange={(e) => {

                            var variables = { ...this.props.data.variables };
                            variables[key] = e.target.value;
                            // console.log(variables, key);
                            this.updateData("variables", variables);
                        }} type="text" value={this.props.data.variables[key]} />
                    </div> */}
                </>
            );
        });


        return vars;
    };


    render() {
        if (this.props.data != undefined) {

            if (!this.props.ifttt_linked) {
                return (
                    <div>
                        <div className="mb-15">
                            <h4>Execute an IFTTT Trigger</h4>
                            <p>Execute an IFTTT trigger and any associated applets. You muts first link your BotGhost account to IFTTT and create an Applet with an <strong className='text-red'>Action Block Triggered</strong> trigger.</p>
                        </div>

                        <hr class="slash-hr" style={{ borderTop: "1px solid rgb(173, 181, 189)" }} />

                        <div class="text-center">
                            <h3 style={{ marginBottom: "0px" }} className="font-bold text-white text-xl">IFTTT BotGhost Link {!this.props.ifttt_linked ? <FontAwesomeIcon icon={faExclamationCircle} color="#f45142" /> : <FontAwesomeIcon icon={faCheckCircle} color="green" />}</h3>

                            <div className="section-content-header mb-2">
                                Click the button below to link your IFTTT account to your BotGhost account. Once you have linked your account, you will be able to create IFTTT Applets that trigger custom events in your bot, and you will be able to trigger IFTTT Applets from your bot.
                            </div>
                            <div className='flex justify-center' >
                                <div className=''>
                                    <RefreshIFTTT />
                                    <a href={process.env.REACT_APP_IFTTT_LINK} target="_blank" className='btn btn-red ml-3 !bg-[#51c8f3]'>Link IFTTT</a>
                                </div>
                            </div>
                        </div>


                    </div>
                );
            } else {
                return (
                    <div>
                        <div className="mb-15">
                            <h4>Execute an IFTTT Trigger</h4>
                            <p>Execute an IFTTT trigger and any associated applets. You muts first link your BotGhost account to IFTTT and create an Applet with an <strong className='text-red'>Action Block Triggered</strong> trigger.</p>
                        </div>

                        <h4 style={{ marginBottom: "0px" }}>Bot ID<DocsLink location={"https://docs.botghost.com/custom-commands-and-events/ifttt#action-block-triggered-ifttt-triggers"}></DocsLink></h4>
                        <div className="section-content-header mb-15">Your Bots ID. You can copy this here and pass it along to your IFTTT trigger.</div>

                        {/* <VariableTextInput slash_options={this.props.options} label={`Bot ID`} placeholder={`Bot ID`} value={this.props.bot_id} onChange={(value) => {
                            this.updateData("bot_id", value);
                        }} required={true} /> */}
                        <div class="long-input mb-15">
                            <label>Bot ID</label>
                            <input disabled className={``} onChange={(e) => {

                            }} type="text" value={this.props.bot_id} />
                        </div>

                        <h4 style={{ marginBottom: "0px", marginTop: "15px" }}>Action ID<DocsLink location={"https://docs.botghost.com/custom-commands-and-events/ifttt#action-block-triggered-ifttt-triggers"}></DocsLink></h4>
                        <div className="section-content-header mb-15">The ID you set in your IFTTT Applet. Any IFTTT Trigger with this Action ID will be executed.</div>

                        {/* <VariableTextInput slash_options={this.props.options} label={`Action ID`} placeholder={`Action ID`} value={this.props.data.trigger_id} onChange={(value) => {

                            this.updateData("trigger_id", value);
                        }} required={true} /> */}
                        <div class="long-input mb-15">
                            <label>Action ID</label>
                            <input className={`${this.props.data.trigger_id == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                                // if (!isNaN(e.target.value) && e.target.value <= 850) {
                                this.updateData("trigger_id", e.target.value);
                                // }
                            }} type="text" value={this.props.data.trigger_id} />
                        </div>


                        <hr class="slash-hr" style={{ borderTop: "1px solid rgb(173, 181, 189)" }} />

                        <div>
                            <h4>Variables</h4>
                            <p>Set variables to be passed to your IFTTT Applet as <strong className='text-red'>Ingredients</strong>. You can use these variables in your IFTTT Applet.</p>


                            {this.renderVariables()}
                        </div>



                    </div>
                );
            }

        } else {
            return null;
        }
    }
};

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    ifttt_linked: state.data.user.ifttt_linked,
    bot_id: state.data.bot.id
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(IFTTTAction);
