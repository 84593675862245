import React, { Component } from 'react';
import { connect } from 'react-redux';
import deepcopy from 'deepcopy';

import history from "../../../history";
import "../styles/requestbuilder.css";
import Toggle from './Toggle';
import ReactJSON from 'react-json-view';
import axios from 'axios';
import _ from "lodash";
import DocsLink from './DocsLink';
import update from "immutability-helper";
import ToolInput from './ToolInput';
import ToolTextArea from './ToolTextArea';
import DiscordEmbed from './DiscordEmbed';
import DiscordEmbedPreview from './DiscordEmbedPreview';


export class MessageBuilder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            messageValidated: false
        };
    }


    modalBackgroundClick = (e) => {

        if (e.currentTarget == e.target) {
            this.props.closeModal(e);
        }
    };

    renderEmbeds = () => {
        var embeds = [];


        this.props.message.embeds.forEach((embed, index) => {
            embeds.push(
                <DiscordEmbed

                    index={index} open={this.state.openEmbed === index} copyEmbed={() => {
                        if (this.state.embeds.length >= 10) return;
                        var embedCopy = deepcopy(embed);
                        var embeds = [...this.props.message.embeds];
                        embeds.push(embedCopy);
                        var message = deepcopy(this.props.message);
                        message.embeds = embeds;
                        this.props.updateMessage(message);
                    }} onChange={(updatedEmbed) => {
                        var embeds = [...this.props.message.embeds];
                        embeds[index] = updatedEmbed;
                        var message = deepcopy(this.props.message);
                        message.embeds = embeds;
                        this.props.updateMessage(message);
                    }} openClose={() => {
                        if (this.state.openEmbed === index) {
                            this.setState({ openEmbed: null });
                        } else {
                            this.setState({ openEmbed: index });
                        }
                    }} delete={() => {
                        var embeds = [...this.props.message.embeds];

                        embeds.splice(index, 1);

                        var message = deepcopy(this.props.message);
                        message.embeds = embeds;
                        console.log(message, 'AFTER');
                        this.props.updateMessage(message);
                        if (!this.props.embedValidated && this.props.embedErrorIndex === index) {
                            this.props.updateEmbedValidation(true, index);
                        }
                    }} embed={embed} key={index}></DiscordEmbed>
            );
        });

        return embeds;
    };



    render() {
        return (
            <main className='h-full min-w-full bg-darkGray'>
                <div className="absolute top-0 right-0 pt-3 pr-3">
                    <button
                        type="button"
                        className="rounded-md bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-offset-2"
                        onClick={() => {
                            this.props.closeModal();
                        }}
                    >
                        <span className="sr-only">Close</span>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className='container relative'>

                    <div className="text-center text-white flex justify-center flex-col mb-3">
                        <h1 className='text-4xl font-bold'>Message Builder</h1>
                        <p className='muted-text'>Craft powerful Discord messages with our intuitive Message Builder.</p>
                    </div>
                </div>

                <div className='grid grid-cols-1 px-2 md:grid-cols-2 md:!px-5 md:items-start'>

                    <section className='grid flex flex-col gap-y-2'>



                        {/* Content */}
                        <div>

                            <div className='mt-3'>
                                <ToolTextArea value={this.props.message.content} maxLength={2000} label=" Message Content" onChange={(value) => {
                                    var message = deepcopy(this.props.message);
                                    message.content = value;
                                    this.props.updateMessage(message);
                                }} />
                            </div>


                        </div>
                        <div className='border-t-[1px] border-[#adb5bd] mb-2 mt-3'></div>

                        {/* Embeds */}

                        <div>
                            <label htmlFor="email" className="block text-md font-bold leading-6 text-white">
                                Embeds <span className='muted-text opacity-50'>{this.props.message.embeds.length}/10</span>
                            </label>

                            <div>
                                {this.renderEmbeds()}
                            </div>

                            <div className='mt-3'>
                                <button className='btn btn-red mr-2 px-2 py-2 font-bold' onClick={() => {
                                    if (this.props.message.embeds.length >= 10) return;
                                    var newEmbed = {
                                        title: "",
                                        description: "",
                                        author: {
                                            name: "",
                                            url: "",
                                            icon_url: ""
                                        },
                                        color: "",
                                        footer: "",
                                        footerIcon: "",
                                        timestamp: "",
                                        image: {
                                            url: ""
                                        },
                                        thumbnail: {
                                            url: ""
                                        },
                                        fields: [],
                                        timestamp_variable: ""
                                    };

                                    var embeds = this.props.message.embeds;
                                    embeds.push(newEmbed);
                                    this.setState({ embeds: embeds });
                                    var message = deepcopy(this.props.message);
                                    message.embeds = embeds;
                                    this.props.updateMessage(message);
                                }}>Add Embed</button>
                                <button className='btn btn-gray px-2 py-2 font-bold' onClick={() => {
                                    this.setState({ embeds: [] });
                                    var embeds = [];
                                    var message = deepcopy(this.props.message);
                                    message.embeds = embeds;
                                    this.props.updateMessage(message);
                                }}>Clear Embeds</button>
                            </div>
                        </div>

                    </section>


                    <section className='md:ml-5 mt-[48px] md:sticky md:top-0'>

                        <DiscordEmbedPreview
                            content={this.props.message.content}
                            username={this.props.bot.name}
                            avatarURL={this.props.bot.img}
                            embeds={this.props.message.embeds}
                        />
                    </section>
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MessageBuilder);