import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData, setElements, setHighlightAction } from "../../../../actions";

import Embed from "../../elements/Embed";
import TextArea from "../../elements/TextArea";
import Select from "../../elements/Select";
import RefreshBotData from "../../elements/RefreshBotData";
import { addEdge, getOutgoers } from "react-flow-renderer";
import { EmojiAdder } from "./EmojiAdder";
import splitElements from "../splitElements";
import { checkIntegrationChild } from "../eventUtils";
import { renderResponseActionOptions } from "../builderUtils";
import VariableTextInput from "../VariableTextInput";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { successToast, errorToast, loadingToast } from "../toast.js";

const responseTypes = [
	{ value: "reply", label: "Reply to the command" },
	{ value: "targeted", label: "Send the message to a specific text-channel" },
	{ value: "trigger_channel", label: "Send the message to the channel the command was used in." }
];

const EPHEMERAL_OPTIONS = [
	{ value: "true", label: "Hide replies" },
	{ value: "false", label: "Show replies to everyone." }
];

export class SelectMenuAction extends Component {
	constructor(props) {
		super(props);

		this.state = {
			channel_type: "specific"
		};
	}
	componentDidMount() {
		if (this.props.data == undefined) {
			var target = { id: "" };
			if (this.props.components["Interaction"]) {
				target = { reply: true };
			} else if (this.props.components["Channel"]) {
				target = { trigger_channel: true };
			}
			this.props.updateElementData({
				data: {
					type: "select_menu",
					placeholder: "",
					ephemeral: false,
					response_options: {
						response: "",
						target: target,
						emojis: []
					},
					validated: false
				},
				id: this.props.id
			});

			this.addButton();
		}

		if (this.props.data && this.props.data.response_options.target && this.props.data.response_options.target.id) {
			if (this.props.data.response_options.target.id_variable) {
				this.setState({ channel_type: "id" });
			}
		}

		if (this.props.data && this.props.data.action_id && this.props.data.response_options.target && this.props.data.response_options.target.editId) {
			this.props.setHighlightAction(this.props.data.action_id);
		}
	}
	componentWillUnmount() {
		this.props.setHighlightAction(null);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data == undefined) {
			var target = { id: "" };
			if (this.props.components["Interaction"]) {
				target = { reply: true };
			} else if (this.props.components["Channel"]) {
				target = { trigger_channel: true };
			}
			this.props.updateElementData({
				data: {
					type: "select_menu",
					placeholder: "",
					ephemeral: false,
					response_options: {
						response: "",
						target: target,
						emojis: []
					},
					validated: false
				},
				id: this.props.id
			});
		} else {
			// VALIDATE
			if ((("response" in this.props.data.response_options && this.props.data.response_options.response != "") || ("embed" in this.props.data.response_options && "description" in this.props.data.response_options.embed && this.props.data.response_options.embed.description != "")) && this.checkResponseOption()) {
				if (this.props.data.validated == false) {
					this.updateData("validated", true);
				}
			} else if (this.props.data.validated == true) {
				this.updateData("validated", false);
			}
		}
	}

	checkResponseOption = () => {
		var pass = false;
		var target = { ...this.props.data.response_options.target };
		if ("reply" in target) {
			pass = true;
		} else if ("trigger_channel" in target) {
			pass = true;
		} else if ("dmself" in target) {
			pass = true;
		} else if ("user_option" in target && target.user_option != "") {
			pass = true;
		} else if ("user_id" in target && target.user_id != "") {
			pass = true;
		} else if ("id" in target && target.id != "") {
			pass = true;
		} else if ("variable" in target && target.variable != "") {
			pass = true;
		} else if ("editId" in target && target.editId != "") {
			pass = true;
		}

		return pass;
	};
	nameChange = (value) => {
		value = value.toLowerCase();
		value = value.substring(0, 32);
		value = value.replace(" ", "-");
		const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/gu);
		value = value.replace(regexForNonAlphaNum, "");
		this.updateData("name", value);
	};

	updateData = (key, value) => {
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	selectChange = (value) => {
		if (!value) return;
		this.props.setHighlightAction(value);
		var action = this.props.actions.find((action) => action.id == value);

		if (!("editId" in action.data.data)) {
			var data = { ...action.data.data };
			data.editId = `${Date.now()}`;
			this.props.updateElementData({
				data: data,
				id: value
			});
			action.data.data.editId = data.editId;
		}
		var id = action.data.data.editId;

		var action_data = { ...this.props.data };
		action_data.response_options.target.editId = id;
		action_data.action_id = value;
		this.props.updateElementData({
			data: action_data,
			id: this.props.id
		});
	};

	changeType = () => {
		var response_options = { ...this.props.data.response_options };
		if ("response" in this.props.data.response_options) {
			response_options = {
				embed: {},
				target: response_options.target,
				emojis: response_options.emojis || []
			};
		} else {
			response_options = {
				response: "",
				target: response_options.target,
				emojis: response_options.emojis || []
			};
		}
		this.updateData("response_options", response_options);
	};

	updateResponseOptions = (key, value) => {
		var response_options = { ...this.props.data.response_options };
		response_options[key] = value;
		this.updateData("response_options", response_options);
	};

	changeReponseType = (value) => {
		// console.log(value);
		var response_options = { ...this.props.data.response_options };
		if (value == "reply") {
			response_options.target = {
				reply: true
			};
		} else if (value == "trigger_channel") {
			response_options.target = {
				trigger_channel: true
			};
		} else if (value == "dmself") {
			response_options.target = {
				dmself: true
			};
		} else if (value == "user_option") {
			response_options.target = {
				user_option: ""
			};
		} else if (value == "user_id") {
			response_options.target = {
				user_id: ""
			};
		} else if (value == "editId") {
			response_options.target = {
				editId: ""
			};
			if (this.props.data.action_id) {
				var action = this.props.actions.find((action) => action.id == this.props.data.action_id);
				if (action) {
					if (!("editId" in action.data.data)) {
						var data = { ...action.data.data };
						data.editId = `${Date.now()}`;
						this.props.updateElementData({
							data: data,
							id: this.props.data.action_id
						});
					}
					response_options.target.editId = action.data.data.editId;
					this.props.setHighlightAction(this.props.data.action_id);
				}
			}
		} else {
			response_options.target = {
				id: ""
			};
		}
		this.updateData("response_options", response_options);
	};

	renderOptions = () => {
		var options = [
			// {value:"trigger",label:"The member who triggered the command"}
		];
		this.props.options.forEach((option) => {
			if (option.type == "channel") {
				options.push({ value: { variable: option.name }, label: `Channel Option: ${option.name}` });
			}
		});
		return options;
	};

	selectUpdate = (value) => {
		value = JSON.parse(value);
		var response_options = { ...this.props.data.response_options };
		response_options.target = value;
		this.updateData("response_options", response_options);
	};

	addButton = () => {
		var elements = [...this.props.elements];
		var element = this.props.elements.find((element) => element.id == this.props.selected);
		var existingMenuOptions = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
		var id = `select_menu_option_${elements.length + 1}_${Math.random().toString(36).substr(2, 9)}`;
		if (existingMenuOptions.length >= 25) {
			return;
		}
		var lastChild = existingMenuOptions[existingMenuOptions.length - 1];

		var width = 0;
		var position = { x: element.position.x, y: element.position.y + 100 };
		if (lastChild) {
			var docuElement = document.getElementById(lastChild.id);
			width = docuElement.clientWidth;
			position = { x: lastChild.position.x + width + 20, y: lastChild.position.y };
		}

		elements.push({
			id: id,
			type: "selectMenuOption",
			data: {
				node_options: {},
				data: {
					label: `Option ${existingMenuOptions.length + 1}`,
					description: "Option Description"
				}
			},
			// target:"3",
			draggable: true,
			position: position
		});
		var newEdges = addEdge(
			{
				source: element.id,
				target: id,
				type: "step",
				animated: false,
				arrowHeadType: "arrowclosed"
			},
			splitElements(elements).edges
		);
		newEdges.forEach((newEdge) => {
			var index = elements.findIndex((element) => element.id == newEdge.id);
			if (index == -1) {
				elements.push(newEdge);
			}
			// elements[index] = newNode;
		});
		this.props.setElements(elements);
	};

	renderResponseTypes = () => {
		var channel = "Send the message to the channel the command was used in.";
		if (this.props.mode == "event" && this.props.components["Channel"]) {
			channel = "The channel.";
		}
		var dm_user = "Direct message the user who triggered the command";
		if (this.props.mode == "event" && this.props.components["User"]) {
			dm_user = "The user";
		}
		const responseTypes = [
			{ value: "reply", label: "Reply to the command", disabled: this.props.components.Interaction ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true },
			{ value: "targeted", label: "Send the message to a specific text-channel" },
			{ value: "trigger_channel", label: channel, disabled: this.props.components.Channel ? false : checkIntegrationChild(this.props.elements, this.props.id) ? false : true }
		];

		responseTypes.push({ value: "editId", label: "Edit a message sent by another action" });

		responseTypes.push({
			value: "dmself",
			label: dm_user,
			disabled: !this.props.components["User"]
		});

		responseTypes.push({
			value: "user_option",
			label: "Direct message using a user option",
			disabled: !this.props.components["Interaction"]
		});

		responseTypes.push({
			value: "user_id",
			label: "Direct message using a user id"
		});

		return responseTypes;
	};

	renderResponseValue = () => {
		// "reply" in this.props.data.response_options.target ? "reply" : "trigger_channel" in this.props.data.response_options.target ? "trigger_channel" : "targeted"
		if ("reply" in this.props.data.response_options.target) {
			return "reply";
		} else if ("trigger_channel" in this.props.data.response_options.target) {
			return "trigger_channel";
		} else if ("id" in this.props.data.response_options.target || "variable" in this.props.data.response_options.target) {
			return "targeted";
		} else if ("user_option" in this.props.data.response_options.target) {
			return "user_option";
		} else if ("user_id" in this.props.data.response_options.target) {
			return "user_id";
		} else if ("dmself" in this.props.data.response_options.target) {
			return "dmself";
		} else if ("editId" in this.props.data.response_options.target) {
			return "editId";
		}
	};

	render() {
		if (this.props.data != undefined) {
			return (
				<div>
					<div className="mb-15">
						<h4>Send a Message with a Select Menu.</h4>
						<p>Sends a text or embed message to a channel with a Select Menu. Each option in the Select Menu can have its own attached Actions. Attach Actions to Select Menu options to be run when the option is selected.</p>
					</div>

					<div className="justify-space-between mb-15">
						<h4 style={{ marginBottom: "0px" }}>
							Message <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-select-menu#message"}></DocsLink>
						</h4>

						<div className="justify-space-between">
							<p style={{ marginInlineEnd: "12px", marginBottom: "0px" }}>{this.props.data.response_options.embed != undefined ? "Embed" : "Plain Text"}</p>
							<Toggle
								value={this.props.data.response_options.response != undefined ? false : true}
								update={(value) => {
									this.changeType();
								}}
								type="type"
							></Toggle>
						</div>
					</div>
					{"response" in this.props.data.response_options ? (
						<TextArea
							slash={true}
							slash_options={this.props.options}
							variableEditor={true}
							extra_classNames={`${this.props.data.response_options.response == "" && this.props.saveFailed ? "command-required" : ""}`}
							value={this.props.data.response_options.response}
							onChange={(value) => {
								this.updateResponseOptions("response", value);
							}}
						/>
					) : (
						<Embed
							key={this.props.id}
							variableEditor={true}
							slash={true}
							options={this.props.options}
							commandSaveFailed={this.props.saveFailed}
							update={(value) => {
								this.updateResponseOptions("embed", value);
							}}
							value={this.props.data.response_options.embed}
						/>
					)}

					<div className="mt-15 mb-15 slash-action">
						<h4 style={{ marginBottom: "0px" }}>
							Response type <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-select-menu#response-type" />{" "}
						</h4>
						<div className="section-content-header mb-15">How your bot should send your button message.</div>
						<Select
							onChange={(value) => {
								this.changeReponseType(value);
							}}
							value={this.renderResponseValue()}
							options={this.renderResponseTypes()}
						/>
					</div>

					{"id" in this.props.data.response_options.target || "variable" in this.props.data.response_options.target ? (
						<>
							<div className="mb-15 slash-action">
								<h4>Channel Type</h4>
								<span>The channel to post the message in.</span>
								<div className="mt-15">
									<Select
										value={this.state.channel_type}
										options={[
											{ value: "specific", label: "Specific Channel" },
											{ value: "id", label: "Channel ID or Variable" }
										]}
										onChange={(value) => {
											this.setState({ channel_type: value });
										}}
									></Select>
								</div>
							</div>
							{this.state.channel_type == "specific" ? (
								<div className="mb-15 slash-action">
									<h4>
										Specific Channel <RefreshBotData />
									</h4>
									<span>Select a specific channel to post the message in.</span>
									<div className="mt-15">
										<Select slash_options={this.renderOptions()} value={JSON.stringify(this.props.data.response_options.target)} onChange={this.selectUpdate} type="channel"></Select>
									</div>
								</div>
							) : (
								<div className="mb-15 slash-action">
									<h4>Channel ID or Variable</h4>
									<span>Enter a Channel ID or Variable. Custom variables can be used.</span>
									<VariableTextInput
										slash_options={this.props.options}
										value={this.props.data.response_options.target.id}
										onChange={(value) => {
											var response_options = { ...this.props.data.response_options };
											response_options.target.id = value;
											response_options.target.id_variable = true;
											this.updateData("response_options", response_options);
										}}
									/>
									{/* <div className="long-input mt-15">
                                            <label>ID or Variable</label>
                                            <input required type="text" value={this.props.data.response_options.target.id} onChange={(e) => {
                                                var value = e.target.value;
                                                var response_options = { ...this.props.data.response_options };

                                                response_options.target.id = value;
                                                response_options.target.id_variable = true;

                                                this.updateData("response_options", response_options);

                                            }}></input>
                                        </div> */}
								</div>
							)}
						</>
					) : null}

					{"user_option" in this.props.data.response_options.target ? (
						<div className="mb-15 slash-action">
							<h4>User Option </h4>
							<span>The user option to direct message.</span>
							<VariableTextInput
								slash_options={this.props.options}
								value={this.props.data.response_options.target.user_option}
								onChange={(value) => {
									var response_options = { ...this.props.data.response_options };
									response_options.target.user_option = value;
									this.updateData("response_options", response_options);
								}}
							/>

							{/* <div className="long-input mt-15 mb-15">
                                <label>Option</label>
                                <input
                                    required
                                    type={"text"}
                                    value={this.props.data.response_options.target.user_option}

                                    placeholder={"{option_user}"}
                                    onChange={(e) => {
                                        var data = { ...this.props.data };
                                        data.response_options.target.user_option = e.target.value;
                                        this.props.updateElementData(
                                            {
                                                data: data,
                                                id: this.props.id
                                            }
                                        );
                                    }}
                                ></input>
                            </div> */}
						</div>
					) : null}

					{this.props.data.response_options.target && "editId" in this.props.data.response_options.target ? (
						<>
							<div className="mb-15">
								<h4 style={{ marginBottom: "0px" }}>Response Action</h4>
								<div className="section-content-header mb-15">Select one of the response actions in this command to edit the message sent by that action.</div>
								<Select saveFailed={this.props.data.action_id == "" && this.props.saveFailed} value={this.props.data.action_id} options={renderResponseActionOptions(this.props.actions, this.props.selected)} onChange={this.selectChange} />
							</div>

							<div>
								<h4 style={{ marginBottom: "0px" }}>Keep Options</h4>
								<div className="section-content-header mb-15">Keep the menu options of the original message.</div>
								<Select
									saveFailed={this.props.data.action_id == "" && this.props.saveFailed}
									value={this.props.data.response_options.target.keep_components == true ? true : false}
									options={[
										{
											value: true,
											label: "Yes"
										},
										{
											value: false,
											label: "No"
										}
									]}
									onChange={(val) => {
										if (val == "true") {
											val = true;
										} else {
											val = false;
										}

										var data = { ...this.props.data };
										data.response_options.target.keep_components = val;
										this.props.updateElementData({
											data: data,
											id: this.props.id
										});
									}}
								/>
							</div>
						</>
					) : null}

					{"user_id" in this.props.data.response_options.target ? (
						<div className="mb-15 slash-action">
							<h4>User ID </h4>
							<span>The user id to direct message.</span>

							<VariableTextInput
								slash_options={this.props.options}
								value={this.props.data.response_options.target.user_id}
								onChange={(value) => {
									var response_options = { ...this.props.data.response_options };
									response_options.target.user_id = value;
									this.updateData("response_options", response_options);
								}}
							/>
							{/* <div className="long-input mt-15 mb-15">
                                <label>Id</label>
                                <input
                                    required
                                    type={"text"}
                                    value={this.props.data.response_options.target.user_id}

                                    placeholder={"136327647792726016"}
                                    onChange={(e) => {
                                        var data = { ...this.props.data };
                                        data.response_options.target.user_id = e.target.value;
                                        this.props.updateElementData(
                                            {
                                                data: data,
                                                id: this.props.id
                                            }
                                        );
                                    }}
                                ></input>
                            </div> */}
						</div>
					) : null}

					<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>

					<div className="mt-15 mb-15">
						<h4 style={{ marginBottom: "0px" }}>
							Reactions <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-select-menu#reactions"}></DocsLink>
						</h4>
						<div className="section-content-header mb-15">Reactions to add to the message.</div>
						<EmojiAdder
							emojis={this.props.data.response_options.emojis || []}
							update={(newEmojis) => {
								var data = { ...this.props.data };
								data.response_options.emojis = newEmojis;
								this.props.updateElementData({
									data: data,
									id: this.props.id
								});
							}}
						/>
					</div>

					<hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />
					<div className="mb-15">
						<h4 style={{ marginBottom: "0px" }}>Placeholder </h4>
						<div className="section-content-header mb-15">The optional placeholder text of the menu</div>
						<VariableTextInput
							slash_options={this.props.options}
							value={this.props.data.placeholder}
							onChange={(value) => {
								this.updateData("placeholder", value);
							}}
						/>
						{/* <div className="long-input">
                            <label>Placeholder</label>
                            <input maxLength={150} required type="text" value={this.props.data.placeholder} onChange={(e) => {
                                var value = e.target.value;
                                this.updateData("placeholder", value);

                            }}></input>
                        </div> */}
					</div>

					<div className="mb-15">
						<div className="slash-action">
							<h4>
								Hide replies from everyone but user of the select menu. <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-select-menu#hide-menu-replies"} />
							</h4>
							<p>Hides the replies of the bot from everyone except the person who used the select menu. Will not work for targeted responses and DM actions.</p>
						</div>
						<div>
							<Select
								value={this.props.data.ephemeral == true ? "true" : "false"}
								options={EPHEMERAL_OPTIONS}
								onChange={(value) => {
									if (value == "true") {
										this.updateData("ephemeral", true);
									} else {
										this.updateData("ephemeral", false);
									}
								}}
							/>
						</div>
					</div>

					<hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />

					<div className="mb-15">
						<h4 className="mb-2">
							Menu Options <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-select-menu#menu-option" />
						</h4>
						<button
							onClick={() => {
								this.addButton();
							}}
							className="btn btn-red add-button"
						>
							Add Menu Option
						</button>
					</div>

					<hr className="slashcommand-hr"></hr>
					<div className="mt-15">
						<h4 style={{ marginBottom: "0px" }}>Optional Variable</h4>
						<div className="section-content-header mb-15">An optional variable to return the message id of this reply. You can use this variable in other blocks.</div>
						<div class="long-input mb-15">
							<label>Variable</label>
							<input
								className={`${this.props.data.variable == "" && this.props.saveFailed ? "command-required" : ""}`}
								onChange={(e) => {
									// if (!isNaN(e.target.value) && e.target.value <= 850) {
									var value = e.target.value;
									value = value.toLowerCase();
									value = value.substring(0, 32);
									value = value.replace(" ", "_");
									const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/gu);
									value = value.replace(regexForNonAlphaNum, "");

									this.updateData("variable", value);
								}}
								type="text"
								value={this.props.data.variable ? this.props.data.variable : ""}
							/>
						</div>

						{this.props.data.variable ? (
							<div>
								<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>

								<h4>Message Id Variable</h4>
								<p
									className="mb-15"
									style={{
										color: "#fff",
										opacity: "0.7",
										fontWeight: 500
									}}
								>
									You can use the variable <span style={{ color: "#f45142", opacity: "1 !important" }}>{`{${this.props.data.variable}}`}</span>
									<FontAwesomeIcon
										icon={faClipboard}
										fixedWidth
										className="text-sm hover:text-red hover:cursor-pointer"
										onClick={() => {
											navigator.clipboard.writeText(`{${this.props.data.variable}}`);
											successToast("Copied variable to clipboard");
										}}
									/>{" "}
									in other blocks.
								</p>
							</div>
						) : null}
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	saveFailed: state.builder.saveFailed,
	components: state.builder.components,
	mode: state.builder.mode,
	beta: state.data.beta
});
const mapDispatchToProps = {
	updateElementData,
	setElements,
	setHighlightAction
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectMenuAction);
