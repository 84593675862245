import React, { Component } from 'react'
import { connect } from 'react-redux'
import  NotificationItem  from './NotificationItem'
import ReactGA from 'react-ga';

import {setNotifications} from "../../../actions"


export class Notifications extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             notifications:[]
        }
    }
    

    renderNotifications = () =>{
        const notifications = []

        this.props.notifications.forEach(notification =>{
            
            notifications.push(
                <NotificationItem
                text={notification.text}
                image={notification.image}
                redirect={notification.redirect}
                unread={!this.state.notifications.includes(notification.id)}
                onClick={notification.onClick}
                ></NotificationItem>
                
            )
        })

        return notifications;
    }

    componentDidMount() {
        ReactGA.initialize('UA-122665050-1');
        ReactGA.event({
            category:"Open Notifications",
            label:"Open Notifications",
            action:"New Dashboard"
        })

        const user = {...this.props.user};
        if(user.notifications == undefined){
            user.notifications = []
        }
        this.setState({notifications:user.notifications})

        this.props.notifications.forEach(notification =>{
            if(!user.notifications.includes(notification.id)){
                user.notifications.push(notification.id)
            }
        })


        this.props.setNotifications(user.notifications)


    }
    render() {
        return (
            <div className="dropdown-box">
                <div className="dropdown-box-header">
                    <p>Notifications</p>
                </div>

                <div className="dropdown-box-list">
                    {this.renderNotifications()}
                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    notifications:state.notifications,
    user:state.data.user
})

const mapDispatchToProps = {
    setNotifications
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
