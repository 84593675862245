import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from '../Dashboard';
import ModuleHeader from '../elements/ModuleHeader';
import SectionHeader from '../elements/SectionHeader';
import WebhookItem from '../elements/WebhookItem';
import server from '../../../api/server';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { setBotModule } from '../../../actions';
import DocsLink from '../elements/DocsLink';
import { TIMEZONES } from "../../../variables/index";
import Select from '../elements/Select';
import moment from 'moment-timezone';
import TimedEventItem from '../elements/TimedEventItem';
import RefreshBotData from '../elements/RefreshBotData';


export class TimedEvents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            key: "1234",
            showKey: false,
            buttonsDisabled: false,
            showIndex: null

        };
    }

    componentDidMount() {
        // var timezone = moment.tz.guess();
        // console.log(timezone, 'TIMEZONE');
        // if (this.props.timed_events.timezone_default) {
        //     var timed_events = { ...this.props.timed_events };
        //     timed_events.timezone = timezone;
        //     timed_events.timezone_default = false;
        //     this.props.setBotModule({
        //         module: "timed_events",
        //         module_data: timed_events,
        //     });
        // }
        // console.log(moment().tz.guess(), 'GUESS');
    }


    regenerate = async () => {
        this.setState({ buttonsDisabled: true });
        var response = await server.post("/dashboard/generateAPIKey", {
            bot_id: this.props.bot.id,
            cocreate: this.props.cocreate
        });
        if (response.data.success) {
            this.setState({ key: response.data.key, showKey: true });
        }

        this.setState({ buttonsDisabled: false });
    };

    showKey = async () => {
        this.setState({ buttonsDisabled: true });
        if (this.state.showKey) {
            this.setState({ showKey: false, key: "" });
        } else {
            var response = await server.post("/dashboard/fetchAPIKey", {
                bot_id: this.props.bot.id,
                cocreate: this.props.cocreate
            }
            );
            if (response.data.success) {
                this.setState({ key: response.data.key, showKey: true });
            }

        }

        this.setState({ buttonsDisabled: false });
    };


    renderEvents = () => {
        var timed_events = [];

        this.props.timed_events.events.forEach((timed_event, index) => {
            timed_events.push(
                <div>
                    <div className="announcement">
                        <div
                            className="announcement-header"
                            onClick={(e) => {
                                this.setState({
                                    showIndex: this.state.showIndex == index ? null : index,
                                });
                            }}
                        >
                            <div className="w-full">
                                <h3 className="mb-0">{timed_event.name} </h3>
                                <div className="section-content-header overflow-clip w-full">{timed_event.type == "interval" ? "Interval" : "Schedule"}</div>
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                                {this.state.showIndex == index ? (
                                    <i style={{ fontSize: "20px" }} class="bi bi-caret-up-fill"></i>
                                ) : (
                                    <i
                                        style={{ fontSize: "20px" }}
                                        class="bi bi-caret-down-fill"
                                    ></i>
                                )}
                            </div>
                        </div>

                        {this.state.showIndex == index ? (
                            <div className="announcement-content section-content-normal">
                                <TimedEventItem index={index} timed_event={timed_event} onChange={(updatedEvent) => {
                                    var timed_events = { ...this.props.timed_events };
                                    timed_events.events[index] = updatedEvent;
                                    this.props.setBotModule({
                                        module: "timed_events",
                                        module_data: timed_events,
                                    });
                                    // close
                                    this.setState({
                                        showIndex: null,
                                    });
                                }} delete={(index) => {
                                    var timed_events = { ...this.props.timed_events };
                                    timed_events.events.splice(index, 1);

                                    this.props.setBotModule({
                                        module: "timed_events",
                                        module_data: timed_events,
                                    });

                                    // close
                                    this.setState({
                                        showIndex: null,
                                    });

                                }} />
                            </div>
                        ) : null}
                    </div>

                </div>




            );

        });

        return timed_events;
    };
    render() {
        return (
            <Dashboard>
                <ModuleHeader description={'Trigger custom events through schedules and intervals'}
                    title={"Timed Events"}
                    showDocs={true}
                    docs="https://docs.botghost.com/events/timed-events"
                    showEnabled={false}
                    tutorial="https://docs.botghost.com/events/timed-events"
                    icon={"timedevents.png"} />



                <section>
                    <SectionHeader title="Timezone" pretitle="Timed Events" />


                    <div className="p-[28px] bg-[#2f3136] mt-[25px] rounded-[12px]">
                        <div class="">
                            <h3 style={{ marginBottom: "0px" }} className="font-bold text-white">Timezone <DocsLink location="https://docs.botghost.com/events/timed-events#api-key" /></h3>

                            <div className="section-content-header mb-2">
                                The timezone of your bot for timed events. This is used to determine when to trigger scheduled timed events.
                            </div>

                            <div>
                                <Select value={this.props.timed_events.timezone} onChange={(value) => {
                                    // console.log(value, 'VALUE');
                                    var timed_events = { ...this.props.timed_events };
                                    timed_events.timezone = value;
                                    this.props.setBotModule({
                                        module: "timed_events",
                                        module_data: timed_events,
                                    });
                                }} options={TIMEZONES} />
                            </div>

                        </div>


                    </div>
                </section>

                <section>
                    <SectionHeader title="Default Server" pretitle="Timed Events" />


                    <div className="p-[28px] bg-[#2f3136] mt-[25px] rounded-[12px]">
                        <div class="">
                            <h3 style={{ marginBottom: "0px" }} className="font-bold text-white">Default Server <RefreshBotData /></h3>

                            <p className="section-content-header block mb-2">
                                The default server for all timed events. Use the variable <strong className='text-red'>{`{DEFAULT_SERVER}`}</strong> in the server id field to use this server.
                            </p>

                            <div>
                                <Select type="guilds" value={this.props.timed_events.default_server} onChange={(value) => {
                                    console.log(value, 'VALUE');
                                    var timed_events = { ...this.props.timed_events };
                                    timed_events.default_server = value;
                                    this.props.setBotModule({
                                        module: "timed_events",
                                        module_data: timed_events,
                                    });
                                }} />
                            </div>

                        </div>


                    </div>
                </section>

                <section>

                    <SectionHeader title="New Timed Event" pretitle="Timed Events" />

                    <div>
                        <div className="section-content-normal">
                            <div class="mb-15">
                                <h3 style={{ marginBottom: "0px", fontSize: "26px" }}>
                                    Add a new timed event <DocsLink location="https://docs.botghost.com/events/timed-events#1.-creating-a-webhook" />
                                </h3>
                                <p className="text-white opacity-75 mb-2">
                                    Create a new timed event to be triggered on a schedule or interval. Scheduled events are triggered at a specific time, while interval events are triggered every X amount of seconds, minutes, hours, or days.
                                </p>

                                <p className="text-white opacity-75">Once you have added a timed event, create a <Link className="text-red font-bold" to="/dashboard/customevents" >custom event</Link> to be triggered when this timed event is executed.</p>
                            </div>


                            <TimedEventItem index="new" onChange={(timed_event, index) => {
                                var timed_events = { ...this.props.timed_events };
                                timed_events.events.push(timed_event);
                                this.props.setBotModule({
                                    module: "timed_events",
                                    module_data: timed_events,
                                });
                                // var webhooks = { ...this.props.webhooks };

                                // webhooks.webhooks.push({
                                //     id: webhook.id,
                                //     name: webhook.name,
                                // });

                                // this.props.setBotModule({
                                //     module: "webhooks",
                                //     module_data: webhooks,
                                // });
                            }} />
                        </div>



                        {/* {this.props.value.length > 0 ? <section>
                            <SectionHeader title={`${this.props.settings.active_title} ${this.props.settings.slot_limit ? `${this.props.value.length}/${this.renderSlotLimit()}` : ""}`} pretitle={this.props.settings.active_pretitle} />
                            {this.renderSlots()}
                        </section> : null} */}
                    </div>
                </section>


                <section>
                    <SectionHeader title="Active Timed Events" pretitle="Timed Events"></SectionHeader>


                    <div>
                        {this.renderEvents()}
                    </div>
                </section>
            </Dashboard >
        );
    }
};

const mapStateToProps = (state) => ({
    bot: state.data.bot,
    cocreate: state.data.co_create,
    timed_events: state.data.bot.commands.timed_events,
    webhooks: state.data.bot.commands.webhooks
});

const mapDispatchToProps = {
    setBotModule
};

export default connect(mapStateToProps, mapDispatchToProps)(TimedEvents);