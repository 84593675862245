import React, { Component } from 'react'
import { connect } from 'react-redux'

export class BetaBanner extends Component {
    render() {
        return (
                <div className="beta-banner alert alert-danger" onClick={(e) =>{window.location.replace("https://botghost.com/dashboard/")}}>
                    <h4 className="alert-heading">Dashboard Beta</h4>
                    <p>This dashboard is currently in beta. Some features may not work as expected. To return to the normal dashboard click on this alert.</p>
                </div>

        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(BetaBanner)
