import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../Dashboard";
import ModuleHeader from "../elements/ModuleHeader";
import SectionHeader from "../elements/SectionHeader";
import server from "../../../api/server";

export class Server_FreeHosting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      error: "",
    };
  }

  serverClaim = async () => {
    this.setState({ disabled: true, error: "" });
    var response = await server.post("/dashboard/server_upgrade", {
      bot_id: this.props.bot.id,
    });
    if (response && response.data.success == true) {
      window.location.reload();
    } else if (response.data.success == false) {
      if (response.data.reason == "not_joined") {
        this.setState({
          error:
            "Please join the BotGhost discord server by clicking the Join Server button below",
        });
      } else if (response.data.reason == "claimed") {
        this.setState({ error: "You have already claimed this bonus" });
      } else if (response.data.reason == "premium") {
        this.setState({
          error: "The bot you are currently editing already has premium",
        });
      }
    }

    this.setState({ disabled: false });
  };

  render() {
    return (
      <Dashboard>
        <ModuleHeader
          showEnabled={false}
          icon="discord.png"
          title="Join the Discord Server"
          description="Join the official BotGhost Discord Server to claim 3 days free BotGhost Premium"
        ></ModuleHeader>

        <section>
          <SectionHeader
            title="How to claim Free BotGhost Premium"
            pretitle="Free Premium"
          ></SectionHeader>
          <div className="section-content-normal">
            <span style={{ color: "red", fontWeight: "600" }}>
              {this.state.error}
            </span>
            <div className="mb-15">
              <h3 style={{ marginBottom: "0px" }}>Steps</h3>
              <div class="section-content-header">
                <p>
                  Join the{" "}
                  <a
                    href="https://discord.com/invite/9UPM6S4xyA"
                    target="_blank"
                  >
                    BotGhost Official Discord Server
                  </a>
                  . Once you have joined the discord server, click the Claim 3
                  Days of BotGhost Premium button below
                </p>
              </div>
            </div>
            <div class="m-buttons">
              <a
                href="https://discord.com/invite/botghost"
                target="_blank"
                className="mr-15 btn btn-gray m-ml-0 m-mr-0"
              >
                Join Server
              </a>
              <button
                disabled={
                  this.state.disabled ||
                  this.props.premium == true ||
                  this.props.user.server_claimed == true
                }
                onClick={() => {
                  this.serverClaim();
                }}
                style={{ marginLeft: "15px" }}
                className="btn btn-red m-ml-0 m-mr-0"
              >
                Claim 3 Days of BotGhost Premium
              </button>
            </div>
          </div>
        </section>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.data.user,
  bot: state.data.bot,
  premium: state.data.premium,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Server_FreeHosting);
