import React, { Component } from 'react';
import { connect } from 'react-redux';

export class ShopItem extends Component {
    render() {
        return (
            <div className="shop-item" onClick={() =>{
                this.props.click()
            }}>
                <div className='shop-item-img'>
                    <img src={this.props.img || process.env.PUBLIC_URL + "/images/icons/shopitem.png"} />

                </div>
                <div className='shop-item-description'>
                    <span>{this.props.name}</span>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShopItem);
