import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

const bold = /\*\*(.*?)\*\*|\*\*(.*?)\*\*/gim;
const italics = /\*(.*?)\*|_(.*?)_/gim;
const underline = /__(.*?)__/gim;
const strikethrough = /~~(.*?)~~/gim;
const spoiler = /\|\|(.*?)\|\|/gim;
const code = /`(.*?)`/gim;
const codeBlock = /```(\w+)?\n([\s\S]*?)```/gim;
const quote = /^> (.+)$/gim;
const multiLineQuote = /^>>> (.+)$/gim; // Added regex for multi-line quotes
const heading = /^(#{1,3}) (.+)$/gim;

// Updated regex patterns for Discord-style mentions
const channelMention = /<#(\d+)>/g;
const userMention = /<@(\w+)>/g;
const roleMention = /<@&(\d+)>/g;
const timestamp = /t:(\d+)(?::([tTdDfFR]))?/g;
const customEmoji = /:(\w+):/g;

const lineBreak = /\n/g;
const link = /\[(.*?)\]\((.*?)\)/g;
const subtext = /-# (.+)$/gim; // Added regex for subtext

export class MarkdownRender extends Component {

    escapeHtml = (text) => {
        const map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
        };
        return text.replace(/[&<>"']/g, (m) => map[m]);
    };

    parseMarkdown = (markdownText) => {
        const htmlText = markdownText
            .replace(bold, '<b>$1$2</b>')
            .replace(italics, '<i>$1$2</i>')
            .replace(underline, '<u>$1</u>')
            .replace(strikethrough, ' $1 ')
            .replace(spoiler, '<span class="spoiler bg-gray  px-1 rounded">$1</span>')
            .replace(codeBlock, '<pre class="bg-darkGray text-white p-2 rounded-md"><code class="language-$1">$2</code></pre>')
            .replace(code, '<code class="bg-darkGray text-white p-1 rounded">$1</code>')
            .replace(multiLineQuote, '<blockquote class="multi-line border-l-4 border-grayText pl-4">$1</blockquote>')
            .replace(quote, '<blockquote class="border-l-4 border-grayText pl-4 ">$1</blockquote>')
            .replace(heading, (match, hLevel, text) => {
                const sizeClass = hLevel.length === 1 ? 'text-3xl font-bold' : hLevel.length === 2 ? 'text-2xl font-bold' : 'text-xl font-bold';
                return `<span class="${sizeClass}">${text}</span>`;
            })
            .replace(link, "<a href='$2' class='embed-link text-blue'>$1</a>")
            .replace(channelMention, (match, id) => `<span class="bg-[#5865F2] text-white px-1 rounded-md">#${id}</span>`)
            .replace(userMention, (match, username) => `<span class="bg-[#5865F2] text-white px-1 rounded-md">@${username}</span>`)
            .replace(roleMention, (match, id) => `<span class="bg-[#5865F2] text-white px-1 rounded-md">@&${id}</span>`)
            .replace(timestamp, (match, timestamp, format) => {
                let formattedTime;
                try {
                    const time = moment.unix(timestamp);
                    switch (format) {
                        case 't': formattedTime = time.format('LT'); break;
                        case 'T': formattedTime = time.format('LTS'); break;
                        case 'd': formattedTime = time.format('L'); break;
                        case 'D': formattedTime = time.format('LL'); break;
                        case 'f': formattedTime = time.format('LLL'); break;
                        case 'F': formattedTime = time.format('LLLL'); break;
                        case 'R': formattedTime = time.fromNow(); break;
                        default: formattedTime = time.format('LLLL'); break;
                    }
                } catch (e) {
                    formattedTime = 'Invalid timestamp';
                }
                return `<span class="timestamp text-gray-500">${formattedTime}</span>`;
            })
            .replace(customEmoji, (match, name) => `<span class="emoji">${name}</span>`)
            .replace(subtext, '<small class="text-xs text-muted">$1</small>')
            .split(lineBreak).map(line => `<p>${line}</p>`).join('');

        return htmlText.trim() || markdownText;
    };

    render() {
        return (
            <div className="markdown-content">
                {ReactHtmlParser(this.parseMarkdown(this.props.text))}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MarkdownRender);