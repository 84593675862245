import React, { Component } from "react";
import { connect } from "react-redux";

import SectionHeader from "../elements/SectionHeader";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";

import { setBotModule } from "../../../actions";

import Dashboard from "../Dashboard";
import PremiumRedirect from "../elements/PremiumRedirect";
import PremiumSection from "../elements/PremiumSection";

export class ActiveDevBadge extends Component {
    commandUpdate = (value) => {
        var utils = { ...this.props.utils };
        utils.weather = value;
        this.props.setBotModule({
            module: "utils",
            module_data: utils,
        });
    };

    enableChange = (value) => {
        var utils = { ...this.props.utils };
        utils.weather.enabled = value;
        this.props.setBotModule({
            module: "utils",
            module_data: utils,
        });
    };

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    render() {
        return (
            <Dashboard>
                <PremiumRedirect></PremiumRedirect>
                <ModuleHeader
                    docs="https://botghost.com/community/guides/active-developer-badge/"
                    tutorial="https://botghost.com/community/guides/active-developer-badge/"
                    description="Get the Active Developer Badge without coding"
                    title="Active Developer Badge"
                    icon="activedevbadge.png"
                    showEnabled={false}
                ></ModuleHeader>

                <section>
                    <SectionHeader title="Active Developer Badge" pretitle="Active Developer Badge" />



                    <div className="section-content">
                        <div>
                            <h3>Enable Active Developer Badge Command</h3>
                            <div className="section-content-header">
                                Enable or Disable this toggle to add the /activedevbadge command to your bot. Running this command will fulfill the requirements for the Active Developer Badge.
                            </div>
                        </div>

                        <div style={{ marginLeft: "auto" }}>
                            <Toggle
                                value={this.props.bot.commands.activeDevBadge.enabled}
                                update={(value) => {
                                    var activeDevBadge = { ...this.props.bot.commands.activeDevBadge };
                                    activeDevBadge.enabled = value;
                                    this.props.setBotModule({
                                        module: "activeDevBadge",
                                        module_data: activeDevBadge
                                    });
                                    // console.log(value);
                                }}
                            ></Toggle>
                        </div>
                    </div>

                </section>
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    utils: state.data.bot.commands.utils,
    beta: state.data.beta,
    bot: state.data.bot
});

const mapDispatchToProps = {
    setBotModule,
};
export default connect(mapStateToProps, mapDispatchToProps)(ActiveDevBadge);
