import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';

import TextArea from '../../elements/TextArea';
import VariableTextInput from '../VariableTextInput';


export class WaitAction extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "wait_action",
                        seconds: 5,
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "wait_action",
                        seconds: 5,
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

            if ((this.props.data.seconds != "") && this.props.data.validated != true) {
                this.updateData("validated", true);
            } else if (this.props.data.validated == true && this.props.data.seconds == undefined) {
                this.updateData("validated", false);

            }
        }
    }

    updateData = (key, value) => {

        // console.log("VALUE", value);
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };


    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Wait before running the next action</h4>
                        <p>Wait a predefined amount of time before running the next action in the action chain.</p>
                    </div>

                    <h4 style={{ marginBottom: "0px" }}>Wait Time<DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/wait-before-running-another-action#wait-time"}></DocsLink></h4>
                    <div className="section-content-header mb-15">Wait time in seconds. Max 850 seconds.</div>
                    <VariableTextInput required={true} slash_options={this.props.options} label="Time" placeholder="5" value={this.props.data.seconds} onChange={(value) => {
                        this.updateData("seconds", value);
                    }} />
                    {/* <div class="long-input mb-15">
                        <label>Time</label>
                        <input className={`${this.props.data.seconds == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                            // if (!isNaN(e.target.value) && e.target.value <= 850) {
                            this.updateData("seconds", e.target.value);
                            // }
                        }} type="text" value={this.props.data.seconds} />
                    </div> */}


                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(WaitAction);
