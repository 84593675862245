import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { setBotModule } from "../../../actions";

export class GroupEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            group: {
                id: "",
                name: "",
                description: "",
                selectedGroup: "",
            },
            error: "",
            isEditing: false,
        };
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            group: {
                ...prevState.group,
                [name]: value,
            },
        }));
    };

    handleGroupSelect = (e) => {
        const selectedGroup = e.target.value;
        const selectedGroupData = this.props.groups.find(g => g.id === selectedGroup);
        this.setState({
            group: {
                id: selectedGroupData ? selectedGroupData.id : "",
                name: selectedGroupData ? selectedGroupData.name : "",
                description: selectedGroupData ? selectedGroupData.description : "",
                selectedGroup,
            },
            isEditing: !!selectedGroupData,
        });
    };

    handleSubmit = () => {
        const { id, name, description } = this.state.group;
        if (!name.trim()) {
            this.setState({ error: "Group name is required." });
            return;
        }

        const groups = [...this.props.groups];
        if (this.state.isEditing) {
            const index = groups.findIndex(g => g.id === id);
            if (index !== -1) {
                groups[index] = { id, name, description };
            }
        } else {
            const existingGroup = groups.find((g) => g.name === name);
            if (existingGroup) {
                this.setState({ error: "A group with this name already exists." });
                return;
            }
            const newId = Date.now().toString(); // Simple ID generation
            groups.push({ id: newId, name, description });
        }

        this.props.setBotModule({
            module: "commandGroups",
            module_data: groups,
        });

        this.setState({
            group: { id: "", name: "", description: "", selectedGroup: "" },
            error: "",
            isEditing: false,
        });
        this.props.close();
    };

    handleDelete = () => {
        const { id } = this.state.group;
        const groups = this.props.groups.filter(g => g.id !== id);

        this.props.setBotModule({
            module: "commandGroups",
            module_data: groups,
        });

        this.setState({
            group: { id: "", name: "", description: "", selectedGroup: "" },
            error: "",
            isEditing: false,
        });
        this.props.close();
    };

    render() {
        const { isEditing } = this.state;
        return (
            <Transition.Root show={this.props.showModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={this.props.close}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-[#0000008a] transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#2f3136] pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-3 sm:w-full sm:max-w-lg">
                                    <div className="absolute top-0 right-0 pt-4 pr-4">
                                        <button
                                            type="button"
                                            className="rounded-md bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-offset-2"
                                            onClick={this.props.close}
                                        >
                                            <span className="sr-only">Close</span>
                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                    <section className="mt-0">
                                        <div className="section-content-normal mt-0">
                                            <div className="mb-15">
                                                <h3 style={{ marginBottom: "0px", fontSize: "26px", color: "rgba(255, 255, 255)" }}>
                                                    {isEditing ? "Edit Group" : "New Group"}
                                                </h3>
                                                <div className="section-content-header" style={{ color: "rgba(255, 255, 255, 0.7)" }}>
                                                    {isEditing ? "Edit an existing group for your custom commands, events and variables." : "Create a new Group for your custom commands, events and variables."}
                                                </div>
                                            </div>
                                            <span style={{ color: "red", fontWeight: "600" }}>{this.state.error}</span>

                                            <hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>
                                            <div className="gap-y-4 grid">
                                                <div className="font-semibold flex flex-col">
                                                    <h4 className="text-white font-bold">Select Group</h4>
                                                    <span className="text-muted mb-2">Choose an existing group to edit</span>
                                                    <div className="long-input relative">
                                                        <label>{"Group"}</label>
                                                        <select
                                                            id="groupSelect"
                                                            name="groupSelect"
                                                            className={``}
                                                            value={this.state.group.selectedGroup}
                                                            onChange={this.handleGroupSelect}
                                                        >
                                                            <option value="">Select a group</option>
                                                            {this.props.groups.map((group) => (
                                                                <option key={group.id} value={group.id}>
                                                                    {group.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>
                                                <div className="font-semibold flex flex-col">
                                                    <h4 className="text-white font-bold">Group Name</h4>
                                                    <span className="text-muted mb-2">Enter a name for your group</span>
                                                    <div className="long-input relative">
                                                        <label>{"Name"}</label>
                                                        <input
                                                            maxLength={100}
                                                            placeholder={"My Group"}
                                                            className={``}
                                                            required
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            value={this.state.group.name}
                                                            onChange={this.handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="font-semibold flex flex-col">
                                                    <h4 className="text-white font-bold">Group Description</h4>
                                                    <span className="text-muted mb-2">Enter a description for your group</span>
                                                    <div className="long-input relative">
                                                        <label>{"Description"}</label>
                                                        <textarea
                                                            maxLength={255}
                                                            placeholder={"My group description"}
                                                            className={``}
                                                            required
                                                            name="description"
                                                            id="description"
                                                            rows="3"
                                                            value={this.state.group.description}
                                                            onChange={this.handleInputChange}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between mt-4">
                                                    <button
                                                        type="button"
                                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2  sm:w-auto sm:text-sm"
                                                        onClick={this.handleSubmit}
                                                    >
                                                        {isEditing ? "Update Group" : "Create Group"}
                                                    </button>
                                                    {isEditing && (
                                                        <button
                                                            type="button"
                                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2  sm:w-auto sm:text-sm"
                                                            onClick={this.handleDelete}
                                                        >
                                                            Delete Group
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        );
    }
}

const mapStateToProps = (state) => ({
    groups: state.data.bot.commands.commandGroups || [],
});

const mapDispatchToProps = {
    setBotModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupEditor);