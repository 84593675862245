import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocsLink from '../DocsLink'
import RefreshBotData from '../RefreshBotData'
import Select from '../Select'

import TextArea from "../TextArea"
import Toggle from "../Toggle"
import Embed_Action from './Embed_Action'
import PlainText_Action from './PlainText_Action'


export class Targeted_Response extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }


    componentDidMount() {
        if (!("response_options" in this.props.data)) {
            this.props.update("response_options", {
                response: "",
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!("target" in this.props.data)) {
            this.props.update("target", { id: "" })
        }
    }

    changeType = () => {
        var response_options = { ...this.props.data.response_options };
        if ("response" in this.props.data.response_options) {
            response_options = {
                embed: {

                }
            }
        } else {
            response_options = {
                response: ""
            }
        }
        this.props.update("response_options", response_options);

    }
    updateResponse = (key, value) => {
        var response_options = { ...this.props.data.response_options };
        response_options[key] = value;
        this.props.update("response_options", response_options);
    }

    renderOptions = () => {
        var options = [
            // {value:"trigger",label:"The member who triggered the command"}
        ]
        this.props.options.forEach(option => {
            if (option.type == "channel") {
                options.push({ value: { variable: option.name }, label: `Channel Option: ${option.name}` })

            }

        })
        return options;
    }

    selectUpdate = (value) => {
        // console.log("selected ")
        value = JSON.parse(value);
        // console.log(value)
        var target = { ...this.props.data.target }
        target = value;
        this.props.update("target", target)
    }

    render() {
        if (this.props.data.response_options != undefined && this.props.data.target != undefined) {
            return (
                <div className="mb-15">
                    <div>
                        <div className="mb-15">

                            <div style={{ width: "100%", marginRight: "20px" }}>

                                <div className="justify-space-between">
                                    <h3 style={{ marginBottom: "0px" }}>Message <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions/plain-text-response"}></DocsLink></h3>

                                    <div className="section-content-title justify-space-between">
                                        <span style={{ marginInlineEnd: "12px", marginBottom: "0px" }}>{this.props.data.response_options.embed != undefined ? "Embed" : "Plain Text"}</span>
                                        <Toggle value={this.props.data.response_options.response != undefined ? false : true} update={(value) => { this.changeType() }} type="type"></Toggle>
                                    </div>
                                </div>

                                {'response' in this.props.data.response_options ? <PlainText_Action options={this.props.options} data={this.props.data.response_options} update={this.updateResponse} /> : <Embed_Action options={this.props.options} data={this.props.data.response_options} update={this.updateResponse} />}
                                <hr className="slashcommand-hr"></hr>

                                {/* {this.state.role_message_type ? <Embed value={this.state.embed} update={this.embedUpdate}></Embed> : <TextArea variableEditor={true} value={this.state.response} onChange={this.responseOnChange}></TextArea>} */}
                            </div>
                        </div>

                        <div className="mb-15 slash-action">
                            <h4>Channel <RefreshBotData /><DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions"} /></h4>
                            <span>The channel to post the message in.</span>
                            <div className="mt-15">
                                <Select slash_options={this.renderOptions()} value={JSON.stringify(this.props.data.target)} onChange={this.selectUpdate} type="channel" ></Select>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Targeted_Response)
