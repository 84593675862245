import React, { Component } from "react";
import { connect } from "react-redux";
import CommandEditor from "./CommandEditor";
import CustomCommandEditor from "./CustomCommandEditor";

import { setBotModule } from "../../../actions"
import Toggle from "./Toggle";

class Command extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditor: false,
      openCC: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(prevState, this.state);
  }

  toggleChange = (value, type) => {
    // console.log(value);
    const options = { ...this.props.options };
    options.enabled = value;
    this.props.update(options, this.props.name);
  };

  renderTrigger = () => {

    if (
      this.props.options != undefined &&
      "trigger" in this.props.options &&
      this.props.options.trigger != ""
    ) {
      if (this.props.subcommand != undefined && this.props.subcommand != "") {
        return `${this.props.subcommand} ${this.props.options.trigger}`
      } else {
        return this.props.options.trigger;
      }
    } else if (this.props.defaultTrigger != undefined) {
      if (this.props.subcommand != undefined && this.props.subcommand != "") {
        return `${this.props.subcommand} ${this.props.defaultTrigger}`
      } else {
        return this.props.defaultTrigger;
      }
    } else {
      if (this.props.subcommand != undefined && this.props.subcommand != "") {
        return `${this.props.subcommand} ${this.props.name}`
      } else {
        return this.props.name;
      }
    }
  };

  closeModal = () => {
    this.setState({ openEditor: false });
    document.body.style.overflowY = 'visible';
  };

  closeCC = () => {
    this.setState({ openCC: false })
  }

  commandDelete = (index) => {
    var commands = [...this.props.commands]
    commands.splice(index, 1);
    this.props.setBotModule({
      module: "autoresponder",
      module_data: commands
    })
    this.setState({ openCC: false })
  }

  renderCustomCommandEditor = () => {
    if (this.props.custom_command == true && this.state.openCC == true) {
      return (<CustomCommandEditor showDelete={true} delete={this.commandDelete} close={this.closeCC} save={this.props.custom_command_save} type={this.props.type} options={this.props.options}></CustomCommandEditor>)
    } else {
      return null
    }
  }

  renderModal = () => {
    if (this.state.openEditor == true) {
      document.body.style.overflowY = 'hidden';
      return (
        <CommandEditor
          value={this.state.openEditor}
          module={this.props.module}
          command={this.props.name}
          options={this.props.options}
          defaultTrigger={this.props.defaultTrigger}
          close={this.closeModal}
          submodule={this.props.submodule}
          extraDescriptions={this.props.extraDescriptions}
          custom_command={this.props.custom_command}
        ></CommandEditor>
      );
    } else {
      document.body.style.overflowY = 'visible';
      return null;
    }
  };

  commandClick = (e) => {
    if (e.currentTarget === e.target) {
      this.setState({ openEditor: true });
    }
    // this.setState({openEditor:true})
  };

  render() {
    if (this.props.betaOnly === true && this.props.beta === false) {
      return null
    } else {
      return (
        <div>
          <div>{this.renderModal()}</div>
          <div>{this.renderCustomCommandEditor()}</div>
          <div
            className="command"
            onClick={(e) => {
              this.commandClick(e);
            }}
          >
            <div
              className="command-info"
              onClick={(e) => {
                this.commandClick(e);
              }}
            >
              <h3
                onClick={(e) => {
                  this.commandClick(e);
                }}
              >
                {this.props.showPrefix == true && this.props.slashOnly != true ? this.props.prefix : null}
                {this.props.slashOnly == true ? "/" : null}
                {this.renderTrigger()}  {this.props.slash == true && this.props.slashOnly != true ? ` or /${this.renderTrigger()}` : null}
              </h3>
              <span
                onClick={(e) => {
                  this.commandClick(e);
                }}
              >
                {this.props.description}
              </span>
            </div>

            <div className="command-options justify-center">
              {this.props.showToggle != false ? (
                <div className="command-option">
                  <Toggle
                    value={this.props.toggleValue}
                    update={this.toggleChange}
                  ></Toggle>
                </div>
              ) : null}

              {this.props.custom_command == true ?
                <div className="command-option">
                  <button className="btn btn-red" onClick={(e) => { this.setState({ openCC: true }) }}>Edit</button>
                </div> : null}
            </div>
          </div>
        </div>
      );
    }

  }
}

const mapStateToProps = (state) => ({
  prefix: state.data.bot.prefix,
  commands: state.data.bot.commands.autoresponder,
  beta: state.data.beta
});

const mapDispatchToProps = {
  setBotModule
}

export default connect(mapStateToProps, mapDispatchToProps)(Command);
