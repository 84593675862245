import React, { Component } from "react";
import { connect } from "react-redux";

import server from "../../../api/server";
import history from "../../../history";
import { Loader } from "../../Loader";

export class Invite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.getInvite();
  }

  getInvite = async () => {
    if (this.props.match.params.id != undefined) {
      const response = await server.post("/dashboard/invite", {
        id: this.props.match.params.id,
      });
      if (response.data == true) {
        this.setState({ loading: false });
      } else {
        history.push("/dashboard");
      }
    }
  };

  render() {
    if (this.state.loading == true) {
      return <Loader></Loader>;
    } else {
      return (
        <div className="invite-container">
          <div className="invite">
            <div>
              <div className="invite-image">
                <img src={process.env.PUBLIC_URL + "/images/logo-red.png"} />
              </div>
              <h3>Invite Successful</h3>
              <p>
                You have successfuly been invited to edit another bot, to change
                bots, click the Bot Switcher drop down in the top right hand
                side of your dashboard
              </p>

              <div style={{ float: "right" }}>
                <button
                  className="btn btn-red"
                  onClick={() => {
                    history.push("/dashboard");
                  }}
                >
                  Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
