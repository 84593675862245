import { faDollarSign, faCopy, faFileImport, faCode, faCodeBranch, faEnvelope, faEye, faHashtag, faMouse, faMousePointer, faPaperPlane, faReply, faReplyAll, faSortNumericDown, faTextHeight, faUser, faUserMinus, faUserPlus, faUsers, faQuestion, faComment, faCommentSlash, faHourglass, faPercent, faShoppingCart, faDatabase, faGreaterThanEqual, faFile, faExclamationTriangle, faLock, faEquals } from '@fortawesome/free-solid-svg-icons';
import { ConsoleView } from 'react-device-detect';
import { addEdge } from 'react-flow-renderer';
import { ACTION_TYPES } from '../../../variables';
import { copy } from 'copy-anything';



var types = [
    { icon: faTextHeight, title: "Text", description: "A plain text option", type: "string" },
    { icon: faSortNumericDown, title: "Number", description: "A number option", type: "int" },
    { icon: faUser, title: "User", description: "Select a member from the server", type: "user" },
    { icon: faHashtag, title: "Channel", description: "Select a channel from the server", type: "channel" },
    { icon: faUsers, title: "Role", description: "Select a role from the server", type: "role" },
    { icon: faQuestion, title: "Choice", description: "A True or False option", type: "boolean" },
    { icon: faFile, title: "Attachment", description: "An attachment option", type: "attachment" },
];

var CONDITION_TYPES = [
    { icon: faQuestion, title: "Options Condition", description: "Run actions based on option values", condition_type: "option" },
    { icon: faPercent, title: "Chance Condition", description: "Set a percent chance for actions to run", condition_type: "chance" },
    { icon: faHashtag, title: "Channel Condition", description: "Run actions based on the channel the command was used in", condition_type: "channel" },
    { icon: faUsers, title: "Role Condition", description: "Run actions based on the roles of the user.", condition_type: "role" },
    { icon: faUser, title: "User Condition", description: "Run actions based on who used the command", condition_type: "user" },
    { icon: faDollarSign, title: "Currency Condition", description: "Run actions based on the currency balance of the user", condition_type: "currency" },
    { icon: faShoppingCart, title: "Item Condition", description: "Run actions based on the inventory of the user", condition_type: "item" },
    { icon: faDatabase, title: "Custom Variable Condition", description: "Run actions based on the value of a custom variable", condition_type: "custom_variable" },
    { icon: faGreaterThanEqual, title: "Comparison Condition", description: "Run actions based on the difference between two values.", condition_type: "comparison" },
    { icon: faLock, title: "Permissions Condition", description: "Run actions based on the server permissions of a user.", condition_type: "permissions" },
    { icon: faEquals, title: "Regex Match Condition", description: "Run actions based on wether a value matches a regex", condition_type: "regexMatch" }
];

export default async function createTree(trimmed_elements) {
    var elements = [];

    trimmed_elements.forEach(element => {
        if (element.data) {
            var type = element.type;
            if (type == "root") {

            } else if (type == "action") {

            } else if (type == "condition") {

            } else if (type == "button") {

            } else if (type == "selectMenuOption") {

            } else if (type == "conditionChild") {

            }


        } else {
            // Is a 
            elements.push(element);
        }
    });

    return elements;


}