import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { copyToast } from '../toast';

import TextArea from '../../elements/TextArea';
import VariableTextInput from '../VariableTextInput';


export class SetUniqueVariable extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "set_unique_variable",
                        reference: "",
                        value: "",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "set_unique_variable",
                        reference: "",
                        value: "",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE

            if ((this.props.data.reference != "") && this.props.data.validated != true) {
                this.updateData("validated", true);
            } else if (this.props.data.validated == true && (this.props.data.reference == "")) {
                this.updateData("validated", false);

            }
        }
    }

    updateData = (key, value) => {

        // console.log("VALUE", value);
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    nameChange = (value) => {
        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "_");
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("reference", value);
    };


    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Set a unique variable</h4>
                        <p>Set a unique variable for every execution of this command or event. This variables value will not persist after the command or event has finished executing.</p>
                    </div>

                    <h4 style={{ marginBottom: "0px" }}>Name</h4>
                    <div className="section-content-header mb-15">A name for this variable. Use this name again to set the value again in this command or event.</div>
                    <div class="long-input mb-15">
                        <label>Variable</label>
                        <input className={`${this.props.data.reference == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                            // if (!isNaN(e.target.value) && e.target.value <= 850) {

                            this.nameChange(e.target.value);
                            // }
                        }} type="text" value={this.props.data.reference} />
                    </div>

                    <h4 style={{ marginBottom: "0px" }}>Value</h4>
                    <div className="section-content-header mb-15">The value for this variable.</div>
                    <VariableTextInput required={true} slash_options={this.props.options} label="Value" placeholder="Value" value={this.props.data.value} onChange={(value) => {
                        this.updateData("value", value);
                    }} />
                    {/* <div class="long-input mb-15">
                        <label>Value</label>
                        <input className={`${this.props.data.value == "" && this.props.saveFailed ? "command-required" : ""}`} onChange={(e) => {
                            // if (!isNaN(e.target.value) && e.target.value <= 850) {
                            this.updateData("value", e.target.value);
                            // }
                        }} type="text" value={this.props.data.value} />
                    </div> */}


                    <div>
                        <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>



                        <h4>Unique Variable</h4>
                        <p className="mb-15" style={{
                            color: "#fff",
                            opacity: "0.7",
                            fontWeight: 500
                        }}>You can use the variable <span style={{ color: "#f45142", opacity: "1 !important" }}>{`{${this.props.data.reference}}`}</span><FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.reference}}`); copyToast("Copied Variable"); }} />.</p>

                    </div>



                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(SetUniqueVariable);
