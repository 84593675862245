import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../../../api/server';

import history from "../../../history";
import "../styles/requestbuilder.css";
import Toggle from './Toggle';
import ReactJSON from 'react-json-view';
import axios from 'axios';
import _ from "lodash";
import DocsLink from './DocsLink';
import update from "immutability-helper";



export class RequestBuilder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: "params",
            loaded: false,
            loading: false,
            variable: "",
            api_data: {
                response: { test: "dog" },
                status: "",
                statusText: "",
            }
        };
    }

    componentDidMount() {
        this.setState({ variable: `{${this.props.name}}` });

        // navigator.clipboard.readText()
        //     .then(text =>{
        //         console.log(text)
        //     })
        //     .catch((e) =>{
        //         console.log(e)
        //     })
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log("Updating data");
    }

    testRequest = async () => {

        this.setState({ loaded: false, loading: true });
        var response = await server.post("/dashboard/test_api_action", {
            request_options: this.props.request_options,
            bot_id: this.props.bot_id,
        });

        if (response && response.data && response.data.success == true) {
            var variable = `{${this.props.name}}`;
            if ("response" in response.data.api_data) {
                variable = `{${this.props.name}.response.${Object.keys(response.data.api_data.response)[0]}}`;
            }
            this.setState({
                api_data: response.data.api_data,
                loaded: true,
                loading: false,
                variable: variable
            });
        }



        // if (response && response.data) {
        //     var api_data = {
        //         response: response.data,
        //         status: response.status,
        //         statusText: response.statusText,
        //     };
        //     this.setState({ api_data: api_data });
        // }
    };

    modalBackgroundClick = (e) => {
        // console.log("MODAL BACKGROUND CLICK");
        if (e.currentTarget == e.target) {
            this.props.closeModal(e);
        }
    };

    import = async () => {
        this.setState({ disabled: true, error: "" });
        const response = await server.post("/dashboard/importcommand", { id: this.state.code });
        // console.log(response, 'response');
        if (response.data == false || response.data.command == undefined) {
            // ERROR
            this.setState({ disabled: false, error: "Could not find a command with that share code." });
        } else {
            this.props.importCommand(response.data.command);
        }
    };


    renderOptions = (mode) => {
        var array = mode == "headers" ? this.props.request_options.headers : mode == 'params' ? this.props.request_options.params : this.props.request_options.body;
        var options = [];
        array.forEach((option, index) => {
            options.push(
                <div className='requestbuilder-option mb-15'>
                    <div className="long-input" style={{ width: "50%", paddingRight: "5px" }}>
                        <label>Key</label>
                        <input placeholder="key" required type="text" value={option.key} onChange={(e) => {
                            var request_options = { ...this.props.request_options };
                            request_options[mode][index].key = e.target.value;
                            this.props.updateData(request_options);
                        }}></input>
                    </div>

                    <div className="long-input " style={{ width: "50%", paddingLeft: "5px" }}>
                        <label>Value</label>
                        <input placeholder="value" required type="text" value={option.value} onChange={(e) => {
                            var request_options = { ...this.props.request_options };
                            request_options[mode][index].value = e.target.value;
                            this.props.updateData(request_options);
                        }}></input>
                    </div>

                    <div style={{ marginLeft: "5px" }}>
                        <button className='btn btn-secondary' onClick={() => {
                            var request_options = { ...this.props.request_options };
                            request_options[mode].splice(index, 1);
                            this.props.updateData(request_options);
                        }}><FontAwesomeIcon icon={faTimes} /></button>
                    </div>
                </div>
            );
        });

        return options;
    };


    renderResult = () => {
        if (!this.state.api_data || this.props.name == "" || this.state.variable == "") return null;
        var data = {
            [this.props.name]: this.state.api_data
        };
        var variable = this.state.variable;
        var value = _.get(data, variable.slice(1, -1));
        if (value && typeof value == "object") {
            return '[object Object]';
        } else if (!value && value != false) {
            return 'undefined';
        } else {
            // console.log(value, 'VALUE');
            return `${value}`;
        }
        // console.log(JSON.stringify(value), value.type == 'object', 'RESULT VALUE');
        // if (value && value.type == 'object') {
        //     return JSON.stringify(value);
        // } else if (!value) {
        //     return 'undefined';
        // } else {
        //     return value;
        // }

        // return value;
    };



    render() {
        return (
            <div class={`modal fade show modal-background mt-10 mb-10 xl:mt-auto xl:mb-auto`} onClick={(e) => { this.modalBackgroundClick(e); }} style={{ display: "block" }} tabindex="-1" role="dialog" >
                <div class="modal-dialog premium-modal-dialog" role="document" style={{ maxWidth: "750px" }}>
                    <div class="modal-content premium-modal" style={{ backgroundColor: "#f8f8fb", maxWidth: '750px' }}>
                        <div class="modal-body command-edit-body premium-modal-body overflow-x-scroll">
                            <div className="premium-modal-content" style={{ width: "100%" }}>
                                <h2>Request Builder<DocsLink location="https://docs.botghost.com/custom-commands/api-requests" /></h2>
                                <p>Build the request to be send when this action is executed.</p>
                                <span style={{ color: "red", fontWeight: "800" }}>{this.state.error}</span>

                                <div className='mb-15 url-container'>
                                    <select style={{ paddingLeft: "10px" }} value={this.props.request_options.method} onChange={(e) => {
                                        var value = e.target.value;
                                        var request_options = { ...this.props.request_options };
                                        request_options.method = value;
                                        this.props.updateData(request_options);
                                        if (this.state.mode == "body" && (value == "GET" || value == "HEAD")) {
                                            this.setState({ mode: "params" });
                                        }
                                    }}>
                                        <option>GET</option>
                                        <option>POST</option>
                                        <option>PUT</option>
                                        <option>PATCH</option>
                                        <option>DELETE</option>
                                        <option>HEAD</option>
                                    </select>

                                    <div className="long-input mb-15" style={{ width: "100%" }}>
                                        <label>URL</label>
                                        <input value={this.props.request_options.url} placeholder="ex: https://www.example.com" required type="text" onChange={(e) => {
                                            var value = e.target.value;
                                            value = value.trim();
                                            var request_options = { ...this.props.request_options };
                                            request_options.url = value;
                                            this.props.updateData(request_options);
                                        }}></input>
                                    </div>

                                </div>

                                <div className='requestbuilder-options-container'>
                                    <div className="requestbuilder-option-header">
                                        <h4 className={`${this.state.mode == "params" ? "selected" : null}`} onClick={() => {
                                            this.setState({ mode: "params" });
                                        }}>URL Params </h4>
                                        <h4 className={`${this.state.mode == "headers" ? "selected" : null}`} onClick={() => {
                                            this.setState({ mode: "headers" });
                                        }}>HTTP Headers</h4>
                                        <h4 className={`${this.state.mode == "body" ? "selected" : null} ${this.props.request_options.method == "GET" || this.props.request_options.method == "HEAD" ? "disabled" : null}`} onClick={() => {
                                            if (this.props.request_options.method == "GET" || this.props.request_options.method == "HEAD") return;
                                            this.setState({ mode: "body" });
                                        }}>Request Body</h4>

                                        <h4 className={`${this.state.mode == "options" ? "selected" : null}`} onClick={() => {
                                            this.setState({ mode: "options" });
                                        }}>Options</h4>

                                        <h4 className={`${this.state.mode == "test" ? "selected" : null}`} onClick={() => {
                                            this.setState({ mode: "test" });
                                        }}>Test Request</h4>
                                        {/* <h4>Options</h4> */}
                                    </div>


                                    {this.state.mode == "headers" ? <>
                                        {this.renderOptions("headers")}

                                        <div className='requestbuilder-add-option'>
                                            <button className='btn btn-primary' onClick={() => {
                                                if (this.props.request_options.headers.length >= 50) return;
                                                var request_options = { ...this.props.request_options };
                                                request_options.headers.push({ key: "", value: "" });
                                                this.props.updateData(request_options);
                                            }}>Add</button>
                                        </div>

                                    </> : this.state.mode == "params" ?
                                        <>
                                            {this.renderOptions("params")}

                                            <div className='requestbuilder-add-option'>
                                                <button className='btn btn-primary' onClick={() => {
                                                    if (this.props.request_options.params.length >= 50) return;
                                                    var request_options = { ...this.props.request_options };
                                                    request_options.params.push({ key: "", value: "" });
                                                    this.props.updateData(request_options);
                                                }}>Add</button>
                                            </div>

                                        </> : this.state.mode == "body" ? <>
                                            {this.renderOptions("body")}

                                            <div className='requestbuilder-add-option'>
                                                <button className='btn btn-primary' onClick={() => {
                                                    var request_options = { ...this.props.request_options };
                                                    request_options.body.push({ key: "", value: "" });
                                                    this.props.updateData(request_options);
                                                }}>Add</button>
                                            </div>
                                        </> : this.state.mode == "options" ? <>
                                            <div>
                                                <div className='requestbuilder-settings mb-15'>
                                                    <Toggle value={this.props.request_options.options.excludeEmptyFields} update={(value) => {
                                                        var request_options = { ...this.props.request_options };
                                                        request_options.options.excludeEmptyFields = !request_options.options.excludeEmptyFields;
                                                        this.props.updateData(request_options);
                                                    }} />
                                                    <span>Automatically exclude fields that are empty</span>

                                                </div>

                                                <div className='requestbuilder-settings mb-15'>
                                                    <Toggle value={this.props.request_options.options.replaceURL} update={(value) => {
                                                        var request_options = { ...this.props.request_options };
                                                        request_options.options.replaceURL = !request_options.options.replaceURL;
                                                        this.props.updateData(request_options);
                                                    }} />
                                                    <span>Replace variables in the URL</span>

                                                </div>

                                                <div className='requestbuilder-settings mb-15'>
                                                    <Toggle value={this.props.request_options.options.replaceParams} update={(value) => {
                                                        var request_options = { ...this.props.request_options };
                                                        request_options.options.replaceParams = !request_options.options.replaceParams;
                                                        this.props.updateData(request_options);
                                                    }} />
                                                    <span>Replace variables in the URL Params</span>

                                                </div>

                                                <div className='requestbuilder-settings mb-15'>
                                                    <Toggle value={this.props.request_options.options.replaceHeaders} update={(value) => {
                                                        var request_options = { ...this.props.request_options };
                                                        request_options.options.replaceHeaders = !request_options.options.replaceHeaders;
                                                        this.props.updateData(request_options);
                                                    }} />
                                                    <span>Replace variables in the HTTP Headers</span>

                                                </div>

                                                <div className='requestbuilder-settings mb-15'>
                                                    <Toggle value={this.props.request_options.options.replaceBody} update={(value) => {
                                                        var request_options = { ...this.props.request_options };
                                                        request_options.options.replaceBody = !request_options.options.replaceBody;
                                                        this.props.updateData(request_options);
                                                    }} />
                                                    <span>Replace variables in body</span>

                                                </div>
                                            </div>

                                        </> : <>

                                            {this.state.loaded == true ?
                                                <>
                                                    <div>
                                                        <h4>Variables <DocsLink location="https://docs.botghost.com/custom-commands/api-requests#api-action-variables" /></h4>
                                                        <p>You can test the potential value of a variable below. Use the <span style={{ color: "#f45142" }}>{`{${this.props.name}}`}</span> variable with dot notation to access the below response.</p>
                                                    </div>

                                                    <div className='mb-15'>
                                                        <h4>Test Variable <DocsLink location="https://docs.botghost.com/custom-commands/api-requests#api-action-variables" /></h4>
                                                        <p>Test the potential value of a variable based on the response above. Click on a value in the response below to copy the variable required to return that data in another action.</p>
                                                        <div className="long-input mb-15" style={{ width: "50%", paddingRight: "5px" }}>
                                                            <label>Variable</label>
                                                            <input placeholder="key" required type="text" value={this.state.variable} onChange={(e) => {
                                                                this.setState({ variable: e.target.value });
                                                                // var request_options = { ...this.props.request_options };
                                                                // request_options[mode][index].key = e.target.value;
                                                                // this.props.updateData(request_options);
                                                            }}></input>
                                                        </div>
                                                        <h4>Variable Result: <span style={{ color: this.renderResult() == "undefined" || this.renderResult() == '[object Object]' ? "red" : "green" }}>{this.renderResult()}</span></h4>
                                                    </div>
                                                    <div className="mb-15">
                                                        <h4>Response</h4>
                                                        <div><ReactJSON copy={false} onSelect={(data) => {
                                                            var variable = this.props.name;
                                                            if (data.namespace.length > 0) {
                                                                variable += "." + data.namespace.join(".") + "." + data.name;
                                                            } else {
                                                                variable += "." + data.name;
                                                            }
                                                            navigator.clipboard.writeText(`{${variable}}`);
                                                            this.setState({
                                                                variable: `{${variable}}`
                                                            });
                                                        }} maxHeight={"500px"} collapsed={2} name={false} displayDataTypes={false} theme={"ocean"} src={this.state.api_data} /></div>
                                                    </div>
                                                </> : null}


                                            <div>
                                                <button onClick={() => {
                                                    this.testRequest();
                                                }} className='btn btn-primary mb-2 xl:mb-auto' disabled={this.state.loading}>Test Request</button>
                                            </div>

                                        </>}



                                </div>

                                {/* <div className='requestbuilder-options-container'>
                                        <div className="requestbuilder-option-header">
                                            <h4>Request Body</h4>
                                        </div>


                                        <div className='requestbuilder-option'>
                                            <div className="long-input mb-15" style={{ width: "50%", paddingRight: "5px" }}>
                                                <label>Key</label>
                                                <input placeholder="ex: https://www.example.com" required type="text" value={this.state.code} onChange={(e) => { this.setState({ code: e.target.value }); }}></input>
                                            </div>

                                            <div className="long-input mb-15" style={{ width: "50%", paddingLeft: "5px" }}>
                                                <label>Value</label>
                                                <input placeholder="ex: https://www.example.com" required type="text" value={this.state.code} onChange={(e) => { this.setState({ code: e.target.value }); }}></input>
                                            </div>
                                        </div>

                                        <div className='requestbuilder-add-option'>
                                            <button className='btn btn-primary'>Add</button>
                                        </div>

                                    </div> */}

                                {/* <div className="long-input mb-15" style={{ width: "100%" }}>
                                        <label>Share Code</label>
                                        <input placeholder="CMD_IASDAD-2399ASDAS-293293ASD-ASD" required type="text" value={this.state.code} onChange={(e) => { this.setState({ code: e.target.value }); }}></input>
                                    </div> */}

                                {/* <div>
                                        <button className="btn btn-gray" type="button" onClick={() => { this.props.closeModal(); }}>Close</button>

                                        <button className="btn btn-red ml-2" type="button" disabled={this.state.code == "" || this.state.disabled} onClick={() => { this.import(); }}>Import</button>
                                    </div> */}
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.data.bot.token,
    bot_id: state.data.bot.id

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(RequestBuilder);
