import React, { Component } from 'react';
import { connect } from 'react-redux';
import './preview.css';
import moment from 'moment';
import MarkdownRender from './MarkdownRender';
import DOMPurify from 'dompurify'; // Add this import

const MAX_FIELDS_PER_ROW = 3;
const FIELD_GRID_SIZE = 12;

export class DiscordEmbedPreview extends Component {
    // inline formatting
    getFieldGridColumn = (fields, fieldIndex) => {
        const field = fields[fieldIndex];

        if (!field.inline) {
            return `1 / ${FIELD_GRID_SIZE + 1}`;
        }

        let startingField = fieldIndex;
        while (startingField > 0 && fields[startingField - 1].inline) {
            startingField -= 1;
        }

        let totalInlineFields = 0;
        while (fields.length > startingField + totalInlineFields && fields[startingField + totalInlineFields].inline) {
            totalInlineFields += 1;
        }

        const indexInSequence = fieldIndex - startingField;
        const currentRow = indexInSequence / MAX_FIELDS_PER_ROW;
        const indexOnRow = indexInSequence % MAX_FIELDS_PER_ROW;
        const totalOnLastRow = totalInlineFields % MAX_FIELDS_PER_ROW || MAX_FIELDS_PER_ROW;
        const fullRows = (totalInlineFields - totalOnLastRow) / MAX_FIELDS_PER_ROW;
        const totalOnRow = currentRow >= fullRows ? totalOnLastRow : MAX_FIELDS_PER_ROW;

        const columnSpan = FIELD_GRID_SIZE / totalOnRow;
        const start = indexOnRow * columnSpan + 1;
        const end = start + columnSpan;

        return `${start} / ${end}`;
    };

    sanitizeContent = (content) => {

        return DOMPurify.sanitize(content, {
            ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p', 'br'],
            ALLOWED_ATTR: ['href', 'target', 'rel']
        });
    };

    renderEmbeds = () => {
        var embeds = [];

        this.props.embeds.forEach(embed => {
            var fields = [];

            embed.fields.forEach((field, index) => {
                fields.push(
                    <div className="discord-embed-field" key={field.id} style={{ gridColumn: this.getFieldGridColumn(embed.fields, index) }}>
                        <div className="discord-embed-field-title"><b>{field.name}</b></div>
                        <div className="discord-embed-field-content"><MarkdownRender text={this.sanitizeContent(field.value)}></MarkdownRender></div>
                    </div>
                );
            });
            embeds.push(

                <div className="discord-embed" key={embed.id}>
                    <div className="discord-embed-left-border" style={{ backgroundColor: embed.color || "#000000" }}></div>
                    <div className="discord-embed-container" style={{ width: "100%" }}>
                        <div className="discord-embed-content">
                            <div>
                                {(embed.author.icon_url || embed.author.name) && (
                                    <div className="discord-embed-author">
                                        {embed.author.icon_url && <img src={this.sanitizeContent(embed.author.icon_url)} alt="" />}
                                        {embed.author.name && <span dangerouslySetInnerHTML={{ __html: this.sanitizeContent(embed.author.name) }} />}
                                    </div>
                                )}

                                {embed.title && (
                                    <div className="discord-embed-title">
                                        <span dangerouslySetInnerHTML={{ __html: this.sanitizeContent(embed.title) }} />
                                    </div>
                                )}

                                {embed.description && (
                                    <div className="discord-embed-description">
                                        <p><MarkdownRender text={this.sanitizeContent(embed.description)} /></p>
                                    </div>
                                )}

                                <div className="discord-embed-fields" style={{
                                    minWidth: 0,
                                    margin: '8px 0px, 0px',
                                    display: 'grid',
                                    gridColumn: 1 / 2,
                                    gridGap: 8,
                                }}>
                                    {fields}
                                </div>

                                {(embed.image.url && embed.image.url !== "") && (
                                    <img className="discord-embed-image" src={this.sanitizeContent(embed.image.url)} alt="" />
                                )}
                            </div>
                            {(embed.thumbnail.url && embed.thumbnail.url !== "") && (
                                <img className="discord-embed-thumbnail" src={this.sanitizeContent(embed.thumbnail.url)} alt="" />
                            )}
                        </div>

                        {(embed.footer.icon_url || embed.footer.text || embed.timestamp) && (
                            <div className="discord-embed-footer">
                                {embed.footer.icon_url && <img src={this.sanitizeContent(embed.footer.icon_url)} />}
                                {embed.footer.text && <span>{this.sanitizeContent(embed.footer.text)}</span>}
                                {embed.timestamp && <span>•  {moment(embed.timestamp).format("MM/DD/YY hh:MM")}</span>}
                            </div>
                        )}
                    </div>
                </div>
            );
        });

        return embeds;
    };

    render() {
        return (
            <div className="discord-preview-container">
                <div className="discord-preview">
                    <div className="discord-messages">
                        <div className="discord-message">



                            <div className="discord-message-content">
                                <div class="discord-author-avatar">
                                    <img
                                        src={this.props.avatarURL || "https://botghost.com/img/logo-red.png"}
                                        alt=""
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = "https://botghost.com/img/logo-red.png";
                                        }}
                                    />

                                </div>

                                <div class="discord-message-body">
                                    <div className="discord-author-info-block">
                                        <span class="discord-author-info">
                                            <span class="discord-author-username">{this.props.username || "BotGhost"}</span>
                                            <span class="discord-author-bot-tag"> Bot </span>
                                        </span>
                                        <span class="discord-message-timestamp">Today at {moment().format("h:MM A")}</span>
                                    </div>
                                    <MarkdownRender text={this.sanitizeContent(this.props.content)} />


                                    {this.renderEmbeds()}
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default DiscordEmbedPreview;